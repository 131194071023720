import { types as t } from 'mobx-state-tree';

export const Category = t.model({
  tid: t.identifierNumber,
  name: t.string,
  description: t.maybeNull(t.string),
  weight: t.number,
  symbol: t.maybeNull(t.string)
});

export const Profession = t.model('Profession', {
  tid: t.identifier,
  uuid: t.string,
  name: t.string,
  categories_parent_term: t.number,
  categories2_parent_term: t.number,
  ewise_labels: t.string,
  categories: t.array(Category),
  categories2: t.array(Category)
});
