import { ThemeBrandTitle } from 'helpers/Helpers';
import { checkUserSignedIn } from 'state/RoutesActions';

export const notFoundPageRoute = {
  name: 'notFound',
  pattern: '/not-found',
  title: `Pagina niet gevonden | ${ThemeBrandTitle}`,
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;

    if (store.state !== 'done') await checkUserSignedIn(fromState, toState, routerStore);
  }
  // onEnter: async (fromState, toState, routerStore) => {
  //
  //   const { store } = routerStore.options;
  //
  //   if (store.state !== 'done') await checkUserSignedIn(fromState, toState, routerStore);
  // }
};
