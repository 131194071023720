import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// MUI
import { Grid, Box } from '@mui/material';
/* Custom RHF components */
import { Editor } from 'components/form/RHF';
import { useEffect } from 'react';

/*
Overall, this component serves as an extensive form for editing various attributes and details of a lesson container within a course, 
including title, extra label, information, goals, authors, reviewers, podcasts, video, and sharing information. 
The control prop is used to manage form controls, and translations are provided for various labels and messages.
*/
const LessonContainerSharingEditForm = ({ dirty, control, isDisabled, ...restProps }) => {
  const { t } = useTranslation('editCourseForm');
  const { errors, setValue, getFieldState, resetField } = restProps;

  useEffect(() => {
    if (!getFieldState('lessonContainer.attributes.field_sharing_info').isDirty) {
      resetField('lessonContainer.attributes.field_sharing_info');
    }
  }, [getFieldState, resetField]);
  //
  return (
    <Box className="box-left" sx={{ flexGrow: 1 }}>
      <Grid container direction="column" spacing={0}>
        <Grid item sx={{ margin: '0px' }}>
          <Editor
            disabled={isDisabled('lessonContainer')}
            label={t('lessonEditForm.information')}
            field={{ name: 'lessonContainer.attributes.field_sharing_info' }}
            form={{ setValue, control }}
            error={!!errors?.lessonContainer?.attributes?.field_sharing_info}
            helperText={errors?.lessonContainer?.attributes?.field_sharing_info?.message}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

LessonContainerSharingEditForm.propTypes = {
  control: PropTypes.object,
  isDisabled: PropTypes.func,
  dirty: PropTypes.bool
};

export default observer(LessonContainerSharingEditForm);
