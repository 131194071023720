import * as Yup from 'yup';

const formEvaluationValidation = Yup.object().shape({
  evaluation_question_1: Yup.string().required('This field is required'),
  evaluation_question_1b: Yup.string().when('evaluation_question_1', {
    is: (value) => value && value !== 'uitstekend' && value !== 'goed',
    then: Yup.string().required('Field is required'),
    otherwise: Yup.string()
  }),
  evaluation_question_2: Yup.string().required('This field is required'),
  evaluation_question_2b: Yup.string().when('evaluation_question_2', {
    is: (value) => value && value !== 'uitstekend' && value !== 'goed',
    then: Yup.string().required('Field is required'),
    otherwise: Yup.string()
  }),
  evaluation_question_3: Yup.string().required('This field is required'),
  evaluation_question_4: Yup.string().required('This field is required'),
  evaluation_remarks_1: Yup.string(),
  evaluation_remarks_2: Yup.string(),
  evaluation_question_slpo_1: Yup.string(),
  evaluation_question_slpo_2: Yup.string(),
  evaluation_question_slpo_3: Yup.string(),
  evaluation_question_slpo_4: Yup.string()
});

export { formEvaluationValidation };
