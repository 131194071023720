/* Core */
import { useContext, useEffect, useState } from 'react';
import { useRouterStore } from 'mobx-state-router';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';

/* MUI */
import { Card, CardContent, CardHeader, Grid, Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import 'theme/faq/Faq.scss';

/* Custom components */
import SearchBar from './components/SearchBar';

export const Questions = [
  {
    id: 0,
    category: 'Category 1',
    question: 'What are the costs of a subscription?',
    answer: 'A subscription for teachers costs € 292 per year. You can use this unlimited training. '
  },
  {
    id: 1,
    category: 'Category 2',
    question: 'How soon after my registration can I start the courses?',
    answer: 'If you send, e-mail or fax your signed registration on working days before 5 p.m.,'
  },
  {
    id: 2,
    category: 'Category 3',
    question: 'How soon after my registration can I start the courses??',
    answer: 'If you send, e-mail or fax your signed registration on working days before 5 p.m.,'
  },
  {
    id: 3,
    category: 'Category 4',
    question: 'How soon after my registration can I start the courses??',
    answer: 'If you send, e-mail or fax your signed registration on working days before 5 p.m.,'
  }
];

const Faq2 = () => {
  const store = useContext(Store);
  const routerStore = useRouterStore();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    store.fetchFaqByLabel('ewise').then(() => {
      setLoaded(true);
    });
  }, [store, store.routerState.routeName]);

  const handleClick = () => {
    routerStore.goTo('article');
  };

  return (
    <>
      {loaded && (
        <Grid container className="faq_main">
          <SearchBar />
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 12, sm: 8, md: 12 }}
              className="faq_category_section"
              style={{ justifyContent: 'space-between' }}
            >
              {store.groupByCategory().map((faq, index) => (
                <Grid item xs={12} sm={4} md={4} key={index} style={{ paddingLeft: '0px' }}>
                  <Card className="faq_card">
                    <CardHeader title={faq.field_faq_categorie} className="faq_card_header" style={{ paddingBottom: '3%' }} />
                    <CardContent style={{ padding: '0px', paddingRight: '16%' }}>
                      {faq.items.map((item) => (
                        <List key={item.nid}>
                          <ListItem
                            alignItems="flex-start"
                            button
                            onClick={handleClick}
                            style={{ width: '100%', padding: '0px' }}
                            className="question_buttons"
                          >
                            <ListItemText primary={item.title} />
                          </ListItem>
                        </List>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default observer(Faq2);
