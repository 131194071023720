import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, Stack } from '@mui/material';

import { Form } from 'components/form';
import { MyTextField } from 'components/form/RHF';
import { useStore } from 'hooks';
import { Dialog } from '../../../components';
import * as Types from '../../../types';

const EditReflectionDialog = ({ goal, evaluation, visible, onCloseCallback }) => {
  const { t } = useTranslation('curriculum');
  const store = useStore();

  const handleSubmit = async ({ reflectionText1 = '', reflectionText2 = '' }) => {
    const attributes = {
      ...evaluation.attributes,
      evaluation: reflectionText1.trim(),
      name: reflectionText2.trim()
    };

    const data = {
      ...evaluation,
      attributes
    };

    store.startLoadCurriculum();
    const result = await store.createOrUpdateCurriculumEvaluation(goal, data);
    await store.refreshCurrentCurriculum();
    store.stopLoadCurriculum();

    if (!result) {
      return {
        success: false,
        errors: [{ name: 'root.serverError', error: { type: 'server', message: 'Internal Server Error' }, options: {} }]
      };
    }

    if (result.errors) {
      return {
        success: false,
        errors: result.errors
      };
    }

    onCloseCallback();

    return { success: true };
  };

  const handleClose = () => {
    onCloseCallback();
  };

  const submitRef = useRef();

  const initialValues = {
    reflectionText1: evaluation?.attributes?.evaluation ?? '',
    reflectionText2: evaluation?.attributes?.name ?? ''
  };

  const validationSchema = Yup.object().shape({
    reflectionText1: Yup.string(),
    reflectionText2: Yup.string()
  });

  return (
    <Dialog
      action={{
        action: () => {
          submitRef.current.click();
        },
        name: t('dashboard.saveLabel'),
        icon: 'ant-design:plus-outlined',
        isLoading: store.isLoadingCurriculum
      }}
      title={t('evaluations.editReflectionTitle')}
      name="edit-reflection"
      visible={visible}
      onClose={handleClose}
    >
      <Form id="edit-reflection" onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ control }) => (
          <Stack justifyContent="space-between" sx={{ padding: '8px' }} spacing={2}>
            <label htmlFor="reflectionText1">{t('evaluations.reflectionText1Label')}</label>
            <MyTextField
              control={control}
              label={null}
              name="reflectionText1"
              multiline
              rows={4}
              sx={{
                padding: '0.5rem',
                backgroundColor: '#ffffff',
                width: '100%',
                borderRadius: '0.75rem',
                borderWidth: '0'
              }}
            />

            <label htmlFor="reflectionText2">{t('evaluations.reflectionText2Label')}</label>
            <MyTextField
              control={control}
              label={null}
              name="reflectionText2"
              multiline
              rows={4}
              sx={{
                padding: '0.5rem',
                backgroundColor: '#ffffff',
                width: '100%',
                borderRadius: '0.75rem',
                borderWidth: '0'
              }}
            />

            <Button sx={{ display: 'none' }} ref={submitRef} type="submit">
              {t('general.addBtnLabel')}
            </Button>
          </Stack>
        )}
      </Form>
    </Dialog>
  );
};

EditReflectionDialog.propTypes = {
  goal: Types.LearningGoal.isRequired,
  evaluation: Types.Evaluation.isRequired,
  visible: PropTypes.bool.isRequired,
  onCloseCallback: PropTypes.func.isRequired
};

export default observer(EditReflectionDialog);
