import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';

export const StatPanel = ({ icon, label, statNumber }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 2,
        display: 'inline-flex',
        borderRadius: 2,
        alignItems: 'center',
        backgroundColor: 'white',
        width: '100%'
      }}
    >
      <Box sx={{ height: 70, width: '25%', ml: -1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icon}</Box>
      <Box sx={{ flex: '1 0 auto', mr: 5, width: '50%' }}>
        <Typography variant="h5" sx={{ color: theme.palette.grey[800], fontSize: 18 }}>
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          boxShadow: '0 10px 25px rgba(0,0,0, 0.08)',
          borderRadius: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 70,
          width: '25%'
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.grey[800],
            fontSize: 20,
            fontWeight: 800
          }}
        >
          {statNumber}
        </Typography>
      </Box>
    </Box>
  );
};

StatPanel.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  statNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
