import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import EnhancedTooltip from 'components/EnhancedTooltip';
import { getObjectPropValue } from 'helpers/getObjectPropValue';
import CustomTableCell from './CustomTableCell';
import ActionTableCell from './ActionTableCell';
import DeleteDialog from '../../DeleteDialogBox';
import CSVExport from '../../CSVExport';

function descendingComparator(a, b, orderBy) {
  const valA = getObjectPropValue(orderBy, a);
  const valB = getObjectPropValue(orderBy, b);

  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
  const { tableHeadData, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, checkboxes, moreInfoBtn } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {!!checkboxes && (
          <TableCell sx={{ py: 1, borderBottom: '0' }} padding="checkbox">
            <Checkbox
              sx={{ color: '#A3AED0', '&:hover': { color: '#2B3674' } }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all names'
              }}
            />
          </TableCell>
        )}
        {tableHeadData.map((headCell) => (
          <React.Fragment key={headCell.id}>
            {!headCell?.hidden ? (
              <TableCell
                sx={{ py: 1, borderBottom: '0', ...headCell.sx }}
                align={headCell.numeric ? 'center' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.disableSorting === false && orderBy === headCell.id ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    style={{
                      color: 'rgb(43, 54, 116)'
                    }}
                  >
                    {headCell.label}
                    {/* {orderBy === headCell.id ? <Box component="span">{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box> : null} */}
                  </TableSortLabel>
                ) : (
                  <Box
                    sx={{
                      color: 'rgb(43, 54, 116)'
                    }}
                    onClick={headCell.disableSorting === false ? createSortHandler(headCell.id) : undefined}
                  >
                    {headCell.label}
                  </Box>
                )}
              </TableCell>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))}
        {!!moreInfoBtn && (
          <TableCell sx={{ py: 1, borderBottom: '0', textAlign: 'right' }} padding="normal">
            &nbsp;
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  tableHeadData: PropTypes.array,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  checkboxes: PropTypes.bool,
  moreInfoBtn: PropTypes.bool
};

const CustomTable = ({
  tableName,
  selectedButtons,
  additionalButtons: Buttons,
  tableHeadData,
  data,
  setData,
  setPresent,
  handleRowClick,
  checkboxes,
  moreInfoBtn,
  actions,
  actionProps,
  tableSx = {},
  rowsPerPage = 10,
  rowSx = () => {}
}) => {
  const { t } = useTranslation('datagrid');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('email');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [_rowsPerPage, setRowsPerPage] = React.useState(rowsPerPage);
  const dense = false;
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleDeleteDialogOpen = () => setDeleteOpen(true);
  const handleDeleteDialogClose = () => setDeleteOpen(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleCheckBoxClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteAll = () => {
    setData(
      data.filter((col) => !selected.includes(col.id)),
      selected
    );
    handleDeleteDialogClose();
    setSelected([]);
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main
    }
  }));
  const sliceText = (text, len) => {
    if (text && text.length > len) {
      return `${text.slice(0, len)}...`;
    }

    return text;
  };

  const getColumnContent = (row, column) => {
    const { id, type } = column;
    switch (type) {
      case 'text':
        return column.slice ? sliceText(getObjectPropValue(id, row), column.slice) : getObjectPropValue(id, row);
      case 'component':
        return column.component({ row, column });
      case 'progress-bar':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3
            }}
          >
            <BorderLinearProgress
              sx={{
                width: '100%'
              }}
              variant="determinate"
              value={getObjectPropValue(id, row)}
            />
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(getObjectPropValue(id, row))}%`}</Typography>
            </Box>
          </Box>
        );
      default:
        return getObjectPropValue(id, row);
    }
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * _rowsPerPage - data.length) : 0;

  const firstColumnStyle = { borderLeft: '1px solid #EEEEEE', borderRadius: '20px 0 0 20px' };
  const lastColumnStyle = { padding: 0, borderRight: '1px solid #EEEEEE', borderRadius: '0 20px 20px 0', textAlign: 'right' };
  const cornerBorderStyles = (i) => ({
    borderLeft: i === 0 && !checkboxes ? '1px solid #EEEEEE' : '',
    borderTopLeftRadius: i === 0 && !checkboxes ? '15px' : '',
    borderBottomLeftRadius: i === 0 && !checkboxes ? '15px' : '',
    borderRight: i === tableHeadData.length - 1 && !moreInfoBtn ? '1px solid #EEEEEE' : '',
    borderTopRightRadius: i === tableHeadData.length - 1 && !moreInfoBtn ? '15px' : '',
    borderBottomRightRadius: i === tableHeadData.length - 1 && !moreInfoBtn ? '15px' : ''
  });

  const hasCheckedItems = !!selected.length;
  const hasSelectionActionButton = !!selectedButtons?.length;

  return (
    <Paper elevation={0} className="custom_table">
      <DeleteDialog
        deleteOpen={deleteOpen}
        handleDeleteDialogClose={handleDeleteDialogClose}
        handleDeleteRecord={() => handleDeleteAll()}
        numberOfRecords={selected.length}
      />
      <TableContainer className="table_container">
        {hasCheckedItems && hasSelectionActionButton && (
          <Box
            sx={{
              paddingInline: '10px',
              paddingTop: '20px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderWidth: '1px',
                borderColor: 'rgb(238, 238, 238)',
                borderRadius: '12px',
                padding: '10px 8px',
                width: '100%',
                border: '1px solid #EEEEEE',
                gap: '12px'
              }}
            >
              <Box
                sx={{
                  lineHeight: '16px',
                  fontSize: { xs: '12px', md: '14px' },
                  paddingInline: '8px'
                }}
              >
                {selected.length}&nbsp;{t('general.selected')}
              </Box>

              {selectedButtons.includes('additionalButtons') && Buttons}

              {selectedButtons.includes('csv-download') && (
                <Box
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  <CSVExport data={data.filter((col) => selected.includes(col.id))}>
                    <Button variant="outlined" size="small">
                      <EnhancedTooltip tooltipText={t('general.bulkExportBtnTooltip')} icon={<Icon icon="akar-icons:download" />} />
                    </Button>
                  </CSVExport>
                </Box>
              )}

              {selectedButtons.includes('delete') && (
                <Box
                  sx={{
                    cursor: 'pointer'
                  }}
                  onClick={() => handleDeleteDialogOpen()}
                >
                  <Button variant="outlined" size="small">
                    <EnhancedTooltip tooltipText={t('general.bulkDeleteBtnTooltip')} icon={<Icon icon="ic:baseline-delete" />} />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        )}

        {hasCheckedItems && tableName === 'attendance' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '8px'
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: '8px',
                fontSize: '12px',
                lineHeight: '16px',
                height: '40px',
                textTransform: 'none'
              }}
              onClick={() => setPresent(selected)}
            >
              Mark Present
            </Button>
          </Box>
        )}

        <Table
          sx={{ minWidth: 750, borderCollapse: 'separate', borderSpacing: '0 10px', px: 4, ...tableSx }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            tableHeadData={tableHeadData}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data?.length}
            checkboxes={checkboxes}
            moreInfoBtn={moreInfoBtn}
          />

          <TableBody sx={{ m: 4 }}>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * _rowsPerPage, page * _rowsPerPage + _rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    role="checkbox"
                    sx={{
                      '&:hover': {
                        color: 'rgb(43, 54, 116)'
                      },
                      ...rowSx(row)
                    }}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={handleRowClick ? 'styledTableRow' : ''}
                  >
                    {!!checkboxes && (
                      <CustomTableCell sx={firstColumnStyle} padding="checkbox" onClick={() => handleCheckBoxClick(row.id)}>
                        <Checkbox
                          sx={{
                            color: 'rgb(163, 174, 208)',
                            '&:hover': {
                              color: '#2B3674'
                            }
                          }}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </CustomTableCell>
                    )}
                    {!!tableHeadData &&
                      tableHeadData.map((column, i) => (
                        <React.Fragment key={uniqueId('table-head-id-')}>
                          {!column?.hidden ? (
                            <CustomTableCell
                              sx={{
                                ...cornerBorderStyles(i),
                                textDecoration: column?.underline ? 'underline' : ''
                              }}
                              align={column.numeric ? 'center' : 'left'}
                              onClick={checkboxes ? (event) => handleRowClick(event, row.id) : undefined}
                            >
                              {tableName === 'courseStudents' && column.id === 'completed' && row[column.id] && (
                                <Box className="courseStudents">
                                  <Icon
                                    icon="fluent-mdl2:skype-check"
                                    style={{ opacity: 0.5 }}
                                    color="primary.main"
                                    width="14"
                                    height="14"
                                  />
                                </Box>
                              )}
                              {tableName === 'courseStudents' && column.id === 'completed' && !row[column.id] && (
                                <Box className="courseStudents">
                                  <Icon icon="fluent-emoji-high-contrast:cross-mark" color="#ee5d50" width="10" height="10" />
                                </Box>
                              )}
                              {(() => {
                                if (tableName === 'courseStudents' && column.id === 'completed') {
                                  // Display date if completed else display percentage
                                  if (row[column.id]) return `${row.completionDate}`;
                                  return `${row.completedPercentage}%`;
                                }
                                return getColumnContent(row, column);
                              })()}
                            </CustomTableCell>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                    {!!moreInfoBtn && actions && (
                      <ActionTableCell sx={lastColumnStyle} id={row.id}>
                        {actions({ ...actionProps, id: row.id })}
                      </ActionTableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows
                }}
              >
                <TableCell sx={{ py: 3 }} colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {rowsPerPage <= data.length && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={_rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

CustomTable.propTypes = {
  tableSx: PropTypes.any,
  tableName: PropTypes.string,
  data: PropTypes.array,
  setData: PropTypes.func,
  setPresent: PropTypes.func,
  tableHeadData: PropTypes.array,
  selectedButtons: PropTypes.array,
  additionalButtons: PropTypes.any,
  handleRowClick: PropTypes.func,
  checkboxes: PropTypes.bool,
  moreInfoBtn: PropTypes.bool,
  actions: PropTypes.func,
  actionProps: PropTypes.shape({
    onDelete: PropTypes.func,
    onDownlaod: PropTypes.func
  }),
  rowsPerPage: PropTypes.oneOf([5, 10, 25, 50, 100]),
  rowSx: PropTypes.func
};

export default CustomTable;
