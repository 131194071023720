import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Stack, Button } from '@mui/material';

import { MyTextField } from 'components/form/RHF';
import { useStore } from 'hooks';
import { Form } from 'components/form';
import { Dialog } from '../../../components';
import * as Styles from '../../../components/styles';

const EditFunctionDialog = ({ fn, visible, onCloseCallback, onSubmitCallback }) => {
  const { t } = useTranslation('curriculum');

  const store = useStore();

  const submitRef = useRef();

  const initialValues = useMemo(
    () => ({
      nameString: fn?.attributes?.name
    }),
    [fn]
  );

  const validationSchema = Yup.object().shape(
    {
      nameString: Yup.string().required()
    },
    ['nameString']
  );

  const handleClose = () => {
    onCloseCallback();
  };

  const handleSubmit = async ({ nameString }) => {
    const data = {
      ...fn,
      attributes: {
        ...fn.attributes,
        name: nameString
      }
    };

    store.startLoadCurriculum();
    const result = await store.updateCurriculumFunction(data);
    store.stopLoadCurriculum();

    if (!result) {
      return {
        success: false,
        errors: [{ name: 'root.serverError', error: { type: 'server', message: 'Internal Server Error' }, options: {} }]
      };
    }

    if (result.errors) {
      return {
        success: false,
        errors: result.errors
      };
    }

    onSubmitCallback();

    return { success: true };
  };

  return (
    <Dialog
      action={{
        action: () => {
          submitRef.current.click();
        },
        name: t('general.editBtnLabel'),
        icon: 'ant-design:edit-outlined',
        isLoading: store.isLoadingCurriculum
      }}
      title={t('functions.editFunctionTitle')}
      name="edit-function"
      visible={visible}
      onClose={handleClose}
    >
      <Form id="edit-function" onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ control, errors }) => (
          <Stack justifyContent="space-between" sx={{ paddingBlock: '8px' }} spacing={2}>
            <label htmlFor="nameString" style={{ fontWeight: '600', display: 'none' }}>
              {t('functions.functionTextfieldTooltipLabel')}
            </label>
            <MyTextField
              control={control}
              label={t('functions.functionTextfieldLabel2')}
              id="nameString"
              name="nameString"
              variant="outlined"
              fullWidth
              placeholder={t('functions.functionTextfieldTooltipLabel')}
              sx={Styles.TextField}
              helperText={errors?.nameString?.message}
              error={!!errors?.nameString}
            />
            <Button sx={{ display: 'none' }} ref={submitRef} type="submit">
              {t('dashboard.saveLabel')}
            </Button>
          </Stack>
        )}
      </Form>
    </Dialog>
  );
};

EditFunctionDialog.propTypes = {
  fn: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onCloseCallback: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func.isRequired
};

export default observer(EditFunctionDialog);
