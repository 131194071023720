import * as logger from 'loglevel';
import cloneDeep from 'lodash/cloneDeep';
import { _headersUpload } from 'helpers/rootStoreHelper';
import qs from 'qs';
import { isNil, omitBy } from 'lodash';
import deepmerge from 'deepmerge';
import { convertUrlDecodedString } from 'helpers/Helpers';

import { createCurriculumApi } from 'pages/curriculum/api';

const drupalBaseUrl = process.env.REACT_APP_JSONAPI;
const log = logger.getLogger('LmsApi');

/**
 * Post-processing functions for certain entity types (mostly serializing)
 * Called from general patch-post function
 *
 * @type {{question_widget: (function(*): *), lesson_result: (function(*): *)}}
 */
export const convertSnapshot = {
  question_widget: function postProcessQuestionWidget(snapshot) {
    const newSnapshot = cloneDeep(snapshot);
    const attr = newSnapshot.attributes;
    delete attr.widget_data;
    const dataObj = {
      question: attr.question,
      feedback: attr.feedback,
      correct: attr.correct,
      options: []
    };
    if (attr.options) {
      dataObj.options = attr.options.map((option) => ({
        value: option.value,
        label: option.label,
        feedback: option.feedback,
        correct: option.correct
      }));
    }
    attr.widget_data = JSON.stringify(dataObj);
    Object.entries(dataObj).forEach((entry) => {
      delete attr[entry[0]];
    });
    return newSnapshot;
  },

  lesson_result: function postProcessLessonResult(snapshot) {
    const newSnapshot = cloneDeep(snapshot);
    const attr = newSnapshot.attributes;
    delete attr.data;
    const serialized = {};
    if (attr.questions) {
      serialized.questions = attr.questions;
      delete attr.questions;
    }
    if (attr.pages) {
      serialized.pages = attr.pages;
      delete attr.pages;
    }
    attr.data = JSON.stringify(serialized);
    return newSnapshot;
  }
};

function _convertNewQuestionWidgetData(questionWidget) {
  const qw = questionWidget;
  return {
    data: {
      type: 'question_widget',
      attributes: {
        title: qw.attributes.title,
        weight: qw.attributes.weight,
        widget_code: qw.attributes.widget_code,
        widget_type: qw.attributes.widget_type,
        widget_data: JSON.stringify({
          question: qw.attributes.question,
          feedback: qw.attributes.feedback,
          feedbackCorrect: qw.attributes.feedbackCorrect,
          feedbackFalse: qw.attributes.feedbackFalse,
          options: qw.attributes.options
        })
      }
    }
  };
}

/**
 * Build the include string for the course container
 * queries
 *
 * @returns {string}
 * @private
 */
function _buildCourseContainerIncludes() {
  const courses = `courses`;
  return (
    `${courses}` +
    `,${courses}.profession` +
    `,${courses}.course_category` +
    `,${courses}.course_category2` +
    `,${courses}.lesson_container` +
    `,${courses}.lesson_container.lessons` +
    `,${courses}.lesson_container.field_authors` +
    `,${courses}.lesson_container.field_authors.field_photo` +
    `,${courses}.lesson_container.field_coursephoto` +
    `,${courses}.accreditation_links`
  );
}

// eslint-disable-next-line no-shadow
export const startLmsApiClient = () => ({
  /**
   * Fetch all initially needed user data
   *
   * including:
   *  - courses
   *  - products
   *
   * @param product
   * @param _package
   * @returns {Promise<promise|*>}
   */
  // LmsApi --> fetchUserData ==> single subrequest (1 call) to ewapi product / package
  // LmsApi --> fetchUserData >> NOT NEEDED SUBREQUEST !!
  // LmsApi --> fetchUserData >> Should be a normal fetchEwapi request
  // TODO LmsApi --> fetchUserData >> todo: Fix later not now
  async fetchUserData(product, _package) {
    try {
      const response = await window.authedClient.subRequests([
        {
          requestId: 'ewapi',
          uri: `/ewapi/product/${product}/package/${_package}/json`,
          action: 'view'
        }
      ]);
      return response;
    } catch (e) {
      return e;
    }
  },

  async fetchSearchResults(searchParam, baseProductId, attemptNr) {
    try {
      const params = {
        'search_param': convertUrlDecodedString(searchParam),
        'baseproduct': baseProductId
      };
      // Add search attempt if it is not the first attempt
      if (attemptNr > 0) {
        params.attempt = attemptNr;
      }
      const searchParams = new URLSearchParams(params);
      const response = await window.authedClient.post(`ewapi/search/results?${searchParams.toString()}`);
      return response.data;
    } catch (e) {
      return e;
    }
  },

  /**
   * Fetch the special access courses for the given user
   * (based on currently logged-in user)
   *
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchSpecialAccessCourses() {
    try {
      const response = window.authedClient.get(`ewapi/special-access/overview`);
      return response;
    } catch (e) {
      return null;
    }
  },

  /**
   * Fetch the profile data for the given user uuid
   *
   * @param uuid
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchUser(uuid) {
    return window.authedClient.get('jsonapi/user', {
      params: {
        include: `user_picture,professions,roles`,
        filter: {
          'filter[userid][condition][value]': `${uuid}`,
          'filter[userid][condition][path]': 'id',
          'filter[userid][condition][operator]': '='
        }
      }
    });
  },

  /**
   * Fetches all course_container related data: (_buildCourseContainerIncludes)
   *  - courses
   *  - lesson_container
   *
   * @initiator RootStoreFetch -> fetchData
   * @param {*} id course_container uuid
   * @returns
   */
  async fetchCourseContainerData(id) {
    return window.authedClient.get(`jsonapi/course_container/${id}`, {
      params: {
        include: _buildCourseContainerIncludes()
      }
    });
  },

  /**
   * Fetch all completed course results
   *
   * @param uid
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchCourseResults(uid, params = {}) {
    const defaults = {
      filter: {
        userid: {
          condition: {
            value: uid,
            path: 'user.id',
            operator: '='
          }
        },
        completedDate: {
          condition: {
            value: '0',
            path: 'certificate',
            operator: '>'
          }
        }
      }
    };

    const merged = deepmerge(defaults, params);

    return window.authedClient.get('jsonapi/course_result', {
      params: merged
    });
  },

  /**
   * Fetch the course result for the given course id
   * (based on currently logged-in user)
   *
   * @param courseId
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchCourseResultsByCourseId(courseId) {
    return window.authedClient.get(`ewapi/course-result/course/${courseId}/json`);
  },

  /**
   * Fetch all active subscriptions for the given
   * currently logged in user
   *
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchSubscriptions() {
    const response = await window.authedClient.get(`ewapi/subscriptions/active`);
    return response.data;
  },

  /**
   * Fetch the course favorites for the given user id
   *
   * @param uid
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchCourseFavorites(uid) {
    return window.authedClient.get('jsonapi/course_favorites', {
      params: {
        include: [
          'field_favorite_courses',
          'field_favorite_courses.course_category',
          'field_favorite_courses.course_category2',
          'field_favorite_courses.lesson_container',
          'field_favorite_courses.lesson_container.field_authors',
          'field_favorite_courses.accreditation_links'
        ].join(','),
        filter: {
          condition: {
            path: 'uid.id',
            expression: '=',
            value: uid
          }
        }
      }
    });
  },

  /**
   * Fetch the banners for the given branch
   *
   * @param branch
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchBanners(productId) {
    // Initialize the params object with conditions that do not depend on productId
    const params = {
      sort: {
        'sort[sort-weight][path]': 'order_weight',
        'sort[sort-weight][direction]': 'DESC'
      },
      include: 'background_image',
      filter: {
        'filter[expire][condition][value]': new Date().toISOString(),
        'filter[expire][condition][path]': 'field_expiring_date',
        'filter[expire][condition][operator]': '>'
      }
    };

    const products = productId.split(',');
    if (products.length > 1) {
      params.filter[`filter[product][condition][operator]`] = 'IN';
      params.filter[`filter[product][condition][path]`] = 'product_banner.meta.drupal_internal__target_id';
      for (let i = 0; i < products.length; i++) {
        params.filter[`filter[product][condition][value][${i}]`] = `${products[i]}`;
      }
    } else {
      // Conditionally add the filter[product] condition if productId is not 100
      params.filter['filter[product][condition][value]'] = `${productId}`;
      params.filter['filter[product][condition][path]'] = 'product_banner.meta.drupal_internal__target_id';
      params.filter['filter[product][condition][operator]'] = 'IN';
    }

    // Make the API request with the dynamically built params object
    return window.authedClient.get('jsonapi/banner', { params });
  },

  /**
   * Fetch the users evaluation
   *
   * @param uuid
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchEvaluationByUser(uuid, cuuid) {
    return window.authedClient.get('jsonapi/evaluation', {
      params: {
        filter: {
          user_id: {
            condition: {
              value: uuid,
              path: 'uid.id',
              operator: '='
            }
          },
          course_result_uuid: {
            condition: {
              value: cuuid,
              path: 'course_result_uuid',
              operator: 'CONTAINS'
            }
          }
        }
      }
    });
  },

  /**
   * Request access to download the certificate for the given
   * course result
   *
   * @param courseResultID
   * @returns {Promise<*>}
   */
  async downloadCertificate(courseResultID) {
    const response = await window.authedClient.get(`ewapi/course-result/${courseResultID}/access-certificate`);
    return response.data.code;
  },

  /**
   * Persist course favorites to the backend
   *
   * @param favoriteCourses
   * @param user
   * @returns {Promise<AxiosResponse<{data: {relationships: {course_favorites: {data}}, id, type}}>>}
   * @constructor
   */
  async APIPersistCourseFavorites(favoriteCourses, user) {
    return window.authedClient.patch(`jsonapi/${user.type}/${user.id}`, {
      data: {
        type: user.type,
        id: user.id,
        relationships: {
          course_favorites: {
            data: favoriteCourses
          }
        }
      }
    });
  },

  /**
   * Get Courses edit data
   *
   * @param requests
   * @returns {Promise<unknown>}
   */
  async subRequest(requests) {
    return window.authedClient.subRequests(requests);
  },

  /**
   * Get Courses edit data
   *
   * @param requests
   * @returns {Promise<unknown>}
   */
  async getCourseEdit(requests) {
    return window.authedClient.subRequests(requests);
  },

  /**
   * Upload a file and attach
   * it to the given entity
   *
   * @param entity
   * @param file
   * @param field
   * @returns {Promise<AxiosResponse<*>>}
   */
  async postFile(entity, file, field) {
    const endpoint = entity.type;
    const uuid = entity.id;
    const headersUpload = _headersUpload;

    headersUpload['Content-Disposition'] = `attachment; filename="${file.file.name}"`;

    return window.authedClient.post(`jsonapi/${endpoint}/${uuid}/${field}`, file.data, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': `file; filename="${file.file.name}"`,
        'Cache-Control': 'no-cache'
      }
    });
  },

  /**
   * Upload an image and attach
   * it to the given entity
   *
   * @param entity
   * @param file
   * @param field
   * @returns {Promise<null|AxiosResponse<Buffer>>}
   */
  async postImage(entity, file, field) {
    const endpoint = entity.type;
    const uuid = entity.id;

    try {
      const data = Buffer.from(file.data, 'binary');
      return window.authedClient.post(`jsonapi/${endpoint}/${uuid}/${field}`, data, {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': `file; filename="${file.filename}"`,
          'Cache-Control': 'no-cache'
        }
      });
    } catch (e) {
      return null;
    }
  },

  /**
   * Get the lesson content entity for the given id
   *
   * @param lessonContentId
   * @returns {Promise<AxiosResponse<never>>}
   */
  async getLessonEdit(lessonContentId) {
    return window.authedClient.get(`/jsonapi/lesson_content/${lessonContentId}`, {
      params: {
        include: 'tagdoc,lesson_content_widgets'
      }
    });
  },

  /**
   * Create a new widget
   *
   * @param questionWidget
   * @param lessonContent
   * @returns {Promise<unknown>}
   */
  async createWidget(questionWidget, lessonContent) {
    const newQuestionWidget = _convertNewQuestionWidgetData(questionWidget);
    return window.authedClient.subRequests([
      {
        action: 'create',
        requestId: 'widget',
        body: `${JSON.stringify(newQuestionWidget)}`,
        uri: `jsonapi/${questionWidget.type}`
      },
      {
        action: 'update',
        requestId: 'content',
        body: `${JSON.stringify({ data: lessonContent })}`,
        uri: `jsonapi/${lessonContent.type}/${lessonContent.id}`,
        waitFor: ['widget']
      }
    ]);
  },

  /**
   * Delete the given widget
   *
   * @param questionWidget
   * @param lessonContent
   * @returns {Promise<unknown>}
   */
  async deleteWidget(questionWidget, lessonContent) {
    return window.authedClient.subRequests([
      {
        action: 'update',
        requestId: 'content',
        body: `${JSON.stringify({ data: lessonContent })}`,
        uri: `jsonapi/${lessonContent.type}/${lessonContent.id}`
      },
      {
        action: 'delete',
        requestId: 'widget',
        uri: `jsonapi/${questionWidget.type}/${questionWidget.id}`,
        waitFor: ['content']
      }
    ]);
  },

  /**
   * Delete the given post
   *
   * @param data
   * @returns {Promise<AxiosResponse<never>>}
   */
  async deletePost(data) {
    const type = data.type !== 'file' ? data.type : 'file/file';
    const url = data.id ? `${type}` : type;

    return window.authedClient.delete(`jsonapi/${url}/${data.id}`);
  },

  /**
   * Create  a new course
   *
   * @param requests
   * @returns {Promise<unknown>}
   */
  async createCourse(requests) {
    return window.authedClient.subRequests(requests);
  },

  /**
   * Create a new lesson
   *
   * @param requests
   * @param file
   * @returns {Promise<unknown>}
   */
  // eslint-disable-next-line no-unused-vars
  async createLesson(requests, file = false) {
    return window.authedClient.subRequests(requests);
  },

  /**
   * start the lesson and fetch the lesson
   * play configuration of this lesson from the backend
   *
   * @param courseId
   * @param lessonId
   * @returns {Promise<*>}
   */
  async fetchLessonPlay(courseId, lessonId) {
    try {
      const response = await window.authedClient.get(`/ewapi/course/${courseId}/lesson/${lessonId}/start`);
      return Object.assign(response.data.data, response.data.meta);
    } catch (e) {
      if (e.response?.data?.errors && e.response?.data?.errors.length > 0) {
        const { title } = e.response.data.errors[0];
        const { errorCode, depCourseId, courseTitle } = JSON.parse(title);
        if (errorCode === 'dependingCourse') {
          return { errorCode, depCourseId, courseTitle };
        }
        return title;
      }
      return e; // Handle if no errors array or empty errors array
    }
  },

  /**
   * Restart the given lesson and fetch the updated
   * lesson play config for this lesson
   *
   * @param courseId
   * @param lessonId
   * @returns {Promise<never>}
   */
  async restartLessonPlay(courseId, lessonId) {
    const response = await window.authedClient.get(`/ewapi/course/${courseId}/lesson/${lessonId}/start?restart=true`);
    return Object.assign(response.data.data, response.data.meta);
  },

  /**
   * Fetch all authors
   *
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchAuthors() {
    return window.authedClient.get('/jsonapi/author', {
      params: {
        sort: 'title',
        include: 'field_photo,professions'
      }
    });
  },

  /**
   * Fetch arbitrary jsonapi entities
   *
   * @param what
   * @param params
   * @param id
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchIt(what, params, id) {
    if (!id) {
      if (typeof params === 'string') {
        return window.authedClient.get(`jsonapi/${what}?${params}`);
      }

      return window.authedClient.get(`jsonapi/${what}`, {
        params
      });
    }

    if (typeof params === 'string') {
      return window.authedClient.get(`jsonapi/${what}/${id}?${params}`);
    }

    return window.authedClient.get(`jsonapi/${what}/${id}`, {
      params
    });
  },

  /**
   * postODT
   *
   * @param entity
   * @param file
   * @param field
   * @returns {Promise<*|null>}
   */
  async postODT(entity, file, field) {
    const endpoint = entity.type;
    const uuid = entity.id;

    const data = Buffer.from(file.data, 'binary');
    return window.authedClient.post(`jsonapi/${endpoint}/${uuid}/${field}`, data, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': `file; filename="${file.filename}"`,
        'Cache-Control': 'no-cache'
      }
    });
  },

  /**
   * Patch the given course content
   *
   * @param query
   * @returns {Promise<*>}
   */
  async patchCourse(query) {
    return window.authedClient.subRequests(query);
  },

  /**
   * Fetch all subscriptions (Max. 50)
   *
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchAllProfessions() {
    return window.authedClient.get('jsonapi/profession');
  },

  /**
   * Make a request to change your password
   *
   * @param data
   * @returns {Promise<*|AxiosResponse<*>>}
   * @constructor
   */
  async ResetPassword(data) {
    return window.authedClient.post(`${drupalBaseUrl}/ewapi/password_reset`, data);
  },

  /**
   * Finish the given lesson result
   *
   * @param nid
   * @returns {Promise<AxiosResponse<never>>}
   */
  async postFinish(nid) {
    const response = await window.authedClient.get(`ewapi/lesson_result/${nid}/finish`);
    return response.data;
  },

  /**
   * Finish the given course result
   *
   * @returns {Promise<*>}
   * @param page
   */
  async resetLessonResult(nid, page) {
    const response = await window.authedClient.get(`ewapi/lesson_result/${nid}/reset/${page}`);
    return response.data;
  },

  /**
   * Finish the given course result
   *
   * @param nid
   * @returns {Promise<*>}
   */
  async finishCourseResult(nid) {
    return window.authedClient.get(`ewapi/course_result/${nid}/finish`);
  },

  /**
   * Patch or post the given entity data
   *
   * @param data
   * @param header
   * @returns {Promise<{favorite_courses: {target_id: *, uuid: *}[], nid, uuid}|*|{nid, uuid, hidden_courses: {target_id: *, uuid: *}[]}|{nid, uuid, liked_courses: {target_id: *, uuid: *}[]}>}
   */
  async patchpost(data) {
    const method = data.id ? 'patch' : 'post';
    const { type } = data;

    let response;

    if (method === 'post') {
      const url = `jsonapi/${type}`;
      response = await window.authedClient.post(url, { data });
    } else if (method === 'patch') {
      const url = `jsonapi/${type}/${data.id}`;
      response = await window.authedClient.patch(url, { data });
    } else {
      throw new Error('Faulty request type found');
    }
    if (type === 'course_favorites') {
      return {
        nid: response.data.data.attributes.drupal_internal__nid,
        uuid: response.data.data.id,
        favorite_courses: response.data.data.relationships.field_favorite_courses.data.map((course) => ({
          target_id: course.meta.drupal_internal__target_id,
          uuid: course.id
        }))
      };
    }
    if (type === 'course_likes') {
      return {
        nid: response.data.data.attributes.drupal_internal__nid,
        uuid: response.data.data.id,
        liked_courses: response.data.data.relationships.field_liked_courses.data.map((course) => ({
          target_id: course.meta.drupal_internal__target_id,
          uuid: course.id
        }))
      };
    }
    if (type === 'hidden_courses') {
      return {
        nid: response.data.data.attributes.drupal_internal__nid,
        uuid: response.data.data.id,
        hidden_courses: response.data.data.relationships.hidden_courses.data.map((course) => ({
          target_id: course.meta.drupal_internal__target_id,
          uuid: course.id
        }))
      };
    }
    return response.data.data;
  },

  /**
   * Create a poll widget
   *
   * @param pollWidgetData
   * @returns {Promise<unknown>}
   */
  async createPollWidget(pollWidgetData) {
    return window.authedClient.subRequests([
      {
        action: 'create',
        requestId: 'pollwidget',
        body: `${JSON.stringify(pollWidgetData)}`,
        uri: 'jsonapi/poll_widget'
      }
    ]);
  },

  /**
   * Fetch poll widget by id
   *
   * @param id
   * @returns {Promise<*>}
   */
  async fetchPollWidget(id) {
    const response = await window.authedClient.get(`/ewapi/poll/${id}/data`);
    return response.data;
  },

  /**
   * Post a poll answer to the backend
   *
   * @param id
   * @param answer
   * @returns {Promise<string>}
   */
  async postPollAnswer(id, answer) {
    const response = await window.authedClient.post(`${drupalBaseUrl}/ewapi/poll/${id}/answer`, qs.stringify({ answer }), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
    return response.data;
  },

  /**
   * save a wordcloud widget to the backend
   *
   * @param wordCloudWidgetData
   * @returns {Promise<promise|*>}
   */
  async createWordCloudWidget(wordCloudWidgetData) {
    return window.authedClient.subRequests([
      {
        action: 'create',
        requestId: 'wordCloudWidget',
        body: JSON.stringify(wordCloudWidgetData),
        uri: 'jsonapi/wordcloud_widget'
      }
    ]);
  },

  /**
   * Save wordcloud question widget to the backend
   *
   * @param questionWidgetData
   * @param lessonContentData
   * @returns {Promise<unknown>}
   */
  async createWordCloudQuestionWidget({ questionWidgetData, lessonContentData }) {
    log.info('questionWidgetData =', JSON.stringify(questionWidgetData, null, 2));
    return window.authedClient.subRequests([
      {
        action: 'create',
        requestId: 'questionwidget',
        body: `${JSON.stringify(questionWidgetData)}`,
        uri: `jsonapi/question_widget`
      },
      {
        action: 'update',
        requestId: 'content',
        body: `${JSON.stringify(lessonContentData)}`,
        uri: `jsonapi/${lessonContentData.data.type}/${lessonContentData.data.id}`,
        waitFor: ['questionwidget']
      }
    ]);
  },

  /**
   * Fetch the given wordcloud by its id
   *
   * @param id
   * @returns {Promise<AxiosResponse<never>>}
   */
  async fetchWordCloud(id) {
    const response = await window.authedClient.get(`/ewapi/wordcloud/${id}/data`);
    return response.data;
  },

  /**
   * Post the wordcloud answer for the given nid
   *
   * @param nid
   * @param answer
   * @returns {Promise<never>}
   */
  async postWordCloudAnswer(nid, answer) {
    const response = await window.authedClient.post(`${drupalBaseUrl}/ewapi/wordcloud/${nid}/answer`, qs.stringify({ answer }), {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    });
    return response.data;
  },

  /**
   * Post a question for th ask a question functionality
   *
   * @param question
   * @returns {Promise<{data: *, errors: *}>}
   */
  async postAskQuestion(question) {
    const {
      askquestion: { errors, data }
    } = await window.authedClient.subRequests([
      {
        action: 'create',
        requestId: 'askquestion',
        body: `${JSON.stringify(question)}`,
        uri: `jsonapi/user_question`
      }
    ]);
    return { errors, data };
  },

  // LMS Dashboard

  /**
   * Fetch lms dashboard profession table data
   * @param {*}
   * @returns
   */
  async fetchLMSProfessions(queryParams) {
    const params = new URLSearchParams(
      omitBy(
        {
          ...queryParams
        },
        isNil
      )
    );
    const response = await window.authedClient.get(`ewapi/lms/startpage/profession?${params}`);
    return response.data;
  },

  /**
   * Fetch lms dashboard products table data
   * @param {*}
   * @returns
   */
  async fetchLMSProducts(queryParams) {
    const params = new URLSearchParams(
      omitBy(
        {
          ...queryParams
        },
        isNil
      )
    );
    const response = await window.authedClient.get(`ewapi/lms/startpage/product?${params}`);
    return response.data;
  },

  /**
   * Fetch lms dashboard overview line chart data
   * @param {*}
   * @returns
   */
  async fetchLMSChart(queryParams) {
    const params = new URLSearchParams(
      omitBy(
        {
          ...queryParams
        },
        isNil
      )
    );
    const response = await window.authedClient.get(`ewapi/lms/startpage/graph?${params}`);
    return response.data;
  },

  /**
   * Fetch a student by its nid
   *
   * @returns {Promise<{data: *, errors: *}>}
   * @param id
   */
  async fetchStudent(id) {
    const response = await window.authedClient.get(`student/${id}`);
    return response.data;
  },

  /**
   * Fetch a student by its nid
   *
   * @returns {Promise<{data: *, errors: *}>}
   * @param id
   */
  async fetchLMSStudent(id, startdate, enddate) {
    const response = await window.authedClient.get(`ewapi/lms/student/${id}?startdate=${startdate}&enddate=${enddate}`);
    return response.data;
  },

  /**
   * Fetch a course by its nid
   *
   * @returns {Promise<{data: *, errors: *}>}
   * @param id
   */
  async fetchLMSCourse(id) {
    const response = await window.authedClient.get(`ewapi/lms/course/${id}`);
    return response.data;
  },

  /**
   * Fetch a student by its nid
   *
   * @returns {Promise<{data: *, errors: *}>}
   * @param id
   */
  async fetchLMSAccess() {
    const response = await window.authedClient.get(`ewapi/lms/access`);
    return response.data;
  },

  /**
   * fetch all the course content from the ewapi endpoint
   *
   * @param product
   * @param _package
   * @returns {Promise<*>}
   */
  async fetchEwapiCourses(product, _package) {
    const response = await window.authedClient.get(`ewapi/product/${product}/package/${_package}/json`);
    return response.data;
  },
  //
  async fetchAdminMenu(label) {
    const response = await window.authedClient.get(`ewapi/editor-package-list/0/${label}/json`);
    return response.data;
  },

  // Curriculum (e.g. Portfolio)
  ...createCurriculumApi()
});
