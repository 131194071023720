export const sxInfoCard = (theme) => ({
  margin: '0px',
  display: 'flex',
  flex: '1',
  background: '#fafafa',
  cursor: 'pointer',
  color: '#717171',
  textTransform: 'uppercase',
  minWidth: '100px',
  borderRadius: '10px',
  boxShadow: 'none',
  transition: 'all .5s',
  // '&:hover': { background: '#006847b8', color: 'white', boxShadow: 'rgb(0 0 0 / 34%) 2px 1px 9px 0px', transition: 'all .5s' }
  '&:hover': {
    background: theme.palette.secondary.main,
    color: 'white',
    boxShadow: 'rgb(0 0 0 / 34%) 2px 1px 9px 0px',
    transition: 'all .5s'
  }
});

export const sxInfoCardPointer = { '&:hover': sxInfoCard['&:hover'], ...{ cursor: 'pointer' } };
export const sxInfoCardFavo = {
  '&:hover': { ...sxInfoCard['&:hover'], ...{ background: 'white', color: '#717171' } }
};
