import { createRouterState } from 'mobx-state-router';
import { ThemeBrandTitle } from 'helpers/Helpers';
import { checkUserSignedIn } from 'state/RoutesActions';

export const homeRoute = {
  name: 'home',
  pattern: '/',
  meta: { title: `Home | ${ThemeBrandTitle}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    // check if user is logged is EwiseUser and has no preferred baseproduct
    if (store.isEwiseUser && !store.hasPreferredBaseproduct) {
      return createRouterState('selectProduct', { params: { user: store.login.userName } });
    }
    // Get User Courses from ewapi if not already done
    try {
      if (store.state !== 'done') await checkUserSignedIn(fromState, toState, routerStore);
    } catch (err) {
      return createRouterState({ routeName: 'login' });
    }
    // redirect to course overview if user has courses
    if (store.courses) {
      const productName = store.baseProduct.unique_name;
      return createRouterState('courseOverview', {
        params: { product: store.selectedProductId.toString(), productName, packageId: store.selectedPackageId.toString() }
      });
    }
    // redirect to special access if user has special access but no courses
    if (!store.courses && store.login.hasSpecialAccess) {
      return createRouterState('specialAccess', {
        params: {}
      });
    }
    // redirect to profile if user has no courses and no special access
    return createRouterState('profile', {
      params: {}
    });
  }
};
