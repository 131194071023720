import { ThemeBrandTitle } from 'helpers/Helpers';
import { createEditStore } from 'state/RoutesActions';

// route for viewing courses
export const viewCoursesRoute = {
  name: 'adminViewCourses',
  pattern: '/admin/courses',
  title: `View Courses | ${ThemeBrandTitle()}`,
  meta: { title: `View Courses | ${ThemeBrandTitle()}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    createEditStore(fromState, toState, routerStore);
    const { store } = routerStore.options;
    // get localstorage saved dashboard preferences
    const isSaved = localStorage.getItem('prefDashboard');
    //
    const prefDashboard = isSaved ? JSON.parse(isSaved) : false;
    if (prefDashboard) {
      const routerParams = {
        params: { package: prefDashboard.package, packageNid: prefDashboard.packageNid, containerNid: prefDashboard.containerNid }
      };
      return store.routerStore.goTo('adminDashboardCourses', routerParams);
    }
    return null;
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    store.courseEdit.setStoreValue('drawerOpen', true);
  }
};
