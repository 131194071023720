import { createRouterState } from 'mobx-state-router';
import { checkUserSignedIn } from 'state/RoutesActions';
import { ThemeBrandTitle } from 'helpers/Helpers';

export const colophonRoute = {
  name: 'colophonOverview',
  meta: { title: `%%colophon.title%% | ${ThemeBrandTitle()}` },
  pattern: '/:productName/:product/:packageId/colophon',
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { params } = toState;
    // Check if the user is LTI and trying to reach this page
    if (store.getLtiSession() === 1 || store.getLtiSession() === '1') {
      return createRouterState('errorPage');
    }
    try {
      if (store.hasSubscriptions === 'init') await checkUserSignedIn(fromState, toState, routerStore, params.product);
    } catch (e) {
      return e;
    }
    return null;
  }
};
