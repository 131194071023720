/* eslint-disable camelcase */
import { types as t, flow, getParent } from 'mobx-state-tree';
import { Course } from 'state/modelsEwapi/Course';

export const CourseResult = t.model('CourseResult', {
  id: t.identifierNumber,
  uuid: t.string,
  course_id: t.maybe(t.reference(Course)),
  created: t.number,
  changed: t.number,
  completed: t.number,
  certificate: t.number,
  has_evaluation: t.maybeNull(t.number)
});

export const CourseResults = t
  .model({
    courseResults: t.maybeNull(t.array(CourseResult))
  })

  .views((self) => ({
    getFinishedCourses() {
      return self.courseResults.filter((courses) => courses.certificate !== 0);
    },
    getStartedCourses() {
      return self.courseResults.filter((courses) => courses.certificate === 0);
    },
    getShownCourses(courseIds) {
      return self.getStartedCourses().filter((course) => !courseIds.includes(course.course_id?.course_uuid));
    },
    isCourseStarted(id) {
      const startedCourses = self.getStartedCourses();
      const started = startedCourses.length > 0 ? self.getStartedCourses().map((course) => course.course_id?.course_id) : [];
      return started.includes(id);
    },
    isCourseFinished(id) {
      const finishedCourses = self.getFinishedCourses();
      const finished = finishedCourses.length > 0 ? self.getFinishedCourses().map((course) => course.course_id?.course_id) : [];
      return finished.includes(id);
    },
    findCourseResultByNid(nid) {
      return self.courseResults.find((courseResult) => courseResult.id === nid);
    },
    findCourseResultByCourseNid(nid) {
      return self.courseResults.find((courseResult) => courseResult.course_id?.course_id === nid);
    }
  }))
  .actions((self) => ({
    setHiddenCourses(courseIds) {
      self.hiddenCourses = courseIds;
      return self.hiddenCourses;
    },
    setShowHidden(bool) {
      self.showHidden = bool;
      return self.showHidden;
    },
    updateCourseResultbyNid: flow(function* updateCourseResultbyNid(nid) {
      try {
        const { data: response } = yield getParent(self, 1).lmsApi.fetchCourseResultsByCourseId(nid);
        const { course_results, lesson_results } = response.data;
        const updatedCourseResult = course_results[0];

        if (!updatedCourseResult) {
          return null;
        }
        const updatedLessonResult = lesson_results;
        const index = self.courseResults.findIndex((courseResult) => courseResult.id === updatedCourseResult.id);
        if (index !== -1) {
          self.courseResults[index] = updatedCourseResult;
        } else if (!Array.isArray(updatedCourseResult)) {
          self.courseResults.push(updatedCourseResult);
        }
        getParent(self, 1).lesson_results.updateLessonResults(updatedLessonResult);
        return response;
      } catch (e) {
        return e;
      }
    })
  }));
