import PropTypes from 'prop-types';
import { Store } from 'state/ContextStore';
import { useContext } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
// MATERIAL UI
import { Box, Avatar, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// ICONS
import LinkIcon from '@mui/icons-material/Link';
// import LinkOffIcon from '@mui/icons-material/LinkOff';
// import MoveDownIcon from '@mui/icons-material/MoveDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

/*
The component is used to provide users with a menu of advanced course actions, 
allowing them to select an action and potentially perform operations on courses based on their selection.
*/
const AdvancedCourseActions = ({ handleCopyActions, copyValues, defaultValues }) => {
  const store = useContext(Store);
  const { isMarketing } = store;
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();

  /**
   * "selAction": {
      "title": "Selecteer actie",
      "copy": "Copy",
      "paste": "Paste",
      "1": {
        "title": "Hergebruik",
        "info": "Maak een kopie van alleen de cursus. Je kan de o.a punten, titel, catagorieën en geldigheid aanpassen maar niet de les inhoud.",
        "toast": "Kopie van de cursus is als draft versie opgeslagen en kan herbruikt worden."
      },
      "2": {
        "title": "Dupliceren",
        "info": "Maak een kopie van de volledige cursus inclusief lessen en widgets. Je kan alles aanpassen.",
        "toast": "Duplicaat van de volledige cursus is als draft opgeslagen en kan worden aangepast"
      },
      "3": {
        "title": "Link",
        "info": "Link naar een cursus uit een ander pakket. Er kan niks aangepast worden",
        "toast": "In het huidige pakket is een link naar een cursus uit een ander pakket toegevoegd"
      }
    },
   */

  const defaultActionMenu = [
    {
      id: 3,
      name: t('copyDialog.selAction.3.title'),
      value: 3,
      class: 'duplicate',
      color: { background: '#93c4dc', color: 'white' },
      icon: <LinkIcon />,
      title: t('copyDialog.selAction.3.title'),
      info: t('copyDialog.selAction.3.info')
    },
    {
      id: 1,
      name: t('copyDialog.selAction.1.title'),
      value: 1,
      class: 'link',
      color: { background: 'green', color: 'white' },
      icon: <ContentCopyIcon />,
      title: t('copyDialog.selAction.1.title', 'Linken'),
      info: t('copyDialog.selAction.1.info', 'Vanuit proefpakket een cursus linken')
    },
    {
      id: 2,
      name: t('copyDialog.selAction.2.title'),
      value: 2,
      class: 'duplicate',
      color: { background: 'red', color: 'white' },
      icon: <AutoAwesomeMotionIcon />,
      title: t('copyDialog.selAction.2.title', 'Dupliceren'),
      info: t('copyDialog.selAction.2.info', 'Er wordt een complete kopie gemaakt van de cursus en kan alles aanpassen')
    }
  ];
  const marketingActionMenu = [
    {
      id: 3,
      name: t('copyDialog.selAction.3.title'),
      value: 3,
      class: 'duplicate',
      color: { background: '#93c4dc', color: 'white' },
      icon: <LinkIcon />,
      title: t('copyDialog.selAction.3.title'),
      info: t('copyDialog.selAction.3.info')
    }
  ];

  const actionMenu = isMarketing ? marketingActionMenu : defaultActionMenu;

  const isActive = (action) => (copyValues.action === action ? 'isActive' : '');

  const sxLiActive = {
    '&.isActive': {
      background: theme.palette.primary.light,
      ' p': { color: 'white!important' }
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        <List className="advanced_course_action">
          {actionMenu.map((item) => (
            <Box key={item.id}>
              <ListItem sx={sxLiActive} className={isActive(item.name)} key={item.id} disablePadding>
                <ListItemButton onClick={() => handleCopyActions({ ...defaultValues, action: item.name, actionId: item.id })}>
                  <ListItemIcon>
                    <Avatar sx={item.color} variant="square">
                      {item.icon}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary={item.title} secondary={item.info} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Box>
          ))}
        </List>
      </nav>
    </Box>
  );
};

AdvancedCourseActions.propTypes = {
  handleCopyActions: PropTypes.func,
  copyValues: PropTypes.object,
  defaultValues: PropTypes.object
};

export default observer(AdvancedCourseActions);
