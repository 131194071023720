/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
/* MUI */
import { Box, Grid, Divider } from '@mui/material';
/* CUSTOM components */
import NotFound from 'pages/misc/NotFound';
import { InfoCards, MyDraftCourses, DashboardTable, SectionTitle, ListEndingCourses, BtnNewCourse } from '../components/dashboard';
import NewCourseDialog from '../components/dialog/NewCourseDialog';
import LoadingContent from '../components/loading/LoadingContent';
import BtnShowSidebar from '../components/layout/BtnShowSidebar';
/* HOOKS */
import useEwapi from '../hooks/useEwapi';
/* SCSS */
import './Dashboard.scss';

/*
  In summary, the Dashboard component serves as an administrative dashboard for managing courses. 
  It retrieves and displays course data, provides options to filter and add new courses, 
  and integrates with the store to display notifications when necessary.
*/
const Dashboard = () => {
  const store = useContext(Store);
  const { courseEdit } = store;
  const { t } = useTranslation('adminCourses');
  const [open, setOpen] = useState(false);
  const ewapiCourseList = `editor-course-list/${store.params.containerNid}/0/json`;
  const { response, loading, error } = useEwapi(ewapiCourseList, 'courses');
  //
  const showSidebar = courseEdit.showSidebarDashboard;
  const setDisplay = showSidebar ? 'flex' : 'none';
  // OPEN/CLOSE NEW COURSE DIALOG
  const handleNewCourseModal = () => setOpen(!open);

  useEffect(() => {
    if (!response) return;
    if (response?.data.result?.message) {
      const toastMsg = { content: response.data.result.message, title: 'Information', type: 'error', timeOut: 6000, variant: 'filled' };
      store.courseEdit.setToasts(toastMsg);
    }
  }, [store.courseEdit, response, response?.result]);

  // waiting response from ewapi
  if (loading) return <LoadingContent loading={loading} />;
  if (error) return <NotFound />;

  // RESPONSE FROM EWAPI
  const { courses, result } = response.data;
  if (!loading && result) {
    courseEdit.setStoreValue('selectedContainer', result);
  }
  /* FILTERING  */
  const tableData = courses.map((course) => ({ ...course, id: course.course_nid }));
  const draftCourses = tableData.filter((item) => item.course_status === 0);
  const onlineCourses = tableData.filter((item) => item.course_online === 1);
  const linkedCourses = tableData.filter((item) => item.course_online === 1 && item.course_container.length > 0);

  return (
    <Box sx={sxBoxMain} className="draft-view admin-custom-page">
      <InfoCards courses={courses} />
      <Grid container spacing={2} sx={{ margin: '0', width: '100%', flex: '1', position: 'relative' }}>
        <BtnShowSidebar />
        <Grid sx={{ paddingTop: '0px!important' }} item xs={12} sm={12} md={showSidebar ? 8 : 12} xl={showSidebar ? 9 : 12} container>
          <Box sx={{ width: `100%` }}>
            <Box className="draft_header" sx={sxJustifyContent}>
              <SectionTitle title={`${t('dashboard.draftCourses')}: ${draftCourses.length}`} />
              <BtnNewCourse handleNewCourseModal={handleNewCourseModal} />
            </Box>
            <MyDraftCourses courses={draftCourses} />
            <DashboardTable courses={linkedCourses} />
          </Box>
        </Grid>

        <Grid sx={{ paddingTop: '0px!important', display: setDisplay }} item xs={12} sm={12} md={4} xl={3} container>
          <Box sx={sxBoxEnding}>
            <Box>
              <ListEndingCourses courses={onlineCourses} />
            </Box>
            <Divider />
          </Box>
        </Grid>
      </Grid>

      <NewCourseDialog open={open} handleNewCourseModal={handleNewCourseModal} />
    </Box>
  );
};

export default observer(Dashboard);

const sxBoxMain = {
  paddingRight: '24px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  'table thead': { textTransform: 'capitalize' }
};
const sxJustifyContent = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0px',
  borderRadius: '5px 5px 0 0 ',
  background: 'white',
  padding: '15px'
};
const sxBoxEnding = {
  position: 'relative',
  width: `100%`,
  background: 'white',
  borderRadius: '10px',
  padding: '10px',
  boxShadow: '-4px 2px 7px #0000001a',
  zIndex: 10
};
