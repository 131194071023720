export const rating = [{ name: 'uitstekend' }, { name: 'goed' }, { name: 'voldoende' }, { name: 'matig' }, { name: 'slecht' }];
export const numberRating = [
  { name: '10' },
  { name: '9' },
  { name: '8' },
  { name: '7' },
  { name: '6' },
  { name: '5' },
  { name: '4' },
  { name: '3' },
  { name: '2' },
  { name: '1' }
];
export const hoursSelect = [
  { name: '8 uur of meer', value: '8_uur_of_meer' },
  { name: '15 min', value: 'kwartier' },
  { name: 'half uur', value: 'half_uur' },
  { name: '1 uur', value: '1_uur' },
  { name: '1,5 uur', value: '1,5_uur' },
  { name: '2 uur', value: '2_uur' },
  { name: '2,5 uur', value: '2,5_uur' },
  { name: '3 uur', value: '3_uur' },
  { name: '3,5 uur', value: '3,5_uur' },
  { name: '4 uur', value: '4_uur' },
  { name: '4,5 uur', value: '4,5_uur' },
  { name: '5 uur', value: '5_uur' },
  { name: '5,5 uur', value: '5,5_uur' },
  { name: '6 uur', value: '6_uur' },
  { name: '6,5 uur', value: '6,5_uur' },
  { name: '7 uur', value: '7_uur' },
  { name: '7,5 uur', value: '7,5_uur' }
];
