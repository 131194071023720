import { SvgIcon } from '@mui/material';

const Quiz = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 26" width="21" height="26">
    <path d="M20.3464 20.6247C20.6655 20.4645 20.905 20.1439 20.905 19.7431V6.91941C20.905 6.17136 20.2933 5.55694 19.5486 5.55694H15.3462C14.3622 2.35106 11.3567 0 7.84597 0C3.51088 0 0 3.52661 0 7.88123C0 11.488 2.42033 14.5335 5.71825 15.4686V22.0942C5.71825 23.9108 7.1811 25.3802 8.98957 25.3802H19.9207C20.3729 25.3802 20.7452 25.0863 20.8517 24.6321C20.958 24.2046 20.7454 23.7505 20.3463 23.5367C19.7877 23.2429 19.4421 22.7086 19.4421 22.0673C19.4952 21.4531 19.8144 20.9188 20.3463 20.6249L20.3464 20.6247ZM1.59584 7.88119C1.59584 4.40805 4.41502 1.60291 7.84602 1.60291C11.3036 1.60291 14.0962 4.43477 14.0962 7.88119C14.096 11.3277 11.3035 14.1595 7.84602 14.1595C4.38842 14.1595 1.59584 11.3542 1.59584 7.88119ZM17.8197 23.4566H8.98967C8.24497 23.4566 7.63329 22.8422 7.63329 22.0942C7.63329 21.3461 8.24497 20.7317 8.98967 20.7317H17.8197C17.6602 21.1592 17.5537 21.6133 17.5537 22.0942C17.5537 22.575 17.6602 23.0291 17.8197 23.4566Z" />
    <path d="M8.98936 5.63742C9.14891 5.95795 9.12238 6.33199 8.93612 6.59919C8.72332 6.89308 8.53725 7.02669 8.29776 7.18695C7.73913 7.58761 7.26049 8.0151 7.1542 9.19065C7.1542 9.32427 7.18073 9.45789 7.28722 9.56466C7.36699 9.67145 7.50002 9.72493 7.63303 9.72493H8.32449C8.56382 9.72493 8.77661 9.53783 8.80314 9.27078C8.82967 8.78999 8.93616 8.73651 9.25525 8.52273C9.52129 8.33563 9.89362 8.06859 10.266 7.58758C10.8246 6.81291 10.9043 5.77098 10.4788 4.91597C10.0001 3.98082 9.04265 3.41992 7.9254 3.41992C6.03696 3.41992 5.18596 4.78239 5.02643 5.9579C4.9999 6.09152 5.05296 6.22513 5.13274 6.33191C5.23904 6.4387 5.37206 6.49218 5.50508 6.49218H6.16999C6.40932 6.49218 6.59558 6.33191 6.64863 6.09152C6.72841 5.61072 7.02098 5.04959 7.92522 5.04959C8.59051 5.02294 8.88307 5.39696 8.98936 5.63734V5.63742Z" />
    <path d="M8.96335 11.3791C8.96335 11.9251 8.52274 12.3677 7.97933 12.3677C7.43576 12.3677 6.99512 11.9251 6.99512 11.3791C6.99512 10.8333 7.43573 10.3906 7.97933 10.3906C8.52271 10.3906 8.96335 10.8332 8.96335 11.3791Z" />{' '}
  </SvgIcon>
);

export default Quiz;
