import MainLayout from 'pages/mainLayout/MainLayout';
import ColophonOverview from './ColophonOverview';

const colophonViewMap = {
  colophonOverview: (
    <MainLayout footer title="colophon.title" subtitle="">
      <ColophonOverview />
    </MainLayout>
  )
};

export default colophonViewMap;
