/* eslint-disable jsx-a11y/media-has-caption */
/* Core */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useScrollPosition, useStore } from 'hooks';
import { RouterLink } from 'mobx-state-router';

/* MUI */
import { Typography, Avatar, SvgIcon, CardMedia } from '@mui/material';
import Box from '@mui/material/Box';
import PodcastsIcon from 'components/icons/PodcastsIcon';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Information from 'components/icons/Information';
import AuthorIcon from 'components/icons/AuthorIcon';
import Goals from 'components/icons/goals';
import CloudOffIcon from '@mui/icons-material/CloudOff';

/* Custom components */
import { TabComponent } from 'components';
import ReactPlayer from 'react-player';
import Video from 'components/widgets/Video';
import { rawHTML, embedVideo } from 'helpers/Helpers';
// eslint-disable-next-line import/no-extraneous-dependencies
import ScrollSpy from 'react-ui-scrollspy';
import RelatedCourses from './RelatedCourses';
import NonTrialCourseAlert from './NonTrialCourseAlert';
import CourseAccrEndDate from './CourseAccrEndDate';

const CourseIntroductionTabs = (props) => {
  const store = useStore();
  const { t } = useTranslation();

  const { authors, goals, description, data, category, video, podcast, courseId, reviewers, endDate } = props;
  const { product, productName, packageId } = store.params;

  const tab = useRef(null);

  const goToScroll = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(e.currentTarget.href?.split('#')[1]);

    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const position = useScrollPosition();

  const previewVideo = video && embedVideo(video);
  const categoryCourses = store?.courses?.isSpecialAccessView ? false : store?.courses?.coursesByCategory('course')[category]?.length > 1;
  const initialPosition = position === 0 && tab.current && tab.current.getBoundingClientRect().top;
  const isUrlCorrect = ReactPlayer.canPlay(previewVideo);
  const isNonTrialCourse = (store.isTrialProduct && store.trialCourses && !store.trialCourseIds.includes(courseId)) || false;

  const isSticky = () => {
    let sticky = false;
    if (position > tab.current?.getBoundingClientRect().bottom) {
      sticky = true;
      if (initialPosition) {
        sticky = false;
      }
    }
    return sticky;
  };

  const VideoError = () => (
    <Box sx={{ textAlign: 'center' }}>
      <CloudOffIcon sx={{ fontSize: 60, color: 'silver' }} />
      <Box>{t('course.tabs.videoError', 'Something went wrong loading the video')}</Box>
    </Box>
  );

  const tabItems = [
    {
      id: 'Information',
      label: (
        <Box className="tab_label" alignItems="center">
          <SvgIcon component={Information} />
          {t('course.tabs.information')}
        </Box>
      ),
      callback: goToScroll
    },
    podcast && {
      id: 'Podcast',
      label: (
        <Box className="tab_label">
          <PodcastsIcon />
          {t('course.tabs.podcast')}
        </Box>
      ),
      callback: goToScroll
    },
    previewVideo && {
      id: 'Preview',
      label: (
        <Box className="tab_label">
          <PlayCircleOutlineIcon />
          {t('course.tabs.preview')}
        </Box>
      ),
      callback: goToScroll
    },
    goals && {
      id: 'Goals',
      label: (
        <Box className="tab_label">
          <SvgIcon component={Goals} />
          {t('course.tabs.goals')}
        </Box>
      ),
      callback: goToScroll
    },
    authors &&
      authors.length && {
        id: 'Authors',
        label: (
          <Box className="tab_label">
            <SvgIcon component={AuthorIcon} />
            {t('course.tabs.author')}
          </Box>
        ),
        callback: goToScroll
      },
    reviewers &&
      reviewers.length && {
        id: 'Reviewers',
        label: (
          <Box className="tab_label">
            <SvgIcon component={AuthorIcon} />
            {t('course.tabs.reviewers')}
          </Box>
        ),
        callback: goToScroll
      }
  ];

  return (
    <Box className="content">
      <Box
        id="content_tabs"
        sx={{
          [`.MuiButtonBase-root`]: { backgroundColor: 'background.main', color: 'tabPrimaryBlue.main' },
          [`.MuiButtonBase-root:hover`]: { backgroundColor: '#00000017' },
          [`.MuiButtonBase-root.active-scroll-spy`]: { backgroundColor: 'primary.main', [`.MuiBadge-badge`]: { color: 'primary.main' } }
        }}
        className={isSticky() && 'sticky_content'}
        ref={tab}
      >
        <TabComponent variant="scrollable" selectedTab={false} orientation="horizontal" tabs={tabItems} scrollButtons="auto" />
      </Box>
      {isNonTrialCourse && <NonTrialCourseAlert />}
      <Box className="the_content">
        <ScrollSpy>
          {description && (
            <Box className="course_description" sx={{ li: { '&::marker': { color: 'primary.main' } } }} id="Information">
              {rawHTML(description)}
            </Box>
          )}
          {store.showCourseAccrEndDate && <CourseAccrEndDate endDate={endDate} />}

          {podcast && (
            <Box className="course_podcast" id="Podcast">
              <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
                {t('course.tabs.podcast')}
              </Typography>
              <Typography color="primaryBlue.main" fontSize="12px">
                {data?.attributes?.podcast_title}
              </Typography>
              <Box className="podcast_holder">
                <div className="hide_title" />
                {rawHTML(podcast)}
              </Box>
            </Box>
          )}
          {previewVideo && (
            <Box className="course_preview" id="Preview">
              <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
                {t('course.tabs.preview')}
              </Typography>
              {!isUrlCorrect && <VideoError />}
              {isUrlCorrect && (
                <Box className="video-wrapper">
                  <Video className="video-player" url={previewVideo} width="100%" height="100%" />
                </Box>
              )}
            </Box>
          )}
          {goals && (
            <Box className="course_goals" component="div" id="Goals" sx={{ li: { '&::marker': { color: 'primary.main' } } }}>
              <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
                {t('course.tabs.goals')}
              </Typography>
              {rawHTML(goals)}
            </Box>
          )}
          {authors && authors.length > 0 && (
            <Box className="course_overview_quickview_authors" component="div" id="Authors">
              <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
                {t('course.tabs.author')}
              </Typography>
              {authors &&
                authors.length > 0 &&
                authors.map((author) => {
                  const authorDetail = store.authors.getAuthorByUuid(author.uuid);
                  const { id, fullName, img, bio, disclosure } = authorDetail;

                  return (
                    <Box
                      key={id}
                      sx={{
                        display: {
                          xs: 'block',
                          lg: 'flex'
                        },
                        gap: 2
                      }}
                    >
                      <RouterLink routeName="teacherDetail" params={{ product, productName, packageId, teacherId: author.uuid }}>
                        {img ? (
                          <CardMedia component="img" loading="lazy" image={img} sx={{ width: 160, height: 210 }} />
                        ) : (
                          <Avatar data-testid="author-avatar" variant="rounded" sx={{ width: 160, height: 210 }} />
                        )}
                      </RouterLink>
                      <Box
                        sx={{
                          mt: {
                            xs: 2,
                            lg: 0
                          }
                        }}
                      >
                        <RouterLink
                          routeName="teacherDetail"
                          params={{ product, productName, packageId, teacherId: author.uuid }}
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant="body2" component="h3" fontSize="20px" color="primaryBlue.main" fontWeight="500">
                            {fullName}
                          </Typography>
                        </RouterLink>
                        <Typography component="div" fontSize="18px">
                          {bio}
                        </Typography>
                        {disclosure && (
                          <>
                            <Typography variant="h4" component="h4" fontSize="18px" color="text.secondary" fontStyle="italic">
                              Disclosure
                            </Typography>
                            <Typography variant="body2" fontStyle="italic" color="text.secondary">
                              {disclosure}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          )}
          {reviewers && reviewers.length > 0 && (
            <Box className="course_overview_quickview_authors" component="div" id="Reviewers">
              <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
                {t('course.tabs.reviewers')}
              </Typography>
              {reviewers &&
                reviewers.length > 0 &&
                reviewers.map((author) => {
                  const authorDetail = store.authors.getAuthorByUuid(author.uuid);
                  const { id, fullName, img, bio } = authorDetail;

                  return (
                    <Box key={id} display="flex" gap="19px">
                      <Box>
                        <Avatar
                          variant="rounded"
                          alt={author.title}
                          src={img || 'https://i.pravatar.cc/300?img=2'}
                          sx={{ width: 160, height: 210 }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" component="h3" fontSize="20px" color="primaryBlue.main" fontWeight="500">
                          {fullName}
                        </Typography>
                        <Typography variant="div" component="div" fontSize="18px">
                          {bio}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          )}
        </ScrollSpy>

        {categoryCourses && (
          <Box>
            <Typography variant="h1" color="primaryBlue.main" fontSize="24px">
              {t('introduction.related')}
            </Typography>
            <RelatedCourses category={category} courseId={courseId} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

CourseIntroductionTabs.propTypes = {
  authors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  reviewers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  goals: PropTypes.any,
  description: PropTypes.any,
  category: PropTypes.string,
  video: PropTypes.any,
  podcast: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  courseId: PropTypes.number,
  endDate: PropTypes.string
};

export default CourseIntroductionTabs;
