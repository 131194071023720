import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Store } from 'state/ContextStore';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    minWidth: 375,
    minHeight: 375
  },
  load: {
    textAlign: 'center'
  }
});

// renders a CircularProgress while the children are being loaded
const Load = ({ state }) => {
  const store = useContext(Store);
  const classes = useStyles();

  if (state === 'error') {
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        <div>{store.error && `${store.error.name} : ${store.error.message} status: ${store.error.status}`}</div>
      </div>
    );
  }
  return (
    <div className={`${classes.root} ${classes.center}`}>
      <Card className={`${classes.card} ${classes.center}`}>
        <div className={classes.load}>
          <span>
            <b>Retrieving the pages....</b>
          </span>
          <div />
          <p />
          <span>
            <CircularProgress />
          </span>
        </div>
      </Card>
    </div>
  );
};

Load.propTypes = {
  state: PropTypes.string
};

export default Load;
