import { useContext, useState, useEffect } from 'react';
import { Store } from 'state/ContextStore';

// This code uses the useEwapi hook to fetch information about a product or package.
// It also sets the value of loading to true while the fetch is in progress.

const useEwapi = (product = 0, _package = 0) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const store = useContext(Store);
  const { pattern } = store.routerStore.getCurrentRoute();
  const isAdmin = pattern.includes('/admin/');

  useEffect(() => {
    const doFetch = async () => {
      setLoading(true);
      const urlCourses = `${process.env.REACT_APP_JSONAPI}/ewapi/product/${product}/package/${_package}/json`;
      const urlSelectProduct = `${process.env.REACT_APP_JSONAPI}/ewapi/products/json`;
      const url = store.isEwiseUser ? urlSelectProduct : urlCourses;
      try {
        const res = await window.authedClient.get(url);

        setResponse(res.data);
        // if (isAdmin) store.courseEdit.setStoreValue('ewapi', json.data);
        // if (!isAdmin) store.setStoreValue('ewapi', json.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    doFetch();
  }, [_package, isAdmin, product, store]);
  return { response, error, loading };
};
export default useEwapi;
