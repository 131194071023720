import { useCustomTheme } from '../../hooks';

/* Our logo */
export const SecondaryLogo = () => {
  const { labelLogo, customLogo } = useCustomTheme();

  if (!customLogo) {
    return null;
  }

  return <img src={labelLogo} alt="logo" className="logo" id="logo" style={{ display: 'block', margin: '30px auto' }} />;
};

/* Our logo props */
