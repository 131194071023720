import { useStore } from 'hooks';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const UploadButton = () => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm');
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [pdfTitle, setPdfTitle] = useState('');

  const url = `ewapi/editor-get-answers-pdf/json?courseUuid=${store.courseEdit.currentCourse.id}`;

  const toast = {
    success: { title: 'Success', content: 'Upload succesvol', type: 'success' },
    error: { title: 'Error', content: 'Er is iets mis gegaan', type: 'error' },
    default: { timeOut: 4000, variant: 'filled' }
  };

  useEffect(() => {
    (async () => {
      const { data } = await window.authedClient.post(url);
      if (data?.data?.result?.filename) {
        setPdfTitle(data?.data?.result?.filename);
      }
      setLoading(false);
    })();
  }, [url]);

  const handleDeletePdf = async () => {
    setUploading(true);
    try {
      const deleteUrl = `ewapi/editor-update-answers-pdf/json?courseUuid=${store.params.courseuuid}`;
      const response = await window.authedClient.post(deleteUrl);
      if (!response?.data?.data?.result?.filename) {
        setPdfTitle('');
      }
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
    } catch (error) {
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
    }
    setUploading(false);
  };

  const handleFileUpload = async (event) => {
    setUploading(true);
    const file = event.target.files[0];
    try {
      const upload = await window.authedClient.post('jsonapi/media/free_upload/field_media_file', file, {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': `file; filename="${file.name}"`,
          'Cache-Control': 'no-cache'
        }
      });

      const uploadUrl = `ewapi/editor-update-answers-pdf/json?courseUuid=${store.params.courseuuid}&fileUuid=${upload.data.data.id}`;
      const response = await window.authedClient.post(uploadUrl);
      if (response?.data?.data?.result?.filename) {
        setPdfTitle(response?.data?.data?.result?.filename);
      }
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
    } catch (error) {
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
    }
    setUploading(false);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Box sx={{ textAlign: 'center' }}>
      {uploading && <Box sx={{ fontSize: '13px', minHeight: '30px', marginBottom: '10px', textAlign: 'left' }}>uploading pdf ...</Box>}
      {!uploading && (
        <Box sx={{ fontSize: '13px', minHeight: '30px', marginBottom: '10px', textAlign: 'left', position: 'relative' }}>
          {(pdfTitle && (
            <Box sx={{ position: 'relative' }}>
              {pdfTitle}
              <IconButton
                sx={{ position: 'absolute', right: '0', top: '0' }}
                aria-label="delete"
                size="small"
                color="error"
                onClick={() => {
                  handleDeletePdf();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )) ||
            'Geen pdf aanwezig'}
        </Box>
      )}

      <InputLabel htmlFor="upload">
        <Button size="small" variant="outlined" component="span" disabled={loading || uploading}>
          {t('sidebar.uploadPdf')}
        </Button>
      </InputLabel>
      <input type="file" accept=".pdf" style={{ display: 'none' }} id="upload" onChange={handleFileUpload} />
    </Box>
  );
};

export default UploadButton;
