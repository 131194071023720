import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';

import { useStore } from 'hooks';

const MenuItemLogout = ({ handleMenuSelect }) => {
  const store = useStore();
  const { t } = useTranslation();

  const routeName = store.login.isImpersonation === true ? 'switchback' : 'logout';
  const title = store.login.isImpersonation === true ? t('Switchback') : t('Logout');

  return (
    <MenuItem id="logout" onClick={handleMenuSelect({ routeName })}>
      {title}
    </MenuItem>
  );
};

MenuItemLogout.propTypes = {
  handleMenuSelect: PropTypes.func
};

export default observer(MenuItemLogout);
