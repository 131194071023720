import { destroy, types as t } from 'mobx-state-tree';
import { Author } from './Author';
import { File, Profession } from './BaseModel';

export const AuthorEdit = t
  .model('AuthorEdit', {
    authors: t.maybeNull(t.array(Author)),
    photos: t.maybeNull(t.array(File)),
    professions: t.maybeNull(t.array(Profession))
  })
  .actions((self) => ({
    setNext(href) {
      const url = new URL(href);
      self.next = url.search;
    },

    update(newEntity, key = 'photos') {
      if (self[key] && self[key].length >= 0) {
        const existingEntity = self[key].find((entity) => entity.id === newEntity.id);
        if (existingEntity) {
          destroy(existingEntity);
          self[key].push(newEntity);
        } else if (!existingEntity) {
          self[key].push(newEntity);
        }
      } else {
        self[key] = newEntity;
      }
    }
  }));
