import { types as t } from 'mobx-state-tree';
import { RelProduct } from './Product';
import { RelContract } from './Contract';
import { RelUser } from './User';
import { DateRange, Body } from './BaseModel';

export const Subscription = t
  .model('Subscription', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__nid: t.number,
      langcode: t.maybeNull(t.string),
      status: t.boolean,
      title: t.string,
      body: t.maybeNull(Body),
      created: t.string,
      changed: t.string,
      default_langcode: t.maybeNull(t.string),
      subscription_status: t.maybeNull(t.string),
      contract: t.maybeNull(t.string),
      guid: t.maybeNull(t.string),
      maximum_points: t.number,
      valid_period: DateRange
    }),
    relationships: t.model({
      product: RelProduct,
      student: RelUser,
      contract: t.maybeNull(RelContract)
    })
  })
  .views((self) => ({
    get product() {
      if (self.relationships.product && self.relationships.product.data) {
        return self.relationships.product.data.id;
      }
      return null;
    },
    get contract() {
      if (self.relationships.contract && self.relationships.contract.data) {
        return self.relationships.contract.data.id;
      }
      return null;
    }
  }));

export const RelSubscription = t.model('RelSubscription', {
  data: t.maybeNull(
    t.model({
      id: t.reference(Subscription),
      type: t.string
    })
  )
});

export const RelSubscriptions = t.model('RelSubscription', {
  data: t.maybeNull(
    t.array(
      t.model({
        id: t.reference(Subscription),
        type: t.string
      })
    )
  )
});
