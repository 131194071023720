import { useStore } from 'hooks';

export const useLearningGoals = () => {
  const store = useStore();

  const learningGoals = store.curriculumLearningGoals ?? [];
  const learningGoalActivities = store.curriculumLearningGoalActivities ?? [];
  const learningGoalEvaluations = store.curriculumEvaluations ?? [];

  const learningGoalsWithActivities = learningGoals.map((goal) => {
    const activityIds = goal.relationships?.activities?.data.map((a) => a.id);
    const evaluationIds = goal.relationships?.evaluations?.data.map((e) => e.id);

    const activities = learningGoalActivities?.filter((a) => activityIds.includes(a.id));
    const evaluations = learningGoalEvaluations?.filter((e) => evaluationIds.includes(e.id));

    return {
      ...goal,
      activities,
      evaluations
    };
  });

  return {
    learningGoals,
    learningGoalActivities,
    learningGoalEvaluations,
    learningGoalsWithActivities
  };
};
