import DialogTitleBase from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

const DialogTitle = styled(DialogTitleBase)(() => ({
  fontWeight: 'bold',
  color: '#2B3674',
  fontSize: '20px'
}));

export default DialogTitle;
