/* Core */
import { useContext, useEffect, useState } from 'react';
import { useRouterStore } from 'mobx-state-router';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';

/* MUI */
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import 'theme/faq/Faq.scss';

/* Custom components */
import SearchBar from './components/SearchBar';

const Faq3 = () => {
  const store = useContext(Store);
  const routerStore = useRouterStore();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    store.fetchFaqByLabel('ewise').then(() => {
      setLoaded(true);
    });
  }, [store, store.routerState.routeName]);

  const handleClick = () => {
    routerStore.goTo('article');
  };
  return (
    <>
      {loaded && (
        <Grid container className="faq_main2">
          <SearchBar />
          <Grid container style={{ margin: '0% auto', maxWidth: '1200px' }}>
            <Grid item style={{}}>
              {store.groupByCategory().map((faq, index) => (
                <Card style={{ flexBasis: '30%', marginBottom: '2%', boxShadow: 'none', border: 'none' }} key={index}>
                  <CardHeader title={faq.field_faq_categorie} style={{ fontWeight: '800', textTransform: 'capitalize', padding: '0px' }} />
                  <CardContent>
                    {faq.items.map((item) => (
                      <List key={item.nid}>
                        <ListItem alignItems="flex-start" className="question_buttons" button onClick={handleClick}>
                          <ListItemText primary={item.title} />
                        </ListItem>
                        <Divider variant="inset" component="li" style={{ margin: '0px', marginBottom: '0px' }} />
                      </List>
                    ))}
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default observer(Faq3);
