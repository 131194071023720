import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

// TODO: Only used in customTable.If we decide to not use it remove from components
/*
In summary, the DeleteDialog component is a customizable confirmation dialog for deleting a record. 
It provides a clear and user-friendly way to confirm or cancel the delete action. 
It also allows for styling customization to fit the application's design.
*/
const DeleteDialog = ({ numberOfRecords, deleteOpen, handleDeleteDialogClose, handleDeleteRecord }) => {
  const { t } = useTranslation('datagrid');

  const text = {
    btnText: t('deleteModal.btnText'),
    title: t('deleteModal.title', { count: numberOfRecords }),
    content: t('deleteModal.content', { count: numberOfRecords }),
    closeBtn: t('deleteModal.closeBtn'),
    actionBtn: t('deleteModal.actionBtn')
  };

  return (
    <div>
      <Dialog
        // sx={{ borderRadius: '16px' }}
        sx={{
          '& .MuiPaper-root': { borderRadius: '16px', minWidth: '500px !important' },
          '& .MuiDialogTitle-root': {
            fontSize: '20px',
            textAlign: 'left !important',
            padding: '0 0 8px 0'
          },
          '& .MuiDialogContent-root': {
            padding: '0 0 20px 0'
          },
          '& .MuiDialogActions-root': {
            padding: '0 0 8px 0',
            paddingLeft: '0px',
            paddingRight: '0px !important'
          }
        }}
        open={deleteOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle
          sx={{
            textAlign: 'left'
          }}
          id="delete-dialog-title"
        >
          {text.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">{text.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>{text.closeBtn}</Button>
          <Button
            onClick={handleDeleteRecord}
            sx={{
              backgroundColor: '#006848',
              color: '#FFFFFF',
              borderRadius: '8px',
              '&:hover': {
                color: '#006848'
              }
            }}
            autoFocus
          >
            {text.actionBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteDialog.propTypes = {
  numberOfRecords: PropTypes.number,
  deleteOpen: PropTypes.bool,
  handleDeleteDialogClose: PropTypes.func,
  handleDeleteRecord: PropTypes.func
};

export default DeleteDialog;
