import { observer } from 'mobx-react';
// import { useContext } from 'react';
// import { Store } from 'state/ContextStore';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
/* MUI */
import { Box, FormControlLabel, Checkbox, Tooltip } from '@mui/material';

const CheckboxEnableEdit = ({ linked, overRideEdit, handleOverRideEdit }) => {
  const { t } = useTranslation('editCourseForm');
  if (!linked) return null;
  // const store = useContext(Store);
  return (
    <Box component="span" sx={{ padding: '0px 10px' }}>
      <Tooltip title={t('editCourse.checkboxOverRide.tooltip')} placement="bottom" arrow>
        <FormControlLabel
          control={
            <Checkbox color="primary" checked={overRideEdit} onChange={handleOverRideEdit} inputProps={{ 'aria-label': 'controlled' }} />
          }
          label={t('editCourse.checkboxOverRide.label')}
          labelPlacement="start"
          sx={{ color: '#505055' }}
        />
      </Tooltip>
    </Box>
  );
};

CheckboxEnableEdit.propTypes = {
  overRideEdit: PropTypes.bool,
  handleOverRideEdit: PropTypes.func,
  linked: PropTypes.bool
};

export default observer(CheckboxEnableEdit);
