/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useState, useEffect, useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { Typography, Box, CardContent } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const InfoCardFavo = () => {
  const store = useContext(Store);
  const [prefDashboard, setPrefDashboard] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem('prefDashboard');
    const initialValue = JSON.parse(saved);
    return initialValue || '';
  });
  const isPrefDashboard = isEqual(prefDashboard, store.params);
  //
  const sxPrefDashboard = { fontSize: 40, color: !isPrefDashboard && '#e8e8e8' };
  const handleClickFavoDashboard = () => {
    setPrefDashboard(isPrefDashboard ? '' : store.params);
  };

  useEffect(() => {
    // storing pref dshboard in localstorage
    localStorage.setItem('prefDashboard', JSON.stringify(prefDashboard));
  }, [prefDashboard]);

  return (
    <Box onClick={() => handleClickFavoDashboard()}>
      <CardContent>
        <Typography sx={{ fontSize: 13 }} align="center" color="text.error" gutterBottom>
          Dashboard
        </Typography>
        <Typography variant="h5" component="div" align="center">
          <StarIcon sx={sxPrefDashboard} color={isPrefDashboard ? 'primary' : ''} />
        </Typography>
      </CardContent>
    </Box>
  );
};

export default observer(InfoCardFavo);
