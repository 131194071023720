import PropTypes from 'prop-types';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { observer } from 'mobx-react';

const TeacherSearch = ({ defaultValue = '', onSubmit, onChange, placeholder }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof onSubmit === 'function') {
      const { searchInput } = Object.fromEntries(new FormData(e.target).entries());
      onSubmit(searchInput);
    }
  };
  const handleChange = (e) => {
    if (typeof onChange === 'function') {
      const { value } = e.target;
      onChange(value);
    }
  };

  return (
    <Box className="teacherSearch" component="form" autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        type="search"
        name="searchInput"
        placeholder={placeholder}
        onChange={handleChange}
        defaultValue={defaultValue}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        sx={{ '& .MuiOutlinedInput-root': { backgroundColor: 'background.main' } }}
      />
      <Button type="submit" variant="contained" color="primary" aria-label="search button">
        <SearchIcon />
      </Button>
    </Box>
  );
};

export default observer(TeacherSearch);

TeacherSearch.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string
};
