import { TableCell, Button, Popover } from '@mui/material';
import { Icon } from '@iconify/react';
import { PropTypes } from 'prop-types';
import { useState } from 'react';

/*
In summary, the CustomCell component creates a table cell with a button that, when clicked, displays custom content in a popover. 
It's a flexible and reusable component for creating interactive tables or grids with popovers.
*/
const CustomCell = ({ sx, children, ...rest }) => {
  const [acl, setAcl] = useState(null);
  return (
    <TableCell sx={{ py: 1, borderTop: '1px solid #EEEEEE', lineHeight: '2', ...sx }} {...rest}>
      <Button
        sx={{ minWidth: '2rem', borderRadius: '1rem' }}
        onClick={(e) => {
          setAcl(e.currentTarget);
        }}
      >
        <Icon icon="entypo:dots-three-horizontal" />
      </Button>

      <Popover
        open={!!acl}
        anchorEl={acl}
        onClose={() => {
          setAcl(null);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        {children}
      </Popover>
    </TableCell>
  );
};

export default CustomCell;

CustomCell.propTypes = {
  sx: PropTypes.any,
  children: PropTypes.any
};
