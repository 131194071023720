import { observer } from 'mobx-react';
import { Box, CircularProgress } from '@mui/material';
import { useStore } from 'hooks';
import CourseTable from 'pages/search/components/Grid/CourseTable/CourseTable';

/**
 * SearchOverview component
 *
 * The SearchOverview component is responsible for displaying courses based on search results.
 * It includes a filter bar and switches between grid and table views based on screen size.
 */
const SearchOverview = () => {
  const boxClassName = `course_overview_box search`;

  return (
    <Box className="course_overview search">
      <Box className={boxClassName}>
        <Box component="main" className="course_overview_filterbar2">
          <Box display="flex">
            <Box className="course_overview_wrap_filter">
              <SearchCourseViewAll />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(SearchOverview);

/**
 * Spinner component
 *
 * Renders a loading spinner centered on the screen.
 */
const Spinner = () => (
  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '85vh' }}>
    <CircularProgress color="primary" style={{ width: '65px', height: '65px' }} />
  </Box>
);

/**
 * SearchCourseViewAll component
 *
 * Renders either a grid or table view of search results based on screen size.
 * Uses MobX state to manage loading and responsive behavior.
 */
const SearchCourseViewAll = observer(() => {
  const store = useStore();

  if (store.searchingCourses === true) {
    return <Spinner />;
  }

  return (
    <div className="course_overview_grid">
      <CourseTable />
    </div>
  );
});
