/* Core */
import PropTypes from 'prop-types';
import { useState } from 'react';

/* Custom components */
import { List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// renders a list of items
const ListComponent = (props) => {
  const { items, expand, selected } = props;
  const [open, setOpen] = useState(expand);

  const handleChange = () => () => {
    setOpen(!open);
  };

  return (
    <List>
      {items.map((item, index) => {
        if (item?.showMenuItem === false) return null;
        return (
          <div key={index}>
            <ListItemButton key={item.id} onClick={item.nestedTabs ? handleChange() : item.callback} selected={item.id === selected}>
              <ListItemText primary={item.label} />
              {item.nestedTabs && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            <Collapse in={open}>
              <List>
                {item.nestedTabs &&
                  item.nestedTabs.map((tabs) => (
                    <ListItemButton key={tabs.id} onClick={tabs.callback}>
                      <ListItemText primary={tabs.label} />
                    </ListItemButton>
                  ))}
              </List>
            </Collapse>
          </div>
        );
      })}
    </List>
  );
};

ListComponent.propTypes = {
  items: PropTypes.array,
  expand: PropTypes.bool,
  selected: PropTypes.string
};

export default ListComponent;
