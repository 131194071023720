import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Stack, Button } from '@mui/material';

import { Form } from 'components/form';
import { Dialog } from 'pages/curriculum/components';
import { useStore } from 'hooks';
import ActivitiesFormPart from './ActivitiesFormPart';

const AddOrEditActivityModal = ({ visible, onCloseCallback, onSubmitCallback, learningGoal, activity = null }) => {
  const { t } = useTranslation('curriculum');
  const store = useStore();
  const submitRef = useRef();

  const title = activity ? t('learningGoals.editActivityTitle') : t('learningGoals.addActivityTitle');
  const buttonLabel = t('dashboard.saveLabel');
  const icon = activity ? null : 'ant-design:plus-outlined';
  const name = title.replace(' ', '-').toLowerCase();

  const initialValues = {
    activityDropDown: '',
    activityName: activity?.attributes?.name || '',
    activityExplanation: activity?.attributes?.explanation || ''
  };

  const validationSchema = Yup.object().shape(
    {
      activityDropDown: Yup.string().when('activityName', {
        is: (val) => val === '',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      activityName: Yup.string().when('activityDropDown', {
        is: (val) => val === '',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      activityExplanation: Yup.string().required()
    },
    [['activityDropDown', 'activityName']]
  );

  const handleSubmit = async ({ activityDropDown, activityName, activityExplanation }) => {
    let result = null;

    const data = {
      name: activityDropDown?.length > 0 ? activityDropDown : activityName,
      explanation: activityExplanation
    };

    store.startLoadCurriculum();
    if (activity?.id) {
      result = await store.updateCurriculumLearningGoalActivity({
        ...activity,
        attributes: { ...activity.attributes, ...data }
      });
    } else {
      result = await store.createCurriculumLearningGoalActivity(learningGoal, data);
    }
    store.stopLoadCurriculum();

    if (!result) {
      return {
        success: false,
        errors: [{ name: 'root.serverError', error: { type: 'server', message: 'Internal Server Error' }, options: {} }]
      };
    }

    if (result.errors) {
      return {
        success: false,
        errors: result.errors
      };
    }

    onSubmitCallback();

    return { success: true };
  };

  const handleClose = () => {
    onCloseCallback();
  };

  return (
    <Dialog
      action={{
        action: () => {
          submitRef.current.click();
        },
        name: buttonLabel,
        isLoading: store.isLoadingCurriculum,
        icon
      }}
      title={title}
      name={name}
      visible={visible}
      onClose={handleClose}
    >
      <Form id="add-learning-goal" onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ control, errors, setValue }) => (
          <Stack justifyContent="space-between" sx={{ paddingBlock: '8px' }} spacing={2}>
            <ActivitiesFormPart control={control} errors={errors} setValue={setValue} />

            <Button sx={{ display: 'none' }} ref={submitRef} type="submit">
              Save
            </Button>
          </Stack>
        )}
      </Form>
    </Dialog>
  );
};

AddOrEditActivityModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCloseCallback: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func.isRequired,
  learningGoal: PropTypes.object,
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      explanation: PropTypes.string
    })
  })
};

AddOrEditActivityModal.defaultProp = {
  activity: null
};

export default observer(AddOrEditActivityModal);
