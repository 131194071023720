import React from 'react';
import MainLayout from 'pages/mainLayout/MainLayout';
import { Lazy } from 'components';
import LmsLayout from '../LmsLayout';
import LmsCoursesLayout from './LmsCoursesLayout';

const LmsCourses = React.lazy(() => import('./LmsCourses'));
const LmsCoursesByStatus = React.lazy(() => import('./LmsCoursesByStatus'));

const lmsCoursesViewMap = {
  LmsCourses: (
    <MainLayout title="lms.navbar.courses" subtitle="">
      <LmsLayout>
        <LmsCoursesLayout>
          <Lazy noLayout>
            <LmsCourses />
          </Lazy>
        </LmsCoursesLayout>
      </LmsLayout>
    </MainLayout>
  ),
  LmsCoursesCompleted: (
    <MainLayout title="lms.navbar.completedCourses" subtitle="">
      <LmsLayout>
        <LmsCoursesLayout>
          <Lazy noLayout>
            <LmsCoursesByStatus status="completed" />
          </Lazy>
        </LmsCoursesLayout>
      </LmsLayout>
    </MainLayout>
  ),
  LmsCoursesOpenList: (
    <MainLayout title="lms.navbar.openCourses" subtitle="">
      <LmsLayout>
        <LmsCoursesLayout>
          <Lazy noLayout>
            <LmsCoursesByStatus status="open" />
          </Lazy>
        </LmsCoursesLayout>
      </LmsLayout>
    </MainLayout>
  )
};

export default lmsCoursesViewMap;
