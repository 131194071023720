import { ThemeBrandTitle } from 'helpers/Helpers';
import { createEditStore } from 'state/RoutesActions';

export const adminDashboardViewRoute = {
  name: 'adminDashboardCourses',
  pattern: '/admin/:package/:packageNid/:containerNid/dashboard',
  title: `Dashboard Courses | ${ThemeBrandTitle()}`,
  meta: { title: `Dashboard Courses | ${ThemeBrandTitle()}` },
  beforeEnter: createEditStore,
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { params } = toState;
    const { courseEdit } = store;
    courseEdit.setStoreValue('drawerOpen', true);
    courseEdit.fetchCourseContainer(params.containerNid);
  }
};
