import { observer } from 'mobx-react';
import { useRef } from 'react';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton, Popover, Tooltip } from '@mui/material';

import { useHeaderContext } from '../context';
import Notifications from './Notifications';

const NotificationsItem = () => {
  const notificationRef = useRef(null);

  const { anchorNotification, handleCloseNotification, handleNotification } = useHeaderContext();

  return (
    <>
      <Tooltip title="Notifications" arrow>
        <IconButton id="simple-popover" aria-label="add to favorites" onClick={handleNotification} sx={{ background: 'primary' }}>
          <NotificationsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="simple-popover"
        open={Boolean(anchorNotification)}
        anchorEl={anchorNotification}
        onClose={handleCloseNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        placement="bottom"
        disableScrollLock
        style={{ borderRadius: '20px' }}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
          arrow: {
            enabled: true,
            element: notificationRef
          }
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            borderRadius: '20px'
          }
        }}
      >
        <span className="arrow" ref={notificationRef} />
        <Notifications />
      </Popover>
    </>
  );
};

NotificationsItem.propTypes = {};

export default observer(NotificationsItem);
