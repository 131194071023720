import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
/* MUI */
import { Box, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const BtnDownloadAsCsv = ({ data }) => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm');

  const { course, lessonContainer } = data();

  // Check if the course is on label E-WISE
  const isEwise = store?.label === 'ewise';
  if (!isEwise) return null;

  // Get the course id and the target group
  const courseNid = store?.courseEdit?.currentCourse?.attributes?.drupal_internal__nid || '';
  const targetGroup = store?.courseEdit?.courseContainer?.relationships?.profession?.data?.attributes?.name || '';

  // return the function group
  const functionGroup = (params) => {
    const isLeidingGevend = params.toLowerCase().includes('schoolleider') || params.toLowerCase().includes('leidinggevend');
    return isLeidingGevend ? 'Leidinggevend' : 'Lesgevend';
  };

  // return the education type
  const educationType = (type) => {
    if (type.toLowerCase().includes('po')) return 'PO';
    if (type.toLowerCase().includes('school')) return 'PO';
    if (type.toLowerCase().includes('begeleider')) return 'PO';
    if (type.toLowerCase().includes('vo')) return 'VO';
    if (type.toLowerCase().includes('mbo')) return 'MBO';
    if (type.toLowerCase().includes('kind')) return 'KO';
    if (type.toLowerCase().includes('pedag')) return 'KO';
    return '';
  };
  // Create the csv data
  const createCsvData = () => {
    const csvData = {
      courseid: courseNid,
      coursename: `"${course?.attributes?.title || ''}"`,
      extralabel: `"${lessonContainer?.attributes?.field_extra_label || ''}"`,
      url: `https://learn.e-wise.nl/to-course/${courseNid}`,
      points: course?.attributes?.field_points,
      authors: `"${lessonContainer?.relationships?.field_authors?.map((author) => author.title).join(', ') || ''}"`,
      category: `"${course?.relationships?.course_category?.map((cat) => cat.title).join(', ')}"`,
      targetgroup: targetGroup,
      summary: `"${lessonContainer?.attributes?.field_information.replace(/(\r\n|\n|\r|\t)/gm, '')}"`,
      goals: `"${lessonContainer?.attributes?.field_goals.replace(/(\r\n|\n|\r|\t)/gm, '')}"`,
      image: `<img typeof='foaf:Image' class='img-responsive' src='${lessonContainer?.relationships?.field_course_image_extern}' width='720' height='540' alt='' />`,
      registers: `"${course?.relationships?.course_category2?.map((reg) => reg.title).join(', ')}"`,
      expected: course?.attributes?.expected,
      courseonline: !!course.attributes.online,
      dateoffline: course?.attributes?.field_valid_period?.end_value?.split('-').reverse().join('-') || '',
      functiongroup: functionGroup(store.params.package),
      educationtype: educationType(store.params.package)
    };
    const headers = Object.keys(csvData);
    const rows = Object.values(csvData);
    const csv = [headers.join(','), rows.join(',')].join('\n');
    return csv;
  };
  const createCsvFile = () => {
    try {
      const csv = createCsvData();
      const csvBlob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(csvBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `cursus_${courseNid}.csv`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      store.courseEdit.setToasts({
        title: t('editCourse.btnSaveAsCsv.error.title'),
        content: t('editCourse.btnSaveAsCsv.error.message'),
        type: 'error',
        timeOut: 6000
      });
      console.error('Error creating csv file', error);
    }
  };
  return (
    <Tooltip title={t('editCourse.btnSaveAsCsv.tooltip')} arrow>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }} onClick={() => createCsvFile()}>
        <DownloadIcon color="primary" size="large" /> {t('editCourse.btnSaveAsCsv.btnTitle')}
      </Box>
    </Tooltip>
  );
};

BtnDownloadAsCsv.propTypes = {
  data: PropTypes.func
};

export default observer(BtnDownloadAsCsv);
