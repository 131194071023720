import { Box, Typography, Avatar, Button, CardMedia } from '@mui/material';
import { useBreakpoint, useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useRouterStore } from 'mobx-state-router';
import CourseList from './components/CourseList';

const TeacherDetail = () => {
  const router = useRouterStore();
  const store = useStore();
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  const [showMore, setShowMore] = useState(null);
  const [clamp, setClamp] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    if (isMobile && ref.current && ref.current.clientHeight > 200) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [isMobile]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { teacherId } = router.routerState.params;
  const teacher = store.authors.getAuthorByUuid(teacherId);
  if (!teacher) return <div>something went wrong </div>; // TODO: add error page (404
  const { id, title, fullName, img, bio, disclosure } = teacher;

  const teacherCourseList = store.courses.getAuthorCourseList(id);

  return (
    <Box className="teacherDetail">
      <Box className="imageBox">
        <Box>
          {img ? (
            <CardMedia
              sx={{ width: '200px' }}
              className="rounded-lg ms:rounded-2xl h-full mx-auto"
              component="img"
              loading="lazy"
              image={img}
              alt={title}
            />
          ) : (
            <Avatar
              data-testid="author-avatar"
              variant="rounded"
              sx={{ maxWidth: '200px', borderRadius: '1rem', width: '170px', height: '200px' }}
            />
          )}
        </Box>

        <Box ref={ref}>
          <Typography variant="h1" component="h2" id="courseOverviewTitle" color="primaryBlue.main" fontSize="19px" marginBottom="10px">
            {fullName}
          </Typography>
          <Typography data-testid="author-bio" component="div" color="primaryBlue.main" className={showMore && clamp ? 'clamp' : ''}>
            {bio}
          </Typography>
          {showMore && (
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                onClick={() => {
                  setClamp(!clamp);
                }}
                endIcon={!clamp ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
                sx={{ textTransform: 'capitalize' }}
              >
                {clamp ? t('teachers.showMore', 'Show more') : t('teachers.showLess', 'Show less')}
              </Button>
            </Box>
          )}
          {disclosure && (
            <>
              <Typography variant="h4" component="h4" fontSize="18px" color="text.secondary" fontStyle="italic">
                Disclosure
              </Typography>
              <Typography variant="body2" fontStyle="italic" color="text.secondary">
                {disclosure}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      {teacherCourseList.length !== 0 && (
        <Box className="teacherCourse">
          <Typography variant="h1" component="h1" fontSize="24px" color="primaryBlue.main">
            {t('teachers.coursesBy')} {title}
          </Typography>
          <CourseList courseList={teacherCourseList} />
        </Box>
      )}
    </Box>
  );
};

export default TeacherDetail;
