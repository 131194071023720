/* eslint-disable camelcase */
import { flow, applySnapshot } from 'mobx-state-tree';
import * as logger from 'loglevel';
import { buildLessonPages } from 'pages/play/PageParsing';
import { WordCloudItem, WordCloudList } from './WordCloud';

const log = logger.getLogger('LessonPlay');
log.setDefaultLevel('info');

export const wordCloudLessonPlayViews = (lessonplay) => ({
  getWordCloud(widgetCode) {
    return lessonplay.wordCloudList?.getWordCloud(widgetCode);
  }
});

export const wordCloudLessonPlayActions = (lessonPlay) => ({
  createWordCloudWidget(wordCloudWidget) {
    const newWidget = WordCloudItem.create(wordCloudWidget);

    lessonPlay.wordCloudList = WordCloudList.create({ widgets: [newWidget] });
  },
  fetchWordCloud: flow(function* fetchWordCloud(widgetCode) {
    const questionWidget = lessonPlay.question_widgets.find((w) => w.attributes.widget_code.toLowerCase() === widgetCode.toLowerCase());
    if (!questionWidget) {
      return;
    }
    const widget = yield lessonPlay.lmsApi.fetchWordCloud(questionWidget.attributes.widget_link);

    const wordCloud = {
      ...widget,
      widgetCode
    };
    if (lessonPlay.wordCloudList) {
      lessonPlay.wordCloudList.add(wordCloud);
    } else {
      lessonPlay.createWordCloudWidget(wordCloud);
    }
  }),
  addWordCloudWidget: flow(function* addWordCloudWidget(values) {
    const { title, question, show_fields, input_required, widget_code } = values.attributes;
    // Does the widget exist in lessonplay
    const existingWidgetIndex = lessonPlay.question_widgets.findIndex((qw) => qw.id === values.id);

    let widgetLinkId = values.relationships.widget_link?.data?.id;
    let wordCloudWidget;

    try {
      if (existingWidgetIndex >= 0) {
        const patchWidget = {
          id: values.id,
          type: values.type,
          attributes: {
            title: values.attributes.title,
            weight: values.attributes.weight,
            widget_code: values.attributes.widget_code,
            widget_type: values.attributes.widget_type
          },
          relationships: {
            widget_link: {
              data: {
                type: 'wordcloud_widget',
                id: values.relationships.widget_link.data.id,
                meta: {
                  target_type: 'node'
                }
              }
            },
            lesson_content_link: values.relationships.lesson_content_link
          }
        };
        const wordCloudWidgetDataEdit = {
          nid: values.attributes.widget_link,
          widgetCode: widget_code,
          wordcloud: {
            body: question,
            input_required: input_required ? 1 : 0,
            internal_title: title,
            profession: null,
            show_fields,
            user_has_answered: 0,
            words: []
          }
        };
        const existingWordCloudIndex = lessonPlay.wordCloudList.widgets.findIndex((widget) => widget.widgetCode === widget_code);
        applySnapshot(lessonPlay.question_widgets[existingWidgetIndex], patchWidget);
        applySnapshot(lessonPlay.wordCloudList.widgets[existingWordCloudIndex], wordCloudWidgetDataEdit);
        yield lessonPlay.lmsApi.patchpost(patchWidget);
      }

      if (existingWidgetIndex < 0) {
        if (!values.relationships.widget_link.data) {
          // create word could widget
          const newWordCloudWidget = {
            data: {
              type: 'wordcloud_widget',
              attributes: {
                title,
                body: {
                  value: question
                },
                input_required,
                show_fields
              }
            }
          };
          const wordCloud = yield lessonPlay.lmsApi.createWordCloudWidget(newWordCloudWidget);
          wordCloudWidget = wordCloud.wordCloudWidget;
          widgetLinkId = wordCloudWidget.data.id;
        }
        const questionWidgetData = {
          data: {
            type: 'question_widget',
            attributes: {
              title,
              weight: 0,
              widget_code,
              widget_data: null,
              widget_type: 'Wordcloud Widget'
            },
            relationships: {
              widget_link: {
                data: {
                  type: 'wordcloud_widget',
                  id: widgetLinkId,
                  meta: {
                    target_type: 'node'
                  }
                }
              }
            }
          }
        };

        // Create a new question_widget of type wordcloud_widget and linked to the wordcloud widget

        const {
          id,
          type,
          attributes: { drupal_internal__id, title: lessonTitle }
        } = lessonPlay.lesson_content;
        const lesson_content_widgets = {
          data: [
            ...lessonPlay.question_widgets.map((qw) => ({ id: qw.id, type: qw.type })),
            { type: 'question_widget', id: '{{questionwidget.body@$.data.id}}' }
          ]
        };
        const lessonContentData = {
          data: { id, type, attributes: { drupal_internal__id, title: lessonTitle }, relationships: { lesson_content_widgets } }
        };
        const response = yield lessonPlay.lmsApi.createWordCloudQuestionWidget({ questionWidgetData, lessonContentData });
        const inputRequired = input_required ? 1 : 0;
        const { questionwidget } = response;
        const wordCloudWidgetData = {
          nid:
            wordCloudWidget?.data.attributes.drupal_internal__nid ||
            questionwidget.data.relationships.widget_link.data.meta.drupal_internal__target_id,
          widgetCode: widget_code,
          wordcloud: {
            body: wordCloudWidget?.data.attributes.body.value || question,
            input_required: wordCloudWidget?.data.attributes.input_required ? 1 : inputRequired,
            internal_title: wordCloudWidget?.data.attributes.title || title,
            profession: null,
            show_fields: wordCloudWidget?.data.attributes.show_fields || show_fields,
            user_has_answered: 0,
            words: []
          }
        };
        if (lessonPlay.wordCloudList) {
          lessonPlay.wordCloudList.add(wordCloudWidgetData);
        } else {
          lessonPlay.createWordCloudWidget(wordCloudWidgetData);
        }

        lessonPlay.question_widgets.push({ ...questionWidgetData.data, ...response.questionwidget.data });

        lessonPlay.pageList = buildLessonPages(lessonPlay);
        lessonPlay.reload++;
      }
    } catch (e) {
      log.error(e);
    }
  }),
  getDefaultValueLinkedWordCloud(control) {
    const pollLink = control._formValues.attributes?.widget_link;

    let iniValue = [];
    if (pollLink) {
      const relatedPoll = lessonPlay.wordCloudList?.widgets.find((w) => w.nid === pollLink);
      const internalTitle = relatedPoll?.wordcloud.internal_title;
      iniValue = internalTitle ? [{ attributes: { title: internalTitle } }] : [];
    }
    return iniValue;
  }
});
