/* Core */
import { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';

/* MUI */
import { Card, CardContent, CardHeader, Grid, Box } from '@mui/material';
import 'theme/faq/Faq.scss';

/* Custom components */
import { AccordionComponent } from 'components';
import SearchBar from './components/SearchBar';

const Faq = () => {
  const store = useContext(Store);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    store.fetchFaqByLabel('ewise').then(() => {
      setLoaded(true);
    });
  }, [store, store.routerState.routeName]);

  return (
    <>
      {loaded && (
        <Grid container className="faq_main">
          <SearchBar />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} columns={{ xs: 12, sm: 8, md: 12 }} className="faq_category_section">
              {store.groupByCategory().map((faq, index) => (
                <Grid item xs={12} sm={4} md={4} key={index} style={{ paddingLeft: '0px' }}>
                  <Card className="faq_card">
                    <CardHeader title={faq.field_faq_categorie} className="faq_card_header" />
                    <CardContent>
                      {faq.items.map((item) => (
                        <AccordionComponent title={item.title} body={item.body} open={false} key={item.nid} />
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default observer(Faq);
