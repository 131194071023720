import { renderToString } from 'react-dom/server';

export const createHtml = (store, theme) => {
  const { courses, packages, params, label } = store;
  // show symbols for these packages in the array
  const SHOW_SYMBOLS = ['fysiotherapeut'];
  // set course items
  const labelImage = getLogo(label);
  const labelDomain = getDomain(label);
  const overviewTitle = store.selectedPackageTitle;
  const releaseDate = getReleaseDate();
  const showSymbols = SHOW_SYMBOLS.includes(overviewTitle.toLowerCase());
  const accrLogo = renderToString(accreditationPartners(store));
  const advisoryBoard = getAdvisoryBoard(store?.profession?.advisory_board, store);
  const legendaItems = getLegendaItems(store.categories2);
  // get currentPackage
  const currentPackage = packages.find((pkg) => pkg.nid.toString() === params.packageId);
  // ! create courses by category
  const coursesByCategory = getCoursesByCategory(courses);
  // ! create html items for html output
  const htmlCourses = createHTMLCourses(coursesByCategory, currentPackage, showSymbols, store);
  const htmlAccr = `<div class="category accreditations">${accrLogo}</div>`;
  const htmlAdvBoard = advisoryBoard ? `<div class="advisory_board">${advisoryBoard}</div>` : '';
  const htmlLegenda = showSymbols ? `<div class="legenda">${legendaItems}</div>` : '';
  // ! create html output
  const htmlCourseOverview = `${htmlCourses}${htmlAccr}${htmlAdvBoard}${htmlLegenda}`;
  // ! create html document
  const htmlDocument = createHtmlDoc(htmlCourseOverview, theme, labelImage, overviewTitle, labelDomain, releaseDate);
  // ! ========== OPEN NEW WINDOW AND WRITE HTML ================
  const newTab = window.open(``, '_blank');
  newTab.document.write(htmlDocument);
  newTab.document.close();
};

const accreditationPartners = (store) => {
  const currentDomain = store.label;
  const selectedPackageTitle = store.selectedPackageTitle?.toLowerCase() || '';
  const selectedProduct = store?.baseProduct?.title?.toLowerCase();

  return (
    <div>
      {(currentDomain === 'po' || currentDomain === 'pe') && selectedPackageTitle === 'advocatuur' && (
        <div>
          <img src="/ewise/assets/images/accreditations/adv-logoreeks-hrz.jpg" style={{ width: '270px' }} alt="" />
        </div>
      )}
      {currentDomain === 'po' && selectedPackageTitle === 'archief' && (
        <div>
          <img src="/ewise/assets/images/accreditations/adv-logoreeks-hrz.jpg" style={{ width: '270px' }} alt="" />
        </div>
      )}
      {(currentDomain === 'po' || currentDomain === 'pe') && selectedPackageTitle === 'notariaat' && (
        <div>
          <img src="/ewise/assets/images/accreditations/logo_knb_1.svg" style={{ width: '120px' }} alt="" />
        </div>
      )}
      {(currentDomain === 'po' || currentDomain === 'pe') && selectedPackageTitle === 'fiscaliteit' && (
        <div>
          <img src="/ewise/assets/images/accreditations/rb-logo-homepage-2014.png" style={{ width: '100px' }} alt="" />
        </div>
      )}
      {currentDomain === 'pe' &&
        (selectedPackageTitle === 'accountancy' || selectedPackageTitle === 'archief') &&
        selectedProduct !== 'notaris' && (
          <div>
            <img src="/ewise/assets/images/accreditations/rb-logo-homepage-2014.png" style={{ width: '120px' }} alt="" />
          </div>
        )}
      {currentDomain === 'pe' && selectedPackageTitle === 'juridisch medewerker notariaat' && (
        <div>
          <img src="/ewise/assets/images/accreditations/register-notarisklerk-somn_0.png" style={{ width: '120px' }} alt="" />
        </div>
      )}
      {currentDomain === 'pe' && selectedPackageTitle === 'archief' && selectedProduct === 'notaris' && (
        <div>
          <img src="/ewise/assets/images/accreditations/logo_knb_1.svg" style={{ width: '120px' }} alt="" />
        </div>
      )}
      {currentDomain === 'cme' &&
        (selectedPackageTitle === 'ouderengeneeskunde' || selectedPackageTitle === 'huisarts' || selectedPackageTitle === 'ge-bu') && (
          <div>
            <img src="/ewise/assets/images/accreditations/accreditatiebureau_logo_-_175px.png" style={{ width: '120px' }} alt="" />
          </div>
        )}
      {currentDomain === 'cme' && (selectedPackageTitle === 'medicatiebeoordeling' || selectedPackageTitle === 'apotheker') && (
        <div>
          <img src="/ewise/assets/images/accreditations/knmp.png" style={{ width: '175px' }} alt="" />
        </div>
      )}
      {currentDomain === 'cme' &&
        (selectedPackageTitle === 'apothekersassistent' || selectedPackageTitle === 'farmaceutisch consulent') && (
          <div>
            <img src="/ewise/assets/images/accreditations/kabiz-accreditation-course-overview_0.png" style={{ width: '175px' }} alt="" />
          </div>
        )}
      {currentDomain === 'cme' && selectedPackageTitle === 'fysiotherapeut' && (
        <div>
          <img src="/ewise/assets/images/accreditations/kngf-en-keurmerk-fysio-2022.png" style={{ width: '270px' }} alt="" />
        </div>
      )}
      {currentDomain === 'cme' && selectedPackageTitle === 'mondhygiënist' && (
        <div>
          <img src="/ewise/assets/images/accreditations/KRM_logo-rgb-150.png" style={{ width: '100px' }} alt="" />
        </div>
      )}
      {currentDomain === 'cme' && selectedPackageTitle === 'tandarts' && (
        <div>
          <img src="/ewise/assets/images/accreditations/krt.svg" style={{ width: '100px' }} alt="" />
        </div>
      )}
      {currentDomain === 'cme' && selectedPackageTitle === 'verpleegkundig specialist' && (
        <div>
          <img src="/ewise/assets/images/accreditations/vvn_0.png" style={{ width: '200px' }} alt="" />
        </div>
      )}
      {currentDomain === 'cme' &&
        (selectedPackageTitle === 'VIG / Verpleegkundige' || selectedPackageTitle === 'Helpende plus / Verzorgende') && (
          <div>
            <img src="/ewise/assets/images/accreditations/logo-kr-volledig-fc.png" style={{ width: '200px' }} alt="" />
          </div>
        )}
      {currentDomain === 'ewise' && (
        <div>
          <a href="https://www.nrto.nl" title="NRTO" target="_blank" rel="noreferrer">
            <img src="/ewise/assets/images/accreditations/logo-nrto.png" alt="NRTO" />
          </a>
        </div>
      )}
    </div>
  );
};

const getReleaseDate = () => {
  const months = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december'
  ];
  const date = new Date();
  return [date.getDate(), months[date.getMonth()], date.getFullYear()];
};

const getAdvisoryBoard = (advisoryBoard, store) => {
  if (!advisoryBoard.length) return '';
  const board = advisoryBoard.map((member) => store.authors.authorList.find((auth) => auth.id === member));
  const boardList = board?.map((comp) => `<li><span">${comp?.title}</span></li>`).join('');
  const boardTitle = `<div class="board_title">Redactieraad</div>`;
  return `<div>${boardTitle}<ul>${boardList}</ul></div>`;
};

const getCoursesByCategory = (courses) => {
  const coursesByCategory = {};
  courses.courses.forEach((course) => {
    course.course_category.forEach((cat) => {
      if (!coursesByCategory?.[cat.name]) coursesByCategory[cat.name] = [];
      coursesByCategory[cat.name].push(course);
    });
  });
  return coursesByCategory;
};

const getLogo = (label) => {
  const logo = {
    cme: 'https://storage.googleapis.com/ewise-public-files/signature/logos/logo-cme-online-sm.png',
    pe: 'https://storage.googleapis.com/ewise-public-files/signature/logos/logo-pe-academy-sm.png',
    po: 'https://storage.googleapis.com/ewise-public-files/signature/logos/logo-po-online-sm.png',
    ewise: 'https://storage.googleapis.com/ewise-public-files/signature/logos/logo-e-wise-sm.png'
  };
  return logo[label];
};

const getDomain = (label) => {
  const url = {
    cme: 'cme-online.nl',
    pe: 'pe-academy.nl',
    po: 'po-online.nl',
    ewise: 'e-wise.nl'
  };
  return url[label];
};

const createHTMLCourses = (categories, curPackage, showSymbols, store) => {
  let totCourses = '';
  // set correct order of categories by weight
  const orderedCategories = store.courses.categories.sort((a, b) => a.weight - b.weight).map((cat) => cat.name);
  // CREATE HTML for course categories
  orderedCategories.forEach((cat) => {
    // create HTML for courses in category
    const _catCourses = categories[cat].map((course) => createLiCourse(course, curPackage, showSymbols)).join('');
    totCourses += `
    <div class="category">
      <div class="category_title">${cat}</div>
      <ul class="category_courses">${_catCourses}</ul>
    </div>`;
  });

  return totCourses;
};

const createLiCourse = (course, curPackage, showSymbols) => {
  const courseTitle = course.course_title ? course.course_title.replace(/&/g, '&amp;') : '';
  const courseExpected = course.course_expected ? `<span class="expected">verwacht</span>` : '';
  const courseAuthors = course.lc_author_id.map((author) => `${author.title || ''}`).join(', ');
  const courseSYmbols = showSymbols ? getCompetences(course) : '';
  // create course <li />
  const _liCourse = `
    <li class="course">
      <div class="title">${courseTitle} ${courseExpected}</div>
      <div class="author-points">
        <span class="authors">${courseAuthors}</span>
        <span class="competences">${courseSYmbols}</span>
        <span class="points">${course.points}</span>
      </div>
    </li>`;

  return _liCourse;
};

const getLegendaItems = (category2) => {
  const categories = JSON.parse(JSON.stringify(category2));
  if (!categories) return '';
  const competences = categories
    ?.map(
      (comp) =>
        `<li><span class="symbol-${comp?.symbol || [...comp.name][0]}">${comp?.symbol || [...comp.name][0]}</span><span>${
          comp.name
        }</span></li>`
    )
    .join('');
  return `<ul>${competences}</ul>`;
};

const getCompetences = (course) => {
  const badges = JSON.parse(JSON.stringify(course.course_category2));
  //
  const npoBadges = badges
    .filter((badge) => badge?.name.toLowerCase().includes('npo'))
    .map((badge) => ({ ...badge, ...{ symbol: badge.name, style: true } }));
  //
  const goBadges = badges
    .filter((badge) => badge?.name.toLowerCase() === 'groepsopdracht')
    .map((badge) => ({ ...badge, ...{ symbol: 'G', style: true } }));
  //
  const veBadges = badges
    .filter((badge) => badge?.symbol?.toLowerCase() === 've')
    .map((badge) => ({ ...badge, ...{ symbol: badge.name, style: true } }));
  //
  const aBadges = badges
    .filter((badge) => badge?.name.toLowerCase() === 'a1' || badge?.name.toLowerCase() === 'a2')
    .map((badge) => ({ ...badge, ...{ symbol: badge.name, style: true } }));
  //
  const restBadges = badges
    .filter(
      (badge) =>
        !badge?.name.toLowerCase().includes('npo') &&
        badge?.name.toLowerCase() !== 'groepsopdracht' &&
        badge?.symbol?.toLowerCase() !== 've' &&
        badge?.name.toLowerCase() !== 'a1' &&
        badge?.name.toLowerCase() !== 'a2'
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  //
  const sortedBadges = [...goBadges, ...npoBadges, ...veBadges, ...aBadges, ...restBadges];
  //
  const competences = sortedBadges
    ?.map(
      (comp) =>
        `<span class='${comp?.style ? 'npo' : ''} symbol-${comp?.symbol || [...comp.name][0]}' key='${[...comp.name][0]}'>${
          comp?.symbol || [...comp.name][0]
        }</span>`
    )
    .join('');
  return competences;
};

const createHtmlDoc = (htmlOutput, theme, labelImage, overviewTitle, labelDomain, releaseDate) => `
<!DOCTYPE html>
<html>
<head>
<title>Cursusoverzicht ${overviewTitle} </title>
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">

<style>
/* SYMBOL BACKGROUND COLORS */
.symbol-a { background-color: Thistle; }
.symbol-b { background-color: #e2cfb6; }
.symbol-c { background-color: LightSkyBlue; }
.symbol-d { background-color: #62c2a2; }
.symbol-e { background-color: DeepSkyBlue; }
.symbol-f { background-color: DarkMagenta; }
.symbol-g { background-color: DarkSalmon; }
.symbol-h { background-color: Blue; }
.symbol-i { background-color: BlueViolet; }
.symbol-j { background-color: Brown; }
.symbol-k { background-color: BurlyWood; }
.symbol-l { background-color: CadetBlue; }
.symbol-m { background-color: Chartreuse; }
.symbol-n { background-color: Chocolate; }
.symbol-o { background-color: Coral; }
.symbol-p { background-color: CornflowerBlue; }
.symbol-q { background-color: RosyBrown; }
.symbol-r { background-color: Crimson; }
.symbol-s { background-color: Cyan; }
.symbol-t { background-color: DarkBlue; }
.symbol-u { background-color: DarkCyan; }
.symbol-v { background-color: DarkGoldenRod; }
.symbol-w { background-color: SlateGray; }
.symbol-x { background-color: DarkGray; }
.symbol-y { background-color: DarkGreen; }
.symbol-z { background-color: DarkKhaki; }
/* -- CAPITALS --- */
.symbol-A { background-color: DarkOliveGreen; }
.symbol-B { background-color: Darkorange; }
.symbol-C { background-color: DarkOrchid; }
.symbol-D { background-color: DarkRed; }
.symbol-E { background-color: DarkSalmon; }
.symbol-F { background-color: DarkSeaGreen; }
.symbol-G { background-color: DarkSlateBlue; }
.symbol-H { background-color: DarkSlateGray; }
.symbol-I { background-color: DarkTurquoise; }
.symbol-J { background-color: #663399; }
.symbol-K { background-color: DeepPink; }
.symbol-L { background-color: DeepSkyBlue; }
.symbol-M { background-color: YellowGreen; }
.symbol-N { background-color: DodgerBlue; }
.symbol-O { background-color: FireBrick; }
.symbol-P { background-color: IndianRed; }
.symbol-Q { background-color: ForestGreen; }
.symbol-R { background-color: LightSeaGreen; }
.symbol-S { background-color: SlateBlue; }
.symbol-T { background-color: MediumSeaGreen; }
.symbol-U { background-color: Gold; }
.symbol-V { background-color: GoldenRod; }
.symbol-W { background-color: SlateGray; }
.symbol-X { background-color: DarkGray; }
.symbol-Y { background-color: Green; }
.symbol-Z { background-color: DarkOliveGreen; }

.symbol-HV { background-color: #828080; }
.symbol-Mp { background-color: OrangeRed; }
/* ----- */
body { font-family: sans-serif!important; }
table { width: 100%; }
button {font-size: 10px; margin-right: 5px}

body.layout .columns-2 {
  columns: 2;
  column-gap: 2rem;
  padding: 0cm;
  column-fill: auto;
}
body.layout.balanced .columns-2{ column-fill: balance; }

body.layout.balanced .btnBalanced:after { content: "Balanced"}

.legenda { font-size: 7px; font-weight: 100; padding: 10px; background: rgba(0, 0, 0, 0.04); }
.legenda li { display: flex; gap: 5px; padding: 2px 0px; }
.legenda span[class*=" "] {background-color: #76767629;}

.competences { display: flex; justify-content: end; gap: 1px; }
.competences span, .legenda span[class^="symbol"] {
  font-size: 7px;
  display: block;
  width: 8px;
  height: 8px;
  line-height: 8px;
  text-align: center;
  font-weight: 100;
  color: white;
}
.competences span[class*="npo"], .legenda span[class*="npo"] {
  width: fit-content;
  color: white;
  background: #607d8bbf;
  font-size: 6px;
  padding: 0px 1px;
}
.advisory_board { page-break-inside: avoid; color: ${theme.palette.primary.main}; padding: 10px; font-size: 8px; font-weight: 100; background: #f5f5f5; border-radius: 20px 0px 20px 0px; margin-bottom: 10px }
.advisory_board .board_title { font-size: 12px; font-weight: 600; line-height: 19px; }
.advisory_board li { line-height: 12px; font-size: 9px; }

.accreditations {padding-top: 20px; text-align: center;}

div.category { padding-bottom: 10px;}
.legenda { padding-bottom: 10px; page-break-inside: avoid;}

div.category .category_title {
  font-weight: 600;
  font-size: 9pt;
  padding-bottom: 2px;
  margin-bottom: 0px;
  padding-top: 0px;
  border-bottom: .5pt solid ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
}

ul { padding-inline-start: 0px!important; margin: 0px; }

li { list-style: none; }
li.course { padding: 2px 0px; page-break-inside: avoid; }

.author-points { display: grid; grid-template-columns: auto 125px 100px; }
li.course .title {    font-size: 7.5pt; font-weight: 300; color: ${theme.palette.primary.main}; display: block; }

span.authors { font-size: 6.5pt; font-weight: 100; color: #00000070!important}
span.points {  font-size: 6.5pt; font-weight: 400; color: #00000099 !important; text-align: right; }
span.expected { 
  font-size: 6.5pt;
  font-weight: 400;
  color: #ff9800;
  margin-left: 5px;
}

.section { max-width: 210mm }

.page-header, .page-header-space { height: 50px; }
.page-footer, .page-footer-space { height: 50px; }
.page-footer { position: fixed; bottom: 0; width: 100%; }
.page-header { position: fixed; top: 0mm; width: 100%; }
.header-left {text-align: left; color: ${theme.palette.primary.main}; font-size: 11pt; font-weight: 600}
.header-right {text-align: right; color: black; font-size: 9pt}
.footer-left {text-align: left; color: #00000070!important; font-size: 6.5pt; font-weight: 100; font-style: italic}
.footer-center {text-align: center; color: ${theme.palette.primary.main}; font-size: 6.5pt; font-weight: 400!important}
.footer-right {text-align: right; color: black; font-size: 9pt}

@page { margin: 10mm; }

@media print {
  body {margin: 0;}
   thead {display: table-header-group;} 
   tfoot {display: table-footer-group;}
  .footer-left {text-align: left; color: black; font-size: 6.5pt!important; font-weight: 100!important}
  .footer-center {text-align: center; color: ${theme.palette.primary.main}; font-size: 6.5pt!important; font-weight: 300!important}
  .footer-right {text-align: right; color: black; font-size: 9pt}
   
   button {display: none;}
}

</style>
</head>
<body class="layout ">
  <div class="page-header">
    <table style="width: 100%">
      <tr>
        <td class="header-left">Cursusoverzicht ${overviewTitle} <button class="btnBalanced" onclick="balance()">Auto</button> <button onclick="printPage()">Print Page</button>
    <script>
      function balance() {
        const columnFill = document.querySelector('.layout');
        columnFill.classList.toggle("balanced");
  }

        function printPage() {
            window.print();
        }
    </script></td>
        <td class="header-right" style="text-align: right"><img src="${labelImage}" alt="${overviewTitle}-logo" height="30px!important"></td>
      </tr>
    </table>
  </div>

  <div class="page-footer">
    <table style="width: 100%">
      <tr>
        <td class="footer-left">uitgave ${releaseDate[0]} ${releaseDate[1]} ${releaseDate[2]}</td>
        <td class="footer-center" style="text-align: center">www.${labelDomain} | info@${labelDomain} | (030) 26 44 100</td>
        <td class="footer-right" style="text-align: right"><img src="https://storage.googleapis.com/ewise-public-files/signature/logos/logo-e-wise-sm.png" alt="ewise-logo" height="30px!important"></td>
      </tr>
    </table>
  </div>

  <table>
    <thead>
      <tr>
        <td>
          <!--place holder for the fixed-position header-->
          <div class="page-header-space"></div>
        </td>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>
          <div class="section"><div id="toc" class="columns-2 ">${htmlOutput}</div></div>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td>
          <!--place holder for the fixed-position footer-->
          <div class="page-footer-space"></div>
        </td>
      </tr>
    </tfoot>

  </table>

</body>
</html>`;
