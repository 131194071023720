import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TotalPointsChip from './TotalPointsChip';

const TotalCompletedCourses = () => {
  const { t } = useTranslation();
  const store = useStore();
  const points = store.totalCompletedCourses;

  return <TotalPointsChip points={points} title={t('headerbar.totalCoursesCompleted')} />;
};

export default observer(TotalCompletedCourses);
