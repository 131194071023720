import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { Controller, useWatch } from 'react-hook-form';
import { forwardRef, useState, useEffect } from 'react';
import { convertBlobString2File } from 'helpers/image.helper';
/* MUI */
import { Box, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

/**
 * ImageUploader component
 *
 * Works => need refactoring
 */
const ImageUploader = forwardRef(
  (
    {
      name,
      resetField,
      control,
      index,
      onChange: onChangeProp,
      src,
      avatar,
      buttonName,
      editMode,
      reset,
      maxWidth = 0,
      maxHeight = 0,
      setValue,
      ...otherProps
    },
    ref
  ) => {
    const store = useStore();
    const { courseEdit } = store;
    const [files, setFiles] = useState([src]);
    //
    const watchImg = useWatch({ control, name });
    //
    const isSetImgWH = maxWidth > 0 && maxHeight > 0;
    //

    const onPaste = (event) => {
      const clipboardData = event.clipboardData || window.clipboardData;
      const { items } = clipboardData;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const imageFile = items[i].getAsFile();
          processImage(imageFile);
        }
      }
    };

    const processImage = (imageFile) => {
      const fileDetails = {
        filename: imageFile.name,
        size: imageFile.size
      };
      //
      const reader1 = new FileReader();
      reader1.readAsBinaryString(imageFile);
      reader1.onloadend = () => {
        const data = reader1.result;
        fileDetails.data = data;
        setValue(name, fileDetails, { shouldDirty: true });
      };
    };
    //

    const setImageSize = {
      headerPhoto: { type: 'Cursusinleiding banner', maxWidth: 900, maxHeight: 300, class: 'headerPhoto' },
      coursePhoto: { type: 'Image intern', maxWidth: 480, maxHeight: 270, class: 'coursePhoto' },
      imageExtern: { type: 'image extern', maxWidth: 360, maxHeight: 270, class: 'imageExtern' },
      getSize: (image) => {
        if (image.includes('field_course_header_photo')) return setImageSize.headerPhoto;
        if (image.includes('field_coursephoto')) return setImageSize.coursePhoto;
        if (image.includes('field_course_image_extern')) return setImageSize.imageExtern;
        return { maxWidth: 0, maxHeight: 0 };
      }
    };

    const toastMsg = () => {
      let mediaLibraryImage = false;
      mediaLibraryImage = name.includes('field_course_header_photo') ? 'Cursusinleiding' : mediaLibraryImage;
      mediaLibraryImage = name.includes('field_coursephoto') ? 'Image intern' : mediaLibraryImage;
      mediaLibraryImage = name.includes('field_course_image_extern') ? 'Image extern' : mediaLibraryImage;
      const toast = {
        title: `${mediaLibraryImage} afbeelding incorrect`,
        content: `Download uit de media bibliotheek de afbeelding: ${mediaLibraryImage}`,
        type: 'warning',
        timeOut: 6000,
        variant: 'filled'
      };
      courseEdit.setToasts(toast);
    };

    useEffect(() => {
      if (!watchImg?.filename) {
        setFiles([src]);
      }
    }, [watchImg?.filename, src]);

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={src}
        render={({ field }) => (
          <Box className="uploadContainer" sx={{ display: 'flex', flexDirection: 'column-reverse', position: 'relative' }}>
            <label htmlFor={`file-input-${name}-${index}`}>
              <Button
                sx={{ fontSize: '10px', pointerEvents: 'none', marginTop: '10px' }}
                variant="contained"
                component="label"
                size="small"
                startIcon={<CloudUploadIcon />}
                disabled={otherProps.disabled}
              >
                {`upload ${buttonName}`}
              </Button>

              <input
                disabled={otherProps.disabled}
                type="file"
                accept=".jpg,.jpeg,.png"
                {...field}
                ref={ref}
                hidden
                id={`file-input-${name}-${index}`}
                value={otherProps.defaultValue}
                className="image_upload"
                onChange={(e) => {
                  if (!e.currentTarget.files[0]) return;
                  const file = e.currentTarget.files[0];
                  const fileDetails = { filename: file.name, size: file.size };
                  const reader = new FileReader();
                  reader.readAsBinaryString(file);
                  reader.onloadend = () => {
                    const data = reader.result;
                    fileDetails.data = data;
                    field.onChange(fileDetails);
                  };
                  const newFiles = [...files];
                  // CHECK UPLOADED IMAGE PROPERTIES
                  const dataReader = new FileReader();
                  dataReader.readAsDataURL(file);
                  dataReader.onload = () => {
                    const image = new Image();
                    image.src = dataReader.result;
                    image.onload = () => {
                      const { height } = image;
                      const { width } = image;
                      const correctSize = isSetImgWH ? height === maxHeight && width === maxWidth : true;
                      // SAVE || SHOW TOAST + RESET IMAGE
                      if (correctSize) {
                        newFiles[index] = dataReader.result;
                        setFiles(newFiles);
                      } else {
                        toastMsg();
                        resetField(name);
                      }
                    };
                  };
                }}
              />
            </label>

            <Box
              className={setImageSize.getSize(name).class}
              sx={{ position: 'relative', padding: '0px', paddingTop: `${setImageSize.getSize(name).maxHeight}px` }}
            >
              {watchImg?.data && (
                <Box
                  sx={{ position: 'absolute', top: '0px' }}
                  id="test1"
                  component="img"
                  src={convertBlobString2File(watchImg.data)}
                  height="100px"
                />
              )}
              {!watchImg?.data && src && (
                <Box id="test2" sx={{ position: 'absolute', top: '0px' }} component="img" src={src} height="100px" />
              )}

              <Box
                className="pasteBox"
                ref={(ref2) => {
                  if (ref2) {
                    ref2.addEventListener('paste', onPaste);
                  }
                }}
                sx={{
                  display: 'flex',
                  textAlign: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '20px',
                  background: `${watchImg.data ? 'transparent' : '#c0c0c057'}`,
                  width: `${watchImg.data ? '100%' : `${setImageSize.getSize(name).maxWidth}px`}`,
                  height: `${watchImg.data ? '100%' : `${setImageSize.getSize(name).maxHeight}px`}`,
                  position: 'absolute',
                  top: '0px',
                  left: '0x',
                  'h2, span': { color: '#fff', textShadow: '0px 0px 3px #000', fontWeight: '900' },
                  span: { fontSize: '20px' }
                }}
              >
                <h2>{`${setImageSize.getSize(name).type} : ${maxWidth} x ${maxHeight}`}</h2>
                <span>Upload or paste image here</span>
              </Box>

              {/* <Box className="pasteBox_image">{imageDataURL && <img src={imageDataURL} alt="Pasted" className="pasted-image" />}</Box> */}
            </Box>
          </Box>
        )}
      />
    );
  }
);

ImageUploader.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  reset: PropTypes.any,
  resetField: PropTypes.any,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  src: PropTypes.string,
  editMode: PropTypes.bool,
  avatar: PropTypes.bool,
  buttonName: PropTypes.string,
  index: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number
};

export default observer(ImageUploader);
