import { Radio, FormLabel, RadioGroup, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// renders a RadioGroup with the option to use react-hook-form
// eslint-disable-next-line react/prop-types
const MyRadioGroup = ({ name, control, type = 'radio', value, label, options, checked, helperText }) =>
  control ? (
    <>
      <FormLabel className={helperText && 'Mui-error'}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({ field }) => (
          <>
            <RadioGroup {...field} style={{ marginTop: '10px' }}>
              {options.map((option) => (
                <FormControlLabel key={option.name} value={option.name} control={<Radio />} label={option.name} />
              ))}
            </RadioGroup>
            <FormHelperText className={helperText && 'Mui-error'}>{helperText}</FormHelperText>
          </>
        )}
      />
    </>
  ) : (
    <Radio {...{ name, type, value, label, checked }} inputProps={{ 'aria-label': 'A' }} />
  );

MyRadioGroup.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object,
  checked: PropTypes.bool,
  options: PropTypes.array,
  helperText: PropTypes.string
};

export default MyRadioGroup;
