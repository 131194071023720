import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Button, Box, Card, CardContent } from '@mui/material';
import { Form } from 'components/form';
import { MyRadioGroup, MySelect, MyTextField } from 'components/form/RHF/index';
import { makeStyles } from '@mui/styles';
import { urlify } from 'state/Utils';
import { ThemeBrandTitle } from 'helpers/Helpers';
import Header from '../components/Header';
import { formEvaluationValidation } from './beans/Evaluation.ValidationSchema';
import { rating, numberRating, hoursSelect } from './beans/dataFile';

const useStyles = makeStyles(() => ({
  formContainer: {
    '& .MuiInputBase-root': {
      borderRadius: '18px'
    }
  },
  radioGroup: {
    '& label': {
      color: '#2b3674',
      fontSize: '17px',
      fontWeight: '500'
    }
  }
}));

const EvaluationForm = () => {
  const store = useContext(Store);
  const { t } = useTranslation('evaluation');

  const classes = useStyles();
  const { routerStore, lessonPlay, profession, postData } = store;

  // Find course Result uuid
  const courseResultsId = lessonPlay && lessonPlay.lesson_result?.attributes?.course_result;
  const courseResultUuid = store.courseResults?.findCourseResultByNid(courseResultsId).uuid;
  const courseTitle = store.findCourseByNid(Number(store.params.courseId)).course_title;

  const brandTitle = ThemeBrandTitle();

  // Check if evaluation is already filled in
  const evaluation = useCallback(async () => {
    // This check is only after course result is updated in the completed step
    const courseResultEvalBck = store.courseResults?.findCourseResultByNid(courseResultsId).has_evaluation || false;
    // First check for sesssion, if user did not finish completion step yet, we clear the session on logout
    const courseResultEvaluation = store.getEvaluation() === courseResultUuid || courseResultEvalBck;
    return courseResultEvaluation;
  }, [courseResultUuid, courseResultsId, store]);

  useEffect(() => {
    evaluation().then((res) => {
      if (res) {
        store.setLessonState('init');
        store.routerStore.goTo('defCourseIntroduction', {
          params: {
            product: store.params.product,
            productName: store.params.productName,
            packageId: store.params.packageId,
            courseId: store.params.courseId,
            title: urlify(courseTitle)
          }
        });
      }
    });
  });

  const handleSubmit = async (values) => {
    const { courseId, lessonId, product, productName, packageId } = routerStore.routerState.params;

    const {
      id,
      relationships: { course }
    } = lessonPlay.courseResult;

    // Find proffesion uuid
    const professionUuid = profession.uuid;

    postData({
      attributes: { ...values, course_result_uuid: id, title: 'evaluation' },
      type: 'evaluation',
      relationships: {
        course: {
          data: {
            type: 'course',
            id: course.data.id
          }
        },
        profession: {
          data: {
            type: 'profession',
            id: professionUuid
          }
        }
      }
    });
    // This will save the course_result_uuid in the browser local session
    store.setEvaluation(courseResultUuid);
    // Did you answer all the questions yourself?
    routerStore.goTo('terms', {
      params: {
        product,
        productName,
        packageId,
        lessonId,
        courseId
      }
    });
  };
  const initialValues = {
    evaluation_question_1: '',
    evaluation_question_1b: '',
    evaluation_question_2: '',
    evaluation_question_2b: '',
    evaluation_question_3: '',
    evaluation_question_4: '',
    evaluation_remarks_1: '',
    evaluation_remarks_2: '',
    evaluation_question_slpo_1: '',
    evaluation_question_slpo_2: '',
    evaluation_question_slpo_3: '',
    evaluation_question_slpo_4: ''
  };

  // eslint-disable-next-line react/destructuring-assignment
  const isSchoolleiders = store.profession.name === 'Schoolleider PO';
  const score = lessonPlay?.lesson_result?.attributes?.score;
  const { uw, u, beoordeel, geeft, heeft, Heeft, heeftHebt, vind } =
    brandTitle !== 'E-WISE'
      ? {
          uw: 'uw',
          u: 'u',
          beoordeel: 'beoordeelt u',
          geeft: 'geeft u',
          heeft: 'heeft u',
          Heeft: 'Heeft u',
          heeftHebt: 'heeft',
          vind: 'vindt'
        }
      : {
          uw: 'je',
          u: 'je',
          beoordeel: 'beoordeel je',
          geeft: 'geef je',
          heeft: 'heb je',
          Heeft: 'Heb je',
          heeftHebt: 'hebt',
          vind: 'vind'
        };

  return (
    <>
      <Box className="scorePage" style={{ backgroundColor: '#EEF2FA', minHeight: '100vh', display: 'flex' }}>
        <Header />
        <Box style={{ marginTop: '151px', width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <Card className="scorePageCard" style={{ padding: '22px', borderRadius: '18px', boxShadow: 'none' }}>
            <CardContent>
              <Box display="flex" justifyContent="center">
                <Card style={{ borderRadius: '13px', color: 'white', backgroundColor: '#006846', width: '100%' }}>
                  <CardContent display="flex" padding="16px" style={{ paddinBotttom: '0px' }}>
                    <Grid container style={{ margin: '0px' }}>
                      <Grid item>
                        <Typography>{t('congratulations')}</Typography>
                        <Typography
                          sx={{
                            '&::first-letter': {
                              textTransform: 'capitalize'
                            }
                          }}
                        >
                          {t('form.score', { uw, score })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={formEvaluationValidation}>
                {({ control, errors, dirty, watch }) => (
                  <Grid container display="flex" flexDirection="column" spacing={2} className={classes.formContainer}>
                    <Grid item xs={12} className={classes.radioGroup}>
                      <MyRadioGroup
                        control={control}
                        name="evaluation_question_1"
                        options={rating}
                        label={t('form.evaluation_question_1', { u, uw, beoordeel, geeft, heeft })}
                        error={!!errors?.evaluation_question_1}
                        helperText={errors?.evaluation_question_1?.message}
                      />
                    </Grid>
                    {watch().evaluation_question_1 &&
                      watch().evaluation_question_1 !== 'uitstekend' &&
                      watch().evaluation_question_1 !== 'goed' && (
                        <Grid item xs={12}>
                          <MyTextField
                            control={control}
                            name="evaluation_question_1b"
                            multiline
                            minRows={3}
                            label={t('form.evaluation_question_1b', { u, uw, beoordeel, geeft, heeft })}
                            error={!!errors?.evaluation_question_1b}
                            helperText={errors?.evaluation_question_1b?.message}
                          />
                        </Grid>
                      )}
                    {isSchoolleiders && (
                      <>
                        <Grid item xs={12} className={classes.radioGroup}>
                          <MyRadioGroup
                            control={control}
                            name="evaluation_question_slpo_1"
                            options={rating}
                            label={t('form.evaluation_question_slpo_1', { u, uw, beoordeel, geeft, heeft })}
                            error={isSchoolleiders && !!errors?.evaluation_question_slpo_1}
                            helperText={isSchoolleiders && errors?.evaluation_question_slpo_1?.message}
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.radioGroup}>
                          <MyRadioGroup
                            control={control}
                            name="evaluation_question_slpo_2"
                            options={rating}
                            label={t('form.evaluation_question_slpo_2', { u, uw, beoordeel, geeft, heeft })}
                            error={isSchoolleiders && !!errors?.evaluation_question_slpo_2}
                            helperText={isSchoolleiders && errors?.evaluation_question_slpo_2?.message}
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.radioGroup}>
                          <MyRadioGroup
                            control={control}
                            name="evaluation_question_slpo_3"
                            options={rating}
                            label={t('form.evaluation_question_slpo_3', { u, uw, beoordeel, geeft, heeft })}
                            error={isSchoolleiders && !!errors?.evaluation_question_slpo_3}
                            helperText={isSchoolleiders && errors?.evaluation_question_slpo_3?.message}
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.radioGroup}>
                          <MyRadioGroup
                            control={control}
                            name="evaluation_question_slpo_4"
                            options={rating}
                            label={t('form.evaluation_question_slpo_4', { u, uw, beoordeel, geeft, heeft })}
                            error={!!errors?.evaluation_question_slpo_4}
                            helperText={errors?.evaluation_question_slpo_4?.message}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} className={classes.radioGroup}>
                      <MyRadioGroup
                        control={control}
                        name="evaluation_question_2"
                        options={rating}
                        label={t('form.evaluation_question_2', { u, uw, beoordeel, geeft, heeft, heeftHebt, vind })}
                        error={!!errors?.evaluation_question_2}
                        helperText={errors?.evaluation_question_2?.message}
                      />
                    </Grid>

                    {watch().evaluation_question_2 &&
                      watch().evaluation_question_2 !== 'uitstekend' &&
                      watch().evaluation_question_2 !== 'goed' && (
                        <Grid item xs={12}>
                          <MyTextField
                            control={control}
                            name="evaluation_question_2b"
                            multiline
                            minRows={3}
                            label={t('form.evaluation_question_2b', { u, uw, beoordeel, geeft, heeft })}
                            error={!!errors?.evaluation_question_2b}
                            helperText={errors?.evaluation_question_2b?.message}
                          />
                        </Grid>
                      )}
                    <Grid item xs={12} className={classes.radioGroup}>
                      <MyRadioGroup
                        control={control}
                        name="evaluation_question_3"
                        options={numberRating}
                        label={t('form.evaluation_question_3', { u, uw, beoordeel, geeft, heeft })}
                        error={!!errors?.evaluation_question_3}
                        helperText={errors?.evaluation_question_3?.message}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MySelect
                        name="evaluation_question_4"
                        items={hoursSelect}
                        label={t('form.evaluation_question_4', { u, uw, beoordeel, geeft, heeft })}
                        control={control}
                        error={!!errors?.evaluation_question_4}
                        helperText={errors?.evaluation_question_4?.message}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyTextField
                        control={control}
                        name="evaluation_remarks_1"
                        multiline
                        label={t('form.evaluation_remarks_1', { u, uw, beoordeel, geeft, Heeft })}
                        minRows={3}
                        error={!!errors?.evaluation_remarks_1}
                        helperText={errors?.evaluation_remarks_1?.message}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyTextField
                        control={control}
                        name="evaluation_remarks_2"
                        multiline
                        label={t('form.evaluation_remarks_2', { u, uw, beoordeel, geeft, heeft })}
                        minRows={3}
                        error={!!errors?.evaluation_remarks_2}
                        helperText={errors?.evaluation_remarks_2?.message}
                      />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <Button variant="contained" type="submit" disabled={!dirty}>
                        {t('form.save')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Form>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default observer(EvaluationForm);
