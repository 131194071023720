import PropTypes from 'prop-types';

import { Icon } from '@iconify/react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle, Button, Box, CircularProgress, Tooltip } from '@mui/material';

const CustomDialog = ({ title, onClose, visible, children, name, action }) => (
  <div>
    <Dialog
      sx={{
        overflow: 'visible',
        '& .MuiPaper-root': {
          borderRadius: '16px',
          overflow: 'visible !important'
        },
        '& .MuiDialogTitle-root': {
          fontSize: '20px',
          textAlign: 'left !important',
          padding: '0 0 8px 0'
        },
        '& .MuiDialogContent-root': {
          padding: '0 0 20px 0',
          overflow: 'visible'
        },
        '& .MuiBackdrop-root': {
          background: 'rgba(0, 0, 0, 0.2)'
        },
        '& .MuiDialogActions-root': {
          padding: '0 0 8px 0',
          paddingLeft: '0px',
          paddingRight: '0px !important'
        }
      }}
      open={visible}
      onClose={(_, reason) => reason === 'escapeKeyDown' && onClose()}
      aria-labelledby={`${name}-dialog-title`}
      aria-describedby={`${name}-dialog-description`}
    >
      <DialogTitle
        sx={{
          textAlign: 'left',
          fontSize: '1.25rem !important'
        }}
        id={`${name}-dialog-title`}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{title}</span>
          <button
            type="button"
            style={{
              display: 'grid',
              placeItems: 'center',
              backgroundColor: '#FEE2E2',
              width: '2.5rem',
              height: '2.5rem',
              borderRadius: '9999px',
              border: 'none',
              cursor: 'pointer'
            }}
            onClick={() => onClose()}
          >
            <Icon icon="akar-icons:cross" style={{ color: '#B91C1C', fontSize: '0.75rem', lineHeight: '1rem', fontWeight: '700' }} />
          </button>
        </Box>
      </DialogTitle>
      <DialogContent
        id={`${name}-dialog-description`}
        sx={{
          maxHeight: 'calc(100vh - 200px)',
          overflow: 'auto !important'
        }}
      >
        {children}
      </DialogContent>
      {action && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            justifyContent: 'center',
            width: '100%',
            height: '2.5rem',
            bottom: '-15px',
            left: '0'
          }}
        >
          <Tooltip title={action.name} placement="top">
            <Button
              className={action.classes || ''}
              onClick={action.action}
              type="button"
              disabled={action.isLoading}
              sx={{
                outline: 'none',
                textTransform: 'none',
                color: '#FFFFFF',
                borderRadius: '15px',
                padding: '25px 20px',
                backgroundColor: action.isLoading ? '#cfd8dc' : 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.main'
                },
                ...(action.classes || {})
              }}
            >
              {action.isLoading && <CircularProgress color="primary" style={{ width: '24px', height: '24px' }} />}
              {!action.isLoading && (
                <>
                  {action.icon && <Icon icon={action.icon} style={{ marginRight: '8px' }} />}
                  {action.name}
                </>
              )}
            </Button>
          </Tooltip>
        </Box>
      )}
    </Dialog>
  </div>
);

CustomDialog.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  action: PropTypes.shape({
    action: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    classes: PropTypes.string,
    sx: PropTypes.any,
    isLoading: PropTypes.bool
  })
};

export default CustomDialog;
