import { useState } from 'react';
import { useStore } from 'hooks';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { SubscribeLink } from 'helpers/Helpers';
// MUI
import { Box, Button, Typography, keyframes, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconPlay from '@mui/icons-material/PlayCircleFilled';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const TrialCourseButton = () => {
  const [open, setOpen] = useState(false);
  const handleButtonClick = () => setOpen(!open);
  const sxButton = { filter: 'grayscale(100%)' };

  return (
    <>
      <Button onClick={handleButtonClick} fullWidth variant="contained" className="start_button" sx={sxButton}>
        Start <IconPlay style={{ fontSize: '30px' }} />
      </Button>
      <TrialAlertDialog open={open} handleClose={handleButtonClick} />
    </>
  );
};

export default observer(TrialCourseButton);

const TrialAlertDialog = (props) => {
  const store = useStore();
  const { t } = useTranslation();
  const { open, handleClose } = props;

  const blinkAnimation = keyframes` from { opacity: 0; } to { opacity: 1; } `;
  const sxDialog = {
    [` .MuiPaper-root`]: { background: `linear-gradient(45deg, #ffd700, #ffa500)` },
    [` .trial_content`]: { alignSelf: 'flex-end', maxHeight: '500px', textAlign: 'center' },
    [` .blink_icon`]: { fontSize: '40px', animation: `${blinkAnimation} 2s linear infinite` }
  };

  return (
    <Box>
      <Dialog
        sx={sxDialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box className="trial_content">
            <Stack spacing={2}>
              <Typography variant="heading2">
                <NewReleasesIcon className="blink_icon" />
              </Typography>

              <Typography variant="heading3">
                <strong>{t('subscribe.title', 'Get access to all our courses')}</strong>
              </Typography>

              <Button variant="contained" href={SubscribeLink(store).subscribeUrl} target="_blank" rel="noreferrer">
                {t('subscribe.button', 'Subscribe today')}
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

TrialAlertDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
