import { useStore } from 'hooks';

export const useFunctions = () => {
  const store = useStore();

  const functions = store.curriculumFunctions ?? [];
  const functionActivities = store.curriculumFunctionActivities ?? [];

  // create a nested version for display purposes
  const functionsWithActivities = functions.map((fn) => {
    const activityIds = fn.relationships?.activities?.data.map((a) => a.id);
    const activities = functionActivities?.filter((a) => activityIds.includes(a.id));
    return {
      ...fn,
      activities
    };
  });

  return {
    functions,
    functionActivities,
    functionsWithActivities
  };
};
