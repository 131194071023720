import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'state/ContextStore';

import { translatePoints } from 'helpers/Helpers';
import TotalPointsChip from './TotalPointsChip';

const TotalSubPoints = () => {
  const store = useContext(Store);
  const { t } = useTranslation();

  // NO ACTIVE SUBSCRIPTIONS return fragment
  // eslint-disable-next-line no-extra-boolean-cast
  if (!store?.subscriptions?.subscriptions?.length > 0) return null;

  // ACTIVE SUBSCRIPTIONS return total points
  const userPoints = `${store.subscriptions?.totalSubscriptionPoints(store?.baseProduct?.nid)}`;
  const accreditation = store?.baseProduct?.packages.find((subPackage) => subPackage.nid === store?.selectedPackageId);
  const accrUnit = accreditation?.accreditation?.accr_unit;

  let toolTipText = accrUnit !== 'punt' ? t('headerbar.earnedHours', 'Training hours') : t('headerbar.earnedPoints', 'Earned points');
  toolTipText = store?.isEwiseUser ? t('headerbar.unlimited', 'Unlimited') : toolTipText;

  const points = userPoints ? translatePoints(userPoints, t) : '0';

  return <TotalPointsChip points={points} title={toolTipText} />;
};

export default observer(TotalSubPoints);
