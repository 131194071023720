import { useEffect, useState } from 'react';

export const useEventTypeDescriptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const response = await window.authedClient.get(`jsonapi/node/vtb_event`);

        const transformedData = {};

        response.data.data.forEach((item) => {
          const key = item.attributes.drupal_internal__nid;
          transformedData[key] = item.attributes;
        });

        setData(transformedData);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    };

    init();
  }, []);

  return { data, error, isLoading };
};
