import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import DoneIcon from '@mui/icons-material/Done';

export const CircularProgressWithLabel = (props) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      variant="determinate"
      sx={{
        color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800]
      }}
      size={40}
      {...props}
      value={100}
    />
    <CircularProgress
      variant="determinate"
      {...props}
      sx={{
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round'
        }
      }}
      size={40}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {props.value === 100 ? (
        <DoneIcon color="primary" />
      ) : (
        <Typography variant="caption" component="div" color="primary" sx={{ fontWeight: 800 }}>{`${Math.round(props.value)}%`}</Typography>
      )}
    </Box>
  </Box>
);

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number
};
