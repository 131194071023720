import { createRouterState } from 'mobx-state-router';
import { ThemeBrandTitle } from 'helpers/Helpers';
import { getProfile, checkUserSignedIn } from 'state/RoutesActions';

export const profileRoute = {
  name: 'profile',
  pattern: '/account/profile',
  meta: { title: `%%account.profile.title%% | ${ThemeBrandTitle()}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { params } = toState;
    getProfile(fromState, toState, routerStore);
    // Empty the search params
    store.clearSearchParam();
    // Check if the user is LTI and trying to reach this page
    if (store.getLtiSession() === 1 || store.getLtiSession() === '1') {
      return createRouterState('errorPage');
    }
    // Check if the user is an Ewise user and has not selected a base product
    if (store.isEwiseUser && !store.hasPreferredBaseproduct) {
      return createRouterState('selectProduct', { params: { user: store.login.userName } });
    }
    try {
      if (store.hasSubscriptions === 'init') {
        await checkUserSignedIn(fromState, toState, routerStore, params.product);
      }
    } catch (e) {
      return e;
    }
    return null;
  }
};
