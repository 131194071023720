import { createJsonApi, handleApiError } from 'state/api/JsonApi';

export const createCurriculumApi = () => {
  const curriculumApi = {
    ...createJsonApi({
      endpoint: 'portfolio',
      params: {
        include: `functions,functions.activities,learning_goals,learning_goals.activities,learning_goals.evaluations,learning_goals.evaluations.courses,override_file`
      }
    }),
    async getAll(params) {
      try {
        const searchParams = new URLSearchParams(params);
        const response = await window.authedClient.post(`ewapi/portfolios/0?${searchParams.toString()}`);
        return response.data.data;
      } catch (error) {
        return handleApiError(error);
      }
    }
  };

  const curriculumFunctionApi = createJsonApi({
    endpoint: 'portfolio_function',
    params: {
      include: `activities`
    }
  });

  const curriculumFunctionActivityApi = createJsonApi({
    endpoint: 'function_activity'
  });

  const curriculumLearningGoalApi = createJsonApi({
    endpoint: 'learning_goal',
    params: {
      include: `activities,evaluations`
    }
  });

  const curriculumLearningGoalActivityApi = createJsonApi({
    endpoint: 'learning_goal_activity'
  });

  const curriculumLearningGoalEvaluationApi = createJsonApi({
    endpoint: 'learning_goal_evaluation',
    params: {
      include: `courses`
    }
  });

  const curriculumFunctionExamplesApi = createJsonApi({
    endpoint: 'portfolio_function_examples'
  });

  const curriculumLearningGoalExamplesApi = createJsonApi({
    endpoint: 'portfolio_learning_goal_examples'
  });

  const curriculumLearningGoalActivityExamplesApi = createJsonApi({
    endpoint: 'portfolio_activity_examples'
  });

  return {
    curriculumApi,
    curriculumFunctionApi,
    curriculumFunctionActivityApi,
    curriculumLearningGoalApi,
    curriculumLearningGoalActivityApi,
    curriculumLearningGoalEvaluationApi,
    curriculumFunctionExamplesApi,
    curriculumLearningGoalExamplesApi,
    curriculumLearningGoalActivityExamplesApi
  };
};
