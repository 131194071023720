import { ThemeBrandTitle } from 'helpers/Helpers';
import { lmsBeforeEnter as beforeEnter } from 'pages/lms/LmsRouteActions';

export const lmsCourseDetailRoute = {
  name: 'LmsCourseDetail',
  pattern: '/lms/courses/:title/:id',
  meta: { title: `%%lms.navbar.courses%% | ${ThemeBrandTitle()}` },
  beforeEnter,
  onEnter: async (fromState, toState, routerStore) => {
    // load student detail on entry
    const { store } = routerStore.options;
    await store.fetchCourseDetail(toState.params.id);
  },
  onExit: async (fromState, toState, routerStore) => {
    // clear student detail on exit
    const { store } = routerStore.options;
    store.clearCourseDetail();
  }
};
