import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import { useHeader } from './useHeader';

const HeaderContext = createContext();

export const HeaderContextProvider = ({ isSidebarVisible, children }) => {
  const store = useHeader({ isSidebarVisible });

  // eslint-disable-next-line react/jsx-filename-extension
  return <HeaderContext.Provider value={store}>{children}</HeaderContext.Provider>;
};

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);

  if (context === undefined) {
    throw new Error('useHeaderContext must be used within a HeaderContextProvider');
  }

  return context;
};

HeaderContextProvider.propTypes = {
  isSidebarVisible: PropTypes.bool,
  children: PropTypes.node.isRequired
};
