import { Box, Grid, Button, Dialog, DialogTitle, DialogActions, Stack, Typography, InputAdornment, IconButton } from '@mui/material';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import { Form } from 'components/form';
import { MyTextField } from 'components/form/RHF';
import { getdirtyValues, ThemeBrandTitle } from 'helpers/Helpers';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { passwordResetValidation } from './beans/PasswordReset.ValidationSchema';

/*

The ProfileExtras component is a React functional component that provides a user interface for resetting a password. 
It manages the visibility of a password reset dialog, allows users to input their current and new passwords, and evaluates the strength of the new password. 
The component handles form submission, sending an API request to reset the password and displaying success or error messages accordingly. 
Users can also toggle the visibility of their passwords. Additionally, it features custom styling, including a colored strength indicator for the new password. 
The primary functionality includes password reset and password strength evaluation.
*/
const ProfileExtras = (props) => {
  const store = useContext(Store);
  const { t } = useTranslation();
  const { forced } = props;
  // Initial state is forced if provided, otherwise default to false
  const [open, setOpen] = useState(forced || false);
  const [showCurrent, setShowCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const brandTitle = ThemeBrandTitle();
  const { u, uw } = brandTitle !== 'E-WISE' ? { u: 'u', uw: 'uw' } : { u: 'je', uw: 'jouw' };

  const onClick = () => {
    setOpen(true);
  };
  // sonarcloud is showing security hotspot isues on regular expressions below. there is no risk here.
  const handleStrength = (value) => {
    if (value) {
      let strength = 'Weak';
      const strong = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{12,})/;
      const medium = /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{6,}))/;
      if (value.match(strong)) {
        strength = 'Strong';
      } else if (value.match(medium)) {
        strength = 'Medium';
      }
      return strength;
    }
    return null;
  };

  const initialValues = {
    current_password: '',
    new_password: '',
    repeat_password: ''
  };

  const request = async (data) => {
    const response = await store.lmsApi.ResetPassword(data);
    const hasError = response.data?.errors?.length > 0;
    if (hasError && response.data?.errors?.[0]?.title !== '') {
      store.showToast({ message: response.data?.errors[0]?.title, variant: 'error' });
      return null;
    }
    store.showToast({ message: t('account.password.changed', 'Password changed'), variant: 'success' });
    setOpen(false);
    // Just remove the session token and force the user to login again
    store.setResetPasswordForced();
    return response;
  };
  const handleSubmit = (values) => {
    const dirtyFields = getdirtyValues(values, initialValues);
    request(dirtyFields);
  };

  return (
    <>
      <Box padding="10px  30px" sx={{ backgroundColor: 'white', borderRadius: '12.8px' }}>
        <Grid container height="54px" marginTop="0px" marginBottom="0px" alignItems="center">
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" gap="5px">
              <Button className="reset_pass" onClick={onClick}>
                {t('account.password.resetPassword', 'Reset your password')}
              </Button>
              <Dialog open={open} className="resetForm" fullScreen={fullScreen}>
                <DialogTitle fontWeight="bold" color="#2B3674" fontSize="20px">
                  {t('account.password.resetPassword', 'Reset password')}
                </DialogTitle>

                {store.getPasswordForced() === '1' && (
                  <Box>
                    <Typography fontSize="16px" fontWeight="500">
                      {t('account.password.resetForcePasswordText', 'Please reset your password.')}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    marginTop: '16px'
                  }}
                >
                  <Typography fontSize="16px" fontWeight="500">
                    {t('account.password.resetPasswordTextLogout', { u, uw })}
                  </Typography>
                </Box>

                <Form initialValues={initialValues} validationSchema={passwordResetValidation} onSubmit={handleSubmit}>
                  {({ control, errors, reset, watch }) => {
                    const newValue = watch('new_password');
                    return (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <MyTextField
                              autoFocus
                              margin="normal"
                              name="current_password"
                              label={t('account.password.oldPassword', 'Old password')}
                              type={showCurrent ? 'text' : 'password'}
                              fullWidth
                              control={control}
                              error={!!errors?.current_password}
                              helperText={errors?.current_password?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        setShowCurrent(!showCurrent);
                                      }}
                                      edge="end"
                                    >
                                      {showCurrent ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MyTextField
                              autoFocus
                              margin="normal"
                              name="new_password"
                              label={t('account.password.newPassword', 'New password')}
                              type={showNew ? 'text' : 'password'}
                              fullWidth
                              control={control}
                              error={errors.new_password && true}
                              helperText={errors.new_password ? errors.new_password.message : window.i18next.t('passwordreset')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        setShowNew(!showNew);
                                      }}
                                      edge="end"
                                    >
                                      {showNew ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                          <Stack
                            direction="row"
                            style={{ height: '19px', width: '100%', padding: '0px 15px', alignItems: 'center' }}
                            spacing={1}
                          >
                            <Box
                              sx={{
                                width: '20%',
                                height: '3px',
                                backgroundColor:
                                  handleStrength(newValue) === 'Weak' ||
                                  handleStrength(newValue) === 'Medium' ||
                                  handleStrength(newValue) === 'Strong'
                                    ? '#760f4e'
                                    : '#f4f7fe'
                              }}
                            />
                            <Box
                              style={{
                                width: '20%',
                                height: '3px',
                                backgroundColor:
                                  handleStrength(newValue) === 'Medium' || handleStrength(newValue) === 'Strong' ? '#e27739' : '#f4f7fe'
                              }}
                            />
                            <Box
                              style={{
                                width: '20%',
                                height: '3px',
                                backgroundColor: handleStrength(newValue) === 'Strong' ? '#006846' : '#f4f7fe'
                              }}
                            />
                            <Box style={{ width: '40%', display: 'flex', justifyContent: 'center' }}>
                              <Typography fontSize="14px" color="rgba(0, 0, 0, 0.6)" fontWeight="500">
                                {handleStrength(newValue)}
                              </Typography>
                            </Box>
                          </Stack>
                          <Grid item xs={12}>
                            <MyTextField
                              autoFocus
                              margin="normal"
                              name="repeat_password"
                              label={t('account.password.confirmPassword', 'Confirm password')}
                              type={showConfirm ? 'text' : 'password'}
                              fullWidth
                              control={control}
                              error={!!errors?.repeat_password}
                              helperText={errors?.repeat_password?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        setShowConfirm(!showConfirm);
                                      }}
                                      edge="end"
                                    >
                                      {showConfirm ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                        </Grid>
                        <DialogActions>
                          <Button variant="contained" type="submit">
                            {t('account.password.save', 'Save')}
                          </Button>
                          {store.getPasswordForced() !== '1' && (
                            <Button
                              onClick={() => {
                                reset();
                                setOpen(false);
                              }}
                            >
                              {t('account.password.cancel', 'Cancel')}
                            </Button>
                          )}
                        </DialogActions>
                      </>
                    );
                  }}
                </Form>
              </Dialog>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

ProfileExtras.propTypes = {
  forced: PropTypes.any
};

export default ProfileExtras;
