/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { rawHTML } from 'helpers/Helpers';
/* MUI */
import { Box, LinearProgress } from '@mui/material';
import useJsonApi from 'hooks/useJsonApi';

// renders the author of a course to be displayed in the preview dialog
const CourseAuthor = ({ author }) => {
  const fetchUrl = `author/${author.id}`;
  const fetchQuery = `include=field_photo`;
  // HOOK
  const { response, loading, error } = useJsonApi(fetchUrl, fetchQuery);
  // - based on hook response
  if (loading || (!response && !error)) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }
  if (error) return <p>error...</p>;

  let authorUrl = false;
  if (response?.included) {
    authorUrl = response.included[0].attributes.uri.url;
  }

  return (
    <Box sx={{ display: 'flex', gap: '15px' }}>
      <Box>{authorUrl && <img style={{ width: '160px' }} src={`${process.env.REACT_APP_JSONAPI}/${authorUrl}`} alt={author.title} />}</Box>
      <Box>
        <Box sx={sxAuthorTitle}>{response?.data?.attributes?.title}</Box>
        <Box>{rawHTML(response?.data?.attributes?.body?.value)}</Box>
      </Box>
    </Box>
  );
};

CourseAuthor.propTypes = {
  author: PropTypes.object
};

export default observer(CourseAuthor);

const sxAuthorTitle = { fontSize: '18px', lineHeight: '34px', fontWeight: '500' };
