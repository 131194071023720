import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListItemButton, ListItemText, Collapse, InputLabel, Typography } from '@mui/material';

import { useStore } from 'hooks';
import { MyTextField } from 'components/form/RHF';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import * as Styles from '../../../components/styles';

const LearningGoalFormPart = ({ control, errors, setValue }) => {
  const { t } = useTranslation('curriculum');
  const store = useStore();

  const [isLearningGoalExamplesOpen, setIsLearningGoalExamplesOpen] = useState(false);

  const learningGoalExamples = store.curriculumLearningGoalExamples?.map((item) => ({
    name: item.attributes.description.value,
    value: item.attributes.description.value
  }));

  const handleClick = () => {
    setIsLearningGoalExamplesOpen(!isLearningGoalExamplesOpen);
  };

  return (
    <>
      <Typography type="p">{t('learningGoals.selectLearningGoalDescription')}</Typography>
      <List sx={Styles.TextField} component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={handleClick}>
          <InputLabel sx={{ width: '100%' }}>{t('learningGoals.selectLearningGoalDropdownLabel')}</InputLabel>
          {isLearningGoalExamplesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isLearningGoalExamplesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {learningGoalExamples?.map((item) => (
              <ListItemButton
                sx={{ pl: 4 }}
                key={item.value}
                onClick={() => {
                  setValue('learningGoalName', item.value);
                  setIsLearningGoalExamplesOpen(false);
                }}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>

      <MyTextField
        control={control}
        label={t('learningGoals.addLearningGoalNameTextfieldLabel')}
        name="learningGoalName"
        variant="outlined"
        fullWidth
        placeholder={t('learningGoals.addLearningGoalNameTextfieldPlaceholder')}
        sx={Styles.TextField}
        multiline
        rows={5}
        helperText={errors?.learningGoalName?.message}
        error={!!errors?.learningGoalName}
      />
    </>
  );
};

LearningGoalFormPart.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  setValue: PropTypes.func
};

export default observer(LearningGoalFormPart);
