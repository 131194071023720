import { useState, useEffect } from 'react';

/**
 * Hook to make an arbitrary fetch api request
 *
 * @returns {{response: unknown, error: unknown, loading: boolean}}
 * @param url
 * @param params
 */
const useAPI = (url, params) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const doFetch = async () => {
      setLoading(true);
      try {
        const res = await window.authedClient.get(url, params);
        setResponse(res.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    doFetch();
  }, [url, params]);
  return { response, error, loading };
};
export default useAPI;
