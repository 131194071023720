import { format, isValid, parseISO, set } from 'date-fns';
import { checkUserSignedIn } from 'state/RoutesActions';

function setQueryParams(queryParams, isEwiseLabel) {
  if (
    isValid(parseISO(queryParams.startdate)) &&
    isValid(parseISO(queryParams.enddate)) &&
    new Date(queryParams.startdate) <= new Date(queryParams.enddate) &&
    new Date(queryParams.enddate) < new Date()
  ) {
    return;
  }
  const currentDate = new Date();
  const currentYear = new Date().getFullYear();
  if (isEwiseLabel) {
    const startDate =
      currentDate.getMonth() < 7
        ? set(currentDate, { year: currentYear - 1, month: 7, date: 1 })
        : set(currentDate, { year: currentYear, month: 7, date: 1 });

    queryParams.startdate = format(startDate, 'yyyy-MM-dd');
  } else {
    queryParams.startdate = format(set(currentDate, { year: currentYear, month: 0, date: 1 }), 'yyyy-MM-dd');
  }
  queryParams.enddate = format(currentDate, 'yyyy-MM-dd');
}

export async function lmsBeforeEnter(fromState, toState, routerStore) {
  await checkUserSignedIn(fromState, toState, routerStore);
  const { store } = routerStore.options;
  await store.fetchLmsAccess();
  // eslint-disable-next-line camelcase
  const { enddate, org_id, profession, startdate } = fromState.queryParams;
  toState.queryParams = {
    enddate,
    org_id,
    profession,
    startdate,
    ...toState.queryParams
  };
  toState.params = {
    ...fromState.params,
    ...toState.params
  };
  const isEwiseLabel = store?.label === 'ewise';
  if (isEwiseLabel || store.showMenuItemLms) {
    setQueryParams(toState.queryParams, isEwiseLabel);
  } else {
    store.routerStore.goTo('home');
  }
}
