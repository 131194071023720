/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStore } from 'hooks';
// MUI
import { Grid, DialogContent, Box, Backdrop, CircularProgress } from '@mui/material';
/* Custom RHF components */
import { Form } from 'components/form';
import { MyTextField, Editor } from 'components/form/RHF';
//
import FormWidgetOptions from './FormWidgetOptions';
import FormActionBar from './FormActionBar';
import {
  numericalProps,
  textFieldProps,
  toggleDialogEditWidget,
  ToastWidgetSubmit,
  defaultRadioQuestionWidgetData,
  RadioSchema
} from './formWidgets.helper';

const FormRadioQuestion = ({ optionType }) => {
  const store = useStore();
  const [saving, setSaving] = useState(false);
  const widget = store.courseEdit.setEditWidget;

  // SETTING WIDGET DATA _______________________________________________
  const widgetData = JSON.parse(store.courseEdit.setEditWidget.attributes.widget_data);
  const setWidgetData = { ...defaultRadioQuestionWidgetData, ...widgetData };
  const defaultValues = { ...widget, attributes: { ...widget.attributes, widget_data: setWidgetData } };

  // SET CORRECT WIDGET TYPE
  defaultValues.attributes.widget_type = widget.attributes.widget_type.toLowerCase().includes('radio')
    ? 'Radio Question'
    : 'Check Question';

  // TOAST NOTIFICATIONS ________________________________________________
  const toast = ToastWidgetSubmit();

  // ON SUBMIT / HANDLE SUBMIT __________________________________________
  const handleFormSubmit = async (newWidget) => {
    setSaving(true);
    try {
      await store.courseEdit.patchPostWidget(newWidget);
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
      setSaving(false);
      toggleDialogEditWidget(store);
    } catch (error) {
      setSaving(false);
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
      toggleDialogEditWidget(store);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} initialValues={defaultValues} validationSchema={RadioSchema}>
      {({ control, errors, dirty, reset, setValue }) => (
        <>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={saving}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent sx={{ padding: '0px 24px' }}>
            {/* top */}
            <Grid container spacing={1} sx={sxGrid}>
              <Grid item xs={10}>
                <Box className="widget-question" sx={sxFlexColumnGap}>
                  <MyTextField
                    {...textFieldProps}
                    placeholder="Question title"
                    name="attributes.title"
                    label="title"
                    control={control}
                    error={errors?.attributes?.title}
                    helperText={errors?.attributes?.title && errors.attributes.title?.message}
                  />
                  <Box className="container-editor">
                    <Box>
                      <Editor label="question" field={{ name: 'attributes.widget_data.question' }} form={{ setValue, control }} />
                    </Box>
                  </Box>
                  <Box className="container-editor">
                    <Box>
                      <Editor label="feedback" field={{ name: 'attributes.widget_data.feedback' }} form={{ setValue, control }} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className="widget-type" sx={sxFlexColumnGap}>
                  <MyTextField {...textFieldProps} name="attributes.widget_type" label="type" control={control} disabled />
                  <MyTextField {...numericalProps} margin="normal" name="attributes.weight" label="weight" control={control} />
                  <MyTextField {...textFieldProps} name="attributes.widget_code" label="widget code" control={control} />
                </Box>
              </Grid>
            </Grid>
            {/* bottom */}
            <FormWidgetOptions
              optionType={optionType || 'radio'}
              control={control}
              errors={errors}
              dirty={dirty}
              reset={reset}
              defaultValues={defaultValues}
              setValue={setValue}
            />
          </DialogContent>
          <FormActionBar reset={reset} dirty={dirty} defaultValues={defaultValues} saving={saving} />
        </>
      )}
    </Form>
  );
};

FormRadioQuestion.propTypes = {
  optionType: PropTypes.string
};

export default observer(FormRadioQuestion);

// custom sx ___________________________________________________
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};
//
const sxFlexColumnGap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
};
