/* Core */
import { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';

/* MUI */
import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';

/* Helpers */ /* Custom components */
import { urlify } from 'state/Utils';
import { useIntersection } from 'hooks';
/* CARD Elements */
import CourseSymbols from './elements/CourseSymbols';
import PodcastIcon from './elements/PodcastIcon';
import CourseReactions from './elements/CourseReactions';
import HideCourse from './elements/HideCourse';
import CourseImage from './elements/CourseImage';
import CourseStatus from './elements/CourseStatus';
import CourseCategories from './elements/CourseCategories';
import CourseTitle from './elements/CourseTitle';
import CourseAuthors from './elements/CourseAuthors';
import CoursePoints from './elements/CoursePoints';

// Renders a course card with essential course information in a minimal list view.
const BasicCardListMin = (props) => {
  const store = useContext(Store);

  const {
    chip,
    categoriesArray,
    duration,
    courseTitle,
    authors,
    id,
    nid,
    courseImage,
    podcast,
    badges,
    extraLabel,
    lessons,
    hideCourse,
    reviewers,
    isTrialCourse
  } = props;
  const [visible, setVisible] = useState(false);

  const isCourseExpiring = store.courses.isCourseExpiring(nid);
  const nonTrialCourse = store.product.free_product && store.trialCourses && !isTrialCourse ? 'nonTrialCourse' : '';

  const imgRef = useRef();
  useIntersection(imgRef, () => setVisible(true)); // Updated arrow function here

  // eslint-disable-next-line arrow-body-style
  const gotoCourse = (courseId, title) => {
    if (store.courses.isSpecialAccessView) {
      return store.routerStore.goTo('specialAccessCourseIntroduction', {
        params: {
          sa: 'my',
          productName: 'special',
          product: 'access',
          packageId: '0',
          courseId: courseId.toString(),
          title: urlify(title)
        }
      });
    }
    return store.routerStore.goTo('courseIntroduction', {
      params: {
        productName: store.baseProduct.unique_name,
        product: store.selectedProductId.toString(),
        packageId: store.selectedPackageId.toString(),
        courseId: courseId.toString(),
        title: urlify(title)
      }
    });
  };

  return (
    <div className={`course_overview_card onboarding-course ${nonTrialCourse}`}>
      <Card className={`card__wrapper ${hideCourse ? 'hidden' : chip} `} data-categories={categoriesArray}>
        <Grid className="card__container" container>
          <Grid item xs={4} sm={3} md={4} className="course_overview_card_image" sx={{ display: 'flex' }}>
            <Box className="card_image_wrapper" position="relative" ref={imgRef}>
              <Box className="click__wrapper" onClick={() => gotoCourse(nid, courseTitle)}>
                <CourseImage visible={visible} src={courseImage} alt={courseTitle} goToCourse={gotoCourse} />
              </Box>
              <CourseStatus status={chip} isCourseExpiring={isCourseExpiring} nid={nid} />
              <CourseReactions id={id} lessons={lessons} nid={nid} />
            </Box>
          </Grid>
          <Grid item xs={8} sm={9} md={8} className="course_overview_card_info">
            <Box className="card_info_wrapper" onClick={() => gotoCourse(nid, courseTitle)}>
              <Box className="card__title">
                <CourseTitle courseTitle={courseTitle} courseId={nid} extraLabel={extraLabel} />
              </Box>
              {categoriesArray && (
                <Box className="card__categories">
                  <CourseCategories categories={categoriesArray} />
                </Box>
              )}
              <Box className="card__authors">
                <CourseAuthors authors={[...(authors || []), ...(reviewers || [])]} />
              </Box>
              <Box className="card__symbols__points">
                <Box className="card__symbols">
                  <CourseSymbols badges={badges} />
                </Box>
                <Box className="card__points">
                  {podcast && <PodcastIcon />}
                  {duration && <CoursePoints points={duration} />}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {hideCourse && <HideCourse id={id} />}
      </Card>
    </div>
  );
};

/* Our logo props */
BasicCardListMin.propTypes = {
  chip: PropTypes.string,
  categoriesArray: PropTypes.array,
  duration: PropTypes.string,
  badges: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  courseTitle: PropTypes.string,
  authors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  nid: PropTypes.number,
  id: PropTypes.string,
  courseImage: PropTypes.string,
  podcast: PropTypes.any,
  extraLabel: PropTypes.any,
  lessons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hideCourse: PropTypes.bool,
  reviewers: PropTypes.array,
  isTrialCourse: PropTypes.bool
};

export default observer(BasicCardListMin);
