import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useBreakpoint } from 'hooks';
import React, { useState } from 'react';

export const VtbTooltip = styled(({ className, ...props }) => <CustomTooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: '#2B3674',
      marginInline: 10,
      minWidth: 400,
      '@media (max-width: 600px)': {
        minWidth: 0
      },
      fontSize: theme.typography.pxToRem(12),
      borderRadius: 20,
      padding: 10,
      boxShadow: '0 15px 30px rgba(0,0,0,0.15)'
    },

    [`& .${tooltipClasses.arrow}::before`]: {
      backgroundColor: 'white'
    }
  })
);

// eslint-disable-next-line react/prop-types
const CustomTooltip = ({ children, ...props }) => {
  const breakpoint = useBreakpoint();
  const mobileBreakpoint = breakpoint === 'sm' || breakpoint === 'xs';
  const [open, setOpen] = useState(false);

  return (
    <Tooltip
      {...props}
      open={mobileBreakpoint ? open : undefined}
      onClose={mobileBreakpoint ? () => setOpen(false) : undefined}
      onOpen={mobileBreakpoint ? () => setOpen(true) : undefined}
      disableFocusListener={mobileBreakpoint}
      disableHoverListener={mobileBreakpoint}
    >
      <Box>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onTouchEnd: () => (mobileBreakpoint ? setOpen(true) : undefined)
            });
          }
          return child;
        })}
      </Box>
    </Tooltip>
  );
};
