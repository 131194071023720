import MainLayout from 'pages/mainLayout/MainLayout';
import CourseIntroduction from './CourseIntroduction';

const CourseIntroductionViewMap = {
  courseIntroduction: (
    <MainLayout footer background title="introduction.title">
      <CourseIntroduction />
    </MainLayout>
  )
};

export default CourseIntroductionViewMap;
