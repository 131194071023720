import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, Typography, CardMedia } from '@mui/material';
import { RouterLink, useRouterStore } from 'mobx-state-router';

const TeacherCard = ({ img, title, teacherId }) => {
  const { t } = useTranslation();
  const router = useRouterStore();
  const { product, productName, packageId } = router.routerState.params;

  return (
    <Box data-testid="teacherCard" className="teacherCard">
      <Box className="fullCard" display="flex">
        <Box className="teacher-content" display="flex" flexDirection="column" alignItems="center">
          <Box className="teacher-image">
            {img ? <CardMedia component="img" loading="lazy" image={img} /> : <Avatar data-testid="author-avatar" variant="rounded" />}
          </Box>
          <Box className="teacher-name">
            <Typography
              component="h3"
              variant="h2"
              fontWeight="bold"
              fontSize="1rem"
              color="primaryBlue.main"
              marginTop="auto"
              minHeight="40px"
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
      <RouterLink routeName="teacherDetail" params={{ product, productName, packageId, teacherId }} aria-label={`show ${title} detail`}>
        <Box
          sx={{
            backgroundColor: 'primary.main'
          }}
          className="readBio"
        >
          <Button>
            <Typography variant="caption" textAlign="center">
              {t('teachers.readBio')}
            </Typography>
          </Button>
        </Box>
      </RouterLink>
    </Box>
  );
};

TeacherCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  teacherId: PropTypes.string
};

export default TeacherCard;
