import MainLayout from 'pages/mainLayout/MainLayout';
import Faq3 from '../faq3';

const Faq3ViewMap = {
  faq3: (
    <MainLayout noPaper>
      <Faq3 />
    </MainLayout>
  )
};

export default Faq3ViewMap;
