import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { StageTitleBar, StageTitle } from '../components';
import EvaluationCard from './components/EvaluationCard';
import { useLearningGoals } from '../useLearningGoals';
import StageHeader from '../components/StageHeader';

const CurriculumEvaluations = () => {
  const { t } = useTranslation('curriculum');
  const { learningGoalsWithActivities } = useLearningGoals();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
      <StageHeader title={t('evaluations.title')} description={t('evaluations.description')} />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
        <StageTitleBar>
          <StageTitle title={t('evaluations.title2')} />
        </StageTitleBar>

        {learningGoalsWithActivities?.map((goal) => (
          <EvaluationCard key={goal.id} goal={goal} />
        ))}
      </Box>
    </Box>
  );
};

export default observer(CurriculumEvaluations);
