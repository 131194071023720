/* eslint-disable camelcase */
/* Core */
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';

/* MUI */
import {
  Box,
  Card,
  Grid,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  SvgIcon,
  Select,
  MenuItem,
  Pagination,
  CircularProgress,
  Divider
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import DownloadIcon from '@mui/icons-material/Download';
import CertificateTab from 'components/icons/CertificateTab';
import { translatePoints, GetTimeLabel } from 'helpers/Helpers';
import { startLmsApiClient } from 'state/api/LmsApi';

function getLastEightYears() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < 8; i++) {
    years.push(currentYear - i);
  }

  return years;
}

function getYearTimestamps(year) {
  // First moment of the year
  const startOfYear = new Date(year, 0, 1, 0, 0, 0, 0);
  const startTimestamp = startOfYear.getTime();

  // Last moment of the year
  const endOfYear = new Date(year + 1, 0, 1, 0, 0, 0, 0);
  const endTimestamp = endOfYear.getTime() - 1;

  return { startTimestamp, endTimestamp };
}

/*
The component renders a list of certificates earned by the user.
It displays the name of the course, the number of points earned,
the duration of the course in hours,
the date the certificate was earned,
and a button to download the certificate.
The list can be filtered by year using a dropdown menu.
*/

const limit = 10;
const Certificates = () => {
  const { t } = useTranslation();
  const store = useContext(Store);
  const [loading, setLoading] = useState([]);
  const [certificates, setCertificates] = useState(null);
  const [certificatesLoading, setCertificatesLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(t('account.certificate.filter.all'));
  const [page, setPage] = useState(0);

  useEffect(() => {
    const init = async () => {
      setCertificatesLoading(true);
      const { startTimestamp, endTimestamp } =
        selectedYear === t('account.certificate.filter.all')
          ? { startTimestamp: 0, endTimestamp: new Date().getTime() }
          : getYearTimestamps(selectedYear);

      const result = await startLmsApiClient().fetchCourseResults(store.login.uuid, {
        page: { offset: page * limit, limit },
        sort: '-certificate',
        filter: {
          completedDate: {
            condition: {
              value: startTimestamp / 1000,
              path: 'certificate',
              operator: '>'
            }
          },
          completedDateEnd: {
            condition: {
              value: endTimestamp / 1000,
              path: 'certificate',
              operator: '<'
            }
          }
        }
      });

      if (result?.data) {
        setCertificates(result.data);
      }

      setCertificatesLoading(false);
    };

    init();
  }, [page, selectedYear, store.login.uuid, t]);

  const possibleFilterYears = [t('account.certificate.filter.all'), ...getLastEightYears()];

  const handleDownload = async (id, index) => {
    setLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = true;
      return newLoading;
    });
    try {
      const code = await store.lmsApi.downloadCertificate(id);
      window.open(`${process.env.REACT_APP_JSONAPI}/course-result/${id}/${code}/certificate`, '_blank');
    } catch (error) {
      store.showToast({ message: t('introduction.sidebar.certificateError'), variant: 'error' });
    }
    setLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
  };

  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  if (certificatesLoading || !certificates) {
    return (
      <Box className="account_loader">
        <CircularProgress color="primary" style={{ width: '65px', height: '65px' }} />
      </Box>
    );
  }

  if (certificates?.meta?.count === 0 && selectedYear === t('account.certificate.filter.all')) {
    return (
      <Grid item xs={12} sm={12}>
        <Box display="flex" flexDirection="column" gap="23px" height="23vh" alignItems="center" justifyContent="center">
          <SvgIcon component={CertificateTab} color="primaryBlue" />
          <Typography variant="h1" color="#2B3674" fontSize="14px" fontWeight="700">
            {t('account.certificate.noCertificates', 'No certificates earned yet')}
          </Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Box alignItems="center" padding="17px 30px">
      <Grid container alignItems="center" marginTop="0px">
        <Grid item xs={12} sm={10}>
          <Typography fontWeight="bold" color="#2B3674" fontSize="20px">
            {t('account.certificate.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="certificate-date">{t('account.certificate.filter.label')}</InputLabel>
            <Select
              labelId="certificate-date"
              size="small"
              value={selectedYear}
              label={t('account.certificate.filter.label')}
              onChange={(e) => {
                setSelectedYear(e.target.value);
                setPage(0);
              }}
            >
              {possibleFilterYears.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {certificates?.meta?.count === 0 && (
        <Box display="flex" flexDirection="column" gap="23px" height="23vh" alignItems="center" justifyContent="center">
          <SvgIcon component={CertificateTab} color="primaryBlue" />
          <Typography variant="h1" color="#2B3674" fontSize="14px" fontWeight="700">
            {t('account.certificate.noCertificatesSelectedYear', 'No certifications achieved for the chosen year')}
          </Typography>
        </Box>
      )}
      <Box className="account_certificate">
        <Grid container spacing={{ xs: 2, md: 3 }} alignItems="center" paddingTop="0px" marginTop="0px" gap="3%">
          {certificates.data?.map((c, index) => {
            const { name, points, certificate, accreditation_unit_singular, accreditation_unit_plural } = c.attributes;

            const date = new Date(certificate).toLocaleDateString('nl-NL', options);
            const accrSingular = accreditation_unit_singular || 'uur';
            const accrPlural = accreditation_unit_plural || 'uren';
            const accr = points <= 1 ? accrSingular : accrPlural;

            const defPoints =
              parseFloat(points) > 1 && parseFloat(points).toString().includes('.') ? parseFloat(points).toFixed(1) : parseFloat(points);
            const getPoints = `${defPoints} ${translatePoints(accr, t)}`;
            const splitPoints = getPoints.split(' ');
            const getHours = parseFloat(splitPoints[0]);
            const isMinutes = getHours < 1 && splitPoints[1].includes('ur');
            const duration = isMinutes ? GetTimeLabel(getHours, true) : getPoints;

            return (
              <Grid item xs={12} sm={12} style={{ paddingTop: '0px' }} key={index}>
                <Card className="certificate_card">
                  <CardContent>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <Typography color="#2B3674" fontSize="18px" fontWeight="700">
                          {name}
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={8}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                          <Box>
                            <Box className="typography_box">
                              <Typography fontSize="14px" color="#2B3674" fontWeight="700">
                                {date}
                              </Typography>
                            </Box>
                            <Box className="typography_box">
                              <Typography fontSize="14px" color="#2B3674" fontWeight="700">
                                {t('account.certificate.points')}
                              </Typography>
                              <Typography fontSize="14px" color="#2B3674">
                                {name.includes("met baby's [module 4]") ? '22 uur' : duration}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4} className="download_grid">
                        <Box sx={{ textAlign: 'center' }}>
                          <LoadingButton
                            className="download_button"
                            size="small"
                            onClick={() => handleDownload(c.id, index)}
                            endIcon={<DownloadIcon color="white" />}
                            loading={loading[index]}
                            loadingPosition="end"
                            variant="contained"
                          >
                            Download
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
          {certificates?.meta?.count !== 0 && (
            <Grid item xs={12} container justifyContent="center">
              <Pagination
                count={Math.ceil(certificates.meta?.count / limit)}
                page={page + 1}
                onChange={(event, value) => setPage(value - 1)}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default observer(Certificates);
