import { useEffect, useState } from 'react';

/*
In summary, this code provides a utility for handling responsive breakpoints in a web application. 
It defines media query strings for various breakpoints, determines the initial breakpoint, and updates the current breakpoint based on window size changes. 
The useBreakpoint hook allows components to access the current breakpoint and respond to changes in screen size.
*/
const breakpoints = {
  xs: '(min-width : 0px) and (max-width : 599px)',
  sm: '(min-width : 600px) and (max-width : 899px)',
  md: '(min-width : 900px) and (max-width : 1279px)',
  lg: '(min-width : 1280px) and (max-width : 1919px)',
  xl: '(min-width : 1920px)'
};

const initialState = () => {
  if (window.matchMedia(breakpoints.xs).matches) {
    return 'xs';
  }
  if (window.matchMedia(breakpoints.sm).matches) {
    return 'sm';
  }
  if (window.matchMedia(breakpoints.md).matches) {
    return 'md';
  }
  if (window.matchMedia(breakpoints.lg).matches) {
    return 'lg';
  }
  if (window.matchMedia(breakpoints.xl).matches) {
    return 'xl';
  }
  return 'xl';
};

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState(initialState);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia(breakpoints.xs).matches) {
        setBreakPoint('xs');
      }
      if (window.matchMedia(breakpoints.sm).matches) {
        setBreakPoint('sm');
      }
      if (window.matchMedia(breakpoints.md).matches) {
        setBreakPoint('md');
      }
      if (window.matchMedia(breakpoints.lg).matches) {
        setBreakPoint('lg');
      }
      if (window.matchMedia(breakpoints.xl).matches) {
        setBreakPoint('xl');
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return breakpoint;
};

export default useBreakpoint;
