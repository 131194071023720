/* eslint-disable react/no-danger */
import { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { LinearProgress } from '@mui/material';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import { useTranslation } from 'react-i18next';
import { rawHTML } from 'helpers/Helpers';
import { hashString } from 'pages/play/questions/lib/hashString';
import useWidget from './useWidget';
import { seededShuffle } from './lib/seededShuffle';

const useStyles = makeStyles((theme) => ({
  group: {
    margin: `${theme.spacing}px 0`
  }
}));

const RadioQuestion = (props) => {
  const { store, widgetCode } = props;
  const { t } = useTranslation('widget');
  const classes = useStyles();
  const { question, widget, allowedTries, PlayWidget, answerDisabled } = useWidget(props);

  const { options, feedback, question: widgetAttributeQuestion } = widget.attributes;

  const rotatingAnswers = store.lessonPlay.lesson.attributes.rotating_answers;

  const [value, setValue] = useState(question.last_response ?? false);

  const seed = parseInt(store.lessonPlay.lesson_result.id.replace(/[^\d]/g, ''), 10) + hashString(widgetCode);

  const shuffledOptions = rotatingAnswers ? seededShuffle(options, seed) : options;

  const canSeeAnswers = store?.canSeeAnswers;

  const validateAnswer = () => {
    if (!value) {
      return false;
    }

    const { correct } = options.find((optionItem) => optionItem.value === value);

    return { value, correct };
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const radioOptions = () => {
    const disabled = answerDisabled || question.tries >= allowedTries || question.last_correct;

    return shuffledOptions.map((option) => {
      const checked = value === option.value;

      return (
        <FormControlLabel
          sx={{
            '& p:first-child': { marginTop: 0 },
            '& p:last-child': { marginBottom: 0 }
          }}
          key={option.value}
          control={<Radio color="primary" checked={checked} />}
          value={option.value}
          label={
            <div
              style={{
                padding: '8px 0',
                color: canSeeAnswers && option.correct ? '#006646' : 'inherit',
                textDecoration: canSeeAnswers && option.correct ? 'underline' : 'none'
              }}
            >
              {rawHTML(option.label)}
            </div>
          }
          disabled={disabled}
        />
      );
    });
  };

  // show feedback after an answer
  const showFeedback = () => {
    if (!question.tries) return null;
    const respAnswer = question.last_correct ? t('Correct') : t('Incorrect');

    const chosenOption = options.find((option) => option.value === question.last_response);

    return (
      <div className="feedback_block">
        <b dangerouslySetInnerHTML={{ __html: respAnswer }} className={question.last_correct ? 'correct-answer' : 'wrong-answer'} />
        {/* show specific feedback of choosen answer */}
        {chosenOption.feedback && <div className="feedback" dangerouslySetInnerHTML={{ __html: chosenOption.feedback }} />}
        {feedback && <div className="feedback-wrapper" dangerouslySetInnerHTML={{ __html: feedback }} />}
        {/* show general feedback (allways show) */}
        {showCorrectFeedback()}
        {question.tries < allowedTries && !question.last_correct && (
          <div
            className="retry"
            dangerouslySetInnerHTML={{
              __html: window.i18next.t('lesson.retry')
            }}
          />
        )}
      </div>
    );
  };

  // On second wrong answer show correct feedback (works for multiple question types)
  function showCorrectFeedback() {
    if (question.tries < allowedTries || question.last_correct) return null;
    return options
      .filter((option) => option.correct)
      .map((option, _index) => (
        <>
          <b>{t('The correct answer is')}</b>
          <div key={`feedback-${_index}`} dangerouslySetInnerHTML={{ __html: option.feedback }} />
        </>
      ));
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget touched={value} validateAnswer={validateAnswer} showFeedback={showFeedback}>
        <RadioGroup aria-label={widgetAttributeQuestion} name={widgetCode} className={classes.group} value={value} onChange={handleChange}>
          {radioOptions()}
        </RadioGroup>
      </PlayWidget>
    </>
  );
};

RadioQuestion.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  question: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  props: PropTypes.object,
  store: PropTypes.object
};

export default observer(RadioQuestion);
