import { useState, useEffect } from 'react';

/*
In summary, the useDebounce custom React Hook is used to debounce a value, which is often useful for scenarios 
like handling user input in search bars or reducing the frequency of requests in response to user interactions. 
It ensures that the value is only updated after a specified delay once the input has stopped changing.
*/
export default function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
