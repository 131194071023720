/* eslint-disable react/no-danger */
import { useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { getSnapshot } from 'mobx-state-tree';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import cloneDeep from 'lodash/cloneDeep';
import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Match from 'components/widgets/Match';
import { convertDefaultItems, convertToCorrectMatchAnswer, includeCorrectWrongOrderToLastResponse } from './MatchSortUtils';

import useWidget from './useWidget';

const MatchQuestion = (props) => {
  const { store, widgetCode } = props;
  const { t } = useTranslation('widget');
  const { question, widget, allowedTries, PlayWidget, answerDisabled } = useWidget(props);

  const widgetAttr = widget.attributes;
  const items = cloneDeep(getSnapshot(widgetAttr.options));

  const itemsAsCorrectMatchObject = convertToCorrectMatchAnswer(items);
  const defaultItems = convertDefaultItems(items);

  const touched = useRef(!!question.last_response);
  const lastResponse = question.last_response ? includeCorrectWrongOrderToLastResponse(question.last_response) : '';
  const isDisabled = answerDisabled || question.tries >= allowedTries || question.last_correct || false;

  const answer = useRef(lastResponse);
  const matchItems = touched.current ? lastResponse : defaultItems;

  const isAnswerCorrect = () => {
    const checkCorrect = [];
    delete answer.current.bench;
    const entries = Object.entries(answer.current);
    entries.forEach(([list, { items: value }]) => {
      value.map((item) => checkCorrect.push(Number(list.match(/\d+$/)) === parseInt(item.nr, 10))); // sonarcloud false positive on regular expression
    });

    return !checkCorrect.includes(false);
  };

  const handleInput = (value) => {
    answer.current = value;
  };
  const handleTouched = (value) => {
    touched.current = value;
  };
  const validateAnswer = () => {
    if (question.tries === 1 && answer.current?.bench && answer.current?.bench?.items?.length !== 0) return false;
    if ((answer.current?.bench && answer.current?.bench?.items?.length !== 0) || !answer.current) return false;
    const isCorrect = isAnswerCorrect();

    return { value: JSON.stringify(answer.current), correct: isCorrect };
  };

  const showFeedback = () => {
    if (!question.tries) return null;
    const correctMatch = (
      <Match
        items={itemsAsCorrectMatchObject}
        handleInput={handleInput}
        touched={touched.current}
        handleTouched={handleTouched}
        disabled={isDisabled}
      />
    );
    const isCorrect = isAnswerCorrect();

    const respAnswer = isCorrect ? t('Correct') : t('Incorrect');
    const tryAgain = !isCorrect && question.tries < allowedTries ? t('Try again') : '';
    const showCorrectAnswer = !isCorrect && question.tries === allowedTries ? t('The correct answer is') : '';
    const showCorrectMatch = !isCorrect && question.tries === allowedTries ? correctMatch : '';
    const feedback = isCorrect ? widgetAttr.feedbackCorrect : widgetAttr.feedbackFalse;

    return (
      <div className="feedback_block">
        <b dangerouslySetInnerHTML={{ __html: respAnswer }} className={isCorrect ? 'correct-answer' : 'wrong-answer'} />
        <div dangerouslySetInnerHTML={{ __html: feedback }} /> {/* show correct or incorrect feedback (always show) */}
        {/* show correct answer in case of 2nd answer wrong */}
        <div className="feedback" dangerouslySetInnerHTML={{ __html: showCorrectAnswer }} />
        {showCorrectMatch}
        {showCorrectFeedback()}
        <div dangerouslySetInnerHTML={{ __html: tryAgain }} className="retry" />
      </div>
    );
  };

  function showCorrectFeedback() {
    if (question.tries < allowedTries && !question.last_correct) return null;
    const correctFeedback = widgetAttr.options.feedback;
    return correctFeedback;
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget touched={touched.current} validateAnswer={validateAnswer} showFeedback={showFeedback} {...props}>
        <Match
          items={matchItems}
          handleInput={handleInput}
          touched={touched.current}
          handleTouched={handleTouched}
          disabled={isDisabled}
          {...props}
        />
      </PlayWidget>
    </>
  );
};

MatchQuestion.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  question: PropTypes.object,
  store: PropTypes.object
};

export default observer(MatchQuestion);
