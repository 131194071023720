import PropTypes from 'prop-types';
import { Autocomplete, FormControl, TextField } from '@mui/material';

const DropDown = ({ value, handleChange, options, label, placeholder }) => (
  <Autocomplete
    fullWidth
    variant="customAutocomplete"
    value={options?.find((option) => option?.profession_id === parseInt(value) || option?.organisation_id === parseInt(value)) || null}
    onChange={handleChange}
    options={options || []}
    getOptionLabel={(option) => option?.name}
    renderInput={(params) => (
      <FormControl variant="standard" fullWidth>
        <TextField
          {...params}
          placeholder={placeholder}
          label={label}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
        />
      </FormControl>
    )}
  />
);

export default DropDown;
DropDown.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any
};
