import { types as t } from 'mobx-state-tree';
import { File } from './BaseModel';

export const Banner = t.model('Banner', {
  id: t.identifier,
  type: t.string,
  attributes: t.model({
    branch: t.maybeNull(t.string),
    button_link: t.maybeNull(t.string),
    button_title: t.maybeNull(t.string),
    campaign_title: t.maybeNull(t.string),
    changed: t.maybeNull(t.string),
    chip: t.maybeNull(t.string),
    created: t.maybeNull(t.string),
    description: t.maybeNull(t.string),
    drupal_internal__nid: t.maybeNull(t.number),
    field_custom_css: t.maybeNull(t.string),
    field_expiring_date: t.maybeNull(t.string),
    field_schedule_date: t.maybeNull(t.string),
    field_title: t.maybeNull(t.string),
    order_weight: t.maybeNull(t.number),
    status: t.maybeNull(t.boolean),
    title: t.maybeNull(t.string)
  }),
  relationships: t.model({
    background_image: t.maybeNull(
      t.model({
        data: File
      })
    )
  })
});
