/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OAuth2 } from '../state/api/helpers/oauth2';
import createAuthenticatedClient from '../state/api/AuthenticatedClient';
import { getLabelNameFromDomain, convertAmplifyRedirectUrl } from '../helpers/Helpers';
import { createCustomTheme } from '../theme/CustomTheme';
import { useCustomTheme } from '../hooks';

export const OauthContext = React.createContext({});
export const AuthedClientContext = React.createContext({});

/**
 * The Oauth provider and context are responsible for decimating the
 * authentication for this app
 *
 * it is best thought of as a middleware wrapping
 * the entire application and only
 * allowing access with valid credentials
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OauthProvider = ({ children, LoaderComponent }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const currentTheme = getLabelNameFromDomain();
  const { setTheme, setCustomLogo } = useCustomTheme();

  /**
   * Helper function to convert theme id
   * to environment variables
   *
   * @param themeID
   * @param key
   * @returns {`REACT_APP_OAUTH_${string}_${string}`}
   */
  const convertThemeIDToEnvName = (themeID, key) => `REACT_APP_OAUTH_${themeID.toUpperCase().replaceAll('-', '_')}_${key}`;

  const oauthClient = new OAuth2({
    redirectUrl:
      currentTheme === 'amplify'
        ? convertAmplifyRedirectUrl(process.env[convertThemeIDToEnvName(currentTheme, 'REDIRECT_URL')])
        : process.env[convertThemeIDToEnvName(currentTheme, 'REDIRECT_URL')],
    clientId: process.env[convertThemeIDToEnvName(currentTheme, 'CLIENT_ID')],
    clientSecret: process.env[convertThemeIDToEnvName(currentTheme, 'CLIENT_SECRET')],
    authorizeEndpoint: process.env.REACT_APP_OAUTH_PROVIDER_AUTHORIZE_ENDPOINT,
    tokenEndpoint:
      process.env[convertThemeIDToEnvName(currentTheme, 'PROVIDER_DOMAIN')] + process.env.REACT_APP_OAUTH_PROVIDER_TOKEN_ENDPOINT,
    authorizationDomain: process.env[convertThemeIDToEnvName(currentTheme, 'PROVIDER_DOMAIN')]
  });

  /**
   * Register the behaviour for catching
   * requests made with bad creds
   */
  useEffect(() => {
    const oauthClientListener = () => {
      oauthClient.killSession();
    };

    /**
     * This is the event the api fires
     * when it detects invalid creds
     *
     * using the window as an eventbus as a hackaround
     */
    window.addEventListener('oauth_invalid', oauthClientListener);

    return () => {
      window.removeEventListener('oauth_invalid', oauthClientListener);
    };
  }, []);

  /**
   * We halt the rendering stack
   * until we have valid credentials
   */
  useEffect(() => {
    const init = async () => {
      try {
        const result = await oauthClient.initializeOauthSession();

        if (!result) {
          return;
        }

        const authenticatedClient = createAuthenticatedClient(oauthClient);

        window.clientInstance = oauthClient;
        window.authedClient = authenticatedClient;

        setIsAuthenticated(true);

        try {
          const response = await authenticatedClient.get('ewapi/lms/branding');

          const { is_branded: isBranded, data } = response.data;

          if (!isBranded || !data) {
            return;
          }

          const { brand_logo: brandLogo, brand_color_main: brandColorMain, brand_color_secondary: brandColorSecondary } = data;

          if (brandLogo) {
            setCustomLogo(`${process.env.REACT_APP_JSONAPI}${brandLogo}`);
          }

          if (brandColorMain || brandColorSecondary) {
            const customTheme = createCustomTheme({ primaryColor: brandColorMain, secondaryColor: brandColorSecondary });

            setTheme(customTheme);
          }
        } catch (err) {
          console.error('Theme could not be loaded', err);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  return (
    <>
      {isAuthenticated && (
        <OauthContext.Provider value={oauthClient}>
          <AuthedClientContext.Provider value={window.authedClient}>{children}</AuthedClientContext.Provider>
        </OauthContext.Provider>
      )}
      {!isAuthenticated && <LoaderComponent isLoading msg="LOADING" />}
    </>
  );
};

OauthProvider.propTypes = {
  children: PropTypes.object.isRequired,
  LoaderComponent: PropTypes.any
};

export default OauthProvider;
