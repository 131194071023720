import { Lazy } from 'components';
import EvaluationForm from '../evaluation/forms/EvaluationForm';

const EvaluationViewMap = {
  evaluation: (
    <Lazy noLayout>
      <EvaluationForm />
    </Lazy>
  )
};

export default EvaluationViewMap;
