/* Course status */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Store } from 'state/ContextStore';

/* MUI */
import { Chip } from '@mui/material';

// renders the course status
const CoursePackage = (props) => {
  const { packageLabel } = props;
  const store = useContext(Store);

  if (store.packages.length === 1) {
    return '';
  }
  return <Chip className="badge_course_status package" label={packageLabel} size="small" color="primary" sx={{ marginRight: '4px' }} />;
};

CoursePackage.propTypes = {
  packageLabel: PropTypes.string
};

export default CoursePackage;
