/* eslint-disable camelcase */
/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useState, useContext } from 'react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';

/* MUI */
import * as material from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setPrivacyStatementUrl } from 'helpers/Helpers';

/* Custom styling */
import 'theme/course/courseoverview/Quickview.scss';

/* Custom components */

const NewUserTerms = (props) => {
  const [check, setChecked] = useState(false);
  const { open, onClose } = props;
  const store = useContext(Store);
  const { t } = useTranslation();

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const getPatchUser = () => ({
    type: 'user',
    id: store.login.uuid,
    attributes: {
      field_accepted_gdpr: 1
    }
  });

  const saveGdpr = async () => {
    try {
      await store.lmsApi.patchpost(getPatchUser());
      store.showToast({ message: t('account.profile.gdpr', 'Thank you for accepting!'), variant: 'success' });
      store.setGdpr(true);
    } catch (error) {
      store.showToast({ message: t('account.profile.error', 'Something went wrong'), variant: 'error' });
      store.setGdpr(true);
    }
    onClose();
  };

  function handleClose(event, reason) {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      onClose();
    }
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <material.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        onClick={handleBackdropClick}
        maxWidth="md"
        className="general_dialog"
        fullScreen={fullScreen}
      >
        <Box display="flex" gap="10px" justifyContent="start" alignItems="center">
          <Box>
            <Typography variant="h2" fontSize="23px" color="primaryBlue.main">
              Algemene verordening gegevensbescherming (AVG)
            </Typography>
            <Typography variant="caption" color="#a3aed0">
              Laats bijgewerkt April 2020
            </Typography>
          </Box>
        </Box>
        <material.Divider style={{ borderColor: '#a3aed0', borderBottomWidth: 'initial' }} />
        <material.DialogContent>
          <Box className="course_overview_quickview_other" component="div">
            <Typography component="p" marginBottom="1em">
              Geachte deelnemer,
            </Typography>
            <Typography variant="subtitle" component="p" marginBottom="1em">
              In het kader van de nieuwe Europese privacywetgeving, de Algemene verordening gegevensbescherming (AVG) informeren wij u graag
              over de verwerking van uw resultaten.
            </Typography>
            Wanneer u een cursus met succes heeft afgerond wordt dat in ons systeem geregistreerd. Per afgeronde cursus gaat het om de
            volgende gegevens:
            <ul>
              <li>Datum afronding van de cursus </li>
              <li> Titel van de cursus </li>
              <li> Certificaatnummer </li>
              <li> Uw naam</li>
              <li> Uw voorletters</li>
              <li> Uw geboortedatum</li>
              <li> Uw registratienummer (indien van toepassing)</li>
              <li> Uw praktijk</li>
              <li> Het e-mailadres waaronder u inlogt.</li>
            </ul>
            <Typography component="p" marginBottom="1em">
              Er is géén administratie van het aantal pogingen dat u nodig heeft gehad om een cursus af te ronden en er is ook géén
              administratie van het percentage goed of fout beantwoorde vragen.
            </Typography>
            <Typography component="p" marginBottom="1em">
              De gegevens worden opgeslagen in uw eigen dossier dat u kunt openen onder “mijn certificaten”, alleen toegankelijk als u bent
              ingelogd met uw e-mailadres en wachtwoord. Afhankelijk van de afspraken met de verschillende beroepsregisters geven wij de
              door u afgeronde cursussen door. Indien uw werkgever dit product voor u heeft afgesloten krijgt uw werkgever ook inzicht in de
              door u afgeronde cursussen.
            </Typography>
            <Typography component="p" marginBottom="1em">
              Wilt u meer weten? Raadpleeg dan ons{' '}
              <a target="_blank" href={setPrivacyStatementUrl()} rel="noreferrer" style={{ color: theme.palette.primary.main }}>
                privacy statement
              </a>
              .
            </Typography>
            <Typography component="p" fontWeight="bold" marginBottom="1em">
              Wij verzoeken u vriendelijk om dit bericht te bevestigen door hieronder op akkoord te klikken zodat het niet meer verschijnt
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap="5px">
            <>
              <material.Checkbox
                value={check}
                sx={{ width: '20px', height: '20px', background: '#FAFAFA' }}
                onClick={() => {
                  setChecked(!check);
                }}
              />
              <Typography>Ik heb het gelezen en ga ermee akkoord.</Typography>
            </>
          </Box>
        </material.DialogContent>
        <material.DialogActions>
          <material.Button variant="contained" onClick={saveGdpr} disabled={!check}>
            Akkoord
          </material.Button>
        </material.DialogActions>
      </material.Dialog>
    </>
  );
};

NewUserTerms.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default observer(NewUserTerms);
