import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconDelete from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';

// renders a delete button with a tooltip
const useStyles = makeStyles((theme) => ({
  name: {
    margin: ''
  },
  fabDelete: { marginTop: '15px' },
  fabRed: {
    width: '100%',
    maxWidth: '200px',
    color: theme.palette.common.white,
    backgroundColor: red[700],
    marginTop: '15px',
    '&:hover': {
      backgroundColor: red[900]
    }
  }
}));

const DeleteButton = (props) => {
  const classes = useStyles();
  const { item, onClick, disabled } = props;
  const tooltipText = `Delete this ${item}`;
  const isDisabled = !!disabled;
  const { t } = useTranslation('widget');

  return (
    <Tooltip title={tooltipText} arrow>
      <span>
        <Button
          variant="contained"
          disabled={isDisabled}
          className={classNames(classes.fabDelete, classes.fabRed)}
          {...{ onClick: isDisabled ? undefined : onClick }}
          startIcon={<IconDelete />}
        >
          {t('edit.widget.delete', 'Delete widget')}
        </Button>
      </span>
    </Tooltip>
  );
};

DeleteButton.propTypes = {
  item: PropTypes.any,
  disabled: PropTypes.any,
  onClick: PropTypes.func
};

export default DeleteButton;
