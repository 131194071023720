import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/vimeo';
import { useWatch } from 'react-hook-form';
/* Helpers */
import { matchNumbersInString } from 'helpers/Helpers';
/* MUI */
import { Alert, Box, Grid } from '@mui/material';
/* RHF */
import { MyTextField } from 'components/form/RHF';
/* HOOKS */
import useDebounce from '../../../hooks/useDebounce';

const VideoPlayer = ({ control, name, label, helperText, getValue, ...otherProps }) => {
  const watchVimeo = useWatch({ control, name: 'lessonContainer.attributes.field_preview_video' });
  const stringHasNumbers = matchNumbersInString(watchVimeo);
  //
  const [vimeoUrl, setUrl] = useState('');
  const [isErrorPlayer, setErrorPlayer] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [urlVideo, setUrlVideo] = useState(getValue(name));
  //
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setUrlVideo(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setUrl(stringHasNumbers ? `https://vimeo.com/${stringHasNumbers[0]}` : false);
  }, [stringHasNumbers]);

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <MyTextField
            disabled={otherProps.disabled}
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
            helperText={helperText}
            label={label}
            name={name}
            control={control}
          />
        </Box>
        <Box>
          {urlVideo && (
            <Box className="preview-video">
              <ReactPlayer
                url={vimeoUrl || ''}
                playing={false}
                volume={0}
                width="100%"
                height="100%"
                onError={(error) => setErrorPlayer(error.message)}
                onReady={() => setErrorPlayer(false)}
              />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {isErrorPlayer && <Alert severity="warning">{`Error from the react player component: ${isErrorPlayer}`}</Alert>}
      </Grid>
    </>
  );
};

VideoPlayer.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  getValue: PropTypes.func,
  helperText: PropTypes.string,
  otherProps: PropTypes.object
};

export default observer(VideoPlayer);
