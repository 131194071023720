import { useContext } from 'react';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';
/* Custom styling */
import { Box, Alert, AlertTitle } from '@mui/material';
import { ThemeBrandTitle } from 'helpers/Helpers';

/**
 * This component is responsible for displaying an introductory message from a package to the user, providing the option to access more information in a popup dialog, and managing the presentation of additional content.
 */
const CourseOverviewIntroAccr = () => {
  const store = useContext(Store); // Use useContext to access the store
  const { t } = useTranslation();
  const brandTitle = ThemeBrandTitle();

  const selectedPackage = store.packages.filter((pack) => pack.nid === store.selectedPackageId);
  const selectedPackageBody = selectedPackage[0]?.body || '';
  const selectedPackageAccr = selectedPackage[0]?.accredited || '';
  const cssMargin = selectedPackageBody !== '' ? '16px' : '';
  const { u, kunt, heeft } = brandTitle !== 'E-WISE' ? { u: 'U', kunt: 'kunt', heeft: 'heeft' } : { u: 'Je', kunt: 'kan', heeft: 'hebt' };

  return (
    !selectedPackageAccr && (
      <Box className="course_overview_intro_accr" sx={{ marginTop: cssMargin }}>
        <Alert severity="info" variant="filled" color="info" sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <AlertTitle
            sx={{
              margin: '0px',
              '&::first-letter': {
                textTransform: 'capitalize'
              },
              textTransform: 'lowercase'
            }}
          >
            {store.routerState.routeName.includes('specialAccess')
              ? t('courseoverview.intro.accrSpecial', { u, heeft })
              : t('courseoverview.intro.accr', { u, kunt })}
          </AlertTitle>
        </Alert>
      </Box>
    )
  );
};
export default observer(CourseOverviewIntroAccr);
