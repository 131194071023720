/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useFieldArray } from 'react-hook-form';
// MUI
import { Grid, Stack, Box, Button, IconButton } from '@mui/material';
/* ICONS */
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
/* Custom RHF components */
import { Editor, MyTextField } from 'components/form/RHF';
// helpers
import { numericalProps } from './formWidgets.helper';

const FormSortOptions = ({ control, setValue }) => {
  const { fields, append, remove } = useFieldArray({ control, name: 'attributes.widget_data.options' });

  return (
    <Grid container spacing={1} sx={sxGrid}>
      <Grid item xs={12} container>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            append({
              label: `label${fields.length + 1}`,
              value: (fields.length + 1).toString(),
              startposition: (fields.length + 1).toString()
            });
          }}
          color="primary"
        >
          Antwoord
        </Button>
      </Grid>

      {fields.map((option, index) => (
        <Grid item key={option.id} xs={12} container>
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Stack spacing={2} direction="row" alignItems="center" sx={{ flex: 1 }}>
              <Box>{index + 1}</Box>
              <Box sx={sxFlexColumnGap}>
                <Box className="container-editor">
                  <Box>
                    <Editor
                      disabled={false}
                      label={`Antwoord ${index + 1}`}
                      field={{ name: `attributes.widget_data.options[${index}].label` }}
                      form={{ setValue, control }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="">
                <Box>
                  <MyTextField
                    sx={{ display: 'none' }}
                    {...numericalProps}
                    label="Startposition"
                    name={`attributes.widget_data.options[${index}].startposition`}
                    control={control}
                  />
                </Box>
              </Box>
              <Box className="">
                <Box>
                  <MyTextField
                    {...numericalProps}
                    label="Juiste volgorde"
                    name={`attributes.widget_data.options[${index}].value`}
                    control={control}
                  />
                </Box>
              </Box>
              <IconButton aria-label="delete" size="medium" onClick={() => remove(index)}>
                <HighlightOffIcon fontSize="inherit" color="error" />
              </IconButton>
            </Stack>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

FormSortOptions.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func
};

export default observer(FormSortOptions);

// custom sx ________________________________________________________
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};
//

const sxFlexColumnGap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
};
