/* Core */
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/* Mui */
import { Box, Button, Drawer, IconButton, Typography, keyframes, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BadgeIcon from '@mui/icons-material/Badge';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

/* Custom components */
import Logo from 'components/common/Logo';
import { SecondaryLogo } from 'components/common/SecondaryLogo';
import { SubscribeLink } from 'helpers/Helpers';
import { useBreakpoint, useStore } from 'hooks';
import PackageList from './PackageList';
import MyCompany from './MyCompany';
import SpecialAccess from './SpecialAccess';

const blink = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const LeftSideDrawer = () => {
  const store = useStore();
  const { t } = useTranslation();
  const [isManualMinimized, setIsManualMinimized] = useState(false);

  const breakpoint = useBreakpoint();
  const isNarrow = ['xs', 'sm', 'md'].includes(breakpoint);

  // const boxClassName = store.minDrawer ? 'right_sidebar' : 'right_sidebar_minimized';
  const boxClassName = store.minDrawer ? 'right_sidebar_minimized' : 'right_sidebar';

  const toggleMinDrawer = () => {
    setIsManualMinimized(!store.minDrawer);
    store.setMinDrawer(!store.minDrawer);
  };

  useEffect(() => {
    // If the screen is narrow, the drawer should be minimized
    // If the drawer is wide and not manually minimized, it should be maximized
    // Otherwise it should stay minimized
    if (isNarrow) {
      store.setMinDrawer(true);
    } else if (!isManualMinimized) {
      store.setMinDrawer(false);
    }
  }, [store, isManualMinimized, isNarrow]);

  const drawer = (
    <>
      <Box className="main_box">
        <Box className="header_logo">
          <Logo />
          <IconButton onClick={toggleMinDrawer} className="minimize_button">
            <MenuIcon />
          </IconButton>
        </Box>
        <Box className="drawer_footer_box">
          <Box className="package_list">
            <PackageList open={!store.minDrawer} />
            <SpecialAccess />
          </Box>
          <Box className="drawer_second_box">
            <MyCompany />
            {store.login.isImpersonation === true && (
              <>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                  <Box
                    className="right_side_footer"
                    sx={{
                      alignSelf: 'flex-end',
                      background: '#fff3e0',
                      color: 'orange',
                      maxHeight: '500px',
                      textAlign: 'center'
                    }}
                  >
                    <Typography variant="heading2" sx={{ textAlign: 'center', width: '100%' }}>
                      <BadgeIcon sx={{ fontSize: '32px' }} />
                    </Typography>
                    <br />
                    {!store.minDrawer && (
                      <Typography variant="heading3">
                        {t('impersonate.message')} <br />
                        <strong>{store.login.userName}</strong>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            )}
            <Stack sx={{ marginTop: 'auto' }}>
              <Box sx={{ padding: 1 }}>
                <SecondaryLogo />
              </Box>
              {store.login.isImpersonation !== true && store.showBtnSubscribe && (
                <Box
                  className="right_side_footer alert"
                  sx={{
                    alignSelf: 'flex-end',
                    maxHeight: '500px',
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="heading2" sx={{ textAlign: 'center', width: '100%' }}>
                    <NewReleasesIcon sx={{ fontSize: '40px', animation: `${blink} 2s linear infinite` }} />
                  </Typography>
                  <br />
                  {!store.minDrawer && (
                    <Typography variant="heading3">
                      <strong>{t('subscribe.title', 'Get access to all our courses')}</strong>
                    </Typography>
                  )}
                  <Button variant="contained" href={SubscribeLink(store).subscribeUrl} target="_blank" rel="noreferrer">
                    {t('subscribe.button', 'Subscribe today')}
                  </Button>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );

  return (
    <Box data-testid="left-side-drawer" className={boxClassName}>
      <Drawer
        variant="temporary"
        className={boxClassName}
        open={store.openDrawer}
        onClose={() => {
          store.toggleDrawer();
        }}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box' }
        }}
      >
        {drawer}
      </Drawer>

      <Drawer
        variant="permanent"
        open={store.openDrawer}
        id="permanent"
        onClose={() => {
          store.toggleDrawer();
        }}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box' },
          '& .MuiPaper-root:hover': {
            '& .Mui-selected': { backgroundColor: 'primary.main' }
          }
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default observer(LeftSideDrawer);
