import { Box, FormControlLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getYearsFromStartToCurrent } from 'pages/vtbDashboard/lib/getYearsFromStartToCurrent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { CertificateIcon } from 'pages/vtbDashboard/EventTypeTitleHead/CertificateIcon';
import { InformationIcon } from 'pages/vtbDashboard/EventTypeTitleHead/InformationIcon';

export const FilterBar = ({ onOnlyShowAvailableChange, onlyShowAvailableChange, year, onYearChange }) => {
  const { t } = useTranslation('vtb');

  const years = getYearsFromStartToCurrent(2024);

  return (
    <Box
      sx={{
        paddingBlock: { xs: '0.625rem', md: '0.875rem' },
        paddingInline: { xs: '1rem', md: '1.5rem' },
        backgroundColor: '#ffffff',
        borderRadius: { xs: '1rem', md: '0.75rem' },
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        overflow: 'auto'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="year">{t('filters.year')}</InputLabel>
          <Select value={year} label={t('filters.year')} onChange={(event) => onYearChange(event.target.value)} labelId="year">
            {years.map((yearOption) => (
              <MenuItem value={yearOption} key={yearOption}>
                {yearOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Switch onChange={(event) => onOnlyShowAvailableChange(event.target.checked)} checked={onlyShowAvailableChange} />}
          label={
            <Typography variant="body2" sx={{ minWidth: 'max-content' }}>
              {t('filters.onlyShowAvailableEvent')}
            </Typography>
          }
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CertificateIcon />
          <Typography variant="body1" sx={{ fontSize: 13, fontWeight: 500 }}>
            {t('filters.certificates')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 0 }}>
          <InformationIcon />
          <Typography variant="body1" sx={{ fontSize: 13, fontWeight: 500 }}>
            {t('filters.info')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

FilterBar.propTypes = {
  onOnlyShowAvailableChange: PropTypes.func.isRequired,
  onlyShowAvailableChange: PropTypes.bool,
  year: PropTypes.number,
  onYearChange: PropTypes.func.isRequired
};
