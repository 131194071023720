import { Box } from '@mui/material';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import { calculateProgressValue } from './utils';

const Progress = ({ id, lessons }) => {
  const store = useStore();

  if (!store.courseResults.courseResults.length) {
    return null;
  }

  const lessonResult = store.lesson_results.findLessonByCourseNid(id);
  const isCourseFinished = store.courseResults.isCourseFinished(id);
  const isCourseStarted = !isCourseFinished && store.courseResults.isCourseStarted(id);
  const progressValue = calculateProgressValue({ lessonResult, isCourseFinished, isCourseStarted, lessons });
  if (progressValue < 1 && !isCourseFinished) {
    return null;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.85)'
      }}
    >
      <CircularProgressWithLabel value={isCourseFinished ? 100 : progressValue} />
    </Box>
  );
};

Progress.propTypes = {
  id: PropTypes.number,
  lessons: PropTypes.any
};
export default observer(Progress);
