import { observer } from 'mobx-react';

import { Divider, Menu } from '@mui/material';

import { useStore } from 'hooks';

import MenuItemAccount from './MenuItemAccount';
import MenuItemAdminPanel from './MenuItemAdminPanel';
import MenuItemBackend from './MenuItemBackend';
import MenuItemXmlBrochure from './MenuItemXmlBrochure';
import MenuItemXmlPrint from './MenuItemXmlPrint';
import MenuItemCreatePdf from './MenuItemCreatePdf';
import MenuItemEditCourse from './MenuItemEditCourse';
import MenuItemEwiseUser from './MenuItemEwiseUser';
import MenuItemLogout from './MenuItemLogout';

import { useHeaderContext } from '../context';

const MainMenu = () => {
  const store = useStore();
  const { handleMenuSelect, anchorAccount, handleMenuClose, handlePopoverClose, isMenuAccountOpen } = useHeaderContext();

  return (
    <Menu
      id="simple-account"
      anchorEl={anchorAccount}
      open={isMenuAccountOpen}
      onClose={handleMenuClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      BackdropProps={{ invisible: true }}
      onMouseOut={handlePopoverClose}
      disableScrollLock
      sx={{ '& .MuiMenu-paper': { borderRadius: '15px' } }}
    >
      {/*
        Doing every element with a separate condition here, because
        MUI Menu doesn't accept fragments (e.g. <> .... </>) as children
      */}
      <MenuItemAccount handleMenuSelect={handleMenuSelect} />
      <Divider component="li" />

      {store.isEwiseUser && <MenuItemEwiseUser />}
      {store.isEwiseUser && <Divider component="li" />}

      {store.isAdministrator && <MenuItemBackend handleMenuSelect={handleMenuSelect} />}
      {store.isAdministrator && <Divider component="li" />}

      {store.canCreateXml && <MenuItemXmlPrint />}
      {store.canCreateXml && <MenuItemXmlBrochure />}
      {store.canCreateXml && <MenuItemCreatePdf />}
      {store.canCreateXml && <Divider component="li" />}

      {store.canEditCourse && <MenuItemEditCourse handleMenuSelect={handleMenuSelect} />}
      {store.canEditCourse && <Divider component="li" />}

      {store.canGotoAdminPanel && <MenuItemAdminPanel />}
      {store.canGotoAdminPanel && <Divider component="li" />}

      <MenuItemLogout handleMenuSelect={handleMenuSelect} />
    </Menu>
  );
};

export default observer(MainMenu);
