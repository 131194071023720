import { types as t } from 'mobx-state-tree';
// eslint-disable-next-line no-unused-vars
import cloneDeep from 'lodash/cloneDeep';
import { RelLesson } from './Lesson';
import { Links, IsoDate } from './BaseModel';

export const LessonResult = t
  .model('LessonResult', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__id: t.number,
      created: IsoDate,
      changed: IsoDate,
      status: t.string, // 0 = Not started --- 1 = Started --- 2 = Failure --- 3 = Succesfully finished
      page_id_max: t.number,
      course_result: t.maybeNull(t.number),
      score: t.number,
      questions_answered: t.number,
      sequence_no: t.number,
      current: t.boolean,
      data: t.maybeNull(t.string), // general field for serialized data
      is_lti: t.maybeNull(t.number),
      // Below are the deserialized fields
      pages: t.maybe(
        t.array(
          t.model({
            completed: t.boolean
          })
        )
      ),
      questions: t.maybe(
        t.array(
          t.model({
            questionId: t.string,
            type: t.string,
            weight: t.number,
            correct: t.optional(t.boolean, false),
            last_correct: t.optional(t.boolean, false),
            last_response: t.string,
            last_response_timestamp: IsoDate,
            first_response: t.string,
            first_response_timestamp: IsoDate,
            tries: t.number
          })
        )
      )
    }),
    relationships: t.maybeNull(
      t.model({
        lesson: RelLesson
        // user: RelUser,
        // course_result: t.maybe(RelCourseResult),
      })
    )
  })
  .preProcessSnapshot((snapshot) => {
    const newSnapshot = cloneDeep(snapshot);
    try {
      let { data } = newSnapshot.attributes;
      if (!data || data === '{}') data = { questions: [], pages: [] };
      else data = JSON.parse(data);
      if (!data.questions) data.questions = [];
      if (!data.pages) data.pages = [];
      newSnapshot.attributes = { ...newSnapshot.attributes, ...data };
    } catch (e) {
      console.error('Error parsing lesson result data', e);
    }
    return newSnapshot;
  });

export const RelLessonResult = t.model('RelLessonResult', {
  data: t.maybeNull(
    t.model({
      id: t.reference(LessonResult),
      type: t.string
    })
  ),
  links: Links
});

export function postProcessLessonResult(snapshot) {
  const newSnapshot = cloneDeep(snapshot);
  const attr = newSnapshot.attributes;
  delete attr.data;
  const serialized = {};

  if (attr.questions) {
    serialized.questions = attr.questions;
    delete attr.questions;
  }

  if (attr.pages) {
    serialized.pages = attr.pages;
    delete attr.pages;
  }

  attr.data = JSON.stringify(serialized);
  return newSnapshot;
}
