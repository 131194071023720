// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie11';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from 'App';
import smoothscroll from 'smoothscroll-polyfill';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { ThemeMeta } from './helpers/Helpers';

// Initialize sentry A.S.A.P to make sure all errors are handled (even non react ones)
Sentry.init({
  dsn: 'https://21712f117406e75094e9e32c9a3c931e@o4504849089757184.ingest.sentry.io/4505675190829056',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https:yourserver.io/api/']
    }),
    new Sentry.Replay(),
    new HttpClientIntegration({
      // This array can contain tuples of `[begin, end]` (both inclusive),
      // single status codes, or a combination of both.
      // default: [[500, 599]]
      failedRequestStatusCodes: [[500, 505], 507]
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

ThemeMeta(); // This will change some icons
smoothscroll.polyfill(); // kick off the polyfill for smooth vertically scrolling (only necessary for IE11 and Safari)
window.isIE11 = window.navigator.userAgent.indexOf('Trident/') >= 0;

// Some security message
window.console.log('%cStop!', 'font-size: 30px; color: red; font-weight: bold;');
window.console.log(
  '%cPlease note our content and product is copyrighted. Unauthorized use is prohibited.',
  'font-size: 21px; color: #000;'
);
window.console.log(
  '%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or "hack" someone\'s account, it is a scam and will give them access to your account.',
  'font-size: 14px; color: #000;'
);
window.console.log(
  '%cIn case of a security breach, contact security@reducate.com.',
  'font-size: 14px; color: #000;'
);

/**
 * Polyfill for IE-11
 */
if (window.isIE11) {
  // eslint-disable-next-line global-require,import/extensions
  require('./helpers/ie11domfull.js'); // Only ie11
}

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<App />, document.getElementById('root'));
