import { makeStyles } from '@mui/styles';

export const useCurriculumStyles = makeStyles(() => ({
  mySelect: {
    maxHeight: 250
  }
}));

export const TextField = {
  borderWidth: '0px',
  '& .MuiOutlinedInput-notchedOutline': {
    padding: '5px',
    border: 'none',
    boxShadow: '0px 3px 10px 2px rgba(0,0,0,0.10)',
    borderRadius: '10px'
  }
};

export const SelectBox = {
  paddingInline: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    padding: '5px',
    border: 'none',
    boxShadow: '0px 3px 10px 2px rgba(0,0,0,0.10)',
    borderRadius: '10px'
  }
};
