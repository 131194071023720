import { useCustomTheme } from '../../../../hooks';

const Logo = () => {
  const { labelLogo, customLogo } = useCustomTheme();

  return (
    <img
      src={customLogo ?? labelLogo}
      alt="logo"
      className="logo"
      id="logo"
      style={{ filter: 'drop-shadow(0px 0px 1px white)', transition: 'all .2s', borderRadius: '0px', height: '40px', maxHeight: '40px' }}
    />
  );
};

export default Logo;
