import { useContext } from 'react';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';

/* MUI */
import { List, ListItemButton, ListItemText } from '@mui/material';

const SpecialAccess = () => {
  const store = useContext(Store);
  if (!store?.login?.hasSpecialAccess) return <></>;

  const tabTitle = 'Special Access';

  const handleMenuSelect = (link) => () => {
    store.routerStore.goTo(link, { params: {}, queryParams: {} });
  };

  return (
    <List>
      <ListItemButton
        className="package-item"
        key={store.selectedPackageId}
        selected={store.routerState.routeName.includes('specialAccess')}
        component="a"
        onClick={handleMenuSelect('specialAccess')}
        sx={{
          '&.Mui-selected.MuiButtonBase-root:hover': { backgroundColor: 'primary.main' },
          '&.Mui-selected': { backgroundColor: 'primary.main' }
        }}
      >
        <ListItemText
          primary={
            <div className="lists">
              <span className="abr">{tabTitle.substring(0, 2)}</span>
              <div>{tabTitle}</div>
            </div>
          }
        />
      </ListItemButton>
    </List>
  );
};

export default observer(SpecialAccess);
