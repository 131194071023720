import { types as t } from 'mobx-state-tree';
import { RelPackages } from './Package';
import { RelUser } from './User';
import { DateRange, Body, IsoDate } from './BaseModel';

export const Contract = t
  .model('Contract', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__nid: t.number,
      langcode: t.string,
      status: t.boolean,
      title: t.string,
      body: t.maybeNull(Body),
      created: IsoDate,
      changed: IsoDate,
      promote: t.boolean,
      default_langcode: t.boolean,
      // subscription_status: t.maybeNull(t.string),
      contract: t.maybeNull(t.string),
      // guid: t.maybeNull(t.string),
      maximum_points: t.number,
      maximum_subscriptions: t.number,
      valid_period: DateRange
    }),
    relationships: t.model({
      packages: t.maybeNull(RelPackages),
      customer: RelUser
    })
  })
  .views((self) => ({
    get packages() {
      if (self.relationships.packages) {
        return self.relationships.packages.packagerefs;
      }
      return [];
    }
  }));

export const RelContract = t.model('RelContract', {
  data: t.maybeNull(
    t.model({
      id: t.reference(Contract),
      type: t.string
    })
  )
});
