import { observer } from 'mobx-react';
import { Typography, AppBar, Toolbar } from '@mui/material';
import { useStore } from 'hooks';
import { makeStyles } from '@mui/styles';
import Logo from 'components/common/Logo';

const useStyles = makeStyles(() => ({
  toolbar: {
    padding: '0',
    maxHeight: '68px'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  grow: {
    flexGrow: 1
  },
  logoWrapper: {
    marginLeft: '40px'
  },
  logoBackground: {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    height: '280px',
    outline: '5px solid rgba(255, 255, 255, 0.4)',
    position: 'absolute',
    right: '-110px',
    top: '-165px',
    transform: 'rotate(-47deg)',
    width: '250px',
    zIndex: '-1'
  }
}));

const Header = () => {
  const store = useStore();
  const classes = useStyles();

  const courseNid = store.routerStore.routerState.params.courseId;
  const courseUuid = store.findCourseByNid(Number(courseNid));
  return (
    <AppBar position="fixed">
      <Toolbar className={`${classes.toolbar} ${classes.overflowHidden}`}>
        <Typography variant="h6" color="inherit" fontSize="24px" fontWeight={400} noWrap marginLeft="22px">
          {courseUuid && courseUuid.course_title}
        </Typography>
        <div className={classes.grow} />
        <div className={classes.logoWrapper}>
          <Logo style={{ width: '81px', maxWidth: '81px' }} flex />
          <div className={classes.logoBackground} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default observer(Header);
