import { Typography, Box, Button, Card, CardHeader, IconButton, CardContent, CardActions, Avatar, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Notifications = () => (
  <Box style={{ padding: '24px' }}>
    <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
      <Box>
        <Typography variant="h1" fontSize="22px" color="#2B3674">
          Notifications
        </Typography>
        <Typography color="#A3AED0" fontSize="16px">
          2 new Notifications
        </Typography>
      </Box>
      <Box style={{ alignSelf: 'flex-start' }}>
        <Button variant="contained" style={{ color: '#FF5B5B', background: '#fdefee', borderRadius: '12.5px', boxShadow: 'none' }}>
          Clear Notifications
        </Button>
      </Box>
    </Box>
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Card style={{ borderRadius: '15px', boxShadow: ' 0px 18px 40px rgba(112, 144, 176, 0.12)', padding: '23px' }}>
        <CardHeader
          sx={{
            color: '#2B3674',
            [`& .MuiTypography-root`]: { fontSize: '18px' },
            [`& .MuiButtonBase-root`]: { background: '#FF5B5B', color: 'white', width: '17px', height: '17px' },
            [`& .MuiSvgIcon-root`]: { width: '13px' }
          }}
          action={
            <IconButton aria-label="settings">
              <CloseIcon />
            </IconButton>
          }
          title="Some headings for the Notifications"
        />
        <CardContent>
          <Typography color="#A3AED0" fontSize="16px">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry
          </Typography>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box style={{ display: 'flex', gap: '21px' }}>
            <Avatar style={{ width: '32px', height: '32px' }}>ER</Avatar>
            <Chip sx={{ [`.MuiChip-label`]: { color: 'white' } }} color="primary" label="2 PO" />
          </Box>
          <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography color="#A3AED0" fontSize="12px">
              45 minutes ago
            </Typography>
            <AccessTimeIcon style={{ width: '17px', height: '17px', color: '#2C3774' }} />
          </Box>
        </CardActions>
      </Card>
      <Card style={{ borderRadius: '15px', boxShadow: ' 0px 18px 40px rgba(112, 144, 176, 0.12)', padding: '23px' }}>
        <CardHeader
          sx={{
            color: '#2B3674',
            [`.MuiTypography-root`]: { fontSize: '18px' },
            [`.MuiButtonBase-root`]: { background: '#FF5B5B', color: 'white', width: '17px', height: '17px' },
            [`.MuiSvgIcon-root`]: { width: '13px' }
          }}
          action={
            <IconButton aria-label="settings">
              <CloseIcon />
            </IconButton>
          }
          title="Some headings for the Notifications"
        />
        <CardContent>
          <Typography color="#A3AED0" fontSize="16px">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry
          </Typography>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box style={{ display: 'flex', gap: '21px' }}>
            <Avatar style={{ width: '32px', height: '32px' }}>ER</Avatar>
            <Chip sx={{ [`.MuiChip-label`]: { color: 'white' } }} color="primary" label="2 PO" />
          </Box>
          <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography color="#A3AED0" fontSize="12px">
              45 minutes ago
            </Typography>
            <AccessTimeIcon style={{ width: '17px', height: '17px', color: '#2C3774' }} />
          </Box>
        </CardActions>
      </Card>
      <Card style={{ borderRadius: '15px', boxShadow: ' 0px 18px 40px rgba(112, 144, 176, 0.12)', padding: '23px' }}>
        <CardHeader
          sx={{
            color: '#2B3674',
            [`& .MuiTypography-root`]: { fontSize: '18px' },
            [`& .MuiButtonBase-root`]: { background: '#FF5B5B', color: 'white', width: '17px', height: '17px' },
            [`& .MuiSvgIcon-root`]: { width: '13px' }
          }}
          action={
            <IconButton aria-label="settings">
              <CloseIcon />
            </IconButton>
          }
          title="Some headings for the Notifications"
        />
        <CardContent>
          <Typography color="#A3AED0" fontSize="16px">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry
          </Typography>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box style={{ display: 'flex', gap: '21px' }}>
            <Avatar style={{ width: '32px', height: '32px' }}>ER</Avatar>
            <Chip sx={{ [`.MuiChip-label`]: { color: 'white' } }} color="primary" label="2 PO" />
          </Box>
          <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography color="#A3AED0" fontSize="12px">
              45 minutes ago
            </Typography>
            <AccessTimeIcon style={{ width: '17px', height: '17px', color: '#2C3774' }} />
          </Box>
        </CardActions>
      </Card>
    </Box>
  </Box>
);

export default Notifications;
