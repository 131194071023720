import { LinearProgress } from '@mui/material';
import { observer } from 'mobx-react';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PollStats from './PollStats';
import PollVote from './PollVote';

const PollWidget = (props) => {
  const { store, widgetCode } = props;
  const { t } = useTranslation('widget');
  const widget = store.lessonPlay.getPollWidget?.(widgetCode);
  const isFetching = useRef(false);
  const handleSubmit = (answer) => {
    try {
      if (!answer) {
        store.showToast({ message: t('pollWidget.vote.error.empty'), variant: 'error' });
        return;
      }
      store.lessonPlay.pollWidgetList.pollVote(widget, answer);
      store.showToast({ message: t('pollWidget.vote.success'), variant: 'success' });
    } catch (error) {
      store.showToast({ message: t('pollWidget.vote.error.failed'), variant: 'error' });
    }
  };
  const handleCancelVote = () => {
    store.lessonPlay.pollWidgetList.pollCancelVote(widget);
  };

  useEffect(() => {
    if (!widget && !isFetching.current) {
      isFetching.current = true;
      store.lessonPlay.fetchPollWidget(widgetCode);
    }
  }, [store.lessonPlay, widget, widgetCode]);

  if (!widget) {
    return null;
  }
  const { options, body: title, user_has_answered: userHasAnswered } = widget.poll;

  return userHasAnswered ? (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PollStats options={options} title={title} handleClick={handleCancelVote} />
    </>
  ) : (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PollVote options={options} title={title} onSubmit={handleSubmit} disable={store.lessonPlay.pageNo === 0} />
    </>
  );
};

PollWidget.propTypes = {
  widgetCode: PropTypes.string.isRequired,
  store: PropTypes.object
};

export default observer(PollWidget);
