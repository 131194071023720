import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

/* MUI */
import { Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const BtnNewCourse = ({ handleNewCourseModal }) => {
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();
  const sxNewCourseBtn = {
    border: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    '&:hover': { border: `4px solid ${theme.palette.primary.light}` }
  };

  return (
    <Button sx={sxNewCourseBtn} startIcon={<AddCircleIcon />} onClick={handleNewCourseModal} variant="outlined" color="primary">
      {t('dashboard.newCourse')}
    </Button>
  );
};

BtnNewCourse.propTypes = {
  handleNewCourseModal: PropTypes.func
};

export default observer(BtnNewCourse);
