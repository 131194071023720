import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const StageTitle = ({ title }) => (
  <Typography
    sx={{
      color: 'primaryBlue.main',
      fontSize: '20px'
    }}
  >
    {title}
  </Typography>
);

StageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default StageTitle;
