import { createContext, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useModal } from 'hooks';
import { Dialog } from '@mui/material';
import { createPortal } from 'react-dom';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const { modal, handleModal, modalContent } = useModal();
  return (
    <ModalContext.Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ModalContext, ModalProvider };
export const useModalContext = () => useContext(ModalContext);

const Modal = () => {
  const { modalContent, modal, handleModal } = useModalContext();

  const handleClose = () => {
    handleModal();
  };

  const element = document.querySelector('#modal-root') || document.querySelector('body');
  return createPortal(
    <Dialog open={!!modal} onClose={handleClose}>
      {modalContent}
    </Dialog>,
    element
  );
};
