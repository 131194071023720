import { createRouterState } from 'mobx-state-router';
import { ThemeBrandTitle } from 'helpers/Helpers';
import { getCertificates, checkUserSignedIn } from 'state/RoutesActions';

// This code gets the certificates for a user.
// It also checks whether the user is signed in.
// If the user is not signed in, it returns an error.
// If the user is signed in, it returns the certificates.

export const certificateRoute = {
  name: 'certificates',
  pattern: '/account/certificates',
  meta: { title: `%%account.certificate.title%% | ${ThemeBrandTitle()}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { params } = toState;
    // Check if the user is LTI and trying to reach this page
    if (store.getLtiSession() === 1 || store.getLtiSession() === '1') {
      return createRouterState('errorPage');
    }
    // Check if the user is an Ewise user and has not selected a base product
    if (store.isEwiseUser && !store.hasPreferredBaseproduct) {
      return createRouterState('selectProduct', { params: { user: store.login.userName } });
    }
    try {
      if (store.hasSubscriptions === 'init') await checkUserSignedIn(fromState, toState, routerStore, params.product);
    } catch (e) {
      return e;
    }
    return null;
  },
  onEnter: async (fromState, toState, routerStore) => getCertificates(fromState, toState, routerStore)
};
