import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { conditionalFields } from '../forms/beans/profileData';

const AccreditationFieldsMsg = ({ professions }) => (
  <Box>
    {professions?.map((profession) => {
      const msgArray = conditionalFields[profession.attributes.name]?.filter((fields) => fields.msg !== '').map((fields) => fields.msg);

      return (
        <Box key={profession.attributes.name}>
          {msgArray?.map((msg) => (
            <div key={profession.attributes.name} style={{ marginBottom: '16px' }}>
              {msg}
            </div>
          ))}
        </Box>
      );
    })}
  </Box>
);

AccreditationFieldsMsg.propTypes = {
  professions: PropTypes.any
};

export default AccreditationFieldsMsg;
