import { LinearProgress } from '@mui/material';
import Logo from './Logo';
import '../../theme/login/Login.scss';
import '../../theme/EwiseTheme';

const LoadingPage = () => (
  <div id="dialog-signin-loading" data-test="signin-loading">
    <LinearProgress />
    <div className="loading_logo">
      <Logo />
    </div>
  </div>
);

export default LoadingPage;
