import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Xarrow, { Xwrapper, useXarrow } from 'react-xarrows';

import { Icon } from '@iconify/react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';

import { useBreakpoint, useStore } from 'hooks';
import { Button, ConfirmationModal, DisclaimerDialog } from '../components';
import { CURRICULUM_STAGE, CURRICULUM_STEP } from '../constants';
import CurriculumActivities from './curriculumStageFunctions/CurriculumFunctions';
import CurriculumGoals from './curriculumStageLearningGoals/CurriculumLearningGoals';
import CurriculumEvaluation from './curriculumStageEvaluations/CurriculumEvaluations';
import { useEvaluations } from './useEvaluations';

const StageButton = ({ tooltipText, stage, handleOnClick, bottomText, active = false, disabled = false, btnId }) => {
  const theme = useTheme();

  const lightGreen = alpha(theme.palette.primary.main, 0.2);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '1 1 auto'
      }}
    >
      <Box
        sx={{
          padding: '0.25rem',
          borderRadius: '0.75rem',
          background: active ? 'primary.main' : 'transparent',
          display: 'grid',
          placeItems: 'center'
        }}
        id={btnId}
      >
        <Button
          tooltipText={tooltipText}
          square
          size="large"
          disabled={disabled}
          sx={{
            fontSize: '1.5rem',
            backgroundColor: active ? 'primary.main' : '#ffffff',
            color: active ? '#ffffff' : 'primary.main',
            '&:hover': {
              color: active ? 'primary.main' : '#ffffff',
              backgroundColor: active ? '#ffffff' : 'primary.main',
              boxShadow: active ? `${lightGreen} 0px 0px 0px 5px` : undefined
            },
            boxShadow: active ? `${lightGreen} 0px 0px 0px 5px` : undefined
          }}
          onClick={() => handleOnClick()}
        >
          {stage}
        </Button>
      </Box>
      <Typography
        sx={{
          marginTop: '0.5rem',
          fontWeight: '700',
          color: 'rgb(43, 54, 116)',
          textAlign: 'center'
        }}
      >
        {bottomText}
      </Typography>
    </Box>
  );
};

StageButton.propTypes = {
  btnId: PropTypes.string,
  tooltipText: PropTypes.string,
  stage: PropTypes.string,
  handleOnClick: PropTypes.func,
  bottomText: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

const CurriculumStagesLayout = () => {
  const updateXarrow = useXarrow();
  const layoutRef = useRef();
  const store = useStore();
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const { t } = useTranslation('curriculum');
  const { canFinalize } = useEvaluations();

  const [isDisclaimerDialogOpen, setIsDisclaimerDialogOpen] = useState(false);
  const [isConfirmFinalizeDialogOpen, setIsConfirmFinalizeDialogOpen] = useState(false);

  const {
    data: {
      attributes: { percentage_completed: percentageCompleted }
    }
  } = store.curriculum;

  const { stageId, uid } = store.params;
  const isSmallScreen = breakpoint === 'xs' || breakpoint === 'sm';

  const stages = Object.values(CURRICULUM_STAGE);
  const [currentStage, setCurrentStage] = useState(stages.includes(stageId) ? stageId : CURRICULUM_STAGE.functions);

  const isPreviousButtonDisabled = currentStage === CURRICULUM_STAGE.functions;

  const canGoToStep2 = percentageCompleted >= 25;
  const canGoToStep3 = percentageCompleted >= 50;

  const isNextButtonDisabled =
    currentStage === CURRICULUM_STAGE.evaluation ||
    (currentStage === CURRICULUM_STAGE.functions && !canGoToStep2) ||
    (currentStage === CURRICULUM_STAGE.learningGoals && !canGoToStep3);

  const shouldShowFinalizeButton = currentStage === CURRICULUM_STAGE.evaluation && percentageCompleted >= 50;
  const isFinalizeButtonDisabled = !canFinalize;

  const goToStage = async (stage) => {
    setCurrentStage(stage);

    await store.updateCurriculumStep(store.curriculum.data, CURRICULUM_STEP[stage]);

    // use replace state to prevent triggering a new route change and reloading data from the API
    window.history.replaceState({}, '', `/portfolio/${uid}/stages/${stage}`);

    // if we need to, we could use the routerStore to navigate to the new route and relaod data from the API
    // store.routerStore.goTo('curriculumStages', { params: { uid, stageId: stage } });
  };

  const nextStep = () => {
    if (currentStage === CURRICULUM_STAGE.functions) {
      goToStage(CURRICULUM_STAGE.learningGoals);
    }
    if (currentStage === CURRICULUM_STAGE.learningGoals) {
      goToStage(CURRICULUM_STAGE.evaluation);
    }
  };

  const prevStep = () => {
    if (currentStage === CURRICULUM_STAGE.evaluation) {
      goToStage(CURRICULUM_STAGE.learningGoals);
    }
    if (currentStage === CURRICULUM_STAGE.learningGoals) {
      goToStage(CURRICULUM_STAGE.functions);
    }
  };

  const onFinalizeBtnClick = () => {
    setIsConfirmFinalizeDialogOpen(true);
  };

  const onConfirmFinalizeBtnClick = async () => {
    setIsConfirmFinalizeDialogOpen(false);
    store.showToast({ message: t('general.pdfDownload'), variant: 'success' });
    await store.finalizeCurriculum(store.curriculum.data);
    await download();
  };

  const download = async () => {
    try {
      const result = await window.authedClient.get(`/portfolio/${uid}/access-pdf`);
      const downloadUrl = result?.data?.download_url;
      if (downloadUrl) {
        window.open(downloadUrl, '_blank');
        goBack();
      } else {
        store.showToast({ message: t('general.pdfDownloadError'), variant: 'error' });
      }
    } catch (error) {
      store.showToast({ message: t('general.pdfDownloadError'), variant: 'error' });
    }
  };

  const goBack = () => {
    store.routerStore.goTo('curriculumDashboard', {
      params: {
        type: 'active'
      }
    });
  };

  // Ugly hack to make sure the xarrow is rendered correctly on the first render
  useEffect(() => {
    setTimeout(updateXarrow, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Whenever the drawer is opened or closed, we need to update the xarrow
  useEffect(() => {
    setTimeout(updateXarrow, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.minDrawer]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={goBack}>
        <IconButton aria-label="" osx={{ background: 'primary' }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography>{t('dashboard.title')}</Typography>
      </Box>

      <Box
        ref={layoutRef}
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Xwrapper>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                paddingBlock: '0.75rem',
                paddingInline: { xs: '0.75rem', md: '5rem' },
                alignItems: 'flex-end',
                width: '100%',
                borderRadius: '0.75rem',
                columnGap: '0.5rem'
              }}
            >
              <StageButton
                btnId="stage-btn-1"
                tooltipText={t('progressBar.functionsTooltip')}
                bottomText={t('progressBar.functionsTitle')}
                handleOnClick={() => goToStage(CURRICULUM_STAGE.functions)}
                active
                stage="1"
              />
              <StageButton
                btnId="stage-btn-2"
                tooltipText={t('progressBar.learningGoalsTooltip')}
                bottomText={t('progressBar.learningGoalsTitle')}
                handleOnClick={() => goToStage(CURRICULUM_STAGE.learningGoals)}
                active={currentStage === CURRICULUM_STAGE.learningGoals || currentStage === CURRICULUM_STAGE.evaluation}
                disabled={!canGoToStep2}
                stage="2"
              />
              <StageButton
                btnId="stage-btn-3"
                tooltipText={t('progressBar.evaluationsTooltip')}
                bottomText={t('progressBar.evaluationsTitle')}
                handleOnClick={() => goToStage(CURRICULUM_STAGE.evaluation)}
                active={currentStage === CURRICULUM_STAGE.evaluation}
                disabled={!canGoToStep3}
                stage="3"
              />
            </Box>
            {!isSmallScreen && (
              <>
                <Xarrow
                  strokeWidth={2}
                  curveness={0.3}
                  _cpy1Offset={-45}
                  _cpx1Offset={-15}
                  _cpy2Offset={45}
                  _cpx2Offset={15}
                  dashness={{
                    animation: false
                  }}
                  start="stage-btn-1"
                  headShape="circle"
                  headSize={4}
                  lineColor={currentStage === CURRICULUM_STAGE.functions ? '#ccc' : theme.palette.primary.main}
                  headColor={currentStage === CURRICULUM_STAGE.functions ? '#ccc' : theme.palette.primary.main}
                  tailColor={currentStage === CURRICULUM_STAGE.functions ? '#ccc' : theme.palette.primary.main}
                  showTail
                  tailShape="circle"
                  tailSize={4}
                  end="stage-btn-2"
                  lab
                />
                <Xarrow
                  strokeWidth={2}
                  curveness={0.3}
                  _cpy2Offset={-45}
                  _cpx2Offset={-15}
                  _cpy1Offset={45}
                  _cpx1Offset={15}
                  dashness={{
                    animation: false
                  }}
                  start="stage-btn-2"
                  headShape="circle"
                  headSize={4}
                  showTail
                  tailShape="circle"
                  tailSize={4}
                  end="stage-btn-3"
                  lineColor={currentStage !== CURRICULUM_STAGE.evaluation ? '#ccc' : theme.palette.primary.main}
                  headColor={currentStage !== CURRICULUM_STAGE.evaluation ? '#ccc' : theme.palette.primary.main}
                  tailColor={currentStage !== CURRICULUM_STAGE.evaluation ? '#ccc' : theme.palette.primary.main}
                />
              </>
            )}
          </Box>
          {!isSmallScreen && (
            <Box>
              <Button
                tooltipText="Help"
                onClick={() => setIsDisclaimerDialogOpen(true)}
                shadow
                inverted
                sx={{
                  width: '3rem',
                  height: '3rem'
                }}
              >
                <Icon icon="fluent-emoji-high-contrast:white-question-mark" />
              </Button>
            </Box>
          )}
        </Xwrapper>
      </Box>

      <Box sx={{ marginTop: '1.25rem' }}>
        {currentStage === CURRICULUM_STAGE.functions && <CurriculumActivities />}
        {currentStage === CURRICULUM_STAGE.learningGoals && <CurriculumGoals />}
        {currentStage === CURRICULUM_STAGE.evaluation && <CurriculumEvaluation />}
      </Box>

      <Box sx={{ display: 'flex', gap: '0.5rem', marginTop: '0.5rem', justifyContent: 'flex-end' }}>
        <Button disabled={isPreviousButtonDisabled} onClick={prevStep} tooltipText={t('general.previousBtnTooltip')}>
          <Icon icon="akar-icons:arrow-left" />
          {t('general.previousBtnLabel')}
        </Button>
        {!shouldShowFinalizeButton && (
          <Button disabled={isNextButtonDisabled} onClick={nextStep} tooltipText={t('general.nextBtnTooltip')}>
            {t('general.nextBtnLabel')}
            <Icon icon="akar-icons:arrow-right" />
          </Button>
        )}
        {shouldShowFinalizeButton && (
          <>
            <Button disabled={isFinalizeButtonDisabled} onClick={onFinalizeBtnClick} tooltipText={t('general.finalizeBtnTooltip')}>
              {t('general.finalizeBtnLabel')}
              <Icon icon="akar-icons:arrow-right" />
            </Button>
          </>
        )}
      </Box>

      <DisclaimerDialog open={isDisclaimerDialogOpen} onCloseCallback={() => setIsDisclaimerDialogOpen(false)} />

      <ConfirmationModal
        title={t('evaluations.confirmFinalizeTitle')}
        text={t('evaluations.confirmFinalizeDescription')}
        isVisible={isConfirmFinalizeDialogOpen}
        onCancel={() => setIsConfirmFinalizeDialogOpen(false)}
        onConfirm={onConfirmFinalizeBtnClick}
      />
    </>
  );
};
export default observer(CurriculumStagesLayout);
