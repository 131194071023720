/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

/* Custom components */
import { Paper, Typography, Box, SvgIcon } from '@mui/material';
import { TabComponent } from 'components';
import { useBreakpoint } from 'hooks';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import CertificateTab from 'components/icons/CertificateTab';
import SubscribeTab from 'components/icons/SubscribeTab';

/*
In summary, the AccountSidebar component is used for rendering a sidebar navigation menu with tabs. 
The tabs are configured based on the user's role (with administrators having access to an additional "Settings" tab). 
The component also handles internationalization for text content and adjusts the orientation of the tabs based on the screen size. 
It appears to be part of a user account or profile section of a web application.
*/
const AccountSidebar = () => {
  const store = useContext(Store);
  const mobileBreakpoint = useBreakpoint();
  const orientationChange = mobileBreakpoint === 'sm' || mobileBreakpoint === 'xs' || (mobileBreakpoint === 'md' && !store.minDrawer);
  const { t } = useTranslation();
  const tabItems = [
    {
      id: 'profile',
      label: (
        <Box className="tab_label">
          <PersonIcon /> {t('account.profile.title')}
        </Box>
      ),
      nestedTable: false,
      callback: () => {
        store.routerStore.goTo('profile');
      }
    },
    {
      id: 'certificates',
      label: (
        <Box className="tab_label">
          <SvgIcon component={CertificateTab} />
          {t('account.certificate.tab')}
        </Box>
      ),
      nestedTable: true,
      callback: () => {
        store.routerStore.goTo('certificates');
      }
    },
    {
      id: 'subscription',
      label: (
        <Box className="tab_label">
          <SvgIcon component={SubscribeTab} />
          {t('account.subscription.title')}
        </Box>
      ),
      nestedTable: false,
      callback: () => {
        store.routerStore.goTo('subscription');
      }
    }
  ];
  const tabSettings = {
    id: 'settings',
    label: (
      <Box className="tab_label">
        <SettingsIcon /> {t('account.settings.title')}
      </Box>
    ),
    nestedTable: false,
    callback: () => {
      store.routerStore.goTo('settings');
    }
  };
  // SETTINGS TAB IS VISABLE FOR ADMINISTRATORS ONLY
  if (store.isAdministrator) tabItems.push(tabSettings);

  return (
    <Paper className="account_sidebar">
      <Typography fontWeight="bold" color="#2B3674" fontSize="20px">
        {t('account.title')}
      </Typography>
      <TabComponent
        selectedTab={store.routerStore.routerState.routeName}
        variant="scrollable"
        orientation={orientationChange ? 'horizontal' : 'vertical'}
        indicatorColor="primary"
        tabs={tabItems}
      />
    </Paper>
  );
};

export default observer(AccountSidebar);
