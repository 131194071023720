import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import { LtiLaunchUrl } from 'helpers/Helpers';

/* MUI */
import { Button, Box } from '@mui/material';

const CourseLtiButton = (props) => {
  const { courseId, hideBg } = props;
  const { t } = useTranslation();
  const store = useStore();
  const launchUrl = LtiLaunchUrl();

  const handleCopyCourseId = useCallback(() => {
    const courseParam = `${launchUrl}?course_id=${courseId}`;
    navigator.clipboard
      .writeText(courseParam)
      .then(() => store.showToast({ message: t('lti.copy.msgSuccess', 'Link copied'), variant: 'success' }))
      .catch((err) => store.showToast({ message: err, variant: 'error' }));
  }, [courseId, launchUrl, store, t]);

  return (
    <Box
      sx={{
        padding: hideBg ? '0px' : '7px',
        paddingLeft: '0px',
        width: hideBg ? 'auto' : '100%'
      }}
    >
      <Button variant="contained" onClick={handleCopyCourseId} sx={{ backgroundColor: '#e27739', fontSize: '9px !important' }}>
        {t('LTI Deeplink')}
      </Button>
    </Box>
  );
};

CourseLtiButton.propTypes = {
  courseId: PropTypes.bool, // Ensures courseId is a string
  hideBg: PropTypes.number // Ensures hideBg is a number
};

export default CourseLtiButton;
