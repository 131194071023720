import { useEffect } from 'react';
import PropTypes from 'prop-types';

const GTMProvider = ({ containerId, children }) => {
  useEffect(() => {
    // Check if the user meets certain conditions
    // GTM script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${containerId}');
    `;

    // GTM noscript
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${containerId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style = 'display:none;visibility:hidden';

    // Append elements to head and body
    document.head.appendChild(script);
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
    // If not an allowed user, do nothing
  }, [containerId]);

  return <>{children}</>;
};

GTMProvider.propTypes = {
  containerId: PropTypes.string,
  children: PropTypes.object.isRequired
};

export default GTMProvider;
