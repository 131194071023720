import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useStore } from '../../../hooks';

export const RevokeButton = ({ contractId, subscriptionId, onSuccess, deleteType }) => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();
  const btnLabel = deleteType === 'delete' ? t('assistant.revoke.btn1') : t('assistant.revoke.btn2');
  const popupTitle = deleteType === 'delete' ? t('assistant.revoke.popupTitle1') : t('assistant.revoke.popupTitle2');
  const popupMsg = deleteType === 'delete' ? t('assistant.revoke.popupMsg1') : t('assistant.revoke.popupMsg2');
  const popupBtn = deleteType === 'delete' ? t('assistant.revoke.popupBtn1') : t('assistant.revoke.popupBtn2');
  const successMsg = deleteType === 'delete' ? t('assistant.revoke.msgSuccess1') : t('assistant.revoke.msgSuccess2');

  const onClick = async () => {
    setOpenDialog(true);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      params.append('contract_id', contractId);
      params.append('subscription_id', subscriptionId);

      await window.authedClient.post(`ewapi/assistant_manager/remove?${params.toString()}`);
      await store.showToast({ message: successMsg, variant: 'success' });
      await onSuccess();
    } catch (err) {
      await store.showToast({ message: t('assistant.revoke.msgError'), variant: 'error' });
    }
    setIsLoading(false);
    setOpenDialog(false);
  };

  return (
    <>
      <LoadingButton variant="outlined" color="error" onClick={onClick} loading={isLoading}>
        {btnLabel}
      </LoadingButton>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{popupTitle}</DialogTitle>
        <DialogContent>{popupMsg}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>{t('assistant.revoke.btnCancel')}</Button>
          <Button onClick={handleConfirm} variant="contained" color="error">
            {popupBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RevokeButton.propTypes = {
  contractId: PropTypes.number.isRequired,
  subscriptionId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  deleteType: PropTypes.string
};