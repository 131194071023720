export const attachRelation = (parentData, relationName, relationType, relationId) => {
  const relationships = parentData?.relationships || {};
  const relations = Object.hasOwn(relationships, relationName) ? parentData?.relationships[relationName] : {};
  const relationsData = relations?.data || [];

  return {
    ...parentData,
    relationships: {
      ...relationships,
      [relationName]: {
        ...relations,
        data: [
          ...relationsData,
          {
            type: relationType,
            id: relationId
          }
        ]
      }
    }
  };
};
