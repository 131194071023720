// eslint-disable-next-line import/no-extraneous-dependencies
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

/* MUI */
import { Box, Badge } from '@mui/material';
import { TabComponent } from 'components';
import { useStore } from 'hooks';

// Renders the tabs for the course overview page
const CourseTabs = () => {
  const store = useStore();
  const { t } = useTranslation();

  const changeCourseOverview = (mode) => () => {
    store.setStoreValue('courseHeaderTabs', mode);
    if (mode === 'All' && store.courses.isFiltering) {
      store.courses.resetFilter();
    }
  };

  const courseTotal = !store.loadingCourses ? '0' : store.courses?.coursesCount?.toString();
  const favoriteTotal = !store.loadingCourses || store.favorites === null ? '0' : store.favorites.favorite_courses.length.toString();
  const finishedTotal = !store.loadingCourses ? '0' : store.courseResults?.getFinishedCourses().length.toString();
  const afterHide = store.hidden?.hidden_courses.length
    ? store.courseResults?.getShownCourses(store.hiddenIds).length.toString()
    : store.courseResults?.getStartedCourses().length.toString();
  const startedTotal = !store.loadingCourses ? '0' : afterHide;

  const tabDefaultItems = [
    {
      id: 'All',
      label: (
        <Badge className="onboarding-filter" badgeContent={courseTotal} color="primary" max={999}>
          {t('courseoverview.tabs.tab1_label', 'All courses')}
        </Badge>
      ),
      callback: changeCourseOverview('All')
    },
    {
      id: 'Started',
      label: (
        <Badge data-testid="tab-started-courses" badgeContent={startedTotal} color="primary">
          {t('courseoverview.tabs.tab2_label', 'Started')}
        </Badge>
      ),
      callback: changeCourseOverview('Started')
    }
  ];
  const tabExtraItems = [
    {
      id: 'Favourites',
      label: (
        <Badge data-testid="tab-favourite-courses" badgeContent={favoriteTotal} color="primary">
          {t('courseoverview.tabs.tab3_label', 'My favourites')}
        </Badge>
      ),
      callback: changeCourseOverview('Favourites')
    },
    {
      id: 'Finished',
      label: (
        <Badge data-testid="tab-finished-courses" color="primary" badgeContent={finishedTotal}>
          {t('courseoverview.tabs.tab4_label', 'Finished')}
        </Badge>
      ),
      callback: changeCourseOverview('Finished')
    }
  ];
  const tabItems = store.courses?.isSpecialAccessView ? [...tabDefaultItems] : [...tabDefaultItems, ...tabExtraItems];

  return (
    <Box
      data-intro="Intro.js can highlight on elements"
      className="course_overview_tabs"
      sx={{
        [`.MuiButtonBase-root`]: { backgroundColor: 'background.main', color: 'tabPrimaryBlue.main' },
        [`.MuiButtonBase-root.Mui-selected`]: { backgroundColor: 'primary.main', [`.MuiBadge-badge`]: { color: 'primary.main' } }
      }}
    >
      <TabComponent
        variant="scrollable"
        orientation="horizontal"
        allowScrollButtonsMobile
        selectedTab={store.courseHeaderTabs}
        tabs={tabItems}
      />
    </Box>
  );
};

export default observer(CourseTabs);
