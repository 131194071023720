import DialogBase from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const Dialog = styled(DialogBase)(() => ({
  '& .MuiPaper-root': { minWidth: '500px !important' },
  '& .MuiDialogTitle-root': {
    fontSize: '20px',
    textAlign: 'left !important',
    padding: '0 0 8px 0'
  },
  '& .MuiDialogContent-root': {
    padding: '0 0 20px 0'
  },
  '& .MuiDialogActions-root': {
    padding: '0 0 8px 0',
    paddingLeft: '0px',
    paddingRight: '0px !important'
  }
}));

export default Dialog;
