/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
/* MUI */
import { Box } from '@mui/material';

// container component for the authors of a course
// - renders a list of authors of a course to be displayed in the preview dialog
const CourseIntroductionAuthors = (props) => {
  const { authors } = props;
  if (!authors.length) return '';
  // Authors > 2: map and join titles to show in tooltip
  const showAuthors = authors.map((item) => item.fullName).join(', ');
  return <Box className="authors-wrapper">{showAuthors}</Box>;
};

CourseIntroductionAuthors.propTypes = {
  authors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default observer(CourseIntroductionAuthors);
