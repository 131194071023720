import { types as t } from 'mobx-state-tree';
import { IsoDate, RelProfession } from './BaseModel';
// eslint-disable-next-line no-unused-vars
import { RelLessonContentSimple } from './LessonContent';

export const Lesson = t.model('Lesson', {
  id: t.identifier,
  type: t.string,
  attributes: t.model({
    drupal_internal__nid: t.number,
    title: t.string,
    created: IsoDate,
    changed: IsoDate,
    rotating_answers: t.maybeNull(t.boolean),
    '1answer_nofeedback': t.maybeNull(t.boolean),
    '1answer_visualfeedback': t.maybeNull(t.boolean),
    minimum_score: t.maybeNull(t.number),
    minimum_score_2nd_try: t.maybeNull(t.number),
    max_attempts: t.maybeNull(t.number),
    lesson_type: t.maybeNull(t.string),
    free_flow: t.maybeNull(t.string) // TODO: Should be enumerated
  }),
  relationships: t.maybeNull(
    t.model({
      lesson_content: t.maybeNull(RelLessonContentSimple)
    })
  )
});

export const NewLesson = t.model('NewLesson', {
  type: t.string,
  attributes: t.model({
    title: t.string,
    rotating_answers: t.maybeNull(t.boolean),
    '1answer_nofeedback': t.maybeNull(t.boolean),
    '1answer_visualfeedback': t.maybeNull(t.boolean),
    minimum_score: t.maybeNull(t.number),
    minimum_score_2nd_try: t.maybeNull(t.number),
    max_attempts: t.maybeNull(t.number),
    lesson_type: t.maybeNull(t.string),
    free_flow: t.maybeNull(t.string) // TODO: Should be enumerated
  }),
  relationships: t.model({
    lesson_content: t.maybeNull(RelLessonContentSimple),
    profession: RelProfession
  })
});

export const RelLesson = t.model('RelLesson', {
  data: t.maybeNull(
    t.model({
      id: t.reference(Lesson),
      type: t.string
    })
  )
});

export const RelLessons1 = t.model('RelLessons', {
  data: t.maybeNull(
    t.array(
      t.model({
        type: t.string,
        id: t.reference(Lesson)
      })
    )
  )
});
