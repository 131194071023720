/* eslint-disable no-unused-expressions */
import { getEwapi } from 'state/RoutesActions';
import { createRouterState } from 'mobx-state-router';
import { ThemeBrandTitle } from 'helpers/Helpers';

// http://elearn4.local.pe-academy.nl:3000/accountant/38/49/course/62738/De-kern-van-de-NV-COS-I#Information

export const defCourseIntroductionRoute = {
  name: 'defCourseIntroduction',
  pattern: '/:productName/:product/:packageId/course/:courseId/:title',
  altPatterns: ['/:productName/:product/:packageId/course/:courseId'],
  meta: { title: `%%introduction.metatitle%% | ${ThemeBrandTitle()}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    const { productName, product, packageId, courseId } = toState.params;
    const { store } = routerStore.options;
    //
    if (productName === 'special') {
      return createRouterState('specialAccessCourseIntroduction', {
        params: { sa: 'my', productName, product, packageId, courseId, title: 'test' }
      });
    }
    if (productName === 'redactie') {
      return createRouterState('defEditCourse', {
        params: { def: 'red', package: '00', packageNid: product, containerNid: '00', courseuuid: packageId }
      });
    }
    // Check if the user is LTI and trying to reach this page
    if (store.getLtiSession() === 1 || store.getLtiSession() === '1') {
      return createRouterState('errorPage');
    }
    // load courses if not already done
    try {
      return await getEwapi(fromState, toState, routerStore);
    } catch (e) {
      return e;
    }
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { courseId } = toState.params;
    await store.courseResults.updateCourseResultbyNid(Number(courseId));
    store.courseResults.isCourseFinished(Number(courseId)) && !store.isEwiseUser && store.subscriptions.updateSubscription();
  }
};
export const CourseIntroductionRoute = {
  name: 'courseIntroduction',
  pattern: '/:productName/:product/:packageId/course/:courseId/:title',
  altPatterns: ['/:productName/:product/:packageId/course/:courseId'],
  meta: { title: `%%introduction.metatitle%% | ${ThemeBrandTitle()}` },
  beforeEnter: getEwapi,
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { courseId } = toState.params;
    await store.courseResults.updateCourseResultbyNid(Number(courseId));
    store.courseResults.isCourseFinished(Number(courseId)) && !store.isEwiseUser && store.subscriptions.updateSubscription();
  }
};
