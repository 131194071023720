import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { red, green } from '@mui/material/colors';

/*
In summary, the ErrorBox component provides a visually appealing way to present error information to users. 
It can display a title, a message, and the status of the error, with sensible default values for cases where specific error information is not provided. 
The component's styling is determined by the external styles defined in the classes variable, which are not shown in the provided code snippet.
*/
const useStyles = makeStyles(() => ({
  card: {
    minWidth: 275,
    marginBottom: '50px',
    width: '80%'
  },
  cardError: {
    backgroundColor: red[50],
    color: red[700]
  },
  cardInfo: {
    backgroundColor: green[700],
    color: 'white'
  },
  message: {
    fontSize: 18,
    textAlign: 'center',
    color: 'black'
  }
}));

export const ErrorBox = ({ error }) => {
  const classes = useStyles();

  return (
    <Card className={`${classes.cardError} ${classes.card}`}>
      <CardContent>
        <Typography variant="h5" className={`${classes.cardError}`} align="center" gutterBottom component="h2">
          {error.title || error.name || 'Er is iets fout gegaan'}
        </Typography>
        <Typography className={classes.message} align="center">
          {error.message || 'Onbekende fout'}
        </Typography>
        <br />
        <Typography variant="body2" align="center">
          Status: {error.status}
        </Typography>
      </CardContent>
    </Card>
  );
};

ErrorBox.propTypes = {
  error: PropTypes.object.isRequired
};
