import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { observer } from 'mobx-react';
import DownloadIcon from '@mui/icons-material/Download';
import { useStore } from 'hooks';

/* MUI */
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const DownloadCertificateSection = (props) => {
  const { title } = props;
  const store = useStore();
  const { t } = useTranslation();
  const { findCourseResultByCourseNid } = store.courseResults;
  const [isFetching, setIsFetching] = useState(false);

  const fetchCertificate = async () => {
    try {
      setIsFetching(true);
      const courseResultUuid = findCourseResultByCourseNid(Number(store.params.courseId)).uuid;
      const code = await store.lmsApi.downloadCertificate(courseResultUuid);
      window.open(`${process.env.REACT_APP_JSONAPI}/course-result/${courseResultUuid}/${code}/certificate`, '_blank');
    } catch (error) {
      store.showToast({ message: t('introduction.sidebar.certificateError'), variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Box className="course_materials">
      <Typography variant="h2" component="h2" fontSize="20px" color="primaryBlue.main" fontWeight="500">
        {t('introduction.sidebar.certificate')}
      </Typography>
      <Box className="materials">
        <Box display="flex" flexDirection="column" padding={2}>
          <Typography color="primaryBlue.main" fontSize="16px" fontWeight="500" marginBottom={2}>
            {title}
          </Typography>

          <LoadingButton
            className="download_button"
            size="small"
            onClick={fetchCertificate}
            endIcon={<DownloadIcon size="2rem" />}
            loading={isFetching}
            loadingPosition="end"
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
          >
            {t('introduction.sidebar.certificate')}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

DownloadCertificateSection.propTypes = {
  title: PropTypes.string
};

export default observer(DownloadCertificateSection);
