// SHUFFLE algorithm
export function shuffle(array) {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex); // sonarclound false positive on Math.random()
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

// DRAG & DROP MOVE method
export const move = (state, source, destination) => {
  const srcListClone = [...state[source.droppableId].items];
  const destListClone = source.droppableId === destination.droppableId ? srcListClone : [...state[destination.droppableId].items];
  const [movedElement] = srcListClone.splice(source.index, 1);

  destListClone.splice(destination.index, 0, movedElement);

  return {
    ...state,
    [source.droppableId]: { ...state[source.droppableId], items: srcListClone },
    [destination.droppableId]: { ...state[destination.droppableId], items: destListClone }
  };
};
