/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
/* Helpers */
import { rawHTML } from 'helpers/Helpers';
/* MUI */
import { Box } from '@mui/material';

// renders the course information to be displayed in the preview dialog
const CourseInformation = ({ previewValues }) => (
  <Box sx={{ fontSize: '18px', lineHeight: '34px' }}>
    {previewValues?.lessonContainer?.attributes?.field_information &&
      rawHTML(previewValues?.lessonContainer?.attributes?.field_information)}
  </Box>
);

CourseInformation.propTypes = {
  previewValues: PropTypes.object
};

export default observer(CourseInformation);
