import { styled } from '@mui/material/styles';
import { Button as ButtonBase, IconButton as IconButtonBase } from '@mui/material';

export const Button = styled(ButtonBase)(() => ({
  color: '#2b3674',
  borderRadius: '49px',
  letterSpacing: '-0.02em',
  fontSize: '14px',
  fontWeight: 400,
  paddingLeft: '10px',
  background: '#F4F7FE',
  height: '41px',
  width: 'auto',
  textTransform: 'none'
}));

export const IconButton = styled(IconButtonBase)(() => ({
  background: '#F4F7FE',
  width: '41px',
  height: '41px'
}));
