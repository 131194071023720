import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { useStore } from 'hooks';
import Logo from 'components/common/Logo';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderTitle from './components/HeaderTitle';
import { useHeaderContext } from './context';
import CourseHeader from './CourseHeader';

const CourseHeadline = ({ title, subtitle }) => {
  const store = useStore();

  const { showSidebar, isMobile } = useHeaderContext();

  // Check if the user is LTI and trying to reach this page
  if (store.isLtiSession() === 1 || store.getLtiSession() === '1') {
    return null;
  }

  const isIntroduction =
    store.routerStore?.routerState?.routeName === 'courseIntroduction' ||
    store.routerStore?.routerState?.routeName === 'defCourseIntroduction';

  return (
    <Box className="course_overview_headline_stack">
      {isMobile && showSidebar && (
        <Box className="course_overview_headline_hamburger">
          <IconButton onClick={() => store.toggleDrawer()}>
            <MenuIcon />
          </IconButton>
        </Box>
      )}

      {(!showSidebar || isMobile) && (
        <Box className="course_overview_headline_mobile_logo">
          <Logo style={{ maxWidth: '75px', width: '81px' }} />
        </Box>
      )}

      {(!isMobile || (isMobile && !isIntroduction)) && (
        <Box className="course_overview_headline_title">
          <HeaderTitle title={title} subtitle={subtitle} />
        </Box>
      )}

      <Box className="course_overview_headline_menu">
        <CourseHeader />
      </Box>
    </Box>
  );
};

CourseHeadline.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subtitle: PropTypes.string
};

export default observer(CourseHeadline);
