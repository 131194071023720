import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const FormProfileValidation = () => {
  const { t } = useTranslation();

  // Reusable transform function
  const transformNullable = (value, originalValue) => (originalValue === '' ? null : value);

  return Yup.object().shape({
    mail: Yup.string().email(t('account.validation.email')),
    field_gender: Yup.string().nullable(),
    field_birthdate: Yup.date()
      .nullable()
      .transform((value, originalValue) => {
        try {

          // Parse the date string manually
          originalValue = String(originalValue);
          const parts = originalValue.split(' ');
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const monthIndex = monthNames.indexOf(parts[1]);
          const day = parseInt(parts[2]);
          const year = parseInt(parts[3]);
          const hours = parseInt(parts[4].split(':')[0]);
          const minutes = parseInt(parts[4].split(':')[1]);
          const seconds = parseInt(parts[4].split(':')[2]);

          // Create a Date object using the parsed components
          // +1 is import for hours or the backend thinks it is the day before
          const date = new Date(year, monthIndex, day, (hours + 2), minutes, seconds);

          // Check if the parsed date is valid
          if (Number.isNaN(date.getTime())) {
            window.console.log(`Invalid date: ${originalValue}`);
            return null;
          }

          return date;

        } catch (error) {
          window.console.log('date:', originalValue);
          window.console.error("Error parsing birthdate:", error);
          return null; // Handle invalid date gracefully
        }
      }),
    field_bignr: Yup.string()
      .nullable()
      .transform(transformNullable)
      .matches(/^\d+$/, t('account.validation.digits'))
      .max(11, t('account.validation.digitsMax')),
    field_rbnr: Yup.string()
      .nullable()
      .transform(transformNullable)
      .matches(/^\d+$/, t('account.validation.digits'))
      .max(11, t('account.validation.digitsMax')),
    field_kngfnr: Yup.string()
      .nullable()
      .transform(transformNullable)
      .matches(/^\d+$/, t('account.validation.digits'))
      .max(11, t('account.validation.digitsMax')),
    field_krmnr: Yup.string()
      .nullable()
      .transform(transformNullable)
      .matches(/^\d+$/, t('account.validation.digits'))
      .max(11, t('account.validation.digitsMax')),
    field_kabiznr: Yup.string()
      .nullable()
      .transform(transformNullable)
      .matches(/^\d+$/, t('account.validation.digits'))
      .max(11, t('account.validation.digitsMax')),
    preferred_langcode: Yup.string()
  });
};

export default FormProfileValidation;
