/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { Store } from 'state/ContextStore';
import * as logger from 'loglevel';

const log = logger.getLogger('Helpers');

export const triggerEvent = (event, element = window) => {
  element.dispatchEvent(event);
};

export function rawHTML(rawHTMLContent) {
  return React.createElement('div', { dangerouslySetInnerHTML: { __html: modifyExternalLinksInHTML(rawHTMLContent) } });
}

export function removeBracketsFromString(string) {
  const regex = /\[video:(.*)\]/;
  const regex2 = /\[video:&nbsp;(.*)\]/;

  const url = string.match(regex2) || string.match(regex);
  return url[1];
}

export function removeBracketsFromStringEditor(string) {
  if (!string) return '';
  const regex = /\[video:https?:\/\/(.*?)\]/;
  const regex2 = /\[video:&nbsp;https?:\/\/(.*?)\]/;
  const match = string.match(regex2) || string.match(regex);
  const url = match ? `//${match[1]}` : '';
  return url;
}

export function isValidVimeoUrl(string) {
  const regex = /\[video:(.*)\]/;
  const regex2 = /\[video:&nbsp;(.*)\]/;
  const url = string.match(regex2) || string.match(regex);

  return url ? url[1] : false;
}

export const matchNumbersInString = (string) => string.match(/\d+/g) || '';

export const isValidPodcast = (string) => {
  if (string.length < 10) return false;
  const regEx = /<iframe ?.* src="([^"]+)" ?.*>/;
  const regEx2 = /<iframe ?.* src='([^"]+)' ?.*>/;
  const html = string.match(regEx);
  const html2 = string.match(regEx2);
  let html3 = false;
  html3 = html || html2;
  if (!html3) return false;
  const src = html3[1];
  return src.includes('https://app.springcast.fm/player');
};

export const ShowProps = (props) => (
  <div style={{ margin: '0', fontFamily: 'monospace' }}>
    <pre style={{ background: '#f6f8fa', fontSize: '.65rem', padding: '.5rem', lineHeight: '1.3em' }}>
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

export const courseIntroductionTitle = (name) => {
  let courseName = name;
  if (name === 'Course introduction') {
    courseName = window.location.href.split('/').pop().replaceAll('-', ' ');
    return courseName.split('#')[0];
  }
  return courseName;
};

/**
 * This decorator is a replacement for the depracated
 * `disableBackdropClick` on modals.
 *
 * @see https://v4.mui.com/api/dialog/
 *
 * @param func
 * @returns {function(*=, *=): *}
 */
export const ignoreModalBackdropClickFor = (func) => (event, reason) => {
  if (typeof reason !== 'undefined' && reason === 'backdropClick') {
    return null;
  }

  return func(event, reason);
};

/* Will define current environment */

const hostnameToLabel = {
  '.e-wise.nl': 'ewise',
  '.cme-online.nl': 'cme',
  '.po-online.nl': 'po',
  '.pe-academy.nl': 'pe',
  '.amplifyapp.com': 'amplify'
};
export const getLabelNameFromDomain = () => {
  const { hostname } = window.location;

  if (!hostname) {
    return null;
  }

  const presentKeys = Object.keys(hostnameToLabel).filter((urlPart) => hostname.includes(urlPart));

  if (presentKeys.length >= 1) {
    return hostnameToLabel[presentKeys[0]];
  }

  return 'pe';
};

export function SubscribeLink(store) {
  const domain = window.location.hostname.match(/(\.cme-online.nl|\.pe-academy.nl|\.e-wise.nl|\.po-online.nl)/);
  let subscribeUrl = '';
  let facebookLink = '';
  let linkedinLink = '';
  if (domain && domain.length >= 2 && store && store.baseProduct) {
    const modifiedUniqueName = store?.baseProduct?.unique_name.replace(/proefcursus|proef/g, '');
    if (window.location.hostname.match(/(\.e-wise\.nl)/)) {
      facebookLink = 'https://www.facebook.com/people/E-WISE/100057513967040/';
      linkedinLink = 'https://www.linkedin.com/company/e-wise_nederland/';
      subscribeUrl = `https://www.e-wise.nl/${modifiedUniqueName}/subscribe`;
    }
    if (window.location.hostname.match(/(\.cme-online\.nl)/)) {
      facebookLink = null;
      linkedinLink = 'https://www.linkedin.com/company/cme-online/';
      subscribeUrl = `https://www.cme-online.nl/${modifiedUniqueName}/subscribe`;
    }
    if (window.location.hostname.match(/(\.po-online\.nl)/)) {
      facebookLink = 'https://www.facebook.com/pages/category/Education/PO-Online-307603886401427/';
      linkedinLink = 'https://www.linkedin.com/company/po-online/';
      subscribeUrl = `https://www.po-online.nl/${modifiedUniqueName}/subscribe`;
    }
    if (window.location.hostname.match(/(\.pe-academy\.nl)/)) {
      facebookLink = null;
      linkedinLink = 'https://www.linkedin.com/company/pe-academy/';
      subscribeUrl = `https://www.pe-academy.nl/${modifiedUniqueName}/subscribe`;
    }
  }
  return { subscribeUrl, facebookLink, linkedinLink };
}

/* Will change some html meta's */
export function ThemeMeta() {
  const theme = getLabelNameFromDomain();

  document.querySelector('meta[name="theme"]').setAttribute('content', theme);
  document.querySelector('link[rel="shortcut icon"]').href = `/${theme}/assets/images/favicon.ico`;
  document.querySelector('link[rel="icon"][sizes="16x16"]').href = `/${theme}/assets/images/favicon-16x16.png`;
  document.querySelector('link[rel="icon"][sizes="32x32"]').href = `/${theme}/assets/images/favicon-32x32.png`;
  document.querySelector('link[rel="apple-touch-icon"][sizes="180x180"]').href = `/${theme}/assets/images/apple-touch-icon.png`;
  document.body.classList.add(theme);
  document.title = `${ThemeBrandTitle()}`;
}

/* Get the brand title */
export function ThemeBrandTitle() {
  const theme = getLabelNameFromDomain();
  switch (theme) {
    case 'ewise':
      return 'E-WISE';
    case 'po':
      return 'PO-Online';
    case 'pe':
      return 'PE-Academy';
    case 'cme':
      return 'CME-Online';
    default:
      return 'E-WISE';
  }
}

// Change gradient color
export function ThemeBrandGradient() {
  const theme = getLabelNameFromDomain();
  let color1 = '';
  let color2 = '';
  let fill = '';
  switch (theme) {
    case 'ewise':
      color1 = '#DC750F';
      color2 = '#771A51';
      fill = '#bd5b23';
      break;
    case 'po':
    case 'pe':
      color1 = '#00F2A8';
      color2 = '#006848';
      fill = '#01bf84';
      break;
    case 'cme':
      color1 = '#5DAEFF';
      color2 = '#004B96 ';
      fill = '#4190de';
      break;
    default:
  }
  return {
    color1,
    color2,
    fill
  };
}

/* Define the language by hostname */
export function SwitchLanguage() {
  let lng;
  if (window.location.hostname.match(/(\.nl)/)) {
    lng = { lng: 'en', fallback: 'nl' };
  } else if (window.location.hostname.match(/(\.de)/)) {
    lng = { lng: 'de', fallback: 'en' };
  } else if (window.location.hostname.match(/(\.be)/)) {
    lng = { lng: 'nl', fallback: 'en' };
  } else {
    lng = { lng: 'en', fallback: 'nl' };
  }
  return lng;
}

/* Define the theme */
export function getThemeName() {
  const localStoreThemeName = getLabelNameFromDomain();

  switch (localStoreThemeName) {
    case 'ewise':
      return 'ewiseTheme';
    case 'po':
      return 'poTheme';
    case 'pe':
      return 'peTheme';
    case 'cme':
      return 'cmeTheme';
    default:
      return 'ewiseTheme';
  }
}

export function changeLanguageMeta(lng) {
  const htmlTag = document.getElementById('html-tag');
  if (htmlTag && lng) {
    // Set the lang attribute to 'nl' (Dutch) or 'en' (English)
    htmlTag.setAttribute('lang', lng);
  }
}

/* Format categories for course overview */
export function formatCategories(categories) {
  const rawCategories = categories.split(',');
  let formattedCategories = '';
  if (rawCategories.length > 0) {
    for (let i = 0; i < rawCategories.length; i++) {
      if (i !== 0) {
        formattedCategories += ` • ${rawCategories[i]}`;
      } else {
        formattedCategories += rawCategories[i];
      }
    }
  }
  return formattedCategories;
}

/* Capitalize First Letter */
export function CapitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getdirtyValues(values, initialValues) {
  const data = Object.keys(values).filter(
    (value) =>
      (value === 'field_birthdate' && values[value] !== null ? values[value].getTime() : values[value]) !==
      (value === 'field_birthdate' && values[value] !== null ? initialValues[value]?.getTime() : initialValues[value])
  );
  const dirtyFields = data
    .map((x) => ({
      field: x,
      value: x === 'field_birthdate' && values[x] !== null ? values[x].toISOString().split('T')[0] : values[x]
    }))
    .reduce((obj, item) => Object.assign(obj, { [item.field]: item.value }), {});
  return dirtyFields;
}

export function GetPoints(points, extraPoints, courseExtraPointsDesc) {
  const store = useContext(Store);

  if (store.routerState.routeName.includes('specialAccess')) {
    return points <= 1 ? GetTimeLabel(points) : `${points} uren`;
  }

  const accreditation = store.baseProduct.packages.filter((packag) => packag.nid === store.selectedPackageId);

  if (courseExtraPointsDesc !== null) {
    return `${courseExtraPointsDesc}`;
  }

  if (extraPoints && accreditation[0]?.accreditation) {
    return `${points} ${
      points <= 1 ? accreditation[0]?.accreditation?.accr_unit : accreditation[0]?.accreditation?.accr_unit_plural
    } + ${extraPoints} ${accreditation[0]?.accreditation?.extra_points_unit}`;
  }

  return `${points} ${
    points <= 1 ? accreditation[0].accreditation?.accr_unit : accreditation[0]?.accreditation?.accr_unit_plural || 'uren'
  }`;
}

// Will give a time label for minutes
export function GetTimeLabel(duration, short = false) {
  const store = useContext(Store);
  const currentDuration = parseFloat(duration);
  const t = (key) => window.i18next.t(key);
  const minLabel = short ? 'min.' : translatePoints('minuten', t);
  const hourLabel = translatePoints('uur', t);
  const selectedPackage = store?.baseProduct?.packages?.find((packag) => packag.nid === store.selectedPackageId);
  const selectedPackageTitle = selectedPackage?.tab_label?.toLowerCase() || '';
  if (currentDuration > 0 && currentDuration < 1) {
    if (currentDuration === 0.5) {
      return `±30 ${minLabel}`;
    }
    return `±${currentDuration * 60} ${minLabel}`;
  }
  // This needs some more flavour for PE
  if (currentDuration === 0 && selectedPackageTitle === 'archief') {
    const poPnt = short ? 'PO' : 'PO-punt';
    return `${currentDuration} ${poPnt}`;
  }
  return `${currentDuration} ${hourLabel}`;
}

// Will give a time label for minutes
export function GetSearchTimeLabel(duration, packageId, short = false) {
  const store = useContext(Store);
  const currentDuration = parseFloat(duration);
  const t = (key) => window.i18next.t(key);
  const minLabel = short ? 'min.' : translatePoints('minuten', t);
  const hourLabel = translatePoints('uur', t);
  const selectedPackage = store?.baseProduct?.packages?.find((packag) => packag.nid === packageId);
  const selectedPackageTitle = selectedPackage?.tab_label?.toLowerCase() || '';
  if (currentDuration > 0 && currentDuration < 1) {
    if (currentDuration === 0.5) {
      return `±30 ${minLabel}`;
    }
    return `±${currentDuration * 60} ${minLabel}`;
  }
  // This needs some more flavour for PE
  if (currentDuration === 0 && selectedPackageTitle === 'archief') {
    const poPnt = short ? 'PO' : 'PO-punt';
    return `${currentDuration} ${poPnt}`;
  }
  return `${currentDuration} ${hourLabel}`;
}

// Will five back the default top image in lesson play
export function getPlayHeaderImage(currentPackage) {
  const currentTheme = window.store?.label || 'ewise';
  const definePackage = currentPackage.toLocaleLowerCase().replace(/\s/g, '');
  const isLeraren = definePackage.includes('leraren') && !definePackage.includes('docentenvo');
  const isLerarenVO = definePackage.includes('docentenvo');
  switch (definePackage) {
    case 'advocatuur':
      return `/po/assets/images/play/backgrounds/advocaat.jpg`;
    case 'accountancy':
      return `/pe/assets/images/play/backgrounds/accountant.jpg`;
    case 'fiscaliteit':
      return `/pe/assets/images/play/backgrounds/fiscalist.jpg`;
    case 'notariaat':
      return `/pe/assets/images/play/backgrounds/notaris.jpg`;
    case 'apotheker':
      return `/cme/assets/images/play/backgrounds/apotheker.jpg`;
    case 'apothekersassistent':
      return `/cme/assets/images/play/backgrounds/apothekersassistent.jpg`;
    default:
      if (isLeraren) {
        return `/ewise/assets/images/play/backgrounds/leraren.jpg`;
      }
      if (isLerarenVO) {
        return `/ewise/assets/images/play/backgrounds/lerarenvo.jpg`;
      }
      return `/${currentTheme}/assets/images/play/backgrounds/collage-elearning@4x.jpg`;
  }
}

// Defines if a course is failed
export function isCourseFailed(lessonResult, courseResult, courseId) {
  let isFailed = false;
  if (lessonResult !== undefined && lessonResult !== null) {
    const { getFailedCourses } = lessonResult;
    const { findCourseResultByNid } = courseResult;
    const failedLessonResults = getFailedCourses();
    const failedCourses = failedLessonResults.map((failedLes) => findCourseResultByNid(failedLes.course_result.id));
    isFailed = failedCourses.filter((failedCourse) => failedCourse.course_id.course_id === courseId).length;
  }
  return isFailed;
}

export function LessonProgress(lesson) {
  // Calculate progress single lesson
  const totalPages = lesson?.pages?.length || 0;
  const completedPages = lesson?.pages?.filter((page) => page.completed === true).length;
  const progress = completedPages === 0 || totalPages === 0 ? 1 : Math.round((completedPages * 100) / totalPages);
  return progress;
}

export function LessonArrayProgress(lessonResult, lessons) {
  // Calculate progress for each lesson multiple lesson
  // To use for individual lesson progress
  const ids = lessonResult.map((result) => result.lesson.uuid);
  const startedLessons = lessons.map((lesson) => ids.includes(lesson.uuid));
  const lessonArray = lessonResult.map((lesson) => LessonProgress(lesson));
  if (lessonResult.length === lessons.length) {
    return lessonArray;
  }
  const progressArray = startedLessons.map((les, index) => {
    if (!les) {
      if (lessonArray[index]) {
        lessonArray[index + 1] = lessonArray[index];
        lessonArray[index] = 0;
      } else {
        lessonArray[index] = 0;
      }
      return lessonArray;
    }
    return lessonArray;
  });
  return progressArray[0];
}

/**
 * Same height for images on course list min
 */

export function courseOverviewImgSize() {
  const isMobile = window.innerWidth <= 900;

  if (!isMobile) {
    // Get all elements with the class 'courseview_category_items'
    const elements = document.querySelectorAll('.course_overview_card');

    // Calculate the maximum height
    const maxHeight = Math.max(...Array.from(elements).map((el) => el.clientHeight));

    // Set the same height for all elements
    elements.forEach((el) => {
      el.style.height = `${maxHeight}px`;
    });
  }
}

/**
 * Helper to translate points
 */
export const translatePoints = (str, t) => {
  const translations = {
    punt: 'course.accr.point',
    punten: 'course.accr.points',
    uur: 'course.accr.duration',
    uren: 'course.accr.hours',
    unlimited: 'headerbar.unlimited',
    minuten: 'course.accr.minutes'
  };
  const matches = str.match(/\d*\s*(punten|punt|uur|uren|unlimited|minuten)/); // sonarcloud complains about this regex, but it's fine
  if (matches) {
    const unit = matches[1];
    const translatedUnit = translations[unit];
    if (translatedUnit) {
      return `${str.replace(unit, t(translatedUnit))}`;
    }
  }
  return str;
};

/**
 * Get the course card props
 */
export const getCourseCardProps = (course) => {
  const drupalBaseUrl = process.env.REACT_APP_JSONAPI;
  const {
    course_category,
    course_category2,
    course_id,
    course_status,
    course_title,
    course_uuid,
    coursephoto_url,
    lc_author_id,
    lc_extra_label,
    lc_information,
    lc_podcast_html,
    lessons,
    points,
    valid_period_end,
    categoryLabelArray,
    categoryLabel,
    package_id,
    lc_reviewer_id,
    isTrialCourse
  } = course;

  const podcast = lc_podcast_html;
  const label = lc_extra_label?.match('podcast');
  // properties for each type basic card
  return {
    authors: lc_author_id || [],
    badges: course_category2,
    categories: course_category?.map((category) => category?.name),
    chip: course_status,
    courseImage: coursephoto_url && `${drupalBaseUrl}${coursephoto_url}`,
    courseTitle: course_title,
    description: lc_information ? rawHTML(lc_information) : '',
    duration: points,
    extraLabel: !label && lc_extra_label,
    id: course_uuid,
    lessons,
    nid: course_id,
    podcast,
    endDate: valid_period_end,
    categoryLabelArray,
    categoryLabel,
    packageId: package_id || 0,
    reviewers: lc_reviewer_id || [],
    isTrialCourse
  };
};

/**
 * Modify any link not part of the current domains
 */
function modifyExternalLinksInHTML(htmlContent) {
  // Get current domain
  const currentDomain = window.location.hostname.match(/(\.cme-online.nl|\.pe-academy.nl|\.e-wise.nl|\.po-online.nl)/);
  const { pattern } = window.store?.routerStore?.getCurrentRoute() || '';
  const isAdmin = pattern.includes('/admin/');
  if (currentDomain && currentDomain.length >= 2 && !isAdmin) {
    // Create a tmp element
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    // Get all links from the element
    const links = tempDiv.querySelectorAll('a');
    // Loop through each lement
    links.forEach((link) => {
      let href = link.getAttribute('href');
      if (href && href.length >= 2) {
        // Check if the href starts with "http://" or "https://"
        if (!href.startsWith('http://') && !href.startsWith('https://')) {
          // If not, prepend "http://"
          href = `https://${href}`;
        }
        // Get url and do some magic
        try {
          const { hostname } = new URL(href);
          const cleanedHostname = `.${hostname.split('.').slice(-2).join('.')}`;
          // Check if the current domain and url is not the same or add a target _blank
          if (cleanedHostname !== currentDomain[0]) {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
          }
        } catch (error) {
          log.error('Invalid URL:', href);
        }
      }
    });
    return tempDiv.innerHTML;
  }
  return htmlContent;
}

/**
 * This will change the meta title based on the route meta + translation + brand
 */
export const changeMetaRoute = (routerStore) => {
  let setTitle = '';
  let extraMeta = '';
  const titleElement = document.querySelector('title');
  if (titleElement && routerStore) {
    /** Set package title on course overview */
    const currentPackage = routerStore?.options?.store?.packages?.filter(
      (pack) => pack.nid === routerStore?.options?.store?.selectedPackageId
    );
    if (routerStore?.routerState?.routeName === 'courseOverview') {
      setTitle = currentPackage[0]?.tab_label.length > 0 ? currentPackage[0]?.tab_label : routerStore?.routerState?.params?.productName;
      extraMeta = `- ${setTitle}`;
    }
    /** Set course title on course introduction */
    if (routerStore?.routerState?.routeName === 'courseIntroduction' || routerStore?.routerState?.routeName === 'defCourseIntroduction') {
      setTitle =
        routerStore?.routerState?.params?.title?.length > 0
          ? routerStore?.routerState?.params?.title.replace(/-+/g, '-').replace(/-/g, ' ')
          : routerStore?.routerState?.params?.productName;
      extraMeta = `- ${setTitle}`;
    }
    /** Set course title on lesson play */
    if (routerStore?.routerState?.routeName === 'lessonPlay') {
      const courseNid = routerStore?.routerState?.params?.courseId;
      const courseUuid = window.store?.findCourseByNid(Number(courseNid));
      setTitle = courseUuid?.course_title.length > 0 ? courseUuid?.course_title : routerStore?.routerState?.params?.productName;
      extraMeta = `- ${setTitle}`;
    }
    titleElement.textContent =
      replaceMetaRoute(translateMetaTitle(routerStore?.getCurrentRoute()?.meta?.title), extraMeta) || ThemeBrandTitle();
  }
};

/**
 * This will add more meta to the title
 */
export const replaceMetaRoute = (title, replace) => {
  const oldTitle = title;
  const newTitle = replace ? oldTitle.replace('|', `${replace} |`) : title;
  return newTitle;
};

/**
 * This will translate the meta title
 */
export const translateMetaTitle = (title) => {
  const regex = /%%(.*?)%%/g;
  const matches = title ? title.match(regex) : null;
  const t = (key) => window.i18next.t(key);

  if (matches) {
    matches.forEach((match) => {
      const key = match.slice(2, -2); // Extract the key between %%
      const translation = t(key); // Get the translation using i18next
      title = title.replace(match, translation); // Replace %%...%% with translation
    });
  }

  return title;
};

// This will check if the ask a question should be showed
export function showAskQuestion() {
  const currentPackage = window.store?.packages?.find((pack) => pack.nid === window.store?.selectedPackageId);
  const currentPackageLabel = currentPackage?.tab_label?.toLowerCase() || '';
  const currentLabel = window.store?.label || '';
  if (
    currentLabel === 'ewise' ||
    (currentLabel === 'cme' &&
      currentPackageLabel !== 'apotheker' &&
      currentPackageLabel !== 'apothekersassistent' &&
      currentPackageLabel !== 'farmaceutisch consulent')
  ) {
    return true;
  }
  return false;
}

/**
 * This will set the Google tag manager IDs
 */
export const setGtmId = () => {
  const currentLabel = getLabelNameFromDomain();

  let gtmId = 'GTM-MRVKLHR'; // ewise

  if (currentLabel === 'cme') {
    gtmId = 'GTM-5RGBQFR';
  }
  if (currentLabel === 'pe') {
    gtmId = 'GTM-KSTJCG7';
  }
  if (currentLabel === 'po') {
    gtmId = 'GTM-MDLMP6V';
  }

  return gtmId;
};

/**
 * Track pageviews
 */
export const trackGaPageViews = () => {
  // Track a pageview for this component
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'pageview', pagePath: window.location.pathname });
};

/**
 * Set privacyStatement
 */
export const setPrivacyStatementUrl = () => {
  const currentLabel = getLabelNameFromDomain();
  let privacyUrl = 'https://www.e-wise.nl/privacystatement'; // ewise
  if (currentLabel === 'cme') {
    privacyUrl = 'https://www.cme-online.nl/privacystatement';
  }
  if (currentLabel === 'pe') {
    privacyUrl = 'https://www.pe-academy.nl/privacystatement';
  }
  if (currentLabel === 'po') {
    privacyUrl = 'https://www.po-online.nl/privacystatement';
  }
  return privacyUrl;
};

/**
 * Set setDataLeakUrl
 */
export const setDataLeakUrl = () => {
  const currentLabel = getLabelNameFromDomain();
  let privacyUrl = 'https://www.e-wise.nl/melden-datalek'; // ewise
  if (currentLabel === 'cme') {
    privacyUrl = 'https://www.cme-online.nl/melden-datalek';
  }
  if (currentLabel === 'pe') {
    privacyUrl = 'https://www.pe-academy.nl/melden-datalek';
  }
  if (currentLabel === 'po') {
    privacyUrl = 'https://www.po-online.nl/melden-datalek';
  }
  return privacyUrl;
};

export const getMarketingSiteUrl = () => {
  const currentLabel = getLabelNameFromDomain();
  let marketingSiteURL = 'https://www.e-wise.nl'; // ewise
  if (currentLabel === 'cme') {
    marketingSiteURL = 'https://www.cme-online.nl';
  }
  if (currentLabel === 'pe') {
    marketingSiteURL = 'https://www.pe-academy.nl';
  }
  if (currentLabel === 'po') {
    marketingSiteURL = 'https://www.po-online.nl';
  }
  return marketingSiteURL;
};

export const shouldShowSidebar = (store) => {
  if (store.noSidebar) {
    return false;
  }

  if (store.isLtiSession()) {
    return false;
  }

  if (!store.hasSubscriptions && !store.login?.isVtbTeacher) {
    return false;
  }

  if (store.packages.length === 1 && !store.isDemoProduct) {
    return false;
  }

  // Need check still for only LMS users when we have
  // Need check still for special access or combination package + 1 package
  return true;
};

/**
 * Filter duplicate additional fields
 */
export const addUserFields = (store, conditionalFields) => {
  const professions = store.profile?.userProfessions;
  const uniqueNamesObject = {};
  professions?.forEach((profession) => {
    const professionLabel = profession.attributes.name;
    if (!uniqueNamesObject[professionLabel]) {
      uniqueNamesObject[professionLabel] = [];
    }
    conditionalFields[professionLabel]?.forEach((unFields) => {
      const isNameUnique = !Object.values(uniqueNamesObject).some((namesArray) => namesArray.includes(unFields.name));
      if (isNameUnique) {
        uniqueNamesObject[professionLabel].push(unFields.name);
      }
    });
  });
  return uniqueNamesObject;
};

/**
 * Check if valid youtube
 */
export const isYouTubeUrl = (url) => {
  // Check if the URL already contains "embed" and return true
  if (url.includes('embed')) {
    return true;
  }
  // Regular expression to check if the URL is a YouTube video URL
  const youtubeRegex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^"&?/\s]{11})(?:[?&]t=(\d+))?/;
  return youtubeRegex.test(url);
};

/**
 * Get youtube embed url
 */
export const youtubeEmbedUrl = (url) => {
  const isEmbed = url.includes('embed');
  if (isYouTubeUrl(url) && !isEmbed) {
    // Extract video ID from the standard YouTube URL
    const match = url.match(
      /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^"&?/\s]{11})(?:[?&](?:.*&)?t=(\d+))?/
    );

    const videoId = match && match[1];
    const timestamp = match && match[2];
    if (videoId) {
      // Build the embed URL with the video ID
      return `https://www.youtube.com/embed/${videoId}?rel=0&amp;showinfo=0${timestamp ? `&start=${timestamp}` : ''}`;
    }
  }
  return url;
};

/**
 * Check if valid vimeo
 */
export const isVimeoUrl = (url) => {
  // Check if the URL already contains "embed" and return true
  if (url.includes('player')) {
    return true;
  }
  const vimeoRegex = /(?:vimeo\.com\/(?:video\/)?)([0-9]+)([?&]share=copy)?(#t=(\d+))?/;
  return vimeoRegex.test(url);
};

/**
 * Get vimeo embed url
 */
export const vimeoEmbedUrl = (url) => {
  const isEmbed = url.includes('player');
  if (isVimeoUrl(url) && !isEmbed) {
    // Extract video ID and optional timestamp from the standard Vimeo URL
    const match = url.match(/(?:vimeo\.com\/(?:video\/)?)([0-9]+)([?&]share=copy)?(#t=(\d+))?/);
    const videoId = match && match[1];
    const timestamp = match && match[4];
    if (videoId) {
      // Build the Vimeo embed URL with the video ID and optional timestamp
      return `https://player.vimeo.com/video/${videoId}${timestamp ? `#t=${timestamp}` : ''}`;
    }
  }
  return url;
};

/**
 * Check if the user can view the course id's show and to copy
 */
export function IsLtiDeeplinker() {
  const store = useContext(Store);
  return store.login?.dlxGate > 0;
}

/**
 * Define LTI Launch URL
 */
export const LtiLaunchUrl = () => {
  const store = useContext(Store);
  const currentLabel = getLabelNameFromDomain();

  let ltiUrl = `${process.env.REACT_APP_OAUTH_EWISE_PROVIDER_DOMAIN}/lti/v1p3/launch`; // ewise
  if (currentLabel === 'cme') {
    ltiUrl = `${process.env.REACT_APP_OAUTH_CME_PROVIDER_DOMAIN}/lti/v1p3/launch`;
  }
  if (currentLabel === 'pe') {
    ltiUrl = `${process.env.REACT_APP_OAUTH_PE_PROVIDER_DOMAIN}/lti/v1p3/launch`;
  }
  if (currentLabel === 'po') {
    ltiUrl = `${process.env.REACT_APP_OAUTH_PO_PROVIDER_DOMAIN}/lti/v1p3/launch`;
  }
  if (store.login?.dlxGate > 0) {
    return ltiUrl;
  }
  return null;
};

/**
 * Change old class to new class
 */
export const replaceSliderCssClass = (css, oldClass, newClass) => {
  // Create a regular expression to replace the old class with the new class globally
  const regEx = new RegExp(`\\.${oldClass}`, 'g');
  css = css.replace(regEx, `.${newClass}`);

  // Replace other specific classes with new ones
  css = css.replace(/\.inner/g, '.carousel-item');

  return css;
};

/**
 * Minify CSS
 */
export const minifyCss = (css) => {
  const minifiedCss = css.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '').replace(/\s{2,}/g, ' ');
  return minifiedCss;
};

/**
 * Embed video
 */

export const embedVideo = (url) => {
  if (isYouTubeUrl(url)) {
    return youtubeEmbedUrl(url);
  }
  if (isVimeoUrl(url)) {
    return vimeoEmbedUrl(url);
  }
  // Return the original URL if it is not a valid YouTube or Vimeo video URL
  return url;
};

const currencyList =
  '؋,Lek,دج,Kz,$,֏,ƒ,m,B$,.د.ب,৳,Bds$,Br,fr,Nu.,฿,Bs.,KM,P,R$,£,B$,Лв.,FBu,KHR,CFA,FCFA,₣,¥,CF,FC,₡,kn,Kč,Kr.,Fdj,ج.م,Nfk,kr,Nkf,€,£,FJ$,D,ლ,DM,GH₵,£,₯, Δρχ, Δρ,Q,FG,G,L,Ft,kr,₹,Rp,﷼,د.ع,₪,L,£,J$,¥,ا.د,лв,KSh,ك.د,лв,₭,Ls,£,L,د.ل,Lt,ден,Ar,MK,RM,Rf,MRU,₨,L,₮,MAD,MT,₨,ƒ,C$,₦,₩,kr,.ع.ر,₨,B/.,K,₲,S/.,₱,zł,ق.ر,lei,₽,FRw,₡,SAT,﷼,din,SRe,Le,Sk,Si$,Sh.so.,R,₩,SDR,Rs,£,.س.ج,E,kr,CHf,LS,Db,SM,TSh,฿,ت.د,₺,T,USh,₴,إ.د,лв,VT,Bs,₫,﷼,ZK'.split(
    ','
  );
export const isCurrency = (str) => currencyList.includes(str);
export const chooseNumber = (value, otherValue) => (value || +value === 0 ? +value : otherValue);
export const formatWith = (unit) => (value) => decideUnitPos(unit, value);
export const decideUnitPos = (unit, value) => (isCurrency(unit) ? `${unit} ${value}` : `${value} ${unit || ''}`);

export const getFaqUrl = (currentLabel, professionPath) => {
  if (currentLabel === 'cme') {
    return `https://www.cme-online.nl/${professionPath}/faq`;
  }
  if (currentLabel === 'pe') {
    return `https://www.pe-academy.nl/${professionPath}/faq`;
  }
  if (currentLabel === 'po') {
    return `https://www.po-online.nl/${professionPath}/faq`;
  }
  if (currentLabel === 'ewise') {
    return `https://www.e-wise.nl/${professionPath}/faq`;
  }
  return null;
};

/**
 * Convert Amplify redirect URL
 */
export const convertAmplifyRedirectUrl = (url) => {
  const { hostname } = window.location;
  if (url && url.includes('amplifyapp.com')) {
    const pattern = /^([a-z0-9-]+\.[a-z0-9]+)\.amplifyapp\.com$/;
    const match = hostname.match(pattern);
    if (match) {
      const subdomain = match[1];
      return url.replace('amplifyapp.com', `${subdomain}.amplifyapp.com`);
    }
  }
  return url;
};

/**
 * Encoded search params to string
 * @param {string} encodedString
 * @returns
 */
export const convertUrlEncodedString = (encodedString) => {
  // Decode the URL-encoded string
  const decodedString = decodeURIComponent(encodedString);

  // Replace spaces with pluses
  return decodedString.replace(/ /g, '+');
};

/**
 * Decoded search params to string
 * @param {string} decodedString
 * @returns
 */
export const convertUrlDecodedString = (decodedString) => {
  // Encode the decoded string
  // Also replace %2B with spaces
  const encodedString = encodeURIComponent(decodedString);
  return encodedString.replace(/%2B/g, ' ');
};

export const isStaging = () => window.location.hostname.includes('staging');

export function formatNumbersWithComma(row, keys = []) {
  Object.keys(row).forEach((key) => {
    if (keys.includes(key)) {
      row[key] = Intl.NumberFormat('nl-NL').format(row[key]);
    }
  });
  return row;
}
