import { useEffect, useState } from 'react';

const useScrollPosition = (elementRef = { current: window }) => {
  const [scrollPosition, setScrollPosition] = useState(elementRef?.current?.scrollTop || elementRef?.current?.scrollY || 0);

  useEffect(() => {
    if (!elementRef.current) return null;
    const element = elementRef.current;
    const updatePosition = ({ currentTarget: { scrollTop, scrollY } }) => {
      const scroll = scrollTop || scrollY || 0;
      const lastKnownScrollPosition = scroll;
      let ticking = false;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          setScrollPosition(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    };
    element.addEventListener('scroll', updatePosition);
    return () => element.removeEventListener('scroll', updatePosition);
  }, [elementRef]);

  return scrollPosition;
};

export default useScrollPosition;
