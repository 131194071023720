import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Box, Paper } from '@mui/material';

import ScrollUpButton from 'components/common/ScrollUpButton';
import MaintenanceModal from 'components/MaintenanceModal';
import LoadingPage from 'components/common/LoadingPage';
import { getThemeName, shouldShowSidebar } from 'helpers/Helpers';
import { useBreakpoint, useStore } from 'hooks';
import { useEffect } from 'react';
import LeftSideDrawer from './leftSideBar/LeftSideDrawer';
import { Header, HeaderContextProvider } from './header';
import Footer from './footer/Footer';
import 'theme/App.scss';

const MainLayout = ({ noSidebar, noPaper, children, footer, title, background, subtitle }) => {
  const store = useStore();
  const themeName = getThemeName();
  const showSidebar = shouldShowSidebar(store);
  const breakpoint = useBreakpoint();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  const extraClassOpenDrawer = showSidebar && store.openDrawer ? `open_drawer` : '';
  const extraClassMinDrawer = showSidebar && store.minDrawer ? `min_drawer` : '';
  const extraClassNoSidebar = !showSidebar ? `no_sidebar` : '';
  const extraClassIsMobile = isMobile ? `is_mobile` : '';

  useEffect(() => {
    store.setStoreValue('noSidebar', noSidebar);
  }, [store, noSidebar]);

  if (store.state === 'loading') {
    return <LoadingPage />;
  }

  return (
    <>
      <Box
        className={`main_layout ${themeName} ${extraClassOpenDrawer} ${extraClassMinDrawer} ${extraClassNoSidebar} ${extraClassIsMobile}`}
      >
        {showSidebar && <LeftSideDrawer />}
        <Box className={background ? 'main_layout_children main_layout_children_white' : 'main_layout_children'}>
          {noPaper ? (
            children
          ) : (
            <Paper className="main_paper">
              <HeaderContextProvider>
                <Header title={title} subtitle={subtitle} />
              </HeaderContextProvider>
              <Box className="main_content">{children}</Box>
              <ScrollUpButton />
              {footer && <Footer />}
            </Paper>
          )}
        </Box>
      </Box>
      <MaintenanceModal />
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element,
  noSidebar: PropTypes.bool,
  noPaper: PropTypes.bool,
  footer: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subtitle: PropTypes.string,
  background: PropTypes.bool
};

export default observer(MainLayout);
