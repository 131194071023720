/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useStore } from 'hooks';
import Carusel, { CarouselItem } from 'components/common/Carousel';
import { getCourseCardProps } from 'helpers/Helpers';
// import { getCourseCardProps } from 'helpers/Helpers';

import BasicCard from '../CourseOverview/Card/CourseGridCard';

const RelatedCourses = (props) => {
  const { category, courseId } = props;
  const store = useStore();
  const courses = store.courses.coursesByCategory('course');
  const relatedCourses = courses[category].filter((course) => course.course_id !== courseId);
  const showArrows = relatedCourses.length > 2;

  return (
    <Box className="course_related">
      {relatedCourses && relatedCourses.length && (
        <Carusel arrows={showArrows} length={relatedCourses.length}>
          {relatedCourses.map((course, index) => {
            const propsBasicCard = getCourseCardProps(course);
            return (
              <CarouselItem key={index}>
                <BasicCard {...propsBasicCard} />
              </CarouselItem>
            );
          })}
        </Carusel>
      )}
    </Box>
  );
};

RelatedCourses.propTypes = {
  category: PropTypes.string,
  courseId: PropTypes.number
};

export default RelatedCourses;
