import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import { useScrollPosition } from 'hooks';
import { Typography } from '@mui/material';
import { CapitalizeFirstLetter, convertUrlDecodedString, ThemeBrandTitle } from 'helpers/Helpers';
import TeacherHeadline from './TeacherHeadline';
import CourseIntroductionHeadline from './CourseIntroductionHeadline';

const HeaderTitle = ({ title, subtitle }) => {
  const store = useContext(Store);
  const { t } = useTranslation();

  const selectedPackageLabel = store.packages.filter((pack) => pack.nid === store.selectedPackageId);
  const isScrolling = useScrollPosition() > 14;
  const brandTitle = ThemeBrandTitle();
  const { uw } = brandTitle !== 'E-WISE' ? { uw: 'uw' } : { uw: 'je' };

  const courseIntroductionTitle = () => {
    if (title === 'introduction.title') {
      const courseNid = store.routerStore.routerState.params.courseId;
      const courseUuid = store.findCourseByNid(Number(courseNid));
      return courseUuid.course_title;
    }
    return null;
  };
  const courseIntroductionTitleText = isScrolling && courseIntroductionTitle();

  const specialAccessCourseOverviewTitle = () => {
    const isVtbTeacher =
      store.login?.isVtbTeacher === 1 && (store?.courses?.courses?.length === 0 || store?.courses?.courses === undefined);
    if (isVtbTeacher) {
      return t('courseoverview.specialAccessVtb.title');
    }
    return t('courseoverview.specialAccess.title');
  };

  const headerTitle = {
    'teachers.title.bio': {
      header: <TeacherHeadline title={title} />
    },
    'introduction.title': {
      header: <CourseIntroductionHeadline title={courseIntroductionTitleText} />
    },
    'courseoverview.title': {
      header: (
        <>
          <Typography sx={{ display: 'none' }} variant="h2" component="h2">
            Dashboard/
            {CapitalizeFirstLetter(store.baseProduct?.unique_name || '')}/{CapitalizeFirstLetter(selectedPackageLabel[0]?.tab_label || '')}
          </Typography>
          <Typography variant="h1" component="h1" id="courseOverviewTitle" color="primaryBlue.main" fontSize="19px">
            {`${t(title)} ${CapitalizeFirstLetter(selectedPackageLabel[0]?.tab_label || '')}`}
          </Typography>
        </>
      )
    },
    'curriculum.stagesTitle': {
      header: (
        <>
          <Typography variant="h2" component="h2">
            {t(`${subtitle}`) || subtitle}
          </Typography>
          <Typography variant="h1" component="h1" id="courseOverviewTitle" color="primaryBlue.main" fontSize="19px">
            {t(`${title}`) || title} {store.curriculum?.data?.attributes?.year}
          </Typography>
        </>
      )
    },
    'courseoverview.specialAccess.title': {
      header: (
        <>
          <Typography variant="h1" component="h1" id="courseOverviewTitle" color="primaryBlue.main" fontSize="19px">
            {specialAccessCourseOverviewTitle()}
          </Typography>
        </>
      )
    },
    'searchoverview.title': {
      header: (
        <>
          {store.searchParam !== '' && (
            <>
              <Typography variant="h1" component="h1" id="courseOverviewTitle" color="primaryBlue.main" fontSize="19px">
                {t('searchoverview.searchParam', 'Search results for:')} {convertUrlDecodedString(store.searchParam) || ''}
              </Typography>
              <Typography variant="h2" component="h2" sx={{ fontSize: '15px !important' }}>
                {store.searchCoursesFound ?? 0}{' '}
                {store.searchCoursesFound === 0 || store.searchCoursesFound > 1 ? (
                  <>{t('searchoverview.totalFound2', 'courses found')}</>
                ) : (
                  <>{t('searchoverview.totalFound1', 'course found')}</>
                )}
              </Typography>
              {store.searchCoursesFound > 0 &&
                store.searchSuggestedParam !== '' &&
                store.searchParam !== store.searchSuggestedParam &&
                store.searchIsRandom !== true && (
                  <Typography variant="h2" component="h2" sx={{ fontSize: '15px !important' }}>
                    {t('searchoverview.suggestion', 'Showing results for:')} {store.searchSuggestedParam || ''}
                  </Typography>
                )}
              {store.searchIsRandom === true && (
                <Typography variant="h2" component="h2" sx={{ fontSize: '15px !important' }}>
                  {t('searchoverview.suggestionrand', { uw })}
                </Typography>
              )}
            </>
          )}
        </>
      )
    }
  }[title];

  const defaultHeader = (
    <>
      <Typography variant="h2" component="h2">
        {t(`${subtitle}`) || subtitle}
      </Typography>
      <Typography variant="h1" component="h1" id="courseOverviewTitle" color="primaryBlue.main" fontSize="19px">
        {t(`${title}`) || title}
      </Typography>
    </>
  );

  return (headerTitle && headerTitle.header) || (typeof title === 'function' && title()) || defaultHeader;
};

HeaderTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subtitle: PropTypes.string
};

export default HeaderTitle;
