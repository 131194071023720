import { parseISO, isToday, isFuture, isPast } from 'date-fns';

export const getEvents = async () => {
  const overviewResponse = await window.authedClient.get('ewapi/vtb/overview?currentTeacherEvents=true');

  if (overviewResponse.status !== 200) {
    throw new Error('something went wrong');
  }

  const formattedEvents = [];

  // Loop through each event in the data
  overviewResponse.data.data.forEach((event) => {
    // Extract the event type name
    const { name, subtitle } = overviewResponse.data.included.find(
      (includedItem) => includedItem.id === event.relationships.event_type.data.id
    )?.attributes;

    const title = [name, subtitle].join(', ');

    // Extract the date of the event
    const date = parseISO(event.attributes.date.value);
    const dateIsToday = isToday(date);

    const dateIsInTheFuture = isFuture(date) && !dateIsToday;
    const dateIsInThePast = isPast(date) && !dateIsToday;

    const totalSeats = event.attributes.seats;
    const seatsLeft = event.meta.available_seats;

    // Extract the location details
    const locationId = event.relationships.location.data.id;
    const location = overviewResponse.data.included.find((includedItem) => includedItem.id === locationId)?.attributes;
    const address = `${location.street ?? ''} ${location.house_number ?? ''}, ${location.zipcode ?? ''}, ${location.name ?? ''}`;

    // Add the formatted event to the array
    formattedEvents.push({
      id: event.attributes.drupal_internal__id,
      uuid: event.id,
      isToday: dateIsToday,
      isPast: dateIsInThePast,
      isFuture: dateIsInTheFuture,
      totalSeats,
      seatsLeft,
      title,
      date,
      address
    });
  });

  // Return the array of formatted events
  return formattedEvents.sort((a, b) => a.date - b.date);
};
