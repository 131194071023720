/**
 * CREATE INDESIGN XML FROM COURSEOVERVIEW JSON
 *
 * @param {*} courses
 * @returns
 */
// LAYOUT STYLE
const PRINT = 1;
// eslint-disable-next-line no-unused-vars
const LEAF = 2;
let CURRENT_STYLE = 2;

// SETTINGS VOOR COLUMN WIDTH's
const COLWIDTH = {
  print: { t: 170, a: 135, v: 35 },
  leaf: { t: 200, a: 100, v: 35 },
  title() {
    return CURRENT_STYLE === PRINT ? this.print.t : this.leaf.t;
  },
  authors() {
    return CURRENT_STYLE === PRINT ? this.print.a : this.leaf.a;
  },
  value() {
    return CURRENT_STYLE === PRINT ? this.print.v : this.leaf.v;
  }
};

export const createXml = (store, style) => {
  CURRENT_STYLE = style;
  const { courses, packages, params } = store;
  const currentPackage = params.packageId === '0' ? packages[0] : packages.find((pkg) => pkg.nid.toString() === params.packageId);
  // group courses by category
  const coursesByCategory = {};
  courses.courses.forEach((course) => {
    course.course_category.forEach((cat) => {
      if (!coursesByCategory?.[cat.name]) coursesByCategory[cat.name] = [];
      coursesByCategory[cat.name].push(course);
    });
  });
  // create the xml with the courses grouped by category
  const xmlOutput = _createXmlContent(coursesByCategory, currentPackage);
  // create the xml file and start the download
  return _createFileAndDownload(xmlOutput, currentPackage);
};

//
export const _createXmlContent = (categories, curPackage) => {
  const tcols = CURRENT_STYLE === PRINT ? 2 : 3;
  const xmlAid = {
    root: `xmlns:aid5="http://ns.adobe.com/AdobeInDesign/5.0/"`,
    table: `xmlns:aid="http://ns.adobe.com/AdobeInDesign/4.0/" aid:table="table" aid:tcols="${tcols}" aid5:tablestyle="course-table"`
  };

  let totCourses = '';
  //
  const entries = Object.entries(categories);
  entries.sort((a, b) => a[0].localeCompare(b[0]));
  entries.forEach(([key, val]) => {
    const count = CURRENT_STYLE === PRINT ? val.length * 2 : val.length;
    // create the xml for the courses
    const _catCourses = val.map((course) => _xmlCourse(course, curPackage)).join('');
    // create the xml for the category
    let xmlCat = `<node>\n\n<category>${key}</category>\n`;
    xmlCat += `<PHP-course>`;
    xmlCat += `<Root ${xmlAid.root}>`;
    xmlCat += `<table ${xmlAid.table} aid:trows="${count}" >`;
    xmlCat += `${_catCourses}`;
    xmlCat += `</table>`;
    xmlCat += `</Root>`;
    xmlCat += `</PHP-course>`;
    xmlCat += `</node>`;
    totCourses += xmlCat;
  });
  //
  return `<?xml version="1.0" encoding="UTF-8" ?><nodes>${totCourses}</nodes>`;
};

const _xmlCourse = (course, curPackage) => {
  const ccols = CURRENT_STYLE === PRINT ? 2 : 1;

  const xmlAid = {
    title: `aid:table="cell" aid:crows="1" aid:ccols="${ccols}" aid:ccolwidth="${COLWIDTH.title()}" aid5:cellstyle="cell-title"`,
    authors: `aid:table="cell" aid:crows="1" aid:ccols="1" aid:ccolwidth="${COLWIDTH.authors()}" aid5:cellstyle="cell-authors"`,
    value: `aid:table="cell" aid:crows="1" aid:ccols="1" aid:ccolwidth="${COLWIDTH.value()}" aid5:cellstyle="cell-value"`
  };
  //
  const title = course.course_title ? course.course_title.replace(/&/g, '&amp;').replace(/  +/g, ' ') : '';
  const weblecture = course?.webLecture ? `<weblecture> ►</weblecture>` : '';
  const expected = course.course_expected ? `<expected aid:cstyle="expected"> verwacht</expected>` : '';
  const extrapoints = course.course_extra_points ? _getExtraValues(course, curPackage) : '';
  const authors = course.lc_author_id.map((author) => `${author.title}`).join(', ');
  const accrUnitShort = curPackage?.accreditation?.accr_unit_short || 'pnt';
  //
  // create the xml for the course
  const cellTitle = `<cell ${xmlAid.title} ><name>${title}${weblecture}${expected}</name></cell>`;
  const cellAuthors = `<cell ${xmlAid.authors} ><authors>${authors}</authors></cell>`;
  const cellValue = `<cell ${xmlAid.value} ><value>${course.course_points}</value> <unit>${accrUnitShort}</unit> ${extrapoints}</cell>`;
  // return the xml for the course
  return cellTitle + cellAuthors + cellValue;
};

// OUTPUTS XML
const _getExtraValues = (course, curPackage) => {
  let xValue = `, <extrapoints aid:cstyle="${curPackage?.accreditation?.extra_points_unit || ''}">`;
  xValue += `<extravalue>${course.course_extra_points}</extravalue>`;
  xValue += `<extraunit>${curPackage?.accreditation?.extra_points_unit || ''}</extraunit>`;
  xValue += `</extrapoints>`;
  return xValue;
};

const _createFileAndDownload = (xmlFile, curPackage) => {
  const layout = CURRENT_STYLE === 1 ? 'print' : 'brochure';
  const packageTitle = curPackage?.tab_label || 'cursusoverzicht';
  // create a file and put the content, name and type
  const file = new File([`\ufeff${xmlFile}`], `${packageTitle}_${layout}.xml`, { type: 'text/plain:charset=UTF-8' });
  // create a ObjectURL in order to download the created file
  const url = window.URL.createObjectURL(file);
  // create a hidden link and set the href and click it
  const a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  a.download = file.name;
  a.click();
  window.URL.revokeObjectURL(url);
};
