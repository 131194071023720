import MainLayout from 'pages/mainLayout/MainLayout';
import Article from '../components/ArticlePage';

const ArticleViewMap = {
  article: (
    <MainLayout noPaper>
      <Article />
    </MainLayout>
  )
};

export default ArticleViewMap;
