/* Course image */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Store } from 'state/ContextStore';

/* MUI */
import { CardMedia, Skeleton } from '@mui/material';

// renders the course image
const CourseImage = (props) => {
  const store = useContext(Store);
  const { visible, src, alt } = props;
  const courseImage = src?.replace(/%20/g, '%2520') || store.defaultCourseGridImage;
  if (!visible) return <Skeleton sx={{ height: 194 }} animation="wave" variant="rectangular" />;
  return <CardMedia component="img" loading="lazy" image={courseImage} alt={alt} />;
};

CourseImage.propTypes = {
  visible: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string
};

export default CourseImage;
