/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
// MUI
import { Grid, Radio, Stack, IconButton, FormControlLabel, Collapse, Checkbox, Box, Button } from '@mui/material';
/* ICONS */
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIconOff from '@mui/icons-material/VisibilityOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

/* Custom RHF components */
import { Editor } from 'components/form/RHF';

const FormWidgetOptions = ({ optionType = 'radio', control, setValue }) => {
  const { fields, append, remove } = useFieldArray({ control, name: 'attributes.widget_data.options' });
  const indexCorrectAnswer = fields.findIndex((item) => item.correct === true);
  const [checkedOption, setCheckedOption] = useState(indexCorrectAnswer);
  const watchOptions = useWatch({ control, name: 'attributes.widget_data.options' });
  const [showFeedback, setShowFeedback] = useState('false');

  useEffect(() => {
    if (indexCorrectAnswer !== checkedOption) {
      setCheckedOption(indexCorrectAnswer);
    }
  }, [checkedOption, indexCorrectAnswer]);
  //
  return (
    <Grid container spacing={1} sx={sxGrid}>
      <Grid item xs={12} container>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            append({ value: (fields.length + 1).toString(), label: '', correct: false, feedback: '' });
          }}
          color="primary"
        >
          Antwoord
        </Button>
      </Grid>

      {fields.map((option, index) => (
        <Grid item key={option.id} xs={12} container>
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Stack spacing={2} direction="row" alignItems="center" sx={{ flex: 1 }}>
              <Box>
                <Box>{index + 1}</Box>
                <IconButton aria-label="delete" onClick={() => setShowFeedback(showFeedback === `#${index}` ? 'false' : `#${index}`)}>
                  {showFeedback === `#${index}` ? <VisibilityIconOff /> : <VisibilityIcon />}
                </IconButton>
              </Box>
              <Box>
                {optionType === 'radio' && (
                  <Radio
                    name={`attributes.widget_data.options[${index}].correct`}
                    index={index}
                    checked={watchOptions?.[index]?.correct === true || checkedOption === `${index}`}
                    onChange={() => {
                      setCheckedOption(`${index}`);
                      setValue(
                        'attributes.widget_data.options',
                        watchOptions.map((item, optionIndex) =>
                          optionIndex === index ? { ...item, correct: true } : { ...item, correct: false }
                        ),
                        {
                          shouldTouch: true,
                          shouldDirty: true
                        }
                      );
                    }}
                    label="correct"
                    control={control}
                  />
                )}
                {optionType !== 'radio' && (
                  <Controller
                    control={control}
                    name={`attributes.widget_data.options[${index}].correct`}
                    defaultValue={option?.correct || false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} />
                    )}
                  />
                )}
              </Box>
              <Box sx={sxFlexColumnGap}>
                <Box className="container-editor">
                  <Box>
                    <Editor
                      disabled={false}
                      label={`Antwoord ${index + 1}`}
                      field={{ name: `attributes.widget_data.options[${index}].label` }}
                      form={{ setValue, control }}
                    />
                  </Box>
                </Box>
                <Collapse in={showFeedback === `#${index}` || false}>
                  <Box className="container-editor">
                    <Box>
                      <Editor
                        disabled={false}
                        label={`Feedback ${index + 1}`}
                        field={{ name: `attributes.widget_data.options[${index}].feedback` }}
                        form={{ setValue, control }}
                      />
                    </Box>
                  </Box>
                </Collapse>
              </Box>
              <IconButton aria-label="delete" size="medium" onClick={() => remove(index)}>
                <HighlightOffIcon fontSize="inherit" color="error" />
              </IconButton>
            </Stack>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

FormWidgetOptions.propTypes = {
  control: PropTypes.object,
  optionType: PropTypes.string,
  setValue: PropTypes.func
};

export default observer(FormWidgetOptions);

// custom sx ________________________________________________________
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};
//

const sxFlexColumnGap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
};
