import MainLayout from 'pages/mainLayout/MainLayout';
import Accountv2 from '../Accountv2';
import Settings from '../components/Settings';

const SettingsViewMap = {
  settings: (
    <MainLayout title="account.settings.title" subtitle="">
      <Accountv2>
        <Settings />
      </Accountv2>
    </MainLayout>
  )
};

export default SettingsViewMap;
