/* eslint-disable camelcase */
/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/* MUI */
import { Alert, AlertTitle, Box, Button, Grid, Typography, InputAdornment, Badge, Divider, LinearProgress } from '@mui/material';

/* Custom components */
import { Form } from 'components/form';
import { MySelect, MyTextField, MyCheckbox } from 'components/form/RHF';
import MyDatePicker from 'components/form/RHF/DatePicker/MyDatepicker';
import { getdirtyValues, changeLanguageMeta, addUserFields, ThemeBrandTitle } from 'helpers/Helpers';
import Mail from 'components/icons/Mail.svg';
import Calendar from 'components/icons/Calendar';
import ImageUploader from 'components/form/RHF/ImageUpload/ImageUploader';
import EditIcon from '@mui/icons-material/Edit';
import ProfileFields from './ProfileFields';
import formProfileValidation from './beans/Profile.ValidationSchema';
import { conditionalFields, languages } from './beans/profileData';
import AccreditationFieldsMsg from '../components/AccreditationFieldsMsg';

const drupalBaseUrl = process.env.REACT_APP_JSONAPI;

/*
The component returns a form that displays the user's profile data. The form fields are populated with the initial values and can be edited if the user clicks the edit button. 
If the user clicks the cancel button, the form fields are reset to their initial values. If the user clicks the save button, the handleSubmit function is called. 
The component also displays the user's profile picture, if it exists, and allows the user to upload a new one.
*/
const Profile = () => {
  const store = useContext(Store);
  const { t, i18n } = useTranslation();
  const [alertMsg, setAlertMsg] = useState('');
  const [checked, setChecked] = useState(false);
  const validationSchema = formProfileValidation();
  const [profileLoading, setProfileLoading] = useState(false);

  const {
    attributes: {
      field_first_name,
      field_gender,
      mail,
      field_birthdate,
      field_last_name,
      field_bignr,
      field_rbnr,
      field_kngfnr,
      field_krmnr,
      field_kabiznr,
      field_initials,
      field_receive_email_notification,
      field_accountants_type,
      field_rechtsgebieden,
      field_fysio_deelregister,
      field_nirv_number,
      field_no_registration_numbers,
      field_slpo_id,
      field_function,
      preferred_langcode,
      field_somn_member,
      field_vvn_nr
    },
    relationships: { user_picture }
  } = store.profile;

  const userPicture = user_picture?.data && `${drupalBaseUrl}${user_picture.data.attributes.uri.url}`;
  const professions = store.profile?.userProfessions;

  const initialValues = {
    mail,
    field_birthdate,
    photo: !userPicture ? '' : userPicture,
    field_gender: field_gender === null ? '' : field_gender,
    field_bignr: field_bignr === null ? '' : field_bignr,
    field_last_name,
    field_rbnr: field_rbnr === null ? '' : field_rbnr,
    field_kngfnr: field_kngfnr === null ? '' : field_kngfnr,
    field_krmnr: field_krmnr === null ? '' : field_krmnr,
    field_kabiznr: field_kabiznr === null ? '' : field_kabiznr,
    field_initials: field_initials === null ? '' : field_initials,
    field_first_name: field_first_name === null ? '' : field_first_name,
    field_receive_email_notification: field_receive_email_notification === null ? false : field_receive_email_notification,
    field_accountants_type: field_accountants_type === null ? '' : field_accountants_type,
    field_nirv_number: field_nirv_number === null ? '' : field_nirv_number,
    field_no_registration_numbers: field_no_registration_numbers === null ? '' : field_no_registration_numbers,
    field_somn_member: field_somn_member === null ? '' : field_somn_member,
    field_slpo_id: field_slpo_id === null ? '' : field_slpo_id,
    field_function: field_function === null ? '' : field_function,
    field_vvn_nr: field_vvn_nr === null ? '' : field_vvn_nr,
    field_rechtsgebieden:
      field_rechtsgebieden === null || field_rechtsgebieden[0] === 'Geen rechtsgebieden geregistreerd' ? [] : field_rechtsgebieden,
    field_fysio_deelregister:
      field_fysio_deelregister === null
        ? [
            {
              name: 'Algemene fysiotherapie',
              value: 'algemene_fysiotherapie'
            }
          ]
        : field_fysio_deelregister,
    preferred_langcode
  };
  const gender = [
    { name: t('account.profile.genderOptions.male'), value: 'male' },
    { name: t('account.profile.genderOptions.female'), value: 'female' },
    { name: t('account.profile.genderOptions.other'), value: 'others' }
  ];

  const zohoId = store?.profile?.attributes?.zoho_id;
  const hasZohoId = zohoId !== undefined && zohoId !== null && zohoId !== '';
  const initialsCheck = hasZohoId;
  // This is to determine which extra fields to show
  const currentProfession = (professions && professions.find((profession) => profession.attributes.name === 'Notaris')) || false; //
  const noRegistrationLabel = currentProfession ? t('account.profile.noregistration1') : t('account.profile.noregistration');
  const hasConditionalFields = professions?.some((profession) => conditionalFields[profession.attributes.name]?.length > 0);
  const extraFields = addUserFields(store, conditionalFields);
  const editModeVal = true;
  const brandTitle = ThemeBrandTitle();

  // This is to determine the text to show in the accreditation fields
  const { uw } = brandTitle !== 'E-WISE' ? { uw: 'uw' } : { uw: 'je' };
  const { u } = brandTitle !== 'E-WISE' ? { u: 'u' } : { u: 'je' };
  const { U } = brandTitle !== 'E-WISE' ? { U: 'U' } : { U: 'Je' };

  // This function is called when the user clicks the checkbox not registered
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // fix for the scroll issue, when the user navigates to the profile page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // This function is called when the component is mounted
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    const msg = <AccreditationFieldsMsg professions={professions} />;
    if (status && status === '1') {
      setAlertMsg(msg);
    }
    if (field_no_registration_numbers) {
      setChecked(true);
    }
    // cleanup
    return () => setAlertMsg('');
  }, [store, field_no_registration_numbers, professions]);

  // This function is called when the user clicks the save button
  const handleSubmit = async (values) => {
    const dirtyFields = getdirtyValues(values, initialValues);
    setProfileLoading(true);
    if (dirtyFields.photo) {
      await store.profile.patchPicture(store.profile, dirtyFields.photo, 'user_picture').then(() => {
        store.login.profilePicture = `${drupalBaseUrl}${user_picture.data.attributes.uri.url}`;
        store.user?.updatePicture(`${drupalBaseUrl}${user_picture.data.attributes.uri.url}`);
        delete dirtyFields.photo;
      });
    }
    try {
      await store.patchData(store.profile, {
        attributes: dirtyFields,
        id: store.profile.id,
        type: store.profile.type
      });
      i18n.changeLanguage(values.preferred_langcode);
      changeLanguageMeta(values.preferred_langcode);
      store.showToast({ message: t('account.profile.updated', 'Profile updated'), variant: 'success' });
    } catch (error) {
      store.showToast({ message: t('account.profile.error', 'Something went wrong'), variant: 'error' });
    }
    setProfileLoading(false);
  };

  // This function renders the extra fields based on the profession
  const renderProfileFields = (control, errors, profession, fields) =>
    fields.map((fieldName) => {
      const fieldDetails = conditionalFields[profession]?.find((profField) => profField.name === fieldName);
      const errorField = errors[fieldName];
      return fieldDetails ? (
        <Grid item xs={12} sm={12} key={`${profession}-${fieldName}`}>
          <ProfileFields
            autoFocus
            margin="normal"
            name={fieldDetails.name}
            label={fieldDetails.label}
            type={fieldDetails.type}
            fullWidth
            required={!checked && fieldDetails.required}
            control={control}
            error={Boolean(errorField)}
            items={fieldDetails.items}
            multiple={fieldDetails.multiple}
            helperText={errorField ? errorField.message : fieldDetails.helperText}
          />
        </Grid>
      ) : null;
    });

  return (
    <Box
      padding="30px"
      paddingBottom="10px"
      className="profile_box"
      sx={{
        opacity: profileLoading ? 0.5 : 1,
        position: 'relative',
        cursor: profileLoading ? 'not-allowed' : 'auto'
      }}
    >
      {profileLoading && <LinearProgress sx={{ position: 'absolute', bottom: '0', left: '0', width: '100%' }} />}
      {store.profile && (
        <>
          <Grid container spacing={2} marginTop="0px" paddingTop="0px">
            <Grid item xs={12} sm={12}>
              <Typography fontWeight="bold" color="#2B3674" fontSize="20px">
                {t('account.profile.title')}
              </Typography>
              {alertMsg && (
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  {alertMsg}
                </Alert>
              )}
            </Grid>
          </Grid>
          <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ control, errors, dirty }) => (
              <>
                <Grid container spacing={2} marginTop="0px" paddingTop="0px">
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} justifyContent="center" marginBottom="0px">
                      <Grid item style={{ paddingTop: '0px', marginTop: '0px', marginBottom: '0px' }}>
                        <Badge
                          className="profileBadge"
                          overlap="circular"
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          badgeContent={
                            <EditIcon sx={{ backgroundColor: 'primary.main', color: 'white', padding: '4px', borderRadius: '10px' }} />
                          }
                        >
                          <ImageUploader
                            editMode={editModeVal}
                            name="photo"
                            id="avatar"
                            control={control}
                            src={initialValues.photo}
                            avatar
                            index={0}
                          />
                        </Badge>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div>
                  <Grid container spacing={2} className="profile_form">
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        autoFocus
                        margin="normal"
                        name="field_initials"
                        label={t('account.profile.initials')}
                        disabled={initialsCheck}
                        type="text"
                        fullWidth
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        autoFocus
                        margin="normal"
                        name="field_first_name"
                        label={t('account.profile.name')}
                        type="text"
                        fullWidth
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        autoFocus
                        margin="normal"
                        name="field_last_name"
                        label={t('account.profile.surname')}
                        type="text"
                        fullWidth
                        disabled
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        autoFocus
                        margin="normal"
                        name="mail"
                        label={t('account.profile.email')}
                        type="text"
                        fullWidth
                        disabled
                        control={control}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="mail" src={Mail} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className={field_birthdate === null ? 'profile_datepicker' : ''}>
                      <MyDatePicker
                        name="field_birthdate"
                        label={t('account.profile.birthday')}
                        control={control}
                        fullWidth
                        inputFormat="dd-MM-yyyy"
                        error={errors.field_birthdate && true}
                        helperText={errors.field_birthdate && errors.field_birthdate.message}
                        components={{
                          OpenPickerIcon: Calendar
                        }}
                        sx={{
                          '&.MuiInputBase-input': { padding: '8.5px 14px' },
                          '&.MuiOutline-input': { padding: '8.5px 14px' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="profile_select">
                      <MySelect name="field_gender" label={t('account.profile.gender')} items={gender} control={control} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6} className="profile_select">
                      <MySelect
                        name="preferred_langcode"
                        label={t('account.profile.language')}
                        items={languages}
                        control={control}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} />
                    <Grid item xs={12} sm={12}>
                      <Divider />
                    </Grid>
                    {hasConditionalFields && (
                      <Box sx={{ padding: '14px', width: '100%' }}>
                        <Typography fontWeight="bold" color="#2B3674" fontSize="20px">
                          {t('account.profile.accreditation')}
                        </Typography>
                        <Typography fontSize="16px">{t('account.profile.accreditationText', { U, u, uw })}</Typography>
                      </Box>
                    )}
                    {hasConditionalFields &&
                      Object.entries(extraFields).map(([profession, fields]) => (
                        <Box sx={{ width: '100%' }} key={profession}>
                          {renderProfileFields(control, errors, profession, fields)}
                        </Box>
                      ))}
                    {hasConditionalFields && extraFields && (
                      <Box
                        sx={{
                          width: '100%',
                          padding: '14px'
                        }}
                      >
                        <Grid item xs={12} sm={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <MyCheckbox
                            checked={checked}
                            onClick={handleChange}
                            name="field_no_registration_numbers"
                            label={noRegistrationLabel}
                            control={control}
                          />
                        </Grid>
                      </Box>
                    )}
                  </Grid>
                  <Grid container style={{ justifyContent: 'flex-end' }}>
                    <Grid item>
                      <Button variant="contained" type="submit" disabled={!dirty}>
                        {t('account.profile.save')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Form>
        </>
      )}
    </Box>
  );
};

export default observer(Profile);
