import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { LocationButton } from '../../LocationButton/LocationButton';
import { EventTypeColumn } from './EventTypeColumn/EventTypeColumn';
import { getOtherEventsOfSameEventType } from '../../lib/getOtherEventsOfSameEventType';

export const LocationRow = ({ vtbEvents, eventSignupIds, location, eventTypes, onEventClick }) => (
  <Box key={location.id} sx={{ display: 'flex', gap: 1 }}>
    <LocationButton location={location} />
    <Box sx={{ display: 'flex', gap: 1 }}>
      {eventTypes.map((eventType) => {
        // So let's get all events of this event type
        const eventsOfSameEventType = getOtherEventsOfSameEventType(vtbEvents, eventType.id);

        // So is a user present in one of these event of this type
        const userPresentInOneOfTheEvents = eventsOfSameEventType.some((eventOfSameEventType) =>
          eventSignupIds?.includes(eventOfSameEventType.id)
        );

        const parsedEvents = eventsOfSameEventType.map((eventOfSameEventType) => {
          const isUserAttending = eventSignupIds?.includes(eventOfSameEventType.id);
          const isUserAttendingEventOfOtherEventType = userPresentInOneOfTheEvents;
          return { ...eventOfSameEventType, isUserAttending, isUserAttendingEventOfOtherEventType };
        });

        const events = parsedEvents.filter((parsedEvent) => parsedEvent.relationships.location.data.id === location.id);

        return (
          <EventTypeColumn
            isUserAttendingEventType={events.some((vtbEvent) => vtbEvent.isUserAttendingEventOfOtherEventType)}
            key={eventType.id}
            eventType={eventType}
            vtbEvents={events}
            onEventClick={({ vtbEvent, isUserAttending }) =>
              onEventClick({
                vtbEvent,
                eventType,
                location,
                isUserAttending
              })
            }
          />
        );
      })}
    </Box>
  </Box>
);

LocationRow.propTypes = {
  vtbEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        date: PropTypes.shape({
          value: PropTypes.string.isRequired
        }).isRequired
      }).isRequired,
      isEnrollmentClosed: PropTypes.bool
    })
  ).isRequired,
  eventSignupIds: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  onEventClick: PropTypes.func.isRequired
};
