import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
/* MUI */
import { Box, Divider, Checkbox } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
/* MATERIAL TABLE COMPONENT */
import MaterialTable, { MTableToolbar } from '@material-table/core';
/* HELPERS */
import { getPlaceHoldersFromLesson, lessonTableQuestionWidgets, restButtonsInLesson } from 'pages/adminCourses/addCourse/EditCourse.helper';
// HOOKS
import useJsonApi from 'hooks/useJsonApi';
import LoadingLinearProgress from '../loading/LoadingLinearProgress';
import DialogWidgetsTableDetailPanel from './DialogWidgetsTableDetailPanel';
import DialogWidgetsTableNoOdt from './DialogWidgetsTableNoOdt';

/*
In summary, the DialogWidgetsTable component fetches and displays widgets related to a specific lesson content. 
It provides users with a searchable and expandable table view of widget data, making it easy to navigate and review the available widgets. 
The component also offers a high level of customization for styling the Material Table and its components.
*/
const DialogWidgetsTabel = ({ lessonContentId, showLessonItems }) => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm');
  // HOOK
  const fetchUrl = `lesson_content/${lessonContentId}`;
  const fetchQuery = `include=tagdoc,lesson_content_widgets`;
  const { response, loading, error } = useJsonApi(fetchUrl, fetchQuery);
  // STATES
  const [checked, setChecked] = useState(false);
  const [buttonsInLesson, setButtons] = useState([]);

  // workaround to update the table when a widget is added or removed
  const { currentLessonWidgets } = store.courseEdit;

  // THE WIDGETS
  // lessonContentWidgets: filter widgets from currentLesson.included
  const widgets = store?.courseEdit?.currentLesson?.included ? store?.courseEdit.lessonContentWidgets() : [];
  //
  useEffect(() => {
    if (!response?.data?.attributes?.lessontext?.value) return;
    store.courseEdit.setStoreValue('currentLesson', { lessonContent: response.data, included: response.included });
    //
    const file = response?.included?.filter((item) => item.type.includes('file'));
    const setFile = {
      fileName: file?.[0]?.attributes?.filename || '',
      fileUri: `${process.env.REACT_APP_JSONAPI}${file?.[0]?.attributes?.uri?.url}` || ''
    };
    store.courseEdit.setStoreValue('currentFilename', setFile);
    //
    const lessonHTML = response?.data?.attributes?.lessontext?.value;
    const lessonButtons = getPlaceHoldersFromLesson(lessonHTML);
    setButtons(lessonButtons);
  }, [response, store.courseEdit]);

  // ___ EVENT: TOGGLE QUESTION WIDGET TABLE UP/DOWN ___________________________________
  const toggleUpDown = (event) => {
    setChecked(event.target.checked);
    showLessonItems(event.target.checked ? 'none' : 'flex');
  };

  // ___ EVENT: OPEN EDIT WIDGET DIALOG _______________________________________________
  const openEditWidgetDialog = (widgetData, togglePanel, widgetType = false, copy = false) => {
    if ((!widgetData.attributes.widget_type && !widgetType) || copy) {
      togglePanel();
    } else {
      if (widgetType) widgetData.attributes.widget_type = widgetType;
      store.courseEdit.setStoreValue('setEditWidget', widgetData);
      store.courseEdit.setStoreValue('toggleDialogEditWidget', true);
    }
  };

  // ___ RENDERING: LOADING / ERROR / RESPONSE _________________________________________
  if (loading) {
    return (
      <Box>
        <LoadingLinearProgress loading={loading} />
        <Box>Loading widgets...</Box>
      </Box>
    );
  }
  if (error) return <p>error...</p>;
  if (!response) return <p>no response</p>;

  if (!response?.data?.relationships?.tagdoc?.data?.id) return <DialogWidgetsTableNoOdt />;

  // Get the buttons that are not widgets
  const restButtons = restButtonsInLesson(widgets, buttonsInLesson);

  // ___ TABLE PROPERTIES ________________________________________
  const tableUpdate = currentLessonWidgets !== 'test' ? `updated: ${currentLessonWidgets}` : '';
  const tableDataMerged = [...widgets, ...restButtons];

  /**
   * The MaterialTable component requires every row to have a unique key as id.
   * The widget data is already unique because of the id field.
   * The restButtons data is not unique, so we need to generate unique ids for each row.
   *  - We use the crypto.randomUUID() function to generate a unique id for each row.
   *  - In the tableOptions object, we set the idSynonym property to 'uniqId' to use the uniqId field as the unique key.
   */
  const tableData = tableDataMerged.map((item) => ({ ...item, uniqId: crypto.randomUUID() }));

  const tableTitle = `${t('lesEditForm.widgetsTabel.totalWidgets')}: ${widgets.length} / ${tableData.length} ${tableUpdate}`;
  const tableOptions = {
    ...lessonTableQuestionWidgets.options,
    ...customStyling,
    ...{ actionsColumnIndex: -1 },
    ...{ showGroupingCount: true },
    ...{ idSynonym: 'uniqId' }
  };
  const tableColumns = lessonTableQuestionWidgets.columns;

  return (
    <Box className="questionWidgets" sx={sxFlexColumn}>
      <Divider sx={{ marginTop: '-5px', marginBottom: '0' }}>
        <Checkbox
          color="primary"
          checked={checked}
          onChange={toggleUpDown}
          icon={<ArrowCircleUpIcon sx={dividerIconSize} color="primary" />}
          checkedIcon={<ArrowCircleDownIcon sx={dividerIconSize} color="primary" />}
        />
      </Divider>
      <Box className="mt__container" sx={sxMtContainer}>
        <MaterialTable
          title={tableTitle}
          style={{ padding: '13px 5px' }}
          data={tableData}
          options={tableOptions}
          columns={tableColumns}
          detailPanel={(rowData) => <DialogWidgetsTableDetailPanel data={rowData} openWidgetDialog={openEditWidgetDialog} />}
          onRowClick={(_event, rowData, togglePanel) => openEditWidgetDialog(rowData, togglePanel)}
          components={{
            Toolbar: (props) => (
              <Box className="toolbar__container" sx={sxToolbarContainer}>
                <MTableToolbar {...props} />
              </Box>
            )
          }}
        />
      </Box>
    </Box>
  );
};

DialogWidgetsTabel.propTypes = {
  lessonContentId: PropTypes.string,
  showLessonItems: PropTypes.func
};

export default observer(DialogWidgetsTabel);

// CUSTOM SX _______________________________________________________________________________________
const sxFlexColumn = { display: 'flex', flexDirection: 'column', flex: 1 };
const dividerIconSize = { fontSize: '35px' };

const sxMtContainer = {
  padding: '0 10px',
  borderRadius: '5px',
  '.MuiTableRow-head': { height: '50px' },
  '.MuiTableCell-root': { lineHeight: '0px' },
  '.MuiPaper-root': { backgroundColor: 'transparent', boxShadow: 'none' },
  '.MuiTablePagination-root': { border: 'none', background: 'white', borderRadius: '5px', marginTop: '15px', padding: '0px' },
  'tr td > button': { padding: '0px' },
  'td[colspan]': { lineHeight: '40px!important' },
  '.MuiToolbar-root > div': {
    boxSizing: 'border-box',
    borderRadius: '20px',
    padding: '0px!important',
    background: 'transparent!important',
    border: '1px dashed transparent!important'
  },
  '.MuiPaper-root > .MuiToolbar-root > div:hover': { border: '1px dashed rgb(204, 204, 204)!important' },
  '.MuiPaper-root > .MuiToolbar-root > div': { background: '#c0c0c036!important', pading: '5px' },
  '.MuiToolbar-root .MuiChip-root': { background: 'white', color: '#0f0f0f', border: '3px solid #dae0e3' },
  pre: { margin: '0px!important', fontSize: '.9rem!important', whiteSpace: 'pre-wrap' }
};

const sxToolbarContainer = {
  h6: { fontSize: '20px', fontWeight: '300' },
  '>.MuiToolbar-root': { maxHeight: '50px!important', minHeight: '50px!important', paddingLeft: '0px' }
};

const customStyling = {
  rowStyle: {
    fontSize: '14px',
    lineHeight: '0px'
  },
  searchFieldStyle: {
    height: '33px',
    fontSize: '14px',
    borderRadius: '20px',
    border: '3px solid #e0e0e0'
  }
};
