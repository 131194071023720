import { types as t } from 'mobx-state-tree';

export const Accreditation = t.model({
  nid: t.number,
  uuid: t.string,
  title: t.string,
  short_name: t.string,
  profession: t.number,
  accr_unit: t.maybeNull(t.string),
  accr_unit_plural: t.maybeNull(t.string),
  accr_unit_short: t.maybeNull(t.string),
  extra_points_unit: t.maybeNull(t.string),
  accr_subtext: t.maybeNull(t.string),
  accr_registers: t.maybeNull(t.number),
  logo: t.maybeNull(t.string),
  accr_logo: t.maybeNull(t.string)
});

export const Package = t.model('Package', {
  nid: t.number,
  uuid: t.string,
  tab_label: t.maybeNull(t.string),
  body: t.maybeNull(t.string),
  accredited: t.number,
  course_container: t.number, // reference per course container
  accreditation: t.maybeNull(Accreditation)
});

export const Packages = t.array(Package);
