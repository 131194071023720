import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEwaApiPost } from '../hooks/useEwaApiPost';
import { DialogBox } from './DialogBox';
import { formatDate } from '../lib/formatDate';

export const RegisterEventDialogBox = ({ selectedData, onClose }) => {
  const { t } = useTranslation('vtb');
  const [mailNotification, setMailNotification] = useState(false);

  const toastSuccessText = !selectedData?.isUserAttending ? t('toast.registered') : t('toast.unregistered');
  const translationKey = selectedData?.isUserAttending ? 'alreadySignedIn' : 'notSignedIn';

  const { post, isLoading } = useEwaApiPost({ toastTextSuccess: toastSuccessText, toastTextFailure: t('toast.failed') });

  const translations = useMemo(
    () => ({
      title: t(`popups.${translationKey}.title`),
      cancelButtonText: t(`popups.${translationKey}.cancelText`),
      submitButtonText: t(`popups.${translationKey}.submitButtonText`),
      text: t(`popups.${translationKey}.text`, { eventDate: formatDate(selectedData?.vtbEvent.attributes.date.value) }),
      mailNotificationText: t('popups.mailNotificationText')
    }),
    [selectedData?.vtbEvent.attributes.date.value, t, translationKey]
  );

  const handleAction = useCallback(async () => {
    const eventDrupalId = selectedData?.vtbEvent.attributes?.drupal_internal__id.toString();

    if (!eventDrupalId) {
      return;
    }

    const endpoint = selectedData?.isUserAttending ? 'signout' : 'signup';

    await post({
      url: `events/${eventDrupalId}/${endpoint}`,
      body: {
        data: {
          type: 'event_signup',
          attributes: {
            wants_confirmation: mailNotification ? 1 : 0
          }
        }
      }
    });

    onClose();
  }, [selectedData?.vtbEvent.attributes?.drupal_internal__id, selectedData?.isUserAttending, post, mailNotification, onClose]);

  return (
    <DialogBox
      title={translations.title}
      isLoading={isLoading}
      onAction={handleAction}
      onClose={onClose}
      cancelButtonText={translations.cancelButtonText}
      submitButtonText={translations.submitButtonText}
      open={!!selectedData}
      text={translations.text}
      mailNotification={mailNotification}
      setMailNotification={setMailNotification}
      mailNotificationText={translations.mailNotificationText}
    />
  );
};

RegisterEventDialogBox.propTypes = {
  selectedData: PropTypes.any,
  onClose: PropTypes.func.isRequired
};
