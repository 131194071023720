import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

/*
In summary, the MyTextField component is a flexible text input component that can operate in both controlled and uncontrolled modes. 
It allows users to enter text values and can handle numeric input when necessary. 
This component is commonly used in forms and can be easily integrated with libraries like react-hook-form for controlled form handling.
*/
// eslint-disable-next-line react/prop-types
const MyTextField = forwardRef(({ name, control, onChange: onChangeProp, type = 'text', ...otherProps }, ref) => {
  const configTextfield = {
    size: 'small',
    fullWidth: true,
    variant: 'outlined',
    type,
    ...otherProps
  };

  return control ? (
    <Controller
      name={name}
      control={control}
      shouldUnregister={false}
      defaultValue={otherProps.defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          ref={ref}
          onChange={(e) => {
            const val = e.target.value.match(/^\d*\.?\d*$/);
            if (!val) return;
            field.onChange(val[0]);
          }}
          {...configTextfield}
        />
      )}
    />
  ) : (
    <TextField ref={ref} {...configTextfield} defaultValue={otherProps.defaultValue} name={name} />
  );
});

MyTextField.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  onChange: PropTypes.func,
  type: PropTypes.string
};

export default MyTextField;
