import { types as t } from 'mobx-state-tree';
import { Links, RelFile, RelProfessions, Body } from './BaseModel';

// TODO: Remove 'field_' @ 'field_professions' in drupal
export const Author = t.model('Author', {
  id: t.identifier,
  type: t.string,
  attributes: t.model({
    drupal_internal__nid: t.number,
    body: t.maybeNull(Body),
    title: t.maybeNull(t.string),
    first_name: t.maybeNull(t.string),
    last_name: t.maybeNull(t.string)
  }),
  relationships: t.model({
    field_photo: t.maybeNull(RelFile),
    professions: t.maybeNull(RelProfessions)
  })
});

export const RelAuthors = t
  .model('RelAuthors', {
    data: t.maybeNull(
      t.array(
        t.model({
          id: t.maybeNull(t.reference(Author)),
          type: t.string
        })
      )
    ),
    links: Links
  })
  .views((self) => ({
    get authorrefs() {
      if (self.data) {
        return self.data.map((authorrefs) => authorrefs.id);
      }
      return [];
    }
  }));
