/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rawHTML } from 'helpers/Helpers';
import { Box, Button, FormControl, RadioGroup, Radio, FormControlLabel, Typography } from '@mui/material';

const PollVote = ({ options, title, onSubmit, disable }) => {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation('widget');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(inputValue);
  };

  return (
    <Box p={2} component="form" onSubmit={handleSubmit}>
      <Typography variant="p" sx={{ fontWeight: 'bold' }}>
        {rawHTML(title)}
      </Typography>
      <FormControl sx={{ width: '100%' }} disabled={disable}>
        <RadioGroup value={inputValue} onChange={(e) => setInputValue(e.target.value)}>
          {options.map(({ value }, id) => (
            <FormControlLabel key={id} value={id} control={<Radio />} label={value} />
          ))}
        </RadioGroup>
        <Box sx={{ textAlign: 'center' }} mt={2}>
          <Button type="submit" variant="contained" disabled={disable}>
            {t(`vote`, `Vote`)}
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};

PollVote.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  disable: PropTypes.any
};

export default PollVote;
