import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import ComponentProps from 'helpers/ComponentProps';

// renders a subtitle with a bottom border
const SubTitle = (props) => {
  const { title, display, customStyle } = props;
  const style = ComponentProps();

  const defaultStyle = { marginTop: '10px', display: display || 'inline-block' };
  const inlineStyle = customStyle ? { ...defaultStyle, ...customStyle } : { ...defaultStyle };

  return (
    <Typography style={inlineStyle} {...style.subTitle}>
      {title}
    </Typography>
  );
};

SubTitle.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object,
  theme: PropTypes.object,
  display: PropTypes.string,
  customStyle: PropTypes.object
};

export default SubTitle;
