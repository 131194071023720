import { useState } from 'react';
import { Divider, Button, Typography, Paper } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import 'theme/faq/Faq.scss';
import { Questions } from '../faq2';

const Answer = () => {
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Paper style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: 'none', maxWidth: '1200px' }}>
        <div style={{ width: '100%' }}>
          <div style={{ margin: '3% 0%' }}>
            <Breadcrumbs>
              <Link underline="hover" href="/">
                HOME
              </Link>
              <Link underline="hover" href="/faq3">
                FAQ
              </Link>
              <Link underline="hover" href="/article">
                ARTICLE
              </Link>
            </Breadcrumbs>
          </div>
          <Typography variant="h3" color="primary" style={{ marginBottom: '6%' }}>
            {Questions[1].question}
          </Typography>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris varius lacinia ligula, congue varius nulla varius eu. Praesent
            bibendum pharetra orci, nec suscipit justo posuere quis. Nullam et pulvinar lorem. Nulla eget justo lectus. Etiam scelerisque
            viverra mi non dignissim. Vestibulum sodales lorem quis erat sollicitudin facilisis. Ut id elit sed lorem pulvinar elementum in
            nec leo.
          </p>
          <p>
            Aliquam erat volutpat. Duis porttitor risus non purus mollis, sit amet ullamcorper neque cursus. Duis facilisis quam a urna
            porttitor bibendum. Vestibulum viverra erat scelerisque felis posuere, at dapibus sem faucibus. Integer volutpat odio in augue
            scelerisque auctor sed maximus quam. Duis pharetra, nisi a mollis suscipit, quam urna aliquam enim, in suscipit massa dolor vel
            nibh. Pellentesque tellus ante, ultrices id nibh ac, varius venenatis lorem. Fusce porttitor metus eget commodo convallis.
            Vestibulum eget ante sodales, efficitur tellus at, fermentum mauris. Ut id rutrum dui. Nam varius sapien purus, et convallis
            odio maximus efficitur. Pellentesque pellentesque purus at urna rutrum tristique. Nunc in sagittis felis, id ultricies magna.
            Cras efficitur varius ipsum, in convallis arcu posuere id.
          </p>
          <p>
            Etiam eget tempor tellus. Etiam fermentum, elit in tristique porttitor, turpis felis lacinia lorem, et commodo quam sapien
            egestas purus. Aliquam egestas ullamcorper vulputate. Donec vitae elit justo. Nullam ac enim sagittis, blandit turpis sit amet,
            congue tortor. Maecenas quis purus ac tortor fringilla pellentesque at sed ex. Integer augue augue, varius ut magna non,
            ultrices euismod turpis.
          </p>
          <ul>
            <li>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias numquam culpa ipsum nihil laborum libero, aliquid maxime
              provident cupiditate unde, deserunt quidem ex dignissimos corporis voluptas doloremque doloribus corrupti earum.
            </li>
            <li>
              Assumenda, quo? Quos, rem ad. Vel corrupti, enim tempore sunt perferendis ab tempora ex aspernatur eveniet rerum. Quas, in
              atque numquam iste at aspernatur quaerat maiores ut molestias veniam aliquid?
            </li>
            <li>
              A sunt delectus quas velit officia ea quisquam iste odit excepturi, veritatis quo explicabo. Labore eveniet nobis qui
              doloribus, hic iste dolorum voluptates obcaecati recusandae eos totam soluta, consectetur culpa?
            </li>
            <li>
              Quo itaque excepturi facere neque qui ipsa quas recusandae mollitia, autem, nemo eius eveniet accusamus harum modi corporis
              culpa nobis aspernatur, maiores perspiciatis iste saepe quia! Officiis modi nisi libero!
            </li>
          </ul>
          <Divider style={{ marginTop: '43px' }} />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="faq_article">
            <div>
              <Typography variant="h6" style={{ fontSize: '24px' }}>
                Was this article helpful?
              </Typography>
              {clicked ? (
                <div style={{ textAlign: 'center' }}>Thank you for your feedback!</div>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3%', gap: '2%' }}>
                  <Button onClick={() => setClicked(true)}>YES</Button>
                  <Button onClick={() => setClicked(true)}>NO</Button>
                </div>
              )}
            </div>
          </div>
          <Divider />
        </div>
      </Paper>
    </>
  );
};

export default Answer;
