import { Stack } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import LmsTable from '../components/LmsTable';
import LmsToolbar from '../components/LmsToolbar';
import { getLmsOrganisations } from '../api';

const LmsOrganisations = () => {
  const { t } = useTranslation();

  return (
    <Stack rowGap={2} sx={{ background: 'white' }}>
      <LmsToolbar title={t('lms.organisations.title')} orgType={1} profType={1} dateType={1} />
      <LmsTable title={t('lms.organisations.title')} getData={getLmsOrganisations} options={{ paging: false, pageSize: null }} />
    </Stack>
  );
};

export default observer(LmsOrganisations);
