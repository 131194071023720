/* Core */
import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

/* Custom styling */
import 'theme/account/Account.scss';

/* MUI */
import { Grid, Paper } from '@mui/material';

/* Custom components */
import { useBreakpoint } from 'hooks';
import AccountSidebar from './AccountSidebar';

// This component is responsible for the account page. It renders a sidebar and the main content.

const AccountV2 = (props) => {
  const { children } = props;
  const store = useContext(Store);
  const mobileBreakpoint = useBreakpoint();

  const orientationChange = mobileBreakpoint === 'sm' || mobileBreakpoint === 'xs' || (mobileBreakpoint === 'md' && !store.minDrawer);

  return (
    <Grid container className={orientationChange ? 'account account_sidebar_open' : 'account'} spacing={3}>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
        <AccountSidebar />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={9} display="flex" flexDirection="column" gap="11px">
        <Paper
          style={{
            boxShadow: 'none',
            border: 'none',
            borderRadius: '18px',
            minHeight: '545px'
          }}
        >
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};

AccountV2.propTypes = {
  children: PropTypes.element
};
export default observer(AccountV2);
