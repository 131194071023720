/* Core */
import PropTypes from 'prop-types';

/* MUI */
import { Box, Tabs, Tab } from '@mui/material';

/*
In summary, the TabComponent is a flexible tabbed interface component that allows developers to create tabbed navigation elements. 
It can render tabs as interactive elements with click handlers or as links that navigate to specific sections on the page. 
The component provides control over the appearance and behavior of the tabs through its props, making it suitable for a variety of use cases.
*/
const TabComponent = (props) => {
  const { tabs, variant, orientation, selectedTab, indicatorColor, ...rest } = props;
  return (
    <Box>
      <Tabs variant={variant} orientation={orientation} selectionFollowsFocus value={selectedTab} indicatorColor={indicatorColor} {...rest}>
        {tabs.map(
          (item) =>
            item &&
            (selectedTab ? (
              <Tab key={item.id} onClick={item.callback} label={item.label} value={item.id} />
            ) : (
              <Tab
                key={item.id}
                onClick={item.callback}
                label={item.label}
                value={item.id}
                data-to-scrollspy-id={item.id}
                href={`#${item.id}`}
              />
            ))
        )}
      </Tabs>
    </Box>
  );
};

TabComponent.propTypes = {
  tabs: PropTypes.array,
  variant: PropTypes.string,
  orientation: PropTypes.string,
  selectedTab: PropTypes.any,
  indicatorColor: PropTypes.string
};

export default TabComponent;
