import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import * as Types from '../../../types';

const Reflections = ({ evaluation }) => {
  const { t } = useTranslation('curriculum');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Typography component="p" variant="h6" sx={{ fontSize: '1rem' }}>
        {t('evaluations.reflectionTitle')}
      </Typography>

      <Typography paragraph>{t('evaluations.reflectionText1Label')}</Typography>
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '1rem',
          borderRadius: '10px'
        }}
      >
        {evaluation?.attributes?.evaluation?.length > 0 ? evaluation.attributes.evaluation : <i>{t('evaluations.noEvaluationText')}</i>}
      </Box>

      <Typography paragraph>{t('evaluations.reflectionText2Label')}</Typography>
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '1rem',
          borderRadius: '10px'
        }}
      >
        {evaluation?.attributes?.name?.length > 0 ? evaluation.attributes.name : <i>{t('evaluations.noReflectionText')}</i>}
      </Box>
    </Box>
  );
};

Reflections.propTypes = {
  evaluation: Types.Evaluation.isRequired
};

export default observer(Reflections);
