/* eslint-disable camelcase */
/* Core */
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';

/* Custom styling */
import { Box, Grid } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

/* Custom components */
import { getCourseCardProps, courseOverviewImgSize } from 'helpers/Helpers';
import BasicCard from './Card/CourseGridCard';
import BasicCardListMin from './Card/CourseGridCardListMin';
import NoCourses from './Card/elements/NoCourses';
import CourseTableCell from './Grid/CourseTable/CourseTableRow';

/*
In summary, the CourseStarted component is responsible for rendering a list of Started courses.
The layout and appearance of these courses are determined by the value of store.courseoverview.
If no courses are started, it displays a message encouraging the user to start a new course.
If there are started courses, it generates a grid layout and populates it with course cards based on the selected layout style.
*/
const CourseStarted = () => {
  const store = useContext(Store);
  const { t } = useTranslation();

  useEffect(() => {
    if (store.courseoverview === 1) {
      courseOverviewImgSize();
    }
  }, [store.courseoverview]); // Run this effect when the loads

  const startedId =
    store.hidden?.hidden_courses.length > 0
      ? store.courseResults.getShownCourses(store.hiddenIds).map((cr) => cr.id)
      : store.courseResults.getStartedCourses().map((cr) => cr.id);
  //
  const lessonResultSorted = store.lesson_results.lessonResults
    .filter((ls) => startedId.includes(ls.course_result.id))
    .sort((a, b) => b.changed - a.changed)
    .map((course) => course.course_result);
  //
  const startedCourses = [...new Map(lessonResultSorted.map((m) => [m.id, m])).values()];
  const isGridLayout = store.courseoverview === 3;

  // Items
  const gridItemXs = 12;
  const gridItemSm = isGridLayout ? 6 : 12;
  const gridItemMd = isGridLayout ? 4 : 12;
  const gridItemLg = isGridLayout ? 6 : 12;
  const gridItemXl = isGridLayout ? 3 : 12;

  const setColumns = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };

  if (!startedCourses.length) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <NoCourses text={t('courseoverview.noStarted', 'Start a new course!')} icon={<RocketLaunchIcon color="primary" />} />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={setColumns}>
        {startedCourses &&
          startedCourses.map((course, index) => {
            const propsBasicCard = getCourseCardProps(course.course_id);
            return (
              <React.Fragment key={index}>
                {store.courseoverview === 1 && (
                  <Grid item key={propsBasicCard.nid} xs={12} sm={12} md={6} lg={6} xl={4}>
                    <BasicCardListMin {...propsBasicCard} hideCourse />
                  </Grid>
                )}
                {store.courseoverview === 2 && (
                  <Grid item key={propsBasicCard.nid} xs={gridItemXs} sm={gridItemSm} md={gridItemMd} lg={gridItemLg} xl={gridItemXl}>
                    <BasicCard {...propsBasicCard} hideCourse />
                  </Grid>
                )}
                {store.courseoverview === 3 && (
                  <Grid
                    item
                    key={propsBasicCard.nid}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ marginTop: 0, [`.card__container`]: { marginTop: 0 } }}
                  >
                    <CourseTableCell propsBasicCard={propsBasicCard} hideCourse />
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
      </Grid>
    </Box>
  );
};

export default observer(CourseStarted);
