import AdminCourseLayout from 'pages/adminCourses/AdminCourseLayout';
import CourseSearchBar from './CourseSearchBar';

const CourseSearchBarViewMap = {
  courseSearchBar: (
    <AdminCourseLayout menu="search_course" title="Search Course">
      <CourseSearchBar noPaper />
    </AdminCourseLayout>
  )
};

export default CourseSearchBarViewMap;
