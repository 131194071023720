/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { MySelect, MyTextField, MyCheckbox } from 'components/form/RHF';
import MyAutocomplete from 'components/form/RHF/Autocomplete/MyAutocomplete';

const ProfileFields = ({ type, ...props }) => {
  if (type === 'select') {
    const { margin, ...rest } = props;
    return <MySelect {...rest} />;
  }
  if (type === 'checkbox') {
    const { margin, fullWidth, ...rest } = props;
    return <MyCheckbox {...rest} />;
  }
  if (type === 'autocomplete') {
    const { margin, ...rest } = props;
    return <MyAutocomplete {...rest} />;
  }
  return <MyTextField {...props} />;
};

ProfileFields.propTypes = {
  type: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.any
};

export default observer(ProfileFields);
