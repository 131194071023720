export const CURRICULUM_STAGE = {
  functions: 'functions',
  learningGoals: 'learning-goals',
  evaluation: 'evaluation'
};

export const CURRICULUM_STEP = {
  functions: 1,
  'learning-goals': 2,
  evaluation: 3
};

export const CURRICULUM_STAGE_BY_STEP = {
  1: CURRICULUM_STAGE.functions,
  2: CURRICULUM_STAGE.learningGoals,
  3: CURRICULUM_STAGE.evaluation
};

export const CURRICULUM_TYPE = {
  function: 'portfolio_function',
  functionActivity: 'function_activity',
  learningGoal: 'learning_goal',
  learningGoalActivity: 'learning_goal_activity',
  learningGoalEvaluation: 'learning_goal_evaluation',
  learningGoalEvaluationActivity: 'learning_goal_evaluation_activity',
  courseResult: 'course_result'
};
