/* Course status */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';

/* MUI */
import { Chip } from '@mui/material';

// renders the course status
const CourseStatus = (props) => {
  const { status, isCourseExpiring, size = 'medium', nid } = props;
  const { t } = useTranslation();
  const store = useStore();
  // hide expired label on labels pe/po when course end date is 31.12
  const hideExpiredLabel = store?.courses?.hideExpiredLabel(nid);
  if (hideExpiredLabel) return null;

  const course = {
    Expected: { text: t('course.badges.badge2', 'Expected'), color: '#6F6F6F' },
    Expire: { text: t('course.badges.badge3', 'Expire'), color: 'primary.main' },
    Update: { text: t('course.badges.badge4', 'Update'), color: '#007ABE' },
    New: { text: t('course.badges.badge1', 'New'), color: '#62A000' },
    Expiring: { text: t('course.badges.expiringSoon', 'Expires soon'), color: '#b04744' }
  };

  if ((!status || status === 'Default') && !isCourseExpiring) return null;

  return (
    <Chip
      className="badge_course_status"
      label={isCourseExpiring ? course.Expiring.text : course[status].text}
      size={size}
      sx={{ backgroundColor: isCourseExpiring ? course.Expiring.color : course[status].color, color: 'white' }}
    />
  );
};

CourseStatus.propTypes = {
  status: PropTypes.string,
  isCourseExpiring: PropTypes.bool,
  size: PropTypes.string,
  nid: PropTypes.number
};

export default CourseStatus;
