/* eslint-disable camelcase */
import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
/* MUI COMPONENTS */
import { Grid, Button, Box } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import ArchiveIcon from '@mui/icons-material/Archive';
import { LoadingButton } from '@mui/lab';
/* COMPONENTS */
import { Form } from 'components/form';
import { TabComponent } from 'components';
/* HELPER */
import { editCourseModel, EditCourseDefaultValues, Toast } from './EditCourse.helper';
import { FrmCourse, FrmImages, FrmIntro, FrmLessons, FrmSharing, FrmStudents, FrmFeedback } from './forms';
//
import LessonScreen from '../components/dialog/LessonScreen';
import IntroductionPreviewDialog from '../components/dialog/IntroductionPreviewDialog';
import EditWidgetDialog from '../components/dialog/EditWidgetDialog';
//
import { BtnLinkedCourse, CheckEnableEdit, TxtReUsed, BtnDropdownOptions } from '../components/editCourse';
import BtnShowSidebar from '../components/layout/BtnShowSidebar';
/* LOADERS */
import LoaderWithBackdrop from '../components/loading/LoaderWithBackdrop';
import LoadingLinearProgress from '../components/loading/LoadingLinearProgress';
/* SCHEMAS */
import { EditCourse as EditCourseSchema } from './forms/beans/EditCourse.ValidationSchema';
/* CUSTOM SCSS */
import './addCourse.scss';

/*
  EDIT COURSE PAGE
  In summary, the EditCourse component serves as an interface for editing course details. 
  It allows users to navigate between different editing tabs, including an introduction section and sections for managing images and lessons. 
  Users can save their changes and preview the course details as well. 
  It integrates with the courseEdit store for data management.
 */
const EditCourse = () => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm');
  const { courseEdit } = store;
  const { currentCourse } = courseEdit;
  const { lesson_container } = currentCourse?.relationships || {};
  //
  const [loading, setLoading] = useState(false);
  const [overRideEdit, setOverRideEdit] = useState(false);
  // OPEN DIALOG PREVIEW
  const [open, setOpen] = useState(false);
  const [previewValues, setPreviewValues] = useState(false);
  //
  const showSidebar = store.courseEdit.showSidebarEdit;
  const setDisplay = showSidebar ? 'flex' : 'none';
  //
  const linked = store?.routerStore?.routerState?.queryParams?.type === 'linked';

  // COURSE DEFAULT VALUES ____________________________________________________
  const defaultValues = EditCourseDefaultValues(store);

  // TOASTS MESSAGE WITH TRANSLATIONS _________________________________________
  const toast = Toast();

  // TAB FUNCTIONS ____________________________________________________________
  const changeTab = (tab) => () => {
    window.history.pushState(null, '', `?tab=${tab}`);
    courseEdit.setEditTab(tab);
  };
  // tab items
  const tabItems = [
    { id: 'Introduction', label: <>{t('editCourse.tabs.introduction', 'Introduction')}</>, callback: changeTab('Introduction') },
    { id: 'Images', label: <>{t('editCourse.tabs.images', 'Images')}</>, callback: changeTab('Images') },
    { id: 'Lessons', label: <>{t('editCourse.tabs.lessons', 'Lessons')}</>, callback: changeTab('Lessons') },
    { id: 'Sharing', label: <>{t('editCourse.tabs.sharing', 'Sharing Info')}</>, callback: changeTab('Sharing') },
    { id: 'Students', label: <>{t('editCourse.tabs.students', 'Students')}</>, callback: changeTab('Students') },
    { id: 'Feedback', label: <>{t('editCourse.tabs.feedback', 'Feedback')}</>, callback: changeTab('Feedback') }
  ];
  // check if tab exists || default tab ('Introduction')
  const isTabItem = tabItems.find((tab) => tab.id === courseEdit.editTab);
  if (!isTabItem) courseEdit.setEditTab('Introduction');
  // get current tab
  const { editTab } = courseEdit;

  // PREVIEW DIALOG FUNCTIONS ________________________________________________
  const handleClickDialog = (passedValues) => {
    setPreviewValues(passedValues);
    setOpen(!open);
  };

  // OVERRIDE EDIT FUNCTIONS __________________________________________________
  const handleOverRideEdit = () => setOverRideEdit(!overRideEdit);

  // ENABLE / DISABLE FIELDS __________________________________________________
  const isDisabled = (name) => {
    if (linked && !overRideEdit) return true;
    if (linked && overRideEdit) return false;
    const isReUsed = courseEdit.isReUsedCoursed();
    const isCourse = name.startsWith('course');
    return isReUsed && !isCourse;
  };

  // PATCH PICTURE (helper function used in handleSubmit) _______________________
  const picturePatch = async (newValues, key) => {
    const isObject = typeof newValues.lessonContainer.relationships[key].data === 'object';
    if (newValues.lessonContainer.relationships[key].data && newValues.lessonContainer.relationships[key].data !== '' && isObject) {
      await lesson_container.data.id.patchPicture(lesson_container?.data?.id, newValues.lessonContainer.relationships[key].data, key);
      delete newValues.lessonContainer.relationships[key];
    }
    delete newValues.lessonContainer.relationships[key];
  };

  // SUBMIT FORM ______________________________________________________________
  const handleSubmit = async (values) => {
    setLoading(true);
    const newValues = editCourseModel(values);
    try {
      await picturePatch(newValues, 'field_course_image_extern');
      await picturePatch(newValues, 'field_course_header_photo');
      await picturePatch(newValues, 'field_coursephoto');
      // POST VALUES TO API
      await courseEdit.patchCourseData(currentCourse, newValues);
      // show toast
      courseEdit.setToasts({ ...toast.success, ...toast.default });
      setLoading(false);
    } catch (error) {
      courseEdit.setToasts({ ...toast.error, ...toast.default });
      setLoading(false);
    }
  };

  return (
    <Box className="edit-course" sx={sxBoxEditCourse}>
      {courseEdit.isReUsedCoursed() && <TxtReUsed />}
      <LoaderWithBackdrop open={loading} />
      <Form onSubmit={handleSubmit} initialValues={defaultValues} validationSchema={EditCourseSchema}>
        {({ control, dirty, reset, watch, ...restProps }) => (
          <Grid container className="formContainer" sx={{ flex: '1', marginBottom: '50px', position: 'relative' }}>
            <Grid
              item
              xs={12}
              md={showSidebar ? 8 : 12}
              lg={showSidebar ? 9 : 12}
              container
              direction="column"
              spacing={2}
              className="formContainer-left"
            >
              <Box
                className="new-course-tabs"
                sx={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'wrap', gap: '10px', marginBottom: '10px' }}
              >
                {/* TAB ITEMS */}
                <Box className="new-course-tabs-container" sx={{ padding: '10px 15px 0px 15px!important' }}>
                  <TabComponent selectedTab={editTab} tabs={tabItems} orientation="horizontal" />
                </Box>
                {/* PREVIEW / LINKED / CHECKBOX */}
                <Box className="course_edit_options" sx={sxBoxInfo}>
                  <CheckEnableEdit handleOverRideEdit={handleOverRideEdit} overRideEdit={overRideEdit} linked={linked} />
                  <BtnLinkedCourse />
                  <BtnDropdownOptions handleClickDialog={handleClickDialog} getValues={restProps.getValues} />
                  <BtnShowSidebar right="-115px" rightHover="-33px" />
                </Box>
              </Box>

              {/* TAB CONTENT -> FORMS */}
              <Box sx={{ display: 'flex', flex: 1 }} className="tab-panel">
                {editTab === 'Introduction' && <FrmIntro control={control} isDisabled={isDisabled} {...restProps} />}
                {editTab === 'Images' && <FrmImages control={control} isDisabled={isDisabled} {...restProps} />}
                {editTab === 'Lessons' && <FrmLessons control={control} isDisabled={isDisabled} {...restProps} />}
                {editTab === 'Sharing' && <FrmSharing control={control} isDisabled={isDisabled} dirty={dirty} {...restProps} />}
                {editTab === 'Students' && <FrmStudents control={control} isDisabled={isDisabled} dirty={dirty} {...restProps} />}
                {editTab === 'Feedback' && <FrmFeedback control={control} isDisabled={isDisabled} dirty={dirty} {...restProps} />}
              </Box>
            </Grid>
            <Grid
              sx={{ paddingTop: '0px', marginTop: '0px', display: setDisplay }}
              item
              xs={12}
              md={4}
              lg={3}
              container
              className="formContainer-right"
            >
              {/* FORM COURSE @ RIGHT SIDEBAR */}
              <FrmCourse control={control} isDisabled={isDisabled} {...restProps} />
            </Grid>

            {/* FORM ACTION BAR: saving progress indicator, [reset button], [save button] */}
            <Box className="formActionBar" sx={sxFormActionBar}>
              <Box>
                <LoadingLinearProgress loading={loading} />
              </Box>
              <Box className="formActionBar_buttons">
                <Button disabled={!dirty} variant="text" onClick={() => reset(defaultValues)} startIcon={<UndoIcon />}>
                  {t('editCourse.reset')}
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  endIcon={<ArchiveIcon />}
                  disabled={!dirty || (linked && !overRideEdit) || Object.keys(restProps.errors).length > 0}
                  loading={loading}
                  loadingPosition="end"
                >
                  {t('editCourse.save')}
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        )}
      </Form>

      {/* Dialogs */}
      <IntroductionPreviewDialog open={open} handleClickDialog={handleClickDialog} previewValues={previewValues || {}} />
      <LessonScreen open={courseEdit.dialogLesson.open} />
      <EditWidgetDialog />
    </Box>
  );
};

export default observer(EditCourse);

// CUSTOM SX _____________________________________________________________
const sxBoxInfo = {
  display: 'flex',
  alignSelf: 'center',
  fontSize: '20px',
  fontWeight: '900',
  color: '#c0cce4',
  gap: '10px',
  padding: '0px 15px'
};
const sxBoxEditCourse = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  '.MuiFormLabel-root': { background: 'white', padding: '0px 5px' },
  ' form': { display: 'flex', flexDirection: 'column', flex: '1' }
};
const sxFormActionBar = { display: 'block!important', '.formActionBar_buttons': { display: 'flex', justifyContent: 'space-between' } };
