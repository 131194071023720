import { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Input from '@mui/material/TextField';
import { Box } from '@mui/material';

/*
In summary, the Open component is a text input field that allows users to enter open-ended text responses. 
It provides options for customization and includes features like character limits. 
The component is designed to be easily integrated into different parts of an application and offers internationalization support for messages and labels.
*/
const Open = (props) => {
  const { value, handleInput, disabled } = props;

  const [inputValue, setInputValue] = useState(value);

  const inputProps = { type: 'text', label: 'Antwoord', rows: '4', margin: 'normal', variant: 'outlined' };

  const handleChange = (event) => {
    handleInput(event.target.value);
    setInputValue(event.target.value);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Input {...inputProps} multiline value={inputValue} onChange={handleChange} disabled={disabled} inputProps={{ maxLength: 10000 }} />
    </Box>
  );
};

Open.propTypes = {
  value: PropTypes.string,
  handleInput: PropTypes.func,
  disabled: PropTypes.bool
};

export default observer(Open);
