/* eslint-disable camelcase */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { useStore } from 'hooks';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import SchoolIcon from '@mui/icons-material/School';
import TopLinks from 'pages/course/components/CourseOverview/TopLinks';
import TeacherSearch from './components/TeacherSearch';
import TeacherCard from './components/TeacherCard';
import { getFilteredData } from './getFilteredData';
import useDebounce from '../../hooks/useDebounce';

const TeachersOverview = () => {
  const store = useStore();
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { product, packageId } = store.params;
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    const init = async () => {
      const result = await window.authedClient(`${process.env.REACT_APP_JSONAPI}/ewapi/product/${product}/package/${packageId}/json`);

      const { authors, profession } = result.data.data;

      const parsedAuthors = authors
        .map((author) => {
          const { titles, first_name: firstName, last_name: lastName, affiliation } = author;
          const fullName = [titles, firstName, lastName, affiliation]
            .filter(Boolean)
            .map((el) => el.trim())
            .join(' ');

          const img = author.photo_uri && `${process.env.REACT_APP_JSONAPI}/${author.photo_uri}`;

          return { ...author, img, fullName };
        })
        .sort((a, b) => a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()));

      setData({
        authors: parsedAuthors,
        advisoryBoard: profession.advisory_board
          .map((advisoryBoardAuthorId) => parsedAuthors.find((author) => author.id === advisoryBoardAuthorId))
          .sort((a, b) => a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()))
      });
    };

    init();
  }, [packageId, product, setData]);

  if (!data) {
    return <CircularProgress color="inherit" size={20} />;
  }

  const filteredData = getFilteredData(data, debouncedSearchText);

  if (!filteredData.authors.length && !filteredData.advisoryBoard.length) {
    return (
      <Box className="teacherOverview">
        <TopLinks current="teachersOverview" />
        <Box className="teachers_overview_box">
          <TeacherSearch placeholder={t('teachers.search')} onChange={setSearchText} />
          <Box className="noTeachers">
            <IconButton aria-label="add to favorites" className="school_icon">
              <SchoolIcon color="primary" />
            </IconButton>
            <Typography color="primaryBlue.main" variant="h1" fontSize="16px">
              {t('teachers.noTeachers', 'No teachers available')}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="teacherOverview">
      <TopLinks current="teachersOverview" />
      <Box className="teachers_overview_box">
        <TeacherSearch placeholder={t('teachers.search')} onChange={setSearchText} />
        {filteredData?.advisoryBoard?.length > 0 && (
          <>
            <Typography
              variant="h5"
              sx={{
                mt: 3,
                color: 'primary.main'
              }}
            >
              {t('teachers.title1')}
            </Typography>
            <Grid container spacing={2} sx={{ mt: -2 }}>
              {filteredData.advisoryBoard.map(({ id, uuid, fullName, img }) => (
                <Grid container item key={id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  <TeacherCard teacherId={uuid} img={img} title={fullName} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {filteredData?.authors?.length > 0 && (
          <>
            <Typography
              variant="h5"
              sx={{
                mt: 3,
                color: 'primary.main'
              }}
            >
              {t('teachers.title2')}
            </Typography>
            <Grid container spacing={2} sx={{ mt: -2 }}>
              {filteredData.authors.map(({ id, uuid, fullName, img }) => (
                <Grid container item key={id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  <TeacherCard teacherId={uuid} img={img} title={fullName} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default observer(TeachersOverview);
