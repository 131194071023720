import { ThemeBrandTitle } from 'helpers/Helpers';
import { lmsBeforeEnter as beforeEnter } from 'pages/lms/LmsRouteActions';

export const lmsStudentsRoute = [
  {
    name: 'LmsStudents',
    pattern: '/lms/students',
    meta: { title: `%%lms.navbar.students1%% | ${ThemeBrandTitle()}` },
    beforeEnter
  },
  {
    name: 'LmsStudentsOpen',
    pattern: '/lms/students/open',
    meta: { title: `%%lms.navbar.students1%% | ${ThemeBrandTitle()}` },
    beforeEnter
  },
  {
    name: 'LmsStudentsCompleted',
    pattern: '/lms/students/completed',
    meta: { title: `%%lms.navbar.students1%% | ${ThemeBrandTitle()}` },
    beforeEnter
  }
];
