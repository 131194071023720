import { useCallback, useState } from 'react';
import { useStore } from '../../../hooks';

export const useEwaApiPost = ({ toastTextSuccess, toastTextFailure }) => {
  const store = useStore();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const post = useCallback(
    async ({ url, body }) => {
      setIsLoading(true);

      try {
        const response = await window.authedClient.post(`ewapi/${url}`, body ?? undefined);

        if (response.status > 199 && response.status < 300) {
          setData(response.data);
          store.showToast({ message: toastTextSuccess, variant: 'success' });
          setIsLoading(false);
          return;
        }

        setError(`${url} returned statuscode: ${response.status}`);
        store.showToast({ message: toastTextFailure, variant: 'error' });
      } catch (_) {
        setError('Something went wrong with the fetch');
        store.showToast({ message: toastTextFailure, variant: 'error' });
      }

      setIsLoading(false);
    },
    [store, toastTextSuccess, toastTextFailure]
  );

  return { post, data, isLoading, error };
};
