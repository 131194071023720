/* Core */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import PropTypes from 'prop-types';

/* MUI */
import { Box, Button } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const TopLinks = (props) => {
  const { t } = useTranslation();
  const store = useStore();
  const { current } = props;
  return (
    <>
      <Box className="top_links">
        <Button
          component="a"
          className={current === 'courseOverview' ? 'active' : ''}
          onClick={() => {
            const { product, productName, packageId } = store.params;
            store.routerStore.goTo('courseOverview', { params: { product, productName, packageId } });
          }}
        >
          <ArrowRightIcon /> {t('courseoverview.toplinks.link3', 'Course overview')}
        </Button>
        <Button
          component="a"
          className={current === 'colophonOverview' ? 'active' : ''}
          onClick={() => {
            const { product, productName, packageId } = store.params;
            store.routerStore.goTo('colophonOverview', { params: { product, productName, packageId } });
          }}
        >
          <ArrowRightIcon /> {t('courseoverview.toplinks.link1', 'Colophon')}
        </Button>
        <Button
          component="a"
          className={current === 'teachersOverview' ? 'active' : ''}
          onClick={() => {
            const { product, productName, packageId } = store.params;
            store.routerStore.goTo('teachersOverview', { params: { product, productName, packageId } });
          }}
        >
          <ArrowRightIcon /> {t('courseoverview.toplinks.link4', 'Our teachers')}
        </Button>
        {/* <Button
          component="a"
          className={current === 'faq' ? 'active' : ''}
          onClick={() => {
            const { product, productName, packageId } = store.params;
            store.routerStore.goTo('faq', { params: { product, productName, packageId } });
          }}
        >
          <ArrowRightIcon /> {t('courseoverview.toplinks.link2', 'Most frequent questions')}
        </Button> */}
      </Box>
    </>
  );
};

TopLinks.propTypes = {
  current: PropTypes.string
};

export default observer(TopLinks);
