import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';

import Dialog from 'components/dialog/Dialog';
import DialogTitle from 'components/dialog/DialogTitle';
import { Form } from 'components/form';
import { MySelect } from 'components/form/RHF';
import { useStore } from 'hooks';
import * as Styles from '../components/styles';

const AddNewCurriculumDialog = ({ isOpen, onCloseCallback, onSubmitCallback }) => {
  const classes = Styles.useCurriculumStyles();
  const store = useStore();
  const { t } = useTranslation('curriculum');

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const allowedYears = [currentYear, currentYear + 1];
  if (currentMonth === 0) {
    allowedYears.unshift(currentYear - 1);
  }
  const allowedValues = allowedYears.map((year) => ({ name: year }));

  const validationSchema = Yup.object().shape({
    year: Yup.number()
      .min(Math.min(...allowedYears))
      .max(Math.max(...allowedYears))
      .integer()
      .required()
  });

  const initialValues = {
    year: currentYear
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }
    onCloseCallback();
    return true;
  };

  const handleSubmit = async (values) => {
    store.startLoadCurriculum();
    const result = await store.createCurriculum(values);
    store.stopLoadCurriculum();

    if (!result) {
      return {
        success: false,
        errors: [{ name: 'root.serverError', error: { type: 'server', message: 'Internal Server Error' }, options: {} }]
      };
    }

    if (result.errors) {
      return {
        success: false,
        errors: result.errors
      };
    }

    onSubmitCallback(result?.data?.data);

    return { success: true };
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('dashboard.addNewCurriculumTitle')}</DialogTitle>
      <Form id="add-curriculum" onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ control, errors, reset }) => (
          <>
            <Grid container>
              <Grid item xs={12}>
                <MySelect
                  control={control}
                  name="year"
                  items={allowedValues}
                  autoFocus
                  id="year"
                  label={t('dashboard.yearLabel')}
                  fullWidth
                  helperText={errors?.year?.message}
                  error={!!errors?.year}
                  inputProps={{ classes: { paper: classes.mySelect } }}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                onClick={() => {
                  reset();
                  handleClose();
                }}
              >
                {t('dashboard.cancelLabel')}
              </Button>
              <Button type="submit" variant="contained" disabled={store.isLoadingCurriculum}>
                {t('dashboard.saveLabel')}
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

AddNewCurriculumDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCloseCallback: PropTypes.func,
  onSubmitCallback: PropTypes.func
};

export default observer(AddNewCurriculumDialog);
