import { Alert, Box, Button, Checkbox, Chip, Grid, Paper, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/styles';
import { format } from 'date-fns';
import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEvents } from './getEvents';
import { getStudents } from './getStudents';

const EventButton = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const aDate = props?.date?.getTime();
  const formattedDate = aDate ? format(aDate, 'dd-MM-yyyy') : '';

  return (
    <Paper
      sx={{
        width: '100%',
        padding: 4,
        borderRadius: 2,
        mb: 2,
        display: 'flex',
        alignItems: 'flex-start',
        boxShadow: '0 6px 15px rgba(0,0,0,0.15)',
        border: 'none',
        cursor: 'pointer',
        flexDirection: 'column',
        color: props.id === props.selectedEvent?.id ? theme.palette.getContrastText(theme.palette.primary.main) : 'black',
        backgroundColor: props.id === props.selectedEvent?.id ? theme.palette.primary.main : 'white',
        '&:hover': {
          boxShadow: '0 6px 15px rgba(0,0,0,0.3)'
        },
        textAlign: 'left'
      }}
      component="button"
      onClick={props.onClick}
    >
      <Typography variant="body1" sx={{ fontWeight: 800, mb: 1 }}>
        {props.title}
      </Typography>
      <Chip
        label={`${t('attendance.attendees', { count: props.attendees })}`}
        size="small"
        color={props.id === props.selectedEvent?.id ? 'secondary' : 'primary'}
        sx={{ mb: 2 }}
      />
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        {formattedDate}
      </Typography>
      <Typography variant="body2">{props.address} </Typography>
    </Paper>
  );
};

EventButton.propTypes = {
  id: PropTypes.any,
  selectedEvent: PropTypes.any,
  attendees: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.any,
  date: PropTypes.any,
  address: PropTypes.any
};
const AttendanceSheetLayout = () => {
  const [students, setStudents] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [ongoingBulkUpdate, setOngoingBulkUpdate] = useState(false);
  const ongoingUpdates = {};

  useEffect(() => {
    const init = async () => {
      const currentEvents = await getEvents();
      if (currentEvents.length > 0) {
        setEvents(currentEvents);
        const todayEvent = currentEvents.filter((eventItem) => !!eventItem.isToday)?.[0];
        setSelectedEvent(todayEvent);
      }
    };

    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      if (!selectedEvent) {
        setStudents([]);
        return;
      }
      setStudents([]); // Reset the students to show a loading state
      const studentsOfEvent = await getStudents(selectedEvent.uuid);
      if (studentsOfEvent.length > 0) {
        setStudents(studentsOfEvent);
      }
    };

    init();
  }, [selectedEvent]);

  const handleSetPresent = async (signupId, isPresent) => {
    // Prevent concurrent updates for the same signupId
    if (ongoingUpdates[signupId] || ongoingBulkUpdate) {
      return;
    }
    try {
      // Mark this signupId as having an ongoing update
      ongoingUpdates[signupId] = true;
      await window.authedClient.patch(`jsonapi/event_signups/${signupId}`, {
        data: {
          type: 'event_signups',
          id: signupId,
          attributes: {
            present: !!isPresent
          }
        }
      });
      // Fetch the updated list of students
      const updatedStudents = await getStudents(selectedEvent.uuid);
      setStudents(updatedStudents);
    } catch (error) {
      // Handle error appropriately
    } finally {
      // Once the update is complete or fails, allow new updates for this signupId
      delete ongoingUpdates[signupId];
    }
  };

  const notPresentStudents = students.filter((student) => !student.present);

  const handleMarkAllStudentActive = async () => {
    try {
      setOngoingBulkUpdate(true);
      await window.authedClient.subRequests(
        notPresentStudents.map((student) => ({
          action: 'update',
          uri: `jsonapi/event_signups/${student.signupId}`,
          body: JSON.stringify({
            data: {
              type: 'event_signups',
              id: student.signupId,
              attributes: {
                present: true
              }
            }
          })
        }))
      );
      const updatedStudents = await getStudents(selectedEvent.uuid);
      setStudents(updatedStudents);
    } catch (error) {
      console.error(error);
    } finally {
      setOngoingBulkUpdate(false);
    }
  };

  const todayEvents = events.filter((eventItem) => !!eventItem.isToday);

  return (
    <Box
      sx={{
        display: 'flex',
        paddingInline: { xs: '0.25rem', md: '1rem' },
        paddingTop: '1rem',
        paddingBottom: '1rem',
        marginTop: { xs: '15rem', md: '4rem' },
        flexDirection: 'column',
        gap: '0.625rem'
      }}
    >
      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {t('attendance.events')}
          </Typography>
          <Box sx={{ my: 2 }}>
            <Typography sx={{ mb: 1 }} variant="subtitle2">
              {t('attendance.today')}
            </Typography>
            {todayEvents.length < 1 && (
              <Box
                sx={{
                  height: 120,
                  backgroundColor: theme.palette.grey[200],
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: 400, color: theme.palette.grey[600] }} variant="h5">
                  {t('attendance.noEventsToday')}
                </Typography>
              </Box>
            )}

            {todayEvents?.map(({ id, uuid, address, date, title, totalSeats, seatsLeft, isFuture }) => (
              <EventButton
                attendees={totalSeats - seatsLeft}
                key={date + address + title}
                id={id}
                selectedEvent={selectedEvent}
                onClick={() => setSelectedEvent({ id, uuid, address, date, title, totalSeats, seatsLeft, isFuture })}
                title={title}
                date={date}
                address={address}
              />
            ))}
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('attendance.allEvents')}
            </Typography>

            {events.length < 1 && (
              <Box
                sx={{
                  height: 120,
                  backgroundColor: theme.palette.grey[200],
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: 400, color: theme.palette.grey[600] }} variant="h5">
                  {t('attendance.noEvents')}
                </Typography>
              </Box>
            )}

            {events?.map(({ id, uuid, address, date, title, totalSeats, seatsLeft, isFuture }) => (
              <EventButton
                attendees={totalSeats - seatsLeft}
                key={date + address + title}
                id={id}
                selectedEvent={selectedEvent}
                theme={theme}
                onClick={() => setSelectedEvent({ id, uuid, address, date, title, totalSeats, seatsLeft, isFuture })}
                title={title}
                date={date}
                address={address}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h5" sx={{ mb: 4 }}>
            {t('attendance.students')}
          </Typography>
          {selectedEvent?.isFuture && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {t('attendance.notActiveYet')}
            </Alert>
          )}
          {!selectedEvent?.isFuture && (
            <Box
              sx={{
                p: 3,
                backgroundColor: 'white',
                borderRadius: 2
              }}
            >
              <Box>
                <Box mb={2}>
                  <Button
                    variant="contained"
                    disabled={notPresentStudents.length === 0 || ongoingBulkUpdate}
                    onClick={handleMarkAllStudentActive}
                  >
                    {ongoingBulkUpdate && <CircularProgress size={24} color="inherit" />} {t('attendance.markAllPresent')}
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Box sx={{ flex: '0 0 100px', fontWeight: 800 }}>{t('attendance.present')}</Box>
                  <Box sx={{ flex: '0 0 200px' }}>
                    <Typography variant="label" sx={{ fontWeight: 800 }}>
                      {t('attendance.name')}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: '0 0 300px' }}>
                    <Typography variant="label" sx={{ fontWeight: 800 }}>
                      {t('attendance.email')}
                    </Typography>
                  </Box>
                </Box>
                {students?.length < 1 && (
                  <Alert variant="outlined" sx={{ mt: 2 }} color="info">
                    {t('attendance.noSignups')}
                  </Alert>
                )}
                {students?.map((student) => (
                  <Box
                    key={student.email}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2,
                      cursor: selectedEvent?.isFuture ? 'not-allowed' : 'pointer',
                      borderRadius: '30px',
                      transition: 'all ease-in-out 0.15s',
                      '&:hover': { backgroundColor: theme.palette.grey[200] }
                    }}
                    onClick={() => !selectedEvent?.isFuture && handleSetPresent(student.signupId, !student.present)}
                  >
                    <Box sx={{ flex: '0 0 100px' }}>
                      <Checkbox
                        checked={Boolean(student.present === 1 || student.present === true)}
                        onClick={() => handleSetPresent(student.signupId, !student.present)}
                      />
                    </Box>
                    <Box sx={{ flex: '0 0 200px' }}>
                      <Typography variant="body">{student.name}</Typography>
                    </Box>
                    <Box sx={{ flex: '0 0 300px' }}>
                      <Typography variant="body2">{student.email}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttendanceSheetLayout;
