import * as Yup from 'yup';

const passwordResetValidation = Yup.object().shape({
  current_password: Yup.string().required(window.i18next.t('passwordold')),
  new_password: Yup.string()
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{12,})/, // sonarclound is showing this as a security hotspot, but it is a false positive
      window.i18next.t('passwordreset')
    )
    .required(),
  repeat_password: Yup.string().oneOf([Yup.ref('new_password'), null], window.i18next.t('passwordmatch'))
});

export { passwordResetValidation };
