import { ThemeBrandTitle } from 'helpers/Helpers';
import { lmsBeforeEnter as beforeEnter } from 'pages/lms/LmsRouteActions';

export const lmsStudentDetailRoute = {
  name: 'LmsStudentDetail',
  pattern: '/lms/students/:id',
  meta: { title: `%%lms.navbar.students%% | ${ThemeBrandTitle()}` },
  beforeEnter,
  onEnter: async (fromState, toState, routerStore) => {
    // load student detail on entry
    const { store } = routerStore.options;
    const startdate = toState?.queryParams?.startdate || '';
    const enddate = toState?.queryParams?.enddate || '';
    await store.fetchStudentDetail(toState.params.id, startdate, enddate);
  },
  onExit: async (fromState, toState, routerStore) => {
    // clear student detail on exit
    const { store } = routerStore.options;
    store.clearStudentDetail();
  }
};
