import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import { Box } from '@mui/material';

import { useStore } from 'hooks';
import { ConfirmationModal } from '../../components';
import { ActivitiesTable, ActivitiesTableToolbar, StageTitleBar, StageTitle, StageHeader } from '../components';
import { useCurriculum } from '../useCurriculum';
import { useLearningGoals } from '../useLearningGoals';
import AddOrEditActivityModal from './components/AddOrEditActivityModal';
import AddLearningGoalModal from './components/AddLearningGoalModal';
import EditLearningGoalDialog from './components/EditLearningGoalDialog';
import Button from '../../components/Button';

const CurriculumLearningGoals = () => {
  const store = useStore();
  const { t } = useTranslation('curriculum');

  const { curriculum } = useCurriculum();
  const { learningGoalsWithActivities, learningGoalActivities } = useLearningGoals();

  const [activeLearningGoal, setActiveLearningGoal] = useState(null);
  const [activeActivity, setActiveActivity] = useState(null);

  const [isAddLearningGoalModalOpen, setIsAddLearningGoalModalOpen] = useState(false);
  const [isEditLearningGoalModalOpen, setIsEditLearningGoalModalOpen] = useState(false);
  const [isAddOrEditActivityModalVisible, setIsAddOrEditActivityModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const onAddGoal = () => {
    setIsAddLearningGoalModalOpen(true);
  };

  const onEditGoal = (goal) => {
    setActiveLearningGoal(goal);
    setIsEditLearningGoalModalOpen(true);
  };

  const onDeleteGoal = async (goal) => {
    setActiveLearningGoal(goal);
    setIsDeleteModalVisible(true);
  };

  const onCloseAddLearningGoalDialog = () => {
    setIsAddLearningGoalModalOpen(false);
  };

  const onSubmitAddLearningGoalDialog = () => {
    setIsAddLearningGoalModalOpen(false);
    store.refreshCurrentCurriculum();
  };

  const onDeleteGoalConfirm = async () => {
    store.startLoadCurriculum();
    await store.deleteCurriculumLearningGoal(curriculum, activeLearningGoal);
    store.refreshCurrentCurriculum();
    store.stopLoadCurriculum();
    setIsDeleteModalVisible(false);
  };

  const onAddActivity = (goal) => {
    setActiveLearningGoal(goal);
    setActiveActivity(null);
    setIsAddOrEditActivityModalVisible(true);
  };

  const onEditActivity = (goal, id) => {
    const activity = learningGoalActivities.find((a) => a.id === id);

    setActiveLearningGoal(goal);
    setActiveActivity(activity);
    setIsAddOrEditActivityModalVisible(true);
  };

  const onCloseAddOrEditActivityModal = () => {
    setIsAddOrEditActivityModalVisible(false);
  };

  const onSubmitAddOrEditActivityModal = () => {
    setIsAddOrEditActivityModalVisible(false);
    store.refreshCurrentCurriculum();
  };

  const onCloseEditLearningGoalDialog = () => {
    setIsEditLearningGoalModalOpen(false);
  };

  const onSubmitEditLearningGoalDialog = () => {
    setIsEditLearningGoalModalOpen(false);
    store.refreshCurrentCurriculum();
  };

  const onDeleteActivities = async (goal, ids) => {
    if (!ids) {
      return;
    }

    store.startLoadCurriculum();
    await store.deleteCurriculumLearningGoalActivities(goal, ids);
    store.refreshCurrentCurriculum();
    store.stopLoadCurriculum();
  };

  const activityColumns = [
    {
      id: 'attributes.name',
      numeric: false,
      disablePadding: true,
      disableSorting: false,
      label: t('learningGoals.activitiesLabel'),
      type: 'text',
      sx: {
        width: '40%'
      }
    },
    {
      id: 'attributes.explanation',
      numeric: false,
      disablePadding: true,
      disableSorting: false,
      label: t('learningGoals.explanationLabel'),
      type: 'text',
      sx: {
        width: '40%'
      }
    }
  ];

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
        <StageHeader title={t('learningGoals.title')} description={t('learningGoals.description')} />

        <StageTitleBar>
          <StageTitle title={t('learningGoals.title2')} />
          <Button tooltipText={t('learningGoals.addLearningGoalBtnTooltip')} onClick={onAddGoal}>
            <Icon icon="dashicons:plus" />
            {t('learningGoals.addLearningGoalBtnLabel')}
          </Button>
        </StageTitleBar>

        {learningGoalsWithActivities.map((goal) => (
          <Box sx={{ padding: '0.5rem', backgroundColor: '#ffffff', borderRadius: '0.75rem' }} key={goal.id}>
            <ActivitiesTableToolbar
              translationGroup="learningGoals"
              title={goal.attributes.name}
              addActivityBtnLabel={t('learningGoals.addActivityBtnLabel')}
              addActivityBtnTooltip={t('learningGoals.addActivityBtnTooltip')}
              editBtnLabel={t('learningGoals.editLearningGoalBtnLabel')}
              editBtnTooltip={t('learningGoals.editLearningGoalBtnTooltip')}
              onAddCallback={() => onAddActivity(goal)}
              onEditCallback={() => onEditGoal(goal)}
              onDeleteCallback={() => onDeleteGoal(goal)}
            />
            <ActivitiesTable
              translationGroup="learningGoals"
              activities={goal.activities}
              columns={activityColumns}
              onEditCallback={(id) => onEditActivity(goal, id)}
              onDeleteCallback={(ids) => onDeleteActivities(goal, ids)}
            />
          </Box>
        ))}
      </Box>

      <AddLearningGoalModal
        visible={isAddLearningGoalModalOpen}
        onCloseCallback={onCloseAddLearningGoalDialog}
        onSubmitCallback={onSubmitAddLearningGoalDialog}
      />

      {activeLearningGoal && (
        <EditLearningGoalDialog
          visible={isEditLearningGoalModalOpen}
          goal={activeLearningGoal}
          onCloseCallback={onCloseEditLearningGoalDialog}
          onSubmitCallback={onSubmitEditLearningGoalDialog}
        />
      )}

      <AddOrEditActivityModal
        visible={isAddOrEditActivityModalVisible}
        onCloseCallback={onCloseAddOrEditActivityModal}
        onSubmitCallback={onSubmitAddOrEditActivityModal}
        learningGoal={activeLearningGoal}
        activity={activeActivity}
      />

      <ConfirmationModal
        title={t('learningGoals.confirmDeleteLearningGoalTitle')}
        text={t('learningGoals.confirmDeleteLearningGoalText')}
        btnLabel={t('general.deleteBtnLabel')}
        isVisible={isDeleteModalVisible}
        onConfirm={onDeleteGoalConfirm}
        onCancel={() => setIsDeleteModalVisible(false)}
      />
    </>
  );
};
export default observer(CurriculumLearningGoals);
