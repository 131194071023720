import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TabsLayout from '../components/TabsLayout';

const LmsStudentsLayout = ({ children }) => {
  const { t } = useTranslation();

  const mapRouteNameToTitle = {
    LmsStudents: t('lms.courses.allCourses.title'),
    LmsStudentsOpen: t('lms.courses.coursesByStatus.open.title'),
    LmsStudentsCompleted: t('lms.courses.coursesByStatus.completed.title')
  };

  return <TabsLayout tabsRoutes={mapRouteNameToTitle}>{children}</TabsLayout>;
};

LmsStudentsLayout.propTypes = {
  children: PropTypes.node
};

export default LmsStudentsLayout;
