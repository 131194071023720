import React from 'react';
import MainLayout from 'pages/mainLayout/MainLayout';
import { Lazy } from 'components';
import LmsLayout from '../LmsLayout';
import LmsDashboardLayout from './LmsDashboardLayout';

const LmsDashboardOverview = React.lazy(() => import('./LmsDashboardOverview'));
const LmsDashboardProfessions = React.lazy(() => import('./LmsDashboardProfessions'));
const LmsDashboardProducts = React.lazy(() => import('./LmsDashboardProducts'));

const LmsDashboardViewMap = {
  LmsDashboardOverview: (
    <MainLayout title="lms.navbar.dashboard" subtitle="">
      <LmsLayout>
        <LmsDashboardLayout>
          <Lazy noLayout>
            <LmsDashboardOverview />
          </Lazy>
        </LmsDashboardLayout>
      </LmsLayout>
    </MainLayout>
  ),
  LmsDashboardProfessions: (
    <MainLayout title="lms.navbar.dashboard" subtitle="">
      <LmsLayout>
        <LmsDashboardLayout>
          <Lazy noLayout>
            <LmsDashboardProfessions />
          </Lazy>
        </LmsDashboardLayout>
      </LmsLayout>
    </MainLayout>
  ),
  LmsDashboardProducts: (
    <MainLayout title="lms.navbar.dashboard" subtitle="">
      <LmsLayout>
        <LmsDashboardLayout>
          <Lazy noLayout>
            <LmsDashboardProducts />
          </Lazy>
        </LmsDashboardLayout>
      </LmsLayout>
    </MainLayout>
  )
};

export default LmsDashboardViewMap;
