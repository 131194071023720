import AdminCourseLayout from 'pages/adminCourses/AdminCourseLayout';
import Dashboard from './Dashboard';

const adminDashboardViewMap = {
  adminDashboardCourses: (
    <AdminCourseLayout menu="draft" title="Dashboard">
      <Dashboard noPaper />
    </AdminCourseLayout>
  )
};

export default adminDashboardViewMap;
