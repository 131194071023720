import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Stack } from '@mui/material';
import { rawHTML } from 'helpers/Helpers';

const PollStats = ({ options, title }) => {
  const { t } = useTranslation('widget');
  const total = options.reduce((acc, opt) => acc + opt.count, 0);
  return (
    <Stack spacing={2}>
      <Typography variant="p" sx={{ fontWeight: 'bold' }}>
        {rawHTML(title)}
      </Typography>
      <Box mt={3}>
        {options.map((opt, i) => {
          const percentage = Math.round((opt.count * 100) / total);
          return (
            <Box key={i}>
              <Typography sx={{ fontSize: '18px', paddingTop: '0px' }} variant="body1">
                {opt.value}
              </Typography>
              <Progress percentage={percentage} />
              <Typography sx={{ fontSize: '17px' }} textAlign="end" variant="body1">
                {percentage}% ({opt.count} {t(`votes`, `Votes`)})
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Typography sx={{ fontSize: '20px', lineHeight: '50px' }} textAlign="center" variant="body1">
        {t(`total`, `Total`)}: {total}
      </Typography>
    </Stack>
  );
};

PollStats.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
};

export default PollStats;

const Progress = ({ percentage }) => {
  const [style, setStyle] = useState({ width: '1%' });
  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${percentage}%`
    };
    setStyle(newStyle);
  }, 1000);
  return (
    <Box sx={{ boxShadow: `1px 2px 10px #00000047` }} borderRadius={2} border={1} borderColor="disabled">
      <Box
        sx={{ transition: `1s ease 0.3s`, background: 'linear-gradient(to right, #006847, #289774)' }}
        className="progress-done"
        style={style}
        id="progressbar"
        borderRadius={2}
        bgcolor="success.main"
        height={20}
        width={`${percentage}%`}
      />
    </Box>
  );
};

Progress.propTypes = {
  percentage: PropTypes.number
};
