import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { lighten, useTheme } from '@mui/material/styles';

import { Button } from '../../../components';
import * as Types from '../../../types';
import Activities from './Activities';
import { useAccordion } from './useAccordion';

const EvaluationActivities = ({ goal, evaluation, onAddActivityCallback }) => {
  const { t } = useTranslation('curriculum');
  const theme = useTheme();

  const panelId = `evaluation-activities-${goal.id}`;
  const { hasState, toggleState } = useAccordion(panelId);

  const handleOpen = (e) => {
    e.preventDefault();
    toggleState(!hasState);
  };

  return (
    <Accordion square style={{ boxShadow: 'none', backgroundColor: lighten(theme.palette.primary.main, 0.95) }} expanded={!hasState}>
      <AccordionSummary
        sx={{ backgroundColor: lighten(theme.palette.primary.main, 0.85), height: '40px' }}
        expandIcon={
          <Button variant="inverted">
            <ExpandMoreIcon style={{ pointerEvents: 'none' }} />
          </Button>
        }
        aria-controls={`${panelId}-content`}
        id={`${panelId}-header`}
        onClick={handleOpen}
      >
        <Box
          sx={{
            display: 'flex',
            marginRight: '0.25rem',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>{t('evaluations.activitiesPaneTitle')}</Typography>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleState(true);
              onAddActivityCallback();
            }}
          >
            <Icon icon="ant-design:plus-outlined" />
            {t('evaluations.addActivityBtnLabel')}
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Activities evaluation={evaluation} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

EvaluationActivities.propTypes = {
  goal: Types.LearningGoal.isRequired,
  evaluation: Types.Evaluation.isRequired,
  onAddActivityCallback: PropTypes.func.isRequired
};

export default observer(EvaluationActivities);
