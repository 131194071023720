import React from 'react';
import MainLayout from 'pages/mainLayout/MainLayout';
import { Lazy } from 'components';

const CourseOverview = React.lazy(() => import('../course/CourseOverview'));

const SpecialAccessViewMap = {
  specialAccess: (
    <MainLayout title="courseoverview.specialAccess.title" subtitle="">
      <Lazy noLayout>
        <CourseOverview />
      </Lazy>
    </MainLayout>
  )
};

export default SpecialAccessViewMap;
