import { ThemeBrandTitle } from 'helpers/Helpers';
import { checkUserSignedIn, checkSubscription } from 'state/RoutesActions';

export const curriculumDashboardRoute = {
  name: 'curriculumDashboard',
  meta: { title: `%%curriculum.title%% | ${ThemeBrandTitle()}` },
  pattern: '/portfolio/dashboard/:type',
  beforeEnter: async (fromState, toState, routerStore) => {
    try {
      await checkUserSignedIn(fromState, toState, routerStore);
    } catch (e) {
      return e;
    }
    return checkSubscription(fromState, toState, routerStore, ['accountant', 'ewise accountant']);
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const offset = 0;
    const isArchive = toState.params.type === 'archive';
    try {
      const params = {
        offset,
        limit: 10,
        is_deleted: isArchive ? 1 : 0
      };
      await store.fetchCurriculums(params);
    } catch (e) {
      return e;
    }
    return null;
  }
};
