import { types as t } from 'mobx-state-tree';
import { Package } from './Package';

export const BaseProduct = t.model('BaseProduct', {
  nid: t.number,
  uuid: t.string,
  title: t.string,
  unique_name: t.string,
  packages: t.array(Package)
});
