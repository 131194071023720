export const defaultValues = {
  type: 'lesson',
  attributes: {
    title: '',
    minimum_score: 70,
    minimum_score_2nd_try: 0,
    max_attempts: 0,
    lesson_type: 'Cursus',
    free_flow: 'Normal',
    rotating_answers: false,
    '1answer_nofeedback': false,
    '1answer_visualfeedback': false
  },
  relationships: {
    lesson_content: {
      data: null
    }
  }
};

export const lessonTypes = [
  { name: 'Cursus', value: 'Cursus' },
  { name: 'Eindtoets', value: 'Eindtoets' },
  { name: 'Praktijktoets', value: 'Praktijktoets' },
  { name: 'Kennistoets', value: 'Kennistoets' },
  { name: 'Module', value: 'Module' },
  { name: 'Module 1', value: 'Module 1' },
  { name: 'Module 2', value: 'Module 2' },
  { name: 'Module 3', value: 'Module 3' },
  { name: 'Module 4', value: 'Module 4' },
  { name: 'Module 5', value: 'Module 5' },
  { name: 'Final module', value: 'Final module' },
  { name: 'Lesson', value: 'Lesson' }
];

export const freeFlow = [
  { name: 'Normal', value: 'Normal' },
  { name: 'Off', value: 'Off' }
];
