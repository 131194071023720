import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { Store } from 'state/ContextStore';
import { useScrollPosition } from 'hooks';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// renders a button that scrolls to the top of the page
const ScrollUpButton = () => {
  const store = useContext(Store);

  const defaultColor = '#ffa601';
  const defaultColorHover = '#d88204e8';

  const scrolling = useScrollPosition();

  const isScrolled = scrolling > 1100;

  const scrolltop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const saveScrollPosition = store.routerStore.routerState.routeName === 'courseOverview' && scrolling > 20 && store.loadingCourses;

  useEffect(() => {
    if (saveScrollPosition) {
      store.setScrollingPosition(scrolling);
    }
  }, [saveScrollPosition, scrolling, store]);

  return (
    <>
      {isScrolled && (
        <IconButton
          onClick={scrolltop}
          className="scroll_top"
          sx={{ backgroundColor: defaultColor, '&:hover': { background: defaultColorHover } }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      )}
    </>
  );
};

export default observer(ScrollUpButton);
