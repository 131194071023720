import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialTable from '@material-table/core';
import { Grid, Box, Paper } from '@mui/material';
// hook
import { convertIsoDate2Date } from '../EditCourse.helper';

const mapFields = {
  name: 'user.name',
  lastName: 'user.field_last_name',
  initials: 'user.field_initials',
  mail: 'user.mail',
  started: 'created'
};

const orderByCollection = [{ orderBy: 0, orderDirection: 'asc', sortOrder: 1 }];

async function getData(query, courseId) {
  const pageLimit = `page[limit]=${query.pageSize}`;
  const pageOffset = `page[offset]=${query.pageSize * query.page}`;
  const orderBy = query.orderByCollection.find((item) => item.sortOrder === 1);
  const sort = `${orderBy.orderDirection === 'asc' ? '-' : ''}${mapFields[orderBy.orderByField]}`;
  const filter = `filter[course.meta.drupal_internal__target_id][value]=${courseId}&filter[completed][value]=0&include=user`;
  const url = `jsonapi/course_result?${pageLimit}&${pageOffset}&sort=${sort}&${filter}`;
  const { data } = await window.authedClient.get(url);

  //
  const tableMappedData =
    data?.data?.map((result) => {
      const { attributes, relationships } = result;
      const { name: courseName, created } = attributes;
      // user
      const { id } = relationships.user.data;
      // if a user is deleted the id in relationship user.data.id returns the value: 'missing'
      if (id === 'missing') return { lastName: 'userDeleted', initials: 'missing', mail: 'missing', courseName, started: 'missing' };
      const relUser = data.included.find((item) => item.id === id);
      // if user is not found in included data return the following object
      if (!relUser) return { name: 'test', lastName: 'userDeleted', initials: 'missing', mail: 'missing', courseName, started: 'missing' };
      const { name = '', mail = '', field_initials: initials = '', field_last_name: lastName = '' } = relUser.attributes;
      return {
        id: result.attributes.drupal_internal__id,
        name,
        lastName,
        initials,
        mail,
        courseName,
        started: convertIsoDate2Date(created, 'DD-MM-YYYY')
      };
    }) || [];

  const tableData = tableMappedData ? tableMappedData.filter((item) => item.lastName !== 'userDeleted') : [];

  return {
    data: tableData,
    page: query.page,
    totalCount: data.meta.count
  };
}

const LessonTabStudents = () => {
  const [usersCount, setUsersCount] = useState(0);
  const { t } = useTranslation('editCourseForm');
  const store = useContext(Store);
  const courseId = store.courseEdit.currentCourse.attributes.drupal_internal__nid;

  const tableOptions = {
    maxColumnSort: 1,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 100],
    emptyRowsWhenPaging: false,
    search: true,
    showTitle: false,
    header: true,
    toolbar: false,
    dense: true,
    thirdSortClick: false,
    rowStyle: { fontSize: '14px', '&:hover': { background: 'silver' } },
    defaultOrderByCollection: orderByCollection
  };

  const tableColumns = [
    { title: 'Username', field: 'name' },
    { title: t('tabStudents.lastName'), field: 'lastName' },
    { title: t('tabStudents.initials'), field: 'initials' },
    { title: t('tabStudents.email'), field: 'mail' },
    { title: t('tabStudents.started'), field: 'started' }
  ];

  return (
    <Box className="box-left" sx={{ flexGrow: 1 }}>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <Box component="h3">Studenten bezig met deze cursus: {usersCount}</Box>
        </Grid>
        <Grid item sx={sxTableCourseUsersInprogress}>
          <MaterialTable
            title="test"
            data={(query) =>
              new Promise((resolve, reject) => {
                getData(query, courseId)
                  .then((result) => {
                    setUsersCount(result.totalCount);
                    resolve(result);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            options={tableOptions}
            columns={tableColumns}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />
            }}
            localization={{ body: { emptyDataSourceMessage: 'No students found' } }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(LessonTabStudents);

const sxTableCourseUsersInprogress = {
  margin: '0px',
  'tbody td': { padding: '8px' },
  'tbody tr.MuiTableRow-root:hover': { background: '#c0c0c04d' }
};
