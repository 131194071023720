import { Box, Button, Typography, Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Assuming you use react-i18next for translations
import { useStore } from 'hooks'; // Assuming useStore is a custom hook for accessing store data
import { convertUrlEncodedString, convertUrlDecodedString, ThemeBrandTitle } from 'helpers/Helpers'; // Assuming convertUrlDecodedString is a helper function
import { ReactComponent as AISearchIcon } from 'pages/search/components/Grid/CourseTable/NoResults/assets/icons/AISearch.svg';
import { useTheme } from '@emotion/react';

const NoResultsMessage = () => {
  const { t } = useTranslation(); // t function for translations
  const store = useStore(); // Assuming useStore hook provides access to store data
  const searchAttemptNr = store.searchNextAttempt;
  const brandTitle = ThemeBrandTitle();
  const { u } = brandTitle !== 'E-WISE' ? { u: 'u' } : { u: 'je' };
  const theme = useTheme();
  const sxAISearchIcon = {
    width: '140px',
    height: '140px',
    borderRadius: '0px',
    fill: theme.palette.primary.main
  };
  const renderMessage = () => {
    switch (searchAttemptNr) {
      case 1:
        return t(
          'searchoverview.noCoursesMessage1',
          'We can reverse the order of your words, perform another search, and attempt to match each variation.'
        );
      case 2:
        return t('searchoverview.noCoursesMessage2', 'We can attempt to search for a word based on your input.');
      case 3:
        return t('searchoverview.noCoursesMessage3', { u });
      default:
        return '';
    }
  };

  const handleSubmit = () => {
    store.routerStore.goTo('searchOverview', {
      params: {
        baseProductId: store.baseProduct?.nid.toString(),
        searchParam: convertUrlEncodedString(store.searchParam),
        searchAttempt: searchAttemptNr.toString()
      }
    });
  };

  if (searchAttemptNr === 3) {
    return (
      <Box textAlign="center" sx={{ marginTop: '0' }} mt={4}>
        <Alert severity="info" variant="filled" color="info" sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <AlertTitle
            sx={{
              margin: '0px',
              '&::first-letter': {
                textTransform: 'capitalize'
              }
            }}
          >
            {renderMessage()}
            <br />
            {t('searchoverview.noCoursesMessage')}
          </AlertTitle>
        </Alert>
      </Box>
    );
  }

  return (
    <Box textAlign="center" sx={{ marginTop: searchAttemptNr === 3 ? 'px' : '90px' }} mt={4}>
      {searchAttemptNr > 0 && searchAttemptNr < 3 && (
        <>
          <AISearchIcon style={sxAISearchIcon} />
          <Typography variant="h5" gutterBottom>
            {t('searchoverview.noCourses', 'No courses found based on your search.')}
            <br />
            &quot;{convertUrlDecodedString(store.searchParam)}&quot;
          </Typography>
          <Box sx={{ backgroundColor: '#fff', marginTop: '16px', padding: '16px' }}>
            <Typography variant="body1" gutterBottom>
              {renderMessage()}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: '20px',
              marginTop: '14px'
            }}
            onClick={handleSubmit}
          >
            {t('searchoverview.searchAgain', 'Search')}
          </Button>
        </>
      )}
    </Box>
  );
};

export default NoResultsMessage;
