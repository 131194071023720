import { useCallback, useRef, useState } from 'react';
import { useStore } from '../../../../../../hooks';

export const useFavourites = () => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState();

  const storeFavourite = useRef();

  const toggleFavorites = useCallback(
    async (courseId, courseNid) => {
      const removeFavorite = store.favoriteIds.filter((course) => course !== courseId);

      const removedFavorite = removeFavorite.map((course) => ({
        type: 'course',
        id: course
      }));

      const remodelData = store.favoriteIds.map((course) => ({
        type: 'course',
        id: course
      }));

      setIsLoading(true);

      if (!store.favorites.isCourseFavorite(courseNid)) {
        storeFavourite.current = await store.patchData(store.favorites, {
          id: store.favorites.uuid,
          type: 'course_favorites',
          relationships: {
            field_favorite_courses: {
              data: [
                ...remodelData,
                {
                  type: 'course',
                  id: courseId
                }
              ]
            }
          }
        });

        if (storeFavourite.current) {
          setIsLoading(false);
        }
      } else {
        storeFavourite.current = await store.patchData(store.favorites, {
          id: store.favorites.uuid,
          type: 'course_favorites',
          relationships: {
            field_favorite_courses: {
              data: removedFavorite
            }
          }
        });

        if (storeFavourite.current) {
          setIsLoading(false);
        }
      }
      return storeFavourite;
    },
    [store]
  );

  const handleFavorite = useCallback(
    (courseId, courseNid) => {
      setIsLoading(true);
      if (!store.favorites) {
        store.postData({
          type: 'course_favorites',
          attributes: {
            title: 'course_favorites'
          },
          relationships: {
            field_favorite_courses: {
              data: [
                {
                  id: courseId,
                  type: 'course'
                }
              ]
            }
          }
        });
      } else {
        toggleFavorites(courseId, courseNid);
      }
    },
    [store, toggleFavorites]
  );

  return { handleFavorite, isLoading };
};
