import { useMemo } from 'react';
import { useStore } from 'hooks';
import { evaluationIsComplete } from '../utils';

export const useEvaluations = () => {
  const store = useStore();

  const goals = useMemo(() => store.curriculumLearningGoals ?? [], [store.curriculumLearningGoals]);
  const evaluations = useMemo(() => store.curriculumEvaluations ?? [], [store.curriculumEvaluations]);
  const evaluationCourses = store.curriculumEvaluationCourses ?? [];

  const canFinalize = useMemo(() => {
    if (goals.length <= 0 || evaluations.length <= 0) {
      return false;
    }

    // check if all goals have an evaluation
    const goalsWithEvaluation = goals.filter(
      (goal) => goal.relationships?.evaluations?.data && goal.relationships?.evaluations?.data.length > 0
    );
    if (goalsWithEvaluation.length !== goals.length) {
      return false;
    }

    // check if all evaluations have a name, evaluation and at least one course or activity
    const evaluationsThatAreComplete = evaluations.filter((evaluation) => evaluationIsComplete(evaluation));
    if (evaluationsThatAreComplete.length !== evaluations.length) {
      return false;
    }

    // all checks have passed, user can finalize portfolio
    return true;
  }, [evaluations, goals]);

  const evaluationsWithCourses = evaluations.map((evaluation) => {
    const courseIds = evaluation.relationships?.courses?.data.map((c) => c.id);
    const courses = evaluationCourses?.filter((c) => courseIds.includes(c.id));
    return {
      ...evaluation,
      courses
    };
  });

  // courses
  const courseResults = store.curriculumAvailableCourseResults.length > 0 ? store.curriculumAvailableCourseResults : [];

  return {
    evaluations,
    evaluationCourses,
    courseResults,
    evaluationsWithCourses,
    canFinalize
  };
};
