import { createContext, useCallback, useMemo, useState } from 'react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import { getThemeByName } from 'theme';
import { getLabelNameFromDomain, getThemeName } from 'helpers/Helpers';
import merge from 'deepmerge';

export const CustomThemeContext = createContext(getThemeByName('cmeTheme'));

export const CustomThemeProvider = ({ children }) => {
  const [globalTheme, setGlobalTheme] = useState(getThemeByName(getThemeName()));
  const [customLogo, setCustomLogo] = useState();
  const labelLogo = `${process.env.PUBLIC_URL}/${getLabelNameFromDomain()}/assets/images/logo.svg`;

  const setThemeName = (name) => {
    setGlobalTheme(getThemeByName(name));
  };

  const setTheme = useCallback(
    (theme) => {
      if (!theme || !globalTheme) {
        return;
      }

      setGlobalTheme(merge(globalTheme, theme));
    },
    [globalTheme]
  );

  const value = useMemo(
    () => ({ setThemeName, setTheme, setCustomLogo, customLogo, labelLogo, globalTheme }),
    [setTheme, customLogo, labelLogo, globalTheme]
  );

  return (
    <CustomThemeContext.Provider value={value}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={globalTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
};

CustomThemeProvider.propTypes = {
  children: PropTypes.object.isRequired
};
