/* Core */
import { Controller } from 'react-hook-form';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { TextField, Chip, Autocomplete, FormControl, FormHelperText } from '@mui/material';

/*
In summary, the MyAutocomplete component is a controlled multiple selection autocomplete input field. 
It's designed for use in forms and leverages the react-hook-form library for controlled form handling. 
Users can select multiple options, including custom entries not present in the provided options array. 
The selected options are displayed as colorful Chip components within the input field. 
The component allows for flexible and customized autocomplete functionality in a React application.
*/
const MyAutocomplete = (props) => {
  const { control, name, label, helperText, ...otherProps } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { onChange, ...field } }) => (
        <FormControl style={{ width: '100%' }} variant="outlined">
          <Autocomplete
            {...field}
            multiple
            id="tags-filled"
            options={[]}
            onChange={(_, data) => onChange(data)}
            freeSolo
            {...otherProps}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  color={otherProps.color || 'info'}
                  size="small"
                  variant="filled"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => <TextField {...params} {...field} label={label} placeholder="Keywords" />}
          />
          <FormHelperText error>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

MyAutocomplete.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string
};

export default observer(MyAutocomplete);
