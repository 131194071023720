import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import { lighten, useTheme } from '@mui/material/styles';
import { Button } from '../../../components';
import { ActivitiesTable } from '../../components';
import { useAccordion } from './useAccordion';

const PlannedActivities = ({ goal }) => {
  const theme = useTheme();
  const { t } = useTranslation('curriculum');

  const panelId = `evaluation-planned-activities-${goal.id}`;
  const { hasState, toggleState } = useAccordion(panelId);

  const handleOpen = (e) => {
    e.preventDefault();
    toggleState(!hasState);
  };

  const activityColumns = [
    {
      id: 'attributes.name',
      numeric: false,
      disablePadding: true,
      disableSorting: false,
      label: t('learningGoals.activitiesLabel'),
      type: 'text',
      sx: {
        width: '40%'
      }
    },
    {
      id: 'attributes.explanation',
      numeric: false,
      disablePadding: true,
      disableSorting: false,
      label: t('learningGoals.explanationLabel'),
      type: 'text',
      sx: {
        width: '40%'
      }
    }
  ];

  return (
    <Accordion square sx={{ boxShadow: 'none' }} expanded={hasState}>
      <AccordionSummary
        sx={{
          borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.75)}`,
          backgroundColor: lighten(theme.palette.primary.main, 0.85),
          height: '40px'
        }}
        expandIcon={
          <Button variant="inverted">
            <ExpandMoreIcon style={{ pointerEvents: 'none' }} />
          </Button>
        }
        aria-controls={`${panelId}-content`}
        id={`${panelId}-header`}
        onClick={handleOpen}
      >
        <Box
          sx={{
            display: 'flex',
            marginRight: '0.25rem',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>{t('evaluations.plannedActivitiesTitle')}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <ActivitiesTable translationGroup="learningGoals" activities={goal.activities} columns={activityColumns} showActions={false} />
      </AccordionDetails>
    </Accordion>
  );
};
PlannedActivities.propTypes = {
  goal: PropTypes.object.isRequired
};
export default observer(PlannedActivities);
