import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import { rawHTML } from 'helpers/Helpers';
/* MUI */
import { Alert, AlertTitle, Box } from '@mui/material';

const NonTrialCourseAlert = () => {
  const { t } = useTranslation();
  const store = useStore();
  const { product } = store;

  const subscriptionText = product?.trial_detail_text ? rawHTML(product?.trial_detail_text) : t('trials.courseIntro.subscriptionText');

  return (
    <Box sx={{ marginTop: '10px', [` p`]: { margin: '0px' } }}>
      <Alert severity="warning">
        <AlertTitle>{t('trials.courseIntro.trialTitle')}</AlertTitle>
        {subscriptionText}
      </Alert>
    </Box>
  );
};

export default observer(NonTrialCourseAlert);
