import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { forwardRef } from 'react';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#F8F8F8',
    paddingLeft: 20,
    borderRadius: 10,
    width: '100%',
    border: 'none',
    height: '48px',
    boxShadow: '0 15px 30px rgba(0,0,0,0)',
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
    alignItems: 'center',
    textTransform: 'initial',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
    color: '#2B3674',
    cursor: 'pointer',
    transition: 'all ease-in-out 0.15s',
    '&:hover:not([disabled])': { boxShadow: '0 8px 15px rgba(0,0,0,0.15)', opacity: 1 },
    '&:disabled': {
      backgroundColor: '#A9A9A9',
      color: 'white',
      cursor: 'not-allowed'
    }
  },
  isUserAttendingEventType: {
    opacity: 0.5,

    '&:disabled': {
      opacity: 1
    },

    '&:hover:not([disabled])': { boxShadow: '0 8px 15px rgba(0,0,0,0.0)', opacity: 0.5, cursor: 'not-allowed' }
  },
  isUserAttending: {
    color: 'white',
    fontWeight: 800,
    backgroundColor: '#006848',
    opacity: 1,
    '&:hover:not([disabled])': { backgroundColor: '#FF3B30' }
  }
});

export const EventButton = forwardRef(
  ({ isUserAttending, isEnrollmentClosed, isUserAttendingEventType, noSeatsAvailable, onClick, children, ...props }, ref) => {
    const classes = useStyles();

    const className = [
      classes.button,
      isUserAttendingEventType && !isUserAttending ? classes.isUserAttendingEventType : false,
      isUserAttending ? classes.isUserAttending : false
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <button
        type="button"
        {...props}
        className={className}
        disabled={isEnrollmentClosed || (noSeatsAvailable && !isUserAttending)}
        onClick={onClick}
        ref={ref}
      >
        {children}
        {isUserAttending && isEnrollmentClosed ? <CheckIcon /> : null}
      </button>
    );
  }
);

EventButton.propTypes = {
  isUserAttending: PropTypes.bool,
  isUserAttendingEventType: PropTypes.bool,
  isEnrollmentClosed: PropTypes.bool,
  noSeatsAvailable: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any
};
