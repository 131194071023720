import { createRouterState } from 'mobx-state-router';
import { ThemeBrandTitle } from 'helpers/Helpers';
import { getEwapi } from 'state/RoutesActions';

/**
 * CourseOverviewRoute
 * @type {{name: string, pattern: string, title: string, beforeEnter: (function(*, *, *): Promise<void>)}}
 */
export const courseOverviewRoute = {
  name: 'courseOverview',
  pattern: '/:productName/:product/:packageId/cursusoverzicht',
  meta: { title: `%%courseoverview.title%% | ${ThemeBrandTitle()}` },
  // eslint-disable-next-line consistent-return
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const selPackageId = store.selectedPackageId.toString();
    const fromPackageId = fromState.params.packageId === undefined ? selPackageId : fromState.params.packageId.toString();
    const toPackageId = toState.params.packageId.toString();
    // Empty the search params
    store.clearSearchParam();
    // Check if the user is LTI and trying to reach this page
    if (store.getLtiSession() === 1 || store.getLtiSession() === '1') {
      return createRouterState('errorPage');
    }
    //
    if (toPackageId === '0' || selPackageId !== toPackageId || toPackageId !== fromPackageId || fromState.routeName !== toState.routeName) {
      const response = await getEwapi(fromState, toState, routerStore);
      if (response?.data?.error) return createRouterState('home');
    }
  }
};
