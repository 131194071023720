/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { urlify } from 'state/Utils';
import { useTheme } from '@emotion/react';
/* MUI */
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Box, IconButton, Avatar, Tooltip } from '@mui/material';
/* ICONS */
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
/* CUSTOM components */
import LoadingContent from '../loading/LoadingContent';
import { isDisabled, isActive, setColorMenuIcon, isCurrentRoute, isSelected, sxPackageList } from './menuList.helper';
/* SCSS */
import '../../scss/MenuList.scss';
// HOOKS
import useEwapi from '../../hooks/useEwapi';

/*
In summary, the MenuList component provides dynamic navigation for packages and their related submenus. 
Users can filter, search, and interact with various submenus, and the component ensures a visually engaging and functional menu system. 
It's part of a larger application that manages packages and their associated actions.
*/
const MenuList = () => {
  const store = useContext(Store);
  const { isMarketing } = store;
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();
  const { params, routeName } = store.routerStore.routerState;
  const [open, setOpen] = useState(params?.packageNid);
  // Hook fetch package list
  const url = `editor-package-list/${0}/${store.label}/json`;
  const { response, loading, error } = useEwapi(url, 'packages');

  // useEffect change route
  useEffect(() => {
    setOpen(params?.packageNid);
  }, [params?.packageNid]);

  const prefPackage = 1;
  const defaultSubMenus = [
    { id: 1, name: 'Dashboard', class: 'draft', icon: <AdminPanelSettingsIcon />, routeName: 'adminDashboardCourses' },
    { id: 2, name: 'Online', class: 'online', icon: <CheckIcon />, routeName: 'adminOnlineCourses' },
    { id: 3, name: 'Offline', class: 'offline', icon: <CloudOffIcon />, routeName: 'adminOfflineCourses' },
    { id: 4, name: 'Edit', class: 'offline', icon: <EditIcon />, routeName: 'editCourse' }
  ];
  const marketingSubMenus = [
    { id: 2, name: 'Online', class: 'online', icon: <CheckIcon />, routeName: 'adminOnlineCourses' },
    { id: 3, name: 'Offline', class: 'offline', icon: <CloudOffIcon />, routeName: 'adminOfflineCourses' }
  ];

  const subMenus = isMarketing ? marketingSubMenus : defaultSubMenus;
  // EVENT HANDLERS
  const handleClick = (item) => setOpen(item.package_id.toString());
  //
  const handleMenuSelect = (_package, subMenu) => () => {
    store.courseEdit.setStoreValue('currentPackage', _package);
    const routerParams = {
      params: {
        package: urlify(_package.package_title.toLowerCase()),
        packageNid: _package.package_id.toString(),
        containerNid: _package.course_container_id.toString()
      }
    };
    store.routerStore.goTo(subMenu.routeName, routerParams);
  };

  // HOOK LOADING / ERROR
  if (loading) return <LoadingContent />;
  // ON ERROR
  if (error) return <p>error...</p>;
  //
  if (response?.data) {
    store.courseEdit.setStoreValue('packageMenuList', response?.data?.packages);
  }
  const menuListItems = response?.data?.packages.sort((a, b) => a.package_title.localeCompare(b.package_title));
  const selectedPackage = menuListItems?.filter((item) => item.package_id.toString() === params?.packageNid);
  const filteredPackageData = response.data.packages.filter(
    (item) => item.preferred_package === prefPackage && !item.package_title.includes('not acc') && !item.package_title.includes('niet ge')
  );
  const trialCourses = response.data.packages.filter((item) => item.package_title.includes('proef'));
  //
  const showList = isMarketing ? trialCourses : [...new Set([...selectedPackage, ...filteredPackageData])];

  return (
    <Box sx={sxPackageList(theme)} className="menu_list__package">
      <Box className="menu_list__filter" sx={{ padding: '0px 15px', display: 'flex', justifyContent: 'space-between' }}>
        <Box className="package_menu__title" sx={{ color: 'white', display: 'flex', alignItems: 'center' }} component="span">
          {t('packagesMenu.preferredPackages')}
        </Box>
        <Tooltip title={t('packagesMenu.tooltipShowMore')} placement="bottom" arrow>
          <IconButton
            onClick={() => store.courseEdit.setStoreValue('dialogMenuList', !store.courseEdit.dialogmenulist)}
            aria-label="show"
            size="small"
          >
            <PlaylistAddIcon sx={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <List dense component="nav" aria-labelledby="nested-list-subheader" className="menu-list-root">
          {menuListItems &&
            showList.map((item) => (
              <Box
                className={`package__list ${isSelected(item, open)} ${isCurrentRoute(item, params)}`}
                key={item.package_id}
                sx={{ padding: '4px 0px', borderBottom: `1px solid #dee6f6` }}
              >
                <ListItemButton key={item.package_id} onClick={() => handleClick(item)}>
                  <ListItemIcon>
                    <Avatar
                      sx={{
                        fontSize: '17px',
                        background: `${setColorMenuIcon(item, params, theme)[0]}`,
                        color: `${setColorMenuIcon(item, params, theme)[1]}`
                      }}
                    >
                      {item.package_title.substring(0, 2).toUpperCase()}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText className="list_header_text" sx={sxListItemText} primary={`${item.package_title}`} />
                  {open === item.course_container_id.toString() ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open === item.package_id.toString()} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ marginLeft: '15px', marginRight: '15px' }}>
                    {subMenus.map((_subMenu) => (
                      <ListItemButton
                        disabled={isDisabled(item, _subMenu, routeName)}
                        key={_subMenu.id}
                        sx={{
                          backgroundColor: `${isActive(item, _subMenu, params, routeName) ? theme.palette.primary.main : 'transparent'} `,
                          color: `${isActive(item, _subMenu, params, routeName) ? 'white' : theme.palette.primary.light} `,
                          '&.subMenu_true:hover': { backgroundColor: '#607d8bcf' }
                        }}
                        button="true"
                        className={`list-item-nested package__list_submenu subMenu_${isActive(item, _subMenu, params, routeName)}`}
                        onClick={handleMenuSelect(item, _subMenu)}
                      >
                        <Tooltip title={_subMenu.name} placement="right" arrow>
                          <ListItemIcon sx={{ color: `${isActive(item, _subMenu, params, routeName) ? 'white' : '#ffffff73'} ` }}>
                            {_subMenu.icon}
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={_subMenu.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Box>
            ))}
        </List>
      </Box>
    </Box>
  );
};

export default observer(MenuList);

const sxListItemText = {
  whiteSpace: 'break-spaces',
  fontWeight: '600',
  fontSize: '15px',
  '.MuiListItemText-primary': { fontSize: '15px', fontWeight: '500', color: 'white' }
};
