/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
// MUI
import { Grid, Box, Divider, Typography, FormControlLabel, Switch, Collapse, FormControl } from '@mui/material';
import { rawHTML, isValidPodcast } from 'helpers/Helpers';
/* Custom RHF components */
import { MyTextField, Editor } from 'components/form/RHF';
// eslint-disable-next-line import/no-extraneous-dependencies
import VideoPlayer from 'pages/adminCourses/components/VideoPlayer';
import AutocompleteTags2 from 'pages/adminCourses/components/AutocompleteTags2';

/*
Overall, this component serves as an extensive form for editing various attributes and details of a lesson container within a course,
including title, extra label, information, goals, authors, reviewers, podcasts, video, and sharing information.
The control prop is used to manage form controls, and translations are provided for various labels and messages.
*/
const LessonContainerEditForm = ({ control, isDisabled, ...restProps }) => {
  const { t } = useTranslation('editCourseForm');
  const { errors, setError, clearErrors, setValue, getValues } = restProps;
  //
  const [showPodcast, setShowPodcast] = useState(!!getValues('lessonContainer.attributes.field_podcast_html'));
  const watchPodcast = useWatch({ control, name: 'lessonContainer.attributes.field_podcast_html' });
  //
  const handleShowPodcast = () => {
    setShowPodcast(!showPodcast);
  };

  const sxQuillEditor = {
    ' .ql-toolbar.ql-snow': { borderRadius: '4px 4px 0 0' },
    ' .ql-container.ql-snow': { borderRadius: '0 0 4px 4px' }
  };

  useEffect(() => {
    const setRequired = { types: { required: 'This is required' } };
    if (watchPodcast && !isValidPodcast(watchPodcast)) {
      setError('lessonContainer.attributes.field_podcast_html', setRequired);
    }
    if (!watchPodcast || (watchPodcast && isValidPodcast(watchPodcast))) {
      clearErrors('lessonContainer.attributes.field_podcast_html');
    }
  }, [setError, errors, watchPodcast, clearErrors]);

  return (
    <Box className="box-left" sx={{ flexGrow: 1, ...sxQuillEditor }}>
      <Grid container direction="column" spacing={0}>
        {/* start grid */}
        <FormControl disabled>
          <Grid item sx={{ margin: '0px' }}>
            <MyTextField
              disabled={isDisabled('course.attributes.title')}
              autoFocus
              margin="normal"
              name="course.attributes.title"
              label={t('lessonEditForm.title')}
              type="text"
              fullWidth
              control={control}
              error={!!errors?.course?.attributes?.title}
              helperText={errors?.course?.attributes?.title?.message}
            />
          </Grid>
          <Grid item sx={{ margin: '0px', '.MuiFormControl-root': { marginTop: '8px' } }}>
            <MyTextField
              disabled={isDisabled('lessonContainer.attributes.field_extra_label')}
              margin="normal"
              name="lessonContainer.attributes.field_extra_label"
              label={t('lessonEditForm.extraLabel')}
              type="text"
              fullWidth
              control={control}
            />
          </Grid>
        </FormControl>
        <Grid item>
          <Box className="container-editor">
            <Box>
              <Editor
                // disabled={linked}
                disabled={isDisabled('lessonContainer')}
                label={t('lessonEditForm.information')}
                field={{ name: 'lessonContainer.attributes.field_information' }}
                form={{ setValue, control }}
                error={!!errors?.lessonContainer?.attributes?.field_information}
                helperText={errors?.lessonContainer?.attributes?.field_information?.message}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box className="container-editor">
            <Box className="editor">
              <Editor
                disabled={isDisabled('lessonContainer')}
                label={t('lessonEditForm.goals')}
                field={{ name: 'lessonContainer.attributes.field_goals' }}
                form={{ setValue, control }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <FormControlLabel
            sx={{ color: '#607D8B' }}
            onClick={handleShowPodcast}
            value="start"
            control={<Switch color="warning" checked={showPodcast} />}
            label={t('lessonEditForm.showPodcast')}
            labelPlacement="start"
          />
          <Collapse in={showPodcast} timeout="auto" unmountOnExit>
            <Box>
              <MyTextField
                disabled={isDisabled('lessonContainer')}
                margin="normal"
                name="lessonContainer.attributes.field_podcast_html"
                label={t('lessonEditForm.podcastHtml', 'Podcast HTML')}
                type="text"
                fullWidth
                multiline
                rows={7}
                control={control}
                error={!!watchPodcast && !isValidPodcast(watchPodcast)}
                helperText={errors?.lessonContainer?.attributes?.field_podcast_html?.message}
              />
              <Box sx={{ padding: '10px' }}>
                {watchPodcast && !isValidPodcast(watchPodcast) && (
                  <Typography color="error"> Geen geldige html voor een podcast</Typography>
                )}
                {watchPodcast && isValidPodcast(watchPodcast) && (
                  <Box>
                    <p>{t('lessonEditForm.podcastPreview', 'Podcast preview')}</p>
                    {rawHTML(watchPodcast)}
                  </Box>
                )}
              </Box>
            </Box>
          </Collapse>
        </Grid>
        {/* <Grid item>

        </Grid> */}
        <Grid item xs={6}>
          <AutocompleteTags2
            disabled={isDisabled('lessonContainer')}
            name="lessonContainer.relationships.field_authors"
            node="author"
            label={t('lessonEditForm.authors')}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteTags2
            disabled={isDisabled('lessonContainer')}
            helperText="type minimal 3 characters to start the search"
            name="lessonContainer.relationships.field_reviewers"
            node="author"
            label={t('lessonEditForm.reviewers')}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <VideoPlayer
            disabled={isDisabled('lessonContainer')}
            control={control}
            name="lessonContainer.attributes.field_preview_video"
            label={t('lessonEditForm.video')}
            helperText={t('lessonEditForm.videoHelperText')}
            getValue={getValues}
          />
        </Grid>
        <Grid id="dependentCourse" item xs={6}>
          <Divider>{t('lessonEditForm.dependentCourses')}</Divider>
          <AutocompleteTags2
            disabled={isDisabled('lessonContainer')}
            helperText="type minimal 3 characters to start the search"
            name="lessonContainer.relationships.dependent_courses"
            node="course"
            multiSelect={false}
            includeNid
            label={t('lessonEditForm.dependentCourses')}
            control={control}
          />
        </Grid>
        {/* end grid */}
      </Grid>
    </Box>
  );
};

LessonContainerEditForm.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  isDisabled: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func
};

export default observer(LessonContainerEditForm);
