import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TabsLayout from '../components/TabsLayout';

const LmsCoursesLayout = ({ children }) => {
  const { t } = useTranslation();

  const mapRouteNameToTitle = {
    LmsCourses: t('lms.courses.allCourses.title'),
    LmsCoursesCompleted: t('lms.courses.coursesByStatus.completed.title'),
    LmsCoursesOpenList: t('lms.courses.coursesByStatus.open.title')
  };

  return <TabsLayout tabsRoutes={mapRouteNameToTitle}>{children}</TabsLayout>;
};

LmsCoursesLayout.propTypes = {
  children: PropTypes.node
};

export default LmsCoursesLayout;
