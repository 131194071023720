import * as logger from 'loglevel';
import { ThemeBrandTitle } from 'helpers/Helpers';
// HOME ROUTE (DEFAULT ENTRY ROUTE)
import { homeRoute } from 'pages/home/HomeRoute';
// COURSE
import { CourseIntroductionRoute, defCourseIntroductionRoute } from 'pages/course/CourseIntroductionViewRoute';
import { courseOverviewRoute } from 'pages/course/CourseOverviewRoute';
import { defCourseOverviewRoute } from 'pages/course/DefCourseOverviewRoute';
import { teachersOverviewRoute, teacherDetailRoute } from 'pages/teachersOverview';
// SPECIAL ACCESS
import {
  specialAccessRoute,
  SpecialAccessCourseIntroductionRoute,
  specialAccessLessonPlayRoute
} from 'pages/specialAccess/SpecialAccessRoute';
// ADMIN COURSES
import {
  onlineCourses,
  offlineCourses,
  dashboard,
  editCourse,
  defEditCourse,
  viewCourses,
  courseSearchBar,
  adminCourseLessonPlay
} from 'pages/adminCourses';
// PROFILE
import { subscriptions, settings, profile, certificates } from 'pages/account';
// LOGIN
import { loginRoute, switchBackRoute } from 'pages/login/loginRoute/LoginRoute';
// LESSON PLAY
import { lessonPlayRoutes, defLessonPlayRoutes } from 'pages/play/lessonPlayRoutes/LessonPlayRoute';
import { evaluation, feedback, terms } from 'pages/play';
// ASSISTANT
import { assistantManagementRoute } from 'pages/assistentManagment/assistantManagementRoute';
// LMS
import {
  lmsDashboardRoute,
  lmsStudentsRoute,
  lmsStudentDetailRoute,
  lmsCoursesRoute,
  lmsCourseDetailRoute,
  lmsOrganisationsRoute
} from 'pages/lms';
// ERROR PAGES
import { errorPageRoute } from 'pages/misc/miscRoutes/ErrorPageRoute';
import { notFoundPageRoute } from 'pages/misc/miscRoutes/NotFoundPageRoute';
// VTB
import { vtbRoute } from 'pages/vtbDashboard/vtbRoutes/VtbRoute';
import { attendanceSheetVtbRoute } from 'pages/vtbDashboard/vtbRoutes/AttendanceSheetVtbRoute';
// Curriculum
import { curriculumDashboardRoute } from 'pages/curriculum/curriculumDashboard/curriculumDashboardRoute';
import { curriculumStagesRoute } from 'pages/curriculum/curriculumStages/curriculumStagesRoute';
// OTHER PAGES
import { faq, faq2, faq3, article } from 'pages/faq';
import { colophonRoute } from 'pages/colophon/colophonRoutes/ColophonRoute';
// E-WISE SELECT PRODUCT
import { selectProductRoute } from 'pages/ewiseUserSelectProduct/SelectProductRoute';
// SEARCH
import { searchOverviewRoute } from 'pages/search/SearchOverviewRoute';

const log = logger.getLogger('Routes');
const BrandTitle = ThemeBrandTitle();
log.setDefaultLevel('info');

// Be aware: Sequence is important! Routes are matched from top to bottom.
// Make sure they are sequenced specific to general.
export const routes = [
  // HOME
  { ...homeRoute },
  {
    name: 'gotoadmin',
    title: `Admin panel | ${BrandTitle}`,
    pattern: '/go-to-admin'
  },
  // COURSE
  { ...courseOverviewRoute },
  { ...defCourseOverviewRoute },
  { ...defCourseIntroductionRoute },
  { ...CourseIntroductionRoute },
  { ...teachersOverviewRoute },
  { ...teacherDetailRoute },

  // SPECIAL ACCESS
  { ...specialAccessRoute },
  { ...SpecialAccessCourseIntroductionRoute },
  { ...specialAccessLessonPlayRoute },
  // LOGIN
  { ...loginRoute },
  { ...switchBackRoute },
  // OTHER
  { ...selectProductRoute },

  // ADMIN
  { ...editCourse },
  { ...defEditCourse },
  { ...viewCourses },
  { ...dashboard },
  { ...onlineCourses },
  { ...offlineCourses },
  { ...courseSearchBar },
  { ...adminCourseLessonPlay },

  // MY ACCOUNT
  { ...subscriptions },
  { ...certificates },
  { ...settings },
  { ...profile },

  // LOADING
  {
    name: 'loading',
    title: `Loading... | ${BrandTitle}`,
    meta: {
      title: `Loading... | ${BrandTitle}`
    },
    pattern: '/loading'
  },

  // LESSONPLAY ROUTES
  { ...lessonPlayRoutes },
  { ...defLessonPlayRoutes },
  { ...evaluation },
  { ...terms },
  { ...feedback },

  // ERROR PAGES
  { ...errorPageRoute },
  { ...notFoundPageRoute },

  // ASSISTANT
  { ...assistantManagementRoute },

  // LMS
  ...lmsDashboardRoute,
  ...lmsCoursesRoute,
  ...lmsStudentsRoute,
  { ...lmsStudentDetailRoute },
  { ...lmsCourseDetailRoute },
  { ...lmsOrganisationsRoute },

  // VTB
  { ...vtbRoute },
  { ...attendanceSheetVtbRoute },

  // Curriculum
  { ...curriculumDashboardRoute },
  { ...curriculumStagesRoute },

  // OTHER PAGES
  { ...faq },
  { ...faq2 },
  { ...faq3 },
  { ...article },
  { ...colophonRoute },

  // SEARCH
  { ...searchOverviewRoute }
];
