import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Avatar, Tooltip } from '@mui/material';

import { useStore } from 'hooks';
import { useHeaderContext } from '../context';

import * as Styled from './HeaderComponents.styles';

const AccountButton = () => {
  const store = useStore();
  const { t } = useTranslation();

  const { anchorAccount, handleMenuAccountOpen } = useHeaderContext();

  return (
    <Tooltip title={store?.login?.userName || t('headerbar.myAccountMenuTooltip')} data-test="account">
      <Styled.IconButton
        id="account"
        className="onboarding-avatar"
        color="inherit"
        aria-haspopup="true"
        aria-owns={anchorAccount ? 'simple-account' : null}
        onClick={handleMenuAccountOpen}
      >
        <Avatar data-id="Super" alt="" src={store.avatarUrl()} color="primary" />
      </Styled.IconButton>
    </Tooltip>
  );
};

export default observer(AccountButton);
