import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { widgetButtons } from '../dialog/formWidgets/formWidgets.helper';

const DialogWidgetsTabelDetailPanel = ({ data, openWidgetDialog }) => {
  const { rowData } = data;
  const { t } = useTranslation('editCourseForm');
  // Create a deep clone of data as rowData to prevent mutation
  const clonedRowData = JSON.parse(JSON.stringify(rowData));

  const widgetCode = clonedRowData.attributes.widget_code;

  // If rowData has an id, it is an existing widget resulting in creating a new rotating question based on the existing widget
  if (rowData?.id) {
    clonedRowData.attributes.title = `COPY of ${rowData.attributes.title}`;
    // Remove properties preventing it from updating the widget instead of creating a new widget
    ['id', 'links', 'relationships'].map((item) => delete clonedRowData[item]);
    ['drupal_internal__nid'].map((item) => delete clonedRowData.attributes[item]);
  }

  // Disable buttons based on new widget || rotating question
  const isDisabled = (widgetType) => {
    if (!clonedRowData?.attributes?.widget_type) return false;
    return widgetType.toLowerCase() !== clonedRowData.attributes.widget_type.toLowerCase();
  };

  // TRANSLATIONS _________________________________________________________
  const detailPanelTitle = t(`lesEditForm.easyWidgetcreator.${rowData?.id ? 'rotatingTitle' : 'title'}`);
  const detailPanelSubText = t(`lesEditForm.easyWidgetcreator.${rowData?.id ? 'rotatingDescription' : 'description'}`);

  //  BUTTONS ______________________________________________________________
  const buttons = widgetButtons;

  return (
    <Box className="detailPanel" sx={sxDetailPanel}>
      <Box className="detailPanel_container">
        <Box className="detailPanel__title">{`${widgetCode}: ${detailPanelTitle}`}</Box>
        <Box className="detailPanel_subtext">{`${detailPanelSubText}`}</Box>
      </Box>
      <Box className="detailPanel_buttons">
        <Box className="detailPanel_buttons_type">{`Widget type: `}</Box>
        {buttons.available.map((btn, index) => (
          <Button
            key={index}
            {...buttons.props}
            disabled={isDisabled(btn.type)}
            onClick={() => openWidgetDialog(clonedRowData, false, btn.type)}
          >
            {btn.label}
          </Button>
        ))}
        {buttons.disabled.map((btn) => (
          <Button key={btn} disabled>
            {btn}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

DialogWidgetsTabelDetailPanel.propTypes = {
  data: PropTypes.object,
  openWidgetDialog: PropTypes.func
};

export default observer(DialogWidgetsTabelDetailPanel);

// CUSTOM SX ______________________________________________________________
const sxDetailPanel = {
  padding: '20px',
  background: '#c0c0c033',
  '.detailPanel_container': { paddingBottom: '20px' },
  ' .detailPanel__title': { color: '#000000ab', fontSize: '16px', fontWeight: 500, lineHeight: '30px' },
  ' .detailPanel_subtext': { color: '#000000ab', fontSize: '13px', fontWeight: 400, lineHeight: '13px' },
  ' .detailPanel_buttons': { display: 'flex', gap: '8px' },
  ' .detailPanel_buttons_type': { fontWeight: 500, lineHeight: '30px', alignItens: 'center', color: '#474242' }
};
