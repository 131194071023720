import { Button } from '@mui/material';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';

const mapLabelToComplainsUrl = {
  ewise: 'https://www.e-wise.nl/klachtenprocedure',
  pe: 'https://www.pe-academy.nl/klachtenprocedure',
  po: 'https://www.po-online.nl/klachtenprocedure',
  cme: 'https://www.cme-online.nl/klachtenprocedure'
};

const ComplaintsLink = () => {
  const { t } = useTranslation();
  const store = useStore();
  const { label } = store;
  const url = mapLabelToComplainsUrl[label];
  return (
    <Button target="_blank" component="a" sx={{ textTransform: 'capitalize', color: 'black', fontWeight: '400' }} href={url}>
      {t('footer.complaints')}
    </Button>
  );
};

export default ComplaintsLink;
