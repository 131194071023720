/* Core */
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import { Store } from 'state/ContextStore';

/* Custom components */
import { Box, Typography, Divider, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TopLinks from 'pages/course/components/CourseOverview/TopLinks';
import { ThemeBrandTitle, getMarketingSiteUrl } from 'helpers/Helpers';

/**
 *  ColophonOverview
 *  The ColophonOverview component is the main component for displaying the colophon.
 */
const ColophonOverview = () => {
  const { t } = useTranslation('colophon');
  const store = useContext(Store); // Use useContext to access the store
  const { product, packageId } = store.params;
  const selectedPackage = store.packages.filter((pack) => pack.nid === store.selectedPackageId);
  const selectedPackageTitle = selectedPackage[0]?.tab_label || '';
  const labelTitle = ThemeBrandTitle();
  const marketingUrl = getMarketingSiteUrl();
  const marketingShortUrl = getMarketingSiteUrl().replace(/^https?:\/\//, '');
  const colophonEmail = `redactie@${getMarketingSiteUrl().replace(/^https?:\/\/(www\.)?/, '')}`;
  const colophonEmailFull = `mailto:${colophonEmail}`;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      const result = await window.authedClient(`${process.env.REACT_APP_JSONAPI}/ewapi/product/${product}/package/${packageId}/json`);

      const { authors, profession } = result.data.data;

      const parsedAuthors = authors
        .map((author) => {
          const { titles, first_name: firstName, last_name: lastName, affiliation } = author;
          const fullName = [titles, firstName, lastName, affiliation]
            .filter(Boolean)
            .map((el) => el.trim())
            .join(' ');
          return { ...author, fullName };
        })
        .sort((a, b) => (a.fullName > b.fullName ? 1 : -1));

      setData({
        authors: parsedAuthors,
        advisoryBoard: profession.advisory_board.map((advisoryBoardAuthorId) =>
          parsedAuthors.find((author) => author.id === advisoryBoardAuthorId)
        )
      });

      setLoading(false);
    };

    init();
  }, [packageId, product, setData]);

  return (
    <Box className="colophon_overview">
      <TopLinks current="colophonOverview" />
      <Box className="colophon_overview_box">
        <Box className="colophon_box">
          <Typography variant="h1">{selectedPackageTitle}</Typography>
          <Typography variant="body">{t('intro', { labelTitle })}</Typography>
        </Box>
        <Divider />
        {loading && <Skeleton animation="wave" variant="rectangular" width="100%" height="120px" />}
        {data?.advisoryBoard?.length > 0 && (
          <>
            <Box className="colophon_box">
              <Typography variant="h1">{t('titles.title1')}</Typography>
              <Typography variant="body">
                <ul>
                  {data.advisoryBoard.map(({ id, fullName }) => (
                    <li key={id}>{fullName}</li>
                  ))}
                </ul>
              </Typography>
            </Box>
            <Divider />
          </>
        )}
        <Box className="colophon_box">
          <Typography variant="h1">{t('titles.title2')}</Typography>
          <Typography variant="body">{t('experts', { labelTitle })}</Typography>
        </Box>
        <Divider />
        <Box className="colophon_box">
          <Typography variant="h1">{t('titles.title3')}</Typography>
          <Typography variant="body">
            E-WISE Nederland bv <br /> Janssoniuslaan 40 <br /> 3528 AJ Utrecht <br />
            <br /> 030-26 44 100 <br />
            <a href={marketingUrl} target="_blank" rel="noreferrer">
              {marketingShortUrl}
            </a>
            <br />
            <a href={colophonEmailFull} target="_blank" rel="noreferrer">
              {colophonEmail}
            </a>
          </Typography>
        </Box>
        <Divider />
        <Box className="colophon_box">
          <Typography variant="h1">{t('titles.title4')}</Typography>
          <Typography variant="body">{t('copyright', { labelTitle })}</Typography>
        </Box>
        <Divider />
        <Box className="colophon_box">
          <Typography variant="h1">{t('titles.title5')}</Typography>
          <Typography variant="body">{t('rights')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(ColophonOverview);
