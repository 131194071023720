import { types as t } from 'mobx-state-tree';
import cloneDeep from 'lodash/cloneDeep';
// import { IsoDate } from './BaseModel';
import { RelLessonContent } from './LessonContent';

import {
  RadioQuestion,
  CheckQuestion,
  OpenQuestion,
  VideoWidget,
  MatchQuestion,
  SortQuestion,
  HtmlWidget,
  NumericalQuestion,
  WordCloud,
  PollWidget
} from './QuestionOption';

import { RelPollWidgetItem } from './PollWidget/PollWidget';
import { RelWordCloudItem } from './WordCloudWidget/WordCloud';

let widgetType;

const questionOption = t.union({
  dispatcher: () => {
    switch (widgetType) {
      case 'Radio Question':
        return RadioQuestion;
      case 'Check Question':
        return CheckQuestion;
      case 'Open Question':
        return OpenQuestion;
      case 'Sort Question':
        return SortQuestion;
      case 'Match Question':
        return MatchQuestion;
      case 'Numerical Question':
        return NumericalQuestion;
      case 'Video Widget':
        return VideoWidget;
      case 'HTML Widget':
        return HtmlWidget;
      case 'Wordcloud Widget':
        return WordCloud;
      case 'Poll widget':
        return PollWidget;
      default:
        throw new Error();
    }
  },
  RadioQuestion,
  CheckQuestion,
  OpenQuestion,
  VideoWidget,
  SortQuestion,
  MatchQuestion,
  HtmlWidget,
  NumericalQuestion,
  WordCloud,
  PollWidget
});

export const QuestionWidget = t
  .model('QuestionWidget', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      status: t.maybe(t.boolean),
      drupal_internal__nid: t.maybe(t.number),
      title: t.string,
      weight: t.number,
      widget_code: t.string,
      widget_type: t.string,
      widget_data: t.maybeNull(t.string),
      widget_link: t.maybeNull(t.number),
      widget_link_uuid: t.maybeNull(t.string),
      // Deserialized fields
      question: t.maybeNull(t.string),
      feedback: t.maybeNull(t.string),
      feedbackCorrect: t.maybeNull(t.string),
      feedbackFalse: t.maybeNull(t.string),
      options: t.maybeNull(questionOption)
    }),
    relationships: t.maybeNull(
      t.model({
        lesson_content_link: t.maybeNull(RelLessonContent),
        widget_link: t.maybeNull(t.union(RelWordCloudItem, RelPollWidgetItem))
      })
    )
  })
  .volatile(() => ({
    component: {} // Caching instantiated component during lessonPlay
  }))

  .views((self) => ({
    get widgetType() {
      return self.attributes.widget_type.split(/\s/)[0];
    },
    get isQuestion() {
      return self.attributes.widget_type.match(/Question$/i);
    }
  }))

  .actions((self) => ({
    setComponent(component) {
      self.component = component;
    }
  }))

  .preProcessSnapshot((snapshot) => {
    const newSnapshot = cloneDeep(snapshot);
    widgetType = newSnapshot.attributes.widget_type;
    try {
      const data = JSON.parse(newSnapshot.attributes.widget_data);
      newSnapshot.attributes = { ...newSnapshot.attributes, ...data };
    } catch (e) {
      console.error('Error parsing widget_data', e);
    }
    return newSnapshot;
  });
