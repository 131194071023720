import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { useHeaderContext } from '../context';
import * as Styled from './HeaderComponents.styles';

const CourseOverviewButton = () => {
  const { t } = useTranslation();

  const { handleGoToCourseOverview } = useHeaderContext();

  return (
    <Tooltip title={t('CourseOverview')} arrow>
      <Styled.Button id="go-to-course-overview" aria-label="" onClick={handleGoToCourseOverview}>
        {t('course.leftSidebar.courseOverview')}
      </Styled.Button>
    </Tooltip>
  );
};

export default observer(CourseOverviewButton);
