import { Box } from '@mui/material';
import { useContext } from 'react';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
//
import Toast from './Toast';

// renders the toast container to be displayed in the application
const ToastContainer = () => {
  const store = useContext(Store);
  const { courseEdit } = store;
  const { toasts } = courseEdit;

  const close = (id) => courseEdit.deleteToast(id);
  //
  const sxToastContainer = { position: 'fixed', top: '100px', right: '20px', zIndex: '9999' };

  return (
    <Box sx={sxToastContainer}>
      <Box className="toasts-wrapper">
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} close={close} />
        ))}
      </Box>
    </Box>
  );
};

export default observer(ToastContainer);
