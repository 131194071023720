import MainLayout from 'pages/mainLayout/MainLayout';
import AttendanceSheetLayout from 'pages/attendanceSheet/AttendanceSheetLayout';

const AttendanceSheetViewMap = {
  attendanceSheet: (
    <MainLayout subtitle="" title="attendance.title">
      <AttendanceSheetLayout />
    </MainLayout>
  )
};

export default AttendanceSheetViewMap;
