import React from 'react';
import MainLayout from 'pages/mainLayout/MainLayout';
import { Lazy } from 'components';
import LmsLayout from '../LmsLayout';

const LmsStudentDetail = React.lazy(() => import('./LmsStudentDetail'));

const LmsStudentDetailViewMap = {
  LmsStudentDetail: (
    <MainLayout title="lms.navbar.students" subtitle="">
      <LmsLayout>
        <Lazy noLayout>
          <LmsStudentDetail />
        </Lazy>
      </LmsLayout>
    </MainLayout>
  )
};

export default LmsStudentDetailViewMap;
