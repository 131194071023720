import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Icon } from '@iconify/react';
import { Box, Typography } from '@mui/material';

import { Button } from '../../components';

const ActivitiesTableToolbar = ({
  onAddCallback,
  onEditCallback,
  onDeleteCallback,
  title,
  addActivityBtnLabel,
  addActivityBtnTooltip,
  editBtnLabel,
  editBtnTooltip
}) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div>
      <Typography
        sx={{
          maxWidth: '960px',
          marginLeft: '0.5rem',
          fontSize: '1.125rem',
          lineHeight: '1.75rem',
          fontWeight: '600',
          color: 'rgb(3, 54, 116)'
        }}
      >
        {title}
      </Typography>
    </div>
    <Box sx={{ display: 'flex', gap: '0.5rem', flexShrink: 0, paddingLeft: '20px' }}>
      <Button tooltipText={addActivityBtnTooltip} onClick={onAddCallback}>
        <Icon icon="dashicons:plus" />
        {addActivityBtnLabel}
      </Button>

      <Button tooltipText={editBtnTooltip} onClick={onEditCallback}>
        <Icon icon="ant-design:edit-filled" />
        {editBtnLabel}
      </Button>

      <button
        type="button"
        style={{
          display: 'grid',
          placeItems: 'center',
          backgroundColor: '#FEE2E2',
          width: '2.5rem',
          height: '2.5rem',
          borderRadius: '9999px',
          border: 'none',
          cursor: 'pointer'
        }}
        onClick={onDeleteCallback}
      >
        <Icon icon="akar-icons:cross" style={{ color: '#B91C1C', fontSize: '0.75rem', lineHeight: '1rem', fontWeight: '700' }} />
      </button>
    </Box>
  </Box>
);

ActivitiesTableToolbar.propTypes = {
  onAddCallback: PropTypes.func.isRequired,
  onDeleteCallback: PropTypes.func.isRequired,
  onEditCallback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  editBtnLabel: PropTypes.string.isRequired,
  editBtnTooltip: PropTypes.string,
  addActivityBtnLabel: PropTypes.string.isRequired,
  addActivityBtnTooltip: PropTypes.string
};

ActivitiesTableToolbar.defaultProps = {
  editBtnTooltip: '',
  addActivityBtnTooltip: ''
};

export default observer(ActivitiesTableToolbar);
