/* Course status */
import { Store } from 'state/ContextStore';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

// displays a message when there are no courses
const ViewAllCertificates = () => {
  const store = useContext(Store);
  const { t } = useTranslation();

  return (
    <Box sx={sxMyCertificates}>
      <Typography className="txtShowAllCertificates" variant="body">
        {t('courseoverview.tabFinished.showAllCertificates')}
      </Typography>
      <Button
        onClick={() => store.routerStore.goTo('certificates')}
        className="btnShowAllCertificates"
        variant="outlined"
        color="primary"
        size="small"
      >
        {t('account.certificate.tab')}
      </Button>
    </Box>
  );
};

const sxMyCertificates = {
  justifyContent: 'end',
  alignItems: 'center',
  padding: '10px 0px',
  paddingTop: '0px',
  display: 'flex',
  gap: '10px',
  '.txtShowAllCertificates': {
    color: '#0000008f',
    fontWeight: '500',
    '@media screen and (max-width: 440px)': {
      fontSize: '12px',
      lineHeight: '12px',
      textAlign: 'right'
    }
  },
  '.btnShowAllCertificates': {
    textTransform: 'math-auto',
    whiteSpace: 'nowrap'
  }
};

export default ViewAllCertificates;
