import { SvgIcon } from '@mui/material';

const PodcastsIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 500 500">
    <g>
      <path
        d="M355.19,175.09v69.16c0,57.76-47.01,105.07-104.66,105.56v39.84c77.34-.39,140.49-63.77,140.49-141.19v-73.37h-35.83Z" />
      <g>
        <path
          d="M250,13.45h0c-51.83,0-94.24,42.41-94.24,94.24v134.49c0,51.83,42.41,94.24,94.24,94.24h0c51.83,0,94.24-42.41,94.24-94.24V107.69c0-51.83-42.41-94.24-94.24-94.24ZM317.23,173.08v61.91c0,37.15-30.39,67.54-67.54,67.54h0c-37.15,0-67.54-30.39-67.54-67.54v-126.01c0-37.15,30.39-67.54,67.54-67.54h0c37.15,0,67.54,30.39,67.54,67.54v64.1Z" />
        <path d="M250.32,175.09h-94.55v66.55c0,53.24,42.94,94.77,94.77,94.77l-.22-161.32Z" />
        <path
          d="M182.46,175.09v59.64c0,37.47,29.85,68.13,66.33,68.13h2.42c36.48,0,66.33-30.66,66.33-68.13v-59.64h-135.08Z" />
      </g>
      <circle cx="373.26" cy="175.35" r="17.84" />
      <rect x="108.91" y="175.09" width="282.18" height="45.79" />
      <circle cx="126.21" cy="176.42" r="17.84" />
      <path
        d="M250.53,349.81c-.3,0-.61.01-.91.01h0c-58.07,0-105.57-47.51-105.57-105.57v-69.16h-35.42v73.37c0,77.66,63.54,141.2,141.2,141.2h0c.24,0,.47,0,.71,0v-39.84Z" />
      <g>
        <path d="M171.2,391.25h133.11v9.79c0,8.7-7.07,15.77-15.77,15.77h-101.57c-8.7,0-15.77-7.07-15.77-15.77v-9.79h0Z"
              transform="translate(641.78 166.27) rotate(90)" />
        <path d="M212.53,391.25h101.57c8.7,0,15.77,7.07,15.77,15.77v9.79h-133.11v-9.79c0-8.7,7.07-15.77,15.77-15.77Z"
              transform="translate(667.34 140.72) rotate(90)" />
      </g>
      <rect x="162.15" y="440.77" width="177.3" height="45.79" rx="17.73" ry="17.73" />
    </g>
    {' '}
  </SvgIcon>
);

export default PodcastsIcon;
