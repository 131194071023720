export const dateDiff = (tillDate) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const a = new Date();
  const b = new Date(tillDate);
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1;
};

export const convertDate = (date) => new Date(date).toJSON().slice(0, 10).split('-').reverse().join('-');

// export const convertDate = (date) => {
//   const dd = String(date.getDate()).padStart(2, '0');
//   const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
//   const yyyy = date.getFullYear();
//   return `${yyyy}-${mm}-${dd}`;
// };

export const addMonths = (months) => {
  const today = new Date();
  const currentDate = convertDate(today);
  const newEndDate = new Date(today.setMonth(today.getMonth() + months));
  const endDate = convertDate(newEndDate);
  return { currentDate, endDate };
};
