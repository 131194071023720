export const isDisabled = (item, subMenu, routeName) => {
  if (subMenu.name !== 'Edit') return false;
  const isEditRoute = subMenu.name === 'Edit' && item.name === 'Edit' && routeName.includes('edit');
  return !isEditRoute;
};

export const setColorMenuIcon = (item, params, theme) => {
  const isSelected = item.package_id.toString() === params?.packageNid;
  if (!isSelected) return ['#eef2fa', 'inherit'];
  return isSelected && [theme.palette.primary.main, '#fff'];
};

export const isSelected = (item, open) => {
  const isOpen = item.package_id.toString() === open;
  return isOpen ? 'selected' : '';
};

export const isCurrentRoute = (item, params) => {
  const isCurRoute = item.package_id.toString() === params?.packageNid && item.course_container_id.toString() === params?.containerNid;
  return isCurRoute ? 'current_route' : '';
};

export const isActive = (mainMenu, subMenu, params, routeName) => {
  const pId = mainMenu.package_id.toString();
  const subMenuName = subMenu.routeName;
  const isSubmenu = pId === params?.packageNid && subMenuName === routeName;
  return isSubmenu;
};

export const sxPackageList = (theme) => ({
  '.package__list.current_route': {
    background: 'white',
    '.list_header_text': { color: 'white' },
    '.MuiListItemText-primary': { color: theme.palette.primary.main },
    '.subMenu_true': {
      '.MuiListItemText-primary': { color: 'white' }
    }
  },
  '.package__list.selected .list_header_text .MuiListItemText-primary': { color: 'white' },
  '.package__list.selected': {
    background: theme.palette.primary.light,
    '.MuiListItemText-primary': { color: '#dfdfdfd4' }
  }
});
