import { types as t, getSnapshot, detach } from 'mobx-state-tree';
import cloneDeep from 'lodash/cloneDeep';
import { IsoDate } from '../models/BaseModel';
import { Lesson } from './Course';
import { CourseResult } from './CourseResults';
import { User } from './User';

export const LessonContainer = t.model('LessonResult', {
  id: t.number,
  uuid: t.string,
  user_id: t.reference(User),
  status: t.string,
  score: t.number,
  questions_answered: t.number,
  sequence_no: t.number,
  page_id_max: t.maybeNull(t.number),
  created: t.number,
  changed: t.number,
  data: t.string,
  pages: t.maybe(
    t.array(
      t.model({
        completed: t.boolean
      })
    )
  ),
  questions: t.maybe(
    t.array(
      t.model({
        questionId: t.string,
        type: t.string,
        weight: t.number,
        correct: t.optional(t.boolean, false),
        last_correct: t.optional(t.boolean, false),
        last_response: t.string,
        last_response_timestamp: IsoDate,
        first_response: t.string,
        first_response_timestamp: IsoDate,
        tries: t.number
      })
    )
  ),
  lesson: t.reference(Lesson),
  course_result: t.reference(CourseResult),
  current: t.number
});

export const LessonResult = t.array(
  t.snapshotProcessor(LessonContainer, {
    preProcessor(sn) {
      let newSnapshot = cloneDeep(sn);
      try {
        let { data } = newSnapshot;
        if (!data || data === '{}') data = { questions: [], pages: [] };
        else data = JSON.parse(data);
        if (!data.questions) data.questions = [];
        if (!data.pages) data.pages = [];
        newSnapshot = { ...newSnapshot, ...data };
      } catch (e) {
        console.error(e);
      }
      return newSnapshot;
    }
  })
);

export const LessonResults = t
  .model({
    lessonResults: LessonResult
  })
  .views((self) => ({
    findLessonByCourseNid(nid) {
      const lessonResult = self.lessonResults.filter((lesson) => lesson && lesson.course_result.course_id.course_id === nid);
      return lessonResult;
    },
    getFailedCourses() {
      return self.lessonResults.filter((lessonR) => lessonR.status === '2');
    },
    isLessonFailed(lessonId) {
      return self.getFailedCourses().filter((failedCourse) => failedCourse.lesson === lessonId).length > 1;
    }
  }))
  .actions((self) => ({
    updateLessonResults(lessonResults) {
      const updatedLessonRes = [...JSON.parse(JSON.stringify(self.lessonResults))];
      lessonResults.forEach((lessonResult) => {
        const index = updatedLessonRes.findIndex((lesson) => lesson.lesson === lessonResult.lesson);
        if (index !== -1) {
          updatedLessonRes[index] = lessonResult;
        } else {
          updatedLessonRes.push(lessonResult);
        }
      });

      detach(self.lessonResults);
      self.lessonResults = getSnapshot(LessonResult.create(updatedLessonRes));
      return self.lessonResults;
    }
  }));
