// eslint-disable-next-line import/no-extraneous-dependencies
import pkce from 'pkce-codes';
import * as Sentry from '@sentry/react';
import authorizationApi from '../authorizationApi';

const LOCALSTORAGE_KEY_OAUTH_TOKEN = 'oauth_token';

let currentTokenRefreshCycle = null;

export class OAuth2 {
  authorizationCode;

  authorizationDomain;

  tokenEndpoint;

  authorizeEndpoint;

  tokenInformation;

  clientId;

  clientSecret;

  redirectUrl;

  constructor({ redirectUrl, clientId, clientSecret, authorizeEndpoint, tokenEndpoint, authorizationDomain }) {
    this.authorizationCode = null;
    this.authorizeEndpoint = authorizeEndpoint;
    this.tokenEndpoint = tokenEndpoint;
    this.tokenInformation = {};
    this.clientSecret = clientSecret;
    this.clientId = clientId;
    this.redirectUrl = redirectUrl;
    this.authorizationDomain = authorizationDomain;
  }

  async initializeOauthSession() {
    /**
     * Get the token from the localstorage/session
     */
    const hasPersistedToken = this.getTokenFromPersistentStorage();

    /**
     * Check if authorization values
     * are present
     *
     * (E.G we are being directed back from the auth portal)
     */
    const hasAuthorizeValues = this.getAuthorizeValuesFromQuery();

    /**
     * Check if impersonation values
     * are present
     *
     * (E.G we are being directed back from the admin panel)
     */
    const hasImpersonationValues = this.getImpersonationValuesFromQuery();

    /**
     * Getting authorization credentials
     * from the auth portal takes precedence over all other forms
     * of authentication
     */
    if (hasAuthorizeValues) {
      const tokenRequest = authorizationApi.post(
        this.tokenEndpoint,
        new URLSearchParams({
          grant_type: 'authorization_code',
          code: this.authorizationCode,
          redirect_uri: this.redirectUrl,
          client_id: this.clientId,
          code_verifier: localStorage.getItem('code_verifier')
        })
      );
      try {
        const response = await tokenRequest;

        // Handle errors in the response
        if (response.error) {
          // Redirect on error and stop further execution
          Sentry.captureException(`Auth error: Something went wrong: ${response.error}`);
          window.location.href = this.authorizeEndpoint;
          return false;
        }

        // Save token information to persistent storage
        this.tokenInformation = response.data;
        this.saveTokenToPersistentStorage();

        // Update browser history if a state is present in the response
        if (response.state) {
          window.history.pushState('', '', response.state);
        }

        // Request successful
        return true;
      } catch (error) {
        // Handle unexpected errors
        // Redirect on error and stop further execution
        Sentry.captureException(`Auth error: Something went wrong: ${error}`);
        window.location.href = this.authorizeEndpoint;
        console.error('Token request failed:', error);
        return false;
      }
    }

    /**
     * Getting authorization credentials
     * from the auth portal takes precedence over all other forms
     * of authentication
     */
    if (hasImpersonationValues) {
      const tokenRequest = authorizationApi.post(
        this.tokenEndpoint,
        new URLSearchParams({
          grant_type: 'authorization_code',
          code: this.authorizationCode,
          redirect_uri: this.redirectUrl,
          client_id: this.clientId,
          code_verifier: '--6t5HeyDNhPx8C9MYOEFWAgj9q9Ijhg7at-WtGGmrgIVB'
        })
      );

      const response = await tokenRequest.catch((err) => {
        console.error(err);
      });

      // If no data go back
      if (response.error) {
        window.location.href = this.redirectUrl;
      }

      this.tokenInformation = response.data;
      this.tokenInformation.isImpersonation = true;
      this.saveTokenToPersistentStorage();

      if (response.state) {
        window.history.pushState('', '', response.state);
      }

      return true;
    }

    if (hasPersistedToken) {
      return true;
    }

    /**
     * Redirect to the oath provider
     * if we do not have a valid token
     * or way to generate it
     */
    if (!hasAuthorizeValues && !hasPersistedToken) {
      this.redirectToExternalProvider();
    }

    return false;
  }

  /**
   * Redirect the user to the external auth provider
   *
   * @param location
   * @returns {Promise<null>}
   */
  async redirectToExternalProvider(location = null) {
    const { code_verfier: codeVerifier, code_challenge: codeChallenge } = await pkce();

    const codeChallengeMethod = 'S256';

    localStorage.setItem('code_verifier', codeVerifier);

    let url = `${this.authorizationDomain}${this.authorizeEndpoint}`;
    if (location !== null) {
      url = `${this.authorizationDomain}${location}`;
    }
    const authorizationUrl = new URL(url);
    authorizationUrl.searchParams.set('client_id', this.clientId);
    authorizationUrl.searchParams.set('code_challenge', codeChallenge);
    authorizationUrl.searchParams.set('code_challenge_method', codeChallengeMethod);
    authorizationUrl.searchParams.set('redirect_uri', this.redirectUrl);
    authorizationUrl.searchParams.set('response_type', 'code');
    authorizationUrl.searchParams.set('scope', 'authenticated');
    authorizationUrl.searchParams.set('state', this.getCurrentRoutingState());

    window.location.href = authorizationUrl;

    // Hacky, but it is a needed safeguard.
    // Sleeps execution for 3 seconds to make sure the browser has time
    // to navigate away
    await new Promise((resolve) => setTimeout(resolve, 3000));

    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  async killSession() {
    /**
     * Remove the now invalidated tokens from the
     * browser storage
     */
    localStorage.removeItem(LOCALSTORAGE_KEY_OAUTH_TOKEN);

    /**
     * Redirect to external provider so
     * login can commence again
     */
    this.redirectToExternalProvider('/oauth/logout');
  }

  // eslint-disable-next-line class-methods-use-this
  async killLocalSession() {
    /**
     * Remove the now invalidated tokens from the
     * browser storage
     */
    localStorage.removeItem(LOCALSTORAGE_KEY_OAUTH_TOKEN);
  }

  /**
   * Get the current routing state. to persist it to the auth provider
   */
  // eslint-disable-next-line class-methods-use-this
  getCurrentRoutingState() {
    const currentState = window.location.pathname;

    /**
     * If you are entering through
     * the default route we don't send
     * the state
     */
    if (currentState === '/' || currentState === '/logout') {
      return '';
    }

    return currentState;
  }

  /**
   * Get the token for usage within the application
   */
  async getToken() {
    // Add a hack around the fact multi-window users
    // might get out of sync
    //
    // This effectively syncs the auth between windows
    // trough the localstorage
    this.getTokenFromPersistentStorage();

    if (this.accessTokenIsExpired()) {
      await this.refreshToken();
    }

    return this.tokenInformation;
  }

  /**
   * Check if the access token is expired
   *
   * @return {boolean}
   */
  accessTokenIsExpired() {
    const currentTime = new Date().getTime();

    return !!(this.tokenInformation.tokenExpireTime && currentTime >= this.tokenInformation.tokenExpireTime);
  }

  /**
   * Check if we have the correct
   * data to refresh our token
   * @return {boolean}
   */
  refreshGrantIsAvailable() {
    if (!this.tokenInformation.refresh_token) {
      return false;
    }
    const currentTime = new Date().getTime();

    return !(!this.tokenInformation.refreshExpireTime || this.tokenInformation.refreshExpireTime >= currentTime);
  }

  /**
   * Attempt to refresh the token
   * @return {Promise<boolean>}
   */
  async refreshToken() {
    if (currentTokenRefreshCycle === null) {
      if (!this.refreshGrantIsAvailable()) {
        await this.killSession();
      }

      currentTokenRefreshCycle = this._refreshTokenInternal();
    }

    const result = await currentTokenRefreshCycle;
    currentTokenRefreshCycle = null;
    return result;
  }

  async _refreshTokenInternal() {
    if (this.tokenInformation.refresh_token === null) {
      throw new Error('You are trying to refresh a non existing token');
    }

    const tokenRequest = authorizationApi.post(
      this.tokenEndpoint,
      new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: this.tokenInformation.refresh_token,
        client_id: this.clientId
      })
    );

    const response = await tokenRequest.catch(async () => {
      await this.killSession();
      throw new Error('The /token request has failed');
    });

    if (response.status >= 200 && response.status < 300) {
      const { isImpersonation } = this.tokenInformation;
      this.tokenInformation = response.data;
      this.tokenInformation.isImpersonation = isImpersonation;
      this.saveTokenToPersistentStorage();
    }

    if (response.status >= 400) {
      await this.killSession();
      throw new Error('The /token request has failed');
    }

    return true;
  }

  /**
   * Get the values generated in the first
   * step of the authorize flow.
   *
   * (So this is where we get back to after logging in to the backend)
   */
  getAuthorizeValuesFromQuery() {
    const queryString = new URLSearchParams(window.location.search);

    if (queryString.has('code')) {
      this.authorizationCode = queryString.get('code');
      return true;
    }

    return false;
  }

  /**
   * Get the values generated in the first
   * step of the authorize flow.
   *
   * (So this is where we get back to after logging in to the backend)
   */
  getImpersonationValuesFromQuery() {
    const queryString = new URLSearchParams(window.location.search);

    if (queryString.has('impersonate')) {
      this.authorizationCode = queryString.get('impersonate');
      return true;
    }

    return false;
  }

  /**
   * Get the token (if exists) from a persistent source
   * this is usefull when opening multiple tabs
   */
  getTokenFromPersistentStorage() {
    if (localStorage.getItem(LOCALSTORAGE_KEY_OAUTH_TOKEN)) {
      this.tokenInformation = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY_OAUTH_TOKEN));

      return true;
    }

    return false;
  }

  /**
   * Save the token to persistent storage
   * (currently localstorage, might go to session soon)
   * so please don't rely on the underlying value location!!
   */
  saveTokenToPersistentStorage() {
    const tokenExpireTime = new Date();
    const refreshExpireTime = new Date();

    tokenExpireTime.setTime(tokenExpireTime.getTime() + this.tokenInformation.expires_in * 1000);
    refreshExpireTime.setTime(refreshExpireTime.getTime() + 60 * 1000);

    this.tokenInformation.tokenExpireTime = tokenExpireTime.getTime();
    this.tokenInformation.refreshExpireTime = refreshExpireTime.getTime();
    const jsonFormattedToken = JSON.stringify(this.tokenInformation);
    localStorage.setItem(LOCALSTORAGE_KEY_OAUTH_TOKEN, jsonFormattedToken);
  }
}
