/* Core */
import { useState } from 'react';
import PropTypes from 'prop-types';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, ClickAwayListener } from '@mui/material';

/* MUI - Icons */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

/*
In summary, the AccordionComponent is a reusable component for creating accordions with customizable titles, content, and expand/collapse behavior. 
It can be used to display and hide content sections, and it provides the flexibility to customize the expand/collapse icon. 
Additionally, it allows for the accordion to be collapsed by clicking away from it when the clickAway prop is set to true.
*/
const AccordionComponent = ({ title, body, open, addIcon, clickAway }) => {
  const [expand, setExpand] = useState(open);

  const handleChange = () => {
    setExpand(!expand);
  };
  const handleClickAway = () => {
    if (clickAway) {
      setExpand(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Accordion expanded={expand} onChange={handleChange}>
        <AccordionSummary
          expandIcon={addIcon || <ArrowDropDownIcon color="primary" />}
          sx={{ [`.MuiAccordionSummary-expandIconWrapper`]: { backgroundColor: addIcon && 'background.main' } }}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails onClick={handleClickAway}>{body}</AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
};

AccordionComponent.propTypes = {
  title: PropTypes.any,
  body: PropTypes.any,
  addIcon: PropTypes.any,
  open: PropTypes.bool,
  clickAway: PropTypes.bool
};

export default AccordionComponent;
