/* eslint-disable camelcase */
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Card, CardContent, Grid, CardActions, Button, SvgIcon } from '@mui/material';
import { urlify } from 'state/Utils';
import download from 'components/icons/download';
import { ThemeBrandTitle } from 'helpers/Helpers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CoursePointsAlert from 'pages/course/components/CourseIntroduction/CoursePointsAlert';
import { differenceInMinutes, isValid } from 'date-fns';
import Header from './components/Header';

const isCourseCompletedInLessThan30Minutes = (date1, date2) => {
  if (!isValid(date1) || !isValid(date2)) {
    return false;
  }

  const diff = differenceInMinutes(date2, date1);
  return diff < 30;
};

const ScorePage = () => {
  const store = useStore();
  const { t } = useTranslation('evaluation');

  const { courseId, courseFeeback, lessonId } = store.routerStore.routerState.params;
  const { lesson_result } = store.lessonPlay;

  const success = courseFeeback === '1';
  const fail = courseFeeback === '0';

  const [link, setLink] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [answersPdf, setAnswersPdf] = useState('');

  // show popup if course is completed in less than 30 minutes only for notariaat package
  const [showPopup, setShowPopup] = useState(() => {
    const isNotariaatPackage = store.selectedPackageTitle.toLowerCase().includes('notariaat');
    const isAccredited = store.selectedPackage?.accredited === 1;
    if (
      isNotariaatPackage &&
      isAccredited &&
      success &&
      isCourseCompletedInLessThan30Minutes(
        new Date(store.lessonPlay?.lesson_result?.attributes.created),
        new Date(store.lessonPlay?.lesson_result?.attributes.changed)
      )
    ) {
      return true;
    }
    return false;
  });

  const brandTitle = ThemeBrandTitle();
  const score = lesson_result?.attributes?.score;
  const courseTitle = store.findCourseByNid(Number(courseId)).course_title;
  const courseUuid = store.findCourseByNid(Number(courseId));

  // check if we have more than one lesson
  const lessonLength = courseUuid.lessons.length;
  const lastLesson = courseUuid.lessons[lessonLength - 1].nid === Number(lessonId);

  // Find course Result id
  const courseResultsId = store?.lessonPlay?.lesson_result?.attributes?.course_result;
  const courseResultUuid = store?.lessonPlay?.courseResult?.id;

  // Check if user is LTI user
  const isLti = store.getLtiSession() === 1 || store.getLtiSession() === '1';

  // Check if user can do another attempt
  const maxAttempts = store?.lessonPlay?.lesson?.attributes?.max_attempts;
  const sequenceNo = store?.lessonPlay?.lesson_result?.attributes?.sequence_no;
  const cantDoAnotherAttempt = maxAttempts && parseInt(sequenceNo) + 1 > parseInt(maxAttempts - 1);

  const { u, uw, kunt, ww } =
    brandTitle !== 'E-WISE' ? { u: 'U', uw: 'uw', kunt: 'kunt', ww: 'heeft' } : { u: 'Je', uw: 'je', kunt: 'kan', ww: 'hebt' };
  const minimumScore = store.lessonPlay.lesson.attributes.minimum_score;

  const loadCompleted = async () => {
    store.setLessonState('init');
    if (!isLti) {
      setTimeout(() => {
        store.setLessonPlay(null);
      }, 1450);
      store.routerStore.goTo('defCourseIntroduction', {
        params: {
          product: store.params.product,
          productName: store.params.productName,
          packageId: store.params.packageId,
          courseId: store.params.courseId,
          title: urlify(courseTitle)
        }
      });
    }
  };

  // Restart course for LTI users
  const restartCourse = async () => {
    store.setLessonState('init');
    await store.courseResults.updateCourseResultbyNid(Number(store.params.courseId));
    store.courseResults.isCourseFinished(Number(store.params.courseId));
    store.routerStore.goTo('lessonPlay', {
      params: {
        product: store.params.product,
        productName: store.params.productName,
        packageId: store.params.packageId,
        courseId: store.params.courseId,
        lessonId: store.params.lessonId,
        pageNo: '1'
      }
    });
  };

  const handleSucces = async () => {
    try {
      if (courseResultsId && courseResultUuid) {
        const completedCourse = await store.lessonPlay.finishCourse(courseResultsId);
        const code = await store.lmsApi.downloadCertificate(courseResultUuid);
        const getAnswerPdf = completedCourse?.data?.answers_pdf?.url || '';
        if (getAnswerPdf) {
          setAnswersPdf(getAnswerPdf);
        }
        setLink(`${process.env.REACT_APP_JSONAPI}/course-result/${courseResultUuid}/${code}/certificate`);
        setDisabled(null);
      }
    } catch (error) {
      store.showToast({ message: t('scorePage.error', 'Something went wrong'), variant: 'error' });
    }
  };

  const nextLesson = async () => {
    store.setLessonState('init');
    const nextLessonId =
      courseUuid.lessons[lessonLength - 1].nid === Number(lessonId) ? courseUuid.lessons[0].nid : courseUuid.lessons[lessonLength - 1].nid;
    store.routerStore.goTo('lessonPlay', {
      params: {
        product: store.params.product,
        productName: store.params.productName,
        packageId: store.params.packageId,
        courseId: store.params.courseId,
        lessonId: nextLessonId,
        pageNo: '1'
      }
    });
  };

  useEffect(() => {
    if (!link && success) {
      handleSucces();
    }
  });

  return (
    <>
      <Box className="scorePage" style={{ backgroundColor: '#EEF2FA', minHeight: '100vh', display: 'flex' }}>
        <Header />
        <Box style={{ marginTop: '151px', width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <Card className="scorePageCard" style={{ padding: '22px', borderRadius: '18px', boxShadow: 'none' }}>
            <CardContent>
              <Grid container>
                {success && (
                  <Grid item>
                    <Box marginBottom="27px">
                      <Typography variant="h5" fontSize="20px">
                        {t('scorePage.completed', { courseTitle })}
                      </Typography>
                    </Box>
                    {/* <Box marginBottom="13px">
                      <Typography fontWeight="bold">{t('congratulations')}</Typography>
                    </Box> */}
                    {/* <Box marginBottom="27px">
                      <Typography> {t('scorePage.completed', { courseTitle })}</Typography>
                      <Typography fontWeight="600">{t('scorePage.score', { score })}</Typography>
                    </Box> */}
                    <Box>
                      <Typography>{t('scorePage.downloadCertificate', { u, uw, kunt })}</Typography>
                    </Box>
                  </Grid>
                )}
                {fail && (
                  <Grid item>
                    <Box marginBottom="13px">
                      <Typography fontWeight="bold" fontSize="20px">
                        {t('helaas')}
                      </Typography>
                    </Box>
                    <Box marginBottom="27px">
                      <Typography> {t('scorePage.fail')}</Typography>
                      <Typography fontWeight="600">{t('scorePage.failScore', { ww, u, uw, kunt, score, minimumScore })}</Typography>
                    </Box>
                    {!cantDoAnotherAttempt && (
                      <Box>
                        <Typography>{t('scorePage.retake', { u, uw, kunt })}</Typography>
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
              {success && (
                <>
                  {answersPdf && (
                    <Button variant="contained" href={answersPdf} target="_blank" style={{ marginRight: '16px', textAlign: 'center' }}>
                      {t('scorePage.downloadAnswers')}
                      <SvgIcon component={download} color="white" style={{ marginLeft: '16px' }} />
                    </Button>
                  )}
                  <Button
                    disabled={disabled}
                    variant="contained"
                    href={link}
                    target="_blank"
                    style={{ marginRight: '16px', textAlign: 'center' }}
                    onClick={loadCompleted}
                  >
                    {t('scorePage.downloadButton')}
                    <SvgIcon component={download} color="white" style={{ marginLeft: '16px' }} />
                  </Button>
                  {isLti && lessonLength > 1 && !lastLesson && (
                    <Button variant="contained" onClick={nextLesson} style={{ textAlign: 'center' }}>
                      {t('scorePage.nextLesson')}
                    </Button>
                  )}
                </>
              )}
              {fail && !isLti && (
                <Button variant="contained" onClick={loadCompleted} style={{ textAlign: 'center' }}>
                  {t('scorePage.goto')}
                </Button>
              )}
              {fail && isLti && !cantDoAnotherAttempt && (
                <Button variant="contained" onClick={restartCourse} style={{ textAlign: 'center' }}>
                  {t('scorePage.retakeBtn')}
                </Button>
              )}
            </CardActions>
            {success && !isLti && (
              <Box style={{ textAlign: 'center' }}>
                <Button
                  id="course-overview-back-button"
                  aria-label=""
                  onClick={loadCompleted}
                  startIcon={<ArrowBackIcon />}
                  sx={{ textTransform: 'inherit' }}
                >
                  {t('scorePage.GoBack')}
                </Button>
              </Box>
            )}
          </Card>
        </Box>
      </Box>
      <CoursePointsAlert open={showPopup} msg="msg-5" handleClose={() => setShowPopup(false)} showConfirm btnBackCaption={t('ok')} />
    </>
  );
};

export default observer(ScorePage);
