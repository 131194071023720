import { Box, Chip, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { RouterLink } from 'mobx-state-router';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ResendInvitationButton } from './ResendInvitationButton';
import { RevokeButton } from './RevokeButton';

export const ListItem = ({ assistant, onAction }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const assistantFullName = `${assistant.first_name ?? assistant.invitationdata?.first_name ?? assistant.initials ?? ''} ${
    assistant.last_name ?? assistant.invitationdata?.last_name ?? ''
  }`;

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: 2,
        alignItems: 'center',
        borderRadius: 4,
        mb: 2,
        mt: 2
      }}
    >
      <Grid item xs={12} sm={2} md={2}>
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 900 }}>
            {t('assistant.table.name')}
          </Typography>
        </Box>
        {assistant.uid && assistant.opencourses > 0 ? (
          <Box sx={{ '& a': { color: (sxTheme) => sxTheme.palette.primary.main } }}>
            <RouterLink routeName="LmsStudentDetail" params={{ id: assistant.uid }}>
              <Typography variant="body2">{assistantFullName}</Typography>
            </RouterLink>
          </Box>
        ) : (
          <Typography variant="body2">{assistantFullName}</Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 900 }}>
            {t('assistant.table.email')}
          </Typography>
        </Box>
        <Typography variant="body2">{assistant.mail ?? assistant.invitationdata?.email}</Typography>
      </Grid>
      <Grid item xs={12} sm={2} md={2} sx={{ textAlign: { xs: 'left', sm: 'center' } }}>
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 900 }}>
            {t('assistant.table.courses')}
          </Typography>
        </Box>
        <Typography variant="body2">{assistant.opencourses}</Typography>
      </Grid>
      <Grid item xs={12} sm={2} md={2} sx={{ textAlign: { xs: 'left', sm: 'center' } }}>
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 900 }}>
            {t('assistant.table.status')}
          </Typography>
        </Box>
        <Chip
          variant="error"
          label={assistant.subscription_status === 'active' ? t('assistant.table.status1') : t('assistant.table.status2')}
          color={assistant.subscription_status === 'active' ? 'success' : 'warning'}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
        {assistant.subscription_status === 'pending' && (
          <ResendInvitationButton
            data={{
              email: assistant.invitationdata.email,
              first_name: assistant.invitationdata.first_name,
              last_name: assistant.invitationdata.last_name,
              contract_id: assistant.assistant_container_nid
            }}
            onSuccess={onAction}
          />
        )}
        <RevokeButton
          deleteType={assistant.subscription_status === 'active' ? 'delete' : 'revoke'}
          contractId={assistant.assistant_container_nid}
          subscriptionId={assistant.subscription_nid}
          onSuccess={onAction}
        />
      </Grid>
    </Grid>
  );
};

ListItem.propTypes = {
  assistant: PropTypes.any,
  onAction: PropTypes.func
};
