import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { Button } from '../components';

export const FilterButton = ({ children, onClick, isActive }) => {
  const theme = useTheme();

  return (
    <Button
      sx={{
        backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        color: isActive ? 'white' : theme.palette.primary.main,
        '&:hover': { color: 'white' }
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

FilterButton.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func
};
