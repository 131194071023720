import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVtbOverview } from '../hooks/useVtbOverview';
import { EventTypeTitleHead } from '../EventTypeTitleHead/EventTypeTitleHead';
import { RegisterEventDialogBox } from './RegisterEventDialogBox';
import { LocationRow } from './LocationRow/LocationRow';
import { EventTypeDescriptionDialog } from './EventTypeDescriptionDialog/EventTypeDescriptionDialog';
import { useEventTypeDescriptions } from './useEventTypeDescriptions';

const MidContentSection = ({ showOnlyAvailable, year }) => {
  const [selectedData, setSelectedData] = useState(null);
  const { t } = useTranslation('vtb');
  const [descriptionDialogId, setDescriptionDialogId] = useState(false);

  const { data, refetch } = useVtbOverview({ year });
  const { data: descriptionData } = useEventTypeDescriptions();

  if (!data) {
    return (
      <Box sx={{ width: '100%', backgroundColor: 'white', height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  const filteredEvents = showOnlyAvailable ? data.events.filter((event) => !event.isEnrollmentClosed) : data.events;

  const locationIds = [...new Set(filteredEvents.map((vtbEvent) => vtbEvent.relationships.location.data.id))];
  const eventTypeIds = [...new Set(filteredEvents.map((vtbEvent) => vtbEvent.relationships.event_type.data.id))];

  const filteredLocations = showOnlyAvailable ? data.locations.filter((location) => locationIds.includes(location.id)) : data.locations;

  const filteredEventTypes = showOnlyAvailable
    ? data.eventTypes.filter((eventType) => eventTypeIds.includes(eventType.id))
    : data.eventTypes;

  if (data.events.length < 1) {
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 1, backgroundColor: 'white', borderRadius: 2, padding: 4, overflowX: 'auto' }}
      >
        <Typography variant="body1" sx={{ fontSize: 20, fontWeight: 500, textAlign: 'center', m: 4 }}>
          {t('noEvents')}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <RegisterEventDialogBox
        selectedData={selectedData}
        onClose={async () => {
          setSelectedData(null);
          await refetch();
        }}
      />
      <EventTypeDescriptionDialog onClose={() => setDescriptionDialogId(null)} selectedData={descriptionData?.[descriptionDialogId]} />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 1, backgroundColor: 'white', borderRadius: 2, padding: 4, overflowX: 'auto' }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box
            sx={{
              flex: '0 0 250px',
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant="body1" sx={{ fontSize: 22, fontWeight: 800 }}>
              Meetings {year}
            </Typography>
          </Box>
          {filteredEventTypes.map((eventType) => (
            <EventTypeTitleHead key={eventType.id} eventType={eventType} onInfoClick={setDescriptionDialogId} />
          ))}
        </Box>
        {filteredLocations.map((location) => (
          <LocationRow
            eventSignupIds={data.eventSignupIds}
            location={location}
            key={location.id}
            eventTypes={filteredEventTypes}
            vtbEvents={filteredEvents}
            onEventClick={setSelectedData}
          />
        ))}
      </Box>
    </>
  );
};

MidContentSection.propTypes = {
  showOnlyAvailable: PropTypes.bool,
  year: PropTypes.number
};

export default MidContentSection;
