/* eslint-disable react/no-danger */
import { useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import inRange from 'lodash/inRange';
import Numerical from 'components/widgets/Numerical';
import { LinearProgress } from '@mui/material';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import { useTranslation } from 'react-i18next';
import { decideUnitPos } from 'helpers/Helpers';
import useWidget from './useWidget';

const NumericalQuestion = (props) => {
  const { store, widgetCode } = props;
  const { t } = useTranslation('widget');
  const { question, widget, allowedTries, PlayWidget, answerDisabled } = useWidget(props);
  const widgetAttr = widget.attributes;
  const touched = useRef(!!question.last_response);
  let lastResponse = question.last_response ? Number(question.last_response) : 0;
  if (store.canSeeAnswers) {
    lastResponse = widgetAttr.options.value;
  }

  const answer = useRef(lastResponse);
  const isDisabled = answerDisabled || question.last_correct || question.tries >= allowedTries || false;

  const isRange = widgetAttr.options.valueEnd > widgetAttr.options.value;

  const checkRange = () => inRange(answer.current, widgetAttr.options.value, widgetAttr.options.valueEnd);
  const checkStatic = () => Number(answer.current) === Number(widgetAttr.options.value);
  const checkLess = () => Number(answer.current) < Number(widgetAttr.options.value);

  const handleInput = (value) => {
    answer.current = value;
  };

  const validateAnswer = () => {
    if (answer.current === 0) return false;
    const isCorrect = isRange ? checkRange() : checkStatic();
    return { value: answer.current.toString(), correct: isCorrect };
  };

  function getOptionsFeedback() {
    if (isRange && checkRange()) return 'feedbackCorrect';
    if (isRange && !checkRange()) return checkLess() ? 'feedbackLess' : 'feedbackMore';
    return checkStatic() ? 'feedbackCorrect' : false;
  }

  // show feedback after an answer
  const showFeedback = () => {
    if (!question.tries) return null;
    const isCorrect = isRange ? checkRange() : checkStatic();
    const respAnswer = isCorrect ? t('Correct') : t('Incorrect');
    const tryAgain = !isCorrect && question.tries < allowedTries ? t('Try again') : '';
    const correctAnswer = isRange
      ? `${t('tussen')} ${decideUnitPos(widgetAttr.options.unit, widgetAttr.options.value)} ${t('en')} ${decideUnitPos(
          widgetAttr.options.unit,
          widgetAttr.options.valueEnd
        )}`
      : widgetAttr.options.value;
    const showCorrectAnswer = !isCorrect && question.tries === allowedTries ? `${t('The correct answer is')} ${correctAnswer}` : '';
    const optionsFeedback = getOptionsFeedback();

    const respAnswerFb = widgetAttr.options[optionsFeedback] || widgetAttr.feedbackFalse;

    return (
      <div className="feedback_block">
        <b dangerouslySetInnerHTML={{ __html: respAnswer }} className={isCorrect ? 'correct-answer' : 'wrong-answer'} />
        <div dangerouslySetInnerHTML={{ __html: respAnswerFb }} />
        <div className="feedback" dangerouslySetInnerHTML={{ __html: showCorrectAnswer }} />
        <div dangerouslySetInnerHTML={{ __html: widgetAttr.feedback }} />
        {/* show general feedback (always show) */}
        {showCorrectFeedback()}
        <div dangerouslySetInnerHTML={{ __html: tryAgain }} className="retry" />
      </div>
    );
  };

  // On second wrong answer show correct feedback (works for multiple question types)
  function showCorrectFeedback() {
    if (question.tries < allowedTries && !question.last_correct) return null;
    const correctFeedback = widgetAttr.options.feedback;

    return correctFeedback;
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget touched={touched.current} validateAnswer={validateAnswer} showFeedback={showFeedback} {...props}>
        <Numerical
          answer={answer}
          value={lastResponse}
          options={widgetAttr.options}
          handleInput={handleInput}
          disabled={isDisabled}
          {...props}
        />
      </PlayWidget>
    </>
  );
};

NumericalQuestion.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  question: PropTypes.object,
  store: PropTypes.object
};

export default observer(NumericalQuestion);
