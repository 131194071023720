import { ThemeBrandTitle } from 'helpers/Helpers';
import { getEwapi } from 'state/RoutesActions';
import { getLessonPlay } from 'state/models/LessonPlay';

const BrandTitle = ThemeBrandTitle();

export const feedbackLesRoute = {
  name: 'feedback',
  pattern: '/:productName/:product/:packageId/course/:courseId/lesson/:lessonId/feedback/:courseFeeback',
  meta: { title: `%%introduction.metatitle%% | ${BrandTitle}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    await getEwapi(fromState, toState, routerStore);
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    await getLessonPlay(store, toState.params.courseId, toState.params.lessonId);
  }
};
