import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Box, LinearProgress } from '@mui/material';

// renders a loading bar
const LoadingContent = ({ loading = false }) => {
  if (!loading) return false;
  return (
    <Box sx={{ width: '100%', zIndex: '10001' }}>
      <LinearProgress />
    </Box>
  );
};
LoadingContent.propTypes = {
  loading: PropTypes.bool
};
export default observer(LoadingContent);
