import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
/* MUI - Icons */
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';

// renders the hide course button
const HideCourse = ({ id }) => {
  const store = useStore();
  const { t } = useTranslation();
  if (store.routerState.routeName.includes('specialAccess')) return null;

  const handleHide = (courseId) => {
    const remodelData = store.hiddenIds.map((course) => ({
      type: 'course',
      id: course
    }));

    store.patchData(store.hidden, {
      id: store.hidden.uuid,
      type: 'hidden_courses',
      relationships: {
        hidden_courses: {
          data: [
            ...remodelData,
            {
              type: 'course',
              id: courseId
            }
          ]
        }
      }
    });
  };

  const hideCourses = (courseId) =>
    !store.hidden
      ? store.postData({
          type: 'hidden_courses',
          attributes: {
            title: 'hidden_courses'
          },
          relationships: {
            hidden_courses: {
              data: [
                {
                  id: courseId,
                  type: 'course'
                }
              ]
            }
          }
        })
      : handleHide(courseId);

  return (
    <Box className="hideCourse">
      <Tooltip title={t('hideCourse')} arrow key={id}>
        <IconButton
          sx={{ backgroundColor: 'primary.main', color: 'white', '&:hover': { backgroundColor: 'primary.main' } }}
          className="test"
          onClick={(event) => {
            event.stopPropagation();
            hideCourses(id);
          }}
        >
          <VisibilityOffIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

HideCourse.propTypes = {
  id: PropTypes.string
};
export default observer(HideCourse);
