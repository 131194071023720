import { Alert, Snackbar, IconButton, Box } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

/*
In summary, the ToastItem component is used to create customizable and dismissible toast messages in a web application. 
It listens to changes in a global state for toast notifications and provides a consistent way to display feedback or information to the user.
*/
const ToastItem = (props) => {
  const { message, variant, courseIntroduction, horizontal, vertical } = props;

  const store = useContext(Store);

  const [open, setOpen] = useState(store.isToastvisible);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    store.isToastvisible && setOpen(true);
  }, [store.toastStates, store.isToastvisible]);

  const handleClose = () => {
    setOpen(false);
    store.hideToast();
  };

  const icons = {
    success: <CheckCircleIcon />,
    info: <InfoIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={1500}
      onClose={handleClose}
      anchorOrigin={{
        horizontal: horizontal || 'right',
        vertical: vertical || 'top'
      }}
      className={courseIntroduction && 'bottom_0'}
    >
      <Alert
        severity={variant}
        className={`snackbar-${variant}`}
        icon={false}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Box display="flex" gap="3%" alignItems="center">
          <Box display="flex">{icons[variant]} </Box>
          <Box>{message}</Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};

ToastItem.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  courseIntroduction: PropTypes.bool,
  horizontal: PropTypes.string,
  vertical: PropTypes.string
};
export default observer(ToastItem);
