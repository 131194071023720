import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../hooks';
import { createURLParams } from './createURLParams';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is verplicht').email('Email heeft niet het correcte formaat'),
  first_name: Yup.string().required('Voornaam is verplicht'),
  last_name: Yup.string().required('Achternaam is verplicht')
});

export const InviteUserDialog = ({ open, handleClose, contractId, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const store = useStore();
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: { email: '', first_name: '', last_name: '' },
    resolver: yupResolver(validationSchema)
  });

  const handleInvite = async (data) => {
    setIsLoading(true);

    const params = {
      ...data,
      contract_id: contractId
    };

    try {
      await window.authedClient.post(`ewapi/assistant_manager/invite?${createURLParams(params)}`);
      await store.showToast({ message: t('assistant.invite.msgSuccess'), variant: 'success' });
      await onSuccess();
      await reset();
    } catch (err) {
      await store.showToast({ message: t('assistant.invite.msgError'), variant: 'error' });
    }

    setIsLoading(false);
  };

  return (
    <Dialog open={open} keepMounted onClose={handleClose}>
      <form onSubmit={handleSubmit(handleInvite)} noValidate>
        <DialogTitle>{t('assistant.invite.title')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    label={t('assistant.invite.email')}
                    type="email"
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message ?? ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="first_name"
                render={({ field }) => (
                  <TextField
                    label={t('assistant.invite.firstName')}
                    type="text"
                    {...field}
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message ?? ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="last_name"
                render={({ field }) => (
                  <TextField
                    label={t('assistant.invite.lastName')}
                    type="text"
                    {...field}
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message ?? ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('assistant.invite.btnCancel')}</Button>
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            {t('assistant.invite.btnInvite')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

InviteUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  contractId: PropTypes.any
};