import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { Store } from 'state/ContextStore';

/* MUI */
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Popper, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { urlify } from 'state/Utils';
import LoaderWithBackdrop from '../loading/LoaderWithBackdrop';

const BtnLinkedCourse = () => {
  const store = useContext(Store);
  const { t } = useTranslation('editCourseForm');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  //
  const linked = store?.routerStore?.routerState?.queryParams?.type === 'linked';
  if (!linked) return null;

  const btnLink = {
    btnTooltip: t('editCourse.btnDeleteLink.tooltip'),
    btnTitle: t('editCourse.btnDeleteLink.btnTitle'),
    dialogTitle: t('editCourse.btnDeleteLink.dialogTitle'),
    dialogMesssage: t('editCourse.btnDeleteLink.dialogMessage'),
    dialogBtnCancel: t('editCourse.btnDeleteLink.btnCancel'),
    dialogBtnDelete: t('editCourse.btnDeleteLink.btnDelete')
  };

  const toast = {
    success: { content: 'Courses are updated', title: 'Yeah Great', type: 'success' },
    error: { content: 'er is iets mis gegaan', title: 'Error', type: 'error' },
    default: { timeOut: 5000, variant: 'filled' }
  };

  // DELETE LINKED COURSE
  const handleDelete = async () => {
    setIsSaving(true);
    const { courseContainer } = store.courseEdit;
    const { courseuuid, packageNid, containerNid } = store.params;
    //
    const courses = [...JSON.parse(JSON.stringify(courseContainer.relationships.courses.data))];
    const indexCurrentCourse = courses.findIndex((course) => course.id === courseuuid);
    courses.splice(indexCurrentCourse, 1);
    //
    const newCourseContainer = {
      id: courseContainer.id,
      type: courseContainer.type,
      relationships: { courses: { data: courses } }
    };

    try {
      await store.courseEdit.lmsApi.patchpost(newCourseContainer);
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
      const routerParams = {
        params: { package: urlify(store.params.package.toLowerCase()), packageNid, containerNid }
      };
      setIsSaving(false);
      store.routerStore.goTo('adminDashboardCourses', routerParams);
    } catch (error) {
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
      setIsSaving(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  return (
    <Box component="span">
      <LoaderWithBackdrop open={isSaving} />
      <Tooltip title={btnLink.btnTooltip} placement="bottom" arrow>
        <Button
          color="error"
          sx={{ borderRadius: '10px' }}
          variant="outlined"
          endIcon={<LinkOffIcon color="error" />}
          onClick={handleClick}
        >
          {btnLink.btnTitle}
        </Button>
      </Tooltip>
      <Popper open={open} anchorEl={anchorEl} placement="bottom" transition sx={{ zIndex: 10 }}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <Paper>
              <DialogTitle sx={{ fontSize: '18px' }} id="dialogDeleteLink">
                {btnLink.dialogTitle}
              </DialogTitle>
              <DialogContent>
                <Typography gutterBottom>{btnLink.dialogMesssage}</Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button variant="text" onClick={handleClick}>
                  {btnLink.dialogBtnCancel}
                </Button>
                <Button variant="contained" autoFocus onClick={handleDelete}>
                  {btnLink.dialogBtnDelete}
                </Button>
              </DialogActions>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default observer(BtnLinkedCourse);
