import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';

/* MUI */
import { Box, Typography, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { uniqueId } from 'lodash';

const SpecialAccessSection = (props) => {
  const { lessons } = props;
  const store = useStore();
  const { t } = useTranslation();

  const toLesson = (lesson) => {
    store.routerStore.goTo('lessonPlay', {
      params: {
        product: store.params.product,
        productName: store.params.productName,
        packageId: store.params.packageId,
        courseId: store.params.courseId,
        lessonId: lesson.nid.toString(),
        pageNo: '0'
      }
    });
  };

  return (
    <Box className="course_lessons">
      <Typography variant="h2" component="h2" fontSize="20px" color="primaryBlue.main" fontWeight="500">
        {t('introduction.sidebar.specialAccess')}
      </Typography>
      {lessons &&
        lessons.map((lesson) => (
          <Box key={uniqueId('lesson-id')} className="lessons_display" onClick={() => toLesson(lesson)} sx={{ cursor: 'pointer' }}>
            <Box paddingLeft="10px" color="black">
              {lesson.lesson_type}
            </Box>
            <Box className="lesson_button">
              <IconButton>
                <ArrowForwardIosIcon color="black" sx={{ opacity: '0.5' }} />
              </IconButton>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

SpecialAccessSection.propTypes = {
  lessons: PropTypes.any
};

export default observer(SpecialAccessSection);
