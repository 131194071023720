import ewiseTheme from './EwiseTheme';
import cmeTheme from './CmeTheme';
import peTheme from './PeTheme';
import poTheme from './PoTheme';

const themeMap = {
  ewiseTheme,
  cmeTheme,
  peTheme,
  poTheme
};

export function getThemeByName(theme) {
  return themeMap[theme];
}
