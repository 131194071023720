import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Link, MenuItem } from '@mui/material';
import { isStaging } from 'helpers/Helpers';

const MenuItemAdminPanel = () => {
  const { t } = useTranslation();

  return (
    <MenuItem key="adminPanel" id="adminPanel">
      <Link
        href={isStaging() ? 'https://app.aws-staging.e-wise.it' : 'https://app.admin.po-online.nl'}
        target="_blank"
        style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        rel="noreferrer"
      >
        {t('Admin panel')}
      </Link>
    </MenuItem>
  );
};

export default observer(MenuItemAdminPanel);
