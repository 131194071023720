/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
/* MUI */
import { Box, Skeleton } from '@mui/material';
import { convertBlobString2File } from 'helpers/image.helper';

// renders the course image to be displayed in the preview dialog
const CourseImage = ({ previewValues }) => {
  let src = false;
  const coursePhoto = previewValues?.lessonContainer?.relationships?.field_coursephoto;
  const iscoursePhotoObject = coursePhoto instanceof Object;
  if (!iscoursePhotoObject && coursePhoto.includes('http')) {
    src = coursePhoto;
  }
  src = coursePhoto?.data ? convertBlobString2File(coursePhoto.data) : src;

  return (
    <Box className="preview_image_course">
      {src && <img src={src} width="100%" alt="test2" />}
      {!src && <Skeleton variant="rectangular" width="100%" height={200} />}
    </Box>
  );
};

CourseImage.propTypes = {
  previewValues: PropTypes.object
};

export default observer(CourseImage);
