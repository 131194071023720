/* Core */
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';

const InfoCardText = (props) => {
  const { title, text } = props;

  return (
    <>
      <CardContent>
        <Typography sx={{ fontSize: 13 }} align="center" color="text.error" gutterBottom>
          {title}
        </Typography>
        <Typography sx={{ fontSize: 'calc(0.75em + 1vw)' }} variant="h5" component="div" align="center">
          {text}
        </Typography>
      </CardContent>
    </>
  );
};

InfoCardText.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

export default observer(InfoCardText);
