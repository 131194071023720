export const detachRelation = (parentData, relationName, relationIds) => {
  const ids = Array.isArray(relationIds) ? relationIds : [relationIds];

  if (!parentData?.relationships) {
    return parentData;
  }

  if (!Object.hasOwn(parentData.relationships, relationName)) {
    return parentData;
  }

  const relation = parentData.relationships[relationName];
  const relationData = relation?.data || null;

  if (!relationData) {
    return parentData;
  }

  const hasRelation = relationData.map((d) => ids.includes(d.id));

  if (!hasRelation) {
    return parentData;
  }

  return {
    ...parentData,
    relationships: {
      ...parentData.relationships,
      [relationName]: {
        ...relation,
        data: relationData.filter((d) => !ids.includes(d.id))
      }
    }
  };
};
