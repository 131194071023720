import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import PropTypes from 'prop-types';

/* MUI - Icons */
import FavoriteIcon from '@mui/icons-material/Favorite';
// import ThumbUpIcon from '@mui/icons-material/ThumbUp';

/* MUI */
import { IconButton, Tooltip } from '@mui/material';

import CalculateProgress from 'pages/course/components/Common/CalculateProgress';
import { isCourseFailed } from 'helpers/Helpers';
import { useState } from 'react';

// TODO: !!! NEEDS REFACTORING !!!!
const CourseReactions = ({ nid, id, lessons, courseIntroduction }) => {
  const store = useStore();
  const { t } = useTranslation();
  // eslint-disable-next-line camelcase
  const { courseResults, lesson_results } = store;

  const isFinished = courseResults.isCourseFinished(nid);
  const isCourseStarted = !isFinished && store.courseResults.isCourseStarted(nid);
  const isCourseFavorite = store.favorites && store.favorites.isCourseFavorite(nid);
  const [isSave, setIsSave] = useState(false);
  const isFailed = isCourseFailed(lesson_results, courseResults, nid);
  let storeFavourite = null;

  const toggleFavorites = async (courseId, courseNid) => {
    const removeFavorite = store.favoriteIds.filter((course) => course !== courseId);

    const removedFavorite = removeFavorite.map((course) => ({
      type: 'course',
      id: course
    }));

    const remodelData = store.favoriteIds.map((course) => ({
      type: 'course',
      id: course
    }));

    setIsSave(true);

    if (!store.favorites.isCourseFavorite(courseNid)) {
      storeFavourite = await store.patchData(store.favorites, {
        id: store.favorites.uuid,
        type: 'course_favorites',
        relationships: {
          field_favorite_courses: {
            data: [
              ...remodelData,
              {
                type: 'course',
                id: courseId
              }
            ]
          }
        }
      });

      if (storeFavourite) {
        setIsSave(false);
      }
    } else {
      storeFavourite = await store.patchData(store.favorites, {
        id: store.favorites.uuid,
        type: 'course_favorites',
        relationships: {
          field_favorite_courses: {
            data: removedFavorite
          }
        }
      });
      if (storeFavourite) {
        setIsSave(false);
      }
    }
    return storeFavourite;
  };

  const handleFavorite = (courseId, courseNid) => {
    setIsSave(true);
    if (!store.favorites) {
      store.postData({
        type: 'course_favorites',
        attributes: {
          title: 'course_favorites'
        },
        relationships: {
          field_favorite_courses: {
            data: [
              {
                id: courseId,
                type: 'course'
              }
            ]
          }
        }
      });
    } else {
      toggleFavorites(courseId, courseNid);
    }
  };

  // IF SPECIAL ACCESS VIEW
  if (store.courses.isSpecialAccessView) {
    return (
      <>
        {(isFinished || isCourseStarted) && !courseIntroduction && (
          <CalculateProgress id={nid} uuid={id} lessons={lessons} isCourseStarted={isCourseStarted} isFinished={isFinished} />
        )}
      </>
    );
  }
  return (
    <>
      <Tooltip
        title={
          isCourseFavorite
            ? t('course.tooltips.favourite.remove', 'Remove from favourites')
            : t('course.tooltips.favourite.add', 'Add to favourites')
        }
        arrow
      >
        <IconButton
          aria-label="add to favorites"
          className="favourite_icon"
          onClick={() => handleFavorite(id, nid)}
          disabled={isSave}
          style={isSave ? { transform: 'scale(1)', animation: 'pulse 1s infinite' } : {}}
        >
          <FavoriteIcon color={isCourseFavorite ? 'favoriteRed' : ''} />
        </IconButton>
      </Tooltip>
      {(isFinished || isCourseStarted) && !courseIntroduction && !isFailed && (
        <CalculateProgress id={nid} uuid={id} lessons={lessons} isCourseStarted={isCourseStarted} isFinished={isFinished} />
      )}
    </>
  );
};

CourseReactions.propTypes = {
  nid: PropTypes.number,
  id: PropTypes.string,
  lessons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  courseIntroduction: PropTypes.bool
};

export default observer(CourseReactions);
