import { observer } from 'mobx-react';
/* MUI */
import { Box } from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

/*
In summary, the DialogWidgetsTable component fetches and displays widgets related to a specific lesson content. 
It provides users with a searchable and expandable table view of widget data, making it easy to navigate and review the available widgets. 
The component also offers a high level of customization for styling the Material Table and its components.
*/
const DialogWidgetsTabelNoOdt = () => (
  <Box className="questionWidgets" sx={sxFlexColumn}>
    <Box>
      <DoNotDisturbAltIcon sx={{ fontSize: 40 }} />
      <Box>Er is geen odt</Box>
    </Box>
  </Box>
);

export default observer(DialogWidgetsTabelNoOdt);

// CUSTOM SX _______________________________________________________________________________________
const sxFlexColumn = { display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' };
