import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';

const MenuItemBackend = ({ handleMenuSelect }) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      key="admin"
      id="amdin"
      onClick={handleMenuSelect({
        routeName: 'gotoadmin',
        title: 'Backend',
        params: {}
      })}
    >
      {t('Backend (admin)')}
    </MenuItem>
  );
};

MenuItemBackend.propTypes = {
  handleMenuSelect: PropTypes.func.isRequired
};

export default observer(MenuItemBackend);
