import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import ComponentProps from 'helpers/ComponentProps';

// renders a page title with a bottom border
const PageTitle = (props) => {
  const { title } = props;
  const style = ComponentProps();

  return <Typography {...style.formTitle}>{title}</Typography>;
};

PageTitle.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object,
  theme: PropTypes.object
};

export default PageTitle;
