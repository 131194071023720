/* eslint-disable camelcase */
/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
/* Custom styling */
import { Box, Grid } from '@mui/material';
import { getCourseCardProps } from 'helpers/Helpers';

/* Custom components */
import CourseGridCard from 'pages/course/components/CourseOverview/Card/CourseGridCard';
import { uniqueId } from 'lodash';

const CourseList = ({ courseList }) => (
  // const gridItemXs = 12;
  // const gridItemSm = 4;
  // const gridItemMd = 4;
  // const gridItemLg = 6;
  // const gridItemXl = 3;
  <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
      {courseList.map((course) => {
        const propsBasicCard = getCourseCardProps(course);

        return (
          <Grid key={uniqueId('course-by-key')} data-testid="courseListItem" item xs={12} sm={6} md={4} xl={4} lg={3}>
            <CourseGridCard {...propsBasicCard} />
          </Grid>
        );
      })}
    </Grid>
  </Box>
);
CourseList.propTypes = {
  courseList: PropTypes.array
};
export default observer(CourseList);
