import MainLayout from 'pages/mainLayout/MainLayout';
import SearchOverview from './SearchOverview';

const searchOverviewViewMap = {
  searchOverview: (
    <MainLayout footer title="searchoverview.title">
      <SearchOverview />
    </MainLayout>
  )
};

export default searchOverviewViewMap;
