import React from 'react';
import { makeStyles } from '@mui/styles';

import { MyCheckbox, MyRadio, MyTextField, MySelect } from 'components/form/RHF';
import { green, red } from '@mui/material/colors';

const Editor = React.lazy(() => import('components/form/RHF/Editor/Editor'));

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexDirection: 'column' },
  isGreen: { color: green[600], '&checked': { color: green[500] } },
  buttonTemp: {
    backgroundColor: red[500],
    '&:hover': { backgroundColor: red[700] },
    '&:disabled': { backgroundColor: red[500], color: 'white' }
  },

  alignRight: { textAlign: 'right' },
  alignLeft: { textAlign: 'left' },
  alignCenter: { textAlign: 'center' },

  tabBar: { background: 'transparent', boxShadow: 'none', zIndex: '1' },
  tabItem: { background: '#f7f7f7', padding: '15px', fontSize: '13px', lineHeight: '15px', minHeight: '400px' },
  tabSelected: { background: '#f7f7f7' },
  tabsIndicator: { backgroundColor: '#760f4e' },

  section1200: { boxShadow: 'none', padding: '15px', maxWidth: '1200px', width: '100%', margin: '0 auto' },
  paperFlat: { boxShadow: 'none', padding: '15px' },
  paperFlatPadding: { boxShadow: 'none', padding: '15px' },
  paperFMb: { boxShadow: 'none', padding: '15px', marginBottom: '15px' },
  paperNoPadding: { boxShadow: 'none' },
  paperFlatSmoke: { boxShadow: 'none', padding: '15px', background: 'whitesmoke' },
  paperBoxSmoke: { padding: '15px', background: 'whitesmoke' },
  paperCategories: { padding: '15px' },
  paperLegenda: { padding: '15px' },

  formTitle: { color: '#67123f', fontWeight: '500', marginTop: '10px', marginBottom: '20px' },

  pageTitle: { color: '#67123f', fontWeight: '500', marginTop: '10px' },

  subTitle: { color: '#67123f', fontSize: '80%', fontStyle: 'italic' },

  gridTitle: { width: '100%' },

  bordered: { border: '1px solid silver', borderRadius: '5px', margin: '0px', width: '100%' },

  form: { marginTop: '10px' },
  formControl: { marginLeft: '8px', width: '100%', padding: '15px' },
  formDivider: { margin: '5px 0px' },
  helperText: { marginTop: '0px' },
  textField: { width: '100%', fontSize: '80%!important' },
  multipleSelect: { '&:focus': { background: 'white!important' } },

  div20: { display: 'inline-block', width: '20%', float: 'right' },
  div80: { display: 'inline-block', width: '75%', marginRight: '4%' },

  fabAdd: {
    background: theme?.palette?.primary?.main,
    color: 'white',
    height: '40px',
    width: '40px',
    minHeight: '30px',
    marginLeft: '15px',
    '&:hover': { background: theme?.palette?.primary?.light }
  },
  fabAddSm: {
    background: theme?.palette?.primary?.main,
    color: 'white',
    height: '40px',
    width: '40px',
    minHeight: '30px',
    marginRight: '10px',
    '&:hover': { background: theme?.palette?.primary?.light }
  },
  fabAddLesson: { background: '#460026', height: '30px', width: '30px', minHeight: '30px', marginLeft: '15px' },
  actionBar: {
    borderTop: '1px solid silver',
    position: 'fixed',
    bottom: '0px',
    left: '0',
    right: '0',
    background: '#fbfbfbf7',
    padding: '0px 20px',
    zIndex: '10',
    margin: '0'
  },

  actionBarModal: {
    borderTop: '1px solid silver',
    position: 'absolute',
    bottom: '0px',
    left: '0',
    right: '0',
    background: '#fbfbfbf7',
    padding: '0px 20px',
    zIndex: '10',
    margin: '0'
  },

  dialogActionBar50: {
    borderTop: '1px solid silver',
    position: 'fixed',
    bottom: '0px',
    left: 'inherit',
    right: '0',
    width: '50%',
    background: '#fbfbfbf7',
    padding: '0px 20px',
    zIndex: '10',
    margin: '0'
  }
}));

export default function ComponentProps() {
  const classes = useStyles();
  return {
    number: {
      component: MyTextField,
      type: 'number',
      className: classes.textField,
      variant: 'outlined',
      fullWidth: true,
      margin: 'dense'
    },
    text: {
      // component: MyTextField,
      className: classes.textField,
      variant: 'outlined',
      fullWidth: true,
      margin: 'dense',
      InputLabelProps: { shrink: true }
    },
    // input: {
    //   component: MyTextField,
    //   className: classes.textField,
    //   variant: 'outlined',
    //   fullWidth: true,
    //   margin: 'dense',
    //   InputLabelProps: { shrink: true }
    // },
    textarea: { className: classes.textField, variant: 'outlined', fullWidth: true, margin: 'dense' },
    checkbox: { component: MyCheckbox, className: classes.checkBox },
    radio: { component: MyRadio, className: classes.checkBox },
    editor: { component: Editor, height: '200' },
    select: { component: MySelect, className: classes.select, variant: 'outlined', placeholder: 'select status' },

    formTitle: { variant: 'h4', className: classes.formTitle, color: 'inherit', gutterBottom: true },
    pageTitle: { variant: 'h4', className: classes.pageTitle, color: 'inherit', gutterBottom: true },
    subTitle: { component: 'span', variant: 'h5', className: classes.subTitle, color: 'inherit', gutterBottom: true },

    section1200: { className: classes.section1200 },
    div: { component: 'div' },
    div20: { component: 'div', className: classes.div20 },
    div80: { component: 'div', className: classes.div80 },

    root: { className: classes.root },
    fabAddSm: { className: classes.fabAddSm },
    fabAdd: { className: classes.fabAdd },
    frm: { className: classes.form },
    frmDivider: { className: classes.formDivider },
    frmCtrl: { className: classes.formControl },
    hlpTxt: { className: classes.helperText },
    ppr: { className: classes.paper },
    pprBoxed: { className: classes.paperLegenda },
    pprFlat: { className: classes.paperFlatPadding },
    pprFMb: { className: classes.paperFMb },
    pprFlatSmoke: { className: classes.paperFlatSmoke },
    pprBoxSmoke: { className: classes.paperBoxSmoke },
    txtRight: { className: classes.alignRight },
    txtLeft: { className: classes.alignLeft },
    txtCenter: { className: classes.alignCenter },
    actionBar: { className: classes.actionBar },
    dialogActionBar50: { className: classes.dialogActionBar50 }
  };
}
