import { useCallback, useEffect, useState } from 'react';

export const useAssistantManager = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(null);

  const refreshData = useCallback(async (offset = 0, search = '', status = 'all') => {
    setIsLoading(true);
    let url = `ewapi/assistant_manager/assistants?limit=50&offset=${offset}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (status === 'pending' || status === 'active') {
      url += `&status=${status}`;
    }
    try {
      const response = await window.authedClient.post(url);
      setData(response.data?.data);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return { data, isLoading, hasError, refreshData };
};
