/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import Dialog from './Dialog';
import { useDisclaimer } from './useDisclaimer';

const DisclaimerDialog = ({ open, onCloseCallback }) => {
  const { t } = useTranslation('curriculum');

  const { setHasReadDisclaimer } = useDisclaimer();

  const onClose = () => {
    setHasReadDisclaimer(true);
    onCloseCallback();
  };

  return (
    <Dialog
      visible={open}
      onClose={onClose}
      action={{
        action: onClose,
        name: t('general.closeBtnLabel')
      }}
    >
      <Box sx={{ padding: '1rem' }}>
        <div dangerouslySetInnerHTML={{ __html: t('general.disclaimerHtml', { joinArrays: '' }) }} />
      </Box>
    </Dialog>
  );
};

DisclaimerDialog.propTypes = {
  open: PropTypes.bool,
  onCloseCallback: PropTypes.func
};

export default DisclaimerDialog;
