import { types as t, getEnv, flow, getSnapshot, destroy, applySnapshot } from 'mobx-state-tree';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { Course, newCourse } from './Course';
import { Lesson } from './Lesson';
import { Author } from './Author';
import { AccreditationLink, newAccreditationLink } from './AccreditationLink';
import { AccreditationOrganisation } from './AccreditationOrganisation';
import { LessonContainer } from './LessonContainer';
import { LessonContentEdit } from './LessonContent';
import { CourseContainer } from './CourseContainer';
import { Category, SpecialAccess } from './BaseModel';
import { startLmsApiClient } from '../api/LmsApi';

export const CourseEdit = t
  .model('CourseEdit', {
    course: t.maybeNull(Course),
    authors: t.maybeNull(t.array(Author)),
    lesson_container: t.maybeNull(LessonContainer),
    lessons: t.maybeNull(t.array(Lesson)),
    lesson_contents: t.maybeNull(t.array(LessonContentEdit)),
    category: t.maybeNull(t.array(Category)),
    category2: t.maybeNull(t.array(Category)),
    special_access: t.maybeNull(t.array(SpecialAccess)),
    accreditation_links: t.maybeNull(t.array(AccreditationLink)),
    organisations: t.maybeNull(t.array(AccreditationOrganisation)),
    courseContainer: t.maybeNull(CourseContainer),
    currentCourse: t.maybeNull(Course),
    toasts: t.optional(t.array(t.frozen()), [])
  })
  .volatile(() => ({
    isCreated: true,
    store: null,
    jsonApi: null,
    // lmsApi: null,
    v_state: null,
    duplicate: {},
    link: [],
    isOpen: false,
    setNewCourse: {},
    isFetching: 'init',
    retrieved: [],
    courses: {},
    editCourse: false,
    ewapi: '',
    toast: { open: false, msg: '', type: 'info' },
    modalLesson: { open: false },
    editTab: 'Introductie',
    newCourseId: '',
    dialogLesson: { open: false, lessonId: '' },
    dialogMenuList: false,
    packageMenuList: {},
    specialAccessMenu: [],
    setCopyCourse: null,
    selectedContainer: null,
    img: false,
    toggleDialogEditWidget: false,
    setEditWidget: {},
    drawerOpen: false,
    currentFilename: '',
    currentLesson: {},
    currentLessonWidgets: 'test',
    currentPackage: null,
    accreditation: {},
    tableTotal: 0,
    lessonWidgets: {},
    dependentCourse: {},
    showSidebarDashboard: 'showSidebarDashboard' in localStorage ? localStorage.getItem('showSidebarDashboard') === 'true' : true,
    showSidebarEdit: 'showSidebarEdit' in localStorage ? localStorage.getItem('showSidebarEdit') === 'true' : true
  }))

  .views((self) => ({
    get newCourse() {
      return newCourse;
    },
    get lmsApi() {
      return startLmsApiClient();
    },

    lessonContentWidgets() {
      return self?.currentLesson?.included.filter((w) => w.type === 'question_widget') || [];
    },
    get newAccreditationLink() {
      return newAccreditationLink;
    },

    get newAccrreditation() {
      return { action: 'Add', points: 0, code: 'ewise', start: '', end: '', unit: '', organisation: '' };
    },

    isNewRecord() {
      return !self.store.params.courseId;
    },

    get filter() {
      return `filter[name][condition][path]=parent.id&filter[name][condition][operator]=CONTAINS&filter[name][condition][value]=`;
    },

    get courseFields() {
      return (
        'fields[lesson_content]=drupal_internal__id,title,lessontext,tagdoc,lesson_content_widgets' +
        '&include=accreditation_links, accreditation_links.accreditation_organisation,' +
        'lesson_container, lesson_container.lessons, lesson_container.lessons.lesson_content'
      );
    },

    get courseEditDataFields() {
      return (
        'fields[lesson_content]=drupal_internal__id,title,tagdoc,lesson_content_widgets' +
        '&include=course_category,course_category2,special_access,' +
        'lesson_container,' +
        'lesson_container.field_coursephoto,' +
        'lesson_container.field_course_header_photo,' +
        'lesson_container.field_course_image_extern,' +
        'lesson_container.dependent_courses,' +
        'lesson_container.lessons,' +
        'lesson_container.lessons.profession,' +
        'lesson_container.lessons.lesson_content,' +
        'lesson_container.field_authors,' +
        'lesson_container.field_reviewers,' +
        'lesson_container.profession'
      );
    },

    getContainerIdFromPackageNid(packageNid) {
      const _package = {
        49: '2a80c909-8d51-422e-bf92-941e14be8543',
        8279: '193134c7-0920-471a-a8ae-5a323cd35952',
        8227: '4c874638-5438-4c89-a9d9-595b510f0af8',
        8221: '012bc8d4-beb8-4b6c-a2fc-8a9e9418f130'
      };
      return _package[packageNid];
    },

    categoryId() {
      return self.store.package.courseContainer.relationships.category.data.id;
    },

    category2Id() {
      return self.store.package.courseContainer.relationships.category2.data.id;
    },

    accreditations() {
      return self.accreditation_links;
    },

    currentLessonByNid(nid) {
      return self.lessons.find((l) => l.attributes.drupal_internal__nid === nid);
    },
    getAccrPoint(accr) {
      return accr.attributes.accreditation_points;
    },
    getAccrUnit(accr) {
      return accr.relationships.accreditation_organisation.data.id.attributes.accr_unit;
    },

    currentLessonContentId(nid) {
      if (self.currentLessonByNid(nid) && self.currentLessonByNid(nid).relationships.lesson_content.data) {
        return self.currentLessonByNid(nid).relationships.lesson_content.data.id;
      }
      return null;
    },

    canPlayLesson(nid) {
      if (!self.currentLessonContentId(nid)) return false;
      const lessonContent = self.lesson_contents.find((l) => l.id === self.currentLessonContentId(nid));
      return !!lessonContent.relationships.tagdoc.data;
    },
    get currentLessonContainer() {
      return self.currentCourse.included.find((l) => l.type === 'lesson_container');
    },
    newLessonContent(title) {
      return {
        type: 'lesson_content',
        attributes: {
          title: `Lesson Content ${title}`
        },
        relationships: {
          lesson_content_widgets: {
            data: []
          },
          tagdoc: {
            data: null
          }
        }
      };
    },
    currentLessonContent(lesson) {
      const title = lesson?.attributes?.title;
      const lessonContentId = lesson?.relationships?.lesson_content?.data?.id;
      const lessonContent =
        (lessonContentId && self.currentCourse.included.find((l) => l.id === lessonContentId)[0]) || self.newLessonContent(title);
      return lessonContent;
    }
  }))

  .actions((self) => ({
    afterCreate() {
      self.v_state = 'created';
      self.store = getEnv(self).store;
      self.store.setStoreValue('courseEditState', 'done');
    },
    // ___ SET STORE VALUES ________________________
    setStoreValue(key, value) {
      self[key] = value;
      return value;
    },
    // ___ TOASTS _________________________________
    generateToastId() {
      const crypto = window.crypto || window.msCrypto;
      const array = new Uint32Array(1);
      const newUID = crypto.getRandomValues(array);
      return newUID[0];
    },
    setToasts(toastMsg, type = 'info', variant = 'filled') {
      const id = self.generateToastId();
      if (toastMsg instanceof Object) {
        const toast = {
          id,
          title: toastMsg?.title || type,
          content: toastMsg?.content || 'no content',
          type: toastMsg?.type || 'info',
          timeOut: toastMsg?.timeOut || 8000,
          variant: toastMsg?.variant || 'filled'
        };
        return self.toasts.push(toast);
      }
      return self.toasts.push({ id, content: toastMsg, type, title: type, timeOut: 8000, variant });
    },
    deleteToast(id) {
      const filtered = self.toasts.filter((toast) => toast.id !== id);
      self.toasts = filtered;
    },

    setEditTab(tab) {
      self.editTab = tab;
      return self.editTab;
    },

    createNewLessonContainer(course) {
      return {
        type: 'lesson_container',
        attributes: { title: `LC ${course.attributes.title}` },
        relationships: {
          profession: { data: course.relationships.data }
        }
      };
    },

    // ___ EWAPI: FETCH COURSES ________________________
    fetchEwapiCourses: flow(function* fetchEwapiCourses(product = 0, _package = 0) {
      yield self.lmsApi.fetchEwapiCourses(product, _package);
    }),

    // FETCH COURSE_CONTAINER by filtering NID (+ includes)
    // - includes: profession, accreditation
    // used to get the profession and related accreditation to set the points unit
    fetchCourseContainer: flow(function* fetchCourseContainer(nid) {
      const query = {
        filter: {
          'filter[drupal_internal__nid][condition][value]': `${nid}`,
          'filter[drupal_internal__nid][condition][path]': 'drupal_internal__nid',
          'filter[drupal_internal__nid][condition][operator]': '='
        },
        include: `profession, profession.field_accreditation`
      };

      try {
        const { data: fetched } = yield self.lmsApi.fetchIt('course_container', query);
        const professionId = fetched.data[0].relationships.profession.data?.id;

        const profession = fetched.included?.find((entity) => entity.type === 'profession' && entity.id === professionId);
        const accreditation = fetched.included?.find((entity) => entity.type === 'accreditation') || {};

        self.accreditation = accreditation;

        self.courseContainer = {
          ...fetched.data[0],
          relationships: {
            ...fetched.data[0].relationships,
            profession: { data: profession }
          }
        };
        return self.courseContainer;
      } catch (e) {
        self.error = e;
        self.state = 'error';

        throw e;
      }
    }),

    // FETCH COURSE by UUID (+ includes)
    // - includes: lesson_container, lessons
    // - used to get the course data for each tab
    fetchCourseEditData: flow(function* fetchCourseEditData(uuid) {
      try {
        const { data: fetched } = yield self.lmsApi.fetchIt('course', self.courseEditDataFields, uuid);
        const dependentCourse = fetched.included.find((entity) => entity.type === 'course');
        self.dependentCourse = dependentCourse
          ? {
              id: dependentCourse.id,
              type: dependentCourse.type,
              title: dependentCourse.attributes.title,
              nid: dependentCourse.attributes.drupal_internal__nid
            }
          : {};

        self.currentCourse = fetched.data;
        self.mergeIncludesCourses(fetched.included);
        return self.currentCourse;
      } catch (e) {
        self.error = e;
        self.state = 'error';

        throw e;
      }
    }),

    // PATCH: Used to update course
    patchCourseData: flow(function* patchCourseData(entity, data) {
      const query = [
        {
          action: 'update',
          requestId: 'course',
          body: `${JSON.stringify({ data: data.course })}`,
          uri: `jsonapi/${entity.type}/${entity.id}`
        },
        {
          action: 'update',
          requestId: 'lesson_container',
          body: `${JSON.stringify({ data: data.lessonContainer })}`,
          uri: `jsonapi/lesson_container/${entity.relationships.lesson_container.data.id.id}`
        },
        {
          action: 'view',
          requestId: 'course',
          uri: `jsonapi/${entity.type}/${entity.id}?${self.courseEditDataFields}`
        }
      ];
      try {
        const fetched = yield self.lmsApi.patchCourse(query);

        self.currentCourse = fetched.course.data;
        self.mergeIncludesCourses(fetched.course.included);

        return fetched;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),

    // > PATCHPOST:
    saveNewCourse: flow(function* saveNewCourse(course) {
      const newLessonContainer = self.createNewLessonContainer(course);

      /* ----- POST NEW LC ------ */
      try {
        const newLC = yield self.lmsApi.patchpost(newLessonContainer);

        course.relationships.lesson_container = { data: { type: newLC.type, id: newLC.id } };
        const savedCourse = yield self.lmsApi.patchpost(course);

        savedCourse.LC = newLC;
        return savedCourse;
      } catch (e) {
        return null;
      }
    }),

    // > POST: Create New Course
    postCourse: flow(function* postCourse(course) {
      const {
        profession: { data }
      } = self.courseContainer.relationships;
      const newLessonContainer = {
        type: 'lesson_container',
        attributes: {
          title: 'new lesson_container'
        },
        relationships: {
          profession: { data: null },
          lessons: { data: [] }
        }
      };

      // _ Course_Container
      const courseContainer = cloneDeep(getSnapshot(self.courseContainer));

      courseContainer.relationships.courses.data.push({ type: 'course', id: '{{course.body@$.data.id}}' });

      // _ Course -*created by user-
      course.relationships.lesson_container = { data: { type: 'lesson_container', id: '{{lessoncontainer.body@$.data.id}}' } };
      course.relationships.profession = { data };

      // _ NEW Lesson_container -*imported from dataObjects-
      newLessonContainer.attributes.title = `lessonContainer-${course.attributes.title}`;
      newLessonContainer.relationships.profession.data = data;

      const subQuery = [];
      // _ function _createSubReqItem(action, id, data=false, uri=false, waitfor=false)
      subQuery.push(_createSubReqItem('create', 'lessoncontainer', newLessonContainer, false, false));
      subQuery.push(_createSubReqItem('create', 'course', course, false, 'lessoncontainer'));
      subQuery.push(_createSubReqItem('update', 'coursecontainer', courseContainer, false, 'course'));

      try {
        const fetched = yield self.lmsApi.createCourse(subQuery);
        self.newCourseId = fetched['course#body{0}'].data.id;

        return fetched;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        throw e;
      }
    }),

    // ! ============== SAME FUNCTIONS =====================
    // ! 1. fetchCourseEdit
    fetchCourseEdit: flow(function* fetchCourseLessons(courseId) {
      const subQuery = self.createSubQuery(courseId);

      if (self.store.courseEditState === 'pending') return;
      self.store.setStoreValue('courseEditState', 'pending');

      try {
        const fetched = yield self.jsonApi.getCourseEdit(subQuery);
        const objCourseEdit = self.convertFetchedToMobxObj(fetched);
        self.updateCourseEditTree(objCourseEdit);
        self.store.setStoreValue('courseEditState', 'done');
      } catch (e) {
        self.store.setStoreValue('error', e);
        self.store.setStoreValue('courseEditState', 'error');
        if (self.store.error.status === 401 || self.store.error.status === 403) {
          self.gotoErrorPage({
            title: 'Error loading course',
            message: 'No access',
            statuscode: self.error.status.toString()
          });
          return;
        }
        throw e;
      }
    }),

    // ! 2. createCourse
    createCourse: flow(function* postCourse(courseId) {
      const subQuery = self.createSubQuery(courseId);

      if (self.store.courseEditState === 'pending') return;
      self.store.setStoreValue('courseEditState', 'pending');

      try {
        const fetched = yield self.jsonApi.getCourseEdit(subQuery);
        const objCourseEdit = self.convertFetchedToMobxObj(fetched);
        self.updateCourseEditTree(objCourseEdit);
        self.store.setStoreValue('courseEditState', 'done');
      } catch (e) {
        self.store.setStoreValue('error', e);
        self.store.setStoreValue('courseEditState', 'error');
        if (self.store.error.status === 401 || self.store.error.status === 403) {
          self.gotoErrorPage({
            title: 'Error loading course',
            message: 'No access',
            statuscode: self.error.status.toString()
          });
          return;
        }
        throw e;
      }
    }),
    // ! 3. updateCourse
    updateCourse: flow(function* patchCourse(courseId) {
      const subQuery = self.createSubQuery(courseId);

      if (self.store.courseEditState === 'pending') return;
      self.store.setStoreValue('courseEditState', 'pending');

      try {
        const fetched = yield self.jsonApi.getCourseEdit(subQuery);
        const objCourseEdit = self.convertFetchedToMobxObj(fetched);
        self.updateCourseEditTree(objCourseEdit);
        self.store.setStoreValue('courseEditState', 'done');
      } catch (e) {
        self.store.setStoreValue('error', e);
        self.store.setStoreValue('courseEditState', 'error');
        if (self.store.error.status === 401 || self.store.error.status === 403) {
          self.gotoErrorPage({
            title: 'Error loading course',
            message: 'No access',
            statuscode: self.error.status.toString()
          });
          return;
        }
        throw e;
      }
    }),
    // ! ======================================================
    // helper function to get new odt file
    getNewODT(tagdoc) {
      if (tagdoc?.data?.filename) return tagdoc.data;
      return false;
    },

    // DELETE LESSON ________________________
    deleteLessonFromLessonContainer: flow(function* deleteLessonFromLessonContainer(lesson) {
      const lessonContainer = cloneDeep(getSnapshot(self.currentLessonContainer));
      const lessonIndex = lessonContainer.relationships.lessons.data.findIndex((l) => l.id === lesson.id);
      lessonContainer.relationships.lessons.data.splice(lessonIndex, 1);
      try {
        const response = yield self.lmsApi.patchpost(lessonContainer);
        const existingContainerIndex = self.currentCourse.included.findIndex((entity) => entity.id === response.id);
        applySnapshot(self.currentCourse.included[existingContainerIndex], response);
        return response;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),

    // PATCH POST LESSON _____________________________________
    patchpostLesson: flow(function* postLesson(lesson) {
      const { selectedLessonContent } = lesson;
      const { tagdoc } = selectedLessonContent.relationships;
      const lessonContainer = cloneDeep(getSnapshot(self.currentLessonContainer));
      // check if new file is uploaded || false
      const newFileUpload = tagdoc?.data?.filename ? tagdoc.data : false;
      // to trigger the parsing of the odt in the backend
      // the filename must be unique, therefore we add a unix timestamp to the filename
      if (newFileUpload) {
        const fileName = newFileUpload.filename;
        const unixTimeStamp = dayjs().unix();
        newFileUpload.filename = `${unixTimeStamp}_${fileName}`;
      }
      delete lesson.selectedLessonContent;
      //
      if (newFileUpload) selectedLessonContent.relationships.tagdoc = { data: null };
      //
      // creating subQuery settings for each entity
      const subQuery = [];
      // set lesson action to update or create by lesson.id
      const lessonAction = lesson?.id ? 'update' : 'create';
      // set lesson to wait for lesson_content
      const lessonWFLC = selectedLessonContent?.id ? false : 'lessonContent';
      // set lesson_container to wait for lesson
      const lcntrWFLesson = lesson?.id ? false : 'lesson';
      // set lesson_content action to update or create by lesson_content.id
      const lessonContentAction = selectedLessonContent?.id ? 'update' : 'create';
      //
      // LESSON CONTENT: create subrequest
      if (selectedLessonContent) {
        subQuery.push(_createSubReqItem(lessonContentAction, 'lessonContent', selectedLessonContent, false, false));
      }
      // LESSON: create subrequest
      if (lesson) {
        // set lesson > relationship > profession
        if (!lesson?.id) lesson.relationships.profession = { data: lessonContainer.relationships.profession.data };
        if (lessonWFLC) {
          // set lesson > relationship > lesson_content > data
          lesson.relationships.lesson_content.data = { type: 'lesson_content', id: '{{lessonContent.body@$.data.id}}' };
        }
        subQuery.push(_createSubReqItem(lessonAction, 'lesson', lesson, false, lessonWFLC));
      }
      // LESSON CONTAINER: create subrequest
      if (lessonContainer) {
        if (!lesson?.id) {
          lessonContainer.relationships.lessons.data.push({ type: 'lesson', id: '{{lesson.body@$.data.id}}' });
          subQuery.push(_createSubReqItem('update', 'lessonContainer', lessonContainer, false, lcntrWFLesson));
        }
      }
      try {
        const fetched = yield self.lmsApi.subRequest(subQuery);
        self.merge2Includes(fetched, true);

        if (fetched?.lessonContent?.data && newFileUpload) {
          const { data: updateODT } = yield self.lmsApi.postODT(fetched?.lessonContent.data, newFileUpload, 'tagdoc');
          const { data: updatedLC } = yield self.lmsApi.fetchIt('lesson_content', {}, fetched?.lessonContent.data.id);
          self.merge2Includes({ updatedLessonContent: updatedLC, updatedOdt: updateODT }, true);
        }
        return fetched;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),

    deleteCourse: flow(function* deleteCourse(courseId) {
      const subQuery = self.createSubQuery(courseId);
      if (self.store.courseEditState === 'pending') return;
      self.store.setStoreValue('courseEditState', 'pending');

      try {
        const fetched = yield self.jsonApi.subQuery(subQuery);
        // update store
        const objCourseEdit = self.convertFetchedToMobxObj(fetched);
        self.updateCourseEditTree(objCourseEdit);
        self.store.setStoreValue('courseEditState', 'done');
      } catch (e) {
        self.store.setStoreValue('error', e);
        self.store.setStoreValue('courseEditState', 'error');
        if (self.store.error.status === 401 || self.store.error.status === 403) {
          self.gotoErrorPage({
            title: 'Error loading course',
            message: 'No access',
            statuscode: self.error.status.toString()
          });
          return;
        }
        throw e;
      }
    }),
    // --
    patchPostWidget: flow(function* patchPostWidget(widget) {
      const isNewWidget = !widget?.id;
      const strWidgetData = JSON.stringify(widget.attributes.widget_data);
      widget.attributes.widget_data = strWidgetData;
      // delete key from widget
      if (widget.tableData) delete widget.tableData;
      try {
        // # API post or patch widget
        const responseWidget = yield self.lmsApi.patchpost(widget);
        //
        // if new widget add it to lesson_content and save it to the backend
        if (isNewWidget) {
          const { lessonContent } = self.currentLesson;
          const { lesson_content_widgets: lessonContentWidgets } = lessonContent.relationships;
          // add the new widget to lesson content
          lessonContentWidgets.data.push({ type: responseWidget.type, id: responseWidget.id });
          // delete keys from lessonContent to avoid conflict with the backend
          delete lessonContent.attributes.changed;
          delete lessonContent.attributes.created;
          // # API patch lesson_content
          const updatedLC = yield self.lmsApi.patchpost(lessonContent);
          // ! workaround to update the lesson_content_widgets count in tab [lesson]
          self.lessonWidgets[updatedLC.id] = updatedLC.relationships.lesson_content_widgets.data.length;
        }
        // update store: add || replace widget
        if (isNewWidget) {
          self.currentLesson.included.push(responseWidget);
        } else {
          const index = self.currentLesson.included.findIndex((w) => w.id === widget.id);
          self.currentLesson.included[index] = responseWidget;
        }
        // update currentLessonWidgets to force rerender in dialogWidgetsTable
        self.currentLessonWidgets = Date.now();
        return responseWidget;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        throw e;
      }
    }),
    deleteWidget: flow(function* deleteWidget() {
      const widget = self.setEditWidget;
      const { id } = widget;
      const { lessonContent } = self.currentLesson;
      const { lesson_content_widgets: lessonContentWidgets } = lessonContent.relationships;
      const toastSucces = { title: 'succes', type: 'success', timeOut: 4000, variant: 'outlined' };
      // show toast
      self.setToasts({ content: 'Preparing to delete the widget', ...toastSucces });
      try {
        // delete course from course_container > courses > data array by index
        const indexWidget = lessonContentWidgets.data.findIndex((w) => w.id === id);
        lessonContentWidgets.data.splice(indexWidget, 1);
        // delete keys from lessonContent to avoid conflict with the backend
        delete lessonContent.attributes.changed;
        delete lessonContent.attributes.created;
        //
        // # API patch: the updated lesson_content node
        const resLessonContent = yield self.lmsApi.patchpost(lessonContent);
        //
        // ! workaround to update the lesson_content_widgets count in tab [lesson]
        const curWidgets = self.lessonWidgets;
        curWidgets[resLessonContent.id] = resLessonContent.relationships.lesson_content_widgets.data.length;
        //
        // # API delete: widget
        yield self.lmsApi.deletePost(widget);
        //
        // update store
        const index = self.currentLesson.included.findIndex((w) => w.id === id);
        self.currentLesson.included.splice(index, 1);
        // ! update currentLessonWidgets to force rerender in dialogWidgetsTable
        self.currentLessonWidgets = Date.now();
        // show toast
        self.setToasts({ content: 'Widget deleted', ...toastSucces, variant: 'filled' });
        // close dialog of the widget
        self.setStoreValue('toggleDialogEditWidget', false);
        return;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        // show error toast
        const toastError = { title: 'error', type: 'error', timeOut: 3000, variant: 'outlined' };
        self.setToasts({ content: 'Successfully deleted the widget', ...toastError });
        throw e;
      }
    }),

    /**
     * DELETE DRAFT COURSE FROM COURSE CONTAINER
     * The course is not deleted, only the id is deleted from the array courses in course_container
     * @param {string} uuidCourse
     * @returns {Promise<*>}
     */
    deleteDraftCourse: flow(function* deleteDraftCourse(uuidCourse) {
      const courseContainer = cloneDeep(getSnapshot(self.courseContainer));
      const getIndexofCourseToDelete = courseContainer.relationships.courses.data.findIndex((c) => c.id === uuidCourse);
      courseContainer.relationships.courses.data.splice(getIndexofCourseToDelete, 1);
      try {
        const response = yield self.lmsApi.patchpost(courseContainer);
        return response;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),
    /**
     * UNLINK/DECOUPLE A LINKED COURSE FROM A COURSE CONTAINER
     * The course is not deleted, only the id is deleted from the array courses in course_container
     * @param {object} data
     * @returns {Promise<*>}
     */
    unlinkCourse: flow(function* unlinkCourse(data) {
      const {
        courseUuid,
        link: { course_container_uuid: courseContainerUuid }
      } = data;
      try {
        // fetch course_container belonging to the course to unlink
        const { data: fetchedCourseContainer } = yield self.lmsApi.fetchIt('course_container', {}, courseContainerUuid);
        // find the index of the course in course_container > courses > data array
        const getIndexofCourseToDelete = fetchedCourseContainer.data.relationships.courses.data.findIndex((c) => c.id === courseUuid);
        // delete course from course_container > courses > data array by index
        fetchedCourseContainer.data.relationships.courses.data.splice(getIndexofCourseToDelete, 1);
        // save the updated course_container
        const pacthCourseContainer = yield self.lmsApi.patchpost(fetchedCourseContainer.data);
        return pacthCourseContainer;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),
    // MERGE INCLUDES
    mergeIncludesCourses(newEntities, overwrite = false) {
      if (!newEntities || !Array.isArray(newEntities)) return;
      newEntities.forEach((newEntity) => {
        if (!newEntity) return;
        const existingEntity =
          self.currentCourse.included.length && self.currentCourse.included.find((entity) => entity.id === newEntity.id);
        if (overwrite && existingEntity) {
          destroy(existingEntity);
          self.currentCourse.included.push(newEntity);
        } else if (!existingEntity) {
          self.currentCourse.included.push(newEntity);
        }
      });
    },
    //
    getCourse() {
      return self.isNewRecord() ? self.newCourse : cloneDeep(getSnapshot(self.store.findCourseByNid(parseInt(self.store.params.courseId))));
    },

    getAccreditationPoints() {
      if (!self.accreditations()) return null;
      return self.accreditations().map((accr) => ({ points: self.getAccrPoint(accr), unit: self.getAccrUnit(accr) }));
    },

    copyCourse() {
      self.duplicate.course = self.course;
    },

    isReUsedCoursed() {
      const currentCourse = JSON.parse(JSON.stringify(self.currentCourse));
      const courseProfId = currentCourse.relationships.profession.data.id;
      const lessonContainer = currentCourse.included.find((l) => l.type === 'lesson_container');
      const lessonContainerProfId = lessonContainer.relationships.profession.data.id;
      return courseProfId !== lessonContainerProfId;
    },

    update(newEntity, key = 'lessons', overwrite = false) {
      const existingEntity = self[key].find((entity) => entity.id === newEntity.id);
      if (overwrite && existingEntity) {
        destroy(existingEntity);
        self[key].push(newEntity);
      } else if (!existingEntity) {
        self[key].push(newEntity);
      }
    },

    updateCourseEditTree(obj) {
      // self.course = obj.course
      self.authors = obj.authors;
      self.category = obj.category;
      self.category2 = obj.category2;
      self.accreditation_links = obj.accreditation_links;
      // self.accreditations = obj.accreditations
      self.organisations = obj.accreditations;
      self.lessons = obj.lessons;
      self.lesson_container = obj.lesson_container;
      self.lesson_contents = obj.lesson_contents;
    },

    createQueryProps() {
      return { filter: self.filter, fields: self.courseFields };
    },

    createSubQuery(courseId) {
      const categoryId = self.categoryId();
      const category2Id = self.category2Id();
      const queryProp = self.createQueryProps();
      const subQuery = [];
      if (courseId) {
        subQuery.push(self.createSubReqItem('view', 'course', false, `jsonapi/course/${courseId}?${queryProp.fields}`, false));
      }
      subQuery.push(self.createSubReqItem('view', 'authors', false, '/jsonapi/author?include=field_photo', false));
      subQuery.push(self.createSubReqItem('view', 'category', false, `/jsonapi/category?${queryProp.filter}${categoryId}`, false));
      subQuery.push(self.createSubReqItem('view', 'category2', false, `/jsonapi/category?${queryProp.filter}${category2Id}`, false));
      return subQuery;
    },

    createSubReqItem(action, id, data = false, uri = false, waitfor = false) {
      const obj = {};
      obj.action = action;
      obj.requestId = id;
      if (data) obj.body = JSON.stringify({ data });
      if (uri) {
        obj.uri = uri;
      } else {
        obj.uri = action === 'create' ? `jsonapi/${data.type}` : `jsonapi/${data.type}/${data.id}`;
      }
      if (waitfor) obj.waitFor = [`${waitfor}`];
      return obj;
    },

    convertFetchedToMobxObj(fetched) {
      return {
        course: fetched.course ? fetched.course.data : null,
        authors: fetched.authors.data,
        category: fetched.category.data,
        category2: fetched.category2.data,
        accreditation_links: fetched?.course?.included ? fetched.course.included.filter((l) => l.type === 'accreditation_link') : [],
        accreditations: fetched?.course?.included ? fetched.course.included.filter((l) => l.type === 'accreditation_organisation') : [],
        lessons: fetched?.course?.included ? fetched.course.included.filter((l) => l.type === 'lesson') : [],
        lesson_container: fetched?.course?.included ? fetched.course.included.find((lc) => lc.type === 'lesson_container') : null,
        lesson_contents: fetched?.course?.included ? fetched.course.included.filter((c) => c.type === 'lesson_content') : []
      };
    }
  }))
  // ========== LESSON ACTIONS =======
  .actions((self) => ({
    // afterCreate() {
    //   self.consoleTimestamp();
    // },
    // eslint-disable-next-line consistent-return
    getCategoriesByContainerNid: flow(function* getCategoriesByContainerNid(nid, category = 'category') {
      const courseCategory = `field_categories_parent_term`;
      const courseCompetence = `field_categories2_parent_term`;
      const fieldCategory = category === 'category' ? courseCategory : courseCompetence;
      const query = `filter[drupal_internal__nid][value]=${nid}&include=profession`;
      try {
        const { data: curCC } = yield self.lmsApi.fetchIt('course_container', query);
        const profession = curCC.included.find((l) => l.type === 'profession');
        const catId = profession.relationships[fieldCategory].data.meta.drupal_internal__target_id;
        const queryCategory = `filter[parent.meta.drupal_internal__target_id][value]=${catId}`;
        const { data: categories } = yield self.lmsApi.fetchIt('category', queryCategory);
        return categories;
      } catch (e) {
        console.error(e);
      }
    }),
    //
    // ==== ADVANCED COPY COURSE ACTIONS FOR COURSES IN SAME COURSE CONTAINER AND EXTERNAL COURSE CONTAINER  =====
    /*
      @param {string} category - category or category2
    */
    // eslint-disable-next-line consistent-return
    advGetCategoriesByContainerNid: flow(function* advGetCategoriesByContainerNid(category = 'category') {
      const courseCategory = `field_categories_parent_term`;
      const courseCompetence = `field_categories2_parent_term`;
      const fieldCategory = category === 'category' ? courseCategory : courseCompetence;

      const query = `filter[drupal_internal__nid][value]=${self.store.params.containerNid}&include=profession`;
      try {
        const { data: curCC } = yield self.lmsApi.fetchIt('course_container', query);
        const profession = curCC.included.find((l) => l.type === 'profession');
        const catId = profession.relationships[fieldCategory].data.meta.drupal_internal__target_id;
        const queryCategory = `filter[parent.meta.drupal_internal__target_id][value]=${catId}`;
        const { data: categories } = yield self.lmsApi.fetchIt('category', queryCategory);
        return categories;
      } catch (e) {
        console.error(e);
      }
    }),

    // eslint-disable-next-line consistent-return
    advGetCategoryforCurrentCourseContainer: flow(function* advGetCategoryforCurrentCourseContainer(containerNid) {
      if (containerNid.toString() === self.store.params.containerNid.toString()) return false;
      const query = `filter[drupal_internal__nid][value]=${self.store.params.containerNid}&include=profession`;
      try {
        const { data: curCC } = yield self.lmsApi.fetchIt('course_container', query);

        const profession = curCC.included.find((l) => l.type === 'profession');

        const catId = profession.relationships.field_categories_parent_term.id;

        //
        const queryCategory = `filter[parent.meta.drupal_internal__target_id][value]=${catId}`;
        const { data: categories } = yield self.lmsApi.fetchIt('category', queryCategory);
        //
        const category = categories?.data[0].id;

        return category;
      } catch (e) {
        console.error(e);
      }
    }),

    // ADVANCED COPY ACTION: CREATE COPY COURSE
    advCreateCopyCourse: flow(function* advCreateCopyCourse(currentCourse, newLessonContainer = false) {
      const action = newLessonContainer ? 'duplicate' : 'reuse';

      try {
        delete currentCourse.data.id;
        delete currentCourse.data.attributes.drupal_internal__nid;
        delete currentCourse.data.attributes.langcode;
        delete currentCourse.data.attributes.changed;
        delete currentCourse.data.attributes.created;
        delete currentCourse.data.links;
        delete currentCourse.data.relationships.uid;
        currentCourse.data.attributes.title = `${action} of ${currentCourse.data.attributes.title}`;
        currentCourse.data.attributes.online = false;
        currentCourse.data.attributes.status = false;
        if (newLessonContainer) {
          currentCourse.data.relationships.lesson_container.data = { type: 'lesson_container', id: newLessonContainer.id };
        }
        // CREATE COURSE
        const respCreateNewCourse = yield self.lmsApi.patchpost(currentCourse.data);

        //
        return respCreateNewCourse;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),
    // TESTING COPY LESSON CONTENT EWAPI
    advDuplicateLessonContent: flow(function* advDuplicateLessonContent(lesContentNid) {
      //

      const res = yield fetch(`${process.env.REACT_APP_JSONAPI}/ewapi/lesson_content/${lesContentNid}/clone`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.clientInstance.tokenInformation.access_token}`
        }
      });
      //
      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }
      const data = yield res.json();

      return data;
    }),
    // CREATE DUPLICATE OF THE LESSON
    advCreateCopyLessson: flow(function* advCreateCopyLessson(curLesson) {
      // set action
      const action = 'duplicate';
      // CREATE A DEEP CLONE OF CURRENT LESSON
      const newLesson = JSON.parse(JSON.stringify(curLesson));
      // CHECK IF LESSON_CONTENT EXIST
      const hasLessonContent = newLesson.relationships?.lesson_content?.data?.id;
      let clonedLessonContentId = false;

      if (hasLessonContent) {
        // -> create copy of lesson_content
        const lesContentNid = newLesson.relationships.lesson_content.data.meta.drupal_internal__target_id;
        //

        const duplicateLessonContent = yield self.advDuplicateLessonContent(lesContentNid);

        //
        clonedLessonContentId = duplicateLessonContent.clone_uuid;
      }
      try {
        // DELETE KEYS
        delete newLesson.attributes.changed;
        delete newLesson.attributes.created;
        delete newLesson.attributes.drupal_internal__nid;
        delete newLesson.id;
        delete newLesson.links;
        delete newLesson.relationships.uid;
        // UPDATE TITLE
        newLesson.attributes.title = `${action} of ${newLesson.attributes.title}`;

        if (hasLessonContent) {
          newLesson.relationships.lesson_content.data = { type: 'lesson_content', id: clonedLessonContentId };
        }
        // CREATE NEW LESSON

        const respCreateNewLesson = yield self.lmsApi.patchpost(newLesson);
        //
        return respCreateNewLesson;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),

    // ADVANCED COPY EXTERNAL COURSE IN CURRENT CONTAINER
    advCreateCopyExternalCourse: flow(function* advCreateCopyExternalCourse(currentCourse, settings = false) {
      const { profession, category } = settings;
      try {
        delete currentCourse.data.id;
        delete currentCourse.data.attributes.drupal_internal__nid;
        delete currentCourse.data.attributes.langcode;
        delete currentCourse.data.attributes.changed;
        delete currentCourse.data.attributes.created;
        delete currentCourse.data.links;
        delete currentCourse.data.relationships.uid;
        currentCourse.data.attributes.title = `Reuse of ${currentCourse.data.attributes.title}`;
        currentCourse.data.attributes.online = false;
        currentCourse.data.attributes.status = false;

        // ===== UPDATE RELATIONSHIPS FIELDS =====
        // SET COURSE_CATEGORY2 = []
        currentCourse.data.relationships.course_category2.data = [];
        // SET PROFESSION TO COURSE CONTAINER PROFESSION
        if (profession) {
          currentCourse.data.relationships.profession.data = profession;
        }
        // SET CATEGORY TO SELECTED COURSE_CATEGORY
        if (category) {
          currentCourse.data.relationships.course_category.data = [{ id: category, type: 'category' }];
        }

        // ====== CREATE COURSE =======
        const respCreateNewCourse = yield self.lmsApi.patchpost(currentCourse.data);

        return respCreateNewCourse;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),

    // ADVANCED COPY ACTION: CREATE COPY LESSON CONTAINER
    advCreateCopyLessonContainer: flow(function* advCreateCopyLessonContainer(curLessonContainer) {
      const curLC = JSON.parse(JSON.stringify(curLessonContainer));
      try {
        delete curLC.id;
        delete curLC.attributes.drupal_internal__nid;
        delete curLC.attributes.drupal_internal__vid;
        delete curLC.attributes.langcode;
        delete curLC.attributes.changed;
        delete curLC.attributes.created;
        delete curLC.attributes.field_goals;
        delete curLC.attributes.field_information;
        delete curLC.attributes.field_preview_video;
        delete curLC.attributes.field_podcast_html;
        delete curLC.links;
        delete curLC.relationships.uid;
        // update fields
        curLC.attributes.field_goals = curLessonContainer?.attributes?.field_goals?.value || '';
        curLC.attributes.field_information = curLessonContainer.attributes.field_information?.value || '';
        curLC.attributes.field_preview_video = curLessonContainer.attributes.field_preview_video?.value || '';
        curLC.attributes.field_podcast_html = curLessonContainer.attributes.field_podcast_html?.value || '';
        curLC.attributes.title = `Duplicate of ${curLC.attributes.title}`;

        // CREATE COURSE
        const respCreateNewLC = yield self.lmsApi.patchpost(curLC);

        return respCreateNewLC;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),

    // ADVANCED COPY ACTION: UPDATE COURSE CONTAINER
    advUpdateCourseContainer: flow(function* advUpdateCourseContainer(newCourseId) {
      try {
        const newQuery = `filter[drupal_internal__nid][value]=${self.store.params.containerNid}`;
        const { data: curCC } = yield self.lmsApi.fetchIt('course_container', newQuery);

        const courseContainer = curCC.data[0];

        courseContainer.relationships.courses.data.push({ type: 'course', id: newCourseId });

        const resPatchCourseContainer = yield self.lmsApi.patchpost(courseContainer);

        return resPatchCourseContainer;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),

    /* ==================== COURSE IN CURRENT COURSE_CONTAINER =================== */
    // - REUSE CURRENT COURSE IN CONTAINER
    reuseCourseInContainer: flow(function* copyCourseInContainer(uuidCourse) {
      try {
        const { data: curCourse } = yield self.lmsApi.fetchIt('course', {}, uuidCourse);

        //

        const newCopyCourse = yield self.advCreateCopyCourse(curCourse);

        //

        const updateCourseContainer = yield self.advUpdateCourseContainer(newCopyCourse.id);

        return updateCourseContainer;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),
    // - DUPLICATE COMPLETE COURSE IN CURRENT COURSE CONTAINER
    duplicateCourseInContainer: flow(function* duplicateCourseInContainer(uuidCourse) {
      const newFilter = `include=lesson_container, lesson_container.lessons`;
      const toastDefault = { title: 'succes', type: 'success', timeOut: 3000, variant: 'outlined' };
      try {
        // FETCH CURRENTCOURSE  + INCLUDES

        const { data: curCourse } = yield self.lmsApi.fetchIt('course', newFilter, uuidCourse);

        self.setToasts({ content: 'Cursus opgehaald', ...toastDefault });
        //
        const { included } = curCourse;
        //
        // FIND/FILTER LESSON_CONTAINER + LESSONS FROM INCLUDED

        const lessonContainer = included.find((l) => l.type === 'lesson_container');

        //

        const lessons = included.filter((l) => l.type === 'lesson');

        // LESSON(s)
        const newLessonArray = [];
        if (lessons.length) {
          //

          const lesson0 = yield self.advCreateCopyLessson(lessons[0]);

          //

          newLessonArray.push({ type: 'lesson', id: lesson0.id });

          //
          if (lessons.length > 1) {
            const lesson1 = yield self.advCreateCopyLessson(lessons[1]);
            newLessonArray.push({ type: 'lesson', id: lesson1.id });
          }
          self.setToasts({ content: 'Kopie gemaakt van de lesson', ...toastDefault });

          //
          // UPDATE LESSON_CONTAINER WITH NEW LESSONS

          lessonContainer.relationships.lessons.data = newLessonArray;
        }

        // LESSEN_CONTAINER
        let newCopyLC = false;
        if (lessonContainer) {
          newCopyLC = yield self.advCreateCopyLessonContainer(lessonContainer);

          self.setToasts({ content: 'Copy van de lesson container gemaakt', ...toastDefault });
        }

        // CREATE COPY OF CURRENT COURSE + ADD NEW LESSON CONTAINER

        const newCopyCourse = yield self.advCreateCopyCourse(curCourse, newCopyLC);

        self.setToasts({ content: 'Copy van de cursus gemaakt', ...toastDefault });

        //

        // UPDATE COURSE CONTAINER WITH DUPLICATE COURSE
        const updateCourseContainer = yield self.advUpdateCourseContainer(newCopyCourse.id);

        self.setToasts({ content: 'finalishing', ...toastDefault });

        return updateCourseContainer;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),

    /*  ==================== COURSE IN ANOTHER COURSE_CONTAINER ==================== */
    // --- LINK TO EXTERNAL COURSE
    linkExternalCourseInContainer: flow(function* linkExternalCourseInContainer(uuidCourse) {
      try {
        const newQuery = `filter[drupal_internal__nid][value]=${self.store.params.containerNid}`;
        const { data: curCC } = yield self.lmsApi.fetchIt('course_container', newQuery);

        const courseContainer = curCC.data[0];

        courseContainer.relationships.courses.data.push({ type: 'course', id: uuidCourse });
        const resPatchCourseContainer = yield self.lmsApi.patchpost(courseContainer);

        return resPatchCourseContainer;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),
    // --- RE-USE COPY OF EXTERNAL COURSE ! course category should be set to the course
    copyExternalCourseInContainer: flow(function* copyExternalCourseInContainer(courseUuid, categoryUuid = false) {
      try {
        // GET CURRENT COURESE CONTAINER
        const newQuery = `filter[drupal_internal__nid][value]=${self.store.params.containerNid}`;
        const { data: curCC } = yield self.lmsApi.fetchIt('course_container', newQuery);

        //
        const courseContainer = curCC.data[0];
        const ccProfession = courseContainer.relationships.profession.data;

        // GET SELECTED COURSE
        const { data: curCourse } = yield self.lmsApi.fetchIt('course', {}, courseUuid);

        // CREATE COPY OF SELECTED COURSE
        const options = { profession: ccProfession, category: categoryUuid };
        // ! + update relationships: course_category / profession
        const newCopyCourse = yield self.advCreateCopyExternalCourse(curCourse, options);

        //
        const updateCourseContainer = yield self.advUpdateCourseContainer(newCopyCourse.id);

        return updateCourseContainer;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),
    // - DUPLICATE COMPLETE COURSE FROM ANOTHER COURSE CONTAINER IN CURRENT COURSE_CONTAINER
    duplicateExternalCourseInContainer: flow(function* duplicateExternalCourseInContainer(uuidCourse, categoryUuid) {
      try {
        //
        // GET CURRENT COURSE_CONTAINER by route params containerNid
        const ccQuery = `filter[drupal_internal__nid][value]=${self.store.params.containerNid}`;
        const { data: curCC } = yield self.lmsApi.fetchIt('course_container', ccQuery);

        //
        const courseContainer = curCC.data[0];
        const ccProfession = courseContainer.relationships.profession.data;
        // ccProfession (course_container profession) is needed when creating the duplicate course
        // the duplicated course should have the same profession as the current course_container

        // GET CURRENT COURSE + INCLUDES 2 DUPLICATE

        const newFilter = `include=lesson_container, lesson_container.lessons`;
        const { data: curCourse } = yield self.lmsApi.fetchIt('course', newFilter, uuidCourse);
        const { included } = curCourse;

        // GET LESSON CONTAINER + LESSONS FROM INCLUDED
        const lessonContainer = included.find((l) => l.type === 'lesson_container');
        const lessons = included.filter((l) => l.type === 'lesson');

        let newCopyLC = false;
        const newLessonArray = [];

        // DUPLICATING LESSON(s)
        if (lessons.length) {
          const lesson0 = yield self.advCreateCopyLessson(lessons[0]);
          newLessonArray.push({ type: 'lesson', id: lesson0.id });
          if (lessons.length > 1) {
            const lesson1 = yield self.advCreateCopyLessson(lessons[1]);
            newLessonArray.push({ type: 'lesson', id: lesson1.id });
          }
          // UPDATE LESSONCONTAINER WITH NEW LESSONS
          lessonContainer.relationships.lessons.data = newLessonArray;
        }

        // DUPLICATE LESSEN_CONTAINER
        if (lessonContainer) {
          lessonContainer.relationships.profession.data = ccProfession;
          newCopyLC = yield self.advCreateCopyLessonContainer(lessonContainer);
        }

        // DEEP CLONE CURRENT COURSE
        const clonedCourse = JSON.parse(JSON.stringify(curCourse));

        // SET CATEGORY TO SELECTED COURSE_CATEGORY
        if (categoryUuid) {
          clonedCourse.data.relationships.profession.data = ccProfession;
          clonedCourse.data.relationships.course_category2.data = [];
          clonedCourse.data.relationships.course_category.data = [{ id: categoryUuid, type: 'category' }];
        }
        // CREATE DUPLICATE OF CURRENT COURSE + ADD NEW LESSON CONTAINER
        const newCopyCourse = yield self.advCreateCopyCourse(clonedCourse, newCopyLC);

        // UPDATE COURSE CONTAINER WITH DUPLICATE COURSE
        const updateCourseContainer = yield self.advUpdateCourseContainer(newCopyCourse.id);

        return updateCourseContainer;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }),
    //  ==== OTHER ACTIONS
    setCurrentPackage(packageNid) {
      self.currentPackage = packageNid;
    },

    // open/close dialog lesson screen
    setDialogLesson(uuid = '') {
      self.dialogLesson = { open: !self.dialogLesson.open, lessonId: uuid };
      self.currentFilename = '';
    },

    // Merge different entities into self.included
    merge2Includes(newEntities, overwrite = false) {
      Object.values(newEntities).forEach((key) => {
        if (key?.errors) return;
        const existingEntity = self.currentCourse.included.find((item) => item.id === key.data.id);

        if (overwrite && existingEntity) {
          destroy(existingEntity);
          self.currentCourse.included.push(key.data);
        } else if (!existingEntity) {
          self.currentCourse.included.push(key.data);
        }
      });
    },
    /**
     * Convert json course to ewapi course
     *
     * Used for editor to inject the course in the course overview and to start the lessonplay.
     * @param {*} course
     * @returns
     */
    convertJsonCourseToEwapiCourse(course) {
      const { profession: prof, lesson_container: lc } = course.relationships;
      const isProxy = prof.data?.id?.attributes?.drupal_internal__tid;
      const profNid = isProxy ? prof.data.id.attributes.drupal_internal__tid : prof.data.meta.drupal_internal__target_id;
      const lcNid = isProxy ? lc.data.id.attributes.drupal_internal__nid : lc.data.meta.drupal_internal__target_id;
      const lcId = isProxy ? course.relationships.lesson_container.data.id.id : course.relationships.lesson_container.data.id;
      return {
        course_accreditation: null,
        course_delta: 0,
        course_expected: 0,
        course_extra_points: null,
        course_goals: null,
        course_id: course.attributes.drupal_internal__nid,
        course_information: null,
        course_points: 0,
        course_preview_video: null,
        course_profession: profNid,
        course_status: null,
        course_title: course.attributes.title,
        course_uuid: course.id,
        coursephoto_alt: null,
        coursephoto_url: null,
        headerphoto_alt: null,
        headerphoto_url: null,
        lc_course_header_photo: null,
        lc_coursephoto: null,
        lc_extra_label: null,
        lc_goals: null,
        lc_information: null,
        lc_podcast_html: null,
        lc_podcast_title: null,
        lc_preview_video: null,
        lesson_container_id: lcNid,
        lesson_container_uuid: lcId,
        valid_period_end: null,
        valid_period: null,
        lc_author_id: null,
        lc_reviewer_id: null, // array ref per autore
        course_extra_points_desc: null,
        course_category: null,
        course_category2: null, // array ref per cat
        lessons: null // array ref per less
      };
    }
  }));

// ==================================[ HELPER FUNCTIONS ]============================================================
// get PROFESSION OBJECT
// function _getProfessionObj(store) {
//   return {
//     id: store.package.relationships.profession.data.id.id,
//     type: store.package.relationships.profession.data.id.type
//   };
// }

// create SUBREQUEST ITEMS
function _createSubReqItem(action, id, data = false, uri = false, waitfor = false, includes = false) {
  const obj = {};
  obj.action = action;
  obj.requestId = id;
  if (data) obj.body = JSON.stringify({ data });
  if (uri) {
    obj.uri = uri;
  } else {
    obj.uri = action === 'create' ? `jsonapi/${data.type}` : `jsonapi/${data.type}/${data.id}`;
  }
  if (waitfor) obj.waitFor = [`${waitfor}`];
  if (includes) obj.includes = includes;
  return obj;
}
export async function createCourseEdit(store) {
  if (store.courseEdit.isCreated) return;

  await store.setCourseEdit(CourseEdit.create({}, { store }));
}
