/* Core */
import { useState, useEffect } from 'react';
import { useStore, useBreakpoint } from 'hooks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

/* MUI */
import { Box, IconButton, Button, Dialog, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';

/* Custom components */
import CourseFilters from './CourseFilters';

export const FilterContainer = ({ onClose }) => (
  <Box className="filterBar">
    <Box className="filterBar_closeIcon">
      <IconButton onClick={onClose}>
        <CloseIcon style={{ width: '13px', height: '13px' }} />
      </IconButton>
    </Box>
    <CourseFilters onClose={onClose} />
  </Box>
);
FilterContainer.propTypes = {
  onClose: PropTypes.func
};

const Filter = () => {
  const store = useStore();
  const { t } = useTranslation();
  const breakpoints = useBreakpoint();
  const mobileMode = breakpoints === 'xl';
  const mobileFilterPopup = breakpoints === 'sm' || breakpoints === 'xs';
  const [anchorFilter, setAnchorFilter] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (anchorFilter === null && mobileMode === false && breakpoints !== '') {
      store.hideFilter(false);
    }
  }, [anchorFilter, store, breakpoints, mobileMode]);

  const handleClickFilter = (event) => {
    store.showFilter();
    if (mobileFilterPopup) {
      setOpen(true);
    }
    setAnchorFilter(event.currentTarget);
  };

  const handleClickFilterFull = (event) => {
    store.showFilter();
    if (mobileFilterPopup) {
      setOpen(true);
    }
    setAnchorFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    if (mobileFilterPopup) {
      setOpen(false);
    }
    setAnchorFilter(null);
  };

  const shouldShowFilterInPopover = !mobileMode && store.isfilterVisible;
  const filterButonStyling = store.isfilterVisible ? 'filter_button_active' : 'filter_button';

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!store.courses) {
    return <></>;
  }

  if (store.courses.courses.length === 0) {
    return <></>;
  }

  return (
    <>
      <Box className={filterButonStyling}>
        {(mobileMode || breakpoints === 'lg') && (
          <Button
            onClick={handleClickFilter}
            id="simple-popover"
            sx={{
              backgroundColor: store.isfilterVisible ? 'primary.main' : 'background.main',
              color: store.isfilterVisible ? 'white' : 'primaryBlue.main',
              [`.MuiButton-startIcon`]: { backgroundColor: store.isfilterVisible ? 'white' : 'primary.main' }
            }}
            startIcon={<FilterListIcon sx={{ color: store.isfilterVisible ? 'primary.main' : 'white' }} />}
          >
            {t('course.filters.buttonTitle')}
          </Button>
        )}
        {!mobileMode && !(breakpoints === 'lg') && !(breakpoints === 'xs') && (
          <IconButton
            onClick={handleClickFilter}
            id="simple-popover"
            sx={{ backgroundColor: store.isfilterVisible ? 'primary.main' : 'background.main' }}
          >
            <FilterListIcon sx={{ color: store.isfilterVisible ? 'white' : 'primary.main' }} />
          </IconButton>
        )}
        {!mobileMode && breakpoints === 'xs' && (
          <Button
            onClick={handleClickFilterFull}
            id="simple-popover"
            sx={{
              backgroundColor: store.isfilterVisible ? 'primary.main' : 'background.main',
              color: store.isfilterVisible ? 'white' : 'primaryBlue.main',
              [`.MuiButton-startIcon`]: { backgroundColor: store.isfilterVisible ? 'white' : 'primary.main' }
            }}
            startIcon={<FilterListIcon sx={{ color: store.isfilterVisible ? 'primary.main' : 'white' }} />}
          >
            {breakpoints === 'xs' && t('course.filters.buttonTitle')}
          </Button>
        )}
      </Box>
      {shouldShowFilterInPopover && !store?.courses?.isSpecialAccessView && (
        <>
          {mobileFilterPopup ? (
            <Dialog open={open} fullScreen={fullScreen} onClose={handleCloseFilter}>
              <Box className="filter_container">
                <FilterContainer onClose={handleCloseFilter} />
              </Box>
              <DialogActions>
                <Button onClick={handleCloseFilter} color="primary">
                  {t('course.filters.results')}
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Box className="filter_container">
              <FilterContainer onClose={handleCloseFilter} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default observer(Filter);
