import { ThemeBrandTitle } from 'helpers/Helpers';
import { createEditStore } from 'state/RoutesActions';

/*
It's important to note that the actual behavior of this route depends on the application's routing system.
The beforeEnter and onEnter functions may be used for various purposes, such as data loading, route guards, or view initialization.
The route allows navigation to a view for viewing online courses within an admin interface.
*/
export const onlineCoursesViewRoute = {
  name: 'adminOnlineCourses',
  pattern: '/admin/:package/:packageNid/:containerNid/online',
  title: `View online Courses | ${ThemeBrandTitle()}`,
  meta: { title: `View online Courses | ${ThemeBrandTitle()}` },
  beforeEnter: createEditStore,
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    store.courseEdit.setStoreValue('drawerOpen', false);
  }
};
