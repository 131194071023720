import { observer } from 'mobx-react';
import { useContext, useEffect, useRef } from 'react';
import { Store } from '../../state/ContextStore';
import LoadingPage from '../../components/common/LoadingPage';

export const Logout = observer(() => {
  const inProgress = useRef(false);
  const store = useContext(Store);

  useEffect(() => {
    if (!inProgress.current) {
      inProgress.current = true;
      store.logout(true);
    }
  });

  return <LoadingPage />;
});
