import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Box, Grid, ThemeProvider } from '@mui/material';
import { useCustomTheme, useStore } from 'hooks';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import NavBar from './components/NavBar';
import ShortCuts from './components/ShortCuts';
import AssistantsNavBar from './components/AssistantsNavBar';
import { getLmsTheme } from './LmsTheme';
import LmsDownloadManualBtns from './components/LmsDownloadManualBtns';

const LmsLayout = ({ children }) => {
  const store = useStore();
  const { globalTheme } = useCustomTheme();

  if (!store.showMenuItemLms) {
    return null;
  }

  const LmsTheme = responsiveFontSizes(createTheme(globalTheme, getLmsTheme(globalTheme)));
  const sxNavigation = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  };
  const sxDownloadInstructions = {
    background: 'white',
    // borderRadius: 1,
    marginBottom: '0px',
    padding: '10px 10px 0px 0px'
  };
  return (
    <ThemeProvider theme={LmsTheme}>
      <Grid container p={{ xs: 2, md: 4, lg: 0 }} spacing={2}>
        <Grid item xs={12} lg={3} xl={2.5}>
          <Box sx={sxNavigation}>
            {store.showMenuAssistant ? <AssistantsNavBar /> : <NavBar />}
            {!store.showMenuAssistant && <ShortCuts />}
          </Box>
        </Grid>
        <Grid id="stackContainer" item xs={12} lg={9} xl={9.5}>
          <Box id="lmsDownload" sx={sxDownloadInstructions} display="flex" justifyContent="flex-end" gap={2} mb={2} flexWrap="wrap">
            <LmsDownloadManualBtns />
          </Box>
          {children}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

LmsLayout.propTypes = {
  children: PropTypes.node
};

export default observer(LmsLayout);
