import { types as t } from 'mobx-state-tree';
import { RelAccreditationOrganisation } from './AccreditationOrganisation';

export const AccreditationLink = t.model('AccreditationLink', {
  type: t.string,
  id: t.identifier,
  attributes: t.model({
    drupal_internal__nid: t.number,
    title: t.string,
    accreditation_code: t.string,
    valid_period: t.model({
      value: t.string,
      end_value: t.string
    })
  }),
  relationships: t.model({
    accreditation_organisation: RelAccreditationOrganisation
  })
});

export const RelAccreditationLinks = t.model('RelAccreditationLinks', {
  data: t.maybeNull(
    t.array(
      t.model({
        id: t.reference(AccreditationLink),
        type: t.string
      })
    )
  )
});

export const newAccreditationLink = {
  attributes: {
    accreditation_code: 'ewise',
    accreditation_points: 0,
    title: '',
    valid_period: {
      value: '',
      end: ''
    }
  }
};
