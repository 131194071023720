import { SvgIcon } from '@mui/material';

const DateIcon = (props) => (
  <SvgIcon {...props} style={{ fill: 'white' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.2915 10.6463C1.2915 4.84964 3.224 2.91797 9.01984 2.91797C14.8165 2.91797 16.749 4.84964 16.749 10.6463C16.749 16.443 14.8165 18.3746 9.01984 18.3746C3.224 18.3746 1.2915 16.443 1.2915 10.6463Z"
      stroke="#2B3674"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.521 7.77018H16.5277" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.6903 11.0509H12.6978" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.02424 11.0509H9.03174" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.35139 11.0509H5.35889" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.6903 14.2609H12.6978" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.02424 14.2609H9.03174" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.35139 14.2609H5.35889" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.361 1.7085V4.42683" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.68717 1.7085V4.42683" stroke="#2B3674" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default DateIcon;
