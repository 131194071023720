import PropTypes from 'prop-types';

import { alpha, Button as ButtonBase, Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';

const Button = ({
  tooltipText,
  color,
  variant = 'contained',
  size = 'medium',
  disableElevation = true,
  shadow = false,
  inverted = false,
  square = false,
  border = false,
  children,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const lightGreen = alpha(theme.palette.primary.main, 0.2);

  const sizes = { small: '2.0rem', medium: '2.5rem', large: '3.0rem' };

  const buttonStyles = {
    borderRadius: '0.75rem',
    display: 'flex',
    gap: '0.5rem',
    textTransform: 'none',
    fontWeight: 400,
    minWidth: 'auto',
    height: sizes[size],
    ...sx
  };

  if (shadow) {
    buttonStyles.boxShadow = '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)';
  }

  if (inverted && variant === 'contained' && (!color || color === 'primary')) {
    buttonStyles.backgroundColor = '#fff';
    buttonStyles.color = 'primary.main';
    buttonStyles['&:hover'] = {
      backgroundColor: 'primary.main',
      color: '#fff'
    };
    if (border) {
      buttonStyles.border = `1px solid ${lightGreen}`;
    }
  }

  if (square) {
    buttonStyles.width = sizes[size];
    buttonStyles.height = sizes[size];
  }

  return (
    <Tooltip title={tooltipText} arrow placement="top">
      {/* span is needed to make tooltip work for disabled button state */}
      <span>
        <ButtonBase variant={variant} color={color} size={size} disableElevation={disableElevation} sx={buttonStyles} {...rest}>
          {children}
        </ButtonBase>
      </span>
    </Tooltip>
  );
};

Button.propTypes = {
  sx: PropTypes.any,
  children: PropTypes.any,
  tooltipText: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disableElevation: PropTypes.bool,
  shadow: PropTypes.bool,
  inverted: PropTypes.bool,
  square: PropTypes.bool,
  border: PropTypes.bool
};

export default Button;
