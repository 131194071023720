import { ThemeBrandTitle } from 'helpers/Helpers';
import { getEwapiForEditors } from 'state/RoutesActions';

/**
 * COURSE ADMIN LESSONPLAY ROUTE
 *
 * @type {{
 * name: string,
 * title: string,
 * pattern: string,
 * beforeEnter: (function(*, *, *): Promise<unknown>),
 * onEnter: (function(*, *, *): Promise<void>)}}
 */
export const adminCourseLessonPlayRoute = {
  name: 'adminCourseLessonPlay',
  pattern: '/pre/:productName/:product/:packageId/course/:courseId/lesson/:lessonId/page/:pageNo',
  title: `LessonPlay | ${ThemeBrandTitle}`,
  meta: { title: `Edit | ${ThemeBrandTitle()}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    /**
     * POST PROCESSING OF THE ROUTE
     * when a user click on the play button @ tab lessons
     * 1. check if store is filled with ewapi data else get ewapi data
     * 2. get the current course from course edit
     * 3. convert current course from json -> ewapi model
     * 4. add the ewapi course to the store
     * 5. goto the real lesson play route
     */
    const { store } = routerStore.options;
    // 1. check if store is filled with ewapi data else get ewapi data
    if (!store?.courses?.courses) await getEwapiForEditors(fromState, toState, routerStore);
    // 2 + 3 convert json course model to ewapi course model
    const ewapiCourse = store.courseEdit.convertJsonCourseToEwapiCourse(store.courseEdit.currentCourse);
    // 4. add the ewapi course to the store
    store.courses.addNewCourse(ewapiCourse);
    // 5. goto the real lesson play route
    routerStore.goTo('lessonPlay', {
      params: {
        productName: toState.params.productName,
        product: toState.params.product,
        packageId: toState.params.packageId,
        courseId: toState.params.courseId,
        lessonId: toState.params.lessonId,
        pageNo: toState.params.pageNo.toString()
      }
    });
  }
};
