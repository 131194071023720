/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useStore } from 'hooks';
/* MUI */
import { Box, List, ListItem, ListItemText, ListItemIcon, SvgIcon } from '@mui/material';
import Quiz from 'components/icons/Quiz';
import CertificateTab from 'components/icons/CertificateTab';

// renders the course details to be displayed in the preview dialog
const CourseDetails = ({ previewValues }) => {
  const store = useStore();
  const getLesson = store?.courseEdit?.currentCourse?.included.find((item) => item.type === 'lesson');
  const minScore = getLesson?.attributes?.minimum_score || 0;
  const points = `${previewValues?.course?.attributes?.field_points || 0}`;
  const mainPoints = `${points} ${
    points === 0 || points > 1
      ? store.courseEdit?.accreditation?.attributes?.field_accr_unit_plural
      : store.courseEdit?.accreditation?.attributes.accr_unit
  }`;
  const xtraPoints = previewValues?.course?.attributes?.field_extra_points || '';
  const isExtraPoints = xtraPoints ? `+ ${xtraPoints} ${store.courseEdit?.accreditation?.attributes?.extra_points_unit}` : '';
  const certificationPoints = `${mainPoints} ${isExtraPoints}`;

  const attrPrimaryText = { primaryTypographyProps: { fontSize: '16px', fontWeight: '600' } };
  return (
    <Box>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <SvgIcon component={CertificateTab} color="primary" />
          </ListItemIcon>
          <ListItemText {...attrPrimaryText} primary="Certificering" secondary={`${certificationPoints}`} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SvgIcon component={Quiz} color="primary" />
          </ListItemIcon>
          <ListItemText {...attrPrimaryText} primary="Benodigde score" secondary={`${minScore}%`} />
        </ListItem>
      </List>
    </Box>
  );
};

CourseDetails.propTypes = {
  previewValues: PropTypes.object
};

export default observer(CourseDetails);
