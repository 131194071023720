import axios from 'axios';

/**
 * This api is made distinct from the main one for one sole reason.
 * The response behavior and defaults are different form the authenticated
 * parts
 *
 * @type {AxiosInstance}
 */
const authorizationApi = axios.create({
  timeout: 3000
});

export default authorizationApi;
