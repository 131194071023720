import { types as t } from 'mobx-state-tree';
import { Course } from 'state/modelsEwapi/Course';

export const Favorites = t
  .model('Favorites', {
    nid: t.number,
    uuid: t.string,
    favorite_courses: t.maybe(
      t.array(
        t.model({
          target_id: t.reference(Course),
          uuid: t.maybeNull(t.string)
        })
      )
    )
  })
  .views((self) => ({
    isCourseFavorite(courseId) {
      const favoriteCourses = self.favorite_courses && self.favorite_courses.map((course) => course.target_id.course_id);
      return favoriteCourses.includes(courseId);
    }
  }));
