import MainLayout from 'pages/mainLayout/MainLayout';
import VTBLayout from '../VTBLayout';

const VtbViewMap = {
  vtb: (
    <MainLayout subtitle="" title="vtb.title">
      <VTBLayout />
    </MainLayout>
  )
};

export default VtbViewMap;
