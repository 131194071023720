import { observer } from 'mobx-react';
import { useState } from 'react';

import { Box } from '@mui/material';

import { useStore } from 'hooks';
import * as Types from '../../../types';
import EvaluationActivities from './EvaluationActivities';
import EvaluationHeader from './EvaluationHeader';
import PlannedActivities from './PlannedActivities';
import AddActivityDialog from './AddActivityDialog';
import EditReflectionDialog from './EditReflectionDialog';
import EvaluationReflections from './EvaluationReflections';
import { InitialValues } from '../../../types';

const EvaluationCard = ({ goal }) => {
  const [isAddActivityDialogVisible, setIsAddActivityDialogVisible] = useState(false);
  const [isEditReflectionDialogVisible, setIsEditReflectionDialogVisible] = useState(false);

  const store = useStore();

  const evaluation = goal.evaluations[0] ?? InitialValues.Evaluation;

  const onAddActivity = () => {
    setIsAddActivityDialogVisible(true);
  };

  const onEditReflection = () => {
    setIsEditReflectionDialogVisible(true);
  };

  const onCloseAddActivityModal = async () => {
    setIsAddActivityDialogVisible(false);
    store.startLoadCurriculum();
    await store.refreshCurrentCurriculum();
    store.stopLoadCurriculum();
  };

  const onCloseEditReflectionModal = async () => {
    setIsEditReflectionDialogVisible(false);
    store.startLoadCurriculum();
    await store.refreshCurrentCurriculum();
    store.stopLoadCurriculum();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '0.75rem',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
        }}
      >
        <EvaluationHeader goal={goal} evaluation={evaluation} />
        <PlannedActivities goal={goal} />
        <EvaluationActivities goal={goal} evaluation={evaluation} onAddActivityCallback={onAddActivity} />
        <EvaluationReflections goal={goal} evaluation={evaluation} onEditReflectionCallback={onEditReflection} />
      </Box>

      <AddActivityDialog
        goal={goal}
        evaluation={evaluation}
        visible={isAddActivityDialogVisible}
        onCloseCallback={onCloseAddActivityModal}
      />
      <EditReflectionDialog
        goal={goal}
        evaluation={evaluation}
        visible={isEditReflectionDialogVisible}
        onCloseCallback={onCloseEditReflectionModal}
      />
    </>
  );
};

EvaluationCard.propTypes = {
  goal: Types.LearningGoal.isRequired
};

export default observer(EvaluationCard);
