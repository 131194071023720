import MainLayout from 'pages/mainLayout/MainLayout';
import CurriculumLayout from 'pages/curriculum/CurriculumLayout';
import CurriculumDashboard from './CurriculumDashboard';

const CurriculumDashboardViewMap = {
  curriculumDashboard: (
    <MainLayout title="curriculum.title" subtitle="curriculum.subtitle">
      <CurriculumLayout>
        <CurriculumDashboard />
      </CurriculumLayout>
    </MainLayout>
  )
};

export default CurriculumDashboardViewMap;
