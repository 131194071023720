import { getEwapi } from 'state/RoutesActions';
import { ThemeBrandTitle } from 'helpers/Helpers';

export const teachersOverviewRoute = {
  name: 'teachersOverview',
  meta: { title: `%%teachers.title.teachers%% | ${ThemeBrandTitle()}` },
  pattern: '/:productName/:product/:packageId/teachers',
  beforeEnter: getEwapi,
  onEnter(fromState, toState, routerStore) {
    const { author } = toState.queryParams;
    const authorName = author || '';
    const { store } = routerStore.options;
    store.authors?.searchByName(authorName);
  }
};
