import { SvgIcon } from '@mui/material';

const download = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 40">
    <path d="M31,22c-0.552,0-1,0.448-1,1v7H2v-7c0-0.552-0.448-1-1-1s-1,0.448-1,1v8c0,0.552,0.448,1,1,1h30   c0.552,0,1-0.448,1-1v-8C32,22.448,31.552,22,31,22z" />
    <path d="M15.27,23.707c0.389,0.385,1.04,0.389,1.429,0l6.999-6.9c0.395-0.391,0.394-1.024,0-1.414   c-0.394-0.391-1.034-0.391-1.428,0l-5.275,5.2V1c0-0.552-0.452-1-1.01-1c-0.558,0-1.01,0.448-1.01,1v19.593l-5.275-5.2   c-0.395-0.391-1.034-0.391-1.428,0c-0.395,0.391-0.395,1.024,0,1.414L15.27,23.707z" />
  </SvgIcon>
);

export default download;
