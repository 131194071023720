import { Store } from 'state/ContextStore';
import { useContext } from 'react';
import { observer } from 'mobx-react';
/* Custom components */
import { Box } from '@mui/material';
// eslint-disable-next-line prettier/prettier
import useEwapi from '../hooks/useEwapi';
import EwapiTable from '../components/tables/EwapiTables';
import LoadingContent from '../components/loading/LoadingContent';
//
import '../scss/AdminTables.scss';
/*
In summary, the OnlineCourses component fetches online course data from an API, 
displays a loading indicator while the data is being fetched, and renders a table (likely displaying course information) when the data is available. 
This component is part of an admin interface for managing courses and appears to be part of a larger web application.
*/
const OnlineCourses = () => {
  const store = useContext(Store);
  const ewapiCourseList = `editor-course-list/${store.params.containerNid}/1/json`;
  const { response, loading, error } = useEwapi(ewapiCourseList, 'courses');
  //
  const title = 'online';

  if (loading) return <LoadingContent loading={loading} />;

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }} className="draft-view admin-custom-page">
      <Box className="admin-tables" sx={{ width: '100%', display: 'flex', flexDirection: 'column', flex: '1' }}>
        {error && <p>Something went wrong...</p>}
        {response && <EwapiTable courses={response.data.courses} summary={response.data.result} loading={loading} title={title} />}
      </Box>
    </Box>
  );
};

export default observer(OnlineCourses);
