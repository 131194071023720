import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MySelect, MyTextField } from 'components/form/RHF';
import { useStore } from 'hooks';

import * as Styles from '../../../components/styles';

const FunctionFormPart = ({ control, errors, setValue }) => {
  const { t } = useTranslation('curriculum');

  const classes = Styles.useCurriculumStyles();

  const store = useStore();

  const functionExamples = store.curriculumFunctionExamples?.data?.map((item) => ({
    name: item.attributes.description.value,
    value: item.attributes.description.value
  }));

  return (
    <>
      <MySelect
        sx={Styles.SelectBox}
        control={control}
        label={t('functions.selectFunctionDropdownLabel')}
        id="nameDropDown"
        name="nameDropDown"
        items={functionExamples}
        variant="outlined"
        fullWidth
        helperText={errors?.nameDropDown?.message}
        error={!!errors?.nameDropDown}
        onChange={(e) => {
          setValue('nameDropDown', e.target.value);
          setValue('nameString', '');
        }}
        inputProps={{ classes: { paper: classes.mySelect } }}
      />

      <MyTextField
        control={control}
        label={t('functions.functionTextfieldLabel')}
        id="nameString"
        name="nameString"
        variant="outlined"
        fullWidth
        placeholder={t('functions.functionTextfieldTooltipLabel')}
        sx={Styles.TextField}
        helperText={errors?.nameString?.message}
        error={!!errors?.nameString}
        onChange={() => {
          setValue('nameDropDown', '');
        }}
      />
    </>
  );
};

FunctionFormPart.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  setValue: PropTypes.func
};

export default observer(FunctionFormPart);
