import AdminCourseLayout from 'pages/adminCourses/AdminCourseLayout';
import ViewCourses from './ViewCourses';

const ViewCourseViewMap = {
  adminViewCourses: (
    <AdminCourseLayout title="View courses">
      <ViewCourses noPaper />
    </AdminCourseLayout>
  )
};

export default ViewCourseViewMap;
