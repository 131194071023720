import * as Yup from 'yup';

const EditCourse = Yup.object().shape({
  course: Yup.object({
    attributes: Yup.object({
      title: Yup.string().min(5, 'Title should be longer than 5 characters').required('Title is required'),
      status: Yup.bool(),
      // field_accreditation_code: Yup.lazy((value) => {
      //   if (value.length > 2) {
      //     return Yup.string().min(5, 'zooo kort?').required();
      //   }
      //   return Yup.mixed().notRequired();
      // }),
      online: Yup.string().nullable(),
      course_status: Yup.string().required('Course status is required'),
      // field_points: Yup.string().when(['status', 'online'], {
      //   is: (status, online) => {
      //
      //
      //     return status && online;
      //   },
      //   then: Yup.string().required('Points is required'),
      //   otherwise: Yup.string().nullable()
      // }),
      field_points: Yup.string().nullable(),
      field_extra_points: Yup.string().nullable(),
      field_valid_period: Yup.object({
        value: Yup.date().nullable(),
        end_value: Yup.date().nullable()
      })
    }),
    relationships: Yup.object({
      course_category: Yup.array().min(1, 'Category is required')
    })
  }),
  lessonContainer: Yup.object({
    attributes: Yup.object({}),
    relationships: Yup.object({})
  })
});

export { EditCourse };
