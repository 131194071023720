/* Core */
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useContext } from 'react';
import { getLabelNameFromDomain } from 'helpers/Helpers';

/* MUI */
import { Grid } from '@mui/material';

const AccreditationPartners = () => {
  const store = useContext(Store);
  const currentDomain = getLabelNameFromDomain();
  const selectedPackage = store?.baseProduct?.packages?.find((packag) => packag.nid === store.selectedPackageId);
  const selectedPackageTitle = selectedPackage?.tab_label?.toLowerCase() || '';
  const selectedProduct = store?.baseProduct?.title?.toLowerCase();

  return (
    <>
      {(currentDomain === 'po' || currentDomain === 'pe') && selectedPackageTitle === 'advocatuur' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/adv-logoreeks-hrz.jpg" style={{ width: '270px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'po' && selectedPackageTitle === 'archief' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/adv-logoreeks-hrz.jpg" style={{ width: '270px' }} alt="" />
        </Grid>
      )}
      {(currentDomain === 'po' || currentDomain === 'pe') && selectedPackageTitle === 'notariaat' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/logo_knb_1.svg" style={{ width: '120px' }} alt="" />
        </Grid>
      )}
      {(currentDomain === 'po' || currentDomain === 'pe') && selectedPackageTitle === 'fiscaliteit' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/rb-logo-homepage-2014.png" style={{ width: '100px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'pe' &&
        (selectedPackageTitle === 'accountancy' || selectedPackageTitle === 'archief') &&
        selectedProduct !== 'notaris' && (
          <Grid item>
            <img src="/ewise/assets/images/accreditations/rb-logo-homepage-2014.png" style={{ width: '120px' }} alt="" />
          </Grid>
        )}
      {currentDomain === 'pe' && selectedPackageTitle === 'juridisch medewerker notariaat' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/register-notarisklerk-somn_0.png" style={{ width: '120px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'pe' && selectedPackageTitle === 'archief' && selectedProduct === 'notaris' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/logo_knb_1.svg" style={{ width: '120px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'cme' &&
        (selectedPackageTitle === 'ouderengeneeskunde' || selectedPackageTitle === 'huisarts' || selectedPackageTitle === 'ge-bu') && (
          <Grid item>
            <img src="/ewise/assets/images/accreditations/accreditatiebureau_logo_-_175px.png" style={{ width: '120px' }} alt="" />
          </Grid>
        )}
      {currentDomain === 'cme' && (selectedPackageTitle === 'medicatiebeoordeling' || selectedPackageTitle === 'apotheker') && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/knmp.png" style={{ width: '175px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'cme' &&
        (selectedPackageTitle === 'apothekersassistent' || selectedPackageTitle === 'farmaceutisch consulent') && (
          <Grid item>
            <img src="/ewise/assets/images/accreditations/kabiz-accreditation-course-overview_0.png" style={{ width: '175px' }} alt="" />
          </Grid>
        )}
      {currentDomain === 'cme' && selectedPackageTitle === 'fysiotherapeut' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/kngf-en-keurmerk-fysio-2022.png" style={{ width: '270px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'cme' && selectedPackageTitle === 'mondhygiënist' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/KRM_logo-rgb-150.png" style={{ width: '100px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'cme' && selectedPackageTitle === 'tandarts' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/krt.svg" style={{ width: '100px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'cme' && selectedPackageTitle === 'verpleegkundig specialist' && (
        <Grid item>
          <img src="/ewise/assets/images/accreditations/vvn_0.png" style={{ width: '200px' }} alt="" />
        </Grid>
      )}
      {currentDomain === 'cme' &&
        (selectedPackageTitle === 'VIG / Verpleegkundige' || selectedPackageTitle === 'Helpende plus / Verzorgende') && (
          <Grid item>
            <img src="/ewise/assets/images/accreditations/logo-kr-volledig-fc.png" style={{ width: '200px' }} alt="" />
          </Grid>
        )}
      {currentDomain === 'ewise' && (
        <Grid item>
          <a href="https://www.nrto.nl" title="NRTO" target="_blank" rel="noreferrer">
            <img src="/ewise/assets/images/accreditations/logo-nrto.png" alt="NRTO" />
          </a>
        </Grid>
      )}
    </>
  );
};
export default observer(AccreditationPartners);
