import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { useTheme } from '@emotion/react';

// TABLE FILTERS COMPONENT
// - renders the table filters to be displayed in the table toolbar
const TableFilterItems = ({ options, clickHandler }) => {
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();

  // SX: custom ___________________________________
  const sxFilters = {
    background: '#ffffffa8',
    padding: '7px 10px!important',
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: '20px'
  };

  return (
    <Box sx={sxFilters}>
      <FormControlLabel
        labelPlacement="top"
        control={<Switch checked={options.filtering} onChange={() => clickHandler('filtering')} size="small" />}
        label={t('dashboard.filtering')}
      />
      <FormControlLabel
        labelPlacement="top"
        control={<Switch checked={options.grouping} onChange={() => clickHandler('grouping')} size="small" />}
        label={t('dashboard.grouping')}
      />
      <FormControlLabel
        labelPlacement="top"
        control={<Switch checked={options.padding === 'dense'} onChange={() => clickHandler('padding')} size="small" />}
        label={t('dashboard.dense')}
      />
    </Box>
  );
};

TableFilterItems.propTypes = {
  options: PropTypes.object,
  clickHandler: PropTypes.func
};
export default observer(TableFilterItems);
