/* Core */
import PropTypes from 'prop-types';

/* MUI */
import { Chip, Box, Tooltip } from '@mui/material';

// const drupalBaseUrl = process.env.REACT_APP_JSONAPI;
// renders a list of authors for a course card
const CourseAuthors = (props) => {
  const { authors } = props;
  if (!authors) return null;
  // show only 2 authors in Card
  const showAuthors = authors.length > 2 ? authors.slice(0, 2) : authors;

  // Authors > 2: map and join titles to show in tooltip
  let restAuthors = authors.length > 2 ? authors.slice(2) : [];
  restAuthors = restAuthors.length > 0 ? restAuthors.map((item) => item.title).join(', ') : '';

  return (
    <Box className="authors-wrapper">
      {showAuthors.map((author, index) => (
        <Tooltip title={author.title} arrow key={index}>
          <Chip
            className="chip_author"
            key={index}
            label={author.title}
            variant="outlined"
            sx={{
              height: 'auto',
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal'
              }
            }}
          />
        </Tooltip>
      ))}
      {authors.length - 2 > 0 && (
        <Tooltip title={restAuthors} arrow key={100}>
          <Chip className="chip_author" label={`+${authors.length - 2}`} variant="outlined" />
        </Tooltip>
      )}
    </Box>
  );
};

/* Our logo props */
CourseAuthors.propTypes = {
  authors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default CourseAuthors;
