/* eslint-disable camelcase */
/* Core */
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';

/* Custom styling */
import { Box, Grid } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

/* Custom components */
import { courseOverviewImgSize, getCourseCardProps, ThemeBrandTitle } from 'helpers/Helpers';
import BasicCard from './Card/CourseGridCard';
import BasicCardListMin from './Card/CourseGridCardListMin';
import NoCourses from './Card/elements/NoCourses';
import CourseTableCell from './Grid/CourseTable/CourseTableRow';

/**
The component first extracts the favorites array from the store context,
sorts it alphabetically by course title, and stores it in favoriteData.
It then sets some grid layout properties based on whether the courseoverview property in the store context is set to 3.
If there are no favorite courses, the component returns a NoCourses component with a message and an icon.
Otherwise, the component returns a Grid component with the favorite courses mapped to
BasicCardListMin, BasicCardList, or BasicCard components, depending on the courseoverview property.
The getCourseCardProps function is used to extract the necessary props for each course.
The key prop is set to the course ID, and the grid item properties are set based on the grid layout properties defined earlier.
 */
const CourseFavorites = () => {
  const store = useContext(Store);
  const { t } = useTranslation();
  const brandTitle = ThemeBrandTitle();
  const { U, ww } = brandTitle !== 'E-WISE' ? { U: 'U', ww: 'heeft' } : { U: 'Je', ww: 'hebt' };

  useEffect(() => {
    if (store.courseoverview === 1) {
      courseOverviewImgSize();
    }
  }, [store.courseoverview]); // Run this effect when the loads

  const favoriteData =
    store.favorites &&
    store.favorites.favorite_courses.slice().sort((a, b) => a.target_id.course_title.localeCompare(b.target_id.course_title));

  const isGridLayout = store.courseoverview === 3;

  // Container
  const gridContainerXS = 12;
  const gridContainerSM = isGridLayout ? 8 : 12;
  const gridContainerMD = 12;
  const gridContainerLg = 12;
  const gridContainerXl = 12;

  // Items
  const gridItemXs = 12;
  const gridItemSm = isGridLayout ? 4 : 12;
  const gridItemMd = isGridLayout ? 3 : 12;
  const gridItemLg = isGridLayout ? 6 : 12;
  const gridItemXl = isGridLayout ? 3 : 12;

  const setColumns = { xs: gridContainerXS, sm: gridContainerSM, md: gridContainerMD, lg: gridContainerLg, xl: gridContainerXl };

  if (!store?.favorites?.favorite_courses?.length) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <NoCourses text={t('courseoverview.noFavorites', { U, ww })} icon={<FavoriteIcon color="favoriteRed" />} />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={setColumns}>
        {favoriteData &&
          favoriteData.map((courses, index) => {
            const propsBasicCard = getCourseCardProps(courses.target_id);

            return (
              <React.Fragment key={index}>
                {store.courseoverview === 1 && (
                  <Grid item key={propsBasicCard.course_id} xs={12} sm={6} md={6} lg={6} xl={4}>
                    <BasicCardListMin {...propsBasicCard} />
                  </Grid>
                )}
                {store.courseoverview === 2 && (
                  <Grid item key={propsBasicCard.course_id} xs={gridItemXs} sm={gridItemSm} md={gridItemMd} lg={gridItemLg} xl={gridItemXl}>
                    <BasicCard {...propsBasicCard} />
                  </Grid>
                )}
                {store.courseoverview === 3 && (
                  <Grid
                    item
                    key={propsBasicCard.course_id}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ marginTop: 0, [`.card__container`]: { marginTop: 0 } }}
                  >
                    <CourseTableCell propsBasicCard={propsBasicCard} />
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
      </Grid>
    </Box>
  );
};

export default observer(CourseFavorites);
