/* eslint-disable camelcase */
/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useCallback, useState, useEffect, forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
/* MUI */
import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material';

const MyAutocompleteTaxonomy = forwardRef(({ name, control, label, ...otherProps }, fRef) => {
  const store = useContext(Store);
  const { t } = useTranslation('editCourseForm');
  const [objOptions, setOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const { professionParentId, professionParentId2 } = store.courseEdit.courseContainer;

  const node = 'category';
  //
  const cat1 = name.endsWith('category');
  const cat2 = name.endsWith('category2');
  const nid = cat1 ? professionParentId : cat2 && professionParentId2;
  //
  const parentId = nid;

  const correctTag = (tag, options) => (options.find((item) => item.id === tag.id) ? 'primary' : 'error');

  // CALLBACK FUNCTION TO FETCH DATA FROM API
  const fetchData = useCallback(async () => {
    try {
      const newFilter = `filter[parent.meta.drupal_internal__target_id][value]=${parentId}`;
      const { data: result } = await store.lmsApi.fetchIt(node, newFilter);
      const output = result.data.map((item) => ({ type: 'category', title: item.attributes.name, id: item.id }));
      setOptions(output);
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      const toastMsg = {
        title: t('serverTimeOut.title', 'Geen data gevonden'),
        content: t('serverTimeOut.message', 'Er zijn geen resultaten gevonden'),
        type: 'error',
        timeOut: 6000,
        variant: 'filled'
      };
      store.courseEdit.setToasts(toastMsg);
    }
  }, [parentId, store.courseEdit, store.lmsApi, t]);

  useEffect(() => {
    if (!parentId) return;
    setIsSearching(true);
    fetchData();
  }, [fetchData, parentId]);

  return (
    <Controller
      control={control}
      name={name}
      ref={fRef}
      defaultValue={[]}
      render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          disabled={otherProps.disabled}
          id="tags-outlined"
          freeSolo
          multiple
          autoComplete
          defaultValue={[]}
          onChange={(_, data) => onChange(data)}
          options={objOptions.sort((a, b) => -b.title.localeCompare(a.title))}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.title}
          filterSelectedOptions
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={index}
                variant="filled"
                color={correctTag(option, objOptions)}
                label={option.title}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              label={label}
              inputRef={ref}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      )}
    />
  );
});

MyAutocompleteTaxonomy.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  otherProps: PropTypes.object
};

export default observer(MyAutocompleteTaxonomy);
