import React from 'react';
import MainLayout from 'pages/mainLayout/MainLayout';
import { Lazy } from 'components';

const CourseIntroduction = React.lazy(() => import('../course/CourseIntroduction'));

const specialAccessIntroductionViewMap = {
  specialAccessCourseIntroduction: (
    <MainLayout footer background title="introduction.title">
      <Lazy noLayout>
        <CourseIntroduction />
      </Lazy>
    </MainLayout>
  )
};

export default specialAccessIntroductionViewMap;
