import MainLayout from 'pages/mainLayout/MainLayout';
import CurriculumLayout from 'pages/curriculum/CurriculumLayout';
import CurriculumStagesLayout from 'pages/curriculum/curriculumStages/curriculumStagesLayout';

const CurriculumStagesViewMap = {
  curriculumStages: (
    <MainLayout title="curriculum.stagesTitle" subtitle="curriculum.stagesSubtitle">
      <CurriculumLayout>
        <CurriculumStagesLayout />
      </CurriculumLayout>
    </MainLayout>
  )
};

export default CurriculumStagesViewMap;
