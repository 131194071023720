import MaterialTable, { MTableToolbar } from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { Box, Stack, Typography } from '@mui/material';
import { formatNumbersWithComma, ThemeBrandTitle } from 'helpers/Helpers';
import { observer } from 'mobx-react';
import { useRouterStore } from 'mobx-state-router';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { downloadExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import { Store } from 'state/ContextStore';

const mapRouteNameToSearchParamName = {
  LmsStudents: 'searchByNameOrEmail',
  LmsCourses: 'searchByNameOrEmail',
  LmsCoursesCompleted: 'searchByNameOrEmail',
  LmsCoursesOpenList: 'searchByNameOrEmail'
};

const LmsCoursesTable = ({ title, getData, options, ...delegated }) => {
  const { t } = useTranslation();
  const routerStore = useRouterStore();
  const { queryParams, routeName } = routerStore.routerState;
  const [tableOptions, setTableOptions] = useState({ filtering: false, padding: 'dense', pageSize: 50 });

  const tableRef = useRef();
  const mounted = useRef();

  const { org_id: orgId, profession, startdate, enddate, q } = queryParams;
  const searchParam = mapRouteNameToSearchParamName[routeName];

  const columns = getColumns({ routeName, t });

  const isLmsOrganisations = routeName === 'LmsOrganisations';
  const isLmsCourses = routeName === 'LmsCourses' || routeName === 'LmsCoursesCompleted' || routeName === 'LmsCoursesOpenList';
  const store = useContext(Store);
  const translateExportName = t(routeName);

  const exportFileName = `${ThemeBrandTitle()} - ${translateExportName} - ${new Date().toLocaleDateString()}`;

  // This useEffect is used to update the table when the user changes the fileds in LmsToolbar or use the search bar
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    tableRef.current?.onQueryChange({ page: 0, orgId, profession, startdate, enddate, [searchParam]: q, filters: [] });
  }, [orgId, profession, startdate, enddate, searchParam, q]);

  return (
    <Box
      id="lmsTable"
      sx={{
        paddingBottom: '20px',
        marginBlock: {
          xs: 8,
          sm: 6
        },
        '& .MuiTablePagination-toolbar': {
          maxWidth: '80vw'
        },
        '& .MuiPaper-root': { boxShadow: 'none' },
        h6: { color: '#2B3674', fontSize: '1.2931rem' },
        '& thead': {
          '& tr': {
            '& th': {
              whiteSpace: 'break-spaces',
              lineHeight: '18px',
              verticalAlign: 'bottom'
            }
          }
        }
      }}
    >
      <MaterialTable
        title={title}
        style={{ maxWidth: '100%' }}
        tableRef={tableRef}
        columns={columns}
        data={(query) => getData({ ...query, ...queryParams, orgId: queryParams.org_id })}
        components={{
          Toolbar: (props) => (
            <Box pl={2} pr={1} position="absolute" width="100%" top={-60}>
              <MTableToolbar {...props} />
            </Box>
          )
        }}
        actions={
          isLmsOrganisations || isLmsCourses
            ? undefined
            : [
                {
                  icon: 'filter_list',
                  tooltip: t('lms.actions.filter.tooltip'),
                  isFreeAction: true,
                  onClick: () => {
                    setTableOptions((prev) => {
                      if (prev.filtering) {
                        tableRef.current?.onQueryChange({ page: 0, orgId, profession, startdate, enddate, [searchParam]: q, filters: [] });
                      }
                      return { ...prev, filtering: !prev.filtering };
                    });
                  }
                }
              ]
        }
        onRowsPerPageChange={(_pageSize) => {
          store.setLmsRecordsPerPage(_pageSize);
          setTableOptions((prev) => ({ ...prev, pageSize: _pageSize }));
          return _pageSize;
        }}
        options={{
          showTitle: false,
          search: false,
          columnsButton: true,
          exportButton: true,
          paging: true,
          pageSize: store.getLmsRecordsPerPage() || tableOptions.pageSize,
          emptyRowsWhenPaging: false, // To avoid having empty rows
          pageSizeOptions: [10, 25, 50, 100, 250, 500, 1000], // rows selection options
          debounceInterval: 1000,
          filtering: tableOptions.filtering,
          padding: tableOptions.padding,
          idSynonym: 'customId',
          thirdSortClick: false,

          exportMenu: [
            {
              label: t('lms.actions.export.asPdf'),
              exportFunc: (cols, data) => ExportPdf(cols, data, exportFileName)
            },
            {
              label: t('lms.actions.export.asCsv'),
              exportFunc: (cols, data) => {
                const renderData = data.map((row) => formatNumbersWithComma(row, ['points', 'sum_points']));
                ExportCsv(cols, renderData, exportFileName);
              }
            },
            {
              label: t('lms.actions.export.asExcel'),
              exportFunc: (cols, data) => {
                const header = cols.map((col) => col.title);
                const renderData = data.map((row) => formatNumbersWithComma(row, ['points', 'sum_points']));
                downloadExcel({
                  fileName: exportFileName,
                  sheet: exportFileName,
                  tablePayload: {
                    header,
                    body: renderData
                  }
                });
              }
            }
          ],
          ...options
        }}
        localization={{
          toolbar: {
            addRemoveColumns: t('lms.actions.columns.title'),
            showColumnsTitle: t('lms.actions.columns.tooltip'),
            exportTitle: t('lms.actions.export.tooltip')
          },
          body: {
            emptyDataSourceMessage: t('lms.courses.allCourses.table.emptyDataSourceMessage')
          },
          pagination: {
            labelDisplayedRows: `{from}-{to} ${t('lms.table.pagination.labelDisplayedRows')} {count}`,
            labelRows: t('lms.table.pagination.labelRowsSelect'),
            labelRowsPerPage: t('lms.table.pagination.labelRowsPerPage'),
            firstTooltip: t('lms.table.pagination.firstTooltip'),
            previousTooltip: t('lms.table.pagination.previousTooltip'),
            nextTooltip: t('lms.table.pagination.nextTooltip'),
            lastTooltip: t('lms.table.pagination.lastTooltip')
          }
        }}
        {...delegated}
      />
    </Box>
  );
};

LmsCoursesTable.propTypes = {
  title: PropTypes.string,
  getData: PropTypes.func.isRequired,
  options: PropTypes.object
};

export default observer(LmsCoursesTable);

function getColumns({ routeName, t }) {
  /**
   I'm defining the columns here because if I pass it as a prop, the borwser freezes when we click on dense button or filter button.
   This is a bug in material-table
   */
  let columns = [];
  if (routeName.includes('LmsStudents')) {
    columns = [
      {
        title: t('lms.students.table.columns.firstName'),
        field: 'first_name'
      },
      {
        title: t('lms.students.table.columns.lastName'),
        field: 'last_name'
      },
      {
        title: t('lms.students.table.columns.email'),
        field: 'email'
      },
      {
        title: t('lms.students.table.columns.profession'),
        field: 'profession',
        filtering: false
      },
      {
        title: t('lms.students.table.columns.organisations'),
        field: 'orgName',
        filtering: false,
        sorting: false
      },
      {
        title: t('lms.students.table.columns.subscriptionEndDate'),
        field: 'subscription_date_end',
        filtering: false,
        type: 'date',
        hidden: true
      },
      {
        title: t('lms.students.table.columns.openCourses'),
        field: 'count_courses_open',
        type: 'numeric',
        filtering: false,
        width: 100
      },
      {
        title: t('lms.students.table.columns.completedCourses'),
        field: 'count_courses_completed',
        type: 'numeric',
        filtering: false,
        width: 100
      },
      {
        title: t('lms.students.table.columns.points'),
        field: 'sum_points',
        type: 'numeric',
        width: 100,
        filtering: false,
        render: (rowData) => (parseFloat(rowData.sum_points) ? parseFloat(rowData.sum_points).toFixed(2) : 0)
      }
    ];
  } else if (routeName === 'LmsCourses') {
    columns = [
      {
        title: t('lms.courses.allCourses.table.columns.name'),
        field: 'name',
        render: (rowData) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography component="span">{rowData.name}</Typography>
          </Stack>
        )
      },
      {
        title: t('lms.courses.allCourses.table.columns.date'),
        field: 'valid_until',
        type: 'date',
        width: 150,
        filtering: false,
        sorting: false
      },
      {
        title: t('lms.courses.allCourses.table.columns.open'),
        field: 'open_amount',
        type: 'numeric',
        width: 100,
        filtering: false
      },
      {
        title: t('lms.courses.allCourses.table.columns.completed'),
        field: 'completed_amount',
        type: 'numeric',
        width: 100,
        filtering: false
      },
      {
        title: t('lms.courses.allCourses.table.columns.points'),
        field: 'points',
        type: 'numeric',
        width: 100,
        filtering: false,
        render: (rowData) => (parseFloat(rowData.points) ? parseFloat(rowData.points).toFixed(2) : 0)
      }
    ];
  } else if (routeName === 'LmsCoursesCompleted') {
    columns = [
      {
        title: t('lms.courses.coursesByStatus.table.columns.name'),
        field: 'course_name',
        filtering: false,
        render: (rowData) => rowData.course_name
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.firstName'),
        field: 'first_name',
        filtering: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.lastName'),
        field: 'last_name',
        filtering: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.gender'),
        field: 'gender',
        render: (rowData) => (
          <Typography>{rowData.gender ? t(`lms.courses.coursesByStatus.table.columns.genderOptions.${rowData.gender}`) : '-'}</Typography>
        ),
        filtering: false,
        sorting: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.email'),
        field: 'mail',
        filtering: false,
        sorting: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.company'),
        field: 'company_name',
        filtering: false,
        sorting: false
      },

      {
        title: t('lms.courses.coursesByStatus.table.columns.points'),
        field: 'points',
        render: (rowData) => (parseFloat(rowData.points) ? parseFloat(rowData.points).toFixed(2) : 0),
        filtering: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.certificateDate'),
        field: 'certificate_date',
        type: 'date',
        filtering: false,
        sorting: true,
        defaultSort: 'desc'
      }
    ];
  }
  if (routeName === 'LmsCoursesOpenList') {
    columns = [
      {
        title: t('lms.courses.coursesByStatus.table.columns.name'),
        field: 'course_name',
        filtering: false,
        render: (rowData) => rowData.course_name
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.firstName'),
        field: 'first_name',
        filtering: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.lastName'),
        field: 'last_name',
        filtering: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.gender'),
        field: 'gender',
        render: (rowData) => (
          <Typography>{rowData.gender ? t(`lms.courses.coursesByStatus.table.columns.genderOptions.${rowData.gender}`) : '-'}</Typography>
        ),
        filtering: false,
        sorting: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.email'),
        field: 'mail',
        filtering: false,
        sorting: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.company'),
        field: 'company_name',
        filtering: false,
        sorting: false
      },
      {
        title: t('lms.courses.coursesByStatus.table.columns.validUntil'),
        field: 'valid_until',
        type: 'date',
        filtering: false,
        sorting: false
      },

      {
        title: t('lms.courses.coursesByStatus.table.columns.points'),
        field: 'points',
        filtering: false,
        render: (rowData) => (parseFloat(rowData.points) ? parseFloat(rowData.points).toFixed(2) : 0)
      }
    ];
  } else if (routeName === 'LmsOrganisations') {
    columns = [
      { title: t('lms.organisations.table.columns.name'), field: 'organisation_name', filtering: false, sorting: false },
      { title: t('lms.organisations.table.columns.students'), field: 'students', filtering: false, sorting: false }
    ];
  }
  return columns;
}
