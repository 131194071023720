import { useStore } from 'hooks';

export const useCurriculum = () => {
  const store = useStore();

  const curriculum = store.curriculum?.data ?? [];

  return {
    curriculum
  };
};
