import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CourseAccrEndDate = (props) => {
  const { t } = useTranslation();
  const { endDate } = props;

  if (!endDate) return null;

  const formattedEndDate = new Date(endDate).toLocaleDateString('nl-NL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
  return (
    <Alert severity="info" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {t('introduction.accreditationEndDate', { endDate: formattedEndDate })}
    </Alert>
  );
};

CourseAccrEndDate.propTypes = {
  endDate: PropTypes.string
};

export default CourseAccrEndDate;
