/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { convertBlobString2File } from 'helpers/image.helper';
/* MUI */
import { Box, Skeleton } from '@mui/material';

// renders the course image to be displayed in the preview dialog
const sxPreviewGradient = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'linear-gradient(90deg,#002318,#003927 38.54%,rgba(0,104,72,0))'
};

const CourseImageHeader = ({ previewValues }) => {
  let src = false;
  const headerPhoto = previewValues?.lessonContainer?.relationships?.field_course_header_photo;
  const isHeaderPhotoObject = headerPhoto instanceof Object;
  if (!isHeaderPhotoObject && headerPhoto.includes('http')) {
    src = headerPhoto;
  }
  src = headerPhoto?.data ? convertBlobString2File(headerPhoto.data) : src;

  return (
    <Box className="preview_image_header">
      <Box className="previewGradient" sx={sxPreviewGradient} />
      <Box className="preview_title" sx={{ zIndex: 1, wordWrap: 'break-word', maxWidth: '65%' }}>
        {previewValues?.course?.attributes?.title}
        <Box sx={{ fontSize: '20px', fontWeight: '400', fontStyle: 'italic' }}>
          {previewValues?.lessonContainer?.attributes.field_extra_label}
        </Box>
      </Box>
      {src && <img src={src} alt="test1" width="100%" />}
      {!previewValues?.lessonContainer?.relationships?.field_course_header_photo && (
        <Skeleton variant="rectangular" animation="wave" width="100%" height={300} />
      )}
    </Box>
  );
};

CourseImageHeader.propTypes = {
  previewValues: PropTypes.object
};

export default observer(CourseImageHeader);
