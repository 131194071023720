/* eslint-disable react/prop-types */
import { Box, Button, LinearProgress, TextField, Typography } from '@mui/material';
import { rawHTML } from 'helpers/Helpers';
import { observer } from 'mobx-react';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactWordcloud from 'react-wordcloud';

// import 'tippy.js/dist/tippy.css';
// import "tippy.js/animations/scale.css";

const WordCloudWidget = (props) => {
  const { store, widgetCode } = props;
  const { t } = useTranslation('widget');
  const widget = store.lessonPlay.getWordCloud?.(widgetCode);
  const isFetching = useRef(false);
  const [inputValues, setInputValues] = useState([]);

  useEffect(() => {
    if (!widget && !isFetching.current) {
      isFetching.current = true;
      store.lessonPlay.fetchWordCloud(widgetCode);
    }
  }, [store.lessonPlay, widget, widgetCode]);

  if (!widget) {
    return null;
  }

  const checkIfDuplicateExists = (arr) => new Set(arr).size !== arr.length;
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (!inputValues[0]) {
        store.showToast({ message: t('edit.wordcloud.error.required'), variant: 'error', vertical: 'bottom' });
        return;
      }
      if (checkIfDuplicateExists(inputValues.filter(Boolean))) {
        store.showToast({ message: t('edit.wordcloud.error.duplicate'), variant: 'error', vertical: 'bottom' });
        return;
      }
      // happy path
      store.lessonPlay.wordCloudList.submitAnswers(widget, inputValues);
    } catch (error) {
      store.showToast({ message: t('edit.wordcloud.error.failed'), variant: 'error', vertical: 'bottom' });
    }
  };

  const handleOnChange = (e, i) => {
    const { value } = e.target;
    const newInput = [...inputValues];
    newInput[i] = value;

    setInputValues(newInput);
  };

  if (widget.wordcloud.user_has_answered >= 1) {
    const formatWords = widget.wordcloud.words.map(({ word: value, count }) => ({
      text: value.slice(0, 20),
      value: count
    }));

    const options = {
      colors: ['#1A237E', '#ff7f0e', '#039BE5'],
      enableTooltip: true,
      deterministic: false,
      fontFamily: 'impact',
      fontSizes: [13, 60],
      fontStyle: 'normal',
      fontWeight: 'normal',
      padding: 1,
      rotations: 3,
      rotationAngles: [0, 90],
      scale: 'sqrt',
      spiral: 'archimedean',
      transitionDuration: 1000
    };

    return (
      <>
        <EditWidgetButton store={store} action="edit" id={widgetCode} />
        <Box display="flex" flexDirection="column" padding="13px" gap="4px">
          <Typography variant="label" fontSize="1rem" fontWeight="700">
            {rawHTML(widget.wordcloud.body)}
          </Typography>
          <Box textAlign="center">
            <ReactWordcloud options={options} words={formatWords} />
            {/* <TagCloud tags={formatWords} minSize={12} maxSize={50} colorOptions={options} style={{ padding: '5' }} /> */}
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <Box spacing={2} display="flex" flexDirection="column" padding={1} gap={2}>
        <Typography variant="label" fontSize="1rem">
          {rawHTML(widget.wordcloud.body)}
        </Typography>
        <Box onSubmit={handleSubmit} component="form" noValidate display="flex" flexDirection="column" alignItems="center" gap={2} pt={2}>
          {Array(widget.wordcloud.show_fields)
            .fill()
            .map((_, i) => (
              <TextField
                key={i}
                name={i.toString()}
                label={i === 0 ? t('wordcloud.insertWord', 'Insert a word') : t('wordcloud.insertAnotherWord', 'Insert another word')}
                onChange={(e) => handleOnChange(e, i)}
                sx={{ width: '70%', [`& .MuiInputBase-root`]: { borderRadius: '10px' } }}
                inputProps={{ maxLength: 33 }}
                required={i === 0}
                InputLabelProps={{
                  shrink: true
                }}
                disable={store.lessonPlay.pageNo === 0}
              />
            ))}
          {checkIfDuplicateExists(inputValues.filter(Boolean)) && (
            <Typography> {t('wordcloud.duplicate', 'Every word should be unique')}</Typography>
          )}
          <Button type="submit" variant="contained" color="primary" disable={store.lessonPlay.pageNo === 0}>
            {t('wordcloud.submit', 'Submit')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default observer(WordCloudWidget);
