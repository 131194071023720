import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { useHeaderContext } from '../context';
import * as Styled from './HeaderComponents.styles';

const SpecialAccessButton = () => {
  const { t } = useTranslation();

  const { handleGoToSpecialAccessOverview } = useHeaderContext();

  return (
    <Tooltip title="Special Access" arrow>
      <Styled.Button id="go-to-special-access" aria-label="" onClick={handleGoToSpecialAccessOverview}>
        {t('course.leftSidebar.specialAccess')}
      </Styled.Button>
    </Tooltip>
  );
};

export default observer(SpecialAccessButton);
