/* Core */
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useContext } from 'react';
import PropTypes from 'prop-types';

/* MUI */
import { Box, Typography, Tooltip, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CourseIntroductionHeadline = ({ title }) => {
  const { t } = useTranslation();
  const store = useContext(Store);

  const goBackRoute = () => {
    const { product, productName, packageId } = store.params;
    const defineRoute = store?.params?.teacherId ? 'teachersOverview' : 'courseOverview';
    if (store.params?.productName === 'special') {
      store.routerStore.goTo('specialAccess', { params: {}, queryParams: {} });
      return;
    }
    store.routerStore.goTo(defineRoute, { params: { product, productName, packageId } });
  };

  const defineTooltip = store?.params?.teacherId ? 'GoBackTeachers' : 'GoBackCourseOverview';

  return (
    <Box>
      <Tooltip title={t(defineTooltip)} arrow>
        <Button
          id="course-overview-back-button"
          aria-label=""
          onClick={goBackRoute}
          startIcon={<ArrowBackIcon />}
          sx={{ textTransform: 'inherit' }}
        >
          {t(defineTooltip)}
        </Button>
      </Tooltip>
      <Box className="course_overview_headline_title" style={{ width: 'auto' }}>
        <Typography
          variant="h1"
          component="h1"
          id="courseOverviewTitle"
          color="primaryBlue.main"
          fontSize="19px"
          alignSelf="start"
          width="100%"
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

CourseIntroductionHeadline.propTypes = {
  title: PropTypes.any
};

export default observer(CourseIntroductionHeadline);
