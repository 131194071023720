/* Course points */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/* MUI */
import { Box, Badge } from '@mui/material';
import { translatePoints, GetTimeLabel } from 'helpers/Helpers';

// renders the course points
const CoursePoints = (props) => {
  const { points } = props;
  const { t } = useTranslation();
  const noBadge = true;
  const coursePoints = points.split(' + ');
  const splitPoints = points.split(' ');
  const getHours = parseFloat(splitPoints[0]);
  const isMinutes = getHours < 1 && splitPoints[1]?.includes('ur');
  let badgeContent = 0;

  if (coursePoints.length > 1) {
    // eslint-disable-next-line prefer-destructuring
    badgeContent = coursePoints[1];
  }

  if (points === '') {
    return '';
  }

  if (noBadge) {
    return (
      <Box className="duration_icon onboarding-course-points" sx={{ backgroundColor: 'primary.main' }}>
        {isMinutes ? GetTimeLabel(getHours, true) : translatePoints(points, t)}
      </Box>
    );
  }

  return (
    <Badge
      className="duration_badge"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      color="secondary"
      badgeContent={badgeContent}
    >
      <Box className="duration_icon" sx={{ backgroundColor: 'primary.main' }}>
        {isMinutes ? GetTimeLabel(getHours, true) : translatePoints(points, t)}
      </Box>
    </Badge>
  );
};

CoursePoints.propTypes = {
  points: PropTypes.string
};

export default CoursePoints;
