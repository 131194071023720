/* Core */
import PropTypes from 'prop-types';

/* MUI */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

// renders a LinearProgress with a label
const LinearProgressWithLabel = ({ value, color, ...props }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" {...props} value={value} color={color} />
    </Box>
    <Box sx={{ minWidth: 35 }} className="linear_label">
      <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
    </Box>
  </Box>
);

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string
};

export default LinearProgressWithLabel;
