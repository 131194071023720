/* Core */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import { rawHTML } from 'helpers/Helpers';
/* MUI */
import { Box, Typography } from '@mui/material';

const TrialCoursesBaseProdIntroText = () => {
  const { t } = useTranslation();
  const store = useStore();
  const { product } = store;

  if (!product?.free_product) return null;

  const subscribeText = product?.trial_overview_text ? rawHTML(product?.trial_overview_text) : t('trials.coursesView.subscriptionText');

  return (
    <Box sx={{ marginTop: '16px', paddingTop: '16px' }}>
      <Box>
        <Typography sx={{ fontSize: '32px', padding: '0px 16px' }} variant="h4" component="h2" color="primary">
          {t('trials.coursesView.allCourses')}: {store.courses.courses.length}
        </Typography>
      </Box>
      <Box sx={{ padding: '0px 16px' }}>
        {!product?.trial_overview_text && <Typography component="div">{t('trials.coursesView.allcoursesText')}</Typography>}
        <Typography sx={{ [` p`]: { margin: '0px' } }} component="div">
          {subscribeText}
        </Typography>
      </Box>
    </Box>
  );
};

export default observer(TrialCoursesBaseProdIntroText);
