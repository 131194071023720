import { Button, CircularProgress } from '@mui/material';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

/* MUI - Icons */
import IconPlay from '@mui/icons-material/PlayCircleFilled';
import { isCourseFailed } from 'helpers/Helpers';
import { useState } from 'react';
import CoursePointsAlert from './CoursePointsAlert';

const CourseButton = () => {
  const store = useStore();
  const { t } = useTranslation();
  // Get course
  const course = store.findCourseByNid(Number(store.params.courseId));
  // Get accreditation
  const accredited =
    store.selectedPackageId !== 0
      ? store?.baseProduct?.packages?.find((packag) => packag.nid === store?.selectedPackageId).accredited
      : false;
  // Calculate enough points
  const coursePoints = store?.subscriptions?.enoughPoints(store?.baseProduct?.nid, store?.params?.courseId);
  const maxPoints = store?.subscriptions?.maxPointsReached(store?.baseProduct?.nid);
  const lessons = course?.lessons;
  const msg = maxPoints ? 'msg-2' : 'msg-1';
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    // Check if we need to show the popup based on accreditation
    // We check if we have enough points to finish the course
    // If not show popup, or just continue automatically
    if (!store.isEwiseUser && accredited && !coursePoints) {
      setOpen(true);
    } else {
      playLesson();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    playLesson();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { isCourseStarted, isCourseFinished } = store.courseResults;

  /* Check for enddate */
  const endDate = course?.valid_period_end;
  const courseExpired = new Date().getTime() >= new Date(endDate).setHours(23, 0, 0, 0);

  if (store.lessonState === 'pending') {
    return (
      <Button fullWidth disabled variant="contained" className="start_button">
        <CircularProgress size={30} color="inherit" />
      </Button>
    );
  }

  // const playSpecialAccessLesson = () => {
  //   store.routerStore.goTo('defLessonPlay', {
  //     params: {
  //       productName: 'special',
  //       product: 'access',
  //       packageId: '0',
  //       courseId: store.params.courseId,
  //       lessonId: lessons[0].nid?.toString(),
  //       pageNo: '1'
  //     }
  //   });
  // };

  // ONCLICK BUTTON TO PLAY LESSON
  // eslint-disable-next-line consistent-return
  const playLesson = () => {
    // if (store.courses.isSpecialAccessView) return playSpecialAccessLesson();
    if (lessons && Array.isArray(lessons) && lessons.length) {
      if (lessons.length > 1) {
        const lastStartedCourse = store?.lesson_results?.findLessonByCourseNid(Number(store.params.courseId)).length;
        if (lastStartedCourse) {
          store.routerStore.goTo('defLessonPlay', {
            params: {
              product: store.params.product,
              productName: store.params.productName,
              packageId: store.params.packageId,
              courseId: store.params.courseId,
              lessonId: lessons[lastStartedCourse - 1].nid?.toString(),
              pageNo: '1'
            }
          });
        }
      }
      store.routerStore.goTo('defLessonPlay', {
        params: {
          product: store.params.product,
          productName: store.params.productName,
          packageId: store.params.packageId,
          courseId: store.params.courseId,
          lessonId: lessons[0].nid?.toString(),
          pageNo: '1'
        }
      });
    }
  };
  const sequenceNo = store?.lesson_results?.findLessonByCourseNid(course.course_id)[0]?.sequence_no;
  const maxAttemptsReached = lessons[0]?.max_attempts && lessons[0]?.max_attempts - 1 <= sequenceNo;
  const retry =
    isCourseFailed(store.lesson_results, store.courseResults, course.course_id) && !maxAttemptsReached
      ? t('course.button.label4')
      : t('course.button.label5');
  const isFinished = isCourseFinished(course.course_id) ? t('course.button.label2') : t('course.button.label1');
  const isFailed = isCourseFailed(store.lesson_results, store.courseResults, course.course_id) ? retry : t('course.button.label3');
  const courseBtn = isCourseStarted(course.course_id) ? isFailed : isFinished;
  const isExpected = course.course_status.toLowerCase() === 'expected';
  //
  // SET BTN LABEL
  let btn = '';
  if (courseBtn === 'Start') {
    btn = (
      <>
        <Button onClick={handleButtonClick} fullWidth variant="contained" className="start_button" disabled={!lessons.length}>
          {courseBtn} <IconPlay style={{ fontSize: '30px' }} />
        </Button>
        <CoursePointsAlert msg={msg} open={open} handleClose={handleClose} handleConfirm={handleConfirm} />
      </>
    );
  }
  if (courseBtn === 'Retry' || courseBtn === 'Herstart') {
    btn = (
      <>
        <Button onClick={handleButtonClick} fullWidth variant="contained" className="start_button" disabled={!lessons.length}>
          {courseBtn} <IconPlay style={{ fontSize: '30px' }} />
        </Button>
        <CoursePointsAlert msg={msg} open={open} handleClose={handleClose} handleConfirm={handleConfirm} />
      </>
    );
  }
  if (courseBtn === 'Continue' || courseBtn === 'Hervat') {
    btn = (
      <>
        <Button onClick={handleButtonClick} fullWidth variant="contained" className="start_button">
          {courseBtn} <IconPlay style={{ fontSize: '30px' }} />
        </Button>
        <CoursePointsAlert msg={msg} open={open} handleClose={handleClose} handleConfirm={handleConfirm} />
      </>
    );
  }
  if (courseBtn === 'No more retries' || courseBtn === 'Geen pogingen meer') {
    btn = (
      <Button fullWidth disabled variant="contained" className="start_button">
        {courseBtn}
      </Button>
    );
  }
  if (courseBtn === 'Done' || courseBtn === 'Afgerond') {
    btn = (
      <Button onClick={playLesson} fullWidth variant="contained" className="start_button">
        {courseBtn}
      </Button>
    );
  }
  if (courseExpired) {
    btn = '';
  }
  if (isExpected && !store.isEwiseUser) {
    btn = '';
  }
  return btn;
};

export default observer(CourseButton);
