import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import NeedsWorkIcon from '@mui/icons-material/Error';
import { Box, Typography, Tooltip } from '@mui/material';

import * as Types from '../../../types';
import { evaluationIsComplete } from '../../../utils';

const EvaluationHeader = ({ goal, evaluation }) => {
  const { t } = useTranslation('curriculum');

  return (
    <Box sx={{ display: 'flex', padding: '1rem', justifyContent: 'space-between', borderBottomWidth: '1px' }}>
      <Typography
        sx={{
          lineHeight: '1.5rem',
          marginBlock: 'auto'
        }}
      >
        {goal.attributes.name}
      </Typography>
      {evaluationIsComplete(evaluation) ? (
        <Tooltip title={t('evaluations.complete')}>
          <CheckIcon sx={{ color: 'primary.main' }} />
        </Tooltip>
      ) : (
        <Tooltip title={t('evaluations.needsWork')}>
          <NeedsWorkIcon sx={{ color: 'warning.main' }} />
        </Tooltip>
      )}
    </Box>
  );
};

EvaluationHeader.propTypes = {
  goal: Types.LearningGoal.isRequired,
  evaluation: Types.Evaluation.isRequired
};

export default observer(EvaluationHeader);
