import { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useBreakpoint, useStore } from 'hooks';
import { shouldShowSidebar } from 'helpers/Helpers';

export const useHeader = () => {
  const store = useStore();

  const showSidebar = shouldShowSidebar(store);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'sm' || breakpoint === 'xs';
  const isXs = breakpoint === 'xs';

  const [anchorProducts, setAnchorProducts] = useState(null);
  const [anchorAccount, setAnchorAccount] = useState(null);
  const [anchorQuickLinks, setAnchorQuickLinks] = useState(null);
  const [anchorNotification, setAnchorNotification] = useState(null);

  const isMenuProductsOpen = Boolean(anchorProducts);
  const isMenuAccountOpen = Boolean(anchorAccount);
  const isMenuQuickLinksOpen = Boolean(anchorQuickLinks);

  const productName = store.subscriptions?.subscriptions.filter((subscription) => subscription.product === store.selectedProductId)[0];
  const hasSubscriptions = store.subscriptions?.subscriptions?.length > 0;

  // Show or hide dynamic menu
  const uniqueProductTitles = new Set();
  store.subscriptions?.subscriptions?.forEach((subscription) => {
    const productNameDef = subscription.base_product_title.toLowerCase();
    if (!uniqueProductTitles.has(productNameDef)) {
      uniqueProductTitles.add(productNameDef);
    }
  });
  const showProductMenu = uniqueProductTitles.size > 1;

  const handleMenuClose = () => {
    setAnchorProducts(null);
    setAnchorAccount(null);
    setAnchorQuickLinks(null);
    setAnchorNotification(null);
  };

  function handleMenuProductsOpen(event) {
    if ((store.products.length > 1 && showProductMenu) || !store.isEwiseUser) {
      setAnchorProducts(event.currentTarget);
      return;
    }

    store.routerStore.goTo('selectProduct');
  }

  const handleCloseNotification = () => {
    setAnchorNotification(null);
  };
  const handleNotification = (event) => {
    setAnchorNotification(event.currentTarget);
  };

  const handleMenuSelect = (link) => () => {
    handleMenuClose();
    store.routerStore.goTo(link.routeName, { params: link.params, queryParams: link.queryParams });
  };

  const handleMenuSelectProduct = (link, handleChangeProduct) => () => {
    handleMenuClose();
    store.clearTabClicked();
    handleChangeProduct();
    store.routerStore.goTo(link.routeName, { params: link.params, queryParams: link.queryParams });
  };

  const handlePopoverClose = (event) => {
    event.persist();
    let menuOpen;
    let menuOpenTimeout;
    const elTarget = event.currentTarget;
    const elChild = event.relatedTarget;

    if (menuOpen) clearTimeout(menuOpenTimeout);

    menuOpenTimeout = setTimeout(() => {
      if (elChild && elChild.parentNode === elTarget) {
        // eslint-disable-next-line no-use-before-define
        handleMenuClose();
      }
    }, 1300);
  };

  const handleGoToLMS = () => {
    if (store.showMenuAssistant) {
      store.routerStore.goTo('LmsStudents');
    } else {
      store.routerStore.goTo('LmsDashboardOverview');
    }
  };

  const handleGoToAssistant = () => {
    store.routerStore.goTo('assistantManagement');
  };

  const handleGoToCourseOverview = () => {
    window.location.href = '/';
  };

  const handleGoToSpecialAccessOverview = () => {
    store.routerStore.goTo('specialAccess');
  };

  function handleMenuAccountOpen(event) {
    setAnchorAccount(event.currentTarget);
  }

  function handleMenuQuickLinksOpen(event) {
    setAnchorQuickLinks(event.currentTarget);
  }

  return {
    showSidebar,
    handleMenuSelect,
    anchorAccount,
    anchorProducts,
    anchorNotification,
    handleCloseNotification,
    handleMenuProductsOpen,
    showProductMenu,
    isMenuProductsOpen,
    handleMenuClose,
    handlePopoverClose,
    handleMenuAccountOpen,
    isMenuAccountOpen,
    hasSubscriptions,
    handleGoToSpecialAccessOverview,
    handleGoToCourseOverview,
    handleMenuSelectProduct,
    handleNotification,
    productName,
    handleGoToAssistant,
    handleGoToLMS,
    isMobile,
    isXs,
    isSmallScreen,
    isMenuQuickLinksOpen,
    anchorQuickLinks,
    handleMenuQuickLinksOpen
  };
};
