/* eslint-disable react-hooks/exhaustive-deps,prettier/prettier */
import PropTypes from 'prop-types';

/**
 * This middleware is for determining whether you are entering
 * the application through a protected route. once the application has
 * loaded these checks are not performed anymore
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ProtectedRoutesMiddleware = ({ children, LoaderComponent }) => {

  /**
   * these are the protected routes that
   * should be handled by the backend
   */
  const protectedRoutes = [
      new RegExp('/user/trial/activate(?:/(.+))?'),
      new RegExp('/user/reset/[0-9]+/[0-9]+/.+')
  ];

  let routeFound = false;
  protectedRoutes.forEach((routeregex) => {
    const currLocation = window.location.pathname;
    if (routeFound === false && routeregex.exec(currLocation) !== null) {
      routeFound = true;
      window.location.href = process.env.REACT_APP_JSONAPI + currLocation;
    }
  })

  return (
    <>
      {!routeFound && children}
      {routeFound && <LoaderComponent isLoading msg="LOADING" />}
    </>
  );
};

ProtectedRoutesMiddleware.propTypes = {
  children: PropTypes.object.isRequired,
  LoaderComponent: PropTypes.any
};

export default ProtectedRoutesMiddleware;
