import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, Stack } from '@mui/material';

import { Form } from 'components/form';
import { MySelect, MyTextField } from 'components/form/RHF';
import { useStore } from 'hooks';
import { Dialog } from '../../../components';
import * as Styles from '../../../components/styles';
import * as Types from '../../../types';
import { useEvaluations } from '../../useEvaluations';

const AddActivityDialog = ({ goal, evaluation, visible, onCloseCallback }) => {
  const classes = Styles.useCurriculumStyles();
  const { t } = useTranslation('curriculum');
  const store = useStore();

  const { courseResults } = useEvaluations();

  const coursesForSelect = courseResults?.map((course) => ({
    name: course.attributes.name,
    value: course.id
  }));

  const handleSubmit = async ({ customActivityText = '', courseId = '' }) => {
    const attributes = customActivityText
      ? {
          ...evaluation.attributes,
          activities: [...evaluation.attributes.activities, customActivityText]
        }
      : evaluation.attributes;

    const relationships = customActivityText
      ? evaluation.relationships
      : {
          ...evaluation.relationships,
        courses: { data: [...evaluation.relationships.courses.data, { id: courseId, type: 'course_result' }] }
      };

    const data = {
      ...evaluation,
      attributes,
      relationships
    };

    store.startLoadCurriculum();
    const result = await store.createOrUpdateCurriculumEvaluation(goal, data);
    await store.refreshCurrentCurriculum();
    store.stopLoadCurriculum();

    if (!result) {
      return {
        success: false,
        errors: [{ name: 'root.serverError', error: { type: 'server', message: 'Internal Server Error' }, options: {} }]
      };
    }

    if (result.errors) {
      return {
        success: false,
        errors: result.errors
      };
    }

    onCloseCallback();

    return { success: true };
  };

  const handleClose = () => {
    onCloseCallback();
  };

  const submitRef = useRef();

  const initialValues = useMemo(
    () => ({
      courseId: '',
      customActivityText: ''
    }),
    []
  );

  const validationSchema = Yup.object().shape(
    {
      courseId: Yup.string().when('customActivityText', {
        is: (val) => val?.trim().length === 0,
        then: Yup.string().required()
      }),
      customActivityText: Yup.string().when('courseId', {
        is: (val) => val?.trim().length === 0,
        then: Yup.string().required()
      })
    },
    [['courseId', 'customActivityText']]
  );

  return (
    <Dialog
      action={{
        action: () => {
          submitRef.current.click();
        },
        name: t('dashboard.saveLabel'),
        icon: 'ant-design:plus-outlined',
        isLoading: store.isLoadingCurriculum
      }}
      title={t('evaluations.addActivityTitle')}
      name="add-activity"
      visible={visible}
      onClose={handleClose}
    >
      <Form id="add-activity" onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ control, errors, setValue }) => (
          <Stack justifyContent="space-between" sx={{ padding: '8px' }} spacing={2}>
            {coursesForSelect.length > 0 ? (
              <MySelect
                sx={Styles.SelectBox}
                control={control}
                label={t('evaluations.selectCourseDropdownLabel')}
                name="courseId"
                items={coursesForSelect}
                multiple={false}
                helperText={errors?.courseId?.message}
                error={!!errors?.courseId}
                onChange={(e) => {
                  setValue('courseId', e.target.value);
                  setValue('customActivityText', '');
                }}
                inputProps={{ classes: { paper: classes.mySelect } }}
              />
            ) : (
              <MyTextField disabled label={t('evaluations.noCoursesPlaceholder')} sx={Styles.TextField} />
            )}

            <label htmlFor="customActivityText" style={{ fontWeight: '600', display: 'none' }}>
              {t('evaluations.activityTextfieldTooltipLabel')}
            </label>
            <MyTextField
              control={control}
              label={null}
              name="customActivityText"
              multiline
              rows={5}
              placeholder={t('evaluations.activityTextfieldTooltipLabel')}
              sx={Styles.TextField}
              helperText={errors?.customActivityText?.message}
              error={!!errors?.customActivityText}
              onChange={(e) => {
                setValue('customActivityText', e.target.value);
                setValue('courseId', '');
              }}
            />
            <Button sx={{ display: 'none' }} ref={submitRef} type="submit">
              {t('general.addBtnLabel')}
            </Button>
          </Stack>
        )}
      </Form>
    </Dialog>
  );
};

AddActivityDialog.propTypes = {
  goal: Types.LearningGoal.isRequired,
  evaluation: Types.Evaluation.isRequired,
  visible: PropTypes.bool.isRequired,
  onCloseCallback: PropTypes.func.isRequired
};

export default observer(AddActivityDialog);
