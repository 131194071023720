import { Tooltip, Box } from '@mui/material';
import PodcastsIcon from 'components/icons/PodcastsIcon';
import PropTypes from 'prop-types';

const PodcastIcon = (props) => {
  const { sx, style, ...otherProps } = props;

  let width = sx?.width ?? '1em';
  let height = sx?.height ?? '1em';

  if (sx?.width && !sx?.height) {
    height = 'auto';
  }

  if (sx?.height && !sx?.width) {
    width = 'auto';
  }

  return (<Box sx={[...(Array.isArray(sx) ? sx : [sx])]} { ...{ className: 'podcast_wrapper', ...otherProps }}>
    <Tooltip title="Podcast" arrow>
      <Box>
        <PodcastsIcon style={{...{ fill: '#dd7901', width, height, margin: 'auto', display: 'block' }, ...style}} />
      </Box>
    </Tooltip>
  </Box>)
};

PodcastIcon.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  style: PropTypes.object
};

PodcastIcon.defaultProps = {
  sx: [],
  style: {}
};

export default PodcastIcon;
