import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useContext, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
/* MUI */
import { Box, IconButton, Button, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
/* Dialog */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

/* ICONS */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import BlockIcon from '@mui/icons-material/Block';
import MenuShowInPlayer from 'pages/adminCourses/components/lesson/MenuShowInPlayer';
/* HELPERS */
import LoaderWithBackdrop from '../../components/loading/LoaderWithBackdrop';
import { getTotalWidgets, isOdtFile } from '../EditCourse.helper';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

/*
  In summary, this component primarily focuses on rendering a list of lessons associated with a course. 
  It provides options to add new lessons, displays various details about each lesson, and allows users to play or edit individual lessons. 
  Additionally, it checks for the presence of ODT files and displays appropriate icons for each lesson.
*/

const LessonsEditForm = ({ isDisabled }) => {
  const { t } = useTranslation('editCourseForm');
  const store = useContext(Store);
  // states
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteLesson, setDeleteLesson] = useState({});
  // objectdestructuring
  const { courseEdit } = store;
  const { included } = courseEdit.currentCourse;
  const { lessonWidgets } = courseEdit;

  // workaround to always have a prefBaseProduct when opening a course
  localStorage.setItem('latestPrefBaseProduct', store?.login?.prefBaseProduct || '');

  const lessons = courseEdit.currentLessonContainer.relationships.lessons.data.map((item) => item.id);
  const courseId = courseEdit.currentCourse.attributes.drupal_internal__nid.toString();
  const url = `/redactie/${store.params.packageNid}/${courseEdit.currentCourse.id}/course/${courseId}`;

  const isOnline = courseEdit.currentCourse.attributes.online;
  const isExpected = courseEdit.currentCourse.attributes.expected;
  const hasMoreThanOneLesson = lessons.length > 1;

  // disable delete lesson button
  // disable if the course is online and has only one lesson
  const disableLessonDelete = isOnline || (!isOnline && !hasMoreThanOneLesson);

  // delete lesson toast
  const toast = {
    success: {
      title: t('deleteLesson.toast.success.title'),
      content: t('deleteLesson.toast.success.message'),
      type: 'success'
    },
    error: {
      title: t('deleteLesson.toast.error.title'),
      content: t('deleteLesson.toast.error.message'),
      type: 'error'
    },
    default: { timeOut: 4000, variant: 'filled' }
  };

  // workaround to update the table when a widget is added or removed
  const { currentLessonWidgets } = store.courseEdit;
  const tableUpdate = currentLessonWidgets !== 'test' ? `updated: ${currentLessonWidgets}` : '';

  // Open delete dialog ___________________________________________________
  const handleClickOpen = (courseDetails) => {
    setDeleteLesson(courseDetails);
    setOpen(true);
  };
  // Close delete dialog ___________________________________________________
  const handleClose = () => {
    setDeleteLesson({});
    setOpen(false);
  };
  // EVENT: Delete Lesson ___________________________________________________
  const handleDeleteLesson = async () => {
    setOpen(false);
    setLoading(true);
    try {
      await courseEdit.deleteLessonFromLessonContainer(deleteLesson);
      courseEdit.setToasts({ ...toast.success, ...toast.default });
    } catch (error) {
      courseEdit.setToasts({ ...toast.error, ...toast.default });
    } finally {
      setLoading(false);
    }
  };

  // EVENT: GotoPLay ________________________________________________________
  const handleGotoPlay = (lesson, pageNr) => {
    const lessonId = lesson.attributes.drupal_internal__nid.toString();
    //
    store.routerStore.goTo('adminCourseLessonPlay', {
      params: {
        productName: 'redactie',
        product: store.params.packageNid,
        packageId: courseEdit.currentCourse.id,
        courseId,
        lessonId,
        pageNo: pageNr.toString()
      }
    });
  };

  // TRANSLATION labels ____________________________________________________
  const labels = {
    play: t('lesEditForm.play'),
    widgets: t('lesEditForm.widgets'),
    title: t('lesEditForm.title'),
    lessonType: t('lesEditForm.lessonType'),
    freeFlow: t('lesEditForm.freeFlow'),
    rotatingAnswers: t('lesEditForm.rotatingAnswers'),
    firstAnswerNofeedback: t('lesEditForm.firstAnswerNoFeedback'),
    firstAnswerVisualfeedback: t('lesEditForm.firstAnswerVisualFeedback'),
    minimumScore: t('lesEditForm.minimScore'),
    minimumScore2ndTry: t('lesEditForm.minimumScore2ndTry'),
    OdtUploaded: t('lesEditForm.odtUploaded'),
    maxAttempts: t('lesEditForm.maxAttempts')
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <LoaderWithBackdrop open={loading} />
      <Box sx={{ display: 'none' }} id="tableupdate">
        {tableUpdate}
      </Box>
      <Button
        disabled={isDisabled('lessonContent') || (isOnline && !isExpected)}
        onClick={() => courseEdit.setDialogLesson()}
        sx={sxNewCourseBtn}
        startIcon={<AddCircleIcon />}
        variant="outlined"
        color="primary"
      >
        {t('lesEditForm.btnNew')}
      </Button>
      <TableContainer sx={sxTable}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <ClearIcon color="error" fontSize="small" />
              </TableCell>
              <TableCell>{labels.title}</TableCell>
              <TableCell align="right">{labels.lessonType}</TableCell>
              <TableCell align="right">{labels.freeFlow}</TableCell>
              <TableCell align="right">{labels.maxAttempts}</TableCell>
              <TableCell align="right">{labels.minimumScore}</TableCell>
              <TableCell align="right">{labels.minimumScore2ndTry}</TableCell>
              <TableCell align="right">{labels.OdtUploaded}</TableCell>
              <TableCell align="right">{labels.widgets}</TableCell>
              <TableCell align="right">{labels.play}</TableCell>
              <TableCell align="right">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lessons.length === 0 && (
              <TableRow key="noItems" sx={{ background: '#607d8b1c', '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontSize: '17px', textAlign: 'center', fontWeight: '300' }} component="th" scope="row" colSpan={10}>
                  {t('lesEditForm.noLessons')}
                </TableCell>
              </TableRow>
            )}
            {lessons.length > 0 &&
              lessons.map((row) => (
                <TableRow key={row.id} sx={{ background: '#607d8b1c', '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="td" scope="row">
                    <Tooltip title="Delete lesson">
                      <Box>
                        <IconButton
                          onClick={() => handleClickOpen(row)}
                          aria-label="delete"
                          size="small"
                          color="error"
                          disabled={disableLessonDelete || !!isOdtFile(row, included)}
                        >
                          <ClearIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {row.attributes.title}
                  </TableCell>
                  <TableCell align="right">{row.attributes.lesson_type}</TableCell>
                  <TableCell align="right">{row.attributes.free_flow}</TableCell>
                  <TableCell align="right">{row.attributes.max_attempts}</TableCell>
                  <TableCell align="right">{row.attributes.minimum_score}</TableCell>
                  <TableCell align="right">{row.attributes.minimum_score_2nd_try}</TableCell>
                  <TableCell align="right">
                    {isOdtFile(row, included) ? <DescriptionIcon color="primary" /> : <BlockIcon sx={{ color: 'silver' }} />}
                  </TableCell>
                  <TableCell align="right">{getTotalWidgets(row, included, lessonWidgets)}</TableCell>
                  <TableCell align="right">
                    <MenuShowInPlayer
                      disabled={!isOdtFile(row, included)}
                      gotoPlay={handleGotoPlay}
                      row={row}
                      url={`${url}/lesson/${row.attributes.drupal_internal__nid}/page`}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      disabled={isDisabled('lessonContent')}
                      onClick={() => courseEdit.setDialogLesson(row.id)}
                      color="warning"
                      aria-label="Edit Lesson"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* --- delete lesson dialog --- */}
      <Dialog TransitionComponent={Transition} open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { padding: '0!important' } }}>
        <DialogTitle sx={{ m: 0, p: 1 }}>{t('deleteLesson.title')}</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ p: 1 }}>
            <Box>{t('deleteLesson.message')}</Box>
            <Box sx={{ fontWeight: '500', color: 'black', fontSize: '1.2rem' }}>{deleteLesson?.attributes?.title || 'Subscribe'}</Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {t('deleteLesson.btnCancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleDeleteLesson}>
            {t('deleteLesson.btnDelete')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialog */}
    </Box>
  );
};

LessonsEditForm.propTypes = {
  isDisabled: PropTypes.func
};

export default observer(LessonsEditForm);

// CUSTOM sx _________________________________________________________________
const sxNewCourseBtn = {
  marginTop: '10px',
  alignSelf: 'end',
  border: '7px solid #eef2fa',
  borderRadius: '20px',
  '&:hover': { border: '7px solid #eef2fa' }
};
const sxTable = { marginTop: '20px', '.MuiTableCell-head': { background: '#607d8b40', border: '3px solid white', padding: '16px' } };
