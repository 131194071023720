import PropTypes from 'prop-types';
import { VtbTooltip } from '../VtbTooltip';
import { LocationContent } from './LocationContent';
import { LocationButtonBase } from './LocationButtonBase';

export const LocationButtonDesktop = ({ location }) => (
  <VtbTooltip arrow placement="right-start" title={<LocationContent location={location} />}>
    <LocationButtonBase>{location.attributes.name}</LocationButtonBase>
  </VtbTooltip>
);

LocationButtonDesktop.propTypes = {
  location: PropTypes.any.isRequired
};
