import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { rawHTML } from '../../../helpers/Helpers';
import 'theme/course/courseoverview/PackageTabs.scss';

const ListItems = (open) => {
  const store = useContext(Store);

  const menuLinks = [];
  const { routerState } = store.routerStore;

  const handleMenuSelect = (link) => () => {
    store.setPrevTab(store.selectedPackageId);
    store.setSelectedPackage(parseInt(link.params.packageId));
    store.setStoreValue('courseHeaderTabs', 'All');
    store.courses.resetFilter();
    store.routerStore.goTo(link.routeName, { params: link.params, queryParams: {} });
  };

  const selectedPackageLabel = store.packages.filter((pack) => pack.nid === store.selectedPackageId);

  menuLinks.push(
    ...store.packages.map((packageref) => ({
      routeName: 'courseOverview',
      title: packageref.tab_label,
      label: (
        // store.product.packageClicked[index] ? (
        //   <div className="lists">
        //     <BarChartIcon />
        //     {open && packageref.tab_label}
        //   </div>
        // ) :
        <div className="lists">
          <span className="abr">{packageref.tab_label.substring(0, 2)}</span>
          <div> {packageref.tab_label} </div>
        </div>
      ),
      params: {
        productName: store.baseProduct.unique_name,
        product: store.isEwiseUser ? store.baseProduct.nid.toString() : store.product.nid.toString(),
        packageId: packageref.nid.toString()
      }
    }))
  );
  return menuLinks.map((link, index) => {
    const { label } = link;

    if (
      link.params &&
      link.routeName === routerState.routeName &&
      link.params.product === routerState.params.product &&
      link.params.packageId === routerState.params.packageId
    ) {
      return (
        <ListItemButton
          className="package-item"
          key={index + 1}
          component="a"
          selected={store.selectedPackageId.toString() === link.params.packageId}
          onClick={(event) => {
            event.preventDefault();
          }}
          sx={{
            '&.Mui-selected': { backgroundColor: 'primary.main' }
          }}
        >
          <ListItemText
            primary={
              <div className="tabLabel">
                {
                  <>
                    <span className="abr">{link.label.props.children[0].props.children.substring(0, 2)}</span>
                    {rawHTML(`<b>${link.label.props.children[1].props.children[1]}</b>`)}
                    <div className="tabs_badge">{/* {store.product.coursesByPackage[index]} */}</div>
                  </>
                }
              </div>
            }
          />
        </ListItemButton>
      );
    }
    return (
      <ListItemButton
        className="package-item"
        key={index + 1}
        component="a"
        selected={selectedPackageLabel[0]?.tab_label === link.title}
        onClick={handleMenuSelect(link)}
        sx={{
          '&.Mui-selected.MuiButtonBase-root:hover': { backgroundColor: 'primary.main', opacity: '0.6' },
          '&.MuiListItemButton-root': { backgroundColor: !open && 'background.main' },
          // '&.MuiListItemButton-root': { backgroundColor: !open && 'transparent' },
          '&.Mui-selected': { backgroundColor: 'primary.main' }
        }}
      >
        <ListItemText primary={label} />
      </ListItemButton>
    );
  });
};
const PackageList = (props) => {
  const { open } = props;
  const store = useContext(Store);

  useEffect(() => {
    if (store.prevTab === 0) {
      store.setPrevTab(store.selectedPackageId);
    }
  }, [store]);
  return <List className="onboarding-navbar-packages"> {ListItems(open)}</List>;
};

PackageList.propTypes = {
  open: PropTypes.bool
};

export default observer(PackageList);
