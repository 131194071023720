import { ThemeBrandTitle } from 'helpers/Helpers';
import { checkUserSignedIn } from 'state/RoutesActions';

export const errorPageRoute = {
  name: 'errorPage',
  pattern: '/error-status',
  title: `Foutmelding | ${ThemeBrandTitle}`,
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    if (store.state !== 'done') await checkUserSignedIn(fromState, toState, routerStore);
  }
};
