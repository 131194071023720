import { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, TextField, InputAdornment, Slider } from '@mui/material';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import { decideUnitPos, formatWith, isCurrency } from 'helpers/Helpers';

/*
In summary, the Numerical component is a flexible numerical input component that can be customized to display either a slider or a text field.
It allows users to input numerical values and can be used for various purposes, including selecting a value within a specified range. 
The component is designed to be easily integrated into different parts of an application and offers options for customization and internationalization.
*/
const Numerical = (props) => {
  const { answer, handleInput, disabled, options: propOptions, value } = props;
  const [options, setOptions] = useState(propOptions);
  const { t } = useTranslation('widget');

  const field = {
    input: { type: 'text', variant: 'outlined', name: 'answer', label: t('Answer', 'Answer'), InputLabelProps: { shrink: true } }
  };

  const handleSliderChange = (e, newValue) => {
    handleInput(newValue);
    if (!Array.isArray(newValue)) return setOptions({ ...options, value: newValue });
    return setOptions({ ...options, value: newValue[0], valueEnd: newValue[1] });
  };

  const handleInputChange = (val) => {
    handleInput(val);
    setOptions({ ...options, value: val });
  };

  const marks = [
    { value: options.sliderMin, label: decideUnitPos(options.unit, options.sliderMin) },
    { value: options.sliderMax, label: decideUnitPos(options.unit, options.sliderMax) }
  ];

  const sliderProps = {
    min: options.sliderMin,
    max: options.sliderMax,
    step: options.sliderStep,
    valueLabelDisplay: 'on',
    marks
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={2} alignItems="center">
        {propOptions.type === 'slider' && (
          <Grid item>
            <SwapHoriz />
          </Grid>
        )}
        <Grid item xs>
          {propOptions.type === 'slider' ? (
            <Slider
              valueLabelFormat={formatWith(options.unit)}
              defaultValue={value}
              onChange={handleSliderChange}
              {...sliderProps}
              disabled={disabled}
              aria-labelledby="iSlider"
            />
          ) : (
            <TextField
              InputProps={
                isCurrency(options.unit)
                  ? { startAdornment: <InputAdornment position="start">{options.unit}</InputAdornment> }
                  : { endAdornment: <InputAdornment position="end">{options.unit}</InputAdornment> }
              }
              defaultValue={value}
              value={answer.current}
              onChange={(e) => {
                const val = e.target.value.match(/^\d*\.?\d*$/);
                if (!val) return;
                handleInputChange(val[0]);
              }}
              {...field.input}
              disabled={disabled}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Numerical.propTypes = {
  options: PropTypes.object,
  props: PropTypes.object,
  handleInput: PropTypes.func,
  answer: PropTypes.number,
  value: PropTypes.number,
  disabled: PropTypes.bool
};

export default observer(Numerical);
