import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Children } from 'react';

import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';

import MobileMenuButton from './MobileMenuButton';
import { useHeaderContext } from '../context';

const QuickLinksMenu = ({ children }) => {
  const { anchorQuickLinks, isMenuQuickLinksOpen, handleMenuClose, handlePopoverClose } = useHeaderContext();

  const arrayChildren = Children.toArray(children);
  if (arrayChildren.length === 0) return null;

  return (
    <>
      <MobileMenuButton />
      <Menu
        id="mobile-menu"
        anchorEl={anchorQuickLinks}
        open={isMenuQuickLinksOpen}
        onClose={handleMenuClose}
        onMouseOut={handlePopoverClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock
        sx={{ '& .MuiMenu-paper': { borderRadius: '15px' } }}
      >
        {arrayChildren.map((child, index) => (
          <MenuItem
            key={index}
            sx={{
              '& .MuiButtonBase-root': {
                background: 'transparent !important',
                justifyContent: 'left'
              }
            }}
          >
            {child}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

QuickLinksMenu.propTypes = {
  children: PropTypes.node
};

export default observer(QuickLinksMenu);
