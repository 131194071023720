import React from 'react';
import MainLayout from 'pages/mainLayout/MainLayout';
import { Lazy } from 'components';
import LmsLayout from '../LmsLayout';

const LmsCourseDetail = React.lazy(() => import('./LmsCourseDetail'));

const LmsCourseDetailViewMap = {
  LmsCourseDetail: (
    <MainLayout title="lms.navbar.courses" subtitle="">
      <LmsLayout>
        <Lazy noLayout>
          <LmsCourseDetail />
        </Lazy>
      </LmsLayout>
    </MainLayout>
  )
};

export default LmsCourseDetailViewMap;
