import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
/* MUI */
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box } from '@mui/material';

const BtnPreviewIntroduction = ({ handleClickDialog, getValues }) => (
  <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }} onClick={() => handleClickDialog(getValues())}>
    <VisibilityIcon size="large" color="primary" />
    Preview
  </Box>
);

BtnPreviewIntroduction.propTypes = {
  handleClickDialog: PropTypes.func,
  getValues: PropTypes.func
};

export default observer(BtnPreviewIntroduction);
