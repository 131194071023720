/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
/* MUI */
import { Box } from '@mui/material';

import CourseAuthor from './CourseAuthor';
import CourseSubHeaders from './CourseSubHeaders';

// container component for the authors of a course
// - renders a list of authors of a course to be displayed in the preview dialog
const CourseAuthors = ({ previewValues }) => {
  const authors = previewValues.lessonContainer.relationships.field_authors;
  if (!authors.length) return '';
  const title = authors.length > 1 ? 'Docenten' : 'Docent';
  return (
    <Box sx={{ border: '1px solid #eee', padding: '15px', borderRadius: '20px' }}>
      <CourseSubHeaders title={title} />
      <Box>
        {authors.map((author) => (
          <Box key={author.id}>
            <CourseAuthor author={author} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

CourseAuthors.propTypes = {
  previewValues: PropTypes.object
};

export default observer(CourseAuthors);
