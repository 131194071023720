import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';

const MenuItemAccount = ({ handleMenuSelect }) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      key="account"
      id="account"
      onClick={handleMenuSelect({
        routeName: 'profile',
        params: {},
        queryParams: {}
      })}
    >
      {t('My Account')}
    </MenuItem>
  );
};

MenuItemAccount.propTypes = { handleMenuSelect: PropTypes.func.isRequired };

export default observer(MenuItemAccount);
