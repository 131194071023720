/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Avatar, IconButton, Popover, MenuItem, Link } from '@mui/material';
import { isStaging } from 'helpers/Helpers';

const ToolbarAccount = () => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');
  const { canGotoAdminPanel, isAdministrator } = store;
  // popover
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  /* EVENTS */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuSelect = (link) => () => {
    store.routerStore.goTo(link.routeName, { params: link.params, queryParams: link.queryParams });
  };

  return (
    <>
      <IconButton onClick={handleClick} {...iconButton}>
        <Avatar src={store.login.profilePicture} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        slotProps={{ paper: paperDrops }}
      >
        <MenuItem
          key="account"
          id="account"
          onClick={handleMenuSelect({
            routeName: 'profile',
            params: {},
            queryParams: {}
          })}
        >
          {t('appBar.accountMenu.myAccount')}
        </MenuItem>
        <Divider />
        {isAdministrator && (
          <Box>
            <MenuItem
              key="admin"
              id="amdin"
              onClick={handleMenuSelect({
                routeName: 'gotoadmin',
                title: 'Backend',
                params: {}
              })}
            >
              {t('appBar.accountMenu.backendAdmin')}
            </MenuItem>
            <Divider />
          </Box>
        )}
        {canGotoAdminPanel && (
          <Box>
            <MenuItem key="adminPanel" id="adminPanel">
              <Link
                href={isStaging() ? 'https://app.aws-staging.e-wise.it' : 'https://app.admin.po-online.nl'}
                target="_blank"
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                rel="noreferrer"
              >
                Admin panel
              </Link>
            </MenuItem>
            <Divider />
          </Box>
        )}
        <MenuItem key="logout" id="logout" onClick={handleMenuSelect({ routeName: 'logout' })}>
          {t('appBar.accountMenu.logout')}
        </MenuItem>
      </Popover>
    </>
  );
};

export default observer(ToolbarAccount);

const paperDrops = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: '50px',
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};

const iconButton = {
  sx: { padding: '0px' },
  size: 'small',
  'aria-label': 'account of current user',
  'aria-controls': 'menu-appbar',
  'aria-haspopup': 'true',
  color: 'inherit'
};
