import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { IconButton, Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import useIsComponentMounted from '../../hooks/useIsComponentMounted';

const MenuShowInPlayer = ({ url, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  //
  const isComponentMounted = useIsComponentMounted();
  //
  const handleClick = (event) => {
    if (isComponentMounted.current) setAnchorEl(event.currentTarget);
  };
  //
  const handleClose = () => {
    if (isComponentMounted.current) setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        disabled={disabled}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <PlayCircleIcon color="primary" sx={{ fontSize: '30px', color: `${disabled ? 'silver' : 'primary'}` }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem>
          <Button value="0" href={`${url}/0`} target="_blank" rel="noopener">
            Start page 0
          </Button>
        </MenuItem>
        <MenuItem>
          <Button value="1" href={`${url}/1`} target="_blank" rel="noopener">
            Start page 1
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};

MenuShowInPlayer.propTypes = {
  disabled: PropTypes.bool,
  url: PropTypes.string
};

export default observer(MenuShowInPlayer);
