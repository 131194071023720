import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

// TODO: Only used in customTable and openInvitesLyout.If we decide to not use it remove from components

const EnhancedTooltip = ({ tooltipText, icon }) => (
  <Tooltip title={tooltipText} arrow placement="top">
    {!!icon && icon}
  </Tooltip>
);

EnhancedTooltip.propTypes = {
  tooltipText: PropTypes.string,
  icon: PropTypes.element
};

export default EnhancedTooltip;
