import MainLayout from 'pages/mainLayout/MainLayout';
import Accountv2 from '../Accountv2';
import ProfileContainer from '../components/ProfileContainer';

const ProfileViewMap = {
  profile: (
    <MainLayout title="account.profile.title" subtitle="">
      <Accountv2>
        <ProfileContainer />
      </Accountv2>
    </MainLayout>
  )
};

export default ProfileViewMap;
