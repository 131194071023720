/* eslint-disable camelcase */
/* Core */
import { Store } from 'state/ContextStore';
import { useContext, useEffect, useState, memo } from 'react';

/* MUI */
import { Box, Typography, Button, Chip } from '@mui/material';
import Slider, { CarouselItem } from 'components/common/Slider';
import { replaceSliderCssClass, minifyCss } from 'helpers/Helpers';

const drupalBaseUrl = process.env.REACT_APP_JSONAPI;

const Banner = () => {
  const store = useContext(Store);

  const [loading, setLoading] = useState(false);
  const selProductId = store.bannerProducts.length > 0 ? store.bannerProducts.join() : store.selectedProductId.toString();

  useEffect(() => {
    const fetchBannerData = async () => {
      setLoading(true);
      await store.fetchBanner(selProductId);
      setLoading(false);
    };

    fetchBannerData();
  }, [selProductId, store]);

  return (
    <>
      {!loading && store.banners && store.banners.length > 0 && (
        <Box className="banner">
          <Box width="100%">
            <Slider>
              {store.banners.map((banner, index) => {
                const { description, title, button_link, button_title } = banner.attributes;
                const image = banner.relationships.background_image.data;
                const bannerImage = image && image.attributes.uri.url;
                const imageUrl = `${drupalBaseUrl}${bannerImage}`;
                const customCss = banner.attributes?.field_custom_css || '';
                const chipLabel = banner.attributes?.chip || '';
                return (
                  <CarouselItem key={index} img={imageUrl}>
                    <Box className="carousel_container">
                      <Box>
                        {chipLabel && <Chip label={chipLabel} color="primary" className="bannerChip" />}
                        {title && (
                          <Typography variant="h1" fontSize="34px" fontWeight="700" color="white" className="bannerTitle">
                            {title}
                          </Typography>
                        )}
                        {description && (
                          <Typography fontSize="16px" fontWeight="500" color="#E3DAFF" className="bannerDescription">
                            {description}
                          </Typography>
                        )}
                        {button_title && (
                          <Button
                            variant="contained"
                            className="bannerButton"
                            onClick={() => {
                              // eslint-disable-next-line no-unused-expressions
                              button_link && window.open(button_link, '_blank');
                            }}
                          >
                            {button_title || ' Discover more'}
                          </Button>
                        )}
                      </Box>
                      <style>{replaceSliderCssClass(minifyCss(customCss), 'bannerContainer', `children_box_${index}`)}</style>
                    </Box>
                  </CarouselItem>
                );
              })}
            </Slider>
          </Box>
        </Box>
      )}
    </>
  );
};

export default memo(Banner);
