import { types as t } from 'mobx-state-tree';

export const TextField = t.model('TextField', {
  format: t.maybeNull(t.string),
  value: t.string,
  processed: t.string
});

export const IsoDate = t.maybeNull(
  t.reference(t.Date, {
    get(identifier /* ISO 8601 string */ /* parent: any */) {
      return new Date(identifier);
    },
    set(value /* Date */) {
      return value.toISOString();
    }
  })
);

/**
 * The base model for Drupal's  default Body field type
 */
export const Body = t
  .model('Body', {
    format: t.string,
    value: t.string,
    processed: t.maybe(t.string),
    summary: t.maybeNull(t.string)
  })
  .views((self) => ({
    get cleanValue() {
      const currentVal = self.value;
      return currentVal.replace(/(<([^>]+)>)/gi, ''); // sonarcloud flase positive on regex
    }
  }));

/**
 * The base model for Drupal's Link field type
 * it provides custom logic for ensuring
 * The field is clean
 */
export const Link = t
  .model('Link', {
    options: t.array(t.string),
    title: t.string,
    uri: t.string
  })
  .views((self) => ({
    get cleanValue() {
      const currentVal = self.uri;

      /**
       * Currently we only clean the drupal added identifier
       * but this abstraction layer is nescesary for
       * the future
       */
      return currentVal.replaceAll(/internal:/gi, '');
    },
    get isExternal() {
      const currentVal = self.uri;

      /**
       * Currently we only clean the drupal added identifier
       * but this abstraction layer is nescesary for
       * the future
       */
      return currentVal.includes('http://') || currentVal.includes('https://');
    }
  }));

export const DateRange = t.model('DateRange', {
  value: t.string,
  end_value: t.string
});

export const Links = t.model('Links', {
  self: t.model({ href: t.string }),
  related: t.maybe(t.model({ href: t.string })),
  prev: t.maybe(t.model({ href: t.string })),
  next: t.maybe(t.model({ href: t.string }))
});

export const SpecialAccess = t.model('SpecialAccess', {
  id: t.identifier,
  type: t.string,
  attributes: t.model({
    drupal_internal__tid: t.number,
    name: t.string
  })
});

export const RelSpecialAccess = t.model('RelSpecialAccess', {
  data: t.maybeNull(
    t.array(
      t.model({
        type: t.string,
        id: t.reference(SpecialAccess)
      })
    )
  )
});

export const Profession = t.model('Profession', {
  id: t.identifier,
  type: t.string,
  links: Links,
  attributes: t.model({
    drupal_internal__tid: t.number,
    name: t.string,
    field_ewise_labels: t.maybeNull(t.string)
  }),
  relationships: t.model({
    field_categories_parent_term: t.maybeNull(
      t.model({
        data: t.model({
          type: 'category',
          id: t.string,
          meta: t.model({
            drupal_internal__target_id: t.number
          })
        })
      })
    ),
    field_categories2_parent_term: t.maybeNull(
      t.model({
        data: t.model({
          type: 'category',
          id: t.string,
          meta: t.model({
            drupal_internal__target_id: t.number
          })
        })
      })
    )
  })
});

export const RelProfession = t.model('RelProfession', {
  data: t.maybeNull(
    t.model({
      id: t.reference(Profession),
      type: t.string
    })
  )
});

export const RelProfessions = t.model('RelProfessions', {
  data: t.array(
    t.model({
      id: t.reference(Profession),
      type: t.string
    })
  )
});

export const File = t.model('File', {
  id: t.identifier,
  type: t.string,
  attributes: t.model({
    drupal_internal__fid: t.number,
    filemime: t.string,
    filename: t.string,
    filesize: t.number,
    status: t.boolean,
    uri: t.model({
      url: t.string
    })
  })
});

export const RelFile = t
  .model('RelFile', {
    data: t.maybeNull(
      t.model({
        id: t.reference(File),
        type: t.string
      })
    )
  })
  .views((self) => ({
    get url() {
      return self.data ? self.data.id.attributes.uri.url : '';
    }
  }));

export const RelCatParent = t.model('RelCatParent', {
  data: t.array(
    t.model({
      id: t.string,
      type: t.string
    })
  )
});

export const Category = t.model('Category', {
  type: t.string,
  id: t.identifier,
  attributes: t.model({
    drupal_internal__tid: t.number,
    langcode: t.string,
    name: t.string,
    symbol: t.maybeNull(t.string)
    // description: t.maybeNull(TextField)
  }),
  relationships: t.model({
    parent: t.maybeNull(RelCatParent)
  })
});

export const RelCategory = t.model('RelCategory', {
  data: t.maybeNull(
    t.model({
      id: t.reference(Category),
      type: t.string
    })
  )
});

export const RelCategories = t
  .model('RelCategories', {
    data: t.maybeNull(
      t.array(
        t.model({
          type: t.string,
          id: t.reference(Category)
        })
      )
    )
  })
  .views((self) => {
    const categoryrefs = [];
    return {
      afterAttach() {
        self.data.map((category) => categoryrefs.push(category.id));
      },
      get categories() {
        return categoryrefs;
      }
    };
  });

export const Relation = t.model('Relation', {
  data: t.maybeNull(
    t.model({
      id: t.string,
      type: t.string
    })
  )
});

export const UserRole = t.model('UserRole', {
  type: t.string,
  id: t.identifier,
  attributes: t.model({
    drupal_internal__id: t.string,
    label: t.string,
    is_admin: t.maybeNull(t.boolean),
    // status: t.boolean,
    permissions: t.array(t.string)
    // weight: t.number
  })
});

export const RelUserRole = t.model('RelUserRole', {
  data: t.maybeNull(
    t.array(
      t.model({
        type: t.string,
        id: t.reference(UserRole)
      })
    )
  )
});
