import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { FormHelperText } from '@mui/material';
import { PropTypes } from 'prop-types';
import { Controller } from 'react-hook-form';

// renders a Checkbox
// eslint-disable-next-line react/prop-types
const MyCheckbox = (props) => {
  const { name, label, control, helperText, ...otherProps } = props;

  // props config for Checkbox
  const configCheckbox = {
    ...otherProps
  };

  return control ? (
    <Controller
      name={name}
      control={control}
      shouldUnregister
      render={({ field }) => (
        <FormControl>
          <FormGroup>
            <FormControlLabel
              labelPlacement={otherProps?.labelplacement}
              control={<Checkbox {...field} {...configCheckbox} checked={field.value} />}
              label={label}
            />
          </FormGroup>
          <FormHelperText className={helperText && 'Mui-error'}>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  ) : (
    <FormControl>
      <FormGroup>
        <FormControlLabel control={<Checkbox {...configCheckbox} name={name} />} label={label} />
      </FormGroup>
    </FormControl>
  );
};

MyCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object,
  helperText: PropTypes.string
};

export default MyCheckbox;
