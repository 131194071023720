/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
/* MUI */
import { Alert, AlertTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
/*
In summary, the Toast component provides a way to display toast notifications with varying types, content, and timeout durations. 
Users can dismiss the toast notifications manually, or they will automatically disappear after a specified time. 
It's a common UI element for providing feedback and information to users in an application.
*/
// severity => info, warning, error, success
const Toast = ({ toast, close }) => {
  const toastType = toast?.type || 'info';
  const bgColor = toast.variant === 'outlined' ? '#ffffffeb' : null;
  /* close toast after x sec */
  useEffect(() => {
    setTimeout(() => {
      close(toast.id);
    }, toast.timeOut);
  }, [close, toast.id, toast.timeOut]);

  return (
    <ClickAwayListener onClickAway={() => close(toast.id)}>
      <Alert
        sx={{ minWidth: '500px', boxShadow: '0px 0px 10px #00000085', marginTop: '10px', backgroundColor: bgColor }}
        key={toast.id}
        variant={toast.variant}
        severity={toastType}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => close(toast.id)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>{toast.title}</AlertTitle>
        {toast.content}
      </Alert>
    </ClickAwayListener>
  );
};

Toast.propTypes = {
  toast: PropTypes.object,
  close: PropTypes.func
};

export default observer(Toast);
