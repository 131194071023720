import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './custom-quill.css';
import { useMemo, useState } from 'react';
import { Switch, Stack, Box, FormControlLabel } from '@mui/material';

const Editor = ({
  field: { name },
  form: { control }, // also touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const [showHtml, setShowHtml] = useState(false);

  const modules = useMemo(
    () => ({
      clipboard: {
        matchVisual: false // prevents inserting unnecessary <p><br /></p> tags
      },
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link'],
          ['clean'],
          ['showHtml']
        ],
        handlers: {
          showHtml: () => {
            setShowHtml(!showHtml);
          }
        }
      }
    }),
    [showHtml]
  );

  return (
    <>
      <Stack {...stackProps}>
        <Box className={classes.label}>{label}</Box>
        <FormControlLabel
          sx={sxFormControlLabel}
          control={<Switch size="small" checked={showHtml} onClick={() => setShowHtml(!showHtml)} />}
          label="Show html"
        />
      </Stack>
      {showHtml && <Controller name={name} control={control} render={({ field }) => <textarea style={{ width: '100%' }} {...field} />} />}
      {!showHtml && (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value } }) => (
            <ReactQuill
              modules={modules}
              readOnly={!!disabled}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              style={{ opacity: disabled ? 0.4 : 1 }}
              {...props}
            />
          )}
        />
      )}
    </>
  );
};

Editor.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default Editor;

// custom sxStackProps _________________________________
const stackProps = { direction: 'row', justifyContent: 'space-between', alignItems: 'flex-end' };
const sxFormControlLabel = { mt: 1, '.MuiFormControlLabel-label': { fontSize: '13px' } };

const useStyles = makeStyles({
  editorFrame: {
    borderStyle: 'solid',
    borderColor: '#c4c4c4',
    borderWidth: '1px',
    borderRadius: '4px',
    minHeight: '100px',
    cursor: 'text',
    overflow: 'auto',
    '&:hover': { borderColor: 'black' }
  },
  label: {
    color: 'gray',
    fontSize: 'smaller',
    marginTop: '-6px',
    marginLeft: '8px',
    background: 'white',
    padding: '0px 5px',
    lineHeight: '13px'
  }
});
