import { types as t, destroy, getSnapshot } from 'mobx-state-tree';
import cloneDeep from 'lodash/cloneDeep';
import { QuestionWidget } from './QuestionWidget';
import { LessonContentEdit } from './LessonContent';
import { File } from './BaseModel';

export const LessonEdit = t
  .model('LessonEdit', {
    widgets: t.maybeNull(t.array(QuestionWidget)),
    lesson_content: t.maybeNull(LessonContentEdit),
    tagdoc: t.maybeNull(File)
  })
  .views((self) => ({
    get currentOdt() {
      return self.tagdoc ? cloneDeep(getSnapshot(self.tagdoc)) : false;
    },
    get uploadEndPoint() {
      return self.lesson_content ? { type: self.lesson_content.type, id: self.lesson_content.id } : false;
    }
  }))
  .actions((self) => ({
    update(newEntity, key = 'widgets') {
      if (self[key] && self[key].length >= 0) {
        const existingEntity = self[key].find((entity) => entity.id === newEntity.id);
        if (existingEntity) {
          destroy(existingEntity);
          self[key].push(newEntity);
        } else if (!existingEntity) {
          self[key].push(newEntity);
        }
      } else {
        self[key] = newEntity;
      }
    }
  }));
