import { ThemeBrandTitle } from 'helpers/Helpers';
import { getEwapi, getProfile } from 'state/RoutesActions';
import { getLessonPlay } from 'state/models/LessonPlay';

const BrandTitle = ThemeBrandTitle();

export const termsLesRoute = {
  name: 'terms',
  pattern: '/:productName/:product/:packageId/course/:courseId/lesson/:lessonId/evaluation/terms',
  meta: { title: `%%introduction.metatitle%% | ${BrandTitle}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    await getEwapi(fromState, toState, routerStore);
    getProfile(fromState, toState, routerStore);
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { courseId } = toState.params;
    store.courseResults.updateCourseResultbyNid(Number(courseId));
    await getLessonPlay(store, toState.params.courseId, toState.params.lessonId);
  }
};
