/* Core */
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

/* MUI */
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import GroupIcon from '@mui/icons-material/Group';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Box, Stack, Typography } from '@mui/material';

/* Custom components */
import ListComponent from 'components/common/List';
import { Store } from 'state/ContextStore';

const MyCompany = () => {
  const { t } = useTranslation();
  const store = useContext(Store);

  const showVTBTeacher = !!store.login.isVtbTeacher;
  const showVTB = (store.isEwiseUser && store.showMenuPortfolio) || store.product?.nid === 8065;
  const showMenuItems = store.showMenuMyCompany || showVTB || showVTBTeacher || store.showMenuPortfolio;

  if (!showMenuItems) {
    return null;
  }

  return (
    <Box>
      {store.showMenuMyCompany && (
        <Box className="my_company">
          <Typography fontSize="18px" color="#2B3674" fontWeight="500">
            {t('course.leftSidebar.company')}
          </Typography>
          <ListComponent
            items={[
              {
                id: 'management',
                showMenuItem: store.showMenuAssistant,
                label: (
                  <Stack direction="row" spacing={1}>
                    <GroupIcon color="primaryBlue" />
                    <Typography>{t('course.leftSidebar.assistent')}</Typography>
                  </Stack>
                ),
                nestedTable: false,
                callback: () => {
                  store.routerStore.goTo('assistantManagement');
                  store.setMinDrawer(false);
                }
              },

              {
                id: 'lms',
                showMenuItem: store.showMenuItemLms,
                label: (
                  <Stack direction="row" spacing={1}>
                    <BarChartIcon color="primaryBlue" />
                    <Typography>{t('course.leftSidebar.LMS')}</Typography>
                  </Stack>
                ),
                nestedTable: false,
                callback: () => {
                  if (store.showMenuAssistant) {
                    store.routerStore.goTo('LmsStudents');
                  } else {
                    store.routerStore.goTo('LmsDashboardOverview');
                  }
                  store.setMinDrawer(false);
                }
              }
            ]}
          />
        </Box>
      )}
      {showVTB && (
        <Box className="my_company plan_meetings">
          <Typography fontSize="18px" color="#2B3674" fontWeight="500">
            {t('vtb.maintitle')}
          </Typography>
          <ListComponent
            items={[
              {
                id: 'vtb',
                label: (
                  <Stack direction="row" spacing={1}>
                    <CalendarMonthRoundedIcon color="primaryBlue" />
                    <Typography>{t('course.leftSidebar.vtb')}</Typography>
                  </Stack>
                ),
                nestedTable: false,
                callback: () => {
                  store.routerStore.goTo('vtb');
                  store.setMinDrawer(false);
                }
              }
            ]}
          />
        </Box>
      )}
      {showVTBTeacher && (
        <Box className="my_company plan_meetings attendees">
          {!showVTB && (
            <Typography fontSize="18px" color="#2B3674" fontWeight="500">
              {t('vtb.maintitle')}
            </Typography>
          )}
          <ListComponent
            items={[
              {
                id: 'attendance',
                label: (
                  <Stack direction="row" spacing={1}>
                    <HowToRegRoundedIcon color="primaryBlue" />
                    <Typography>{t('course.leftSidebar.attendancesheet')}</Typography>
                  </Stack>
                ),
                nestedTable: false,
                callback: () => {
                  store.routerStore.goTo('attendanceSheet');
                  store.setMinDrawer(false);
                }
              }
            ]}
          />
        </Box>
      )}
      {store.showMenuPortfolio && (
        <Box className="my_company portfolio">
          <Typography fontSize="18px" color="#2B3674" fontWeight="500">
            Portfolio
          </Typography>
          <ListComponent
            items={[
              {
                id: 'curriculum',
                label: (
                  <Stack direction="row" spacing={1}>
                    <TextSnippetIcon color="primaryBlue" />
                    <Typography>{t('course.leftSidebar.curriculum')}</Typography>
                  </Stack>
                ),
                nestedTable: false,
                callback: () => {
                  store.routerStore.goTo('curriculumDashboard', {
                    params: {
                      type: 'active'
                    }
                  });
                  store.setMinDrawer(false);
                }
              }
            ]}
          />
        </Box>
      )}
    </Box>
  );
};

export default observer(MyCompany);
