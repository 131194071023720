import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Backdrop, CircularProgress } from '@mui/material';

// renders a loader with a backdrop
const LoaderWithBackdrop = ({ open }) => (
  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

LoaderWithBackdrop.propTypes = {
  open: PropTypes.bool
};

export default observer(LoaderWithBackdrop);
