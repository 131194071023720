import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Box,
  FormControl,
  FormControlLabel,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const DialogBox = ({
  open,
  title,
  onClose,
  submitButtonText,
  cancelButtonText,
  isLoading,
  onAction,
  text,
  mailNotification,
  setMailNotification,
  mailNotificationText
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{text}</DialogContentText>
      <FormControl sx={{ mt: 3 }}>
        <FormControlLabel
          value="end"
          control={<Checkbox checked={mailNotification} onChange={() => setMailNotification(!mailNotification)} />}
          label={<Typography variant="body2">{mailNotificationText}</Typography>}
        />
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
          {cancelButtonText && <Button onClick={onClose}>{cancelButtonText}</Button>}
          <LoadingButton loading={isLoading} onClick={onAction} variant="contained" autoFocus>
            {submitButtonText}
          </LoadingButton>
        </Box>
      </Box>
    </DialogActions>
  </Dialog>
);

DialogBox.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  mailNotification: PropTypes.bool.isRequired,
  setMailNotification: PropTypes.func.isRequired,
  mailNotificationText: PropTypes.string.isRequired
};
