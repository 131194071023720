/* Core */
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

/* MUI components */
import { Box, Grid, Card, Stack } from '@mui/material';
/* INFO CARDS */
import InfoCardText from '../infoCards/InfoCardText';
import InfoCardFavo from '../infoCards/infoCardFavo';
/* CUSTOM CARD STYLING */
import { sxInfoCard, sxInfoCardPointer, sxInfoCardFavo } from './sxInfoCards';
/*
  This component provides a visual summary of different course categories, 
  making it easy for users to see the number of courses in various states or categories within the application.
  Users can click on specific cards to access more detailed information or related views in the application.
*/
const InfoCards = ({ courses }) => {
  const store = useStore();
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();
  /* sx custom styling */
  const sxGridContainer = { background: 'white', padding: '15px', borderRadius: '20px' };
  const sxGridItem = { marginTop: '0px', marginBottom: '0px' };
  const sxInfoCardText = { background: theme.palette.primary.main, color: 'white', boxShadow: 'rgb(0 0 0 / 34%) 2px 1px 9px 0px' };

  const draftCourses = courses.filter((item) => item.course_status === 0);
  const onlineCourses = courses.filter((item) => item.course_online === 1);
  const offlineCourses = courses.filter((item) => item.course_online === 0);
  const linkedCourses = courses.filter((item) => item.course_online === 1 && item.course_container.length > 0);
  const expectedCourses = courses.filter((item) => item.course_online === 1 && item.course_expected === 1);
  const newCourses = courses.filter((item) => item.course_online === 1 && item.course_course_status.includes('New'));
  const updateCourses = courses.filter((item) => item.course_online === 1 && item.course_course_status.includes('Update'));
  // redirect to courses view
  const redirectCoursesClick = (routeName) => {
    const { package: _package, containerNid, packageNid } = store.routerStore.routerState.params;
    const routerParams = {
      params: { package: _package.toString(), containerNid, packageNid }
    };
    store.routerStore.goTo(routeName, routerParams);
  };

  return (
    <Box sx={{ paddingLeft: '16px' }}>
      <Grid
        className="info_card__container"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={sxGridContainer}
      >
        <Grid item sx={sxGridItem}>
          <Stack className="info_stack__container" direction="row" spacing={2}>
            <Card
              sx={{ ...sxInfoCard(theme), ...sxInfoCardText, cursor: 'pointer' }}
              onClick={() => redirectCoursesClick('adminOnlineCourses')}
            >
              <InfoCardText title={t('dashboard.infoCards.online')} text={onlineCourses.length.toString()} />
            </Card>
            <Card sx={{ ...sxInfoCard(theme), cursor: 'default' }}>
              <InfoCardText title={t('dashboard.infoCards.new')} text={newCourses.length.toString()} />
            </Card>
            <Card sx={{ ...sxInfoCard(theme), cursor: 'default' }}>
              <InfoCardText title={t('dashboard.infoCards.update')} text={updateCourses.length.toString()} />
            </Card>
            <Card sx={{ ...sxInfoCard(theme), cursor: 'default' }}>
              <InfoCardText title={t('dashboard.infoCards.expected')} text={expectedCourses.length.toString()} />
            </Card>
            <Card sx={{ ...sxInfoCard(theme), cursor: 'default' }}>
              <InfoCardText title={t('dashboard.infoCards.linked')} text={linkedCourses.length.toString()} />
            </Card>
          </Stack>
        </Grid>
        <Grid item sx={sxGridItem}>
          <Stack className="info_stack__container" direction="row" spacing={2}>
            <Card sx={{ ...sxInfoCard(theme), cursor: 'default' }}>
              <InfoCardText title={t('dashboard.infoCards.draft')} text={draftCourses.length.toString()} />
            </Card>
            <Card sx={{ ...sxInfoCard(theme), cursor: 'pointer' }} onClick={() => redirectCoursesClick('adminOfflineCourses')}>
              <InfoCardText title={t('dashboard.infoCards.offline')} text={offlineCourses.length.toString()} />
            </Card>
          </Stack>
        </Grid>
        <Grid item sx={sxGridItem}>
          <Stack className="info_stack__container" direction="row" spacing={2}>
            <Card sx={{ ...sxInfoCard(theme), ...sxInfoCardPointer, ...sxInfoCardFavo }}>
              <InfoCardFavo />
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

InfoCards.propTypes = {
  courses: PropTypes.array.isRequired
};

export default observer(InfoCards);
