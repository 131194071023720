/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
// MUI
import { DialogActions, Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
/* ICONS */
import UndoIcon from '@mui/icons-material/Undo';
import ArchiveIcon from '@mui/icons-material/Archive';
import ClearIcon from '@mui/icons-material/Clear';
import { toggleDialogEditWidget } from './formWidgets.helper';

const FormActionBar = ({ reset, dirty, saving, defaultValues }) => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm');

  return (
    <DialogActions sx={{ justifyContent: 'space-between', padding: '12px 25px' }}>
      <Box sx={{ display: 'flex', columnGap: '20px' }}>
        <Button
          onClick={() => store.courseEdit.deleteWidget()}
          size="small"
          color="error"
          variant="contained"
          startIcon={<ClearIcon />}
          disabled={!store?.courseEdit?.setEditWidget?.id}
        >
          Delete
        </Button>
        <Button onClick={() => reset(defaultValues)} size="small" variant="text" startIcon={<UndoIcon />} disabled={!dirty}>
          {t('addCourse.form.reset')}
        </Button>
        {/* <Button
          onClick={() => {
            reset(defaultValues);
            toggleDialogEditWidget(store);
          }}
          color="danger"
          disableElevation
          size="small"
          variant="contained"
          endIcon={<ClearIcon />}
          // sx={{ background: '#607d8b' }}
        >
          Delete
        </Button> */}
      </Box>
      <Box sx={{ display: 'flex', columnGap: '20px' }}>
        <Button
          onClick={() => {
            reset(defaultValues);
            toggleDialogEditWidget(store);
          }}
          size="small"
          variant="contained"
          endIcon={<ClearIcon />}
          sx={{ background: '#607d8b' }}
        >
          {t('addCourse.form.close')}
        </Button>
        <LoadingButton
          size="small"
          type="submit"
          variant="contained"
          endIcon={<ArchiveIcon />}
          loadingPosition="end"
          loading={saving}
          disabled={!dirty}
        >
          {t('addCourse.form.save')}
        </LoadingButton>
      </Box>
    </DialogActions>
  );
};

FormActionBar.propTypes = {
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  defaultValues: PropTypes.object,
  saving: PropTypes.bool
};

export default observer(FormActionBar);
