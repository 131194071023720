import React from 'react';
import MainLayout from 'pages/mainLayout/MainLayout';
import { Lazy } from 'components';
import LmsLayout from '../LmsLayout';
import LmsStudentsLayout from './LmsStudentsLayout';

const LmsStudents = React.lazy(() => import('./LmsStudents'));
const LmsStudentsByStatus = React.lazy(() => import('./LmsStudentsByStatus'));

const LmsStudentsViewMap = {
  LmsStudents: (
    <MainLayout title="lms.navbar.students" subtitle="">
      <LmsLayout>
        <LmsStudentsLayout>
          <Lazy noLayout>
            <LmsStudents />
          </Lazy>
        </LmsStudentsLayout>
      </LmsLayout>
    </MainLayout>
  ),
  LmsStudentsOpen: (
    <MainLayout title="lms.navbar.students" subtitle="">
      <LmsLayout>
        <LmsStudentsLayout>
          <Lazy noLayout>
            <LmsStudentsByStatus status="open" />
          </Lazy>
        </LmsStudentsLayout>
      </LmsLayout>
    </MainLayout>
  ),
  LmsStudentsCompleted: (
    <MainLayout title="lms.navbar.students" subtitle="">
      <LmsLayout>
        <LmsStudentsLayout>
          <Lazy noLayout>
            <LmsStudentsByStatus status="completed" />
          </Lazy>
        </LmsStudentsLayout>
      </LmsLayout>
    </MainLayout>
  )
};

export default LmsStudentsViewMap;
