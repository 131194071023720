import { useEffect, useState } from 'react';
// hook which uses css to determine the current breakpoint
const useCssBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState('');
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    const ww = window.width;
    let bp = null;
    if (ww >= 1536) {
      bp = 'xl';
    }
    if (!bp && ww >= 1200) {
      bp = 'lg';
    }
    if (!bp && ww >= 900) {
      bp = 'md';
    }
    if (!bp && ww >= 600) {
      bp = 'sm';
    }
    if (!bp && ww < 600) {
      bp = 'xs';
    }
    setBreakPoint(bp);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);
  return () => (bp, yes, no) => {
    if (bp === breakpoint) {
      return yes;
    }
    return no;
  };
};
export default useCssBreakpoint;
