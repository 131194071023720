import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';
import { Button, FormControl, FormHelperText, FormLabel } from '@mui/material';
import { rawHTML } from 'helpers/Helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  widgetForm: {
    margin: '0px 0px'
  },
  formControl: {
    width: '100%',
    margin: theme.spacing * 3
    // paddingBottom: '10px'
  },
  group: {
    margin: `${theme.spacing}px 0`
  },
  notActive: {
    color: '#e25500fc',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: `38px`,
    marginBottom: '1.3em',
    fontSize: '0.8em',
    lineHeight: '1.2'
  }
}));

// Initialisation of a widget, handle submit and share JSX code
function useWidget(props) {
  const { store, widget, widgetCode } = props;
  const { lessonPlay } = store;
  // -- hooks
  const node = useRef(null);
  const isDisabled = useRef(false);
  const classes = useStyles();
  //
  // -- PAGE ID MAX
  const { pageNo } = lessonPlay;
  const { page_id_max: pageIdMax } = lessonPlay;
  const isPageMax = pageNo === pageIdMax;
  // -- INIT
  const isQuestion = widget.attributes.widget_type.includes('Question');
  // Show greyout without feedback
  const showFeedback1 = store?.lessonPlay?.lesson?.attributes['1answer_nofeedback'] || false;
  // Show color without feedback
  const showFeedback2 = store?.lessonPlay?.lesson?.attributes['1answer_visualfeedback'] || false;
  // Get weight off the widget
  const widgetWeight = Number(widget.attributes?.weight) > 0;
  // Determine feedback
  let detectFeedback = true;
  if (showFeedback1 || showFeedback2) {
    detectFeedback = false;
    if (!widgetWeight) {
      detectFeedback = true;
    }
  }
  // Allowed retries to do the widget
  const allowedTries = lessonPlay.allowedTries(widget);
  //
  const mappedPageWidgets = lessonPlay.mapPageWidgets2QwAnswers();
  const mappedQuestions = mappedPageWidgets.filter((pw) => pw.isQuestion);
  const mappedNotCompleted = mappedQuestions.filter((pw) => !pw.isCompleted);
  const firstItem = mappedNotCompleted.length > 0 && mappedNotCompleted[0];

  const onPageMax = () => {
    if (!isQuestion) {
      return false;
    }

    return firstItem?.qwId !== widget.id;
  };
  //
  const canNotAnswerQuestion = () => {
    if (pageNo === 0 && store.canEditCourse) {
      return false;
    }

    if (!isQuestion) {
      return false;
    }

    if (!isPageMax) return true; // other page than page max id => true

    if (isPageMax) return onPageMax(); // on page max

    return false;
  };
  const mayNotAnswerQuestion = canNotAnswerQuestion();

  isDisabled.current = mayNotAnswerQuestion;
  //
  //
  const question = store.lessonPlay.getLessonResultQuestion(widget);

  let questionResultClass;
  if (!question.tries) {
    // backgroundColor = 'transparent';
  } else if (question.last_correct) {
    // backgroundColor = '#eefcde';
    questionResultClass = 'spa-form-correct';
  } else {
    // backgroundColor = '#fcf4f4';
    questionResultClass = 'spa-form-incorrect';
  }
  // set class of cmeVariable (grandparent) node
  useEffect(() => {
    if (node.current) {
      const cmeVariable = node.current.closest('.cmeVariable');
      if (cmeVariable) {
        cmeVariable.classList.add(questionResultClass);
        if (cmeVariable.classList.contains('spa-form-incorrect') && questionResultClass === 'spa-form-correct') {
          cmeVariable.classList.remove('spa-form-incorrect');
        }
        if (mayNotAnswerQuestion || cmeVariable.classList.contains('spa-form-correct')) {
          cmeVariable.classList.add('spa-form-disabled');
          isDisabled.current = true;
        }
        if (showFeedback1) {
          cmeVariable.classList.remove('spa-form-incorrect');
          cmeVariable.classList.remove('spa-form-correct');
        }
      }
    }
  }, [node, questionResultClass, mayNotAnswerQuestion, isDisabled, showFeedback1]);

  useEffect(() => {
    if (node.current) {
      const cmeVariable = node.current.closest('.cmeVariable');
      if (cmeVariable) {
        if (cmeVariable.children[0]?.classList.contains('EwVideo')) {
          cmeVariable.classList.add('video_widget');
        }
        const isSpan = cmeVariable?.children[0]?.closest('span');
        if (isSpan?.children[0]?.classList.contains('EwVideo')) {
          cmeVariable.classList.add('video_widget');
        }
        Array.from(cmeVariable.children).forEach((child) => {
          if (child?.classList?.contains('EwHTML')) {
            cmeVariable.classList.add('html_widget');
          }
        });
      }
    }
  }, [node]);

  const inProgress = useRef(false);

  // Share jsx
  const PlayWidget = observer((localProps) => {
    const { validateAnswer, showFeedback, children } = localProps;

    const isReflect = widget?.attributes?.options?.type === 'Reflect';
    const buttonLabel = isReflect ? 'lesson.checkReflect' : 'lesson.checkQuestion';

    const checkAnswer = (event) => {
      event.preventDefault();
      const answer = validateAnswer();

      if (!answer) {
        // const toastAnswerText = widget?.attributes?.widget_type === 'Open Question' ? 'lesson.noAnswer1' : 'lesson.noAnswer';
        const toastAnswerText = {
          'Open Question': 'lesson.noAnswer1',
          'Match Question': 'lesson.noAnswerMatch',
          'Check Question': 'lesson.noAnswerMultiple',
          default: 'lesson.noAnswer'
        };
        store.showToast({
          message: window.i18next.t(toastAnswerText[widget?.attributes?.widget_type] || toastAnswerText.default),
          variant: 'error',
          vertical: 'bottom',
          horizontal: 'right'
        });
        return;
      }

      if (inProgress.current) return;
      inProgress.current = true;
      store.lessonPlay.saveAnswer(widget, answer).then(() => {
        inProgress.current = false;
      });
    };

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <form
        onSubmit={checkAnswer}
        className={classes.widgetForm}
        data-test="question"
        data-correct={!!question.last_correct}
        disabled={question.tries >= allowedTries || !!question.last_correct}
        ref={node}
      >
        <div className={classes.root}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" sx={{ color: 'black', '& p': { margin: 0 }, marginBottom: '1rem' }}>
              {!widgetCode?.match('video') && rawHTML(widget.attributes.question)}
            </FormLabel>
            {children}
            {widget.attributes.helpText && <FormHelperText>{widget.attributes.helpText}</FormHelperText>}
          </FormControl>
          {mayNotAnswerQuestion && !question.correct && question.tries < allowedTries && (
            // eslint-disable-next-line react/no-danger
            <div className={classes.notActive} dangerouslySetInnerHTML={{ __html: window.i18next.t('lesson.notActivePage') }} />
          )}
          {detectFeedback && showFeedback()}
          {isQuestion && question.tries < allowedTries && !question.last_correct && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isDisabled.current}
              data-test="submit"
              sx={{
                padding: '15px 60px',
                fontSize: '1.2em',
                fontWeight: '500',
                textTransform: 'initial'
              }}
            >
              {window.i18next.t(buttonLabel)}
            </Button>
          )}
        </div>
      </form>
    );
  });
  return {
    question,
    widget,
    allowedTries,
    PlayWidget,
    answerDisabled: mayNotAnswerQuestion
  };
}

export default useWidget;
