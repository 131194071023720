/* Core */
import { useTranslation } from 'react-i18next';

/* MUI */
import TextField from '@mui/material/TextField';
import { Typography, Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import 'theme/faq/Faq.scss';

/* MUI - Icons */
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = () => {
  const { t } = useTranslation();

  return (
    <Grid container className="faq_searchBar">
      <Grid item className="searchBar_section">
        <Typography variant="h4" className="searchBar_headline" color="primary">
          {t('faq.search.title', 'Do you have a question?')}
        </Typography>
        <TextField
          label={t('faq.search.label', 'Search')}
          className="searchBar_input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SearchBar;
