import { types as t } from 'mobx-state-tree';

export const Product = t.model('Product', {
  nid: t.identifierNumber,
  uuid: t.string,
  title: t.string,
  free_product: t.maybeNull(t.number),
  trial_detail_text: t.maybeNull(t.string),
  trial_overview_text: t.maybeNull(t.string)
});
