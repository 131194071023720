import { Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import MainLayout from '../../pages/mainLayout/MainLayout';

// TODO: centreren CircularProgress
// renders a CircularProgress while the children are being loaded
const Lazy = ({ children, noLayout, title }) => {
  const Suspended = useMemo(
    () => (
      <Suspense
        fallback={
          <div style={{ position: 'absolute', height: '100px', width: '100px', left: '50%', top: '50%' }}>
            <CircularProgress />
          </div>
        }
      >
        {children}
      </Suspense>
    ),
    [children]
  );

  return noLayout ? Suspended : <MainLayout title={title}>{Suspended}</MainLayout>;
};

Lazy.propTypes = {
  children: PropTypes.object.isRequired,
  noLayout: PropTypes.bool,
  title: PropTypes.element
};

Lazy.defaultProps = {
  noLayout: false
};

export default Lazy;
