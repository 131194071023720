/* Core */
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { RouterView, RouterContext } from 'mobx-state-router';
import { Toast } from 'components';

import { StoreProvider } from 'state/ContextStore';
import { RootStore } from 'state/RootStore';
import makeInspectable from 'mobx-devtools-mst';

import { ModalProvider } from './ModalProvider';
import GTMProvider from './GTMProvider';
import { setGtmId } from '../helpers/Helpers';
import { injectStore } from '../state/api/AuthenticatedClient';

const WithStoreProvided = ({ routes, viewMap, intitialRouteName }) => {
  const store = useRef(null);

  if (!store.current) {
    store.current = RootStore.create({}, { routes, intitialRouteName });

    makeInspectable(store.current);

    // for testing purpose
    window.store = store.current;
  }

  const [routerStoreHasLoaded, setRouterStoreHasLoaded] = useState(false);

  if (!routerStoreHasLoaded && store.current.routerStore) {
    const queryString = new URLSearchParams(window.location.search);

    // If the backend initiates with state,
    // we route there.
    if (queryString.has('state') && queryString.get('state') !== '' && queryString.get('state') !== null) {
      // eslint-disable-next-line no-restricted-globals
      location.href = queryString.get('state');
    }
    // if (store.isEwiseUser && !store.hasPreferredBaseproduct) {
    //   return createRouterState('selectProduct', { params: { user: store.login.userName } });
    // }
    setRouterStoreHasLoaded(true);
  }

  injectStore(store.current);

  return (
    <StoreProvider store={store.current}>
      <GTMProvider containerId={setGtmId()}>
        <ModalProvider>
          <Toast />
          {routerStoreHasLoaded && (
            <RouterContext.Provider value={store.current.routerStore}>
              <RouterView viewMap={viewMap} />
            </RouterContext.Provider>
          )}
        </ModalProvider>
      </GTMProvider>
    </StoreProvider>
  );
};
export default WithStoreProvided;

WithStoreProvided.propTypes = {
  routes: PropTypes.array,
  viewMap: PropTypes.object,
  intitialRouteName: PropTypes.string
};
