import { ThemeBrandTitle } from 'helpers/Helpers';

const BrandTitle = ThemeBrandTitle();

export const loginRoute = {
  name: 'logout',
  title: `Uitloggen | ${BrandTitle}`,
  pattern: '/logout'
};

export const switchBackRoute = {
  name: 'switchback',
  title: `Switch back | ${BrandTitle}`,
  pattern: '/switch-back'
};
