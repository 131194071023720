import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

/* MUI */
import { Alert } from '@mui/material';

const CourseExpiredSection = (props) => {
  const { endDate, courseStatus } = props;
  const { t } = useTranslation();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const availableDate = new Date(endDate).toLocaleDateString('nl-NL', options);
  const expirationDate = new Date(endDate).setHours(23, 0, 0, 0);
  const showNotification = new Date().getTime() >= new Date(expirationDate - 60 * 24 * 60 * 60 * 1000).getTime();
  const isExpected = courseStatus.toLowerCase() === 'expected';

  return (
    showNotification &&
    !isExpected && (
      <Alert severity="info" className="endDate_alert">
        {t('introduction.sidebar.endDateAlert', { availableDate })}
      </Alert>
    )
  );
};

CourseExpiredSection.propTypes = {
  endDate: PropTypes.string,
  courseStatus: PropTypes.string
};

export default observer(CourseExpiredSection);
