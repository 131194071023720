import { types as t } from 'mobx-state-tree';
import { Course } from 'state/modelsEwapi/Course';

export const Hidden = t.model('Likes', {
  nid: t.number,
  uuid: t.string,
  hidden_courses: t.maybe(
    t.array(
      t.model({
        target_id: t.reference(Course),
        uuid: t.maybeNull(t.string)
      })
    )
  )
});
