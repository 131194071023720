import { useEffect, useRef } from 'react';

const useIsComponentMounted = () => {
  const isComponentMounted = useRef(false);

  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return isComponentMounted;
};

export default useIsComponentMounted;
