import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const StageTitleBar = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      padding: '1rem',
      backgroundColor: '#ffffff',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '0.75rem'
    }}
  >
    {children}
  </Box>
);

StageTitleBar.propTypes = {
  children: PropTypes.node.isRequired
};

export default StageTitleBar;
