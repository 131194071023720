import { SvgIcon } from '@mui/material';

const Information = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 20">
    <path d="M3.50611 4.54713H9.69673C9.91583 4.54713 10.0985 4.36446 10.0985 4.14538V2.39222C10.0985 2.17312 9.91582 1.99047 9.69673 1.99047L8.6559 1.9906V1.95404C8.60112 0.858286 7.70627 0 6.61065 0C5.5149 0 4.62019 0.858286 4.56541 1.95404V1.9906H3.50624C3.28715 1.9906 3.10449 2.17326 3.10449 2.39235V4.14551C3.10436 4.36447 3.28703 4.54713 3.50611 4.54713V4.54713ZM6.61052 1.20529C7.06706 1.20529 7.43225 1.57049 7.43225 2.02702C7.43225 2.48356 7.06706 2.84875 6.61052 2.84875C6.15399 2.84875 5.78879 2.48356 5.78879 2.02702C5.78879 1.58881 6.15399 1.20529 6.61052 1.20529Z" />
    <path d="M12.3811 3.17578H11.0481V4.12541C11.0481 4.8558 10.4455 5.45846 9.71504 5.45846H3.52442C2.79403 5.45846 2.19137 4.85583 2.19137 4.12541V3.17578H0.9313C0.41998 3.17578 0 3.59576 0 4.10708V16.397C0 16.9083 0.41998 17.3283 0.9313 17.3283H12.3995C12.9108 17.3283 13.3308 16.9083 13.3308 16.397L13.3309 4.10708C13.3126 3.59576 12.8926 3.17578 12.3813 3.17578H12.3811ZM10.5184 14.4248C10.5184 14.6075 10.3723 14.7352 10.208 14.7352H3.10433C2.92166 14.7352 2.7939 14.5891 2.7939 14.4248V13.8953C2.7939 13.7126 2.94001 13.5849 3.10433 13.5849H10.1898C10.3724 13.5849 10.5002 13.731 10.5002 13.8953V14.4248H10.5184ZM10.5184 11.4847C10.5184 11.6674 10.3723 11.7952 10.208 11.7952H3.10433C2.92166 11.7952 2.7939 11.649 2.7939 11.4847V10.9552C2.7939 10.7725 2.94001 10.6448 3.10433 10.6448H10.1898C10.3724 10.6448 10.5002 10.7909 10.5002 10.9552V11.4847H10.5184ZM10.5184 8.54464C10.5184 8.7273 10.3723 8.85506 10.208 8.85506H3.10433C2.92166 8.85506 2.7939 8.70896 2.7939 8.54464V8.01512C2.7939 7.83246 2.94001 7.70469 3.10433 7.70469H10.1898C10.3724 7.70469 10.5002 7.8508 10.5002 8.01512V8.54464H10.5184Z" />
  </SvgIcon>
);

export default Information;
