/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

/* MUI components */
import Typography from '@mui/material/Typography';

/* COMP: Section Title */
const SectionTitle = ({ title, align = 'left' }) => {
  const cssDiv = { color: '#505055', fontSize: '20px', fontWeight: '500', lineHeight: '40px' };

  return (
    <Typography align={align} component="div" sx={cssDiv}>
      {title}
    </Typography>
  );
};
SectionTitle.propTypes = {
  title: PropTypes.string,
  align: PropTypes.string
};

export default observer(SectionTitle);
