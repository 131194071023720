/* Core */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

/* MUI */
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

/* Custom components */
import { ToggleColorMode } from 'components';

const Settings = () => {
  const { t } = useTranslation();

  return (
    <Box padding="30px">
      <Typography fontWeight="bold" color="#2B3674" fontSize="20px">
        {t('account.settings.title')}
      </Typography>
      <Box className="account_subscriptions">
        <Grid container spacing={2} alignItems="center" paddingTop="0px" marginTop="0px">
          <Grid item xs={12} sm={12}>
            <Card className="subscription_card">
              <CardContent>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid item xs={6} sm={6}>
                    <Typography variant="h1" color="#2B3674" fontSize="14px" fontWeight="700">
                      {t('account.settings.theme')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                      <ToggleColorMode />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default observer(Settings);
