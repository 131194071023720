import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getCourseCardProps } from 'helpers/Helpers';
import CourseTableRow from './CourseTableRow';

const CourseList = ({ courses, trialCoursesView = false }) => (
  <Box sx={{ width: '100%' }}>
    {courses.map((course) => {
      const propsBasicCard = getCourseCardProps(course);

      return (
        <React.Fragment key={propsBasicCard.nid}>
          <CourseTableRow propsBasicCard={propsBasicCard} trialCoursesView={trialCoursesView} />
        </React.Fragment>
      );
    })}
  </Box>
);

CourseList.propTypes = {
  courses: PropTypes.any,
  trialCoursesView: PropTypes.any
};

export default observer(CourseList);
