import { TableCell } from '@mui/material';
import { PropTypes } from 'prop-types';

const CustomCell = ({ sx, classes, children, ...rest }) => (
  <TableCell
    className={classes}
    sx={{
      py: 1,
      lineHeight: 2,
      fontWeight: 500,
      color: 'rgb(163, 174, 208)',
      borderTop: '1px solid #EEEEEE',
      '&:hover': {
        color: 'rgb(43, 54, 116)'
      },
      ...sx
    }}
    {...rest}
  >
    {children}
  </TableCell>
);

export default CustomCell;

CustomCell.propTypes = {
  sx: PropTypes.any,
  classes: PropTypes.string,
  children: PropTypes.any
};
