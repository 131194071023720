import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

const MyMultiSelect = forwardRef(({ items, name, control, label, ...otherProps }, fRef) => {
  const isSearching = false;

  return (
    <Controller
      control={control}
      name={name}
      ref={fRef}
      defaultValue={[]}
      render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          disabled={otherProps.disabled}
          id="tags-outlined"
          freeSolo
          multiple
          autoComplete
          defaultValue={[]}
          onChange={(_, data) => onChange(data)}
          options={items.sort((a, b) => -b.name.localeCompare(a.name))}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={index}
                variant="filled"
                // color={correctTag(option, objOptions)}
                label={option.name}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              label={label}
              inputRef={ref}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching ? 'hoi' : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      )}
    />
  );
});

MyMultiSelect.propTypes = {
  items: PropTypes.array,
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  otherProps: PropTypes.object
};

export default MyMultiSelect;
