import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';

// TODO: Only used in customTable.If we decide to not use it remove from components

const CSVExport = ({ data, children }) => <CSVLink data={data}>{children}</CSVLink>;

CSVExport.propTypes = {
  data: PropTypes.array,
  children: PropTypes.any
};

export default CSVExport;
