import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import introJs from 'intro.js';
import { useTheme } from '@mui/styles';
import { useStore } from '../../hooks';
import { ThemeBrandTitle } from '../../helpers/Helpers';
import useAPI from '../../hooks/useAPI';

export function useOnboarding() {
  const { t } = useTranslation('onboarding');
  const store = useStore();
  const theme = useTheme();

  const { response, loading } = useAPI(`/jsonapi/user/${store.login?.uuid}`, undefined);

  const skipTour = response?.data.attributes.field_skip_tour;

  useEffect(() => {
    if (!response || loading) {
      return;
    }

    // Localstorage is not supported
    if (typeof Storage === 'undefined') {
      return;
    }

    if (skipTour) {
      return;
    }

    const brandTitle = ThemeBrandTitle();
    const accreditation = store?.baseProduct?.packages.find((subPackage) => subPackage.nid === store?.selectedPackageId);
    const accrUnit = accreditation?.accreditation?.accr_unit;
    const unitText = !accrUnit?.includes('punt') ? t('hours') : t('points');
    const yourText = brandTitle === 'E-WISE' ? t('informalYour') : t('formalYour');

    const steps = [
      {
        intro: t('intro1', { brandTitle })
      },
      {
        element: document.querySelector('.onboarding-navbar-packages') ?? 'NOT_FOUND',
        intro: t('intro2')
      },
      {
        element: document.querySelector('.onboarding-filters-bar') ?? 'NOT_FOUND',
        intro: t('intro3', { yourText })
      },
      {
        element: document.querySelector('.onboarding-search') ?? 'NOT_FOUND',
        intro: t('intro4')
      },
      {
        element: document.querySelector('.onboarding-points') ?? 'NOT_FOUND',
        intro: t('intro5', { unitText, yourText })
      },
      {
        element: document.querySelector('.onboarding-avatar') ?? 'NOT_FOUND',
        intro: t('intro6', { yourText })
      }
    ].filter((step) => step.element !== 'NOT_FOUND');

    const handleClose = async () => {
      await window.authedClient.patch(`/jsonapi/user/${store.login?.uuid}`, {
        data: {
          type: 'user',
          id: store.login?.uuid,
          attributes: {
            field_skip_tour: true
          }
        }
      });
    };

    introJs()
      .setOptions({
        tooltipClass: 'custom-onboarding',
        nextLabel: t('next'),
        prevLabel: t('previous'),
        showButtons: true,
        steps
      })
      .onbeforeexit(() => {
        handleClose();
      })
      .start();

    const nextButton = document.getElementsByClassName('introjs-nextbutton')?.item(0);

    nextButton.setAttribute('style', `background-color: ${theme?.custom?.onboarding?.buttonBackgroundColor}`);
  }, [store?.baseProduct?.packages, store?.selectedPackageId, t, skipTour, response, loading, theme, store.login?.uuid]);
}
