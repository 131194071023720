import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../hooks';
import { createURLParams } from './createURLParams';

export const ResendInvitationButton = ({ data, onSuccess }) => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onClick = async () => {
    setIsLoading(true);

    try {
      await window.authedClient.post(`ewapi/assistant_manager/invite?${createURLParams(data)}`);
      await store.showToast({ message: t('assistant.resend.msgSuccess'), variant: 'success' });
      await onSuccess();
    } catch (err) {
      await store.showToast({ message: t('assistant.resend.msgError'), variant: 'error' });
    }

    setIsLoading(false);
  };

  return (
    <LoadingButton variant="outlined" color="info" onClick={onClick} loading={isLoading}>
      {t('assistant.resend.btn')}
    </LoadingButton>
  );
};

ResendInvitationButton.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired
};