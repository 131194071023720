import { useStore } from 'hooks';
import { useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { ListItemIcon, MenuItem } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { createHtml } from 'helpers/pdfOverview.helper';

const MenuItemCreatePdf = () => {
  const store = useStore();
  const theme = useTheme();

  return (
    <MenuItem key="pdfprint" onClick={() => createHtml(store, theme)}>
      <ListItemIcon>
        <PrintIcon fontSize="small" />
      </ListItemIcon>
      Print overzicht
    </MenuItem>
  );
};

export default observer(MenuItemCreatePdf);
