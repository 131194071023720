import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from 'hooks';
import { Box, CircularProgress } from '@mui/material';
import Profile from '../forms/Profile';
import ProfileExtras from '../forms/ProfileExtras';
import { ResetOnboarding } from '../forms/ResetOnboarding';

const ProfileContainer = () => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [forced, setForced] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');

    if (store.profile) {
      setLoading(true);
    }

    // If the user is forced to change their password, we need to show the password change modal
    if (status === '2' || store.getPasswordForced() === '1') {
      setForced(true);
      store.setPasswordForced();
    }
  }, [store, store.profile]);
  if (!loading) {
    return (
      <Box className="account_loader">
        <CircularProgress color="primary" style={{ width: '65px', height: '65px' }} />
      </Box>
    );
  }

  return (
    <Box className="profile_container">
      <Profile />
      <ProfileExtras forced={forced} />
      <ResetOnboarding />
    </Box>
  );
};

export default observer(ProfileContainer);
