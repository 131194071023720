import { createSearchRegex } from './createSearchRegex';
import { filterAuthorsByRegex } from './filterAuthorsByRegex';

export const getFilteredData = (data, searchText) => {
  if (!data || !data.authors) {
    throw new Error('Invalid data format');
  }

  const searchRegex = createSearchRegex(searchText);

  return {
    authors: filterAuthorsByRegex(data.authors, searchRegex),
    advisoryBoard: filterAuthorsByRegex(data.advisoryBoard, searchRegex)
  };
};
