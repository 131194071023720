import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';

/*
Overall, this component is responsible for allowing the user to edit or upload images associated with a course,
specifically for the course header, course photo, and external image. 
The ImageUploader component is used to manage the image selection and uploading process.
*/
const OneAnswerGroup = ({ initialValues, setValue, labels }) => {
  const [state, setState] = useState({
    noFeedback: initialValues.attributes['1answer_nofeedback'],
    visualFeedback: initialValues.attributes['1answer_visualfeedback']
  });

  const handleChange = (event) => {
    if (!event.target.checked) {
      setState({ ...state, [event.target.name]: event.target.checked, noFeedback: false });
    }
    if (event.target.name === 'noFeedback' && event.target.checked) {
      setState({ ...state, [event.target.name]: event.target.checked, visualFeedback: false });
    }
    if (event.target.name === 'visualFeedback' && event.target.checked) {
      setState({ ...state, [event.target.name]: event.target.checked, noFeedback: false });
    }
  };

  useEffect(() => {
    setValue('attributes.1answer_nofeedback', state.noFeedback, { shouldDirty: true });
    setValue('attributes.1answer_visualfeedback', state.visualFeedback, { shouldDirty: true });
  }, [setValue, state]);

  return (
    <Grid container sx={{ marginTop: '0px' }}>
      <Grid item xs={12} md={6} sx={{ marginTop: '0px' }}>
        <FormControlLabel
          value="bottom"
          control={<Checkbox checked={state.noFeedback} name="noFeedback" label="nofeedback" onChange={handleChange} />}
          label={labels.firstAnswerNofeedback}
          labelPlacement="bottom"
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ marginTop: '0px' }}>
        <FormControlLabel
          value="bottom"
          control={<Checkbox checked={state.visualFeedback} name="visualFeedback" label="visual feedback" onChange={handleChange} />}
          label={labels.firstAnswerVisualfeedback}
          labelPlacement="bottom"
        />
      </Grid>
    </Grid>
  );
};

OneAnswerGroup.propTypes = {
  initialValues: PropTypes.object,
  labels: PropTypes.object,
  setValue: PropTypes.func
};

export default observer(OneAnswerGroup);
