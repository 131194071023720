import { types as t } from 'mobx-state-tree';
import { Links, RelProfession } from './BaseModel';
import { RelCourseContainer } from './CourseContainer';
import { RelAccreditationOrganisations } from './AccreditationOrganisation';

// Todo: Add relationships: Accreditation_organisation
export const Package = t
  .model('Package', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__nid: t.number,
      // uuid: t.string,
      status: t.boolean,
      title: t.string,
      tab_label: t.maybeNull(t.string),
      body: t.maybeNull(t.string),
      max_question_retries: t.maybeNull(t.number)
    }),
    relationships: t.model({
      profession: RelProfession,
      accreditation_organisation: t.maybeNull(RelAccreditationOrganisations),
      course_container: RelCourseContainer,
      logo: t.model({
        data: t.maybeNull(t.string),
        links: Links
      })
    })
  })
  .volatile(() => ({
    sortedPackage: [],
    selections: {
      category: [],
      status: [],
      competence: [],
      author: []
    }
  }))
  .actions((self) => ({
    afterCreate() {
      if (!self.attributes.max_question_retries) self.attributes.max_question_retries = 2;
    },
    saveSorted() {
      const isSelected =
        (self.selections.category.length ||
          self.selections.status.length ||
          self.selections.competence.length ||
          self.selections.author.length) > 0;
      if (!isSelected) {
        self.sortedPackage = self.coursesByCategory('course');
      }

      return self.sortedPackage;
    },
    setOptions(input, type) {
      if (type === 'author') {
        self.selections[type] = [input];
      } else {
        self.selections[type].push(input);
      }
      return self.selections;
    },
    resetfilterModel() {
      self.selections = {
        category: [],
        status: [],
        competence: [],
        author: []
      };
    },
    removeSelection(input, type) {
      const result = self.selections[type].filter((option) => option !== input);
      self.selections[type] = result;
    },
    multipleFilter(filters) {
      let results = self.courses;
      if (filters.category.length) {
        const category = results.filter((product) => {
          const filterCategory = filters.category.map((element) => element);
          const data = product.relationships.course_category.data.filter((res) => filterCategory.includes(res.id.attributes.name));
          return data.length > 0;
        });
        results = category;
      }
      if (filters.author.length) {
        const author = results.filter((product) => {
          const filterAuthor = filters.author.length > 0 ? filters.author.map((element) => element) : filters.author;
          const data =
            product.relationships.lesson_container.data &&
            product.relationships.lesson_container.data.id.relationships.field_authors.data.filter((res) =>
              filterAuthor.includes(res.id.attributes.title)
            );
          return data && data.length > 0;
        });
        results = author;
      }
      if (filters.competence.length) {
        const competence = results.filter((product) => {
          const filterCompetence = filters.competence.map((element) => element);
          const data = product.relationships.course_category2.data.filter((el) =>
            filterCompetence.includes(el.id.attributes.name.split('-')[0])
          );
          return data.length > 0;
        });
        results = competence;
      }
      if (filters.status.length) {
        const status = results.filter((product) => {
          const filterStatus = filters.status.map((element) => element);
          return filterStatus.includes(product.attributes.course_status);
        });
        results = status;
      }
      self.sortedPackage = results;
      return self.sortedPackage;
    },
    filterBy(filterName, type) {
      const filterMap = {
        author: { data: Object.entries(self.coursesByAuthor) },
        category: { data: Object.entries(self.coursesByCategory) },
        competence: { data: Object.entries(self.coursesByCompetence) },
        status: { data: Object.entries(self.coursesByStatus) }
      };
      const courses = filterMap[type].data;
      const filteredData = courses.filter(([key]) => key === filterName);
      self.sortedPackage = Object.fromEntries(filteredData);
    }
  }))
  .views((self) => ({
    get maxRetries() {
      return self.attributes.max_question_retries;
    },
    get courseContainer() {
      if (self.relationships.course_container && self.relationships.course_container.data) {
        return self.relationships.course_container.data.id;
      }
      return null;
    },
    get courses() {
      try {
        if (self.courseContainer && self.courseContainer.relationships.courses && self.courseContainer.relationships.courses.courserefs) {
          return self.courseContainer.relationships.courses.courserefs;
        }
        return [];
      } catch (e) {
        return [];
      }
    },

    get totalCourses() {
      return self.courses.length;
    },
    coursesByCategory(type) {
      // Computed properties get automatically cached by Mobx. First time: 8ms / second time: 0,2ms
      const courses = type === 'course' ? self.courses : self.sortedPackage;
      const categories = [];
      courses.forEach((course) => {
        course.relationships.course_category.data.forEach((c) => {
          if (!categories[c.id.attributes.name]) categories[c.id.attributes.name] = [];
          categories[c.id.attributes.name].push(course);
        });
      });
      return categories;
    },
    coursesByCompetence(type) {
      const courses = type === 'course' ? self.courses : self.sortedPackage;
      const competence = [];
      courses.forEach((course) => {
        course.relationships.course_category2.data?.forEach((c) => {
          if (!competence[c.id.attributes.name.split('-')[0]]) competence[c.id.attributes.name.split('-')[0]] = [];
          competence[c.id.attributes.name.split('-')[0]].push(course);
        });
      });
      return competence;
    },
    coursesByAuthor(type) {
      const courses = type === 'course' ? self.courses : self.sortedPackage;
      const authors = [];
      courses.forEach((course) => {
        if (course.relationships.lesson_container.data) {
          course.relationships.lesson_container.data.id.relationships.field_authors.data.forEach((c) => {
            if (!authors[c.id.attributes.title]) authors[c.id.attributes.title] = [];
            authors[c.id.attributes.title].push(course);
          });
        }
      });
      return authors;
    },
    coursesByStatus(type) {
      const courses = type === 'course' ? self.courses : self.sortedPackage;
      const status = [];
      courses.forEach((course) => {
        if (!status[course.attributes.course_status]) status[course.attributes.course_status] = [];
        status[course.attributes.course_status].push(course);
      });
      return status;
    }
  }));

export const RelPackages = t
  .model('RelPackages', {
    data: t.array(
      t.model({
        id: t.reference(Package),
        type: t.string
      })
    )
  })
  .views((self) => ({
    get packagerefs() {
      if (self.data && self.data.length) {
        return self.data.map((packageref) => packageref.id);
      }
      return [];
    }
  }));
