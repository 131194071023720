/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
/* MUI */
import { Box, Chip, Popper, Typography, Button, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
// helper
import { ToastLinkedCourses } from 'pages/adminCourses/addCourse/EditCourse.helper';
/* CUSTOM components */
import SectionTitle from './SectionTitle';
import LoaderWithBackdrop from '../loading/LoaderWithBackdrop';
/* HELPERS */
import { convertDate } from '../../helpers/utils';

/*
This component is responsible for displaying a table of linked courses within the dashboard view. 
Users can click on a course to navigate to its details. 
The table provides essential information about each linked course.
*/
const DashboardTable = ({ courses }) => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkCourse, setLinkCourse] = useState({});
  //
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const toast = ToastLinkedCourses();
  // POPPER DIALOG: TRANSLATIONS ____________________________
  const unlinkDialog = {
    title: t('linkedCourses.unlinkDialog.title'),
    message: t('linkedCourses.unlinkDialog.message'),
    btnDelete: t('linkedCourses.unlinkDialog.delete'),
    btnCancel: t('linkedCourses.unlinkDialog.cancel')
  };
  // POPPER DIALOG: OPEN _________________________________
  const handleOpenDialogLinkedCourse = (event, links) => {
    setLinkCourse(links);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  // POPPER DIALOG: CLOSE _________________________________
  const handleCloseDialog = () => setAnchorEl(null);

  // POPPER DIALOG: DELETE btn ____________________________
  const handleDeleteLinked = async (event, links) => {
    setLoading(true);
    try {
      await store.courseEdit.unlinkCourse(links);
      const indexCourse = courses.findIndex((item) => item.course_nid === links.courseNid);
      if (links.links.course_container.length > 1) {
        const indexCourseContainer = links.links.course_container.findIndex(
          (item) => item.course_container_nid === links.link.course_container_nid
        );
        courses[indexCourse].course_container.splice(indexCourseContainer, 1);
      } else {
        courses.splice(indexCourse, 1);
      }
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
    } catch (error) {
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
    }
    setLoading(false);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // TABLE: OPTIONS _______________________________________
  const tableOptions = {
    pageSize: 10,
    emptyRowsWhenPaging: false,
    search: false,
    showTitle: false,
    header: true,
    toolbar: false,
    dense: true
  };

  // TABLE: ON CLICK row _______________________________________
  const handleClickRowCourse = (course) => {
    setLoading(true);
    const { package: _package, containerNid } = store.routerStore.routerState.params;
    const routerParams = {
      params: {
        package: _package.toString(),
        packageNid: store.params.packageNid.toString(),
        containerNid,
        courseuuid: course.course_uuid
      },
      queryParams: { type: 'linked' }
    };
    store.routerStore.goTo('editCourse', routerParams);
  };

  // TABLE: render linked courses as chip _______________________________________
  const linkedCourses = (links) =>
    links.course_container.map((link) => (
      <Tooltip key={link.course_container_nid} title={link.course_container_title} arrow>
        <Chip
          color="warning"
          onDelete={(event) =>
            handleOpenDialogLinkedCourse(event, {
              links,
              link,
              title: links.course_title,
              courseNid: links.course_nid,
              courseUuid: links.course_uuid
            })
          }
          key={link.course_container_nid}
          label={`${link.course_container_title.slice(0, 15)}`}
          sx={{
            fontSize: '11px',
            marginRight: '5px',
            height: '24px',
            border: `2px solid white`,
            ' .MuiChip-deleteIcon': { fontSize: '18px' }
          }}
        />
      </Tooltip>
    ));

  return (
    <Box sx={{ marginTop: '40px' }}>
      <Box sx={sxLinkedCourses}>
        {/* POPPER DIALOG */}
        <Popper className="popper" id={id} open={open} anchorEl={anchorEl} sx={sxPopper}>
          <Box className="popper_box">
            <Paper elevation={0}>
              <DialogTitle className="popper_title" id="dialogDeleteLink">
                <Box>{unlinkDialog.title}</Box>
                <Box>
                  <Chip className="popper_chip" color="warning" label={linkCourse?.link?.course_container_title} />
                </Box>
              </DialogTitle>
              <DialogContent>
                <Typography gutterBottom>{linkCourse?.title || ''}</Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button size="small" variant="text" onClick={handleCloseDialog}>
                  {unlinkDialog.btnCancel}
                </Button>
                <Button size="small" variant="contained" autoFocus onClick={(event) => handleDeleteLinked(event, linkCourse)}>
                  {unlinkDialog.btnDelete}
                </Button>
              </DialogActions>
            </Paper>
          </Box>
        </Popper>
        {/* POPPER DIALOG */}
        <LoaderWithBackdrop open={loading} />
        <SectionTitle title={`${t('dashboard.linkedCourses')}: ${courses.length}`} />
        <MaterialTable
          title={`${t('dashboard.linkedCourses')}: ${courses.length}`}
          onRowClick={(event, rowData) => handleClickRowCourse(rowData)}
          style={{ padding: '13px 5px' }}
          data={courses}
          options={tableOptions}
          columns={[
            {
              title: t('dashboard.course'),
              field: 'course_title',
              cellStyle: { width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
            },
            { title: 'Online', field: 'course_online' },
            { title: 'Pnt', field: 'course_points' },
            {
              title: t('dashboard.till'),
              field: 'valid_period_end',
              cellStyle: { width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
              render: (rowData) => convertDate(rowData.valid_period_end)
            },
            {
              title: t('dashboard.linked'),
              field: 'course_container',
              cellStyle: { lineHeight: '11px', textAlign: 'right' },
              render: (rowData) => linkedCourses(rowData)
            }
          ]}
        />
      </Box>
    </Box>
  );
};

DashboardTable.propTypes = {
  courses: PropTypes.array
};

export default observer(DashboardTable);

const sxLinkedCourses = {
  background: 'white',
  padding: '10px',
  borderRadius: '5px',
  '.MuiTableRow-head': { height: '50px' },
  '.MuiTableCell-root': { padding: '8px!important', fontSize: '14px' },
  '.MuiPaper-root': { backgroundColor: 'transparent', boxShadow: 'none' },
  '.MuiTablePagination-root': { border: 'none', background: 'white', borderRadius: '5px', marginTop: '15px', padding: '0px' }
};

const sxPopper = {
  zIndex: '10',
  ' .popper_box': { bgcolor: 'background.paper', borderRadius: '4px', boxShadow: '1px 2px 15px black', minWidth: '400px' },
  ' .popper_title': { fontSize: '18px', display: 'flex', justifyContent: 'space-between' },
  ' .popper_chip': { height: '24px', fontSize: '11px', marginRight: '5px', border: `2px solid white` }
};
