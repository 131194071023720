import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Stack, Button } from '@mui/material';

import { Form } from 'components/form';
import { Dialog } from 'pages/curriculum/components';
import { useStore } from 'hooks';
import ActivitiesFormPart from './ActivitiesFormPart';

const validationSchema = Yup.object().shape({
  activityName: Yup.string().required(),
  activityResult: Yup.string().required()
});

const AddOrEditActivityModal = ({ visible, onCloseCallback, onSubmitCallback, fn, activity = null }) => {
  const { t } = useTranslation('curriculum');
  const store = useStore();

  const title = activity ? t('functions.editActivityTitle') : t('functions.addActivityTitle');
  const buttonLabel = t('dashboard.saveLabel');
  const icon = activity ? null : 'ant-design:plus-outlined';
  const name = title.replace(' ', '-').toLowerCase();

  const initialValues = {
    activityName: activity?.attributes?.name || '',
    activityResult: activity?.attributes?.expected_result || ''
  };

  const submitRef = useRef();

  const handleSubmit = async ({ activityName, activityResult }) => {
    let result = null;

    store.startLoadCurriculum();
    if (activity?.id) {
      result = await store.updateCurriculumFunctionActivity({
        ...activity,
        attributes: {
          ...activity.attributes,
          name: activityName,
          expected_result: activityResult
        }
      });
    } else {
      result = await store.createCurriculumFunctionActivity(fn, {
        name: activityName,
        expected_result: activityResult
      });
    }
    store.stopLoadCurriculum();

    if (!result) {
      return {
        success: false,
        errors: [{ name: 'root.serverError', error: { type: 'server', message: 'Internal Server Error' }, options: {} }]
      };
    }

    if (result.errors) {
      return {
        success: false,
        errors: result.errors
      };
    }

    onSubmitCallback();

    return { success: true };
  };

  const handleClose = () => {
    onCloseCallback();
  };

  return (
    <Dialog
      action={{
        action: () => {
          submitRef.current.click();
        },
        name: buttonLabel,
        isLoading: store.isLoadingCurriculum,
        icon
      }}
      title={title}
      name={name}
      visible={visible}
      onClose={handleClose}
    >
      <Form id="add-function" onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ control, errors }) => (
          <Stack justifyContent="space-between" sx={{ paddingBlock: '8px' }} spacing={2}>
            <ActivitiesFormPart control={control} errors={errors} />
            <Button sx={{ display: 'none' }} ref={submitRef} type="submit">
              {t('general.addBtnLabel')}
            </Button>
          </Stack>
        )}
      </Form>
    </Dialog>
  );
};

AddOrEditActivityModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCloseCallback: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func.isRequired,
  fn: PropTypes.object,
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      expected_result: PropTypes.string.isRequired
    })
  })
};

AddOrEditActivityModal.defaultProp = {
  activity: null
};

export default observer(AddOrEditActivityModal);
