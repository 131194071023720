import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { Avatar } from '@mui/material';

const ImageUploader = forwardRef(({ name, control, onChange: onChangeProp, src, editMode, ...otherProps }, ref) => {
  const [files, setFiles] = useState(src);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={otherProps.defaultValue}
      render={({ field }) => (
        <label htmlFor="file-input">
          <Avatar alt="Travis Howard" id="avatar" src={files} sx={{ width: 94, height: 94 }} />
          {editMode && (
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              {...field}
              ref={ref}
              hidden
              id="file-input"
              value={otherProps.defaultValue}
              className="image_upload"
              onChange={(e) => {
                const reader = new FileReader();
                const dataReader = new FileReader();
                const file = e.currentTarget.files[0];

                const fileDetails = {
                  filename: file.name,
                  size: file.size
                };
                // TODO: This was where the error is coming from. The file was not being read as a binary string. Find a better way to do this.
                dataReader.readAsDataURL(file);
                reader.readAsBinaryString(file);
                reader.onloadend = () => {
                  const data = reader.result;
                  fileDetails.data = data;
                  field.onChange(fileDetails);
                };
                dataReader.onloadend = () => {
                  setFiles(dataReader.result);
                };
              }}
            />
          )}
        </label>
      )}
    />
  );
});

ImageUploader.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  onChange: PropTypes.func,
  src: PropTypes.string,
  editMode: PropTypes.bool
};

export default ImageUploader;
