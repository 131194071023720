import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MyTextField } from 'components/form/RHF';
import * as Styles from '../../../components/styles';

const ActivitiesFormPart = ({ control, errors }) => {
  const { t } = useTranslation('curriculum');

  return (
    <>
      <label style={{ fontWeight: '600' }} htmlFor="activityName">
        {t('functions.activitiesTitle')}
      </label>
      <div>{t('functions.activitiesDescription')}</div>
      <MyTextField
        control={control}
        label={t('functions.activitiesLabel')}
        name="activityName"
        id="activityName"
        variant="outlined"
        fullWidth
        multiline
        rows={5}
        placeholder={t('functions.activitiesPlaceholder')}
        helperText={errors?.activityName?.message}
        error={!!errors?.activityName}
        sx={Styles.TextField}
      />

      <label style={{ fontWeight: '600' }} htmlFor="activityResult">
        {t('functions.expectedResultsTitle')}
      </label>
      <div>{t('functions.expectedResultsDescription')}</div>
      <MyTextField
        control={control}
        label={t('functions.expectedResultsLabel')}
        id="activityResult"
        name="activityResult"
        variant="outlined"
        fullWidth
        multiline
        rows={5}
        placeholder={t('functions.expectedResultsPlaceholder')}
        helperText={errors?.activityResult?.message}
        error={!!errors?.activityResult}
        sx={Styles.TextField}
      />
    </>
  );
};

ActivitiesFormPart.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object
};

export default observer(ActivitiesFormPart);
