/* Core */
import PropTypes from 'prop-types';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
/* MUI */
import Box from '@mui/material/Box';
/* Custom components */
import CourseExpiredSection from '../CourseExpiredSection';
import SpecialAccessSection from '../SpecialAccessSection';
import DownloadCertificateSection from '../DownloadCertificateSection';
import CourseLessonSection from '../CourseLessonSection';
import MobileTopbarCard from './MobileTopbarCard';

const MobileTopbar = (props) => {
  // eslint-disable-next-line camelcase
  const {
    id,
    isAccredited,
    accreditation,
    title,
    lessons,
    duration,
    logo,
    endDate,
    courseStatus,
    extraPoints,
    extraPointsDescr,
    isTrialCourse
  } = props;
  const store = useStore();
  const { isCourseFinished } = store.courseResults;

  const showSpecialAccessCTA = store.login.hasSpecialAccess && store.specialAccessCourseIds?.includes(id);

  return (
    <Box className="sidebar">
      <MobileTopbarCard
        lessons={lessons}
        isAccredited={isAccredited}
        accreditation={accreditation}
        duration={duration}
        extraPoints={extraPoints}
        extraPointsDescr={extraPointsDescr}
        isTrialCourse={isTrialCourse}
      />
      <CourseExpiredSection endDate={endDate} courseStatus={courseStatus} />
      {lessons && lessons.length > 1 && <CourseLessonSection lessons={lessons} />}
      {isCourseFinished(Number(store.params.courseId)) && <DownloadCertificateSection title={title} />}
      {showSpecialAccessCTA && <SpecialAccessSection lessons={lessons} />}
      {logo && <Box component="img" sx={{ width: '100%', maxWidth: '150px', margin: '0 auto' }} alt="accreditation-collage" src={logo} />}
    </Box>
  );
};
MobileTopbar.propTypes = {
  id: PropTypes.number,
  isAccredited: PropTypes.bool,
  accreditation: PropTypes.string,
  duration: PropTypes.number,
  extraPoints: PropTypes.number,
  extraPointsDescr: PropTypes.string,
  title: PropTypes.string,
  lessons: PropTypes.any,
  logo: PropTypes.string,
  endDate: PropTypes.string,
  courseStatus: PropTypes.string,
  isTrialCourse: PropTypes.bool
};
export default observer(MobileTopbar);
