import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { LocationContent } from './LocationContent';
import { LocationButtonBase } from './LocationButtonBase';

export const LocationButtonMobile = ({ location }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} sx={{ margin: 2 }}>
        <LocationContent location={location} />
      </Dialog>
      <LocationButtonBase onClick={() => setOpen(true)}>{location.attributes.name}</LocationButtonBase>
    </>
  );
};

LocationButtonMobile.propTypes = {
  location: PropTypes.any.isRequired
};
