import MainLayout from 'pages/mainLayout/MainLayout';
import ErrorPage from '../ErrorPage';

const ErrorPageViewMap = {
  errorPage: (
    <MainLayout subtitle="Error page" title="Oepsie">
      <ErrorPage />
    </MainLayout>
  )
};

export default ErrorPageViewMap;
