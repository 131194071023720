/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

/* Helpers */
import { matchNumbersInString } from 'helpers/Helpers';
/* MUI */
import { Box } from '@mui/material';
import CourseSubHeaders from './CourseSubHeaders';

// renders the course video to be displayed in the preview dialog
const CourseVideo = ({ previewValues }) => {
  const hasVimeoId = matchNumbersInString(previewValues?.lessonContainer?.attributes?.field_preview_video);
  if (!previewValues?.lessonContainer?.attributes?.field_preview_video) return '';
  return (
    <Box sx={{ border: '1px solid #eee', padding: '15px', borderRadius: '20px' }}>
      <CourseSubHeaders title="Preview" />

      <Box>
        {hasVimeoId ? (
          <Box className="video-wrapper" sx={sxVideoWrapper}>
            <ReactPlayer
              // eslint-disable-next-line no-undef
              url={`https://vimeo.com/${hasVimeoId[0]}`}
              height="100%"
              width="100%"
              className="video-player"
              controls
            />
          </Box>
        ) : (
          <Box>Geen video gevonden</Box>
        )}
      </Box>
    </Box>
  );
};

CourseVideo.propTypes = {
  previewValues: PropTypes.object
};

export default observer(CourseVideo);

const sxVideoWrapper = {
  position: 'relative',
  paddingTop: '56.25%',
  borderRadius: '6px',
  overflow: 'hidden',
  '.video-player': { position: 'absolute', top: 0, left: 0 },
  iframe: { width: '100%', height: '100%' }
};
