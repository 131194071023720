import DownloadIcon from '@mui/icons-material/Download';
import { Button, Tooltip } from '@mui/material';
import { useStore } from 'hooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const lmsManualUrl = `/system/files/2024-08/LMS_Handleiding.pdf`;
const pharmacistManualUrl = `/system/files/2024-08/LMS_Handleiding_assistententeams.pdf`;

const LmsDownloadButton = ({ url, text, tooltip }) => (
  <Tooltip title={tooltip}>
    <Button
      size="small"
      variant="contained"
      color="warning"
      href={url}
      target="_blank"
      rel="noreferrer"
      sx={{ color: 'white', textTransform: 'none' }}
      startIcon={<DownloadIcon />}
    >
      {text}
    </Button>
  </Tooltip>
);

LmsDownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};

const LmsDownloadManualBtns = () => {
  const { t } = useTranslation();
  const store = useStore();
  const isLms = store.showMenuItemLms;
  const isAssistant = store.showMenuAssistant;

  function getLearnDomainUrl() {
    const { label } = store;
    const mapLabelToDomain = {
      ewise: 'e-wise.nl',
      pe: 'pe-academy.nl',
      po: 'po-online.nl',
      cme: 'cme-online.nl'
    };
    const { hostname } = window.location;
    const isStaging = hostname.includes('staging') || hostname.includes('local');
    const url = `https://learn${isStaging ? '.aws-staging' : ''}.${mapLabelToDomain[label]}`;
    return url;
  }

  if (isAssistant) {
    return (
      <LmsDownloadButton
        url={`${getLearnDomainUrl()}${pharmacistManualUrl}`}
        text={t('lms.downloadManual.assistant.text')}
        tooltip={t('lms.downloadManual.assistant.tooltip')}
      />
    );
  }
  if (isLms) {
    return (
      <LmsDownloadButton
        url={`${getLearnDomainUrl()}${lmsManualUrl}`}
        text={t('lms.downloadManual.lms.text')}
        tooltip={t('lms.downloadManual.lms.tooltip')}
      />
    );
  }
  return null;
};

export default LmsDownloadManualBtns;
