import { useContext, useState, useEffect } from 'react';
import { Store } from 'state/ContextStore';

/**
 * Make a request to a custom api endpoint AKA an EWAPI.
 *
 * @param url
 * @param type
 * @returns {{response: unknown, error: unknown, loading: boolean}}
 */
const useEwapi = (url, type) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const store = useContext(Store);
  const { pattern } = store.routerStore.getCurrentRoute();
  const isAdmin = pattern.includes('/admin/');

  useEffect(() => {
    const doFetch = async () => {
      setLoading(true);
      const urlEwapi = `ewapi/${url}`;
      try {
        const resp = await window.authedClient.get(urlEwapi);
        setResponse(resp.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    doFetch();
  }, [isAdmin, store, type, url]);
  return { response, error, loading };
};
export default useEwapi;
