/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
/* Custom components */
import MaterialTable from '@material-table/core';
import { Box, Chip, Popper, Typography, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
// helper
import { ToastDraftCourses, convertEpochTimestamp } from 'pages/adminCourses/addCourse/EditCourse.helper';
import LoaderWithBackdrop from '../loading/LoaderWithBackdrop';

// renders a table of draft courses
const MyDraftCourses = ({ courses }) => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');
  const [loading, setLoading] = useState(false);
  const [anchorPopper, setAnchorPopper] = useState(null);
  const [deleteCourse, setDeleteCourse] = useState({});
  //
  const open = Boolean(anchorPopper);
  const id = open ? 'simple-popper' : undefined;

  const toast = ToastDraftCourses();

  // POPPER DIALOG: TRANSLATIONS ____________________________
  const unlinkDialog = {
    title: t('draftCourses.deleteDraftDialog.title'),
    message: t('draftCourses.deleteDraftDialog.message'),
    btnDelete: t('draftCourses.deleteDraftDialog.delete'),
    btnCancel: t('draftCourses.deleteDraftDialog.cancel')
  };
  // POPPER DIALOG: OPEN _________________________________
  const handleOpenDialogDeleteCourse = (event, course) => {
    setDeleteCourse(course);
    setAnchorPopper(anchorPopper ? null : event.currentTarget);
  };
  // POPPER DIALOG: CLOSE _________________________________
  const handleCloseDialog = () => setAnchorPopper(null);

  // POPPER DIALOG: DELETE btn ____________________________
  const handleDeleteLinked = async (event, course) => {
    setLoading(true);
    try {
      await store.courseEdit.deleteDraftCourse(course.course_uuid);
      const index = courses.findIndex((item) => item.course_uuid === course.course_uuid);
      courses.splice(index, 1);
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
    } catch (error) {
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
    }
    setLoading(false);
    setAnchorPopper(anchorPopper ? null : event.currentTarget);
  };

  const tableOptions = {
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 100],
    emptyRowsWhenPaging: false,
    search: false,
    showTitle: false,
    header: true,
    toolbar: false,
    dense: true,
    thirdSortClick: false
  };

  const tableColumns = [
    { title: 'id', field: 'course_nid' },
    {
      title: t('dashboard.course'),
      field: 'course_title',
      cellStyle: { width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
    },
    { title: 'Pnt', field: 'course_points' },
    {
      title: 'Created',
      field: 'course_created',
      cellStyle: { width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      render: (rowData) => convertEpochTimestamp(rowData.course_created, 'DD-MM-YYYY')
    },
    {
      title: 'Changed',
      field: 'lesson_container_changed',
      cellStyle: { width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      render: (rowData) => convertEpochTimestamp(rowData.lesson_container_changed, 'DD-MM-YYYY'),
      defaultSort: 'desc'
    },
    {
      title: t('dashboard.status'),
      field: 'course_status',
      cellStyle: { width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      render: (rowData) => (
        <Chip
          color="primary"
          label="draft"
          onDelete={(event) => handleOpenDialogDeleteCourse(event, rowData)}
          sx={{
            marginRight: '5px',
            border: `3px solid white`,
            height: '24px',
            color: 'white',
            '.MuiChip-deleteIcon': { fontSize: '20px' }
          }}
        />
      )
    }
  ];

  const handleClickCourse = (course) => {
    setLoading(true);
    const { package: _package, containerNid } = store.routerStore.routerState.params;
    const routerParams = {
      params: { package: _package.toString(), packageNid: store.params.packageNid.toString(), containerNid, courseuuid: course.course_uuid }
    };
    store.routerStore.goTo('editCourse', routerParams);
  };

  // NO DRAFT COURSES VIEW
  if (courses.length === 0) {
    return <Box sx={noCourses}> No draft courses </Box>;
  }
  // DRAFT COURSES VIEW
  // const tableData = courses.map((course) => ({ ...course, id: course.course_nid }));
  return (
    <Box>
      <Box sx={sxBoxContainer} className="draft_courses_container">
        <LoaderWithBackdrop open={loading} />
        {/* POPPER DIALOG */}
        <Popper className="popper" id={id} open={open} anchorEl={anchorPopper} sx={sxPopper}>
          <Box className="popper_box">
            <Paper elevation={0}>
              <DialogTitle className="popper_title" id="dialogDeleteLink">
                <Box>{unlinkDialog.title}</Box>
                <Box>
                  <Chip className="popper_chip" color="warning" label={deleteCourse?.link?.course_container_title} />
                </Box>
              </DialogTitle>
              <DialogContent>
                <Typography gutterBottom>{deleteCourse?.course_title || ''}</Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button size="small" variant="text" onClick={handleCloseDialog}>
                  {unlinkDialog.btnCancel}
                </Button>
                <Button size="small" variant="contained" autoFocus onClick={(event) => handleDeleteLinked(event, deleteCourse)}>
                  {unlinkDialog.btnDelete}
                </Button>
              </DialogActions>
            </Paper>
          </Box>
        </Popper>
        {/* POPPER DIALOG */}
        <MaterialTable
          title={t('dashboard.draftCourses')}
          style={{ padding: '8px' }}
          data={courses}
          options={tableOptions}
          onRowClick={(event, rowData) => handleClickCourse(rowData)}
          columns={tableColumns}
        />
      </Box>
    </Box>
  );
};

MyDraftCourses.propTypes = {
  courses: PropTypes.array
};

export default observer(MyDraftCourses);

const sxBoxContainer = {
  ' .MuiPaper-root': { borderRadius: '0 0 5px 5px' },
  borderRadius: '0 0 5px 5px',
  ' table tfoot td': { border: 'none !important' },
  display: 'block',
  gap: '10px 10px',
  marginBottom: '15px',
  position: 'relative',
  '>div': {
    boxShadow: 'none'
  },
  '.MuiTableCell-root': { padding: '8px', fontSize: '14px' }
};

const sxPopper = {
  zIndex: '10',
  ' .popper_box': { bgcolor: 'background.paper', borderRadius: '4px', boxShadow: '1px 2px 15px black', minWidth: '400px' },
  ' .popper_title': { fontSize: '18px', display: 'flex', justifyContent: 'space-between' },
  ' .popper_chip': { height: '24px', fontSize: '11px', marginRight: '5px', border: `2px solid white` }
};

const noCourses = {
  background: '#dbdbdb82',
  display: 'flex',
  height: '100px',
  borderRadius: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '15px'
};
