import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { Box, Button } from '@mui/material';
// ICONS
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

/*
In summary, the FileUpload component is used for file uploads in a React form. 
It allows users to select files, and the selected file's name is displayed in the component. 
The file's content is read and can be processed within the onChange callback, making it suitable for various use cases
 where file uploads are required, including image avatars or document uploads. 
The component is typically used within a form and benefits from controlled form handling provided by libraries like react-hook-form.
*/
const FileUpload = forwardRef(
  ({ name, control, index, onChange: onChangeProp, avatar, buttonName, fileName, accept, editMode, reset, ...otherProps }, ref) => {
    const [filename, setFilename] = useState(fileName);

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={otherProps.defaultValue}
        render={({ field }) => (
          <Box sx={{ alignItems: 'center' }}>
            <label htmlFor="file-input">
              <Button
                sx={{ fontSize: '10px', pointerEvents: 'none' }}
                variant="contained"
                component="label"
                size="small"
                startIcon={<CloudUploadIcon />}
              >
                {buttonName}
              </Button>

              <input
                type="file"
                accept={accept}
                {...field}
                ref={ref}
                hidden
                id="file-input"
                value={otherProps.defaultValue}
                className="file_upload"
                onChange={(e) => {
                  const reader = new FileReader();
                  const dataReader = new FileReader();
                  const file = e.currentTarget.files[0];

                  const fileDetails = {
                    filename: file.name
                    // size: file.size
                  };
                  setFilename(file.name);
                  // TODO: This was where the error is coming from. The file was not being read as a binary string. Find a better way to do this.
                  dataReader.readAsDataURL(file);
                  reader.readAsBinaryString(file);
                  reader.onloadend = () => {
                    const data = reader.result;
                    fileDetails.data = data;
                    field.onChange(fileDetails);
                  };
                  // const newFiles = [...files];
                  // dataReader.onloadend = () => {
                  //   newFiles[index] = dataReader.result;
                  //   setFiles(newFiles);
                  // };
                }}
              />
            </label>
            <Box textAlign="left">{filename}</Box>
          </Box>
        )}
      />
    );
  }
);

FileUpload.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  reset: PropTypes.any,
  onChange: PropTypes.func,
  // src: PropTypes.string,
  editMode: PropTypes.bool,
  avatar: PropTypes.bool,
  buttonName: PropTypes.string,
  accept: PropTypes.string,
  fileName: PropTypes.string,
  index: PropTypes.number
};

export default FileUpload;
