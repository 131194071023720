import { Box, Typography } from '@mui/material';
import { useBreakpoint } from 'hooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { VtbTooltip } from '../../../VtbTooltip';
import { formatDate } from '../../../lib/formatDate';
import { EventButton } from './EventButton';

export const EventTypeColumn = ({ vtbEvents, onEventClick, isUserAttendingEventType, eventType }) => {
  const { t } = useTranslation('vtb');
  const breakpoint = useBreakpoint();
  const mobileBreakpoint = breakpoint === 'sm' || breakpoint === 'xs';

  const { name } = eventType.attributes;

  const getTitleContent = (vtbEvent) => {
    if (isUserAttendingEventType && vtbEvent.isUserAttending && vtbEvent.isEnrollmentClosed) {
      return (
        <Box sx={{ p: 0.5 }}>
          <Typography border variant="h6" textAlign="center" sx={{ color: '#A3AED0', fontSize: 16, fontWeight: 400 }}>
            {t('popups.attendedPastEvent')}
          </Typography>
        </Box>
      );
    }
    if (isUserAttendingEventType && !vtbEvent.isUserAttending && !vtbEvent.isEnrollmentClosed) {
      return (
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h5"
            sx={{
              color: '#2B3674',
              fontSize: 22,
              fontWeight: 500,
              mb: 2
            }}
          >
            {t('popups.alreadySignedInSameType.title', { name })}
          </Typography>
          <Typography variant="body" sx={{ color: '#A3AED0', fontSize: 16, fontWeight: 400 }}>
            {t('popups.alreadySignedInSameType.text', { name })}
          </Typography>
        </Box>
      );
    }
    return '';
  };

  return (
    <Box sx={{ flex: '0 0 250px', display: 'flex', flexDirection: 'column', gap: 1 }}>
      {vtbEvents.map((vtbEvent) => (
        <VtbTooltip key={vtbEvent.id} arrow placement={mobileBreakpoint ? undefined : 'right-start'} title={getTitleContent(vtbEvent)}>
          <EventButton
            onClick={() =>
              (vtbEvent.isUserAttending || !isUserAttendingEventType) &&
              onEventClick({
                vtbEvent,
                isUserAttending: vtbEvent.isUserAttending
              })
            }
            isUserAttending={vtbEvent.isUserAttending}
            isUserAttendingEventType={isUserAttendingEventType}
            isEnrollmentClosed={vtbEvent.isEnrollmentClosed}
            noSeatsAvailable={vtbEvent.noSeatsAvailable}
          >
            {formatDate(vtbEvent.attributes.date.value)}
          </EventButton>
        </VtbTooltip>
      ))}
    </Box>
  );
};

EventTypeColumn.propTypes = {
  isUserAttendingEventType: PropTypes.bool,
  vtbEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        date: PropTypes.shape({
          value: PropTypes.string.isRequired
        }).isRequired
      }).isRequired,
      isEnrollmentClosed: PropTypes.bool
    })
  ).isRequired,
  onEventClick: PropTypes.func,
  eventType: PropTypes.any
};
