/* Course status */
import PropTypes from 'prop-types';

/* MUI */
import { Box, IconButton, Typography } from '@mui/material';

// displays a message when there are no courses
const NoCourses = (props) => {
  const { text, icon } = props;

  return (
    <Box className="noFavorite_section">
      <IconButton aria-label="add to favorites" className="favourite_icon">
        {icon}
      </IconButton>
      <Typography color="primaryBlue.main" variant="h1" fontSize="27px">
        {text}
      </Typography>
    </Box>
  );
};

NoCourses.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any
};

export default NoCourses;
