/* eslint-disable require-atomic-updates, no-use-before-define, camelcase */
import { flow, getEnv, getSnapshot, getParent, types as t } from 'mobx-state-tree';
import cloneDeep from 'lodash/cloneDeep';
import * as logger from 'loglevel';

const log = logger.getLogger('CourseCopy');

export async function getCourseCopy() {
  return null;
}

export const CourseCopy = t
  .model('CourseCopy', {})
  .volatile(() => ({
    store: null,
    course_container: null,
    course: null,
    lesson_container: null,
    lessons: null,
    fetched: null,
    fetchedCourse: null,
    selected: null,
    rollBack: null,
    rollBackArray: null,
    newCourse: null
  }))
  .views((self) => ({
    get currentProfessionId() {
      return self.store.packageProfession;
    },

    get jsonApi() {
      return getParent(self).jsonApi;
    },

    get queryParams() {
      return (
        `fields[lesson_content]=drupal_internal__id,title,lessontext,tagdoc,lesson_content_widgets` +
        `&include=lesson_container, lesson_container.lessons, lesson_container.lessons.lesson_content,` +
        `lesson_container.lessons.lesson_content.lesson_content_widgets`
      );
    },

    get emptySubQuery() {
      return { subQuery: [], relData: [], waitFor: [] };
    }
  }))
  .actions((self) => ({
    afterCreate() {
      // Constructor
      log.info('Starting courseCopy');
      self.store = getEnv(self).store;
      self.course_container = self.store.package.courseContainer;
    },

    getTime() {
      return `${new Date().getHours()}-${new Date().getMinutes()}`;
    },

    getCourseContainer() {
      const newCourseContainer = cloneDeep(getSnapshot(self.course_container));
      newCourseContainer.relationships.courses.data.push({ type: 'course', id: '{{course.body@$.data.id}}' });
      return newCourseContainer;
    },

    getCreateRequest(actionId) {
      const createReq = {
        1: {},
        2: {
          course_container: true,
          course: true,
          lesson_container: false,
          lesson: false,
          lesson_content: false,
          question_widgets: false
        },
        3: {
          course_container: true,
          course: true,
          lesson_container: true,
          lesson: true,
          lesson_content: false,
          question_widgets: false
        },
        4: {
          course_container: true,
          course: true,
          lesson_container: true,
          lesson: true,
          lesson_content: true,
          question_widgets: true
        }
      };
      return createReq[actionId];
    },

    getProfession() {
      return { type: 'profession', id: self.currentProfessionId };
    },

    getStoreUpdateItems() {
      return self.newCourse.filter((item) => item.type === 'course_container' || item.type === 'course');
    },

    // [2] COPY [Course] --> [lesson container]
    fetchCourse: flow(function* fetchCourse(courseUUId) {
      const { queryParams } = self;
      const createReq = self.getCreateRequest(2);

      try {
        const fetched = yield self.jsonApi.fetchIt('course', queryParams, courseUUId);
        const groupCourse = groupCourseByType(fetched);
        const groupCourseLessons = groupLessonsByItems(cloneDeep(groupCourse));
        const subRequest = self.createSubRequest(createReq, groupCourseLessons, groupCourse);
        const newCourse = yield self.jsonApi.createCourse(subRequest);
        self.newCourse = validateFetchedToArray(newCourse);
        return newCourse;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        return null;
      }
    }),

    // [3] COPY: [Course] + [lesson container] + [lesson] --> [lesson_content]
    fetchCourse2Lessons: flow(function* fetchCourse2Lessons(courseUUId) {
      const { queryParams } = self;
      const createReq = self.getCreateRequest(3);

      try {
        const fetched = yield self.jsonApi.fetchIt('course', queryParams, courseUUId);
        const groupCourse = groupCourseByType(fetched);
        const groupCourseLessons = groupLessonsByItems(cloneDeep(groupCourse));
        const subRequest = self.createSubRequest(createReq, groupCourseLessons, groupCourse);
        const newCourse = yield self.jsonApi.createCourse(subRequest);
        self.newCourse = validateFetchedToArray(newCourse);
        return newCourse;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        return null;
      }
    }),

    // [4] COPY: [Course] + [lesson container] + [lesson] + [lesson_content] + [widgets]
    fetchCompleteCourse: flow(function* fetchCompleteCourse(courseUUId) {
      const { queryParams } = self;
      const createReq = self.getCreateRequest(4);

      try {
        const fetched = yield self.jsonApi.fetchIt('course', queryParams, courseUUId);
        const groupCourse = groupCourseByType(fetched);
        const groupCourseLessons = groupLessonsByItems(cloneDeep(groupCourse));
        const subRequest = self.createSubRequest(createReq, groupCourseLessons, groupCourse);
        const newCourse = yield self.jsonApi.createCourse(subRequest);
        self.newCourse = validateFetchedToArray(newCourse);
        return newCourse;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        return null;
      }
    }),

    // [5] ========= TEST TEST ======
    testCourse: flow(function* testCourse(courseUUId) {
      const { queryParams } = self;
      const createReq = {
        course_container: true,
        course: true,
        lesson_container: true,
        lesson: true,
        lesson_content: true,
        question_widgets: true
      };

      try {
        const fetched = yield self.jsonApi.fetchIt('course', queryParams, courseUUId);

        const groupCourse = groupCourseByType(fetched);
        const groupCourseLessons = groupLessonsByItems(cloneDeep(groupCourse));
        const subRequest = self.createSubRequest(createReq, groupCourseLessons, groupCourse);
        const newCourse = yield self.jsonApi.createCourse(subRequest);
        self.newCourse = validateFetchedToArray(newCourse);
        return newCourse;
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        return null;
      }
    }),

    // ROLLBACK
    rollbackCourse: flow(function* rollbackCourse() {
      const rollBackQuery = self.doRollBack();
      try {
        return yield self.jsonApi.createCourse(rollBackQuery);
      } catch (e) {
        self.error = e;
        self.state = 'error';
        self.errorShown = false;
        log.error(e);
        return null;
      }
    }),

    /**
     * create subrequest
     */
    createSubRequest(createReq, lessonsArray, fetchedCourse) {
      const subReq = self.emptySubQuery;
      const create = createReq;

      const totLessonSubRequest = [];
      const lessonContainerReldata = [];
      const lessonContainerWaitFor = [];

      const courseContainer = cloneDeep(getSnapshot(self.course_container));

      if (lessonsArray) {
        lessonsArray.forEach((lesson, i) => {
          const index = i + 1;

          const reqWidgets = create.question_widgets ? self.createReq_CourseWidgets(lesson, index) : subReq;

          const reqContent = create.lesson_content
            ? self.createReq_LessonContent(lesson, index, reqWidgets.relData, reqWidgets.waitFor)
            : subReq;

          const reqLesson = create.lesson ? self.createReq_Lesson(lesson, index, reqContent.relData, reqContent.waitFor) : subReq;

          const subQuery = [...reqWidgets.subQuery, ...reqContent.subQuery, ...reqLesson.subQuery];

          totLessonSubRequest.push(...subQuery);
          lessonContainerReldata.push(reqLesson.relData);
          lessonContainerWaitFor.push(reqLesson.waitFor);
        });
      }

      //

      const reqLessonContainer =
        create.lesson_container && fetchedCourse.lesson_container
          ? self.createReq_LessonContainer(fetchedCourse.lesson_container, 1, lessonContainerReldata, lessonContainerWaitFor)
          : subReq;

      const reqCourse =
        create.course && fetchedCourse.course
          ? self.createReq_Course(fetchedCourse.course, 1, reqLessonContainer.relData, reqLessonContainer.waitFor)
          : subReq;

      const reqCourseContainer =
        create.course_container && fetchedCourse.course && courseContainer
          ? self.createReq_CourseContainer(courseContainer, 1, reqCourse.relData, reqCourse.waitFor)
          : subReq;

      const totSubRequest = [...totLessonSubRequest, ...reqLessonContainer.subQuery, ...reqCourse.subQuery, ...reqCourseContainer.subQuery];

      return totSubRequest;
    },

    createReq_CourseWidgets(lesson, index) {
      if (!lesson.question_widgets || !lesson.question_widgets.length) return self.emptySubQuery;
      const cleanData = lesson.question_widgets.map((item) => deleteKeys(item));
      return createMultiDependencySubQuery2(cleanData, index);
    },

    createReq_LessonContent(lesson, index, relData, waitFor) {
      if (!lesson.lesson_content) return self.emptySubQuery;
      const data = self.processData(lesson.lesson_content);
      const dal = data.attributes.lessontext;

      if (Array.isArray(relData) && relData.length) data.relationships.lesson_content_widgets.data = relData;
      if (dal && dal.value) {
        data.attributes.lessontext = { value: data.attributes.lessontext.value };
      }

      const request = { action: 'create', requestId: data.type, body: data, uri: false, waitFor, key: index };
      return createSubRequestItem(request);
    },

    createReq_Lesson(lesson, index, relData, waitFor) {
      if (!lesson.lesson) return self.emptySubQuery;
      const data = self.processData(lesson.lesson);
      if (!Array.isArray(relData)) data.relationships.lesson_content.data = relData;

      const request = { action: 'create', requestId: data.type, body: data, uri: false, waitFor, key: index };
      return createSubRequestItem(request);
    },

    createReq_LessonContainer(lessonContainer, key, relData, waitFor) {
      const data = self.processData(lessonContainer);
      data.relationships.lessons.data = relData;

      const request = { action: 'create', requestId: data.type, body: data, uri: false, waitFor, key };
      return createSubRequestItem(request);
    },

    createReq_Course(course, key, relData, waitFor) {
      const data = self.processData(course);

      if (!Array.isArray(relData)) data.relationships.lesson_container.data = relData;
      data.attributes.online = false;
      data.attributes.course_status = 'New';

      const request = { action: 'create', requestId: data.type, body: data, uri: false, waitFor, key };
      return createSubRequestItem(request);
    },

    createReq_CourseContainer(courseContainer, key, relData, waitFor) {
      const data = courseContainer;
      data.relationships.courses.data.push(relData);

      const request = { action: 'update', requestId: data.type, body: data, uri: false, waitFor, key };
      return createSubRequestItem(request);
    },

    processData(data) {
      const newData = deleteKeys(data);
      if (newData.relationships.profession) newData.relationships.profession.data = self.getProfession();
      newData.attributes.title = `${getTime()} > ${data.attributes.title}`;
      return newData;
    },

    doRollBack() {
      const rollBack = [];
      let nr = 1;
      Object.keys(self.rollBack).forEach((item) => {
        const node = { type: self.rollBack[item].type, id: self.rollBack[item].id };
        if (node.type !== 'course_container') {
          const del = _createDeleteSubRequestItem(node, nr);
          rollBack.push(del);
          nr++;
        }
      });

      return rollBack;
    }
  }));

/**
 * Group jsonapi response by type
 */
function groupCourseByType(fetched) {
  return {
    course: fetched.data,
    lessons: fetched && fetched.included ? fetched.included.filter((l) => l.type === 'lesson') : false,
    lesson_container: fetched && fetched.included ? fetched.included.find((lc) => lc.type === 'lesson_container') : false,
    lesson_contents: fetched && fetched.included ? fetched.included.filter((c) => c.type === 'lesson_content') : false,
    question_widgets: fetched && fetched.included ? fetched.included.filter((c) => c.type === 'question_widget') : false
  };
}

/**
 * groupLessonsByItems : Main function
 * Group lesson, lesson_content, questrion_widgets by lesson
 */
function groupLessonsByItems(groupCourse) {
  if (!groupCourse.lessons) return false;

  return groupCourse.lessons.map((lesson) => {
    const lessonContent = _groupLessons_LessonContent(lesson, groupCourse);
    const lessonWidgets = _groupLessons_QuestionWidgets(lessonContent, groupCourse);

    return {
      lesson,
      lesson_content: lessonContent,
      question_widgets: lessonWidgets
    };
  });
}

/**
 * _groupLessons_LessonContent: Returns lesson_content
 * Help function @ groupLessonsByItems
 */
function _groupLessons_LessonContent(lesson, groupCourse) {
  if (!lesson.relationships.lesson_content.data) return false;
  const lessonContentId = lesson.relationships.lesson_content.data.id;
  return groupCourse.lesson_contents.find((c) => c.id === lessonContentId);
}

/**
 * _groupLessons_QuestionWidgets: Returns question_widgets
 * Help function @ groupLessonsByItems
 */
function _groupLessons_QuestionWidgets(lessonContent, groupCourse) {
  if (!lessonContent) return false;
  if (!lessonContent.relationships.lesson_content_widgets.data) return false;

  const lessonContentWidgets = lessonContent.relationships.lesson_content_widgets.data;
  return lessonContentWidgets.map((widget) => groupCourse.question_widgets.find((q) => q.id === widget.id));
}

function getTime() {
  return `${new Date().getHours()}-${new Date().getMinutes()}`;
}

// ! *** DELETE SUBREQUEST ***
function _createDeleteSubRequestItem(req, nr) {
  // let body = (req.body) && req.body;
  return {
    action: 'delete',
    requestId: `${nr}_${req.type}`,
    uri: `jsonapi/${req.type}/${req.id}`
  };
}

/**
 * CREATE SUBREQUEST ITEM
 * Main function
 */
function createSubRequestItem(req) {
  const requestId = `L${req.key}${req.requestId}`;
  const body = req.body ? req.body : {};

  const newSubQuery = [
    {
      action: req.action,
      requestId,
      body: JSON.stringify({ data: body }),
      uri: _getSubRequest_Uri(req),
      waitFor: _getSubRequest_WaitFor(req)
    }
  ];

  const relData = { type: req.body.type, id: `{{${requestId}.body@$.data.id}}` };

  return {
    subQuery: newSubQuery,
    relData,
    waitFor: requestId
  };
}

// Help function @ createSubRequestItem
function _getSubRequest_Uri(req) {
  const endpoint = req.body?.type;
  return req.action === 'create' ? `jsonapi/${endpoint}` : `jsonapi/${endpoint}/${req.body.id}`;
}
// Help function @ createSubRequestItem
function _getSubRequest_WaitFor(req) {
  let waitFor = [];
  if (req.waitFor && Array.isArray(req.waitFor)) {
    waitFor = req.waitFor;
  }
  if (req.waitFor && !Array.isArray(req.waitFor)) {
    waitFor.push(req.waitFor);
  }
  return waitFor;
}

// create SUBREQUEST ITEMS
function _createSubReqItem(action, requestId, data = false, uri = false, waitfor = false) {
  const obj = {};
  const endpoint = data?.type;
  obj.action = action;
  obj.requestId = requestId;
  if (data) obj.body = JSON.stringify({ data });
  if (uri) {
    obj.uri = uri;
  } else {
    obj.uri = action === 'create' ? `jsonapi/${endpoint}` : `jsonapi/${endpoint}/${data.id}`;
  }
  if (waitfor && Array.isArray(waitfor)) obj.waitFor = waitfor;
  if (waitfor && !Array.isArray(waitfor)) obj.waitFor = [`${waitfor}`];
  return obj;
}

// Removes Subrequest response items with errors in it
function validateFetchedToArray(fetched) {
  const response = [];
  Object.keys(fetched).forEach((key) => {
    if (!fetched[key].errors) response.push(fetched[key].data);
  });
  return response;
}

function createMultiDependencySubQuery2(items, reqId = '') {
  // items must be array
  const { type } = items[0];
  const time = getTime();

  const newArray = items.map((item) => {
    item.attributes.title = `${time} > ${item.attributes.title}`;
    return item;
  });

  // info: _createSubReqItem(action, requestId, data = false, uri = false, waitfor = false)
  const newSubQuery = newArray.map((req, i) => _createSubReqItem('create', `${reqId}${type}${i}`, req, false, false));

  const subWaitFor = newSubQuery.map((waitfor) => waitfor.requestId);
  const relationshipData = newSubQuery.map((_rel, i) => ({
    type,
    id: `{{${reqId}${type}${i}.body@$.data.id}}`
  }));

  return {
    subQuery: newSubQuery,
    relData: relationshipData,
    waitFor: subWaitFor
  };
}

function deleteKeys(obj) {
  const attributes = [
    'changed',
    'created',
    'default_langcode',
    'drupal_internal__id',
    'drupal_internal__nid',
    'drupal_internal__vid',
    'langcode',
    'path',
    'promote',
    'revision_log',
    'revision_timestamp',
    'revision_translation_affected',
    'status',
    'sticky'
  ];
  const relationships = ['menu_link', 'node_type', 'revision_uid', 'uid'];

  if (obj.links) delete obj.links;
  if (obj.id) delete obj.id;

  Object.keys(obj.attributes).forEach((item) => {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.attributes?.[item]?.hasOwnProperty('processed')) delete obj.attributes[item].processed;
    if (attributes.includes(item)) delete obj.attributes[item];
  });

  Object.keys(obj.relationships).forEach((item) => {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.relationships?.[item]?.hasOwnProperty('links')) delete obj.relationships[item].links;
    if (relationships.includes(item)) delete obj.relationships[item];
  });

  return obj;
}
