import { LoadingPage } from 'components';
import { Logout } from './Logout';
import { GoToAdmin } from './GoToAdmin';
import { SwitchBack } from './SwitchBack';

const LogoutViewMap = {
  loading: <LoadingPage />,
  logout: <Logout />,
  switchback: <SwitchBack />,
  gotoadmin: <GoToAdmin />
};

export default LogoutViewMap;
