import { ThemeBrandTitle } from 'helpers/Helpers';
import { createRouterState } from 'mobx-state-router';
import { checkUserSignedIn, checkSubscription } from 'state/RoutesActions';

export const curriculumStagesRoute = {
  name: 'curriculumStages',
  meta: { title: `%%curriculum.title%% | ${ThemeBrandTitle()}` },
  pattern: '/portfolio/:uid/stages/:stageId',
  beforeEnter: async (fromState, toState, routerStore) => {
    await checkUserSignedIn(fromState, toState, routerStore);
    return checkSubscription(fromState, toState, routerStore, ['accountant', 'ewise accountant']);
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { uid } = toState.params;

    store.startLoadCurriculum(fromState);
    await store.fetchCurriculum(uid);

    if (!store.curriculum?.data?.id) {
      store.stopLoadCurriculum(fromState);
      return createRouterState('curriculumDashboard');
    }

    if (store.curriculum?.data?.attributes?.is_finished) {
      store.stopLoadCurriculum(fromState);
      return createRouterState('curriculumDashboard');
    }

    await store.fetchCurriculumFunctionExamples();
    await store.fetchCurriculumLearningGoalExamples();
    await store.fetchCurriculumLearningGoalActivityExamples();
    store.stopLoadCurriculum(fromState);

    return null;
  }
};
