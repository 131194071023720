/* Course title */
import PropTypes from 'prop-types';
/* MUI */
import { Box, Tooltip, Typography } from '@mui/material';
import { IsLtiDeeplinker } from 'helpers/Helpers';
import CourseLtiButton from './CourseLtiButton';

// renders the course title
const CourseTitle = (props) => {
  const { courseTitle, courseId, extraLabel } = props;
  return (
    <Box className="course_title">
      {IsLtiDeeplinker() && <CourseLtiButton courseId={courseId} hideBg={0} />}
      <Tooltip title={courseTitle} arrow>
        <Typography className="course_title_main" variant="h5" component="h1" color="primaryBlue.main" fontSize="18px">
          {courseTitle}
        </Typography>
      </Tooltip>
      {extraLabel && (
        <Tooltip title={extraLabel} arrow>
          <Typography className="course_title_sub" variant="body1" color="primary">
            <i>{extraLabel}</i>
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

CourseTitle.propTypes = {
  courseTitle: PropTypes.string,
  courseId: PropTypes.any,
  extraLabel: PropTypes.any
};

export default CourseTitle;
