import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
/* MUI */
import { Box, Divider, TextField, List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
//
import LoaderWithBackdrop from '../loading/LoaderWithBackdrop';
/* HOOKS */
import { dateDiff } from '../../helpers/utils';

// Renders a list of courses that are ending soon
const ListEndingCourses = ({ courses }) => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();
  const [days, setDays] = useState(100);
  const [loading, setLoading] = useState(false);

  const endingCourses = courses.filter((item) => dateDiff(item.valid_period_end) < days);
  const endingCoursesInDays = endingCourses.map((item) => ({ ...item, days: dateDiff(item.valid_period_end) }));
  endingCoursesInDays.sort((a, b) => a.days - b.days);

  /* styling */
  const sxListItems = {
    rowGap: '4px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    position: 'absolute',
    top: '100px',
    bottom: '0px',
    paddingRight: '10px'
  };
  const sxListText = { span: { fontSize: '.9rem' } };
  const sxFlex = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };
  const sxBtn = { justifyContent: 'space-between', gap: '10px 10px', padding: '5px' };
  const sxAvatar = {
    color: 'white',
    background: theme.palette.primary.light,
    width: '30px',
    height: '30px',
    fontSize: '14px',
    lineHeight: '14px'
  };
  const sxTextField = {
    width: '50px',
    height: '15px',
    padding: '0',
    margin: '0 10px',
    '& .MuiInputBase-input': {
      padding: '0',
      textAlign: 'center',
      justifyContent: 'center'
    }
  };

  const handleClickCourse = (course) => {
    setLoading(true);
    const { package: _package, containerNid } = store.routerStore.routerState.params;
    const routerParams = {
      params: { package: _package.toString(), packageNid: store.params.packageNid.toString(), containerNid, courseuuid: course.course_uuid }
    };
    store.routerStore.goTo('editCourse', routerParams);
  };

  const handleChange = (e) => {
    if (Number.isNaN(e.target.value)) return;
    const newValue = Number(e.target.value) || 0;
    const newDays = parseInt(newValue);
    setDays(newDays);
  };

  return (
    <Box
      className="dashBoard__list"
      sx={{ padding: '5px', marginBottom: `15px`, width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}
    >
      <LoaderWithBackdrop open={loading} />
      <Divider>
        {t('dashboard.endingIn')}
        <TextField
          type="text"
          sx={sxTextField}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={handleChange}
          value={days}
          min="0"
        />{' '}
        {t('dashboard.days')}
      </Divider>
      <Box className="list__item_header" sx={sxFlex}>
        <Box>{t('dashboard.course')}</Box>
        <Box sx={{ textAlign: 'center' }}>{t('dashboard.days')}</Box>
      </Box>
      <List className="list__items" sx={sxListItems}>
        {endingCoursesInDays.map((course) => (
          <ListItem className="list__item" key={course.course_uuid} onClick={() => handleClickCourse(course)} disablePadding>
            <ListItemButton className="list__item_btn" sx={sxBtn}>
              <ListItemText sx={sxListText} className="list__item_title" primary={course.course_title} />
              <ListItemIcon sx={{ minWidth: '20px' }}>
                <Avatar sx={sxAvatar}>{course.days}</Avatar>
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

ListEndingCourses.propTypes = {
  courses: PropTypes.array
};

export default observer(ListEndingCourses);
