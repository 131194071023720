import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// renders a subheader for the course preview dialog
const CourseSubHeaders = ({ title }) => (
  <Box sx={{ color: '#2b3674', h2: { marginTop: '0px' } }}>
    <h2>{title || ''}</h2>
  </Box>
);

CourseSubHeaders.propTypes = {
  title: PropTypes.string
};

export default CourseSubHeaders;
