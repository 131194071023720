import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { useHeaderContext } from '../context';
import * as Styled from './HeaderComponents.styles';

const AssistantButton = () => {
  const { t } = useTranslation();

  const { handleGoToAssistant } = useHeaderContext();

  return (
    <Tooltip title={t('AssistantOverview')} arrow>
      <Styled.Button id="go-to-assistant" aria-label="" onClick={handleGoToAssistant}>
        {t('course.leftSidebar.assistent')}
      </Styled.Button>
    </Tooltip>
  );
};

export default observer(AssistantButton);
