import { createElement } from 'react';
import logger from 'loglevel';
import Radio from 'pages/play/questions/RadioQuestion';
import Check from 'pages/play/questions/CheckQuestion';
import Open from 'pages/play/questions/OpenQuestion';
import Sort from 'pages/play/questions/SortQuestion';
import Match from 'pages/play/questions/MatchQuestion';
import Video from 'pages/play/questions/VideoQuestion';
import Html from 'pages/play/questions/HtmlQuestion';
import Numerical from 'pages/play/questions/NumericalQuestion';
import Poll from 'pages/play/questions/PollWidget';
import Wordcloud from 'pages/play/questions/WordCloudWidget';

const log = logger.getLogger('PageParsing');
log.setDefaultLevel('info');

const components = {
  // Static container to connect the names to the (imported) widget-classes
  Radio,
  Check,
  Open,
  Sort,
  Match,
  Video,
  HTML: Html,
  Numerical,
  Poll,
  Wordcloud
};

/** ************************************************** */
/*   Return array of alle pages as react components  */
/*   which undergone parsing (including widgets)     */
/** ************************************************** */
export function buildLessonPages(lessonPlay) {
  const { store } = lessonPlay;
  const isDevEnv = process.env.NODE_ENV === 'development';
  const canEdit = store.canEditCourse;

  let countQuestions = 0;
  let questionsAnswered = 0;
  let widgetCodes = [];

  return lessonPlay.pages.map((page, index) => {
    // For all pages
    // Parse the HTML
    const parsedPage = parsePage(page);
    // For each page find and replace all widget-references with real HTML and instantiate / cache the react component
    countQuestions = 0;
    questionsAnswered = 0;
    widgetCodes = [];
    const html = parsedPage
      .replace(/\[\[([^[\]]*?)\]\]/gm, (match, widgetCode) => createWidget(match, widgetCode))
      // Remove styling odt tags
      .replaceAll(/\[#[a-zA-Z-]*#\]/g, '')
      // Replace all relative urls to absolute (/or relative) urls based on the
      // base path set in the root store
      .replaceAll(/src="/g, `src="${lessonPlay.store.PATH || process.env.REACT_APP_JSONAPI}/`);

    return {
      // eslint-disable-next-line react/no-danger
      html: <div dangerouslySetInnerHTML={{ __html: html }} key={`page-${index}`} />,
      countQuestions,
      questionsAnswered,
      widgetCodes
    };
  });

  function createWidget(match, widgetCode) {
    if (!lessonPlay.question_widgets) return null;
    const widget = lessonPlay.question_widgets.find((_widget) => _widget.attributes.widget_code.toLowerCase() === widgetCode.toLowerCase());
    // widget not found: return placeholder for widget
    const showMissingWidget = isDevEnv ? `<div>missing widget: ${widgetCode}</div>` : `<div></div>`;

    // Show greyout without feedback
    const showFeedback1 = lessonPlay?.lesson?.attributes['1answer_nofeedback'] || false;
    // Show color without feedback
    const showFeedback2 = lessonPlay?.lesson?.attributes['1answer_visualfeedback'] || false;

    if (!widget) {
      if (!canEdit) return showMissingWidget;
      return `<div class='EwWidgetPlaceholder' id='${widgetCode}'></div>`;
    }

    // widget component undefined
    if (components[widget.widgetType] === undefined) {
      return null;
    }

    // Store the widget codes
    widgetCodes.push(widgetCode);
    // register the number of answered questions on page
    if (widget.isQuestion) countQuestions++;
    if (lessonPlay.lesson_result.attributes.questions) {
      const questionResult = lessonPlay.lesson_result.attributes.questions.find((question) => question.questionId === widget.id);

      const maxAttempts =
        showFeedback1 || showFeedback2 || (widget.attributes.widget_type === 'Radio Question' && widget.attributes.options.length === 2)
          ? 1
          : 2;

      if (questionResult && (questionResult.tries >= maxAttempts || questionResult.last_correct)) {
        questionsAnswered++;
      }

      log.debug(`Widget code is: ${widgetCode}`);
    }

    // Create instantiated component
    const component = createElement(components[widget.widgetType], {
      widget,
      widgetCode,
      store: lessonPlay.store,
      active: true,
      feedback1: showFeedback1,
      feedback2: showFeedback2
    });

    // Cache the instantiated component with the widget to be reused when building the page (in Pages.js)
    widget.setComponent(component);

    return `<div class='EwWidget Ew${widget.widgetType}' id='${widgetCode}'></div>`;
  }
}

/** ************************************************************************** */
/*                      Parse html in the page                               */
/** ************************************************************************** */
const parser = new DOMParser();
function parsePage(page) {
  const doc = parser.parseFromString(page, 'text/html');

  // wraps multiple 'Kader'-paragraphs
  doc.querySelectorAll('.cmebox').forEach((el) => {
    const classes = el.getAttribute('class').split(' ');
    let classname = classes.find((elClass) => elClass.indexOf('cmebox-') === 0);
    classname = classname ? classname.match(/^cmebox-(.+)/i)[1] : '';
    wrap(el, `<div class="CMEKaderWrap CMEBoxWrap CMEBoxWrap${capitalize(classname.toLowerCase())}"></div>`);
  });

  // Put widget heading before widget tag
  doc.querySelectorAll('.CMEVraagkop, .text-question-header').forEach((vraagkop) => {
    if (!vraagkop.nextElementSibling) return;

    const elements = getTillcmeVariable(vraagkop);
    const cmeVariable = elements.pop();
    elements.reverse().forEach((el) => {
      cmeVariable?.insertAdjacentElement('afterbegin', el);
    });
  });

  // Collapsable sections
  doc.querySelectorAll('section[id^="collapsed"]').forEach((section) => {
    section.style.display = 'none'; // Reset the default display:none
    const buttonClassList = ['code-function-button', 'CMECollapsed-Buttontekst'];
    const courseListElement = section.querySelector('.courseList');
    const id = section.getAttribute('id');
    let button;

    // eslint-disable-next-line no-restricted-syntax
    for (const className of buttonClassList) {
      button = section.querySelector(`.${className}`);
      if (button) break;
      // If not found at the immediate child level, search within descendants
      button = section.querySelector(`.${className}`);
      if (button) break;
    }
    // Move the button before the courseList
    if (button && courseListElement && button.parentNode === section && courseListElement.parentNode === section) {
      section.insertBefore(button, courseListElement);
    }
    if (button) {
      button.firstElementChild.setAttribute('data-btext', button.firstElementChild.innerText);
      button.firstElementChild.classList.add('button-text-wrapper');
    } else {
      button = htmlToDom(`
        <div class="ewl CMECollapsed-Buttontekst">
          <span class="button-text-wrapper" data-btext="${window.i18next.t('lesson.open')}">
            ${window.i18next.t('lesson.open')}
          </span>
        </div>
      `);
    }
    button.setAttribute('id', `button-${id}`);
    button.setAttribute('onClick', `ewPage.collapse('${id}')`);
    section.insertAdjacentElement('afterend', button);
  });

  // Tabelcel highlight/header/subheader - move classname from span to table-cell
  // Part 1: Styling Manipulation
  const cellSelector =
    'td, td .CMETabHighlight, td .table-cell-highlight, td .CMETableHeader, td .table-cell-header, td .CMETableSubHeader, td .table-cell-header-sub, td .CMETableFooter, td .table-cell-footer';
  doc.querySelectorAll(cellSelector).forEach((el) => {
    const { className } = el;
    el.classList.remove(...el.classList); // Clear all existing classes
    const parentTd = el.closest('td');
    className.split(' ').forEach((cls) => {
      if (cls) {
        parentTd.classList.add(cls);
      }
    });
  });

  // Part 2: Table Cell Styling Based on Content
  const tdSelector = '//td';
  const tdElements = doc.evaluate(tdSelector, doc, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
  const cellClassMap = [];

  for (let i = 0; i < tdElements.snapshotLength; i++) {
    const tdElement = tdElements.snapshotItem(i);
    // Find all spans within the current td
    const spans = tdElement.querySelectorAll('span');
    const tagContent = Array.from(spans)
      .map((span) => span.innerText)
      .join('');
    const regexMatch = tagContent.match(/\[#(.*?)#\]/);
    const tag = regexMatch && regexMatch[1];
    if (tag !== null && tag !== undefined) {
      const classLabels = tag.split(' ').filter((text) => text !== '');
      spans.forEach((span) => {
        const classText = span.innerHTML;
        span.innerHTML = classText.replace(/\[#[^\]]*#\]/, '');
      });

      classLabels.forEach((classLabel) => {
        cellClassMap.push({ cell: tdElement, classLabel });
      });
    }
  }
  // Apply the modifications to the DOM after processing all elements
  cellClassMap.forEach(({ cell, classLabel }) => {
    const updatedClassLabel = classLabel.replace(/[,\s]/g, '');
    cell.classList.add(updatedClassLabel);
  });

  // right align numbers, underline options for total counts
  doc.querySelectorAll('.ew-tablestyle-align-numbers td, .align-numbers td').forEach((el) => {
    let str = el.innerText.trim();
    if (str === '') return;
    if (str.match(/^\*\*\*(.+)\*\*\*$/m) !== null) {
      el.innerHTML = el.innerHTML.replace(/\*\*\*/g, '');
      el.classList.add('text-align-right');
    }
    if (str.match(/^___(.+)___$/m) !== null) {
      el.innerHTML = el.innerHTML.replace(/___/g, '');
      el.classList.add('ew-underline');
    }
    if (str.match(/^===(.+)===$/m) !== null) {
      el.innerHTML = el.innerHTML.replace(/===/g, '');
      el.classList.add('ew-double-underline');
    }
    str = str.replace(/([0-9,_=+$%*/.()\xA3\xA4\xA5\xB1\xB3\xB2\u20AC\u0192\u2030\-\s]|euro?|nlg|usd)+/gim, ''); // remove all numerical values (like isNaN(content))
    if (str === '') {
      el.classList.add('text-align-right');
    }
  });

  // Open full http(s) url and links to documents in new browser tab
  doc.querySelectorAll('a[href]').forEach((el) => {
    const url = el.getAttribute('href');
    if (url.match(/^https?:|\.pdf$|\.png$|\.pptx?$|\.key$|\.docx?$|\.xlsx?$|\.gif$|\.jpg$|\.jpeg$/i)) el.setAttribute('target', '_blank');
  });

  // Add functionality to footnotelinks
  doc.querySelectorAll('.footnotelink').forEach((el) => {
    const id = el.getAttribute('id').split('_')[1];
    el.classList.add('tooltipTrigger');
    el.setAttribute('onmouseover', `ewPage.showFootnote('${id}', this)`);
    const footnotebody = doc.getElementById(`footnotebody_${id}`);
    footnotebody.setAttribute('onmouseleave', `ewPage.hideFootnote('${id}')`);
  });

  // Move footnotebodies to upper level
  doc.querySelectorAll('.footnotebody').forEach((el) => el && doc.body.appendChild(el));

  // Done parsing
  return `<div class='lesson'>${doc.body.innerHTML}</div>`;
}

/** ******************************************************************************************************************************* */
/* Make a global ewPage with functions so all javascript within the HTML can access these functions (currently only one function) */
/** ******************************************************************************************************************************* */
window.ewPage = {
  // Collapse and open a section
  // TODO: Langzamere animatie
  // TODO: na verbergen moeten de focus van de "tonen" button in beeld blijven. Niets doen als de button nog in beeld is, en anders bovenaan in beeld zetten
  collapse: (id) => {
    const el = document.getElementById(id);
    const buttonSpan = document.getElementById(`button-${id}`).firstElementChild;
    if (el.classList.toggle('collapsed')) {
      el.style.display = '';
      el.style.maxHeight = `${el.scrollHeight}px`;
      buttonSpan.innerText = window.i18next.t('lesson.close');
    } else {
      el.style.maxHeight = null;
      setTimeout(() => {
        el.style.display = 'none';
        buttonSpan.innerText = buttonSpan.getAttribute('data-btext');
      }, 250);
    }
  },
  showFootnote: (id, footnotelink) => {
    const footnotebody = document.getElementById(`footnotebody_${id}`);
    const { scrollTop, pageYOffset } = window.store.lessonPlay.pageNo
      ? document.getElementById('pages').children[1]
      : document.getElementById('pages'); // if pageNo is set, we are in the lesson, otherwise we are in the overview
    const { offsetWidth: drawerWidth } = document.getElementById('leftDrawer');
    const { top, left } = footnotelink.getBoundingClientRect();
    const resumebarHeight = document.querySelector('[data-test="resumeBar"]') ? 34 : 0;
    const newStyle = {
      top: `${top + (pageYOffset || scrollTop) - 60 - resumebarHeight}px`,
      left: `${left - drawerWidth}px`,
      visibility: 'visible'
    };
    Object.assign(footnotebody.style, newStyle);
  },
  hideFootnote: (id) => {
    const footnotebody = document.getElementById(`footnotebody_${id}`);
    footnotebody.style.visibility = 'hidden';
  }
  // *** //
};

// Recursive function to get all the elements from cmeVraagkop till cmeVariable
const getTillcmeVariable = (start, accEls = []) =>
  !start?.nextElementSibling?.firstElementChild || // done if nextElementSibling doesn't exist
  start?.nextElementSibling?.firstElementChild?.classList?.contains('cmeVariable') || // done if next sibling is Variable
  accEls.length > 10 // done if the accumulated elements get to big
    ? accEls.concat([start, start?.nextElementSibling?.firstElementChild]) // cmeVariable will be last element so we can pop
    : getTillcmeVariable(start?.nextElementSibling, accEls.concat([start])); // accumulate current element and try next sibling

// DOM util function
function wrap(el, wrapper) {
  wrapper = htmlToDom(wrapper);
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

// util function
function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

// DOM util function
function htmlToDom(html) {
  if (typeof html === 'string') {
    const template = document.createElement('template');
    template.innerHTML = html.trim();
    return template.content ? template.content.firstChild : template.firstChild; // Difference ie11 & chrome
  }
  return html;
}
