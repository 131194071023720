import React from 'react';
import PropTypes from 'prop-types';
// import { getSnapshot, destroy } from 'mobx-state-tree';
// import { RootStore } from './RootStore';
// import { routes } from './Routes';

// if (!window.store) {
//   const store = localStorage.getItem('store') // Only present during Cypress testing for ommitting logins
//     ? JSON.parse(localStorage.getItem('store'))
//     : {};
//   window.store = RootStore.create(store, { routes });
// } else {
//
//   const tmpStore = window.store;
//   const rootStoreSnapshot = getSnapshot(window.store);
//   window.store = RootStore.create(rootStoreSnapshot, { routes });
//   window.setTimeout(() => {
//     destroy(tmpStore); // Before removing the old store we need to wait until the new store is rendered in the browser window
//   }, 2000);
// }

export const startupStore = window.store; // Only used by App.js to give access to the rootstore
export const Store = React.createContext(); // All other modules use this
export const StoreProvider = (props) => {
  const { children, store } = props;
  // eslint-disable-next-line react/jsx-filename-extension
  return <Store.Provider value={store}>{children}</Store.Provider>;
};

StoreProvider.propTypes = {
  children: PropTypes.object.isRequired,
  store: PropTypes.object
};

// Below is some code to use it as a Redux time machine (I never really needed it)
/*
  try {
    if (snapshotListener) snapshotListener();  // Dispose previous listener (if exists)
    Use window.store to make access easy in console
    
    connect devtools (o.a. time machine)
    connectReduxDevtools(require("remotedev"), window.store); // connect devtools (o.a. time machine)
    connectReduxDevtools(require("remotedev"), store, {logIdempotentActionSteps: false, logChildActions: false}); // connect devtools (o.a. time machine)

    Optionally during development: Create snapshot to localstorage after each (or certain) state change
    snapshotListener = onSnapshot(store, snapshot => {
      
      localStorage.setItem('store', JSON.stringify(snapshot));
    });
  } catch(e) {
    
  }
*/
