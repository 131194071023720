/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/* MUI */
import { Box, Dialog, DialogTitle, Chip } from '@mui/material';
/* Custom  components */
import NewCourseForm from './form/NewCourseForm';
/*
  In summary, the NewCourseDialog component is a user interface element that presents a dialog for adding a new course in an administrative or dashboard context. 
  It includes a title, likely indicating the package name, and a form for inputting course details. 
  It is built to be accessible and may be part of a larger application that includes translation support. 
  The dialog is displayed when open is true and is controlled by the handleNewCourseModal callback.
*/
const NewCourseDialog = (props) => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');

  const { open, handleNewCourseModal } = props;

  const radioGroupRef = useRef(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { borderRadius: '15px' } }}
      open={open}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionProps={{ onEntering: handleEntering }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px' }}>
        <Box>{`${t('dashboard.addCourse')}`}</Box>
        <Chip color="primary" label={store.params.package} />
      </DialogTitle>
      <NewCourseForm handleNewCourseModal={handleNewCourseModal} />
    </Dialog>
  );
};

NewCourseDialog.propTypes = {
  open: PropTypes.bool,
  handleNewCourseModal: PropTypes.func
};

export default observer(NewCourseDialog);
