import { observer } from 'mobx-react';
import { Box } from '@mui/material';

const CourseSearchBar = () => (
  <Box sx={{ flexGrow: 1 }} className="draft-view admin-custom-page">
    coming soon
  </Box>
);

export default observer(CourseSearchBar);
