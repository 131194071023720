export const convertBlobString2File = (blobString) => {
  //
  const d = blobString;
  const l = d.length;
  const array = new Uint8Array(l);
  for (let i = 0; i < l; i++) {
    array[i] = d.charCodeAt(i);
  }
  const b = new Blob([array], { type: 'image/jpeg' });
  //
  const image = new Image();
  image.src = URL.createObjectURL(b);
  return image.src;
};
