import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import isEqual from 'lodash/isEqual';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { rawHTML } from 'helpers/Helpers';
import ComponentProps from 'helpers/ComponentProps';

/*
In summary, the Sort component allows users to reorder a list of items by dragging and dropping them. 
It provides visual cues for different item orders and is integrated with the react-beautiful-dnd library to manage drag-and-drop interactions. 
It doesn't include language-specific strings, indicating that it can be used in various contexts and applications.
*/
const bg = {
  default: '#3597ff',
  wrong: 'darkred',
  correct: 'darkgreen'
};

const Sort = (props) => {
  const { items, handleInput, handleTouched, disabled, store } = props;
  const { t } = useTranslation('widget');
  const canSeeAnswers = store?.canSeeAnswers;
  const dontShowBg = window.store?.getFeedback1() || false;
  const style = ComponentProps();
  let randomItems = items;

  const isCorrectAnswer = (newOrder) => {
    const answer = newOrder.map((a) => a.value);
    handleTouched(true);
    handleInput(answer);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    randomItems = reorder(randomItems, result.source.index, result.destination.index);
    isCorrectAnswer(randomItems);
  }
  return (
    <div label="widget-question" style={{ width: '100%' }}>
      <Typography fontWeight={700}>{t('sortItem')}</Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list" isDropDisabled={disabled}>
          {(providedDroppable) => (
            <div ref={providedDroppable.innerRef} {...providedDroppable.droppableProps}>
              {randomItems &&
                randomItems.length > 0 &&
                randomItems.map((option, index) => (
                  <Draggable key={`option-${index}-${option.value}`} draggableId={option.value} index={index} isDragDisabled={disabled}>
                    {(providedDraggable) => (
                      <div ref={providedDraggable.innerRef} {...providedDraggable.draggableProps} {...providedDraggable.dragHandleProps}>
                        <section label="ARRAY-ITEM" key={index}>
                          <Paper
                            style={{
                              background: dontShowBg ? bg.default : bg[option.order],
                              color: 'white',
                              fontWeight: 'bold',
                              display: 'block',
                              width: '100%',
                              marginBottom: '15px'
                            }}
                            key={index}
                            {...style.pprFMb}
                          >
                            <Grid key={index} container spacing={1}>
                              <Grid item md={1}>
                                {/* <div>{option.value}</div> */}
                              </Grid>
                              <Grid item md={10}>
                                <Box
                                  sx={{
                                    '& p:first-child': { marginTop: 0 },
                                    '& p:last-child': { marginBottom: 0 }
                                  }}
                                >
                                  {rawHTML(canSeeAnswers ? `${option.label}     (${option.value})` : option.label)}
                                </Box>
                              </Grid>
                            </Grid>
                          </Paper>
                        </section>
                      </div>
                    )}
                  </Draggable>
                ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

Sort.propTypes = {
  onChange: PropTypes.func,
  handleInput: PropTypes.func,
  handleTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  items: PropTypes.array,
  setValues: PropTypes.func,
  touched: PropTypes.bool,
  disabled: PropTypes.bool,
  store: PropTypes.any
};

export default observer(Sort);
