import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

const BottomDescription = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        paddingBlock: { xs: '0.625rem', md: '0.875rem' },
        paddingInline: { xs: '1rem', md: '1.5rem' },
        backgroundColor: '#ffffff',
        borderRadius: { xs: '1rem', md: '0.75rem' }
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: '0.875rem', md: '1.125rem' },
          lineHeight: { xs: '1.25rem', md: '1.75rem' },
          fontWeight: '700',
          color: 'rgb(43, 54, 116)'
        }}
      >
        {t('vtb.introTitle')}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: '0.75rem', md: '1rem' },
          lineHeight: { xs: '1rem', md: '1.5rem' }
        }}
      >
        <Trans t={t} i18nKey="vtb.introDescription">
          On this page you can register for the technical meetings. To do this, click on a date in the table below and then click “Save
          changes“. You can adjust your registration up to a week before the meeting by clicking out the registered dates (unsubscribe) or
          clicking on another date. There are four meetings each year that you can register for. <br /> <br />
          You can view the information about the meeting by clicking on the relevant quarter. You can view more information about the
          location of the meeting by place name. <br /> <br /> If you have any questions about your registration, please contact us at:
        </Trans>
        <a
          href="mailto:bijeenkomsten@pe-academy.nl"
          title="bijeenkomsten@pe-academy.nl"
          target="_blank"
          rel="noreferrer"
          style={{ color: theme.palette.primary.main }}
        >
          bijeenkomsten@pe-academy.nl
        </a>
        <Trans t={t} i18nKey="vtb.introDescription2">
          <br /> <br /> or call 030 - 26 44 100.
        </Trans>
      </Typography>
    </Box>
  );
};

export default observer(BottomDescription);
