import StarsIcon from '@mui/icons-material/Stars';
import { Chip, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const TotalPointsChip = ({ points, title }) => (
  <Tooltip title={title} arrow>
    <Chip
      className="onboarding-points"
      sx={{
        background: '#F4F7FE',
        [`.MuiChip-label`]: { width: '100%', paddingLeft: '8px', paddingRight: '8px' }
      }}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              background: 'white',
              borderRadius: '49px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '21px',
              height: '21px'
            }}
          >
            <StarsIcon sx={{ width: '13px', height: '13px' }} color="primary" />
          </div>
          <div
            style={{
              color: '#2B3674',
              fontWeight: '700',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: ' hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {points}
          </div>
        </div>
      }
    />
  </Tooltip>
);
TotalPointsChip.propTypes = {
  points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired
};

export default TotalPointsChip;
