import { ThemeBrandTitle } from 'helpers/Helpers';
import { createEditStore } from 'state/RoutesActions';
import { getSpecialAccessMenu, setTab, showToastRouteError, getProfile, logError } from 'state/RoutesActionsAdminCourse';
import { createRouterState } from 'mobx-state-router';

export const editCourseViewRoute = {
  name: 'editCourse',
  pattern: '/admin/:package/:packageNid/:containerNid/:courseuuid/edit',
  title: `Edit| ${ThemeBrandTitle()}`,
  meta: { title: `Edit | ${ThemeBrandTitle()}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    //
    getProfile(fromState, toState, routerStore);
    createEditStore(fromState, toState, routerStore);
    //
    try {
      await store.courseEdit.fetchCourseContainer(toState.params.containerNid);
      await store.courseEdit.fetchCourseEditData(toState.params.courseuuid);
      await getSpecialAccessMenu(fromState, toState, routerStore);
    } catch (error) {
      logError(error);
      showToastRouteError(fromState, toState, routerStore);
      throw error;
    }
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    store.courseEdit.setStoreValue('drawerOpen', false);
    setTab(fromState, toState, routerStore);
  }
};

export const defEditCourseViewRoute = {
  name: 'defEditCourse',
  pattern: '/admin/:def/:package/:packageNid/:containerNid/:courseuuid/edit',
  title: `Edit| ${ThemeBrandTitle()}`,
  beforeEnter: async (fromState, toState, routerStore) => {
    createEditStore(fromState, toState, routerStore);
    const { store } = routerStore.options;
    //
    const responds = await store.courseEdit.lmsApi.fetchAdminMenu(store.label);
    const menuItem = responds.data.packages.find((item) => item.package_id.toString() === toState.params.packageNid);
    return createRouterState('editCourse', {
      params: {
        package: menuItem.package_title,
        packageNid: toState.params.packageNid,
        containerNid: menuItem.course_container_id.toString(),
        courseuuid: toState.params.courseuuid
      },
      queryParams: { tab: 'Lessons' }
    });
  }
};
