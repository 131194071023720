import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useState, useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, LinearProgress, Slide } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

/*
This component is responsible for allowing users to choose the action they want to perform on a course and then confirming and executing that action. 
It provides a user-friendly interface for reusing or duplicating courses within the application.
*/
const CopyCourseDialog = ({ open, toggleCopyDialog }) => {
  const store = useContext(Store);
  const { courseEdit } = store;
  const { t } = useTranslation('adminCourses');
  //
  const [value, setValue] = useState(1);
  const [formats, setFormats] = useState([1]);
  const [saving, setSaving] = useState(false);
  //
  if (!open) return <></>;
  // toast messages
  const toast = {
    copySuccess: { title: t('saveResponse.success.title'), content: t('saveResponse.success.message'), type: 'success' },
    duplSuccess: { title: t('saveResponse.success.title'), content: t('saveResponse.success.message'), type: 'success' },
    copyError: { title: t('saveResponse.error.title'), content: t('saveResponse.error.message'), type: 'error' },
    duplError: { title: t('saveResponse.error.title'), content: t('saveResponse.error.message'), type: 'error' },
    default: { timeOut: 6000, variant: 'filled' }
  };

  const handleFormat = (event, newFormats) => setFormats(newFormats);

  const handleChange = (event, newValue) => {
    const val = +newValue;
    if (val === 1) setFormats([1]);
    if (val === 2) setFormats([1, 2, 3]);
    setValue(val);
  };

  const handleCloseDialog = () => toggleCopyDialog({});

  const handleSubmitAction = async () => {
    setSaving(true);
    if (value === 1) {
      try {
        await store.courseEdit.reuseCourseInContainer(courseEdit.setCopyCourse.course_uuid);
        store.courseEdit.setToasts({ ...toast.copySuccess, ...toast.default });
        setSaving(false);
        setFormats([1]);
        toggleCopyDialog();
      } catch (error) {
        store.courseEdit.setToasts({ ...toast.copyError, ...toast.default });
        setSaving(false);
      }
    }
    if (value === 2) {
      try {
        await store.courseEdit.duplicateCourseInContainer(courseEdit.setCopyCourse.course_uuid);
        store.courseEdit.setToasts({ ...toast.duplSuccess, ...toast.default });
        setSaving(false);
        setFormats([1]);
        toggleCopyDialog();
      } catch (error) {
        store.courseEdit.setToasts({ ...toast.duplError, ...toast.default });
        setSaving(false);
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} TransitionComponent={Transition}>
      <Box sx={{ width: '100%' }}>
        <DialogTitle sx={{ fontSize: '20px' }}>{courseEdit.setCopyCourse.course_title || ''}</DialogTitle>
        <IconButton aria-label="close" onClick={handleCloseDialog} sx={sxDialog.iconBtn}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <DialogContentText>{t('copyCourseDialog.actionTitle')}</DialogContentText>
        <Box sx={{ width: '100%' }}>
          <FormControl>
            <RadioGroup aria-labelledby="btn-group-action" name="btnGroupAction" value={value} onChange={handleChange}>
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={`${t('copyCourseDialog.radioReuse.action')}: ${t('copyCourseDialog.radioReuse.info')}`}
              />
              <FormControlLabel
                value={2}
                control={<Radio color="warning" />}
                label={`${t('copyCourseDialog.radioDuplicate.action')}: ${t('copyCourseDialog.radioDuplicate.info')}`}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ paddingTop: '15px' }}>
          <ToggleButtonGroup
            sx={sxDialog.toggleBtnGroup2}
            size="small"
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
          >
            <ToggleButton value={1} aria-label="1" disabled>
              {t('copyCourseDialog.course')}
            </ToggleButton>
            <ToggleButton value={2} aria-label="2" disabled>
              {t('copyCourseDialog.lessonContainer')}
            </ToggleButton>
            <ToggleButton value={3} aria-label="3" disabled>
              {t('copyCourseDialog.lesson')}
            </ToggleButton>
            <ToggleButton value={3} aria-label="4" disabled>
              {t('copyCourseDialog.lessonContent')}
            </ToggleButton>
            <ToggleButton value={3} aria-label="5" disabled>
              {t('copyCourseDialog.questionWidgets')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </DialogContent>
      <DialogActions sx={sxDialog.dialogActions}>
        <Box sx={sxDialog.dialogActionsBox}>{saving && <LinearProgress />}</Box>
        <Button sx={{ color: 'primary.light' }} onClick={handleCloseDialog} variant="text" startIcon={<CloseIcon />} disableElevation>
          {t('cancel')}
        </Button>
        <LoadingButton onClick={handleSubmitAction} endIcon={<SaveIcon />} loading={saving} loadingPosition="end" variant="contained">
          {t('save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

CopyCourseDialog.propTypes = {
  open: PropTypes.bool,
  toggleCopyDialog: PropTypes.func
};
export default observer(CopyCourseDialog);

const sxDialog = {
  iconBtn: { position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] },
  toggleBtnGroup: { display: 'flex', '.MuiToggleButton-root': { flexBasis: '100%' } },
  toggleBtnGroup2: { display: 'flex', '.MuiToggleButton-root': { flexBasis: '100%' } },
  dialogActions: { justifyContent: 'space-between', position: 'relative' },
  dialogActionsBox: { width: '100%', height: '4px', position: 'absolute', top: '0px', left: '0px' }
};
