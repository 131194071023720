import { Box } from '@mui/material';
import MainLayout from '../mainLayout/MainLayout';
import { AssistantManagementView } from './AssistantManagementView/AssistantManagementView';

export const assistantManagementViewMap = {
  assistantManagement: (
    <MainLayout title="assistant.title" subtitle="">
      <Box
        sx={{
          padding: { xs: '1rem', md: '2rem' }
        }}
      >
        <AssistantManagementView />
      </Box>
    </MainLayout>
  )
};
