import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { rawHTML } from 'helpers/Helpers';

// renders raw html
const Html = (props) => {
  const { data } = props;

  if (data) {
    return (
      <section width="100%" height="100%">
        {rawHTML(data)}
      </section>
    );
  }

  return <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>Nothing 2 C here</span>;
};

Html.propTypes = {
  data: PropTypes.string
};

export default observer(Html);
