import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import MaterialTable from '@material-table/core';
import { Grid, Box, Paper } from '@mui/material';
// hook
import { buildQuerySearch, BuildQueryFilters } from 'pages/adminCourses/helpers/jsonApiRemoteFilter.helper';
import { feedbackTableOptions } from '../EditCourse.helper';
import TableTotal from './TableTotal';
import FeedbackTableDetail from './FeedbackTableDetail';

/**
 * Tab Feedback courses
 * shows evaluation data as datatable
 */

const mapFields = {
  mail: 'uid.mail',
  name: 'uid.name',
  created: 'created',
  evaluation_question_1: 'evaluation_question_1',
  evaluation_question_2: 'evaluation_question_2',
  evaluation_question_3: 'evaluation_question_3',
  evaluation_question_4: 'evaluation_question_4',
  evaluation_question_1b: 'evaluation_question_1b'
};
// email, name,
const orderByCollection = [{ orderBy: 1, orderDirection: 'asc', sortOrder: 1 }];

const CourseTabFeedback = () => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm');

  const courseId = store.courseEdit.currentCourse.attributes.drupal_internal__nid;
  const defaultFilter = `filter[course.meta.drupal_internal__target_id][value]=${courseId}`;

  // Fetching remote table data
  const getData = async (query) => {
    const { page, filters, search, pageSize } = query;
    // create query params
    const querySearch = buildQuerySearch(search);
    const queryFilters = BuildQueryFilters(filters, store.login.uuid);
    const paging = `&page[limit]=${pageSize}&page[offset]=${page * pageSize}`;
    const includes = '&include=uid';
    //
    const orderBy = query.orderByCollection.find((item) => item.sortOrder === 1);
    const sort = `&sort=${orderBy.orderDirection === 'asc' ? '-' : ''}${mapFields[orderBy.orderByField]}`;
    const filterStart = search ? `&${querySearch}&${queryFilters}` : `&${queryFilters}`;
    //
    const queryParams = defaultFilter + filterStart + sort + includes + paging;
    // fetch data
    const { data: result } = await store.lmsApi.fetchIt('evaluation', queryParams);
    const { data, included } = result;
    store.courseEdit.setStoreValue('tableTotal', result.meta.count);

    const tableData = data.map((item) => {
      const { attributes, relationships } = item;
      const { evaluation_question_1b: q1b, evaluation_question_2b: q2b, evaluation_remarks_1: r1, evaluation_remarks_2: r2 } = attributes;
      const { uid } = relationships;
      const user = included.find((i) => i.id === uid.data.id);
      const { name, mail } = user.attributes;
      const hasComments = q1b || q2b || r1 || r2 ? t('tabFeedback.commentTrue') : t('tabFeedback.commentFalse');
      return {
        id: item.attributes.drupal_internal__nid,
        ...attributes,
        hasComments,
        name,
        mail
      };
    });

    return {
      data: tableData,
      page,
      totalCount: result.meta.count
    };
  };

  const tableOptions = { ...feedbackTableOptions, ...{ defaultOrderByCollection: orderByCollection } };

  // const hasSlpo = tableData.some((item) => item.slpo1);
  // const hasSlpo = tableData?.[0]?.slpo1;
  const hasSlpo = false;

  const tableColumnsDefault = [
    { title: 'Email', field: 'mail', operator: 'CONTAINS', relationPath: 'uid.mail' },
    { title: t('tabFeedback.studentName'), field: 'name', operator: 'CONTAINS', relationPath: 'uid.name' },
    { title: t('tabFeedback.quality'), field: 'evaluation_question_1', operator: 'CONTAINS', cellStyle: { width: '100px!important' } },
    { title: t('tabFeedback.applicable'), field: 'evaluation_question_2', operator: 'CONTAINS', cellStyle: { width: '130px!important' } },
    { title: t('tabFeedback.timeSpend'), field: 'evaluation_question_4', operator: 'CONTAINS', cellStyle: { width: '150px!important' } }
  ];
  const tableColumnsSlpo = [
    { title: t('tabFeedback.contents'), field: 'slpo1', operator: 'CONTAINS' },
    { title: t('tabFeedback.teacher'), field: 'slpo2', operator: 'CONTAINS' },
    { title: t('tabFeedback.levelCourse'), field: 'slpo3', operator: 'CONTAINS' },
    { title: t('tabFeedback.levelTeacher'), field: 'slpo4', operator: 'CONTAINS' }
  ];
  const tableColumnsEnd = [
    { title: t('tabFeedback.comments'), field: 'hasComments', sorting: false, filtering: false, cellStyle: { width: '100px!important' } },
    { title: 'score', field: 'evaluation_question_3', operator: 'CONTAINS', specialOp: true, cellStyle: { width: '50px!important' } },
    {
      title: 'created',
      field: 'created',
      cellStyle: { width: '100px!important' },
      filtering: false,
      render: (rowData) => dayjs(rowData.created).toJSON().slice(0, 10).split('-').reverse().join('-')
    }
  ];

  const tableColumns = hasSlpo
    ? [...tableColumnsDefault, ...tableColumnsSlpo, ...tableColumnsEnd]
    : [...tableColumnsDefault, ...tableColumnsEnd];

  return (
    <Box className="box-left" sx={{ flexGrow: 1 }}>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <TableTotal />
        </Grid>
        <Grid item sx={sxTableCourseUsersInprogress}>
          <Box sx={sxTableFeedback}>
            <MaterialTable
              data={getData}
              options={tableOptions}
              columns={tableColumns}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />
              }}
              localization={{ body: { emptyDataSourceMessage: 'No feedback found' } }}
              onRowClick={(_event, _rowData, togglePanel) => togglePanel()}
              detailPanel={(rowData) => <FeedbackTableDetail rowData={rowData} />}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(CourseTabFeedback);

const sxTableCourseUsersInprogress = {
  margin: '0px',
  'tbody td': { padding: '8px' },
  'tbody tr.MuiTableRow-root:hover': { background: '#c0c0c04d' }
};

const sxTableFeedback = {
  width: '100%',
  'thead tr th:nth-of-type(1)': { width: '50px!important' },
  'thead tr th:nth-of-type(4)': { maxWidth: '100px' },
  'thead tr th:nth-of-type(5)': { maxWidth: '100px' },
  'thead tr th:nth-of-type(6)': { maxWidth: '100px' },
  'thead tr th:nth-of-type(7)': { maxWidth: '100px' },
  'thead tr th:nth-of-type(8)': { maxWidth: '75px' },
  'thead tr th:nth-of-type(9)': { maxWidth: '100px' },
  'tbody tr:nth-of-type(odd)': { background: '#c0c0c01f' },
  'tbody tr: nth-of-type(1) .material-icons': { display: 'none' },
  'tbody td .MuiInputBase-root.MuiOutlinedInput-root': { paddingLeft: '4px' },
  'tbody td input.MuiInputBase-input': { padding: '6px 7px', marginLeft: '-12px' },
  'tbody td .MuiFormControl-root': { width: '100%' }
};
