import { createRouterState } from 'mobx-state-router';
import { checkUserSignedIn } from 'state/RoutesActions';
import { ThemeBrandTitle } from 'helpers/Helpers';

// route for the subscriptions page

export const subscriptionsRoute = {
  name: 'subscription',
  pattern: '/account/subscription',
  meta: { title: `%%account.subscription.title%% | ${ThemeBrandTitle()}` },
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { params } = toState;
    // Check if the user is LTI and trying to reach this page
    if (store.getLtiSession() === 1 || store.getLtiSession() === '1') {
      return createRouterState('errorPage');
    }
    // Check if the user is an Ewise user and has not selected a base product
    if (store.isEwiseUser && !store.hasPreferredBaseproduct) {
      return createRouterState('selectProduct', { params: { user: store.login.userName } });
    }
    try {
      if (store.hasSubscriptions === 'init') await checkUserSignedIn(fromState, toState, routerStore, params.product);
    } catch (e) {
      return e;
    }
    return null;
  }
  // beforeEnter: async (fromState, toState, routerStore) => prepareCourseView(fromState, toState, routerStore)
};
