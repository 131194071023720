import { types as t, getParent } from 'mobx-state-tree';
import { Profession, RelFile, RelUserRole, IsoDate } from './BaseModel';
import { RelCourses } from './Course';
import { RelProducts } from './Product';

export const User = t
  .model('User', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__uid: t.maybeNull(t.number),
      // langcode: t.maybeNull(t.string),
      preferred_langcode: t.string,
      timezone: t.string,
      field_display_name: t.maybeNull(t.string),
      field_first_name: t.maybeNull(t.string),
      // default_langcode: t.boolean,
      name: t.string,
      mail: t.string,
      access: t.maybeNull(t.string),
      display_name: t.string,
      field_no_registration_numbers: t.maybeNull(t.boolean),
      field_bignr: t.maybeNull(t.string),
      field_birthdate: t.maybeNull(IsoDate),
      field_company_name: t.maybeNull(t.string),
      field_gender: t.maybeNull(t.string),
      field_initials: t.maybeNull(t.string),
      field_kabiznr: t.maybeNull(t.string),
      field_kngfnr: t.maybeNull(t.string),
      field_krmnr: t.maybeNull(t.string),
      field_last_name: t.maybeNull(t.string),
      field_rbnr: t.maybeNull(t.string),
      login: t.maybeNull(IsoDate),
      field_accepted_gdpr: t.maybeNull(t.boolean),
      field_accountants_type: t.maybeNull(t.string),
      field_fysio_deelregister: t.maybeNull(t.array(t.string)),
      field_rechtsgebieden: t.array(t.maybeNull(t.string)),
      field_nirv_number: t.maybeNull(t.string),
      field_slpo_id: t.maybeNull(t.string),
      field_function: t.maybeNull(t.string),
      field_somn_member: t.maybeNull(t.boolean),
      field_vvn_nr: t.maybeNull(t.string)
    }),
    relationships: t.model({
      roles: t.maybe(RelUserRole),
      user_picture: t.maybeNull(RelFile),
      course_favorites: t.maybeNull(RelCourses),
      field_lms_groups: t.maybeNull(
        t.model({
          data: t.array(
            t.model({
              type: t.string,
              id: t.string
            })
          )
        })
      ),
      products: RelProducts,
      professions: t.model({
        data: t.array(
          t.model({
            id: t.reference(Profession),
            type: t.string
          })
        )
      })
    })
  })
  .volatile(() => ({
    settingSource: 'profile',
    updateCourseFavoritesState: t.optional(t.enumeration(['init', 'pending', 'done', 'error']), 'init')
  }))
  .views((self) => ({
    get products() {
      if (self.relationships.products.productrefs) {
        return self.relationships.products.productrefs;
      }
      return [];
    },

    get favoriteCourses() {
      if (self.relationships.course_favorites.courserefs) {
        return self.relationships.course_favorites.courserefs;
      }
      return [];
    },

    get lmsApi() {
      return getParent(self).lmsApi;
    }
  }))
  .actions((self) => ({
    /**
     * Set the API error state and message
     *
     * @param stateName
     * @param err
     * @param errPage
     */
    setApiErrorState(stateName = '', err = '', errPage = {}) {
      self.error = err;
      self[stateName] = 'error';

      if (self.error.status === 401 || self.error.status === 403) {
        self.gotoErrorPage(errPage);
      }
    },

    /**
     * Persist the current state of
     * course favorites to the backend
     *
     * @param courseFavorites
     * @returns {Promise<*>}
     */
    persistCourseFavorites(courseFavorites) {
      return self.lmsApi.APIPersistCourseFavorites(courseFavorites, self).catch((error) => {
        self.setApiErrorState('courseEditState', error, {
          title: 'Error loading course',
          message: 'No access',
          statuscode: self.error?.status.toString()
        });
      });
    },

    /**
     * Set the local state of course favorites
     *
     * @param courseFavorites
     * @returns {*}
     */
    setLocalCourseFavorites(courseFavorites) {
      self.relationships.course_favorites.data = courseFavorites;
      return self.relationships.course_favorites.data;
    },

    /**
     * Toggle a course as favorite
     *
     * @param courseId
     */
    toggleFavoriteCourse(courseId) {
      const courseFavoritesArray = Array.from(self.relationships.course_favorites.data.values());

      const isCurrentlyFavorited = courseFavoritesArray.findIndex((value) => value.id.id === courseId);

      if (isCurrentlyFavorited !== -1) {
        courseFavoritesArray.splice(isCurrentlyFavorited, 1);
      } else {
        courseFavoritesArray.push({
          id: courseId,
          type: 'course'
        });
      }

      return self.persistCourseFavorites(courseFavoritesArray).then(() => self.setLocalCourseFavorites(courseFavoritesArray));
    },

    setSettingsSource(type) {
      self.settingSource = type;
    }
  }));

export const RelUser = t.model('RelUser', {
  data: t.model({
    id: t.reference(User),
    type: t.string
  })
});
