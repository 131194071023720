import { useEffect } from 'react';

/*
In summary, the useIntersection hook encapsulates the setup and management of Intersection Observers for monitoring the visibility of DOM elements in the viewport. 
It provides a clean way to handle intersection events and callbacks for those elements within a React component.
*/
const listenerCallbacks = new WeakMap();

let observer;

function handleIntersections(entries) {
  entries.forEach((entry) => {
    if (listenerCallbacks.has(entry.target)) {
      const cb = listenerCallbacks.get(entry.target);

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb();
      }
    }
  });
}

function getIntersectionObserver() {
  if (observer === undefined) {
    observer = new IntersectionObserver(handleIntersections, {
      rootMargin: '100px',
      threshold: '0.15'
    });
  }
  return observer;
}

export default function useIntersection(elem, callback) {
  useEffect(() => {
    const target = elem.current;
    observer = getIntersectionObserver();
    listenerCallbacks.set(target, callback);
    observer.observe(target);

    return () => {
      listenerCallbacks.delete(target);
      observer.unobserve(target);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
