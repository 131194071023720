import MainLayout from 'pages/mainLayout/MainLayout';
import NotFound from '../NotFound';

const NotFoundPageViewMap = {
  notFound: (
    <MainLayout subtitle="notFoundPage.breadcrumbs.subTitle" title="notFoundPage.breadcrumbs.title">
      <NotFound />
    </MainLayout>
  )
};

export default NotFoundPageViewMap;
