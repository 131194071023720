import { Box, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import { CertificateIcon } from './CertificateIcon';
import { InformationIcon } from './InformationIcon';

export const EventTypeTitleHead = ({ eventType, onInfoClick }) => (
  <Box
    sx={{
      flex: '0 0 250px',
      backgroundColor: 'primary.main',
      color: 'primary.contrastText',
      paddingTop: 1,
      paddingBottom: 1,
      paddingLeft: 2,
      paddingRight: 2,
      borderRadius: '10px'
    }}
  >
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant="body1" sx={{ fontSize: 20, fontWeight: 800 }}>
        {eventType.attributes.name}
      </Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <a
          target="_blank"
          href={`https://learn.pe-academy.nl/to-course/${eventType.relationships.course.data.meta.drupal_internal__target_id}`}
          style={{ padding: 0, backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          rel="noreferrer"
        >
          <CertificateIcon />
        </a>
        <button
          type="button"
          style={{ padding: 0, backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          onClick={() => onInfoClick(eventType.attributes.event_description_node_id)}
        >
          <InformationIcon />
        </button>
      </Box>
    </Box>
    <Typography variant="body1" sx={{ textAlign: 'center', fontSize: 18, fontWeight: 200 }}>
      {eventType.attributes.subtitle}
    </Typography>
  </Box>
);

EventTypeTitleHead.propTypes = { eventType: PropTypes.any, onInfoClick: PropTypes.func };
