import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import { Stack, Button, Typography } from '@mui/material';

import { CustomTable } from 'components';
import { ConfirmationModal as DeleteActivityConfirmationModal } from '../../components';

const ActivitiesActions = ({ id, onDelete, onEdit }) => {
  const { t } = useTranslation('curriculum');

  return (
    <Stack
      sx={{
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem'
      }}
    >
      <Button
        sx={{
          width: '100%',
          backgroundColor: '#DBEAFE',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          textTransform: 'none',
          justifyContent: 'flex-start',
          background: 'rgb(219, 234, 254)',
          color: 'rgb(30, 58, 138)',
          '&:hover': {
            background: 'rgb(191, 219, 254)'
          }
        }}
        onClick={() => onEdit(id)}
      >
        <Icon icon="ant-design:edit-filled" />
        <Typography sx={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}>{t('general.editBtnLabel')}</Typography>
      </Button>
      <Button
        sx={{
          width: '100%',
          backgroundColor: '#DBEAFE',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          textTransform: 'none',
          justifyContent: 'flex-start',
          background: 'rgb(254, 226, 226)',
          color: 'rgb(127, 29, 29)',
          '&:hover': {
            background: 'rgb(254, 202, 202)'
          }
        }}
        onClick={() => onDelete(id)}
      >
        <Icon icon="fluent:delete-20-filled" />
        <Typography sx={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}>{t('general.deleteBtnLabel')}</Typography>
      </Button>
    </Stack>
  );
};

ActivitiesActions.propTypes = {
  id: PropTypes.any,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

const ActivitiesTable = ({ translationGroup, activities, columns, showActions, onEditCallback, onDeleteCallback }) => {
  const { t } = useTranslation('curriculum');

  const [selectedForDelete, setSelectedForDelete] = useState();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handleMultiDelete = (data, selected) => {
    onDeleteCallback(selected);
  };

  const onDelete = (id) => {
    setSelectedForDelete([id]);
    setIsDeleteModalVisible(true);
  };

  const onConfirmDelete = () => {
    onDeleteCallback(selectedForDelete);
    setIsDeleteModalVisible(false);
  };

  const onEdit = (id) => {
    onEditCallback(id);
  };

  const onRowClick = (e, id) => {
    e.preventDefault();
    onEditCallback(id);
  };

  return (
    <>
      <CustomTable
        tableSx={{
          px: 1,
          minWidth: undefined,
          '& .MuiTableCell-body': { color: 'rgb(43, 54, 116)' }
        }}
        rowsPerPage={25}
        tableName="activities"
        selectedButtons={['delete']}
        tableHeadData={columns}
        data={activities}
        setData={handleMultiDelete}
        handleRowClick={showActions ? onRowClick : null}
        checkboxes={showActions}
        moreInfoBtn={showActions}
        actions={showActions ? (props) => <ActivitiesActions {...props} /> : null}
        actionProps={showActions ? { onDelete, onEdit } : null}
      />

      <DeleteActivityConfirmationModal
        title={t(`${translationGroup}.confirmDeleteActivityTitle`)}
        text={t(`${translationGroup}.confirmDeleteActivityText`)}
        btnLabel={t('general.deleteBtnLabel')}
        isVisible={isDeleteModalVisible}
        onConfirm={onConfirmDelete}
        onCancel={() => setIsDeleteModalVisible(false)}
      />
    </>
  );
};

ActivitiesTable.propTypes = {
  translationGroup: PropTypes.oneOf(['functions', 'learningGoals']),
  activities: PropTypes.array,
  columns: PropTypes.array,
  onEditCallback: PropTypes.func,
  onDeleteCallback: PropTypes.func,
  showActions: PropTypes.bool
};

ActivitiesTable.defaultProps = {
  translationGroup: 'functions',
  activities: [],
  columns: [],
  onEditCallback: () => {},
  onDeleteCallback: () => {},
  showActions: true
};

export default observer(ActivitiesTable);
