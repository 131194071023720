import { useTheme } from '@mui/styles';
import { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListItem } from './ListItem';

export const List = ({ filteredAssistants, searchText, onAction }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const noResultPanel = useMemo(() => {
    if (filteredAssistants?.length !== 0) {
      return null;
    }

    const text = searchText && searchText !== '' ? `${t('assistant.table.noresults1')} ${searchText}` : t('assistant.table.noresults2');

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} sx={{ backgroundColor: 'grey.100', p: 4, textAlign: 'center', borderRadius: 2 }}>
          <Typography variant="body1" sx={{ weight: 900 }}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    );
  }, [filteredAssistants?.length, searchText, t]);

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 3,
        backgroundColor: 'white',
        mb: 2,
        mt: 2
      }}
    >
      <Grid
        container
        sx={{
          padding: 2,
          borderRadius: 3,
          mb: 2,
          mt: 2,
          display: { xs: 'none', sm: 'flex' }
        }}
      >
        <Grid item xs={12} sm={2} md={2}>
          <Typography variant="body2" sx={{ weight: 900 }}>
            {t('assistant.table.name')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography variant="body2" sx={{ weight: 900 }}>
            {t('assistant.table.email')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2} sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ weight: 900 }}>
            {t('assistant.table.courses')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2} sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ weight: 900 }}>
            {t('assistant.table.status')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} sx={{ textAlign: 'center' }} />
        {noResultPanel}
      </Grid>
      {filteredAssistants?.map((assistant) => (
        <ListItem
          onAction={onAction}
          key={assistant.user_uuid ?? assistant.invitationdata?.key ?? assistant.last_name ?? assistant.invitationdata?.last_name}
          theme={theme}
          assistant={assistant}
        />
      ))}
    </Box>
  );
};

List.propTypes = {
  filteredAssistants: PropTypes.any,
  searchText: PropTypes.string,
  onAction: PropTypes.func
};