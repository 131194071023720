import { types as t } from 'mobx-state-tree';
import { Course } from 'state/modelsEwapi/Course';

export const Likes = t
  .model('Likes', {
    nid: t.number,
    uuid: t.string,
    liked_courses: t.maybe(
      t.array(
        t.model({
          target_id: t.reference(Course),
          uuid: t.maybeNull(t.string)
        })
      )
    )
  })
  .views((self) => ({
    isCourseLiked(courseId) {
      const likedCourses = self.liked_courses && self.liked_courses.map((course) => course.target_id.course_id);
      return likedCourses.includes(courseId);
    }
  }));
