import AdminCourseLayout from 'pages/adminCourses/AdminCourseLayout';
import OnlineCourses from './OnlineCourses';

const OnlineCoursesViewMap = {
  adminOnlineCourses: (
    <AdminCourseLayout menu="online" title="Online courses">
      <OnlineCourses noPaper />
    </AdminCourseLayout>
  )
};

export default OnlineCoursesViewMap;
// adminOnlineCourses: (
//   <AdminCourseLayout menu="online" title="Online courses">
//     <AdminOnlineCourses noPaper />
//   </AdminCourseLayout>
// ),
