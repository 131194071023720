import { useCallback, useEffect, useState } from 'react';
import { isPast, subDays } from 'date-fns';

function parseData({ data }) {
  const eventTypes = data.included.filter((eventType) => eventType.type === 'event_type');
  const files = data.included.filter((location) => location.type === 'file--file');
  const locations = data.included
    .filter((location) => location.type === 'event_location')
    .map((location) => ({ ...location, image: files.find((file) => file.id === location.relationships?.image?.data.id) }));
  const events = data.data
    .map((event) => {
      const availableSeats = event.meta.available_seats;
      const date = event.attributes.date.value;
      const canRegisterMaxDate = subDays(new Date(date), 7);

      const noSeatsAvailable = availableSeats < 1;

      return { ...event, isEnrollmentClosed: isPast(new Date(date)) || new Date() >= canRegisterMaxDate, noSeatsAvailable };
    })
    .sort((a, b) => {
      const dateA = new Date(a.attributes.date.value);
      const dateB = new Date(b.attributes.date.value);

      return dateA - dateB;
    });

  return { eventTypes, locations, events };
}
export const useVtbOverview = ({ year }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const getData = useCallback(async () => {
    setIsLoading(true);

    try {
      const overviewResponse = await window.authedClient.get(`ewapi/vtb/overview?year=${year}`);
      const parsedData = parseData(overviewResponse);

      const signupResponse = await window.authedClient.get(`ewapi/vtb/signups`);
      const eventSignupIds =
        signupResponse.data?.data?.events?.length > 0 ? signupResponse.data?.data?.events?.map((signup) => signup.event_uuid) : [];

      setData({ ...parsedData, eventSignupIds });
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  }, [year]);

  useEffect(() => {
    getData();
  }, [getData]);

  return { data, isLoading, error, refetch: getData };
};
