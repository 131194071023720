import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// import Icon from '@mui/material/Icon';
import EditIcon from '@mui/icons-material/Edit';
// import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

const EditWidgetButton = (props) => {
  const { store, id } = props;
  const isLessonPlay = !!store.lessonPlay;
  const canEdit = store.canEditCourse;

  const action = 'edit';

  const onClick = () => {
    store.lessonPlay.toggleWidgetMenu(action, id);
  };

  return (
    isLessonPlay &&
    canEdit && (
      <Tooltip title={`${id}: Klik hier om de widget te bewerken`} arrow>
        <Button
          className="editWidgetButton"
          color="warning"
          variant="outlined"
          onClick={onClick}
          aria-label="edit"
          startIcon={<EditIcon />}
        >
          Edit widget
        </Button>
      </Tooltip>
    )
  );
};

EditWidgetButton.propTypes = {
  id: PropTypes.string,
  lessonPlay: PropTypes.any,
  store: PropTypes.any
};

export default observer(EditWidgetButton);
