import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { green } from '@mui/material/colors';

const useStyles = makeStyles({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    },
    '&:disabled': {
      backgroundColor: green[500],
      color: 'white'
    }
  },
  fab: {
    position: 'relative',
    backgroundColor: green[500],
    marginLeft: '6px',
    marginTop: '15px',
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    marginTop: '15px',
    position: 'absolute',
    right: '-6px'
  },
  wrapper: {
    marginBottom: '10px',
    position: 'relative'
  }
});

// renders a save button with a tooltip
const SaveButton = (props) => {
  const classes = useStyles();
  const { canSave, isSubmitting } = props;
  const { t } = useTranslation('widget');
  return (
    <span className={classes.wrapper}>
      <Button
        variant="contained"
        type="submit"
        disabled={isSubmitting || !canSave}
        className={classNames({ [classes.buttonSuccess]: !canSave }, classes.fab)}
        startIcon={isSubmitting ? <CircularProgress size={68} className={classes.fabProgress} /> : <SaveIcon />}
      >
        {t('edit.widget.save', 'Save widget')}
      </Button>
    </span>
  );
};

SaveButton.propTypes = {
  canSave: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export default SaveButton;
