import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';

import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'block',
    zIndex: '1003'
  },
  innerText: {
    textAlign: 'center',
    width: '100%',
    height: '100%',
    fontSize: '15px',
    fontWeight: '300',
    background: 'whitesmoke',
    color: 'black'
  }
});

// renders a CircularProgress while the children are being loaded
const Loading = (props) => {
  const { isLoading, msg } = props;
  const classes = useStyles();
  return (
    <>
      {isLoading && (
        <div className={classes.container}>
          <div className={classes.innerText}>
            <div>
              <CircularProgress />
            </div>
            <div>{msg}</div>
          </div>
        </div>
      )}
    </>
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  msg: PropTypes.string
};

export default observer(Loading);
