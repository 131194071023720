import { Box, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { RouterLink } from 'mobx-state-router';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import { useTranslation } from 'react-i18next';

const AssistantsNavBar = () => {
  const { t } = useTranslation();

  return (
    <Paper component={Box} p={4} spacing={2}>
      <Typography variant="h2">{t('lms.title')}</Typography>

      <List component="nav">
        <RouterLink
          routeName="LmsStudents"
          activeClassName="ButtonNavigation-active"
          isActive={(currentState) => ['LmsStudents', 'LmsStudentDetail'].includes(currentState.routeName)}
        >
          <ListItemButton className="ButtonNavigation">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t('lms.navbar.students')} />
          </ListItemButton>
        </RouterLink>

        <RouterLink
          routeName="LmsCourses"
          activeClassName="ButtonNavigation-active"
          isActive={(currentState) =>
            ['LmsCourses', 'LmsCourseDetail', 'LmsCoursesOpenList', 'LmsCoursesCompleted'].includes(currentState.routeName)
          }
        >
          <ListItemButton className="ButtonNavigation">
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary={t('lms.navbar.courses')} />
          </ListItemButton>
        </RouterLink>
      </List>
    </Paper>
  );
};

export default AssistantsNavBar;
