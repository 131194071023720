import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import * as Styled from './HeaderComponents.styles';
import { useHeaderContext } from '../context';

const MobileMenuButton = () => {
  const { t } = useTranslation();
  const { isMenuQuickLinksOpen, handleMenuQuickLinksOpen } = useHeaderContext();

  return (
    <Tooltip title={t('headerbar.quickLinksMenuButtonTooltip')}>
      <Styled.IconButton
        color="inherit"
        aria-haspopup="true"
        aria-owns={isMenuQuickLinksOpen ? 'mobile-quick-links-menu' : null}
        onClick={handleMenuQuickLinksOpen}
      >
        <MoreVertIcon data-id="Super" alt="" />
      </Styled.IconButton>
    </Tooltip>
  );
};

export default observer(MobileMenuButton);
