import MainLayout from 'pages/mainLayout/MainLayout';
import Faq from '../faq';

const FaqViewMap = {
  faq: (
    <MainLayout noPaper>
      <Faq />
    </MainLayout>
  )
};

export default FaqViewMap;
