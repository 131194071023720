import PropTypes from 'prop-types';

import NoPlay from '@mui/icons-material/NotInterested';
import ReactPlayer from 'react-player/lazy';
import { forwardRef } from 'react';
import { observer } from 'mobx-react';
import { embedVideo } from 'helpers/Helpers';

/*
In summary, the Video component is a flexible video player that checks the compatibility of a given URL,
displays the video if it's supported, and handles errors if they occur during playback.
It also provides a placeholder for unsupported URLs.
*/
const Video = forwardRef((props, ref) => {
  const { url } = props;

  const uniqueTitle = 'Video';
  const videoUrl = embedVideo(url);
  const isUrlCorrect = ReactPlayer.canPlay(videoUrl);

  if (isUrlCorrect) {
    return (
      <>
        <iframe
          {...props}
          ref={ref}
          title={uniqueTitle}
          width="100%"
          height="100%"
          src={videoUrl || ''}
          allow=""
          allowFullScreen
          style={{ border: '0' }}
        />
      </>
    );
  }

  return (
    <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
      <NoPlay />
    </span>
  );
});

Video.propTypes = {
  url: PropTypes.string
};

export default observer(Video);
