import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';

// HOOKS
import useEwapi from '../../hooks/useEwapi';
//  CUSTOM CSS
import '../../theme/selectProduct/selectProduct.scss';

const cssCheckIcon = { position: 'absolute', right: '35px' };

const SelectProduct = () => {
  const store = useContext(Store);
  const { t } = useTranslation('ewiseProductSelection');

  const { response, loading, error } = useEwapi();
  const [expanded, setExpanded] = useState(false);
  const [save, setSave] = useState(false);
  // header title
  const title = t('titleSelectProduct', 'Select product');
  // EVENTHANDLERS
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSaveChange = (event) => {
    setSave(event.target.checked);
  };
  // CUSTOM FUNCTIONS
  const getPatchUser = (uuid) => ({
    type: 'user',
    id: store.ewiseUserUuid,
    relationships: {
      field_preferred_product: { data: { type: 'base_product', id: uuid } }
    }
  });

  // ==== SET / SAVE PRODUCT ====
  // eslint-disable-next-line consistent-return
  const setProduct = (nid, uuid, name = '', products = []) => {
    const productName = name.toLowerCase().trim().replace(/\s/g, '-');
    store.login.prefBaseProduct = nid;
    store.setPrefProduct(nid);
    store.setEwiseBannerProducts(products);
    if (save) {
      (async () => {
        await store.lmsApi.patchpost(getPatchUser(uuid));
        store.routerStore.goTo('courseOverview', { params: { productName, product: nid.toString(), packageId: '0' } });
        store.showToast({ message: t('account.profile.productChoice', 'Your choice has been saved!'), variant: 'success' });
      })();
    } else {
      return store.routerStore.goTo('courseOverview', {
        params: { productName, product: nid.toString(), packageId: '0' }
      });
    }
  };

  const getLabel = () => {
    const hostname = window.location.host;
    if (hostname.includes('e-wise')) return 'ewise';
    if (hostname.includes('pe-academy')) return 'pe';
    if (hostname.includes('po-online')) return 'po';
    if (hostname.includes('cme-online')) return 'cme';
    return 'pe';
  };

  const getCurrentProduct = (profs) => {
    if (!store.login.prefBaseProduct) return {};
    const mergedData = profs.map((item) => {
      const baseProductId = item.tid;
      return item.base_product.map((i) => ({ bpId: baseProductId, ...i }));
    });
    const curProduct = mergedData.flat().find((item) => item.nid === Number(store.login.prefBaseProduct));
    return curProduct ? { check: <CheckIcon sx={cssCheckIcon} color="primary" />, ...curProduct } : {};
  };

  const Products = (professions) => {
    const label = getLabel();
    const products = professions.professions.label.filter((p) => p.label === label);
    const profs = products[0].profession;

    const currentProduct = getCurrentProduct(profs);

    return profs.map((prof) => (
      <Accordion
        className="acc"
        defaultExpanded={currentProduct.bpId === prof.tid}
        key={prof.tid}
        expanded={expanded === prof.tid}
        onChange={handleChange(prof.tid)}
      >
        <AccordionSummary
          className={`acc-header ${currentProduct?.bpId === prof.tid && 'acc-header-selected'}`}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color={currentProduct?.bpId === prof.tid ? 'primary' : ''}>{prof.name}</Typography>
        </AccordionSummary>
        <AccordionDetails className="acc-detail">
          {prof.base_product.map((bp) => (
            <Box
              className={`acc-detail-item ${currentProduct?.nid === bp.nid && 'acc-detail-selected'}`}
              key={bp.nid}
              onClick={() => setProduct(bp.nid, bp.uuid, prof.name, bp.products)}
            >
              {bp.title} {currentProduct?.nid === bp.nid && currentProduct?.check}
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <Box className="product-selector">
      <h1 className="user-name">{title}</h1>
      <Box className="product-select">
        {loading && <p>Loading...</p>}
        {error && <p>Something went wrong...</p>}
        {response && (
          <Box className="prod-select-box">
            <Box className="product-select-header">
              <FormGroup>
                <FormControlLabel
                  labelPlacement="start"
                  control={<Checkbox onChange={handleSaveChange} color="success" />}
                  label={t('savePrefProduct', 'Save as preferred product')}
                />
              </FormGroup>
            </Box>
            <Products professions={response.data} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default observer(SelectProduct);
