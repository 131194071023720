import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStore } from 'hooks';
// MUI
import { Grid, DialogContent, Box, Backdrop, CircularProgress, Stack } from '@mui/material';
/* Custom RHF components */
import { Form } from 'components/form';
import { MyTextField, Editor } from 'components/form/RHF';
import FormActionBar from './FormActionBar';
// helpers
import {
  numericalProps,
  textFieldProps,
  toggleDialogEditWidget,
  ToastWidgetSubmit,
  defaultNumericalQuestionWidgetData,
  RadioSchema
} from './formWidgets.helper';
//

const FormNumericalQuestion = () => {
  const store = useStore();
  const [saving, setSaving] = useState(false);

  const widget = store.courseEdit.setEditWidget;
  const widgetData = JSON.parse(store.courseEdit.setEditWidget.attributes.widget_data);
  const setWidgetData = { ...defaultNumericalQuestionWidgetData, ...widgetData };
  const defaultValues = { ...widget, attributes: { ...widget.attributes, widget_data: setWidgetData } };
  defaultValues.attributes.widget_type = 'Numerical Question';

  // TOAST NOTIFICATIONS ________________________________________________
  const toast = ToastWidgetSubmit();

  // ON SUBMIT / HANDLE SUBMIT __________________________________________
  const handleFormSubmit = async (newWidget) => {
    setSaving(true);
    try {
      await store.courseEdit.patchPostWidget(newWidget);
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
      setSaving(false);
      toggleDialogEditWidget(store);
    } catch (error) {
      setSaving(false);
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
      toggleDialogEditWidget(store);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} initialValues={defaultValues} validationSchema={RadioSchema}>
      {({ control, errors, dirty, reset, setValue }) => (
        <>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={saving}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent sx={{ padding: '0px 24px' }}>
            {/* top */}
            <Grid container spacing={1} sx={sxGrid}>
              <Grid item xs={10}>
                <Box sx={sxFlexColumnGap}>
                  <MyTextField
                    {...textFieldProps}
                    label="Title"
                    placeholder="Type your title here"
                    name="attributes.title"
                    control={control}
                    error={errors?.attributes?.title}
                    helperText={errors?.attributes?.title && errors.attributes.title?.message}
                  />
                  <Box className="container-editor">
                    <Box>
                      <Editor label="question" field={{ name: 'attributes.widget_data.question' }} form={{ setValue, control }} />
                    </Box>
                  </Box>
                  <Box className="container-editor">
                    <Box>
                      <Editor label="feedback" field={{ name: 'attributes.widget_data.feedback' }} form={{ setValue, control }} />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ padding: '15px 0px', paddingTop: '30px', paddingLeft: '15px' }}>
                    <Stack sx={{ columnGap: '4px' }} direction="row" justifyContent="center" alignItems="center" spacing={1}>
                      <MyTextField {...numericalProps} label="start value" name="attributes.widget_data.options.value" control={control} />
                      <MyTextField {...numericalProps} label="end value" name="attributes.widget_data.options.valueEnd" control={control} />
                      <MyTextField
                        {...numericalProps}
                        label="Slider min"
                        name="attributes.widget_data.options.sliderMin"
                        control={control}
                      />
                      <MyTextField
                        {...numericalProps}
                        label="Slider max"
                        name="attributes.widget_data.options.sliderMax"
                        control={control}
                      />
                      <MyTextField
                        {...numericalProps}
                        label="slider step"
                        name="attributes.widget_data.options.sliderStep"
                        control={control}
                      />
                      <MyTextField {...textFieldProps} label="unit" name="attributes.widget_data.options.unit" control={control} />
                      <MyTextField {...textFieldProps} label="type" name="attributes.widget_data.options.type" control={control} />
                    </Stack>
                  </Box>
                  <Box sx={{ ...sxFlexColumnGap, paddingLeft: '15px' }}>
                    <Box className="container-editor">
                      <Box>
                        <Editor
                          placeholder="Type your title here"
                          label="feedback when answer is correct"
                          field={{ name: 'attributes.widget_data.options.feedbackCorrect' }}
                          form={{ setValue, control }}
                        />
                      </Box>
                    </Box>
                    <Box className="container-editor">
                      <Box>
                        <Editor
                          label="feedback when answer is less then correct"
                          field={{ name: 'attributes.widget_data.options.feedbackLess' }}
                          form={{ setValue, control }}
                        />
                      </Box>
                    </Box>
                    <Box className="container-editor">
                      <Box>
                        <Editor
                          label="feedback when answer more then correct"
                          field={{ name: 'attributes.widget_data.options.feedbackMore' }}
                          form={{ setValue, control }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box sx={sxFlexColumnGap}>
                  <MyTextField {...textFieldProps} name="attributes.widget_type" control={control} disabled />
                  <MyTextField {...numericalProps} name="attributes.weight" label="weight" control={control} />
                  <MyTextField {...textFieldProps} name="attributes.widget_code" label="Widget code" control={control} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <FormActionBar reset={reset} dirty={dirty} defaultValues={defaultValues} saving={saving} />
        </>
      )}
    </Form>
  );
};

export default observer(FormNumericalQuestion);

const sxFlexColumnGap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
};
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};
