import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Stack, Button } from '@mui/material';

import { useStore } from 'hooks';
import { Form } from 'components/form';
import { Dialog } from '../../../components';
import ActivitiesFormPart from './ActivitiesFormPart';
import LearningGoalFormPart from './LearningGoalFormPart';

const AddLearningGoalModal = ({ visible, onCloseCallback, onSubmitCallback }) => {
  const { t } = useTranslation('curriculum');
  const store = useStore();
  const submitRef = useRef();

  const initialValues = useMemo(
    () => ({
      learningGoalName: '',
      activityDropDown: '',
      activityName: '',
      activityExplanation: ''
    }),
    []
  );

  const validationSchema = Yup.object().shape(
    {
      learningGoalName: Yup.string().required(),
      activityDropDown: Yup.string().when('activityName', {
        is: (val) => val === '',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      activityName: Yup.string().when('activityDropDown', {
        is: (val) => val === '',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      activityExplanation: Yup.string().required()
    },
    [['activityDropDown', 'activityName']]
  );

  const handleSubmit = async ({ learningGoalName, activityDropDown, activityName, activityExplanation }) => {
    store.startLoadCurriculum();
    const result = await store.createCurriculumLearningGoalWithActivity(store.curriculum.data, {
      learningGoalName,
      activityName: activityName.length > 0 ? activityName : activityDropDown,
      activityExplanation
    });
    store.stopLoadCurriculum();

    if (!result) {
      return {
        success: false,
        errors: [{ name: 'root.serverError', error: { type: 'server', message: 'Internal Server Error' }, options: {} }]
      };
    }

    if (result.errors) {
      return {
        success: false,
        errors: result.errors
      };
    }

    onSubmitCallback();

    return { success: true };
  };

  return (
    <Dialog
      action={{
        action: () => {
          submitRef.current.click();
        },
        name: t('general.addBtnLabel'),
        isLoading: store.isLoadingCurriculum,
        icon: 'ant-design:plus-outlined'
      }}
      title={t('learningGoals.addLearningGoalTitle')}
      name="add-goal"
      visible={visible}
      onClose={onCloseCallback}
    >
      <Form
        sx={{ padding: '12px' }}
        id="add-goal"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ control, errors, setValue }) => (
          <Stack justifyContent="space-between" sx={{ paddingBlock: '8px' }} spacing={2}>
            <LearningGoalFormPart control={control} errors={errors} setValue={setValue} />

            <ActivitiesFormPart control={control} errors={errors} setValue={setValue} />

            <Button sx={{ display: 'none' }} ref={submitRef} type="submit">
              {t('dashboard.saveLabel')}
            </Button>
          </Stack>
        )}
      </Form>
    </Dialog>
  );
};

AddLearningGoalModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCloseCallback: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func.isRequired
};

export default observer(AddLearningGoalModal);
