import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import AddIcon from '@mui/icons-material/Add';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box, ButtonGroup, Paper } from '@mui/material';

import { useStore } from 'hooks';
import { Button, DisclaimerDialog } from '../components';
import { CURRICULUM_STAGE } from '../constants';
import AddNewCurriculumDialog from './AddNewCurriculumDialog';
import { CurriculumTable } from './CurriculumTable';
import { FilterButton } from './FilterButton';
import { useDisclaimer } from '../components/useDisclaimer';

function calculatePagination({ offset, limit, total }) {
  // Calculate the current page and total number of pages
  const page = offset / limit + 1;
  const noOfPages = Math.ceil(total / limit);

  // Return the calculated values
  return { page, noOfPages, itemsPerPage: limit };
}

const ActionButton = ({ row: { uid, isFinished } }) => {
  const { t } = useTranslation('curriculum');

  const onDownloadBtnClick = async (e) => {
    e.stopPropagation();

    const result = await window.authedClient.get(`/portfolio/${uid}/access-pdf`);

    if (result?.data?.download_url) {
      window.open(result.data.download_url, '_blank');
    }
  };

  return (
    <>
      {isFinished ? (
        <Button onClick={onDownloadBtnClick} tooltipText={t('dashboard.downloadBtnTooltip')} inverted>
          <Icon icon="akar-icons:download" />
        </Button>
      ) : (
        <Button tooltipText={t('dashboard.editBtnTooltip')}>
          <Icon icon="akar-icons:arrow-right" />
        </Button>
      )}
    </>
  );
};

ActionButton.propTypes = {
  row: PropTypes.shape({
    uid: PropTypes.string,
    isFinished: PropTypes.bool
  })
};

const CurriculumDashboard = () => {
  const store = useStore();
  const { t } = useTranslation('curriculum');

  const items = store.curriculums.portfolios ?? [];
  const meta = store.curriculums.result;

  const isArchive = store.routerStore?.routerState?.params?.type === 'archive';
  const [filter, setFilter] = useState(isArchive ? 'archive' : 'active');

  const { hasReadDisclaimer } = useDisclaimer();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDisclaimerDialogOpen, setIsDisclaimerDialogOpen] = useState(!hasReadDisclaimer);

  const fetchCurriculums = async (offset = 0) => {
    const params = {
      offset,
      limit: 10,
      is_deleted: isArchive ? 1 : 0
    };

    await store.fetchCurriculums(params);
  };

  useEffect(() => {
    if (store.error?.length > 0) {
      store.showToast({ message: store.error[0].message, variant: 'error' });
    }
  }, [store]);

  const tableItems = items?.map(
    ({
      current_step: currentStep,
      drupal_internal__uid: id,
      uuid: uid,
      percentage_completed: percentageCompleted,
      totals: { learning_goal_activities: plannedActivities, learning_goal_evaluations: evaluations, learning_goals: goals },
      year,
      is_finished: isFinished,
      is_deleted: isDeleted,
      is_v3_portfolio: isV3Portfolio
    }) => ({
      uid,
      id,
      year,
      isFinished: !!isFinished,
      isDeleted: !!isDeleted,
      currentStep,
      percentageCompleted,
      goals,
      plannedActivities,
      evaluations,
      isV3Portfolio
    })
  );

  const handlePageChange = (offset) => {
    fetchCurriculums(offset);
  };

  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const onClose = () => {
    setIsAddModalOpen(false);
  };

  const onSubmitted = async (curriculum) => {
    setIsAddModalOpen(false);
    store.startLoadCurriculum();
    store.routerStore.goTo('curriculumStages', {
      params: {
        uid: curriculum.id,
        stageId: CURRICULUM_STAGE.functions
      }
    });
    store.stopLoadCurriculum();
  };

  const setRouteFilter = (route) => {
    setFilter(route);
    store.routerStore.goTo('curriculumDashboard', {
      params: {
        type: route === 'active' ? 'active' : 'archive'
      }
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <ButtonGroup variant="outlined" aria-label="filter">
            <FilterButton isActive={filter === 'active'} onClick={() => setRouteFilter('active')}>
              {t('dashboard.activeLabel')}
            </FilterButton>
            <FilterButton isActive={filter === 'archive'} onClick={() => setRouteFilter('archive')}>
              {t('dashboard.archivedLabel')}
            </FilterButton>
          </ButtonGroup>
        </Box>
        <Box className="info_box">
          <Button tooltipText={t('dashboard.addNewTooltip')} onClick={handleAddClick}>
            <AddIcon sx={{ height: '20px' }} />
            {t('dashboard.addNewLabel')}
          </Button>
          <Button tooltipText="Help" onClick={() => setIsDisclaimerDialogOpen(true)} inverted square shadow>
            <QuestionMarkIcon />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '16px'
        }}
      >
        {tableItems.length > 0 ? (
          <CurriculumTable
            items={tableItems}
            {...calculatePagination(meta)}
            handlePageChange={handlePageChange}
            refreshCallback={fetchCurriculums}
          />
        ) : (
          <Paper>
            <Box sx={{ padding: '40px 20px' }}>{isArchive ? t('dashboard.noArchiveText') : t('dashboard.noPortfolioText')}</Box>
          </Paper>
        )}
      </Box>

      <AddNewCurriculumDialog isOpen={isAddModalOpen} onCloseCallback={onClose} onSubmitCallback={onSubmitted} />

      <DisclaimerDialog open={isDisclaimerDialogOpen} onCloseCallback={() => setIsDisclaimerDialogOpen(false)} />
    </>
  );
};

export default observer(CurriculumDashboard);
