import { types as t } from 'mobx-state-tree';

export const User = t
  .model('User', {
    uid: t.identifierNumber,
    uuid: t.string,
    name: t.string,
    last_name: t.string,
    first_name: t.maybeNull(t.string),
    initials: t.maybeNull(t.string),
    mail: t.string,
    preferred_langcode: t.string,
    user_picture: t.maybeNull(t.string),
    display_name: t.maybeNull(t.string),
    roles: t.array(t.string),
    permissions: t.array(t.string),
    field_accepted_gdpr: t.maybeNull(t.boolean)
  })
  .views((self) => ({
    hasRole(role) {
      return self.roles.includes(role);
    },
    get hasEditorRights() {
      return (
        self.permissions.findIndex((a) => a === 'administer lesson content') !== -1 ||
        self.permissions.findIndex((a) => a === 'administer users') !== -1
      );
    }
  }))
  .actions((self) => ({
    updatePicture(picture) {
      self.user_picture = picture;
    }
  }));
