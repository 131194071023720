import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { useHeaderContext } from '../context';
import * as Styled from './HeaderComponents.styles';

const LmsButton = () => {
  const { t } = useTranslation();

  const { handleGoToLMS } = useHeaderContext();

  return (
    <Tooltip title={t('LMSOverview')} arrow>
      <Styled.Button id="go-to-lms" aria-label="" onClick={handleGoToLMS}>
        {t('course.leftSidebar.LMS')}
      </Styled.Button>
    </Tooltip>
  );
};

export default observer(LmsButton);
