import { types as t, destroy, getParent, flow } from 'mobx-state-tree';

export const WordCloudItem = t
  .model('WordCloudItem', {
    nid: t.number,
    widgetCode: t.string,
    answer: t.maybeNull(t.array(t.string)),
    wordcloud: t.model({
      body: t.string,
      input_required: t.number,
      internal_title: t.string,
      profession: t.maybeNull(t.number),
      show_fields: t.number,
      user_has_answered: t.number,
      words: t.maybe(
        t.array(
          t.model({
            count: t.number,
            word: t.string
          })
        )
      )
    })
  })
  .actions((self) => ({
    update(widgetUpdate) {
      self.answer = widgetUpdate.answer;
      self.wordcloud = widgetUpdate.wordcloud;
    },
    formatWords() {
      self.wordcloud.words.map((words) => {
        words.value = words.word;
        delete words.word;
        return words;
      });
      return self.wordcloud.words;
    }
  }));

export const WordCloudList = t
  .model('WordCloudList', {
    widgets: t.array(WordCloudItem)
  })
  .views((self) => ({
    getWordCloud(widgetCode) {
      return self.widgets.find((w) => w.widgetCode === widgetCode);
    }
  }))
  .actions((self) => ({
    add(widget) {
      if (!self.getWordCloud(widget.widgetCode)) {
        self.widgets.push(widget);
      } else {
        const index = self.widgets.findIndex((w) => w.widgetCode === widget.widgetCode);
        self.widgets[index] = widget;
      }
      return self.widgets;
    },
    remove(widgetCode) {
      const widget = self.widgets.find((w) => w.widgetCode === widgetCode);
      return destroy(widget);
    },
    submitAnswers: flow(function* pollVote(widget, answer) {
      const updatedWidget = yield getParent(self, 1).lmsApi.postWordCloudAnswer(widget.nid, answer);
      widget.update(updatedWidget);
    })
  }));

export const RelWordCloudItem = t.model('RelWordCloudItem', {
  data: t.maybeNull(
    t.model({
      id: t.string,
      type: t.string
    })
  )
});
