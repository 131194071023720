/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/* MUI */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';

/* SCSS */
import '../../scss/MenuList.scss';

/*
The component's primary purpose is to provide users with the ability to select a category for a course, based on the given context and data fetched asynchronously.
It is often used in scenarios where categorization of courses is required.
*/
const AdvancedCourseCategories = ({ handleCopyActions, copyValues }) => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');
  const { courseEdit } = store;
  const { courseUuid, categoryUuid } = copyValues;
  const [catOptions, setOptions] = useState([]);

  // declare the async data fetching function
  const fetchData = useCallback(async () => {
    const categories = await courseEdit.advGetCategoriesByContainerNid();
    const catOptionsObj = categories.data.map((cat) => ({ label: cat.attributes.name, id: cat.id }));
    setOptions(catOptionsObj);
  }, [courseEdit]);

  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);

  const setValue = (categoryValue) => {
    handleCopyActions({
      ...copyValues,
      categoryUuid: categoryValue?.id || ''
    });
  };

  // TRANSLATIONS
  const txtNoSelectedCourse = t('copyDialog.selCategory.noCategory');
  const txtNoCategoryMandatory = t('copyDialog.selCategory.noCategoryMandatory');

  if (copyValues.actionId === 3) return <p>{txtNoCategoryMandatory}</p>;
  if (!courseUuid && !categoryUuid) return <p>{txtNoSelectedCourse}</p>;

  return (
    <Box>
      <div>
        <Autocomplete
          disabled={!courseUuid}
          options={catOptions.sort((a, b) => -b.label.localeCompare(a.label))}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          getOptionLabel={(option) => option.label || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disablePortal
          id="combo-box-demo"
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Categories" />}
        />
      </div>
    </Box>
  );
};

AdvancedCourseCategories.propTypes = {
  handleCopyActions: PropTypes.func,
  copyValues: PropTypes.object
};

export default observer(AdvancedCourseCategories);
