/* eslint-disable no-prototype-builtins */
import { observer } from 'mobx-react';
// import { useContext } from 'react';
import PropTypes from 'prop-types';

/* MUI */
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListSubheader from '@mui/material/ListSubheader';
import StarIcon from '@mui/icons-material/Star';

// renders a list of courses that are linked to a package
const ListLinkedCourses = ({ courses }) => {
  // const store = useContext(Store);
  // const endingCourses = courses.filter((item) => dateDiff(item.valid_period_end) < 30);
  // eslint-disable-next-line no-undef
  const uniqueCourses = [...new Map(courses.map((c) => [c.course_nid, c])).values()];

  return (
    <Box sx={{ padding: '15px', marginBottom: `15px`, width: '100%', bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton sx={{ borderRadius: `10px` }}>
              <ListItemIcon>
                <StarIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Linked courses" />
            </ListItemButton>
          </ListItem>
          {uniqueCourses.map((course, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton sx={{ borderRadius: `10px` }}>
                {/* <ListItemIcon>
                  <Avatar>{dateDiff(course.valid_period_end)}</Avatar>
                </ListItemIcon> */}
                <ListItemText primary={course.course_title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </nav>
    </Box>
  );
};

ListLinkedCourses.propTypes = {
  courses: PropTypes.array
};

export default observer(ListLinkedCourses);
