/* Core */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/* Translation */
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './i18n';

import OauthProvider from './providers/OauthProvider';
import WithStoreProvided from './providers/WithStoreProvided';
import { CustomThemeProvider } from './providers/CustomThemeProvider';

import './index.css';

import { LoadingPage } from './components';
import ProtectedRoutesMiddleware from './providers/ProtectedRoutesMiddleWare';
import { routes } from './state/Routes';
import { viewMap } from './providers/ViewMap';

const App = () => (
  <CustomThemeProvider>
    <ProtectedRoutesMiddleware>
      <OauthProvider LoaderComponent={LoadingPage}>
        <I18nextProvider i18n={i18next}>
          <WithStoreProvided routes={routes} viewMap={viewMap} />
        </I18nextProvider>
      </OauthProvider>
    </ProtectedRoutesMiddleware>
  </CustomThemeProvider>
);

export default App;
