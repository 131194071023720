import { types as t } from 'mobx-state-tree';
import { RelFile } from './BaseModel';

// TODO: Cleanup
export const LessonContent = t
  .model('LessonContent', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__id: t.number,
      // created: IsoDate,
      // changed: IsoDate,
      title: 'Empty',
      documentTitle: '',
      pages: t.array(t.string),
      widgets: t.array(t.array(t.string)),
      toc: t.array(
        t.model('Toc', {
          title: t.string,
          level: t.number,
          pagenr: t.number,
          id: t.string
        })
      )
    }),
    relationships: t.maybeNull(
      t.model({
        printversion: RelFile
      })
    )
  })
  .actions((self) => ({
    setTitle(title) {
      self.title = title;
    }
  }));

export const RelLessonContent = t.model('RelLessonContent', {
  data: t.maybeNull(
    t.model({
      id: t.reference(LessonContent),
      type: t.string
    })
  )
});

export const RelLessonContentWidgets = t.model('RelLessonContentWidgets', {
  data: t.maybeNull(
    t.array(
      t.model({
        type: t.string,
        id: t.identifier
      })
    )
  )
});

export const RelLessonContentSimple = t.model('RelLessonContentSimple', {
  data: t.maybeNull(
    t.model({
      id: t.string,
      type: t.string
    })
  )
});

export const LessonContentEdit = t.model('LessonContentEdit', {
  id: t.identifier,
  type: t.string,
  attributes: t.model({
    drupal_internal__id: t.number,
    title: t.string
  }),
  relationships: t.model({
    tagdoc: RelFile,
    lesson_content_widgets: t.maybeNull(RelLessonContentWidgets)
  })
});
