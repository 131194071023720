import { observer } from 'mobx-react';
import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LoadingPage from 'components/common/LoadingPage';

const CurriculumLayout = ({ children }) => {
  const store = useContext(Store);

  if (store.state === 'loading') {
    return <LoadingPage />;
  }

  return (
    <>
      <Box
        className="curriculum_layout"
        sx={{
          padding: { xs: '1rem', md: '2rem' }
        }}
      >
        {children}
      </Box>
    </>
  );
};
CurriculumLayout.propTypes = {
  children: PropTypes.any
};

export default observer(CurriculumLayout);
