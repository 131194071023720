import { endOfYear, getDate, getMonth, getYear, isToday, isValid, startOfYear } from 'date-fns';

function isAugustFirst(date) {
  return getMonth(date) === 7 && getDate(date) === 1;
}

// a school year is defined as last year 1st of August to this year 1st of August
// this fuction checks if the given start date is 1st of August and the end date doesn't exceed 1 year
// 01/08/2023 - 01/08/2024 all dates that are in this interval are valide school year dates
export function isSchoolYear(startDate, endDate) {
  // check if both dates are valid
  if (!isValid(startDate) || !isValid(endDate)) {
    return false;
  }
  // check if the difference in years is 1 or 0
  if (getYear(endDate) - getYear(startDate) !== 0 && getYear(endDate) - getYear(startDate) !== 1) {
    return false;
  }

  return isAugustFirst(startDate) && (isAugustFirst(endDate) || isToday(endDate));
}

export function isFullYear(startDate, endDate) {
  // check if both dates are valid
  if (!isValid(startDate) || !isValid(endDate)) {
    return false;
  }
  // check if both dates are in the same year
  if (getYear(startDate) !== getYear(endDate)) {
    return false;
  }
  if (startDate.getTime() !== startOfYear(startDate).getTime()) {
    return false;
  }

  return isToday(endDate) || endDate.getTime() === endOfYear(endDate).getTime();
}
