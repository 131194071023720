import { ThemeBrandTitle } from 'helpers/Helpers';
import { lmsBeforeEnter as beforeEnter } from 'pages/lms/LmsRouteActions';

export const lmsDashboardRoute = [
  {
    name: 'LmsDashboardOverview',
    pattern: '/lms/dashboard/overview',
    meta: { title: `%%lms.navbar.dashboard%% | ${ThemeBrandTitle()}` },
    beforeEnter,
    onEnter: async (fromState, toState, routerStore) => {
      const { store } = routerStore.options;
      const { queryParams } = toState;
      if (!['isLoading', 'hasNoOrganisations'].includes(store.LmsDashboard.chart.status)) {
        store.LmsDashboard.fetchLmsChart(queryParams);
      }
    }
  },
  {
    name: 'LmsDashboardProfessions',
    pattern: '/lms/dashboard/professions',
    meta: { title: `%%lms.navbar.dashboard%% | ${ThemeBrandTitle()}` },
    beforeEnter,
    onEnter: async (fromState, toState, routerStore) => {
      const { store } = routerStore.options;
      const { queryParams } = toState;
      if (!['isLoading', 'hasNoOrganisations'].includes(store.LmsDashboard.professions.status)) {
        store.LmsDashboard.fetchLmsProfessions?.(queryParams);
      }
    }
  },
  {
    name: 'LmsDashboardProducts',
    pattern: '/lms/dashboard/products',
    meta: { title: `%%lms.navbar.dashboard%% | ${ThemeBrandTitle()}` },
    beforeEnter,
    onEnter: async (fromState, toState, routerStore) => {
      const { store } = routerStore.options;
      const { queryParams } = toState;
      if (!['isLoading', 'hasNoOrganisations'].includes(store.LmsDashboard.products.status)) {
        store.LmsDashboard.fetchLmsProducts(queryParams);
      }
    }
  }
];
