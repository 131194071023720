import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import { LessonProgress, LessonArrayProgress } from 'helpers/Helpers';
import { Box, CircularProgress, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { LinearProgressWithLabel } from 'components';

const CalculateProgress = ({ id, lessons, isFinished, isCourseStarted, isInHeader, showProgress }) => {
  const store = useContext(Store);

  if (!store.courseResults.courseResults.length) {
    return <></>;
  }
  // Find lesson container for course
  const lessonResult = store.lesson_results.findLessonByCourseNid(id);

  // Calculate course progress for multiple  lesson
  const courseProgress = (lessonProgressArray) => {
    let total = 0;
    lessonProgressArray.map((lesson) => {
      const lessonProgress = lesson / lessons.length;
      total += lessonProgress;
      return total;
    });
    return Math.round(total);
  };
  const showProgressHeader = (progressHeader) => {
    if (isInHeader) {
      if (showProgress) {
        return progressHeader === 100 && !store.courseResults.isCourseFinished(id) ? 99 : progressHeader || 0;
      }
      return (
        <LinearProgressWithLabel
          value={progressHeader === 100 && !store.courseResults.isCourseFinished(id) ? 99 : progressHeader || 0}
          color="secondaryOrange"
        />
      );
    }
    return (
      <Box className="done_icon" backgroundColor={isFinished ? 'primary.main' : 'white'}>
        {isFinished && <CheckIcon />}
        {isCourseStarted && (
          <Box>
            <Box>
              <Typography color="primary" fontSize="12px">
                {lessons && (progressHeader === 100 ? '99%' : `${progressHeader}%`)}
              </Typography>
            </Box>
            <CircularProgress
              variant="determinate"
              value={lessons && (progressHeader === 100 ? 99 : progressHeader)}
              size={43}
              thickness={2}
              sx={{
                position: 'absolute',
                left: '3px',
                top: '3px',
                zIndex: 1,
                color: 'primary.main'
              }}
            />
          </Box>
        )}
      </Box>
    );
  };
  if (lessons && lessons.length === 1) {
    LessonProgress(lessonResult[0]);
    return showProgressHeader(LessonProgress(lessonResult[0]));
  }
  const progressArray = lessons && LessonArrayProgress(lessonResult, lessons);
  const progress = progressArray && courseProgress(progressArray);

  return showProgressHeader(progress);
};

CalculateProgress.propTypes = {
  id: PropTypes.number,
  lessons: PropTypes.any,
  isFinished: PropTypes.bool,
  isCourseStarted: PropTypes.bool,
  isInHeader: PropTypes.bool,
  showProgress: PropTypes.bool
};
export default observer(CalculateProgress);
