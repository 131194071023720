import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useRef, useEffect, useContext, forwardRef } from 'react';
import { urlify } from 'state/Utils';
import { useTranslation } from 'react-i18next';

import { Button, Box, Slide } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PackageListDialog = () => {
  const { t } = useTranslation('adminCourses');
  const store = useContext(Store);
  const { courseEdit } = store;
  //
  const scroll = 'paper';
  const open = courseEdit.dialogMenuList;
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  if (!open) return null;

  // EVENT HANDLERS: select package
  const handleMenuSelect = (item) => () => {
    const { package_title: title, package_id: packageNid, course_container_id: containerNid } = item;
    courseEdit.setStoreValue('currentPackage', item);
    const routerParams = {
      params: { package: urlify(title.toLowerCase()), packageNid: packageNid.toString(), containerNid: containerNid.toString() }
    };
    courseEdit.setStoreValue('dialogMenuList', !open);
    store.routerStore.goTo('adminDashboardCourses', routerParams);
  };

  // FILTER PACKAGES
  const packageMenu = courseEdit?.packageMenuList.filter(
    (item) => !item.package_title.includes('not acc') && !item.package_title.includes('niet')
  );
  //
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => courseEdit.setStoreValue('dialogMenuList', !open)}
      scroll={scroll}
      aria-labelledby="scroll-package-menu-title"
      aria-describedby="scroll-package-menu-description"
    >
      <DialogTitle sx={{ padding: '0 10px' }} id="scroll-package-menu-title">
        {t('packagesMenu.selectPackages')}
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText component="div" id="sscroll-package-menu-description" ref={descriptionElementRef} tabIndex={-1}>
          {packageMenu.map((item) => (
            <Box sx={sxMenuItem} onClick={handleMenuSelect(item)} key={item.package_id}>
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {item.package_title}
              </Box>
              <Box component="span" sx={{ fontSize: '13px' }}>
                {item.profession_name.toLowerCase()}
              </Box>
            </Box>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => courseEdit.setStoreValue('dialogMenuList', !open)}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(PackageListDialog);

const sxMenuItem = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0px 5px',
  ' span': { padding: '5px 0px', transition: 'padding .3s' },
  '&:hover': {
    background: '#c0c0c02e',
    cursor: 'pointer',
    transition: 'background .1s',
    '& span:first-of-type': { padding: '3px 20px', fontWeight: 500, color: 'primary', transition: 'padding .3s' }
  }
};
