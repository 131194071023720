import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Link, MenuItem } from '@mui/material';

import { getMarketingSiteUrl } from 'helpers/Helpers';

const MenuItemEwiseUser = () => {
  const { t } = useTranslation();

  return (
    <MenuItem key="Publieke website" id="Publieke website">
      <Link href={getMarketingSiteUrl()} target="_blank" style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }} rel="noreferrer">
        {t('Publieke website')}
      </Link>
    </MenuItem>
  );
};

export default observer(MenuItemEwiseUser);
