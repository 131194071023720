import MainLayout from 'pages/mainLayout/MainLayout';
import Faq2 from '../faq2';

const Faq2ViewMap = {
  faq2: (
    <MainLayout noPaper>
      <Faq2 />
    </MainLayout>
  )
};

export default Faq2ViewMap;
