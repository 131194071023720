/* eslint-disable camelcase */
import { types as t, getParent, flow } from 'mobx-state-tree';
import { RelAuthors } from './Author';
// eslint-disable-next-line import/no-cycle
import { IsoDate, RelProfession, TextField, Links, RelFile } from './BaseModel';
import { RelLessons1 } from './Lesson';

const drupalBaseUrl = process.env.REACT_APP_JSONAPI;

export const RelLessons = t.model('RelLessons', {
  data: t.maybeNull(
    t.array(
      t.model({
        type: t.string,
        id: t.identifier
      })
    )
  )
});

export const RelDependentCourse = t.model('RelDependentCourse', {
  data: t.maybeNull(
    t.array(
      t.model({
        type: t.string,
        id: t.identifier
      })
    )
  )
});

export const LessonContainer = t
  .model('LessonContainer', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__nid: t.number,
      drupal_internal__vid: t.number,
      created: IsoDate,
      changed: IsoDate,
      title: t.string,
      status: t.boolean,
      field_extra_label: t.maybeNull(t.string),
      field_keywords: t.maybeNull(t.string),
      field_preview_video: t.maybeNull(TextField),
      field_goals: t.maybeNull(TextField),
      field_information: t.maybeNull(TextField),
      field_podcast_html: t.maybeNull(TextField),
      podcast_title: t.maybeNull(t.string),
      field_sharing_info: t.maybeNull(t.string)
    }),
    relationships: t.model({
      lessons: RelLessons1,
      dependent_courses: RelDependentCourse,
      field_authors: RelAuthors,
      field_course_header_photo: t.maybeNull(RelFile),
      field_coursephoto: t.maybeNull(RelFile),
      field_reviewers: t.maybeNull(RelAuthors),
      profession: RelProfession,
      field_course_image_extern: t.maybeNull(RelFile)
    }),
    links: Links
  })
  .views((self) => ({
    get authors() {
      const { field_authors } = self.relationships;
      return (
        field_authors.data.map((data) => {
          const {
            id,
            attributes: { title }
          } = data.id;
          return {
            id,
            title,
            type: data.type
          };
        }) || []
      );
    },
    get reviewers() {
      const { field_reviewers } = self.relationships;
      return (
        field_reviewers.data.map((data) => {
          const {
            id,
            attributes: { title }
          } = data.id;
          return {
            id,
            title,
            type: data.type
          };
        }) || []
      );
    },
    get field_course_image_extern() {
      const { field_course_image_extern } = self.relationships;
      return (field_course_image_extern?.data && `${drupalBaseUrl}${field_course_image_extern.url}`) || '';
    },
    get field_coursephoto() {
      const { field_coursephoto } = self.relationships;
      return (field_coursephoto?.data && `${drupalBaseUrl}${field_coursephoto.url}`) || '';
    },
    get field_course_header_photo() {
      const { field_course_header_photo } = self.relationships;
      return (field_course_header_photo?.data && `${drupalBaseUrl}${field_course_header_photo.url}`) || '';
    }
  }))
  .actions((self) => ({
    patchPicture: flow(function* patchPicture(entity, file, field) {
      try {
        const response = yield getParent(self, 3).lmsApi.postImage(entity, file, field);
        return response;
      } catch (e) {
        return e;
      }
    })
  }));

export const RelLessonContainer = t.model('RelLessonContainer', {
  data: t.maybeNull(
    t.model({
      id: t.reference(LessonContainer),
      type: t.string
    })
  )
});

export const NewLessonContainer = t.model('NewLessonContainer', {
  type: t.string,
  attributes: t.model({
    title: t.string
  }),
  relationships: t.model({
    lessons: RelLessons,
    profession: RelProfession
  })
});
