export const getStudents = async (eventUuid) => {
  const signupsResponse = await window.authedClient.get(
    `ewapi/vtb/attendance?event_uuid=${eventUuid}&limit=all&cacheBust=${new Date().getTime()}`
  );

  if (signupsResponse.status !== 200) {
    throw new Error('something went wrong');
  }

  return signupsResponse.data.data.signups.map((signup) => ({
    id: signup.user.user_internal__id,
    name: signup.user.initials + signup.user.last_name || '',
    email: signup.user.email || '',
    present: signup.present || false,
    signupId: signup.uuid
  }));
};
