/* eslint-disable camelcase */
/* Core */
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';

/* Custom styling */
import { Box, Grid } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

/* Custom components */
import { getCourseCardProps, courseOverviewImgSize } from 'helpers/Helpers';
import BasicCard from './Card/CourseGridCard';
import BasicCardListMin from './Card/CourseGridCardListMin';
import CourseTableCell from './Grid/CourseTable/CourseTableRow';
import NoCourses from './Card/elements/NoCourses';
import ViewAllCertificates from './Card/elements/ViewAllCertificates';

/*
In summary, the CourseFinished component is responsible for rendering a list of finished courses.
The layout and appearance of these courses are determined by the value of store.courseoverview.
If no courses are finished, it displays a message encouraging the user to start a new course.
If there are finished courses, it generates a grid layout and populates it with course cards based on the selected layout style.
*/
const CourseFinished = () => {
  const store = useContext(Store);
  const { t } = useTranslation();

  useEffect(() => {
    if (store.courseoverview === 1) {
      courseOverviewImgSize();
    }
  }, [store.courseoverview]); // Run this effect when the loads

  const finishedCourses = store.courseResults
    .getFinishedCourses()
    .slice()
    .sort((a, b) => a.course_title?.localeCompare(b.course_title));

  const isGridLayout = store.courseoverview === 3;

  // Container
  const gridContainerXS = 12;
  const gridContainerSM = isGridLayout ? 8 : 12;
  const gridContainerMD = 12;
  const gridContainerLg = 12;
  const gridContainerXl = 12;

  // Items
  const gridItemXs = 12;
  const gridItemSm = isGridLayout ? 4 : 12;
  const gridItemMd = isGridLayout ? 3 : 12;
  const gridItemLg = isGridLayout ? 6 : 12;
  const gridItemXl = isGridLayout ? 3 : 12;

  const setColumns = { xs: gridContainerXS, sm: gridContainerSM, md: gridContainerMD, lg: gridContainerLg, xl: gridContainerXl };

  if (!finishedCourses.length) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <ViewAllCertificates />
        <NoCourses text={t('courseoverview.noStarted', 'Start a new course!')} icon={<RocketLaunchIcon color="primary" />} />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ViewAllCertificates />
      <Grid container spacing={2} columns={setColumns}>
        {finishedCourses &&
          finishedCourses.map((course, index) => {
            const propsBasicCard = getCourseCardProps(course.course_id);

            return (
              <React.Fragment key={index}>
                {store.courseoverview === 1 && (
                  <Grid item key={propsBasicCard.nid} xs={12} sm={6} md={6} lg={6} xl={4}>
                    <BasicCardListMin {...propsBasicCard} />
                  </Grid>
                )}
                {store.courseoverview === 2 && (
                  <Grid item key={propsBasicCard.nid} xs={gridItemXs} sm={gridItemSm} md={gridItemMd} lg={gridItemLg} xl={gridItemXl}>
                    <BasicCard {...propsBasicCard} />
                  </Grid>
                )}
                {store.courseoverview === 3 && (
                  <Grid
                    item
                    key={propsBasicCard.nid}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ marginTop: 0, [`.card__container`]: { marginTop: 0 } }}
                  >
                    <CourseTableCell propsBasicCard={propsBasicCard} />
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
      </Grid>
    </Box>
  );
};

export default observer(CourseFinished);
