import React from 'react';
// MAIN LAYOUT
import MainLayout from 'pages/mainLayout/MainLayout';

// E-WISE USERS SELECT PRODUCT PAGE
import SelectProductViewMap from 'pages/ewiseUserSelectProduct/SelectProductViewMap';

/* COURSE */
import CourseOverview from 'pages/course/CourseOverview';
import CourseIntroduction from 'pages/course/CourseIntroduction';

// ACCOUNT PAGES
import CertificatesViewMap from 'pages/account/accountViewMap/CertificatesViewMap';
import ProfileViewMap from 'pages/account/accountViewMap/ProfileViewMap';
import SettingsViewMap from 'pages/account/accountViewMap/SettingsViewMap';
import SubscriptionViewMap from 'pages/account/accountViewMap/SubscriptionViewMap';

// PLAY
import EvaluationViewMap from 'pages/play/lessonPlayViewMaps/EvaluationViewMap';
import TermsViewMap from 'pages/play/lessonPlayViewMaps/TermsViewMap';
import FeedbackViewMap from 'pages/play/lessonPlayViewMaps/FeedbackViewMap';

/* ADMIN COURSES */
import DashboardViewMap from 'pages/adminCourses/dashboard/DashboardViewMap';
import OnlineCoursesViewMap from 'pages/adminCourses/onlineCourses/OnlineCoursesViewMap';
import OfflineCoursesViewMap from 'pages/adminCourses/offlineCourses/OfflineCoursesViewMap';
import EditCourseViewMap from 'pages/adminCourses/addCourse/EditCourseViewMap';
import ViewCourseViewMap from 'pages/adminCourses/viewCourses/ViewCoursesViewMap';
import CourseSearchBarViewMap from 'pages/adminCourses/search/CourseSearchBarViewMap';
/* ERROR PAGES */
import ErrorPageViewMap from 'pages/misc/miscViewMaps/ErrorPageViewMap';
import NotFoundPageViewMap from 'pages/misc/miscViewMaps/NotFoundViewMap';
/* OTHER PAGES */
import FaqViewMap from 'pages/faq/faqViewMaps/FaqViewMap';
import Faq2ViewMap from 'pages/faq/faqViewMaps/Faq2ViewMap';
import Faq3ViewMap from 'pages/faq/faqViewMaps/Faq3ViewMap';
import ArticleViewMap from 'pages/faq/faqViewMaps/ArticleViewMap';
import colophonViewMap from 'pages/colophon/ColophonViewMap';

/* LMS */
import LmsDashboardViewMap from 'pages/lms/lmsDashboard/LmsDashboardViewMap';
import LmsStudentsViewMap from 'pages/lms/lmsStudents/LmsStudentsViewMap';
import LmsStudentDetailViewMap from 'pages/lms/lmsStudentDetail/LmsStudentDetailViewMap';
import lmsCoursesViewMap from 'pages/lms/lmsCourses/lmsCoursesViewMap';
import LmsOrganisationsViewMap from 'pages/lms/lmsOrganisations/LmsOrganisationsViewMap';
import LmsCourseDetailViewMap from 'pages/lms/lmsCourseDetail/LmsCourseDetailViewMap';
import AttendanceSheetViewMap from 'pages/vtbDashboard/vtbViewMaps/AttendanceSheetViewMap';
import VtbViewMap from 'pages/vtbDashboard/vtbViewMaps/VtbViewMap';
import CurriculumDashboardViewMap from 'pages/curriculum/curriculumDashboard/CurriculumDashboardViewMap';
import CurriculumStagesViewMap from 'pages/curriculum/curriculumStages/CurriculumStagesViewMap';
/* Assistant Custom components */

// Only load pages and components when necessary. Lazy loading the following page(s)
import { Lazy } from 'components';
import LogoutViewMap from 'pages/login/LogoutViewMap';
import teachersOverviewViewMap from 'pages/teachersOverview/TeachersOverviewViewMap';
import teacherDetailViewMap from 'pages/teachersOverview/TeacherDetailViewMap';
import courseOverviewViewMap from 'pages/course/CourseOverviewViewMap';
import CourseIntroductionViewMap from 'pages/course/CourseIntroductionViewMap';
import searchOverviewViewMap from 'pages/search/SearchOverviewViewMap';
// TEST HOME
// import HomeViewMap from 'pages/home/HomeViewMap';
import SpecialAccessViewMap from 'pages/specialAccess/SpecialAccessViewMap';
import SpecialAccessIntroductionViewMap from 'pages/specialAccess/specialAccessIntroductionViewMap';
import { assistantManagementViewMap } from '../pages/assistentManagment/assitantManagementViewMap';

const LessonPlay = React.lazy(() => import('pages/play/LessonPlay'));

export const viewMap = {
  ...LogoutViewMap,
  // ...HomeViewMap,
  ...SpecialAccessViewMap,
  ...SpecialAccessIntroductionViewMap,

  /* COURSE OVERVIEW */
  ...courseOverviewViewMap,
  // defCourseOverview repeated in courseOverviewViewMap above, what to do?
  defCourseOverview: (
    <MainLayout footer title="courseoverview.title">
      <CourseOverview />
    </MainLayout>
  ),

  /* SEARCH OVERVIEW */
  ...searchOverviewViewMap,

  /* COURSE INTRODUCTION */
  ...CourseIntroductionViewMap,
  defCourseIntroduction: (
    <MainLayout footer background title="introduction.title">
      <CourseIntroduction />
    </MainLayout>
  ),

  /* ADMIN COURSES */
  ...DashboardViewMap,
  ...OnlineCoursesViewMap,
  ...OfflineCoursesViewMap,
  ...EditCourseViewMap,
  ...ViewCourseViewMap,
  ...CourseSearchBarViewMap,

  /* EWISE USERS SELECT PRODUCT  */
  ...SelectProductViewMap,

  /* MY ACCOUNT */
  ...ProfileViewMap,
  ...CertificatesViewMap,
  ...SubscriptionViewMap,
  ...SettingsViewMap,

  /* LESSONPLAY */
  lessonPlay: (
    <Lazy noLayout>
      <LessonPlay />
    </Lazy>
  ),
  defLessonPlay: (
    <Lazy noLayout>
      <LessonPlay />
    </Lazy>
  ),
  specialAccessLessonPlay: (
    <Lazy noLayout>
      <LessonPlay />
    </Lazy>
  ),
  /* END LESSONPLAY */
  ...EvaluationViewMap,
  ...TermsViewMap,
  ...FeedbackViewMap,

  /* ERROR PAGES */
  ...ErrorPageViewMap,
  ...NotFoundPageViewMap,

  /* ASSISTANT */
  ...assistantManagementViewMap,

  /* VTB */
  ...VtbViewMap,
  ...AttendanceSheetViewMap,

  /* Teachers */
  ...teachersOverviewViewMap,
  ...teacherDetailViewMap,

  /* Curriculum */
  ...CurriculumDashboardViewMap,
  ...CurriculumStagesViewMap,

  /* LMS */
  ...LmsDashboardViewMap,
  ...LmsStudentsViewMap,
  ...LmsStudentDetailViewMap,
  ...lmsCoursesViewMap,
  ...LmsCourseDetailViewMap,
  ...LmsOrganisationsViewMap,

  /* OTHER PAGES */
  ...FaqViewMap,
  ...Faq2ViewMap,
  ...Faq3ViewMap,
  ...ArticleViewMap,
  ...colophonViewMap
};
