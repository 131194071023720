import { Box, ListItemButton, ListItemText, Paper, Typography, List } from '@mui/material';
import { RouterLink } from 'mobx-state-router';
import { useTranslation } from 'react-i18next';

const ShortCuts = () => {
  const { t } = useTranslation();

  const shortcuts = [
    // { name: 'Cursisten', route: 'LmsStudents', disabled: false },
    // { name: 'Students not logged in', route: 'LmsStudenst', disabled: true },
    { name: t('lms.shortcuts.listStudentsOpen'), route: 'LmsStudentsOpen', disabled: false },
    { name: t('lms.shortcuts.listStudentsCompleted'), route: 'LmsStudentsCompleted', disabled: false },
    { name: t('lms.shortcuts.listCoursesCompleted'), route: 'LmsCoursesCompleted', disabled: false },
    { name: t('lms.shortcuts.listCoursesOpen'), route: 'LmsCoursesOpenList', disabled: false },
    { name: t('lms.shortcuts.listAllCoursesAZ'), route: 'LmsCourses', disabled: false }
  ];

  return (
    <Paper component={Box} p={4} spacing={2}>
      <Typography variant="h2">{t('lms.shortcuts.title')}</Typography>
      <Box sx={{ width: '100%', '& a': { textDecoration: 'none', color: 'inherit' } }}>
        <List component="nav">
          {shortcuts.map((shortcut) => (
            <RouterLink key={shortcut.name} routeName={shortcut.route}>
              <ListItemButton disabled={shortcut.disabled} id="schortcutBtn" className="ButtonNavigation">
                <ListItemText primary={shortcut.name} />
              </ListItemButton>
            </RouterLink>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

export default ShortCuts;
