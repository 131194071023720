import { types as t, flow, getParent } from 'mobx-state-tree';
import { DateRange } from '../models/BaseModel';

export const Subscription = t.model('Subscription', {
  nid: t.number,
  uuid: t.string,
  product: t.number,
  product_title: t.maybeNull(t.string),
  base_product_id: t.maybeNull(t.number),
  base_product_title: t.string,
  maximum_points: t.number,
  available_points: t.maybeNull(t.number),
  valid_period: t.array(DateRange)
});

export const Subscriptions = t
  .model({
    subscriptions: t.array(Subscription)
  })
  .views((self) => ({
    earnedPoints(bsProductNid) {
      return self.subscriptions
        .filter((subscription) => subscription?.base_product_id === bsProductNid)
        .reduce((points, subscription) => points + subscription.maximum_points - subscription.available_points, 0);
    },
    totalPoints(bsProductNid) {
      return !self.subscriptions
        ? 0
        : self.subscriptions
            .filter((subscription) => subscription?.base_product_id === bsProductNid)
            .reduce((points, subscription) => {
              points += subscription.maximum_points;
              if (points >= 100) {
                return 'Unlimited';
              }
              return points;
            }, 0);
    },
    totalSubscriptionPoints(bsProductNid) {
      if (getParent(self, 1).isEwiseUser) {
        return 'Unlimited';
      }
      if (self.totalPoints(bsProductNid) === 'Unlimited') {
        return `${self.earnedPoints(bsProductNid)} ${self.getLabel(true)}`;
      }
      return `${self.earnedPoints(bsProductNid)}/${self.totalPoints(bsProductNid)} ${self.getLabel(true)}`;
    },
    maxPoints(productNid) {
      return self.subscriptions
        .filter((subscription) => subscription?.product === productNid)
        .reduce((points, subscription) => {
          points += subscription.maximum_points;
          return points;
        }, 0);
    },
    availablePoints(productNid) {
      return self.subscriptions
        .filter((subscription) => subscription?.product === productNid)
        .reduce((points, subscription) => {
          points += subscription.available_points;
          return points;
        }, 0);
    },
    enoughPoints(bsProductNid, courseNid) {
      if (self.totalPoints(bsProductNid) === 'Unlimited') return true;
      const course = getParent(self, 1).findCourseByNid(Number(courseNid));
      const coursePoints = course?.course_points ?? 0;
      return Number(coursePoints) === 0 || (Number(self.totalPoints(bsProductNid)) - Number(self.earnedPoints(bsProductNid)) >= Number(coursePoints));
    },
    maxPointsReached(bsProductNid) {
      if (self.totalPoints(bsProductNid) === 'Unlimited') return false;
      return self.totalPoints(bsProductNid) - self.earnedPoints(bsProductNid) <= 0;
    },
    getLabel(top) {
      const accreditation = top
        ? getParent(self, 1).baseProduct?.packages[0]
        : getParent(self, 1).baseProduct?.packages.find((packag) => packag.nid === getParent(self, 1).selectedPackageId);
      const accrUnit = accreditation?.accreditation?.accr_unit;
      if (accrUnit) {
        return accrUnit;
      }
      return 'uur';
    }
  }))
  .actions((self) => ({
    updateSubscription: flow(function* updateSubscription() {
      try {
        const response = yield getParent(self, 1).lmsApi.fetchSubscriptions();

        self.subscriptions = response.subscriptions;
        return self.subscriptions;
      } catch (e) {
        return e;
      }
    })
  }));
