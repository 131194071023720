import { shuffle } from 'components/widgets/DragDropUtils';
import cloneDeep from 'lodash/cloneDeep';

export const convertToCorrectMatchAnswer = (items) => {
  const correctAnswer = { bench: { items: [] } };
  const DATA = cloneDeep(items);
  DATA.forEach((i) => {
    correctAnswer[`list${i.nr}`] = {
      items: i.matches.map((item) => ({ ...item, order: 'correct' })),
      label: i.label
    };
  });
  return correctAnswer;
};

export const convertDefaultItems = (items, isShuffle = true) => {
  const defaultState = {};
  let dragItems = [];
  const DATA = cloneDeep(items);
  dragItems = DATA.map((item) => item.matches).flat(2);
  DATA.forEach((i) => {
    defaultState[`list${i.nr}`] = {
      items: [],
      label: i.label
    };
  });
  defaultState.bench = { items: isShuffle ? shuffle(dragItems) : dragItems };
  return defaultState;
};

export const includeCorrectWrongOrderToLastResponse = (questionLastResponse) => {
  const newResponse = { bench: { items: [] } };
  const lastResponse = JSON.parse(questionLastResponse);
  delete lastResponse.bench;
  const entriesLastResponse = Object.entries(lastResponse);
  entriesLastResponse.forEach(([list, { items: value, label }]) => {
    newResponse[list] = {
      label,
      items: value.map((item) => ({
        ...item,
        order: Number(list.match(/\d+$/)) === parseInt(item.nr, 10) ? 'correct' : 'wrong' // sonarcloud false positive on rgex
      }))
    };
  });
  return newResponse;
};
