import { CourseEdit } from 'state/models/CourseEdit';
import { createRouterState } from 'mobx-state-router';

// GET EWAPI -------------------------------------------------------
export const getEwapi = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  const fromRoute = fromState.routeName;
  const isInitial = fromRoute === '__initial__';
  const newPackageId = fromState.params.packageId !== toState.params.packageId;
  const newProductId = fromState.params.product !== toState.params.product;

  const productId = toState.params.product;
  const { packageId } = toState.params;

  if (isInitial || newPackageId || newProductId) {
    store.coursesLoaded(false);
    return store
      .fetchData(productId, packageId)
      .then((response) => {
        store.coursesLoaded(true);
        if (response?.data?.error) return Promise.resolve(response);
        return Promise.resolve();
      })
      .catch(() => {
        store.setState('error');
      });
  }
  return Promise.resolve();
};

//
// GET EWAPI -------------------------------------------------------
export const getEwapiForEditors = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  const productId = store?.login?.prefBaseProduct || localStorage.getItem('latestPrefBaseProduct') || 0;
  const packageId = 0;
  if (productId) {
    store.coursesLoaded(false);
    return store
      .fetchData(productId, packageId)
      .then((response) => {
        store.coursesLoaded(true);
        if (response?.data?.error) return Promise.resolve(response);
        return Promise.resolve();
      })
      .catch(() => {
        store.setState('error');
      });
  }
  return Promise.resolve();
};

// RELOAD LESSON FROM COURSE ADMIN IN LESSONPLAY ----------------------
export const reloadAdminLesson = async (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  const fromRoute = fromState.routeName;
  const isReload = fromRoute === '__initial__';
  if (isReload) {
    try {
      await getEwapiForEditors(fromState, toState, routerStore);
      const { data: course } = await store.lmsApi.fetchIt('course', '', toState.params.packageId);
      await createEditStore(fromState, toState, routerStore);
      const newEwapiCourse = store.courseEdit.convertJsonCourseToEwapiCourse(course.data);
      store.courses.addNewCourse(newEwapiCourse);
    } catch (e) {
      console.error('reloadAdminLesson ERROR:', e);
    }
  }
};

// GET EWAPI -------------------------------------------------------
export const specialAccessGetPackages = (fromState, toState, routerStore) => {
  const { params } = toState;
  const { store } = routerStore.options;
  //
  const isInit = store.hasSubscriptions === 'init';
  const fromRoute = fromState.routeName;
  const isInitial = fromRoute === '__initial__';
  //
  const isSpecialAccessRouteName = toState.routeName.includes('specialAccess');
  const productId = isSpecialAccessRouteName ? 0 : params.product;
  const packageId = isSpecialAccessRouteName ? 0 : params.packageId;

  if (isInitial || isInit) {
    store.coursesLoaded(false);
    return store
      .fetchData(productId, packageId)
      .then((response) => {
        store.coursesLoaded(true);
        if (response?.data?.error) return Promise.resolve(response);
        return Promise.resolve();
      })
      .catch(() => {
        store.setState('error');
      });
  }
  return Promise.resolve();
};

// GET SPECIALACCESS COURSES ---------------------------------------
export const getSpecialAccessCourses = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  store
    .fetchSpecialAccessCourses()
    .then(() => {
      store.coursesLoaded(true);

      return Promise.resolve();
    })
    .catch(() => {
      store.setState('error');
      routerStore.goTo('errorPage');
    });
};

// CREATE EDIT STORE -----------------------------------------------
export const createEditStore = async (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  if (store.courseEditState === 'done') return;
  try {
    await store.setCourseEdit(CourseEdit.create({}, { store }));
  } catch (e) {
    console.error('CourseEdit error', e);
  }
};

// GET PROFILE -------------------------------------------------------
export const getProfile = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  store.fetchUser().catch(() => {
    store.setState('error');
    routerStore.goTo('errorPage');
  });
};

// GET CERTIFICATES -------------------------------------------------------
export const getCertificates = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  store.fetchUserCourseResults().catch(() => {
    store.setState('error');
    routerStore.goTo('errorPage');
  });
};

/**
 * Routing guard to check if the current session is
 * authenticated or not
 *
 * @param fromState
 * @param toState
 * @param routerStore
 * @return {Promise<unknown>}
 */
// CHECK USER SIGNED IN ===================================================
export const checkUserSignedIn = (fromState, toState, routerStore) =>
  new Promise((resolve, reject) => {
    const { store } = routerStore.options;

    //
    //
    /**
     * Note to other devs
     * (It is currently impossible to reach this part of the app without valid credentials)
     * so we dont have to have logic bouncing unauthed users anymore
     */
    if (store.state === 'done') {
      resolve();
    }
    if (store.state === 'pending' || store.state === 'init') {
      // Go try to fetch data if credentials are present

      let prefProductId = '0';
      if (store.isEwiseUser && store.hasPreferredBaseproduct) {
        prefProductId = store.preferredBaseproduct;
      }

      if (store.getPrefProduct() > 0) {
        prefProductId = store.getPrefProduct(); // This is to prevent wrong product on refresh my account page
      }

      store
        .fetchData(prefProductId, 0)
        .then(() => {
          store.coursesLoaded(true);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    }
  });

// PREPARE COURSE VIEW ====================================================
export const prepareCourseView = async (fromState, toState, routerStore) => {
  try {
    await checkUserSignedIn(fromState, toState, routerStore); // If rejected (throw) stop and return to router
  } catch (e) {
    return e;
  }
  return null;
};

export const ewiseUserHasPrefBaseproduct = (routerStore) => {
  const { store } = routerStore.options;
  if (store.isEwiseUser && !store.hasPreferredBaseproduct) {
    return createRouterState('selectProduct', { params: { user: store.login.userName } });
  }
  return null;
};

/**
 *
 * @param {*} fromState
 * @param {*} toState
 * @param {*} routerStore
 * @param {string[]} requiredSubscriptions array of subscription names
 * @returns
 */
export const checkSubscription = async (fromState, toState, routerStore, requiredSubscriptions = []) => {
  const { store } = routerStore.options;

  if (!store.hasActiveSubscription(requiredSubscriptions)) {
    store.setState('init');
    return createRouterState('home');
  }

  return null;
};

//
// GET EWAPI SEARCH -------------------------------------------------------
/**
 * Perform EWAPI search based on route state changes.
 * @param {Object} fromState - Previous route state.
 * @param {Object} toState - New route state.
 * @param {Object} routerStore - Router store containing options.
 * @returns {Promise|null} Promise resolving when search completes or null if no action needed.
 */
export const getEwapiSearch = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;

  // Extract old and new parameters from route states
  const oldSearchParam = fromState.params.searchParam;
  const oldBaseProductId = parseInt(fromState.params.baseProductId);
  const oldSearchAttempt = parseInt(fromState.params.searchAttempt);

  const { searchParam, baseProductId, searchAttempt } = toState.params;

  // Determine if parameters have changed
  const hasParamChanged = oldSearchParam !== searchParam;
  const hasBaseProductIdChanged = oldBaseProductId !== parseInt(baseProductId);
  const hasSearchAttemptChanged = oldSearchAttempt !== parseInt(searchAttempt);

  // If any parameter has changed, initiate search
  if (hasParamChanged || hasBaseProductIdChanged || hasSearchAttemptChanged) {
    store.isSearching(true);

    // Fetch search data from store
    return store.fetchSearchData(searchParam, baseProductId, searchAttempt)
      .then(() => {
        store.isSearching(false);
        return null; // Successful search, return null (no further action)
      })
      .catch((error) => {
        window.console.error('Error fetching search data:', error);
        return null; // Return null to propagate the error further if needed
      });
  }

  // Return null if no parameters have changed (no action needed)
  return null;
};
