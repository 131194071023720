import { useState } from 'react';

export const useModal = () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("I'm the Modal Content");

  const handleModal = (content = false) => {
    if (content) {
      setModal(true);
      setModalContent(content);
    } else {
      setModal(false);
    }
  };

  return { modal, handleModal, modalContent };
};
