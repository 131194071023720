import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStore } from 'hooks';
import Dialog from './Dialog';

const ConfirmationModal = ({ title, text, btnLabel, isVisible, onCancel, onConfirm }) => {
  const { t } = useTranslation('curriculum');
  const store = useStore();

  return (
    <Dialog
      title={title}
      name={`confirmation-modal-${title}`}
      visible={isVisible}
      onClose={onCancel}
      action={{
        action: onConfirm,
        name: btnLabel ?? t('general.confirmBtnLabel'),
        isLoading: store.isLoadingCurriculum,
        sx: {
          background: 'rgb(239, 68, 68)',
          '&:hover': {
            background: 'rgb(220, 38, 38)'
          },
          color: '#fff'
        }
      }}
    >
      {text}
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  btnLabel: PropTypes.string
};

export default observer(ConfirmationModal);
