/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

/* MUI */
import { Box, Card, Grid, CardContent, Typography, SvgIcon } from '@mui/material';
import SubscribeTab from 'components/icons/SubscribeTab';

/*
Each subscription card contains 
the product title, 
maximum points, 
and valid period.
 The valid period is displayed as a formatted date using the toLocaleDateString method.
*/
const Subscriptions = () => {
  const store = useContext(Store);
  const { subscriptions } = store;
  const { t } = useTranslation();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  return (
    <Box padding="30px">
      <Typography fontWeight="bold" color="#2B3674" fontSize="20px">
        {t('account.subscription.title')}
      </Typography>
      <Box className="account_subscriptions">
        <Grid container spacing={2} alignItems="center" paddingTop="0px" marginTop="0px">
          {subscriptions?.subscriptions ? (
            subscriptions.subscriptions.map((subscription, index) => (
              <Grid item xs={12} sm={12} key={index}>
                <Card className="subscription_card">
                  <CardContent>
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h1" color="#2B3674" fontSize="14px" fontWeight="700">
                          {subscription?.product_title || subscription?.base_product_title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3} className="subscription_assistent">
                        <Typography fontSize="14px" variant="h1" color="#2B3674" fontWeight="700">
                          {t('account.subscription.points')}
                        </Typography>
                        <Typography fontSize="14px" color="#2B3674">
                          {subscription?.maximum_points === 1000 ? 'Unlimited' : subscription?.maximum_points}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={5} className="typography_box">
                        <Typography variant="h1" fontSize="14px" color="#2B3674" fontWeight="700">
                          {t('account.subscription.valid')}
                        </Typography>
                        <Typography fontSize="14px" color="#2B3674">
                          {new Date(subscription?.valid_period[0].end_value).toLocaleDateString('nl-NL', options) || '12 November 2022'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} sm={12}>
              <Box display="flex" flexDirection="column" gap="23px" height="23vh" alignItems="center" justifyContent="center">
                <SvgIcon component={SubscribeTab} color="primaryBlue" />
                <Typography variant="h1" color="#2B3674" fontSize="14px" fontWeight="700">
                  {t('account.subscription.none')}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default observer(Subscriptions);
