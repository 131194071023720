import { SvgIcon } from '@mui/material';

const SubscribeTab = (props) => (
  <SvgIcon {...props} width="22" height="26" viewBox="0 0 16 16">
    <path d="M15.1918 9.61664C15.1918 9.06085 14.7371 8.62298 14.1982 8.62298C13.8444 8.62298 13.5245 8.80825 13.3561 9.09456V9.06084C13.3561 8.50505 12.9013 8.06718 12.3624 8.06718C12.0087 8.06718 11.6888 8.25245 11.5203 8.53877V8.50505C11.5203 7.94925 11.0655 7.51139 10.5266 7.51139C10.1729 7.51139 9.85301 7.69666 9.68452 7.98297V4.63137C9.68452 4.36187 9.58349 4.10929 9.39822 3.924C9.21295 3.73873 8.96035 3.6377 8.69085 3.6377C8.13506 3.6377 7.69719 4.09247 7.69719 4.63136V11.1493C7.69719 11.1998 7.66347 11.2503 7.61295 11.284C7.56244 11.3008 7.51192 11.3008 7.4614 11.2503L6.45081 10.2397C6.11399 9.90292 5.57498 9.85239 5.17083 10.1218C4.93505 10.2733 4.78349 10.526 4.73296 10.8123C4.69925 11.0986 4.76668 11.3849 4.95195 11.6039L7.46147 14.6355C8.16884 15.4944 9.22993 15.9997 10.3415 15.9997H11.9078C12.7836 15.9997 13.6088 15.6629 14.2321 15.0396C14.8552 14.4165 15.1921 13.5912 15.1921 12.7154L15.1918 9.61664Z" />
    <path d="M6.56852 4.63164C6.56852 3.4695 7.51165 2.52636 8.6738 2.52636C9.22959 2.52636 9.7517 2.74535 10.1559 3.13268C10.5602 3.53692 10.7791 4.05903 10.7791 4.61483V5.76004H11.9749C12.5139 5.76004 12.9518 5.32217 12.9518 4.78319L12.9517 0.976848C12.9517 0.437837 12.5138 0 11.9748 0H0.976848C0.437837 0 0 0.437867 0 0.976848V4.80003C0 5.33904 0.437867 5.77688 0.976848 5.77688H6.56846L6.56852 4.63164ZM5.37266 2.35788L3.77268 4.07581C3.68845 4.17685 3.5537 4.22737 3.41894 4.22737C3.28418 4.22737 3.16635 4.17685 3.06519 4.07581L2.22308 3.1664C2.03781 2.96432 2.05461 2.6611 2.2568 2.47583C2.45887 2.30736 2.76209 2.30736 2.94736 2.50955L3.43572 3.04856L4.68207 1.70116C4.86734 1.49909 5.17044 1.49909 5.37263 1.66744C5.5411 1.85271 5.5579 2.1558 5.37263 2.35788H5.37266Z" />
  </SvgIcon>
);

export default SubscribeTab;
