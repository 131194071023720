import MainLayout from 'pages/mainLayout/MainLayout';
import TeacherDetail from './TeacherDetail';

const teacherDetailViewMap = {
  teacherDetail: (
    <MainLayout subtitle="" title="teachers.title.bio">
      <TeacherDetail />
    </MainLayout>
  )
};

export default teacherDetailViewMap;
