import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
/* Custom styling */
import { Box, Alert, AlertTitle } from '@mui/material';
import { ThemeBrandTitle } from 'helpers/Helpers';

/**
 * This component is responsible for displaying an introductory message from a package to the user, providing the option to access more information in a popup dialog, and managing the presentation of additional content.
 */
const AccreditationNotice = () => {
  const { t } = useTranslation();
  const brandTitle = ThemeBrandTitle();

  const cssMargin = '16px';
  const { u, kunt } = brandTitle !== 'E-WISE' ? { u: 'U', kunt: 'kunt', heeft: 'heeft' } : { u: 'Je', kunt: 'kan', heeft: 'hebt' };

  return (
    <Box className="course_overview_intro_accr" sx={{ marginTop: cssMargin }}>
      <Alert severity="info" variant="filled" color="info" sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <AlertTitle
          sx={{
            margin: '0px',
            '&::first-letter': {
              textTransform: 'capitalize'
            },
            textTransform: 'lowercase'
          }}
        >
          {t('searchoverview.intro.accr', { u, kunt })}
        </AlertTitle>
      </Alert>
    </Box>
  );
};
export default observer(AccreditationNotice);
