/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

/* MUI */
import { Dialog, DialogContent, Divider, DialogActions, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/* Custom styling */
import 'theme/course/courseoverview/Quickview.scss';
import Logo from '../../../components/common/Logo';

/* Custom components */

const TermsConditions = (props) => {
  const { open, onClose } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        className="general_dialog"
        fullScreen={fullScreen}
      >
        <Box display="flex" gap="10px" justifyContent="start" alignItems="center">
          <Logo style={{ maxWidth: '62px' }} />
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography variant="h2" fontSize="23px" color="primaryBlue.main">
              General Terms
            </Typography>
          </Box>
        </Box>
        <Divider style={{ borderColor: '#a3aed0', borderBottomWidth: 'initial' }} />
        <DialogContent>
          <Box className="course_overview_quickview_other" component="div">
            <Typography component="p" marginBottom="1em">
              <a
                target="_blank"
                href="https://proef.e-wise.nl/files/2023/01/algemene_voorwaarden_e-wise_groep_e-learning_made_easy_24_feb_2023.pdf"
                rel="noreferrer"
              >
                Algemene voorwaarden beroep en bedrijf
              </a>
            </Typography>
            <Typography component="p" marginBottom="1em">
              Voor iedereen die cursussen van E-WISE volgt voor de verplichte herregistratie in zijn beroepsregister.
            </Typography>
            <Typography component="p" marginBottom="1em">
              <a target="_blank" href="https://www.nrto.nl/wp-content/uploads/Algemene-Voorwaarden-november-2020-1.pdf" rel="noreferrer">
                Algemene Voorwaarden Consumenten voor Particulier Onderwijs en Opleidingen (NRTO){' '}
              </a>
            </Typography>
            <Typography component="p" marginBottom="1em">
              Voor iedereen die cursussen van E-WISE volgt niet vanuit een professionele verplichting.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TermsConditions.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default observer(TermsConditions);
