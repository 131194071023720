/* eslint-disable camelcase */
import { types as t, getType, resolveIdentifier, getParent } from 'mobx-state-tree';
import { RelCourses } from './Course';
import { Links, RelCategory, Category, Profession } from './BaseModel';

window.getType = getType;
window.resolveIdentifier = resolveIdentifier;
window.getParent = getParent;
window.Category = Category;

export const CourseContainer = t
  .model('CourseContainer', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__nid: t.number,
      status: t.boolean,
      title: t.string,
      created: t.string,
      changed: t.string
    }),
    links: t.maybeNull(Links),
    relationships: t.model({
      category: RelCategory,
      category2: RelCategory,
      profession: t.maybeNull(
        t.model({
          data: t.maybeNull(Profession)
        })
      ),
      courses: RelCourses
    })
  })
  .views((self) => ({
    // get attributes(){ return self.data.attributes },
    get profession() {
      return self.relationships.profession.data.attributes.name;
    },
    get professionParentId() {
      const { field_categories_parent_term } = self.relationships.profession.data.relationships;
      return field_categories_parent_term.data.meta.drupal_internal__target_id;
    },
    get professionParentId2() {
      const { field_categories2_parent_term } = self.relationships.profession.data.relationships;
      return field_categories2_parent_term.data.meta.drupal_internal__target_id;
    },
    get courses() {
      if (self.relationships.courses) return self.relationships.courses.courserefs;
      return [];
    }

    // get coursesByCategory(){
    //   // Computed properties get automatically cached by Mobx. First time: 8ms / second time: 0,2ms
    //   let categories = [];
    //   self.courses.forEach(course => {
    //     course.relationships.course_category.data.forEach(c => {
    //       if(!categories[c.id.attributes.name]) categories[c.id.attributes.name] = [];
    //       categories[c.id.attributes.name].push(course);
    //     });
    //   });
    //   return categories;
    // }
  }));

export const RelCourseContainer = t.model('RelCourseContainer', {
  data: t.maybeNull(
    t.model({
      id: t.reference(CourseContainer),
      type: t.string
    })
  )
});
