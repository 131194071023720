import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import LoadingPage from '../../components/common/LoadingPage';

export const GoToAdmin = observer(() => {
  const inProgress = useRef(false);

  useEffect(() => {
    if (!inProgress.current) {
      inProgress.current = true;
      window.location.href = `${window.clientInstance.authorizationDomain}/node/add`;
    }
  });

  return <LoadingPage />;
});
