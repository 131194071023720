import { observer } from 'mobx-react';
import { Box } from '@mui/material';

import { useState } from 'react';
import { FilterBar } from './FilterBar';
import MidContentSection from './MidContentSection/MidContentSection';
import BottomDescription from './BottomDescription';

const VTBLayout = () => {
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  return (
    <Box
      sx={{
        display: 'flex',
        paddingInline: { xs: '0.25rem', md: '1rem' },
        paddingBlock: '1rem',
        marginTop: { xs: '15rem', md: '4rem' },
        flexDirection: 'column',
        gap: '0.625rem'
      }}
    >
      <FilterBar
        onlyShowAvailableChange={showOnlyAvailable}
        onOnlyShowAvailableChange={setShowOnlyAvailable}
        year={year}
        onYearChange={setYear}
      />
      <MidContentSection showOnlyAvailable={showOnlyAvailable} year={year} />
      <BottomDescription />
    </Box>
  );
};
export default observer(VTBLayout);
