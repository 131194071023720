import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStore } from 'hooks';
import { MySelect, MyTextField } from 'components/form/RHF';
import * as Styles from '../../../components/styles';

const ActivitiesFormPart = ({ control, errors, setValue }) => {
  const { t } = useTranslation('curriculum');
  const store = useStore();
  const classes = Styles.useCurriculumStyles();

  const learningGoalActivityExamples = store.curriculumLearningGoalActivityExamples?.map((item) => ({
    name: item.attributes.description.value,
    value: item.attributes.description.value
  }));

  return (
    <>
      <h3 style={{ fontWeight: 600 }}>{t('learningGoals.addLearningGoalActivityTitle')}</h3>
      <p>{t('learningGoals.addLearningGoalActivityNameTextfieldDescription')}</p>

      <MySelect
        control={control}
        label={t('learningGoals.selectActivityDropdownLabel')}
        id="activityDropDown"
        name="activityDropDown"
        items={learningGoalActivityExamples}
        variant="outlined"
        fullWidth
        sx={Styles.SelectBox}
        sxMenuItem={{ textWrap: 'wrap', maxWidth: '560px' }}
        helperText={errors?.activityDropDown?.message}
        error={!!errors?.activityDropDown}
        onChange={(e) => {
          setValue('activityDropDown', e.target.value);
          setValue('activityName', '');
        }}
        inputProps={{ classes: { paper: classes.mySelect } }}
      />

      <MyTextField
        control={control}
        label={t('learningGoals.addLearningGoalActivityNameTextfieldLabel')}
        name="activityName"
        variant="outlined"
        fullWidth
        placeholder={t('learningGoals.addLearningGoalActivityNameTextfieldplaceholder')}
        sx={Styles.TextField}
        multiline
        rows={5}
        helperText={errors?.activityName?.message}
        error={!!errors?.activityName}
        onChange={() => {
          setValue('activityDropDown', '');
        }}
      />

      <MyTextField
        control={control}
        label={t('learningGoals.addLearningGoalActivityExplanationTextfieldLabel')}
        name="activityExplanation"
        variant="outlined"
        fullWidth
        placeholder={t('learningGoals.addLearningGoalActivityExplanationTextfieldPlaceholder')}
        multiline
        sx={Styles.TextField}
        rows={5}
        helperText={errors?.activityExplanation?.message}
        error={!!errors?.activityExplanation}
      />
    </>
  );
};

ActivitiesFormPart.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  setValue: PropTypes.func
};

export default observer(ActivitiesFormPart);
