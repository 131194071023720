import { LessonArrayProgress, LessonProgress } from 'helpers/Helpers';

export function calculateCourseProgress(lessonProgressArray, lessons) {
  let total = 0;

  lessonProgressArray.forEach((lesson) => {
    total += lesson / lessons.length;
  });

  return Math.round(total);
}

export function calculateProgressValue({ lessonResult, isCourseFinished, isCourseStarted, lessons }) {
  let progress = 0;

  if (lessons && lessons.length === 1) {
    progress = LessonProgress(lessonResult[0]);
  } else {
    const progressArray = lessons && LessonArrayProgress(lessonResult, lessons);
    progress = progressArray && calculateCourseProgress(progressArray, lessons);
  }
  if (progress < 2 && !isCourseStarted) {
    return 0;
  }

  let progressNumber = progress || 0;

  if (isCourseFinished) {
    progressNumber = 100;
  } else if (progress === 100 && !isCourseFinished) {
    progressNumber = 99;
  }
  return progressNumber;
}
