import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
/*
In summary, the Form component provides an abstraction for managing forms in a React application. 
It leverages the react-hook-form library for efficient form management, validation, and submission. 
The component allows flexibility in defining form content and behavior through the children render prop. 
Additionally, it incorporates local storage for data persistence and offers built-in form reset functionality.
*/
const Form = (props) => {
  const { children, onSubmit, initialValues, validationSchema, id, formProps, storageKey } = props;

  const {
    control,
    watch,
    handleSubmit,
    onBlur,
    setValue,
    getValues,
    getFieldState,
    reset,
    resetField,
    register,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, isDirty, dirtyFields }
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });

  const handleSubmitWithReset = async (values) => {
    const result = await onSubmit(values);

    if (result?.success === false) {
      result.errors.forEach((err) => {
        if (!err.status) {
          setError(err.name, err.error, err.options || {});
        } else {
          // basic API server error handling
          const status = parseInt(err.status, 10);
          switch (true) {
            case status >= 500:
              setError('root.serverError', { type: 'server', message: 'Er is helaas iets fout gegaan op de server.' });
              break;

            case status === 422:
              setError('root.serverError', { type: 'server', message: 'Er is helaas iets mis met de ingevoerde data.' });
              break;

            default:
              setError('root.serverError', { type: 'server', message: 'Er is helaas iets fout gegaan.' });
              break;
          }
        }
      });
    } else {
      localStorage.removeItem(storageKey);
      reset(values);
    }
  };

  return (
    <form id={id} {...formProps} onSubmit={handleSubmit(handleSubmitWithReset)}>
      {errors?.root?.serverError && (
        <FormHelperText sx={{ marginBottom: '24px' }} className="Mui-error">
          {errors.root.serverError.message}
        </FormHelperText>
      )}

      {children({
        clearErrors,
        control,
        dirty: isDirty,
        dirtyFields,
        errors,
        getValues,
        isSubmitting,
        onBlur,
        register,
        reset,
        resetField,
        setError,
        setValue,
        values: initialValues,
        watch,
        getFieldState
      })}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  formProps: PropTypes.object,
  id: PropTypes.string,
  storageKey: PropTypes.string
};

export default observer(Form);
