import { types as t } from 'mobx-state-tree';
import { RelCourse } from './Course';
import { Links, RelProfession, IsoDate } from './BaseModel';
import { RelUser } from './User';
import { RelSubscription } from './Subscription';

export const CourseResult = t
  .model('CourseResult', {
    id: t.string,
    type: t.string,
    attributes: t.model({
      drupal_internal__id: t.number,
      name: t.string,
      created: IsoDate,
      changed: IsoDate,
      completed: IsoDate,
      certificate: IsoDate,
      points: t.string,
      is_accredited: t.maybeNull(t.boolean),
      data: t.maybeNull(t.string)
    }),
    links: t.maybeNull(Links),
    relationships: t.model({
      subscription: RelSubscription,
      profession: RelProfession,
      user: RelUser,
      course: RelCourse
    })
  })
  .views((self) => ({
    get isAccreditated() {
      return self.attributes.is_accredited;
    }
  }));

export const RelCourseResult = t.model('RelCourseResult', {
  data: t.maybeNull(
    t.model({
      id: t.reference(CourseResult),
      type: t.string
    })
  ),
  links: Links
});

export const RelCourseResults = t.model('RelCourseResult', {
  data: t.maybeNull(
    t.array(
      t.model({
        id: t.reference(CourseResult),
        type: t.string
      })
    )
  ),
  links: Links
});
