import { types as t } from 'mobx-state-tree';
// import { RelAccreditation } from './Accreditation';
import { Body, Links, RelProfession } from './BaseModel';
import { RelPackages } from './Package';

export const BaseProduct = t
  .model('BaseProduct', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__nid: t.number,
      drupal_internal__vid: t.number,
      unique_name: t.string,
      status: t.boolean,
      title: t.string,
      body: t.maybeNull(Body)
    }),
    relationships: t.model({
      packages: RelPackages,
      profession: t.maybe(RelProfession)
      // accreditation_organisation: RelAccreditation,
      // ewiselabels: t.maybe(RelProfession)
    }),
    links: Links
  })
  .volatile(() => ({
    packageClicked: [false, false, false, false]
  }))
  .views((self) => ({
    get profession() {
      if (self.relationships.profession) return self.relationships.profession.data.id.attributes.name;
      return '';
    },
    get packages() {
      if (self.relationships.packages) return self.relationships.packages.packagerefs;
      return [];
    },
    get coursesByPackage() {
      return self.packages.map(
        (packages) =>
          packages.relationships.course_container.data.id &&
          packages.relationships.course_container.data.id.relationships.courses.data.map((course) => course).length
      );
    },
    get ewiselabels() {
      if (self.relationships.profession) {
        return self.relationships.profession.data.id.attributes.field_ewise_labels !== null
          ? self.relationships.profession.data.id.attributes.field_ewise_labels
          : 'ewise';
      }
      return [];
    }
  }))
  .actions((self) => ({
    addPackageRef(packageRef) {
      self.relationships.packages.data.push(packageRef);
    },
    setArray(index) {
      self.packageClicked[index] = true;
      return self.packageClicked;
    }
  }));

export const RelBaseProduct = t.model('RelBaseProduct', {
  data: t.maybeNull(
    t.model({
      id: t.reference(BaseProduct),
      type: t.string
    })
  )
});
