import MainLayout from 'pages/mainLayout/MainLayout';
import Accountv2 from '../Accountv2';
import Certificates from '../components/Certificates';

const CertificatesViewMap = {
  certificates: (
    <MainLayout title="account.certificate.tab" subtitle="">
      <Accountv2>
        <Certificates />
      </Accountv2>
    </MainLayout>
  )
};

export default CertificatesViewMap;
