/* Core */
import { forwardRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
/* MUI */
import { Box, Stack, Grid, Button, Dialog, DialogContent, DialogActions, Slide } from '@mui/material';
/* Custom */
import CourseInformation from '../previewIntroduction/CourseInformation';
import CoursePodcast from '../previewIntroduction/CoursePodcast';
import CourseVideo from '../previewIntroduction/CourseVideo';
import CourseGoals from '../previewIntroduction/CourseGoals';
import CourseImage from '../previewIntroduction/CourseImage';
import CourseImageHeader from '../previewIntroduction/CourseImageHeader';
import CourseDetails from '../previewIntroduction/CourseDetails';
import CourseAuthors from '../previewIntroduction/CourseAuthors';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

/*
  In summary, the IntroductionPreviewDialog component is used to display a preview of course information within a dialog. 
  It's well-structured and visually organized, making it user-friendly. Users can view various course details, and when they're done, they can close the dialog with the "Close" button. 
  This component is likely part of a larger system for managing and previewing course content.
*/
const IntroductionPreviewDialog = (props) => {
  const { open, handleClickDialog, previewValues } = props;

  if (!open) return null;
  return (
    <Dialog
      sx={previewDialog}
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xl"
      open={open}
      onClose={handleClickDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box id="alert-dialog-description">
          <CourseImageHeader previewValues={previewValues} />
          <Grid container spacing={1} sx={{ position: 'relative' }}>
            <Grid item xs={12} sm={7} md={7} lg={8}>
              <Stack spacing={2}>
                <CourseInformation previewValues={previewValues} />
                <CoursePodcast previewValues={previewValues} />
                <CourseVideo previewValues={previewValues} />
                <CourseGoals previewValues={previewValues} />
                <CourseAuthors previewValues={previewValues} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={4}>
              <Box className="preview_box_right">
                <CourseImage previewValues={previewValues} />
                <CourseDetails previewValues={previewValues} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickDialog} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

IntroductionPreviewDialog.propTypes = {
  open: PropTypes.bool,
  handleClickDialog: PropTypes.func,
  previewValues: PropTypes.object
};

export default observer(IntroductionPreviewDialog);

const previewDialog = {
  '.MuiDialog-paper': { borderRadius: '20px' },
  '.preview_image_header': {
    position: 'relative',
    maxHeight: '300px',
    overflow: 'hidden',
    borderRadius: '20px',
    background: 'linear-gradient(90deg,#002318,#003927 38.54%,rgba(0,104,72,0))',
    '.preview_title': { position: 'absolute', top: '50%', left: '10px', color: 'white', fontSize: '30px', fontWeight: 'bold' }
  },
  '.preview_box_right': {
    marginTop: '-250px',
    marginRight: '30px',
    borderRadius: '10px',
    padding: '20px',
    background: '#f4f4f4',
    '.preview_image_course': { maxHeight: '200px', overflow: 'hidden', borderRadius: '10px' }
  }
};
