// shuffle questions that have same widget code based on a seed
export function shuffledQuestionWidgetsArray(array, seed) {
  const groupMap = {};

  array.forEach((obj) => {
    const { widget_code: widgetCode } = obj.attributes;

    if (!groupMap[widgetCode]) {
      groupMap[widgetCode] = [];
    }
    groupMap[widgetCode].push(obj);
  });
  const shuffledArray = [];

  Object.keys(groupMap).forEach((widgetCode) => {
    const group = groupMap[widgetCode];
    const seededRandom = () => {
      const max = 1;
      const min = 0;
      seed = (seed * 9301 + 49297) % 233280;
      const rnd = seed / 233280;
      return min + rnd * (max - min);
    };

    for (let i = group.length - 1; i > 0; i--) {
      const j = Math.floor(seededRandom(seed) * (i + 1));
      [group[i], group[j]] = [group[j], group[i]];
    }
    shuffledArray.push(group[0]);
  });
  return shuffledArray;
}
