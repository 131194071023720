import { ThemeBrandTitle } from 'helpers/Helpers';
import { createEditStore } from 'state/RoutesActions';

/*
In summary, offlineCoursesViewRoute is a route configuration object that defines a route for viewing offline courses in a web application.
It specifies the URL pattern, title, and functions to execute before entering and when entering the route.
This route object can be used with a routing library or framework to navigate to the "View Offline Courses" page.
*/
export const offlineCoursesViewRoute = {
  name: 'adminOfflineCourses',
  pattern: '/admin/:package/:packageNid/:containerNid/offline',
  title: `View Offline Courses | ${ThemeBrandTitle()}`,
  meta: { title: `View Offline Courses | ${ThemeBrandTitle()}` },
  beforeEnter: createEditStore,
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    store.courseEdit.setStoreValue('drawerOpen', false);
  }
};
