import MainLayout from 'pages/mainLayout/MainLayout';
import LmsLayout from '../LmsLayout';
import LmsOrganisations from './LmsOrganisations';

const LmsOrganisationsViewMap = {
  LmsOrganisations: (
    <MainLayout title="lms.navbar.organisation" subtitle="">
      <LmsLayout>
        <LmsOrganisations />
      </LmsLayout>
    </MainLayout>
  )
};

export default LmsOrganisationsViewMap;
