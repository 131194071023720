export const _headers = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
  'Cache-Control': 'no-cache'
};
export const _headersUpload = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/octet-stream',
  'Cache-Control': 'no-cache'
};
