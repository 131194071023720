/* Core */
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStore } from 'hooks';
import { Controller } from 'react-hook-form';
// MUI
import { Grid, DialogContent, Box, Backdrop, CircularProgress } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
/* Custom RHF components */
import { Form } from 'components/form';
import { MyTextField, Editor } from 'components/form/RHF';
import FormActionBar from './FormActionBar';
// helpers
import {
  textFieldProps,
  numericalProps,
  toggleDialogEditWidget,
  ToastWidgetSubmit,
  defaultOpenQuestionWidgetData,
  RadioSchema
} from './formWidgets.helper';
//

const FormOpenQuestion = () => {
  const store = useStore();
  const [saving, setSaving] = useState(false);

  const widget = store.courseEdit.setEditWidget;
  const widgetData = JSON.parse(store.courseEdit.setEditWidget.attributes.widget_data);
  const setWidgetData = { ...defaultOpenQuestionWidgetData, ...widgetData };
  const defaultValues = { ...widget, attributes: { ...widget.attributes, widget_data: setWidgetData } };
  defaultValues.attributes.widget_type = 'Open Question';

  // TOAST NOTIFICATIONS ________________________________________________
  const toast = ToastWidgetSubmit();

  // ON SUBMIT / HANDLE SUBMIT __________________________________________
  const handleFormSubmit = async (newWidget) => {
    setSaving(true);
    try {
      await store.courseEdit.patchPostWidget(newWidget);
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
      setSaving(false);
      toggleDialogEditWidget(store);
    } catch (error) {
      setSaving(false);
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
      toggleDialogEditWidget(store);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} initialValues={defaultValues} validationSchema={RadioSchema}>
      {({ control, errors, dirty, reset, setValue }) => (
        <>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={saving}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent sx={{ padding: '0px 24px' }}>
            {/* top */}
            <Grid container spacing={1} sx={sxGrid}>
              <Grid item xs={10}>
                <Box sx={sxFlexColumnGap}>
                  <MyTextField
                    {...textFieldProps}
                    label="Title"
                    name="attributes.title"
                    control={control}
                    error={errors?.attributes?.title}
                    helperText={errors?.attributes?.title && errors.attributes.title?.message}
                  />
                  <Box className="container-editor">
                    <Box>
                      <Editor label="question" field={{ name: 'attributes.widget_data.question' }} form={{ setValue, control }} />
                    </Box>
                  </Box>
                  <Box className="container-editor">
                    <Box>
                      <Editor label="feedback" field={{ name: 'attributes.widget_data.feedback' }} form={{ setValue, control }} />
                    </Box>
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="attributes.widget_data.options.type"
                      defaultValue=""
                      render={({ field }) => (
                        <RadioGroup {...field} sx={{ flexDirection: 'row' }}>
                          <FormControlLabel value="Open" control={<Radio />} label="Open" />
                          <FormControlLabel value="Reflect" control={<Radio />} label="Reflect" />
                        </RadioGroup>
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box sx={sxFlexColumnGap}>
                  <MyTextField {...textFieldProps} name="attributes.widget_type" control={control} disabled />
                  <MyTextField {...numericalProps} name="attributes.weight" label="weight" control={control} />
                  <MyTextField {...textFieldProps} name="attributes.widget_code" label="Widget code" control={control} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <FormActionBar reset={reset} dirty={dirty} defaultValues={defaultValues} saving={saving} />
        </>
      )}
    </Form>
  );
};

export default observer(FormOpenQuestion);

const sxFlexColumnGap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
};
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};
