/* eslint-disable no-template-curly-in-string */

const types = {
  number: 'een numerieke waarde',
  bool: 'Ja / Nee',
  string: 'een alphanumerieke waarde',
  array: 'een lijst van waarden',
  object: 'een object',
  date: 'een geldige datum'
};

const getTypeTranslation = (type) => types[type] || `een "${type}" type`;

// Based on https://github.com/jquense/yup/blob/b940eef48eb7456622ae384d0ffa7363d4fbad25/src/locale.ts
export const mixed = {
  default: 'De waarde is ongeldig.',
  required: 'Dit is een vereist veld',
  defined: 'Dit veld moet worden gedefinieerd',
  notNull: 'Dit veld kan niet leeg zijn',
  oneOf: 'De waarde moet een van de volgende waarden zijn: ${values}',
  notOneOf: 'De waarde mag niet een van de volgende waarden zijn: ${values}',
  notType: ({ type }) => `De waarde moet ${getTypeTranslation(type)} zijn`
};

export const string = {
  length: 'De waarde moet precies ${length} tekens zijn',
  min: 'De waarde moet op zijn minst ${min} tekens zijn',
  max: 'De waarde moet maximaal ${max} tekens zijn',
  matches: 'De waarde moet overeenkomen met: "${regex}"',
  email: 'De waarde moet een geldige e-mail zijn',
  url: 'De waarde moet een geldige URL zijn',
  uuid: 'De waarde moet een geldige uuid zijn',
  trim: 'De waarde mag geen begin- of eindspaties bevatten',
  lowercase: 'De waarde mag alleen bestaan uit kleine letters',
  uppercase: 'De waarde mag alleen bestaan uit hoofdletters'
};

export const number = {
  min: 'De waarde moet groter dan of gelijk zijn aan ${min}',
  max: 'De waarde moet kleiner dan of gelijk zijn aan ${max}',
  lessThan: 'De waarde moet minder zijn dan ${less}',
  moreThan: 'De waarde moet groter zijn dan ${more}',
  positive: 'De waarde moet een positief getal zijn',
  negative: 'De waarde moet een negatief getal zijn',
  integer: 'De waarde moet een geheel getal zijn'
};

export const date = {
  min: 'De datum moet later zijn dan ${min}',
  max: 'De datum moet eerder zijn dan ${max}'
};

export const boolean = {
  isValue: 'De waarde veld moet ${value} zijn'
};

export const object = {
  noUnknown: 'De waarde mag geen waarden bevatten die niet zijn opgegeven in het object'
};

export const array = {
  min: 'De waarde moet ten minste ${min} items bevatten',
  max: 'De waarde mag niet meer dan ${max} items bevatten',
  length: 'De waarde moet ${length} items hebben'
};
