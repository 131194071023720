import { IconButton, Tooltip } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFavourites } from './useFavourites';

export const FavoriteButton = ({ propsBasicCard, isCourseFavorite }) => {
  const { handleFavorite, isLoading } = useFavourites();
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        isCourseFavorite
          ? t('course.tooltips.favourite.remove', 'Remove from favourites')
          : t('course.tooltips.favourite.add', 'Add to favourites')
      }
      arrow
    >
      <IconButton
        aria-label="add to favorites"
        onClick={(event) => {
          event.stopPropagation();
          handleFavorite(propsBasicCard.id, propsBasicCard.nid);
        }}
        disabled={isLoading}
        style={isLoading ? { transform: 'scale(1)', animation: 'pulse 1s infinite' } : {}}
      >
        <FavoriteIcon color={isCourseFavorite ? 'favoriteRed' : ''} />
      </IconButton>
    </Tooltip>
  );
};

FavoriteButton.propTypes = {
  propsBasicCard: PropTypes.any,
  isCourseFavorite: PropTypes.bool
};
