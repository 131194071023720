import MainLayout from 'pages/mainLayout/MainLayout';
import Accountv2 from '../Accountv2';
import Subscriptions from '../components/Subscriptions';

const SubscriptionViewMap = {
  subscription: (
    <MainLayout title="account.subscription.title" subtitle="">
      <Accountv2>
        <Subscriptions />
      </Accountv2>
    </MainLayout>
  )
};

export default SubscriptionViewMap;
