/* Core */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
/* MUI */
import { Box, Typography } from '@mui/material';
import CourseList from './Grid/CourseTable/CourseList';
import TrialCoursesBaseProdIntroText from './TrialCoursesBaseProdIntroText';

const TrialCourses = () => {
  const { t } = useTranslation();
  const store = useStore();
  const { trialCourses } = store;

  if (!trialCourses) return null;

  return (
    <Box sx={{ marginTop: '8px', padding: '0px', paddingRight: '16px' }} className="trialCoursesOverview">
      <Box>
        <Typography sx={{ fontSize: '32px', padding: '0px 16px' }} variant="h2" component="h2" color="primary">
          {t('trials.coursesView.trialCourses')}: {store.trialCourses.length}
        </Typography>
      </Box>
      <CourseList courses={trialCourses} trialCoursesView />
      <TrialCoursesBaseProdIntroText />
    </Box>
  );
};

// TrialCourses.propTypes = {
//   current: PropTypes.string
// };

export default observer(TrialCourses);
