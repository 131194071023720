/**
 * Allows for nested properties to be accessed
 *
 * @param {*} propName - the property name to access, for example 'name', 'attributes.name', or 'attributes.child.name'
 * @param {*} el - the element to access the property from, for example { id: 1, type: 'user', attributes: { name: 'John Doe' } }
 * @returns
 */
export const getObjectPropValue = (propName, el) => {
  if (propName.indexOf('.') === -1) {
    return el[propName];
  }

  return propName.split('.').reduce((acc, val) => {
    if (!acc) return undefined;

    if (Object.hasOwn(acc, val)) {
      return acc[val];
    }

    return undefined;
  }, el);
};
