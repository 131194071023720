import MainLayout from 'pages/mainLayout/MainLayout';
import SelectProduct from './SelectProduct';

const selectProductViewMap = {
  selectProduct: (
    <MainLayout noSidebar title="" subtitle="">
      <SelectProduct />
    </MainLayout>
  )
};

export default selectProductViewMap;
