import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const TableTotal = () => {
  const { t } = useTranslation('editCourseForm');
  const store = useStore();
  const title = `${t('tabFeedback.total')} ${t('tabFeedback.evaluations')}: ${store.courseEdit.tableTotal}`;
  return (
    <Box className="box-left" sx={{ flexGrow: 1 }}>
      {title}
    </Box>
  );
};

export default observer(TableTotal);
