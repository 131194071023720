import { useEffect, useState } from 'react';

export const useAccordion = (id) => {
  const defaultValue = window.localStorage.getItem(id) === 'true' || false;
  const [hasState, toggleState] = useState(defaultValue);

  useEffect(() => {
    if (hasState) {
      window.localStorage.setItem(id, hasState);
    } else {
      window.localStorage.removeItem(id);
    }
  }, [id, hasState]);

  return { hasState, toggleState };
};
