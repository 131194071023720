import MainLayout from 'pages/mainLayout/MainLayout';
import CourseOverview from './CourseOverview';

const courseOverviewViewMap = {
  courseOverview: (
    <MainLayout footer title="courseoverview.title">
      <CourseOverview />
    </MainLayout>
  )
};

export default courseOverviewViewMap;
