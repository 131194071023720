import { useState, useEffect } from 'react';

/**
 * Make a fetch request using the jsonapi endpoint
 *
 * @param node
 * @param filter
 * @returns {{response: unknown, error: unknown, loading: boolean}}
 */
const useJsonApi = (node, filter) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const doFetch = async () => {
      setLoading(true);
      try {
        const resp = await window.authedClient.get(`jsonapi/${node}?${filter}`);
        setResponse(resp.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    doFetch();
  }, [filter, node]);
  return { response, error, loading };
};
export default useJsonApi;
