import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TabsLayout from '../components/TabsLayout';

const LmsDashboardLayout = ({ children }) => {
  const { t } = useTranslation();

  const mapRouteNameToTitle = {
    LmsDashboardOverview: t('lms.dashboard.overview.title'),
    // LmsDashboardProfessions: t('lms.dashboard.professions.title'),
    LmsDashboardProducts: t('lms.dashboard.products.title')
  };

  return (
    <TabsLayout id="tabsLayout" tabsRoutes={mapRouteNameToTitle}>
      {children}
    </TabsLayout>
  );
};

LmsDashboardLayout.propTypes = {
  children: PropTypes.node
};

export default observer(LmsDashboardLayout);
