import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useState, useEffect, useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/* MUI components */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Divider, Box, Grid, Avatar, Stack, Backdrop, CircularProgress } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import LinearProgress from '@mui/material/LinearProgress';
// ICONS
import UndoIcon from '@mui/icons-material/Undo';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
/* CUSTOM components */
// eslint-disable-next-line import/no-cycle
import AdvancedCourseMenuList from './AdvancedCourseMenuList';
import AdvancedCourseTables from './AdvancedCourseTables';
import AdvancedCourseActions from './AdvancedCourseActions';
import AdvancedCourseCategories from './AdvancedCourseCategories';
import { CopyCourseContext } from './context';
import { defaultCopyValues } from './advancedCourse.helper';

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

/*
The primary purpose of this component is to provide an interface for advanced course actions, offering users the ability to select actions, packages, courses, and categories. 
Users can then perform the selected actions and receive feedback through success or error messages.
*/
const AdvancedCourseScreen = ({ open, toggleAdvancedScreen, summary }) => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');
  const [isDisabled, setDisabled] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const defaultCopyAction = defaultCopyValues(store);
  const [copyAction, setCopyAction] = useState(defaultCopyAction);
  //
  const { actionId, course_nid: courseId, categoryUuid } = copyAction;
  const { course_container_profession: profIdCourseContainer } = summary;
  /**  */
  //
  const toast = {
    success1: { title: t('saveResponse.success.title'), content: t('copyDialog.selAction.1.toast'), type: 'success' },
    success2: { title: t('saveResponse.success.title'), content: t('saveResponse.success.message'), type: 'success' },
    success3: { title: t('saveResponse.success.title'), content: t('saveResponse.success.message'), type: 'success' },
    copyError: { title: t('saveResponse.error.title'), content: t('saveResponse.error.message'), type: 'error' },
    duplError: { title: t('saveResponse.error.title'), content: t('saveResponse.error.message'), type: 'error' },
    defaultError: { title: t('saveResponse.error.title'), content: t('saveResponse.error.message'), type: 'error' },
    default: { timeOut: 6000, variant: 'filled' }
  };
  //
  // EVENTS
  const handleCopyActions = (actionType) => {
    setCopyAction({ ...defaultCopyAction, ...actionType });
  };

  const handleSubmitAction = async () => {
    /**   */
    setSaving(true);

    // RE USE COURSE
    if (copyAction.actionId === 1) {
      try {
        await store.courseEdit.copyExternalCourseInContainer(copyAction.courseUuid, copyAction.categoryUuid);
        store.courseEdit.setToasts({ ...toast.success1, ...toast.default });
        setSaving(false);
        toggleAdvancedScreen();
      } catch (error) {
        store.courseEdit.setToasts({ ...toast.defaultError, ...toast.default });
        setSaving(false);
        toggleAdvancedScreen();
      }
    }
    // DUPLICATE COURSE
    if (copyAction.actionId === 2) {
      try {
        await store.courseEdit.duplicateExternalCourseInContainer(copyAction.courseUuid, copyAction.categoryUuid);
        /** await store.courseEdit.advGetCategoryforCurrentCourseContainer(copyAction.containerNid); */
        store.courseEdit.setToasts({ ...toast.success2, ...toast.default });
        setSaving(false);
        toggleAdvancedScreen();
      } catch (error) {
        store.courseEdit.setToasts({ ...toast.defaultError, ...toast.default });
        setSaving(false);
        toggleAdvancedScreen();
      }
    }
    // Link course
    if (copyAction.actionId === 3) {
      try {
        await store.courseEdit.linkExternalCourseInContainer(copyAction.courseUuid);
        store.courseEdit.setToasts({ ...toast.success3, ...toast.default });
        setSaving(false);
        toggleAdvancedScreen();
      } catch (error) {
        store.courseEdit.setToasts({ ...toast.defaultError, ...toast.default });
        setSaving(false);
        toggleAdvancedScreen();
      }
    }
  };

  useEffect(() => {
    if (actionId && courseId && (categoryUuid || actionId === 3)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [actionId, categoryUuid, copyAction, courseId]);

  // TRANSLATIONS
  const dialogTitle = `${t('copyDialog.subtitle')}`;
  const selActionTitle = t('copyDialog.selAction.title');
  const selPackageTitle = t('copyDialog.selPackage.title');
  const selCourseTitle = t('copyDialog.selCourse.title');
  const noCourse = t('copyDialog.selCourse.noCourse');

  return (
    <Dialog
      className="advanced__screen_dialog"
      sx={{ left: '200px', borderRadius: '10px 0px 0px 10px' }}
      fullScreen
      scroll="paper"
      open={open}
      TransitionComponent={Transition}
    >
      <CopyCourseContext.Provider value={copyAction}>
        <AppBar sx={sxAppBar} className="advanced__screen_header">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={toggleAdvancedScreen} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={sxDialogtitle} variant="h6" component="div">
              {dialogTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={sxMainContent}>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSaving}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container spacing={2} sx={sxGridContainer}>
            <Grid className="advanced_select_actions" item xs={2} sx={{ ...sxGridItem, ...{ borderRight: '1px solid silver' } }}>
              <Box>
                <Stack direction="row" spacing={2}>
                  <Avatar sx={sxAvatar1}>1</Avatar>
                  <Box sx={sxBoxTitle}>{selActionTitle}</Box>
                </Stack>
                <Divider />
                <AdvancedCourseActions handleCopyActions={handleCopyActions} copyValues={copyAction} defaultValues={defaultCopyAction} />
              </Box>
            </Grid>

            <Grid
              className="advanced_select_menu"
              item
              xs={2}
              sx={{ ...sxGridItem, ...{ borderRight: '1px solid silver', position: 'relative' } }}
            >
              <Box sx={sxMenuContainer} className="container_menu">
                <Box>
                  <Stack direction="row" spacing={2}>
                    <Avatar sx={sxAvatar2}>2</Avatar>
                    <Box sx={sxBoxTitle}>{selPackageTitle}</Box>
                  </Stack>
                </Box>
                <Divider />
                <AdvancedCourseMenuList profId={profIdCourseContainer} handleCopyActions={handleCopyActions} copyValues={copyAction} />
              </Box>
            </Grid>

            <Grid className="advanced_select_courses" item xs={8} sx={sxGridItem}>
              <Box>
                <Stack direction="row" spacing={2}>
                  <Avatar sx={sxAvatar3}>3</Avatar>
                  <Box sx={sxBoxTitle}>{selCourseTitle}</Box>
                </Stack>
                <Divider />
              </Box>
              {!copyAction.fromContainerNid && <Box sx={noCourses}>{noCourse}</Box>}
              {copyAction.fromContainerNid && (
                <AdvancedCourseTables
                  copyValues={copyAction}
                  handleCopyActions={handleCopyActions}
                  title={copyAction.package}
                  containerNid={copyAction.fromContainerNid}
                />
              )}
            </Grid>
          </Grid>
          <Divider />
          <Box className="advanced_select_category">
            <Box sx={{ padding: '15px', display: 'flex' }}>
              <Stack sx={{ alignItems: 'center', flex: '1' }} direction="row" spacing={2}>
                <Avatar sx={sxAvatar3}>4</Avatar>
                <Box sx={sxBoxTitle}>{t('copyDialog.selCategory.title')}</Box>
                <AdvancedCourseCategories copyValues={copyAction} handleCopyActions={handleCopyActions} />
              </Stack>
            </Box>
          </Box>
          <Box className="saveProgress" sx={{ width: '100%', height: '4px' }}>
            {/* <LinearProgress /> */}
            {isSaving && <LinearProgress />}
          </Box>
          <Box
            sx={{
              marginTop: 'auto',
              padding: '15px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#607d8b1c'
            }}
          >
            <Button variant="text" onClick={() => setCopyAction(defaultCopyAction)} startIcon={<UndoIcon />}>
              {t('reset')}
            </Button>
            <Box sx={{ background: 'white', borderRadius: '40px', padding: '5px 10px' }}>
              {`${t('copyDialog.saveInfo.what')}: `}
              <strong> {`${copyAction.action} `}</strong>
              {`${t('copyDialog.saveInfo.from')} `}
              <strong>{`${copyAction.package}`} </strong>
              {`${t('copyDialog.saveInfo.course')} `}
              <strong> {`${copyAction.course}`} </strong>
              {`${t('copyDialog.saveInfo.to')} `}
              {`${t('copyDialog.saveInfo.currentPackage')}`}
            </Box>
            <Box>
              <Button sx={{ marginRight: '20px' }} color="inherit" onClick={toggleAdvancedScreen} aria-label="close">
                {t('cancel')}
              </Button>
              <Button variant="contained" onClick={handleSubmitAction} disabled={isDisabled}>
                {t('save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </CopyCourseContext.Provider>
    </Dialog>
  );
};

AdvancedCourseScreen.propTypes = {
  open: PropTypes.bool,
  toggleAdvancedScreen: PropTypes.func,
  summary: PropTypes.object
};

// TODO: remove this sx styling
const sxMenuContainer = { position: 'absolute', top: '17px', left: '10px', right: '0px', bottom: '0px', overflow: 'auto' };
const sxBoxTitle = { display: 'flex', alignSelf: 'center' };
const noCourses = { display: 'flex', alignSelf: 'center', justifyContent: 'center', width: '100%' };
const sxGridItem = { display: 'flex', flex: 1, flexDirection: 'column', padding: '16px' };
const sxAppBar = { position: 'relative', background: 'primary' };
const sxDialogtitle = { ml: 2, flex: 1 };
const sxMainContent = { display: 'flex', flex: '1', flexDirection: 'column' };
const sxGridContainer = { margin: '0px', width: '100%', flex: '1' };
const sxAvatar1 = { background: '#57707d', color: 'white' };
const sxAvatar2 = { background: '#9eb1bb', color: 'white' };
const sxAvatar3 = { background: '#7d97a5', color: 'white' };

export default observer(AdvancedCourseScreen);
