import { Box, useMediaQuery } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { useMemo } from 'react';
import { LocationButtonMobile } from './LocationButtonMobile';
import { LocationButtonDesktop } from './LocationButtonDesktop';

export const LocationButton = ({ location }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const content = useMemo(() => {
    if (isMobile) {
      return <LocationButtonMobile location={location} />;
    }

    return <LocationButtonDesktop location={location} />;
  }, [isMobile, location]);

  return <Box sx={{ flex: '0 0 250px' }}>{content}</Box>;
};

LocationButton.propTypes = { location: PropTypes.any };
