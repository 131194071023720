import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';

const MenuItemEditCourse = ({ handleMenuSelect }) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      // disabled
      key="edit"
      id="edit"
      onClick={handleMenuSelect({
        routeName: 'adminViewCourses'
      })}
    >
      {t('Admin courses')}
    </MenuItem>
  );
};

MenuItemEditCourse.propTypes = {
  handleMenuSelect: PropTypes.func
};

export default observer(MenuItemEditCourse);
