import { Box, Button, Grid, Pagination, Alert, Tooltip } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import DownloadIcon from '@mui/icons-material/Download';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useStore } from 'hooks';
import NewUserTermsAss from 'pages/mainLayout/footer/NewUserTermsAss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InviteUserDialog } from './InviteUserDialog';
import { List } from './List';
import { SearchBar } from './SearchBar';
import { StatsPanel } from './StatsPanel';
import { useAssistantManager } from './useAssistantManager';

function calculatePagination({ offset, limit, total }) {
  // Calculate the current page and total number of pages
  const page = offset / limit + 1;
  const noOfPages = Math.ceil(total / limit);

  // Return the calculated values
  return { page, noOfPages, itemsPerPage: limit };
}

export const AssistantManagementView = () => {
  const { data, isLoading, hasError, refreshData } = useAssistantManager();
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  // const filteredAssistants = filterAssistants(data, searchText, statusFilter) || [];
  const [openGdprAss, setOpenGdprAss] = useState(false);
  const { t } = useTranslation();
  const store = useStore();
  const offset = data?.result?.offset ?? 0;
  const { page, noOfPages, itemsPerPage } = calculatePagination({
    offset,
    total: data?.result?.total ?? 1,
    limit: data?.result?.limit ?? 1
  });
  useEffect(() => {
    window.scroll(0, 0);
    if (store?.getGdprAss() !== '1') {
      setOpenGdprAss(true);
    }
  }, [store]);

  if (isLoading && !data) {
    return t('assistant.tabs.loading');
  }

  if (hasError) {
    return <Alert severity="error">{t('assistant.tabs.msgError')}</Alert>;
  }

  if (!data) {
    return null;
  }

  const onSetSearch = (text) => {
    setSearchText(text);
    refreshData(0, text, statusFilter);
  };

  const onStatusFilterChange = (status) => {
    setStatusFilter(status);
    refreshData(0, searchText, status);
  };

  const oncloseGdprAss = () => {
    setOpenGdprAss(!openGdprAss);
  };

  const contractId = data?.assistant_manager?.attributes?.drupal_internal__nid;
  const canInvite =
    data?.assistant_manager?.attributes?.maximum_subscriptions - data?.assistant_manager?.attributes?.subscriptions_used === 0;
  return (
    <>
      <InviteUserDialog
        handleClose={() => setInviteUserDialogOpen(false)}
        open={inviteUserDialogOpen}
        contractId={contractId}
        onSuccess={async () => {
          await refreshData(offset, searchText, statusFilter);
          setInviteUserDialogOpen(false);
        }}
      />
      <Box sx={{ backgroundColor: '#F4F7FE' }}>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <Tooltip title={t('lms.downloadManual.assistant.tooltip')}>
            <Button
              variant="contained"
              color="primary"
              href="https://docs.google.com/document/d/1FCGI9WFsij9QBwT8gDx9tYy3uEBdwqMkrSU6dtVZfkk/export?format=pdf"
              target="_blank"
              rel="noreferrer"
              sx={{ color: 'white', textTransform: 'none' }}
              startIcon={<DownloadIcon />}
            >
              {t('lms.downloadManual.assistant.text')}
            </Button>
          </Tooltip>
        </Box>
        <StatsPanel data={data} />
        <Grid
          container
          spacing={2}
          sx={{
            borderRadius: 2,
            alignItems: 'center',
            backgroundColor: 'white',
            marginLeft: '0px',
            marginRight: '0px',
            width: '100%'
          }}
        >
          <Grid item xs={12} sm={12} md={8} style={{ paddingTop: '0' }}>
            <ToggleButtonGroup
              color="primary"
              value={statusFilter}
              exclusive
              onChange={(_, value) => onStatusFilterChange(value)}
              aria-label="Platform"
            >
              <ToggleButton value="all">{t('assistant.tabs.tab1')}</ToggleButton>
              <ToggleButton value="active">{t('assistant.tabs.tab2')}</ToggleButton>
              <ToggleButton value="pending">{t('assistant.tabs.tab3')}</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            style={{ paddingTop: '0' }}
            sx={{
              paddingRight: { xs: '0', sm: '16px' }, // Remove right padding on xs, apply on sm and above
              textAlign: { xs: 'left', sm: 'right' } // Text align left on xs screens, right on sm screens and above
            }}
          >
            {!canInvite && (
              <Button variant="contained" onClick={() => setInviteUserDialogOpen(true)}>
                {t('assistant.tabs.btnInvite')}
              </Button>
            )}
          </Grid>
        </Grid>
        <SearchBar placeholderText={t('assistant.tabs.search')} setSearch={onSetSearch} />
        <List
          filteredAssistants={data?.assistants || []}
          searchText={searchText}
          onAction={() => refreshData(offset, searchText, statusFilter)}
        />
        <NewUserTermsAss open={openGdprAss} onClose={oncloseGdprAss} />
        {noOfPages > 1 && (
          <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination
              count={noOfPages}
              page={page}
              onChange={(_, newPage) => refreshData((newPage - 1) * itemsPerPage, searchText, statusFilter)}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
