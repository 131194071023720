import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import { useContext } from 'react';
import PropTypes from 'prop-types';
/* MUI */
import { Chip } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const BtnShowSidebar = ({ right = '-141px', rightHover = '-55px' }) => {
  const store = useContext(Store);
  const storageKey = store.routerState.routeName.includes('Dashboard') ? 'showSidebarDashboard' : 'showSidebarEdit';
  const showSidebar = store.courseEdit[storageKey];
  //
  const sxToggleBtn = {
    boxShadow: '1px 2px 4px #0000007d',
    paddingRight: '10px',
    position: 'absolute',
    right,
    transition: 'right .3s',
    zIndex: '100',
    '&:hover': { right: rightHover }
  };
  const handleToggleSidebar = () => {
    store.courseEdit.setStoreValue(storageKey, !showSidebar);
    localStorage.setItem(storageKey, !showSidebar);
  };
  return (
    <Chip
      color="warning"
      sx={sxToggleBtn}
      label={`${showSidebar ? 'hide' : 'show'} sidebar`}
      icon={
        showSidebar ? (
          <KeyboardDoubleArrowRightIcon fontSize="small" sx={{ color: 'white', fontSize: '14px' }} />
        ) : (
          <KeyboardDoubleArrowLeftIcon fontSize="small" sx={{ color: 'white', fontSize: '14px' }} />
        )
      }
      onClick={() => handleToggleSidebar()}
    />
  );
};

BtnShowSidebar.propTypes = {
  right: PropTypes.string,
  rightHover: PropTypes.string
};

export default observer(BtnShowSidebar);
