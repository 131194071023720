/* Core */
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Store } from 'state/ContextStore';
import PropTypes from 'prop-types';

/* MUI */
import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, Button, InputBase, Box } from '@mui/material';
import { convertUrlEncodedString } from 'helpers/Helpers';

/* MUI - Icons */
import SearchIcon from '@mui/icons-material/Search';
import Searching from './Searching';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '49px',
  borderColor: theme.shape.borderColor,
  backgroundColor: '#F4F7FE',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
    height: '24px'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  marginTop: 5,
  marginBottom: 5,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '24ch',
      '&:focus': {
        width: '36ch'
      }
    }
  }
}));

const StyledButton = styled(Button)(() => ({
  position: 'absolute',
  right: '18px',
  height: '100%',
  borderRadius: '34px',
  zIndex: 99,
  backgroundColor: '#F4F7FE'
}));

const CourseSearch = () => {
  const { t } = useTranslation();
  const store = useContext(Store);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const isSearchRoute = store.routerStore?.getCurrentRoute().name === 'searchOverview';
  const isSelectProductRoute = store.routerStore?.getCurrentRoute().name === 'selectProduct';

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    // Navigate to the search results route
    if (searchTerm.trim().length < 2) {
      store.showToast({ message: t('searchoverview.error', 'Please enter at least 2 characters'), variant: 'error' });
      return;
    }
    store.routerStore.goTo('searchOverview', {
      params: {
        baseProductId: store.baseProduct?.nid.toString(),
        searchParam: convertUrlEncodedString(searchTerm.trim()),
        searchAttempt: '0'
      }
    });
  };

  // Show/hide the search input
  const showPopupSearch = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isSelectProductRoute) {
    return null;
  }

  if (!store.hasSubscriptions) {
    return null;
  }

  return (
    <>
      {!isSearchRoute && <Searching />}
      <MobileSearchButton onClick={showPopupSearch} />
      <Search className="MuiInputSearch">
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={t('courseoverview.search.label', 'Search …')}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          inputProps={{ type: 'search' }}
        />
        <StyledButton variant="text" onClick={handleSubmit}>
          {t('courseoverview.search.button', 'Search')}
        </StyledButton>
      </Search>
      <MobileSearchDialog open={open} onChange={handleChange} onClose={handleClose} onSubmit={handleSubmit} />
    </>
  );
};

// Mobile search button
// eslint-disable-next-line arrow-body-style
const MobileSearchButton = ({ onClick }) => {
  return (
    <Button className="MobileSearchButton" onClick={onClick}>
      <SearchIcon />
    </Button>
  );
};

MobileSearchButton.propTypes = {
  onClick: PropTypes.func
};

// Mobile search dialog
const MobileSearchDialog = ({ open, onChange, onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Box className="mobileSearch">
        <DialogContent>
          <StyledInputBase
            placeholder={t('searchoverview.searchPlaceholder', 'Search …')}
            onChange={onChange}
            inputProps={{ type: 'search' }}
            sx={{
              width: '100% !important'
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('searchoverview.cancel', 'Cancel')}
          </Button>
          <Button onClick={onSubmit} color="primary">
            {t('searchoverview.search', 'Search')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

MobileSearchDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
};

export default CourseSearch;
