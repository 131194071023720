import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { Icon } from '@iconify/react';

import { useStore } from 'hooks';
import * as Types from '../../../types';
import { useEvaluations } from '../../useEvaluations';

const Activities = ({ evaluation }) => {
  const { t } = useTranslation('curriculum');
  const store = useStore();

  const { courseResults } = useEvaluations();

  const hasActivities = evaluation?.attributes?.activities?.length > 0;

  const hasCourses = evaluation?.relationships?.courses?.data?.length > 0;
  const relatedCoursesIds = hasCourses ? evaluation.relationships.courses.data.map(({ id }) => id) : null;
  const relatedCourseResults = relatedCoursesIds ? courseResults?.filter((course) => relatedCoursesIds.includes(course.id)) : null;

  const deleteActivity = async (activity, index) => {
    const activities = evaluation.attributes.activities.filter((_, i) => i !== index);
    store.startLoadCurriculum();
    await store.updateCurriculumEvaluation({ ...evaluation, attributes: { ...evaluation.attributes, activities } });
    store.stopLoadCurriculum();
    store.refreshCurrentCurriculum();
  };

  const removeCourse = async (course) => {
    const courses = evaluation.relationships.courses.data.filter(({ id }) => course.id !== id);

    store.startLoadCurriculum();
    await store.updateCurriculumEvaluation({
      ...evaluation,
      relationships: {
        ...evaluation.relationships,
        courses: {
          ...evaluation.relationships.courses,
          data: courses
        }
      }
    });
    store.stopLoadCurriculum();
    store.refreshCurrentCurriculum();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Box>
        <Typography component="p" variant="h6" sx={{ fontSize: '1rem' }}>
          {t('evaluations.coursesTitle')}
        </Typography>
        <Typography paragraph>{t('evaluations.coursesDescription')}</Typography>
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '1rem',
            borderRadius: '10px'
          }}
        >
          {relatedCourseResults ? (
            <ul>
              {relatedCourseResults.map((course) => (
                <li key={course.id}>
                  {course.attributes.name}

                  <Icon
                    onClick={() => removeCourse(course)}
                    icon="akar-icons:cross"
                    style={{
                      color: '#B91C1C',
                      fontSize: '0.75rem',
                      lineHeight: '1rem',
                      fontWeight: '700',
                      marginLeft: '0.5rem',
                      cursor: 'pointer'
                    }}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <Typography>
              <i>{t('evaluations.noCoursesText')}</i>
            </Typography>
          )}
        </Box>
      </Box>

      <Box>
        <Typography component="p" variant="h6" sx={{ fontSize: '1rem' }}>
          {t('evaluations.activitiesTitle')}
        </Typography>
        <Typography paragraph>{t('evaluations.activitiesDescription')}</Typography>
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '1rem',
            borderRadius: '10px'
          }}
        >
          {hasActivities ? (
            <ul>
              {evaluation.attributes.activities.map((activity, i) => (
                <li key={activity}>
                  {activity}

                  <Icon
                    onClick={() => deleteActivity(activity, i)}
                    icon="akar-icons:cross"
                    style={{
                      color: '#B91C1C',
                      fontSize: '0.75rem',
                      lineHeight: '1rem',
                      fontWeight: '700',
                      marginLeft: '0.5rem',
                      cursor: 'pointer'
                    }}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <Typography>
              <i>{t('evaluations.noActivitiesText')}</i>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Activities.propTypes = {
  evaluation: Types.Evaluation.isRequired
};

export default observer(Activities);
