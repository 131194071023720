import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
/* MUI components */
import { Box, Dialog, IconButton, Slide, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
/* CUSTOM components */
import LessonEditForm from './form/LessonEditForm';

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

/*
  In summary, the LessonScreen component is used to show a dialog for adding or updating lessons. 
  It provides a user-friendly interface with a customizable title that changes based on the operation (add or update). 
  The user can close the dialog by clicking the close button. 
  Additionally, there is commented-out code for saving, indicating that the ability to save changes may be implemented separately. 
  This component is likely part of a course management system where lessons can be added or edited.
*/

const LessonScreen = ({ open }) => {
  const store = useContext(Store);
  const { t } = useTranslation('editCourseForm');
  //
  const { courseEdit } = store;
  const { dialogLesson } = courseEdit;
  const dialogTitle = dialogLesson.lessonId ? t('lesEditForm.updateLesson') : t('lesEditForm.addLesson');
  //
  if (!open) return null;

  return (
    <Box>
      <Dialog className="advanced__screen_dialog" sx={sxLessonDialog} fullScreen open={open} TransitionComponent={Transition}>
        <AppBar sx={sxAppBar} className="advanced__screen_header">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => courseEdit.setDialogLesson()} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, textTransform: 'capitalize' }} variant="h6" component="div">
              {dialogTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={sxFlexColumn}>
          <Box sx={{ ...sxFlexColumn, form: sxFlexColumn }}>
            <LessonEditForm />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

LessonScreen.propTypes = {
  open: PropTypes.bool
};

export default observer(LessonScreen);

// CUSTOM sx _________________________________________________________________
const sxLessonDialog = { left: '200px', borderRadius: '10px 0px 0px 10px' };
const sxAppBar = { position: 'relative', background: 'white', boxShadow: 'none', color: '#607d8b' };
const sxFlexColumn = { display: 'flex', flexDirection: 'column', flex: 1 };
