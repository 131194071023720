import { Box, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { RouterLink } from 'mobx-state-router';
import PersonIcon from '@mui/icons-material/Person';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const { t } = useTranslation();

  return (
    <Paper component={Box} p={4} spacing={2}>
      <Typography variant="h2">{t('lms.title')}</Typography>

      <List component="nav">
        <RouterLink
          routeName="LmsDashboardOverview"
          activeClassName="ButtonNavigation-active"
          isActive={(currentState) =>
            ['LmsDashboardOverview', 'LmsDashboardProfessions', 'LmsDashboardProducts'].includes(currentState.routeName)
          }
        >
          <ListItemButton className="ButtonNavigation">
            <ListItemIcon>
              <ExploreOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('lms.navbar.dashboard')} />
          </ListItemButton>
        </RouterLink>
        <RouterLink
          routeName="LmsStudents"
          activeClassName="ButtonNavigation-active"
          isActive={(currentState) =>
            ['LmsStudents', 'LmsStudentsOpen', 'LmsStudentsCompleted', 'LmsStudentDetail'].includes(currentState.routeName)
          }
        >
          <ListItemButton className="ButtonNavigation">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t('lms.navbar.students')} />
          </ListItemButton>
        </RouterLink>

        <RouterLink
          routeName="LmsCourses"
          activeClassName="ButtonNavigation-active"
          isActive={(currentState) =>
            ['LmsCourses', 'LmsCourseDetail', 'LmsCoursesOpenList', 'LmsCoursesCompleted'].includes(currentState.routeName)
          }
        >
          <ListItemButton className="ButtonNavigation">
            <ListItemIcon>
              <WebStoriesIcon />
            </ListItemIcon>
            <ListItemText primary={t('lms.navbar.courses')} />
          </ListItemButton>
        </RouterLink>

        <RouterLink routeName="LmsOrganisations" activeClassName="ButtonNavigation-active">
          <ListItemButton className="ButtonNavigation">
            <ListItemIcon>
              <GridViewSharpIcon />
            </ListItemIcon>
            <ListItemText primary={t('lms.navbar.organisation')} />
          </ListItemButton>
        </RouterLink>
      </List>
    </Paper>
  );
};

export default NavBar;
