/* Core */
import PropTypes from 'prop-types';
import { TextField, Box, InputAdornment, Button, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useState } from 'react';

export const SearchBar = ({ placeholderText, setSearch }) => {
  const [searchText, setSearchText] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    setSearch(searchText);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ backgroundColor: 'white', padding: 2, mt: 2, borderRadius: 3, display: 'flex', gap: 1 }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder={placeholderText}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          sx={{ flex: '1 0 auto' }}
          InputProps={{
            sx: { backgroundColor: 'grey.100', borderRadius: 2, borderColor: 'transparent' },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),

            endAdornment: searchText && searchText.length !== 0 && (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setSearchText('');
                  setSearch('');
                }}
              >
                <CancelRoundedIcon />
              </IconButton>
            )
          }}
        />
        <Button
          aria-label="search"
          color="primary"
          variant="contained"
          type="submit"
          sx={{ aspectRatio: 1, height: 56, borderRadius: 2, boxShadow: 'none' }}
        >
          <SearchIcon />
        </Button>
      </Box>
    </form>
  );
};

SearchBar.propTypes = {
  placeholderText: PropTypes.string,
  setSearch: PropTypes.func
};
