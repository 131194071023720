import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { Stack } from '@mui/material';
import { uniqueId } from 'lodash';
import ToastItem from './Toast';
import 'theme/components/toast.scss';

/*
In summary, the ToastContainer component is responsible for rendering toast messages within a stack.
It listens for changes in the global state and dynamically displays toast messages based on the content and configuration stored in the toastStates array.
The component ensures that a reasonable number of toast messages are displayed, even when there are many in the array.
*/
const ToastContainer = () => {
  const store = useContext(Store);
  const { isToastvisible, toastStates } = store;

  return isToastvisible && toastStates.length ? (
    <Stack spacing={2} className="stack_toast">
      {toastStates.length < 3
        ? toastStates.map((toast) => <ToastItem key={uniqueId('toast-id')} {...toast} />)
        : toastStates.filter((item, index) => index > toastStates.length - 4).map((toast) => <ToastItem key={toast.id} {...toast} />)}
    </Stack>
  ) : null;
};

export default observer(ToastContainer);
