import { observer } from 'mobx-react';
import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';
/* MUI */
import { Alert } from '@mui/material';

const BtnReUsedCourse = () => {
  const store = useContext(Store);
  const { t } = useTranslation('editCourseForm');
  const { courseEdit } = store;
  const reUsedText = t('editCourse.reUsed');
  const lessonContainer = courseEdit?.currentCourse?.included.find((item) => item.type === 'lesson_container');
  const lessonContainerProfName = lessonContainer?.relationships?.profession?.data?.id?.attributes?.name;
  return (
    <Alert icon={false} variant="filled" severity="warning" sx={sxAlertReUsed}>
      {reUsedText}: {lessonContainerProfName}
    </Alert>
  );
};

export default observer(BtnReUsedCourse);

const sxAlertReUsed = {
  position: 'absolute',
  top: '18px',
  right: '220px',
  zIndex: '100000',
  boxShadow: '1px 3px 6px #0000006b'
};
