import PropTypes from 'prop-types';

export const InitialValues = {
  Evaluation: {
    attributes: {
      name: '',
      evaluation: '',
      activities: []
    },
    relationships: {
      courses: {
        data: []
      }
    }
  }
};

export const CourseResult = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    name: PropTypes.string,
    completed: PropTypes.string, // isodate
    certificate: PropTypes.string, // isodate
    transfer: PropTypes.string, // isodate
    points: PropTypes.string,
    accreditation_unit_singular: PropTypes.string,
    accreditation_unit_plural: PropTypes.string,
    is_accredited: PropTypes.bool,
    data: PropTypes.string
  })
});

export const Evaluation = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    name: PropTypes.string,
    evaluation: PropTypes.string,
    activities: PropTypes.array
  }),
  courses: PropTypes.arrayOf(CourseResult)
});

export const LearningGoalActivity = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    name: PropTypes.string,
    explanation: PropTypes.string
  })
});

export const LearningGoal = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    name: PropTypes.string
  }),
  activities: PropTypes.arrayOf(LearningGoalActivity),
  evaluations: PropTypes.arrayOf(Evaluation)
});

export const FunctionActivity = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    name: PropTypes.string,
    expected_result: PropTypes.string
  })
});

export const JobFunction = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    name: PropTypes.string
  }),
  activities: PropTypes.arrayOf(FunctionActivity)
});

export const Curriculum = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    year: PropTypes.number
  }),
  functions: PropTypes.arrayOf(JobFunction),
  learning_goals: PropTypes.arrayOf(LearningGoal)
});
