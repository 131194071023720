import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import { Box } from '@mui/material';
import Logo from '../components/layout/Logo';

/**
 *  Home screen of the admin panel
 * @returns {JSX.Element}
 */
const ViewCourses = () => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');

  return (
    <Box sx={sxDraftView} className="draft-view admin-custom-page">
      <Box className="draft-view__title" sx={{ width: '100%' }}>
        <Box sx={sxDraftViewTitle}>
          {t('viewCourses.title')} {store?.login?.userName || 'Niet ingelogd'}
        </Box>
      </Box>
      <Box className="draft-view__content" sx={sxDraftView}>
        <Box sx={sxBoxContent}>
          <Logo />
        </Box>
      </Box>
    </Box>
  );
};

export default observer(ViewCourses);

const img = { img: { maxWidth: '50%', width: '100%', maxHeight: '50%!important', height: '100%!important', mixBlendMode: 'overlay' } };
const sxCenter = { justifyContent: 'center', alignItems: 'center' };
const sxDraftView = { display: 'flex', flexDirection: 'column', flexGrow: 1 };
const sxBoxContent = { ...sxDraftView, ...sxCenter, ...img };
const sxDraftViewTitle = { fontSize: 'calc(16px + 1vw)', lineHeight: 'calc(1.1em + 0.5vw)', fontWeight: '500' };
