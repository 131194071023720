import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { Box } from '@mui/material';
// eslint-disable-next-line prettier/prettier
import useEwapi from '../hooks/useEwapi';
import EwapiTable from '../components/tables/EwapiTables';
import LoadingContent from '../components/loading/LoadingContent';

import '../scss/AdminTables.scss';

/*
In summary, the OfflineCourses component fetches a list of offline courses using the useEwapi hook, 
displays a loading indicator during data retrieval, handles errors, and renders the list of offline courses in a tabular format using the EwapiTable component. 
The component is designed to work within a larger application context and provides a way to view offline courses.
*/
const OfflineCourses = () => {
  const store = useContext(Store);
  const ewapiCourseList = `editor-course-list/${store.params.containerNid}/2/json`;
  const { response, loading, error } = useEwapi(ewapiCourseList, 'courses');

  const title = 'offline';

  if (loading) return <LoadingContent loading={loading} />;

  return (
    <Box sx={{ flexGrow: 1 }} className="draft-view admin-custom-page">
      <Box className="admin-tables" sx={{ width: '100%' }}>
        {error && <p>Something went wrong...</p>}
        {response && <EwapiTable courses={response.data.courses} summary={response.data.result} loading={loading} title={title} />}
      </Box>
    </Box>
  );
};

export default observer(OfflineCourses);
