import { createTheme } from '@mui/material';

const PrimaryBlue = '#2B3674';
const BackgroundColor = 'rgba(119, 26, 81, 0.05);';
const RedColor = '#FF5B5B';

/* Margins */
const DefaultMarginMin = '8px';
const DefaultMarginMid = '16px';

export const createCustomTheme = ({ primaryColor, secondaryColor }) =>
  createTheme({
    palette: {
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
      secondaryOrange: {
        main: secondaryColor
      },
      background: {
        main: BackgroundColor
      },
      primaryBlue: {
        main: PrimaryBlue
      },
      tabPrimaryBlue: {
        main: primaryColor
      },
      favoriteRed: {
        main: RedColor
      }
    },
    typography: {
      h1: {
        fontSize: '40px',
        fontWeight: '600'
      },
      h2: {
        fontSize: '38px',
        fontWeight: '600'
      },
      h3: {
        fontSize: '36px',
        fontWeight: '600'
      },
      h4: {
        fontSize: '34px',
        fontWeight: '600'
      },
      h5: {
        fontSize: '30px',
        fontWeight: '600'
      },
      h6: {
        fontSize: '38px',
        fontWeight: '600'
      },
      subtitle1: {
        fontSize: '26px'
      },
      subtitle2: {
        fontSize: '26px'
      },
      button: {
        fontSize: '14px'
      },
      caption: {
        fontSize: '12px'
      },
      overline: {
        fontSize: '12px'
      }
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            margin: '0 auto',
            width: '100%'
          }
        }
      },
      MuiAvatarGroup: {
        styleOverrides: {
          root: {
            justifyContent: 'flex-end',
            marginTop: DefaultMarginMid,
            marginBottom: DefaultMarginMid
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            background: BackgroundColor,
            color: primaryColor
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            marginTop: DefaultMarginMin,
            marginBottom: DefaultMarginMin
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            marginTop: DefaultMarginMid,
            marginBottom: DefaultMarginMid
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: 'rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px'
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            '&.Mui-checked.MuiSwitch-track': {
              background: primaryColor
            },
            '& .MuiSwitch-track': {
              background: '#E0E5F2'
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: primaryColor
            }
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            color: primaryColor
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: primaryColor
            }
          }
        }
      },
      MuiListItem: {
        MuiTypography: {
          styleOverrides: {
            root: {
              margin: '0px'
            }
          }
        }
      }
    },
    custom: {
      courseIntroductionHeader: {
        imageGradient: `linear-gradient(90deg, ${primaryColor} 0%, ${primaryColor} 38.54%, transparent 100%)`
      },
      onboarding: {
        buttonBackgroundColor: primaryColor
      }
    }
  });
