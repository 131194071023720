import { types as t } from 'mobx-state-tree';
import { Body, RelProfession } from './BaseModel';

// TODO: Update relationships.
// accr_logo: t.maybeNull(),
// accr_registers: t.maybeNull(),
// field_logo: t.maybeNull(),

export const AccreditationOrganisation = t.model('AccreditationOrganisation', {
  id: t.identifier,
  type: t.string,
  attributes: t.model({
    drupal_internal__nid: t.number,
    title: t.string,
    accr_unit: t.maybeNull(t.string),
    extra_points_unit: t.maybeNull(t.string),
    body: t.maybeNull(Body)
  }),
  relationships: t.model({
    profession: t.maybe(RelProfession)
  })
});

export const RelAccreditationOrganisations = t
  .model('RelAccreditationOrganisations', {
    data: t.maybeNull(
      t.model({
        id: t.reference(AccreditationOrganisation),
        type: t.string
      })
    )
  })
  .views((self) => ({
    get accreditationrefs() {
      if (self.data) {
        return self.data.map((accreditationrefs) => accreditationrefs.id);
      }
      return [];
    }
  }));

export const RelAccreditationOrganisation = t.model('RelAccreditationOrganisation', {
  data: t.maybeNull(
    t.model({
      id: t.reference(AccreditationOrganisation),
      type: t.string
    })
  )
});

export const newAccreditationOrganisation = {
  attributes: {
    title: '',
    accr_unit: '',
    extra_points_unit: ''
  }
};
