import { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
import MenuItem from '@mui/material/MenuItem';

const ProductMenuItems = forwardRef((props, ref) => {
  const store = useContext(Store);
  const { handleMenuSelect } = props;
  const { routerState } = store.routerStore;

  // create dynamic menu
  const menuLinks = [];
  if (store.subscriptions.subscriptions.length > 1) {
    const uniqueProductTitles = new Set();
    store.subscriptions.subscriptions.forEach((subscription, index) => {
      const productName = subscription.base_product_title.toLowerCase();
      if (!uniqueProductTitles.has(productName)) {
        menuLinks.push({
          routeName: 'courseOverview', // without packageId
          title: subscription.base_product_title,
          params: {
            productName: subscription.base_product_title.toLowerCase(),
            product: store.products[index].toString(),
            packageId: '0'
          },
          queryParams: {}
        });
        uniqueProductTitles.add(productName);
      }
    });
  }
  return menuLinks.map((link, index) => {
    if (
      link.params &&
      link.routeName === routerState.routeName &&
      link.params.product === routerState.params.product &&
      link.params.productName === routerState.params.productName &&
      link.params.packageId === routerState.params.packageId
    ) {
      return (
        <MenuItem ref={ref} key={index + 1}>
          {link.title}
        </MenuItem>
      );
    }
    const handleChangeProduct = () => {
      store.setSelectedProduct(store.products[index], 0);
      store.setPrefProduct(store.products[index]);
      store.setState('init');
    };
    return (
      <MenuItem ref={ref} key={index + 1} onClick={handleMenuSelect(link, handleChangeProduct)}>
        {link.title}
      </MenuItem>
    );
  });
});

ProductMenuItems.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  handleMenuSelect: PropTypes.func
};

ProductMenuItems.displayName = 'ProductMenuItems';
export default observer(ProductMenuItems);
