import PropTypes from 'prop-types';
import { RouterLink, useRouterStore } from 'mobx-state-router';
import { observer } from 'mobx-react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
// import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'hooks';
import LmsToolbar from './LmsToolbar';
// import SearchBar from './SearchBar';

const TabsLayout = ({ tabsRoutes, children }) => {
  // const { t } = useTranslation();
  const routerStore = useRouterStore();
  const { queryParams, routeName } = routerStore.routerState;
  const breakpoint = useBreakpoint();

  const isMobile = ['xs'].includes(breakpoint);
  const title = tabsRoutes[routeName];

  return (
    <Stack id="stack" sx={{ background: 'white' }}>
      <Box
        id="headerTabs"
        sx={{
          display: 'flex',
          background: 'white',
          width: '100%',
          maxWidth: '100%',
          borderRadius: '8px',
          overflow: 'hidden',
          justifyContent: 'center',
          gap: '10px',
          padding: '0px 10px'
        }}
      >
        <Tabs
          id="myTabs"
          value={0}
          TabIndicatorProps={{
            sx: {
              display: 'none'
            }
          }}
          sx={{
            minHeight: 0,
            '& a': {
              display: 'inline-block',
              textDecoration: 'none'
            },
            '& .tab-label': {
              color: '#2B3674',
              borderRadius: '20px',
              textTransform: 'capitalize',
              backgroundColor: '#F4F7FE',
              padding: '10px 16px!important'
            },
            '& a.tab-link-active.tab-label': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white'
            },
            '.MuiTabs-flexContainer': {
              justifyContent: 'center',
              gap: '10px',
              a: { padding: '0px' }
            }
          }}
          variant={isMobile ? 'scrollable' : 'standard'}
          scrollButtons
          allowScrollButtonsMobile
        >
          {Object.entries(tabsRoutes).map(([route, label]) => (
            <Tab
              key={route}
              disableRipple
              label={
                <Box
                  component={RouterLink}
                  routeName={route}
                  queryParams={queryParams}
                  activeClassName="tab-link-active"
                  className="tab-label"
                >
                  {label}
                </Box>
              }
            />
          ))}
        </Tabs>
      </Box>
      <LmsToolbar title={title} orgType={1} profType={1} dateType={1} />
      {/* {['LmsStudents'].includes(routeName) && <SearchBar placeholder={t('lms.courses.searchbar.placeholder')} />} */}
      {children}
    </Stack>
  );
};
TabsLayout.propTypes = {
  children: PropTypes.node,
  tabsRoutes: PropTypes.object
};

export default observer(TabsLayout);
