import MainLayout from 'pages/mainLayout/MainLayout';
import TeachersOverview from './TeachersOverview';

const teachersOverviewViewMap = {
  teachersOverview: (
    <MainLayout subtitle="" title="teachers.title.teachers">
      <TeachersOverview />
    </MainLayout>
  )
};

export default teachersOverviewViewMap;
