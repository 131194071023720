import { ThemeBrandTitle } from 'helpers/Helpers';
import { getLessonPlay } from 'state/models/LessonPlay';
import { getSpecialAccessCourses, ewiseUserHasPrefBaseproduct, specialAccessGetPackages } from 'state/RoutesActions';

// EXPORTS 3 ROUTES
// - specialAccessRoute
// - specialAccessLessonPlayRoute

/**
 * SPECIAL ACCESS ROUTE
 *
 * This route is used for displaying the courssoverview.
 *
 * @type {{
 * name: string,
 * title: string,
 * pattern: string,
 * beforeEnter: (function(*, *, *): Promise<unknown>),
 * onEnter: (function(*, *, *): Promise<void>)}}
 */
export const specialAccessRoute = {
  name: 'specialAccess',
  title: `Special Access | ${ThemeBrandTitle}`,
  pattern: '/special-access/courseoverview',
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    // validation checks
    if (!store?.login?.hasSpecialAccess) return store.routerStore.goTo('home');
    // continue
    await specialAccessGetPackages(fromState, toState, routerStore);
    if (fromState.routeName === '__initial__' || !fromState.routeName.includes('specialAccess')) {
      await getSpecialAccessCourses(fromState, toState, routerStore);
    }
    return null;
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    store.setStoreValue('courseHeaderTabs', 'All');
    store.setSelectedPackage(parseInt(0));
  }
};

// SPECIAL ACCESS ROUTE - COURSE INTRODUCTION
export const SpecialAccessCourseIntroductionRoute = {
  name: 'specialAccessCourseIntroduction',
  pattern: '/:sa/:productName/:product/:packageId/course/:courseId/:title',
  altPatterns: ['/:sa/:productName/:product/:packageId/course/:courseId'],
  title: `Course introduction`,
  beforeEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { productName, product } = toState.params;
    // validation checks
    ewiseUserHasPrefBaseproduct(routerStore);
    if (!store?.login?.hasSpecialAccess) return store.routerStore.goTo('home');
    if (productName !== 'special' || product !== 'access') return store.routerStore.goTo('notFound');
    // continue
    if (store.hasSubscriptions === 'init') {
      await specialAccessGetPackages(fromState, toState, routerStore);
    }
    if (fromState.routeName === '__initial__' || !fromState.routeName.includes('specialAccess') || !store.specialAccess) {
      await getSpecialAccessCourses(fromState, toState, routerStore);
    }
    return null;
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    const { courseId } = toState.params;
    store.setSelectedPackage(parseInt(0));
    store.courseResults?.updateCourseResultbyNid(Number(courseId));
    // eslint-disable-next-line no-unused-expressions
    store.courseResults?.isCourseFinished(Number(courseId)) && !store.isEwiseUser && store.subscriptions.updateSubscription();
  }
};

/**
 * SPECIAL ACCESS LESSONPLAY ROUTE
 *
 * @type {{
 * name: string,
 * title: string,
 * pattern: string,
 * beforeEnter: (function(*, *, *): Promise<unknown>),
 * onEnter: (function(*, *, *): Promise<void>)}}
 */
export const specialAccessLessonPlayRoute = {
  name: 'specialAccessLessonPlay',
  // pattern: '/special-access/course/:courseId/lesson/:lessonId/page/:pageNo',
  pattern: '/sp/:productName/:product/:packageId/course/:courseId/lesson/:lessonId/page/:pageNo',
  title: `Lesson | ${ThemeBrandTitle}`,
  beforeEnter: async (fromState, toState, routerStore) => {
    if (fromState.routeName === '__initial__') {
      getSpecialAccessCourses(fromState, toState, routerStore);
    }
  },
  onEnter: async (fromState, toState, routerStore) => {
    const { store } = routerStore.options;
    if (store.lessonState === 'pending') return; // Fix for being called twice
    const pageNo = parseInt(toState.params.pageNo);
    try {
      //
      await getLessonPlay(store, toState.params.courseId, toState.params.lessonId);
      //
      const newPageNo = pageNo === 0 ? 0 : store.lessonPlay.getValidPage(pageNo);
      //
      if (newPageNo !== pageNo) {
        routerStore.goTo('specialAccessLessonPlay', {
          params: {
            product: toState.params.product,
            productName: toState.params.productName,
            // packageId: toState.params.packageId,
            courseId: toState.params.courseId,
            lessonId: toState.params.lessonId,
            pageNo: newPageNo.toString()
          }
        });
      } else {
        store.lessonPlay.setMoveNewPage(pageNo);
      }
    } catch (e) {
      store.gotoErrorPage({ title: 'Error loading content', message: 'Click <a href="/">here</a> to go Back to the home page' });
    }
  }
};
