import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { Icon } from '@iconify/react';

import { useStore } from 'hooks';
import { ConfirmationModal, Button } from '../../components';
import { ActivitiesTable, ActivitiesTableToolbar, StageTitleBar, StageTitle, StageHeader } from '../components';
import { useFunctions } from '../useFunctions';
import AddFunctionDialog from './components/AddFunctionDialog';
import AddOrEditActivityModal from './components/AddOrEditActivityModal';
import { useCurriculum } from '../useCurriculum';
import EditFunctionDialog from './components/EditFunctionDialog';

const CurriculumFunctions = () => {
  const store = useStore();
  const { t } = useTranslation('curriculum');

  const { curriculum } = useCurriculum();
  const { functionsWithActivities, functionActivities } = useFunctions();

  const [activeFunction, setActiveFunction] = useState(null);
  const [activeActivity, setActiveActivity] = useState(null);

  const [isAddFunctionModalOpen, setIsAddFunctionModalOpen] = useState(false);
  const [isEditFunctionModalOpen, setIsEditFunctionModalOpen] = useState(false);
  const [isAddOrEditModalVisible, setIsAddOrEditModalVisible] = useState(false);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);

  const onAddFunction = () => {
    setIsAddFunctionModalOpen(true);
  };

  const onEditFunction = (fn) => {
    setActiveFunction(fn);
    setIsEditFunctionModalOpen(true);
  };

  const onDeleteFunction = async (fn) => {
    setActiveFunction(fn);
    setIsConfirmDeleteModalVisible(true);
  };

  const onCloseAddFunctionDialog = () => {
    setIsAddFunctionModalOpen(false);
  };

  const onSubmitAddFunctionDialog = async () => {
    store.startLoadCurriculum();
    store.refreshCurrentCurriculum();
    store.stopLoadCurriculum();
    setIsAddFunctionModalOpen(false);
  };

  const onDeleteFunctionConfirm = async () => {
    store.startLoadCurriculum();
    await store.deleteCurriculumFunction(curriculum, activeFunction);
    store.refreshCurrentCurriculum();
    store.stopLoadCurriculum();
    setIsConfirmDeleteModalVisible(false);
  };

  const onAddActivity = (fn) => {
    setActiveFunction(fn);
    setActiveActivity(null);
    setIsAddOrEditModalVisible(true);
  };

  const onEditActivity = (fn, id) => {
    const activity = functionActivities.find((a) => a.id === id);

    setActiveFunction(fn);
    setActiveActivity(activity);
    setIsAddOrEditModalVisible(true);
  };

  const onCloseAddOrEditActivityModal = () => {
    setIsAddOrEditModalVisible(false);
  };

  const onSubmitAddOrEditActivityModal = () => {
    setIsAddOrEditModalVisible(false);
    store.refreshCurrentCurriculum();
  };

  const onCloseEditFunctionDialog = () => {
    setIsEditFunctionModalOpen(false);
  };

  const onSubmitEditFunctionDialog = () => {
    setIsEditFunctionModalOpen(false);
    store.refreshCurrentCurriculum();
  };

  const onDeleteActivities = async (fn, ids) => {
    if (!ids) {
      return;
    }

    store.startLoadCurriculum();
    await store.deleteCurriculumFunctionActivities(fn, ids);
    store.stopLoadCurriculum();
    store.refreshCurrentCurriculum();
  };

  const activityColumns = [
    {
      id: 'attributes.name',
      numeric: false,
      disablePadding: true,
      disableSorting: false,
      label: t('functions.activitiesLabel'),
      type: 'text',
      sx: {
        width: '40%'
      }
    },
    {
      id: 'attributes.expected_result',
      numeric: false,
      disablePadding: true,
      disableSorting: false,
      label: t('functions.expectedResultsLabel'),
      type: 'text',
      sx: {
        width: '40%'
      }
    }
  ];

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
        <StageHeader title={t('functions.title')} description={t('functions.description')} />

        <StageTitleBar>
          <StageTitle title={t('functions.title2')} />
          <Button tooltipText={t('functions.addFunctionBtnTooltip')} onClick={onAddFunction}>
            <Icon icon="dashicons:plus" />
            {t('functions.addFunctionBtnLabel')}
          </Button>
        </StageTitleBar>

        {functionsWithActivities.map((fn) => (
          <Box sx={{ padding: '0.5rem', backgroundColor: '#ffffff', borderRadius: '0.75rem' }} key={fn.id}>
            <ActivitiesTableToolbar
              translationGroup="functions"
              title={fn.attributes.name}
              addActivityBtnLabel={t('functions.addActivityBtnLabel')}
              addActivityBtnTooltip={t('functions.addActivityBtnTooltip')}
              editBtnLabel={t('functions.editFunctionBtnLabel')}
              editBtnTooltip={t('functions.editFunctionBtnTooltip')}
              onAddCallback={() => onAddActivity(fn)}
              onEditCallback={() => onEditFunction(fn)}
              onDeleteCallback={() => onDeleteFunction(fn)}
            />
            <ActivitiesTable
              translationGroup="functions"
              activities={fn.activities}
              columns={activityColumns}
              onEditCallback={(id) => onEditActivity(fn, id)}
              onDeleteCallback={(ids) => onDeleteActivities(fn, ids)}
            />
          </Box>
        ))}
      </Box>

      <AddFunctionDialog
        visible={isAddFunctionModalOpen}
        onCloseCallback={onCloseAddFunctionDialog}
        onSubmitCallback={onSubmitAddFunctionDialog}
      />

      {activeFunction && (
        <EditFunctionDialog
          visible={isEditFunctionModalOpen}
          fn={activeFunction}
          onCloseCallback={onCloseEditFunctionDialog}
          onSubmitCallback={onSubmitEditFunctionDialog}
        />
      )}

      <AddOrEditActivityModal
        visible={isAddOrEditModalVisible}
        onCloseCallback={onCloseAddOrEditActivityModal}
        onSubmitCallback={onSubmitAddOrEditActivityModal}
        fn={activeFunction}
        activity={activeActivity}
      />

      <ConfirmationModal
        title={t('functions.confirmDeleteFunctionTitle')}
        text={t('functions.confirmDeleteFunctionText')}
        btnLabel={t('general.deleteBtnLabel')}
        isVisible={isConfirmDeleteModalVisible}
        onConfirm={onDeleteFunctionConfirm}
        onCancel={() => setIsConfirmDeleteModalVisible(false)}
      />
    </>
  );
};

export default observer(CurriculumFunctions);
