import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';

// validation translations
import * as Yup from 'yup';
import * as nl from './translations/nl/yup-validation.inline';

// the translations
import translationEN from './translations/en/translation.json';
import translationDE from './translations/de/translation.json';
import translationNL from './translations/nl/translation.json';
import widgetNL from './translations/nl/widget.json';
import widgetEN from './translations/en/widget.json';
import courseCopyNL from './translations/nl/courseCopy.json';
import askQuestionEN from './translations/en/askQuestion.json';
import askQuestionNL from './translations/nl/askQuestion.json';
import evaluationEN from './translations/en/evaluation.json';
import evaluationNL from './translations/nl/evaluation.json';
import ewiseProductSelectionEn from './translations/en/ewiseProductSelection.json';
import ewiseProductSelectionNL from './translations/nl/ewiseProductSelection.json';
import editCourseFormNL from './translations/nl/editCourseForm.json';
import editCourseFormEN from './translations/en/editCourseForm.json';
import AdminCoursesNL from './translations/nl/adminCourses.json';
import AdminCoursesEN from './translations/en/adminCourses.json';
import onboardingNL from './translations/nl/onboarding.json';
import onboardingEN from './translations/en/onboarding.json';
import curriculumNL from './translations/nl/curriculum.json';
import curriculumEN from './translations/en/curriculum.json';
import vtbEN from './translations/en/vtb.json';
import vtbNL from './translations/nl/vtb.json';
import datagridEN from './translations/en/datagrid.json';
import datagridNL from './translations/nl/datagrid.json';
import colophonEN from './translations/en/colophon.json';
import colophonNL from './translations/nl/colophon.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
    widget: widgetEN,
    askQuestion: askQuestionEN,
    evaluation: evaluationEN,
    ewiseProductSelection: ewiseProductSelectionEn,
    editCourseForm: editCourseFormEN,
    adminCourses: AdminCoursesEN,
    onboarding: onboardingEN,
    curriculum: curriculumEN,
    vtb: vtbEN,
    datagrid: datagridEN,
    colophon: colophonEN
  },
  de: {
    translation: translationDE
  },
  nl: {
    translation: translationNL,
    widget: widgetNL,
    courseCopy: courseCopyNL,
    askQuestion: askQuestionNL,
    evaluation: evaluationNL,
    ewiseProductSelection: ewiseProductSelectionNL,
    editCourseForm: editCourseFormNL,
    adminCourses: AdminCoursesNL,
    onboarding: onboardingNL,
    curriculum: curriculumNL,
    vtb: vtbNL,
    datagrid: datagridNL,
    colophon: colophonNL
  }
};

const options = {
  debug: false, // Set to true locally if you want to see the debug output
  fallbackLng: 'nl',
  ns: ['translation', 'widget', 'courseCopy', 'askQuestion', 'ewiseProductSelection', 'adminCourses'],
  resources,
  keySeparator: '.',
  nsSeparator: ':',
  interpolation: {
    escapeValue: false, // react already safes from xss
    format(value, format) {
      if (format === 'uppercase') return value.toUpperCase();
      if (value instanceof Date) return dayjs(value).format(format);
      return value;
    }
  },
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(options);

export const locales = Object.keys(options.resources);
export function getCurrentLocale() {
  return i18n.languages.find((lng) => locales.indexOf(lng) !== -1);
}

// setup Yup translations
if (getCurrentLocale() === 'nl') {
  Yup.setLocale(nl);
}

window.i18next = i18n;
export default i18n;
