/**
 * ROUTE ACTIONS FOR COURSE ADMIN PART OF THE APP
 * These are the actions that are called when a route is entered
 */

// GET PROFILE -------------------------------------------------------
export const getProfile = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  if (store?.user) return;
  store.fetchUser().catch(() => {
    store.setState('error');
    routerStore.goTo('errorPage');
  });
};

// GET SPECIAL_ACCESS ITEMS ------------------------------------------
export const getSpecialAccessMenu = async (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  if (store?.courseEdit?.specialAccessMenu?.length > 0) return;
  try {
    const { data: responds } = await store.courseEdit.lmsApi.fetchIt('special_access');
    const menu = responds.data.map((item) => ({ type: item.type, id: item.id, name: item.attributes.name }));
    store.courseEdit.setStoreValue('specialAccessMenu', menu);
  } catch (e) {
    throw new Error('getSpecialAccessMenu error', e);
  }
};

// Goto selected tab --------------------------------------------------
export const setTab = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  const { queryParams } = toState;
  const isFromStateRouteLessonPlay = fromState.routeName === 'lessonPlay' && 'Lessons';
  const hasQueryParamsTab = queryParams?.tab;
  const selectedTab = isFromStateRouteLessonPlay || hasQueryParamsTab || 'Introduction';
  store.courseEdit.setEditTab(selectedTab);
};

// Default route error message ----------------------------------------
export const showToastRouteError = (fromState, toState, routerStore) => {
  const { store } = routerStore.options;
  store.courseEdit.setToasts({
    title: 'Error',
    content: 'Er is iets misgegaan bij het ophalen van de cursusgegevens',
    type: 'error',
    timeOut: 4000,
    variant: 'outlined'
  });
};

export const logError = (error) => {
  console.error(error);
};
