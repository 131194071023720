import * as Yup from 'yup';

const newCourseValidation = Yup.object().shape({
  attributes: Yup.object({
    title: Yup.string().required('Title is required'),
    field_points: Yup.string().nullable(),
    field_extra_points: Yup.string().nullable(),
    field_valid_period: Yup.object({
      value: Yup.date().nullable(),
      end_value: Yup.date().nullable()
    })
  }),
  field_course_category: Yup.array().min(1, 'Category is required')
});

export { newCourseValidation };
