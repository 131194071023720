/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext } from 'react';
/* MUI */
import { Box, Dialog, DialogTitle, Chip, Button } from '@mui/material';
/* Custom  components */
import FormRadioQuestion from './formWidgets/FormRadioQuestion';
import FormVideoWidget from './formWidgets/FormVideoWidget';
import FormOpenQuestion from './formWidgets/FormOpenQuestion';
import FormHtmlWidget from './formWidgets/FormHtmlWidget';
import FormSortQuestion from './formWidgets/FormSortQuestion';
import FormMatchQuestion from './formWidgets/FormMatchQuestion';
import FormNumericalQuestion from './formWidgets/FormNumericalQuestion';
import { sxCustomQuickEditor } from './formWidgets/formWidgets.helper';

const EditWidgetDialog = () => {
  const store = useContext(Store);
  if (!store.courseEdit.toggleDialogEditWidget) return null;
  //
  const editWidget = store.courseEdit.setEditWidget || {};
  const editWidgetAction = editWidget.id ? 'Quick edit: ' : 'Quick add: ';
  //
  const dialogTitle = `${editWidgetAction} ${editWidget.attributes.widget_code}`;
  const widgetType = editWidget.attributes.widget_type.toLowerCase();

  // current available form types
  const formType = {
    'radio question': <FormRadioQuestion />,
    'check question': <FormRadioQuestion optionType="check" />,
    'video widget': <FormVideoWidget />,
    'open question': <FormOpenQuestion />,
    'html widget': <FormHtmlWidget />,
    'sort question': <FormSortQuestion />,
    'match question': <FormMatchQuestion />,
    'numerical question': <FormNumericalQuestion />
  };

  return (
    <Dialog
      sx={sxCustomQuickEditor}
      open={store.courseEdit.toggleDialogEditWidget}
      maxWidth="lg"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={editDialog} id="alert-dialog-title">
        <Box>{dialogTitle}</Box>
        <Box className="edit-widget-type">
          <Chip color="primary" label={`${widgetType}`} />
        </Box>
      </DialogTitle>
      {formType[widgetType] || <Button onClick={() => store.courseEdit.setStoreValue('toggleDialogEditWidget', false)}>Close</Button>}
    </Dialog>
  );
};

export default observer(EditWidgetDialog);

const editDialog = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '20px',
  fontWeight: '600',
  color: '#584c4c',
  '& .edit-widget-type': { fontSize: '16px' },
  '& .MuiInputBase-root.MuiOutlinedInput-root': { fontSize: '10px' }
};
