import { Button } from '@mui/material';
import { getFaqUrl } from 'helpers/Helpers';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
import useJsonApi from 'hooks/useJsonApi';

const FaqLink = () => {
  const { t } = useTranslation();
  const store = useStore();
  const baseProductId = store?.baseProduct?.uuid;
  const currentLabel = store.label;
  // HOOK
  const fetchUrl = `base_product/${baseProductId}`;
  const fetchQuery = `include=profession&fields[profession]=name`;
  const { response, loading, error } = useJsonApi(fetchUrl, fetchQuery);

  if (loading) return null;
  if (error) return null;

  const profession = response?.included?.[0]?.attributes?.name?.replace(/ /g, '-').replace('/', '').toLowerCase();
  const url = profession ? getFaqUrl(currentLabel, profession) : '';

  return (
    <Button
      target="_blank"
      component="a"
      style={{ textTransform: 'capitalize', color: 'black', fontWeight: '400' }}
      href={url}
      disabled={loading || error || !url}
    >
      {t('footer.faq')}
    </Button>
  );
};

export default FaqLink;
