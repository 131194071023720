/* eslint-disable react/no-danger */
import { useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Open from 'components/widgets/Open';
import { LinearProgress } from '@mui/material';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import useWidget from './useWidget';

const OpenQuestion = (props) => {
  const { store, widgetCode } = props;
  const { question, widget, allowedTries, PlayWidget, answerDisabled } = useWidget(props);

  const widgetAttr = widget.attributes;
  const touched = useRef(!!question.last_response);
  const lastResponse = question.last_response ? question.last_response : '';
  const isDisabled = answerDisabled || question.tries >= allowedTries || question.last_correct || false;
  const answer = useRef(lastResponse);
  const isReflect = widgetAttr?.options?.type === 'Reflect';

  const nowTime = new Date().getTime();
  const reflectionList = store.lessonPlay?.reflectionList || [];
  const isRepeatReflection =
    reflectionList.length > 0 ? reflectionList.filter((w) => w.id === widgetAttr?.widget_code?.toLowerCase()) : null;
  const checkTime = isRepeatReflection !== null ? isRepeatReflection[0]?.time : nowTime;

  const isTimeDifferenceMoreThan30Seconds = (timestamp1, timestamp2) => {
    const differenceInSeconds = Math.abs((timestamp1 - timestamp2) / 1000);
    return differenceInSeconds > 30;
  };

  const validateAnswer = () => {
    if (!answer.current && !answer.current.length > 0) return false;
    const isCorrect = true;

    return { value: answer.current, correct: isCorrect };
  };

  const handleInput = (value) => {
    answer.current = value;
  };

  const showFeedback = () => {
    if (!question.tries) return null;

    if (isReflect) {
      if (!reflectionList) {
        return null;
      }
      if (isRepeatReflection && isRepeatReflection.length > 0 && !isTimeDifferenceMoreThan30Seconds(nowTime, checkTime)) {
        return null;
      }
    }
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: widgetAttr.feedback }} /> {/* show general feedback (allways show) */}
        {showCorrectFeedback()}
      </>
    );
  };

  function showCorrectFeedback() {
    if (question.tries < allowedTries && !question.last_correct) return null;
    const correctFeedback = widgetAttr.options.feedback;
    return correctFeedback;
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget touched={touched.current} validateAnswer={validateAnswer} showFeedback={showFeedback} {...props}>
        <Open value={lastResponse} handleInput={handleInput} question={question} disabled={isDisabled} {...props} />
      </PlayWidget>
    </>
  );
};

OpenQuestion.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  question: PropTypes.object,
  store: PropTypes.object
};

export default observer(OpenQuestion);
