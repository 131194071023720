import { types as t } from 'mobx-state-tree';
import { RelProfession } from './BaseModel';
import { RelBaseProduct } from './BaseProduct';

export const Product = t
  .model('Product', {
    id: t.identifier,
    type: t.string,
    attributes: t.model({
      drupal_internal__nid: t.number,
      status: t.boolean,
      title: t.string,
      free_product: t.boolean,
      maximum_points: t.maybeNull(t.number),
      maximum_subscriptions: t.maybeNull(t.number)
    }),
    relationships: t.model({
      base_product: RelBaseProduct,
      profession: RelProfession
    })
  })
  .views((self) => ({
    get baseProduct() {
      if (self.relationships.base_product && self.relationships.base_product.data) {
        return self.relationships.base_product.data.id;
      }
      return null;
    },
    get packages() {
      if (self.baseProduct && self.baseProduct.relationships.packages && self.baseProduct.relationships.packages.packagerefs) {
        return self.baseProduct.relationships.packages.packagerefs;
      }
      return [];
    }
  }));

export const RelProduct = t.model('RelProduct', {
  data: t.maybeNull(
    t.model({
      id: t.reference(Product),
      type: t.string
    })
  )
});

export const RelProducts = t
  .model('RelProducts', {
    data: t.array(
      t.maybeNull(
        t.model({
          id: t.reference(Product),
          type: t.string
        })
      )
    )
  })
  .views((self) => ({
    get productrefs() {
      if (self.data && self.data.length) {
        return self.data.map((productref) => productref.id);
      }
      return [];
    }
  }));
