import { ThemeBrandTitle } from 'helpers/Helpers';
import { lmsBeforeEnter as beforeEnter } from 'pages/lms/LmsRouteActions';

export const lmsCoursesRoute = [
  {
    name: 'LmsCourses',
    pattern: '/lms/courses',
    meta: { title: `%%lms.navbar.allCourses%% | ${ThemeBrandTitle()}` },
    beforeEnter
  },
  {
    name: 'LmsCoursesCompleted',
    pattern: '/lms/courses/completed',
    meta: { title: `%%lms.navbar.completedCourses%% | ${ThemeBrandTitle()}` },
    beforeEnter
  },
  {
    name: 'LmsCoursesOpenList',
    pattern: '/lms/courses/open',
    meta: { title: `%%lms.navbar.openCourses%% | ${ThemeBrandTitle()}` },
    beforeEnter
  }
];
