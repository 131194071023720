import { Lazy } from 'components';
import ScorePage from '../evaluation/ScorePage';

const FeedbackViewMap = {
  feedback: (
    <Lazy noLayout>
      <ScorePage />
    </Lazy>
  )
};

export default FeedbackViewMap;
