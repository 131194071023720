import { types as t, getParent, destroy, flow } from 'mobx-state-tree';
import { toJS } from 'mobx';

export const PollWidgetItem = t
  .model('PollWidgetItem', {
    nid: t.number,
    widgetCode: t.string,
    answer: t.maybeNull(t.string),
    poll: t.model({
      internal_title: t.string,
      body: t.string,
      profession: t.maybeNull(t.number),
      totalcount: t.number,
      user_has_answered: t.number,
      options: t.array(
        t.model({
          value: t.string,
          count: t.number,
          delta: t.number
        })
      )
    })
  })
  .actions((self) => ({
    update(widgetUpdate) {
      self.answer = widgetUpdate.answer;
      self.poll = widgetUpdate.poll;
    },
    remove() {
      getParent(self, 2).remove(self);
    }
  }));

export const PollWidgetList = t
  .model('PollWidgetList', {
    widgets: t.array(PollWidgetItem)
  })
  .views((self) => ({
    getPollWidget(widgetCode) {
      return self.widgets.find((w) => w.widgetCode === widgetCode);
    }
  }))
  .actions((self) => ({
    // ADD WIDGET
    add(widget) {
      if (!self.getPollWidget(widget.widgetCode)) {
        self.widgets.push(widget);
      } else {
        const index = self.widgets.findIndex((w) => w.widgetCode === widget.widgetCode);
        self.widgets[index] = widget;
      }
      return self.widgets;
    },
    add2(widget) {
      self.widgets.map((w) => (w.widgetCode === widget.widgetCode ? widget : w));
      return self.widgets;
    },
    remove(widget) {
      destroy(widget);
    },
    pollVote: flow(function* pollVote(widget, answer) {
      const updatedWidget = yield getParent(self, 1).lmsApi.postPollAnswer(widget.nid, answer);
      widget.update(updatedWidget);
    }),
    pollCancelVote(data) {
      const widget = toJS(data);

      data.update({ ...widget, poll: { ...widget.poll, user_has_answered: 0 } });
    }
  }));

export const RelPollWidgetItem = t.model('RelPollWidgetItem', {
  data: t.maybeNull(
    t.model({
      id: t.reference(PollWidgetItem),
      type: t.string
    })
  )
});
