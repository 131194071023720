import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import LoadingPage from '../../components/common/LoadingPage';

export const SwitchBack = observer(() => {
  const inProgress = useRef(false);

  useEffect(() => {
    if (!inProgress.current) {
      inProgress.current = true;
      window.clientInstance.killLocalSession().then(() => {
        window.close();
      });
    }
  });

  return <LoadingPage />;
});
