/* eslint-disable camelcase */
import Carusel, { CarouselItem } from 'components/common/Carousel';
import { rawHTML } from 'helpers/Helpers';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BasicCard from '../Card/CourseGridCard';
import 'theme/course/courseoverview/CourseSwipable.scss';

/*
In summary, the GridCardSwipe component is responsible for rendering a grid of courses with swipable categories.
It supports both filtered and unfiltered views, displaying courses organized by categories.
Users can swipe through courses in the same category within a responsive layout.
The appearance and behavior of this grid are determined by data from the application's store and user interactions.
*/
const GridCardSwipe = () => {
  const store = useStore();
  const { t } = useTranslation();

  if (store.courses.courses.length === 0) {
    return (
      <Box className="noFavorite_section">
        <Typography color="primaryBlue.main" variant="h1" fontSize="27px">
          No courses available yet
        </Typography>
      </Box>
    );
  }

  const isFiltering = store.courses?.isFiltering;
  const currentCourses = store.courses?.courseList;
  const categories = store.courses?.categories;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={{ xs: 12, sm: 8, md: 8, lg: 12, xl: 12 }}>
        {isFiltering ? (
          <>
            <Grid item xs={12} style={{ flexBasis: '100%' }}>
              <Typography variant="h2" component="h2" color="primary">
                {t('courseoverview.filteredResults', 'Filtered results')}: {currentCourses.length}
              </Typography>
            </Grid>
            <CourseList courses={currentCourses} isFiltering />
          </>
        ) : (
          categories.map((category) => {
            const courses = store.courses.coursesByCategoryId(category.tid);

            return (
              <Box key={category.tid} mb={4} className="grid_swipable">
                <Grid item xs={12} style={{ flexBasis: '100%' }}>
                  <Typography variant="h5" component="h2" color="primary">
                    {category.name}
                  </Typography>
                </Grid>
                <Carusel arrows length={courses.length}>
                  <CourseList courses={courses} />
                </Carusel>
              </Box>
            );
          })
        )}
      </Grid>
    </Box>
  );
};

export default observer(GridCardSwipe);

const CourseList = ({ courses, isFiltering }) =>
  courses.map((course) => {
    const {
      course_id,
      course_information,
      lc_author_id,
      course_category2,
      lc_podcast_html,
      course_status,
      course_title,
      course_uuid,
      lc_extra_label,
      lessons,
      categoryLabel,
      categoryLabelArray,
      points,
      imageUrl
    } = course;
    const description = course_information ? rawHTML(course_information) : '';
    const label = lc_extra_label?.match('podcast');
    return (
      <CarouselItem key={course_id}>
        <BasicCard
          authors={lc_author_id || []}
          badges={course_category2 || []}
          categories={isFiltering && categoryLabel}
          categoriesArray={categoryLabelArray}
          chip={course_status}
          courseImage={imageUrl}
          courseTitle={course_title}
          description={description}
          duration={points}
          extraLabel={!label && lc_extra_label}
          id={course_uuid}
          lessons={lessons}
          moreinfo={1}
          nid={course_id}
          podcast={lc_podcast_html}
        />
      </CarouselItem>
    );
  });
