import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import CourseHeadline from './CourseHeadline';

const Header = ({ title, subtitle }) => (
  <Box component="header" className="course_overview_headline">
    <CourseHeadline title={title} subtitle={subtitle} />
  </Box>
);

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subtitle: PropTypes.string
};

export default observer(Header);
