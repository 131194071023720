import { useTranslation } from 'react-i18next';

// Linked courses: toasts with translations
export const ToastSearch = () => {
  const { t } = useTranslation('editCourseForm');
  return {
    search: { title: 'too little', content: 'put in more than 5 characters', type: 'info' },
    success: { title: t('editCourse.updated.success.title'), content: t('editCourse.updated.success.message'), type: 'success' },
    error: { title: t('editCourse.updated.fail.title'), content: t('editCourse.updated.fail.message'), type: 'error' },
    default: { timeOut: 4000, variant: 'filled' }
  };
};

export const getTypeOf = (value) => {
  if (typeof value === 'string') return 'string';
  if (Array.isArray(value)) return 'array';
  return 'object';
};

export const convertFilterValue = (value) => {
  if (value?.[0] === 'true') return 1;
  if (value?.[0] === 'false') return 0;
  return value;
};
export const jsonApiOperators = [
  '=',
  '<>',
  '>',
  '>=',
  '<',
  '<=',
  'STARTS_WITH',
  'CONTAINS',
  'ENDS_WITH',
  'IN',
  'NOT IN',
  'BETWEEN',
  'NOT BETWEEN',
  'IS NULL',
  'IS NOT NULL'
];
export const buildSpecialFilter = (value, title, path) => {
  const valueArray = value.split(' ');
  const op = valueArray.length === 1 ? '=' : valueArray[0];
  const number = valueArray.length === 1 ? valueArray[0] : valueArray[1];
  return `filter[${title}][condition][path]=${path}&filter[${title}][condition][value]=${number}&filter[${title}][condition][operator]=${op}`;
};

// CREATE SEARCHPARAMS FOR EACH FILTER
export const BuildQueryFilters = (filters, uuid) => {
  const filterItems = filters.map((item) => {
    const {
      value,
      column: { title, field, operator = '=', specialOp = false, currentUser = false, relationPath = false }
    } = item;
    const path = relationPath || field;
    // path = field === 'profName' ? 'profession.name' : path;
    // path = field === 'userName' ? 'uid.name' : path;

    if (!value.length) return '';
    if (specialOp) return buildSpecialFilter(value, title, path);
    if (operator === '=') return `filter[${field}][value]=${convertFilterValue(value)}`;
    if (currentUser && value === 'me') return `filter[uid.id][value]=${uuid}`;
    return `filter[${title}][condition][path]=${path}&filter[${title}][condition][value]=${value}&filter[${title}][condition][operator]=${operator}`;
  });
  return filterItems.join('&');
};

export const buildQuerySearch = (search) => {
  const querySearch = search
    ? `filter[title][condition][path]=title&filter[title][condition][value]=${search}&filter[title][condition][operator]=CONTAINS`
    : '';
  return querySearch;
};
