import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { forwardRef } from 'react';
import { Icon } from '@iconify/react';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#F2F7F6',
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
    width: '100%',
    height: '48px',
    color: 'black',
    boxShadow: 'none',
    fontSize: 16,
    justifyContent: 'space-between',
    fontWeight: 400,
    textTransform: 'initial',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all ease-in-out 0.15s',

    '&:hover': { boxShadow: 'none', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }
  }
}));

export const LocationButtonBase = forwardRef(({ children, ...props }, ref) => {
  const classes = useStyles();

  return (
    <button type="button" {...props} ref={ref} className={classes.button}>
      <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 400 }}>
        {children}
      </Typography>
      <Icon icon="fluent:location-16-filled" color="primary.main" />
    </button>
  );
});

LocationButtonBase.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any.isRequired
};
