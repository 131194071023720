import { Controller } from 'react-hook-form';
import { FormControl, OutlinedInput, MenuItem, Select, InputLabel, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';

/*
In summary, the MySelect component is a flexible select input component that can operate in both controlled and uncontrolled modes. 
It allows users to select items from a list and can be customized with labels, helper text, and other input properties. 
It is designed to be integrated into forms and can be used with libraries like react-hook-form for controlled form handling.
*/
const MySelect = (props) => {
  const {
    disabled,
    control,
    name,
    items,
    label,
    onChange: onChangeProp,
    value: valueProp,
    helperText,
    sxMenuItem,
    inputProps = {},
    ...otherProps
  } = props;

  return control ? (
    <Controller
      name={name}
      control={control}
      shouldUnregister
      defaultValue={valueProp}
      render={({ field: { onChange, value, ...field } }) => (
        <FormControl style={{ width: '100%' }} variant="outlined" disabled={disabled}>
          <InputLabel id={label}>{label}</InputLabel>
          <Select
            {...field}
            value={value}
            id={name}
            onChange={onChangeProp || onChange}
            input={<OutlinedInput id="select-multiple-chi" label={label} />}
            fullWidth
            inputProps={{ MenuProps: { disableScrollLock: true, ...inputProps } }}
            {...otherProps}
          >
            {items.map((item, index) => (
              <MenuItem key={index} value={item.value || item.name} sx={sxMenuItem}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className={helperText}>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  ) : (
    <FormControl style={{ width: '100%' }} variant="outlined" disabled={disabled}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        {...otherProps}
        name={name}
        value={valueProp}
        id={name}
        input={<OutlinedInput id="outlined-age-simple" />}
        fullWidth
        inputProps={{ MenuProps: { disableScrollLock: true, ...inputProps } }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item.value || item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MySelect.propTypes = {
  disabled: PropTypes.bool,
  control: PropTypes.object,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string,
  sxMenuItem: PropTypes.object
};

export default MySelect;
