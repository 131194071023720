import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Button, LinearProgress, Pagination, Paper, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import DownloadIcon from '@mui/icons-material/Download';
import RestoreIcon from '@mui/icons-material/Restore';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

import { useStore } from 'hooks';
import { CURRICULUM_STAGE_BY_STEP } from '../constants';
import { canEditCurriculum } from '../utils';

export const CurriculumTable = ({ items, page, noOfPages, itemsPerPage, handlePageChange, refreshCallback }) => {
  const store = useStore();
  const theme = useTheme();
  const { t } = useTranslation('curriculum');

  const actionButtonProps = {
    variant: 'outlined',
    size: 'small',
    sx: {
      borderRadius: '20px',
      textTransform: 'none',
      fontWeight: 400,
      display: 'flex',
      gap: '8px',
      width: '100px',
      [theme.breakpoints.down('lg')]: {
        minWidth: '50px',
        width: '50px'
      }
    }
  };

  const archive = async (uid) => {
    await window.authedClient.delete(`ewapi/portfolios/${uid}/delete`);
    refreshCallback();
  };

  const unarchive = async (item) => {
    await store.unarchiveCurriculum(item.uid);
    refreshCallback();
  };

  const download = async (uid) => {
    const result = await window.authedClient.get(`/portfolio/${uid}/access-pdf`);

    if (result?.data?.download_url) {
      window.open(result.data.download_url, '_blank');
    }
  };

  const handleRowClick = (item) => {
    if (item.isDeleted) {
      return;
    }

    if (item.isFinished) {
      download(item.uid);
      return;
    }

    const stageToGoTo = CURRICULUM_STAGE_BY_STEP[item.currentStep] ?? CURRICULUM_STAGE_BY_STEP[1];

    store.routerStore.goTo('curriculumStages', {
      params: {
        uid: item.uid,
        stageId: stageToGoTo
      }
    });
  };

  return (
    <Paper sx={{ boxShadow: 'none' }}>
      {store.isLoadingCurriculum && <LinearProgress sx={{ width: '100%' }} />}
      <Box sx={{ padding: '40px 20px', color: 'rgb(43, 54, 116)' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '100px 3fr 1fr 1fr 1fr 3fr',
            width: '100%',
            gap: '5px',
            px: 4,
            mb: 4
          }}
        >
          <Box
            sx={{
              fontWeight: 800,
              fontSize: '14px'
            }}
          >
            {t('dashboard.yearTitle')}
          </Box>
          <Box
            sx={{
              fontWeight: 800,
              fontSize: '14px'
            }}
          >
            {t('dashboard.progressTitle')}
          </Box>
          <Box
            sx={{
              fontWeight: 800,
              fontSize: '14px',
              textAlign: 'center'
            }}
          >
            {t('dashboard.learningGoalsTitle')}
          </Box>
          <Box
            sx={{
              fontWeight: 800,
              fontSize: '14px',
              textAlign: 'center'
            }}
          >
            {t('dashboard.plannedActivitiesTitle')}
          </Box>
          <Box
            sx={{
              fontWeight: 800,
              fontSize: '14px',
              textAlign: 'center'
            }}
          >
            {t('dashboard.evaluationsTitle')}
          </Box>
          <Box />
        </Box>
        <Box>
          {items.map((item) => (
            <Box
              onClick={() => handleRowClick(item)}
              key={item.uid}
              sx={{
                mb: 2,
                alignItems: 'center',
                display: 'grid',
                px: 4,
                gridTemplateColumns: '100px 3fr 1fr 1fr 1fr 3fr',
                width: '100%',
                height: '54px',
                borderRadius: '20px',
                border: '1px solid #EEEEEE',
                cursor: item.isDeleted ? 'default' : 'pointer',
                '&:hover': { boxShadow: item.isDeleted ? '' : '0px 30px 15px -26px rgba(0, 0, 0, 0.2)' }
              }}
            >
              <Box>{item.year}</Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '80%', mr: 1 }}>
                    <LinearProgress
                      sx={{ height: 10, borderRadius: 2 }}
                      size="large"
                      variant="determinate"
                      value={item.percentageCompleted}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(item.percentageCompleted)}%`}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ textAlign: 'center' }}>{item.isV3Portfolio ? '-' : item.goals}</Box>
              <Box sx={{ textAlign: 'center' }}>{item.isV3Portfolio ? '-' : item.plannedActivities}</Box>
              <Box sx={{ textAlign: 'center' }}>{item.isV3Portfolio ? '-' : item.evaluations}</Box>
              <Box align="right">
                <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                  {!item.isDeleted && (
                    <Tooltip title={t('dashboard.archiveBtnTooltip')} arrow placement="top">
                      <Button
                        {...actionButtonProps}
                        color="warning"
                        onClick={(event) => {
                          event.stopPropagation();
                          archive(item.uid);
                        }}
                      >
                        <ArchiveIcon fontSize="14px" />
                        <Typography
                          sx={{
                            fontSize: '0.75rem',
                            whiteSpace: 'nowrap',
                            [theme.breakpoints.down('lg')]: {
                              display: 'none'
                            }
                          }}
                        >
                          {t('dashboard.archiveBtnLabel')}
                        </Typography>
                      </Button>
                    </Tooltip>
                  )}

                  {item.isDeleted && (
                    <Tooltip title={t('dashboard.unarchiveBtnTooltip')} arrow placement="top">
                      <Button
                        {...actionButtonProps}
                        color="warning"
                        onClick={(event) => {
                          event.stopPropagation();
                          unarchive(item);
                        }}
                      >
                        <RestoreIcon fontSize="14px" />
                        <Typography
                          sx={{
                            fontSize: '0.75rem',
                            whiteSpace: 'nowrap',
                            [theme.breakpoints.down('lg')]: {
                              display: 'none'
                            }
                          }}
                        >
                          {t('dashboard.unarchiveBtnLabel')}
                        </Typography>
                      </Button>
                    </Tooltip>
                  )}

                  {!item.isDeleted && item.percentageCompleted >= 25 && (
                    <Tooltip title={t('dashboard.downloadBtnTooltip')} arrow placement="top">
                      <Button
                        {...actionButtonProps}
                        color="success"
                        onClick={(event) => {
                          event.stopPropagation();
                          download(item.uid);
                        }}
                      >
                        <DownloadIcon fontSize="14px" />
                        <Typography
                          sx={{
                            fontSize: '0.75rem',
                            whiteSpace: 'nowrap',
                            [theme.breakpoints.down('lg')]: {
                              display: 'none'
                            }
                          }}
                        >
                          {t('dashboard.downloadBtnLabel')}
                        </Typography>
                      </Button>
                    </Tooltip>
                  )}

                  {!item.isDeleted && !item.isFinished && canEditCurriculum(item.year) && (
                    <Tooltip title={t('dashboard.editBtnTooltip')} arrow placement="top">
                      <Button
                        {...actionButtonProps}
                        color="info"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleRowClick(item);
                        }}
                      >
                        <EditIcon fontSize="14px" />
                        <Typography
                          sx={{
                            fontSize: '0.75rem',
                            whiteSpace: 'nowrap',
                            [theme.breakpoints.down('lg')]: {
                              display: 'none'
                            }
                          }}
                        >
                          {t('dashboard.editBtnLabel')}
                        </Typography>
                      </Button>
                    </Tooltip>
                  )}
                </Stack>
              </Box>
            </Box>
          ))}
        </Box>
        {noOfPages > 1 && (
          <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination count={noOfPages} page={page} onChange={(_, newPage) => handlePageChange((newPage - 1) * itemsPerPage)} />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

CurriculumTable.propTypes = {
  noOfPages: PropTypes.number,
  itemsPerPage: PropTypes.number,
  page: PropTypes.number,
  handlePageChange: PropTypes.func,
  refreshCallback: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      isFinished: PropTypes.bool.isRequired,
      currentStep: PropTypes.number.isRequired,
      percentageCompleted: PropTypes.number.isRequired,
      goals: PropTypes.number.isRequired,
      plannedActivities: PropTypes.number.isRequired,
      evaluations: PropTypes.number.isRequired
    })
  ).isRequired
};
