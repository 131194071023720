import { Box, Modal, Button } from '@mui/material';

import { useStore } from 'hooks';

const MaintenanceModal = () => {
  const store = useStore();

  const styles = {
    modal: {
      '--primary-color': '#2b3674',
      '--secondary-color': '#2b3674cc',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& *': {
        color: 'rgba(0, 0, 0, 0.87)'
      }
    },
    main: {
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    icon: {
      width: '14rem',
      height: '14rem'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '1rem',
      textAlign: 'center',
      '& h1': {
        fontWeight: 600,
        fontSize: '2.4rem',
        lineHeight: '2.5rem',
        color: 'var(--primary-color)'
      },
      '& p': {
        marginTop: 0
      }
    }
  };

  const retry = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <Modal open={store.maintenance} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" sx={styles.modal}>
      <Box sx={styles.main}>
        <Box sx={styles.icon}>
          <svg strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="var(--primary-color)">
            <path d="M13.5 6L10 18.5" stroke="var(--primary-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.5 8.5L3 12L6.5 15.5" stroke="var(--primary-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M17.5 8.5L21 12L17.5 15.5"
              stroke="var(--primary-color)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Box sx={styles.content}>
          <Box>
            <h1>De site is in onderhoud</h1>
            <p>
              Sorry voor het ongemak.
              <br />
              We voeren op dit moment onderhoud uit op onze leeromgeving, maar we zijn snel weer online.
            </p>
            <p>Probeer het over 5 minuten a.u.b. nog een keer.</p>
          </Box>
          <Box>
            <h1>This site is under maintenance</h1>
            <p>
              Sorry for the inconvenience.
              <br />
              We&apos;re performing some maintenance at the moment, but we&apos;ll be back online shortly.
            </p>
            <p>Please try again in 5 minutes.</p>
          </Box>
          <Box>
            <Button variant="outlined" onClick={retry}>
              Probeer nu / Retry now
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MaintenanceModal;
