import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { ListItemIcon, MenuItem } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { createXml } from 'helpers/xml.helper';
import { useStore } from 'hooks';

const MenuItemXmlBrochure = () => {
  const store = useStore();
  const { t } = useTranslation();

  return (
    <MenuItem key="xmlbro" onClick={() => createXml(store, 2)}>
      <ListItemIcon>
        <DownloadIcon fontSize="small" />
      </ListItemIcon>
      {t('Brochure xml')}
    </MenuItem>
  );
};

export default observer(MenuItemXmlBrochure);
