import { types as t } from 'mobx-state-tree';

export const OpenQuestion = t.model({
  type: t.maybe(t.string)
});

export const RadioQuestion = t.array(
  t.model({
    correct: t.maybe(t.boolean, false),
    value: t.maybe(t.string),
    label: t.maybe(t.string),
    feedback: t.maybe(t.string)
  })
);

export const CheckQuestion = t.array(
  t.model({
    correct: t.optional(t.boolean, false),
    value: t.maybe(t.string),
    label: t.maybe(t.string),
    feedback: t.maybe(t.string)
  })
);

export const VideoWidget = t.model({
  url: t.maybe(t.string),
  value: t.maybe(t.string),
  feedback: t.maybe(t.string)
});

export const HtmlWidget = t.model({
  htmlData: t.maybe(t.string),
  label: t.maybe(t.string),
  feedback: t.maybe(t.string)
});

export const NumericalQuestion = t.model({
  // correct: t.maybe(t.string),
  // label: t.string,
  // feedback: t.string,
  feedbackCorrect: t.maybe(t.string),
  feedbackLess: t.maybe(t.string),
  feedbackMore: t.maybe(t.string),
  value: t.maybe(t.number),
  valueEnd: t.maybeNull(t.number),
  // slider: t.boolean,
  sliderMin: t.maybe(t.number),
  sliderMax: t.maybe(t.number),
  sliderStep: t.maybe(t.number),
  unit: t.maybe(t.string),
  type: t.maybe(t.string)
});

export const SortQuestion = t.array(
  t.model({
    value: t.maybe(t.string),
    label: t.maybe(t.string),
    startposition: t.maybe(t.number),
    order: 'default'
  })
);

const _matches = t.array(
  t.model({
    answer: t.maybe(t.string),
    nr: t.maybe(t.string),
    order: 'default'
  })
);

export const MatchQuestion = t.array(
  t.model({
    label: t.maybe(t.string),
    nr: t.maybe(t.number),
    matches: t.optional(_matches, [])
  })
);

export const WordCloud = t.array(
  t.model({
    numberOfFields: t.maybe(t.number),
    required: t.maybe(t.boolean)
  })
);

export const PollWidget = t.array(
  t.model({
    label: t.maybe(t.string)
  })
);
