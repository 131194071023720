import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const StageHeader = ({ title, description }) => (
  <Box
    sx={{
      padding: '1rem',
      backgroundColor: '#ffffff',
      borderRadius: '0.75rem'
    }}
  >
    <Typography
      sx={{
        color: 'primaryBlue.main',
        fontSize: '24px',
        marginBottom: '0.5rem'
      }}
    >
      {title}
    </Typography>
    <Typography>{description}</Typography>
  </Box>
);

StageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default StageHeader;
