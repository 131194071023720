import { Box, Stack, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';

export const LocationContent = ({ location }) => (
  <Box sx={{ padding: 2 }}>
    <Stack spacing={2}>
      {location.image && (
        <Box sx={{ boxShadow: '0px 18px 40px 0px rgba(112, 144, 176, 0.12)', borderRadius: 3, padding: 1 }}>
          <Box sx={{ aspectRatio: '5 / 2', borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
            {/* <img src={location.image.attributes.uri.url} alt="location visual" /> */}
            <img
              src="https://loremflickr.com/320/240"
              alt="location visual"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          </Box>
        </Box>
      )}
      <Stack>
        <Typography
          variant="h5"
          sx={{
            color: '#2B3674',
            fontSize: 22,
            fontWeight: 700,
            textTransform: 'uppercase'
          }}
        >
          {location.attributes.title ?? location.attributes.name}
        </Typography>
        <Stack>
          <Typography variant="body" sx={{ color: '#A3AED0', fontSize: 16, fontWeight: 400 }}>
            {location.attributes.street} {location.attributes.house_number}
          </Typography>
          <Typography variant="body" sx={{ color: '#A3AED0', fontSize: 16, fontWeight: 400 }}>
            {location.attributes.zipcode} {location.attributes.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Typography
          variant="caption"
          sx={{
            color: '#2B3674',
            fontSize: 18,
            fontWeight: 700
          }}
        >
          Parkeerinformatie
        </Typography>
        <Typography variant="body" sx={{ color: '#A3AED0', fontSize: 16, fontWeight: 400 }}>
          {location.attributes.parking_info}
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

LocationContent.propTypes = { location: PropTypes.any.isRequired };
