import { Box, Button } from '@mui/material';
import { useState, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSwipeable } from 'react-swipeable';

/*
In summary, the Carousel component creates a versatile carousel with navigation arrows, swipe gestures, and indicator points for displaying a set of items. 
It provides an engaging and interactive way to view content or images, with customizable styling options.
*/
export const CarouselItem = ({ children, className }) => (
  <div className={className === 'active' ? `carousel-item ${className}` : 'carousel-item'}>{children}</div>
);

CarouselItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any
};

const Carousel = ({ children, arrows, length }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = length - 1;
    } else if (newIndex >= length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  return (
    <Box {...handlers} className="carousel">
      <Box className="indicators indicatorsLeft">
        {arrows && (
          <Button
            onClick={() => {
              updateIndex(activeIndex - 1);
            }}
          >
            <ArrowBackIosNewIcon color="primary" />
          </Button>
        )}
      </Box>
      <Box style={{}} className="dragable">
        <Box
          className={`inner  inner${activeIndex}`}
          sx={{
            transform: {
              md: `translate3d(-${activeIndex * 560}px, 0px, 0px)`,
              xs: `translate3d(-${activeIndex * 260}px, 0px, 0px)`,
              lg: `translate3d(-${activeIndex * 260}px, 0px, 0px)`
            },
            width: { sm: length * 260, xs: length * 250 + 9 * length }
          }}
        >
          {Children.map(children, (child, index) => cloneElement(child, { className: activeIndex === index && 'active' }))}
        </Box>
      </Box>

      <Box className="indicators indicatorsRight">
        {arrows && (
          <Button
            onClick={() => {
              updateIndex(activeIndex + 1);
            }}
          >
            <ArrowForwardIosIcon color="white" />
          </Button>
        )}
      </Box>
    </Box>
  );
};

Carousel.propTypes = {
  children: PropTypes.any,
  arrows: PropTypes.bool,
  length: PropTypes.number
};
export default Carousel;
