/* eslint-disable react/no-danger */
import { useRef } from 'react';
import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
// MUI
import FormGroup from '@mui/material/FormGroup';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
// Components
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import Sort from 'components/widgets/Sort';
import { shuffle } from 'components/widgets/DragDropUtils';
//
import useWidget from './useWidget';

const useStyles = makeStyles((theme) => ({
  group: {
    margin: `${theme.spacing}px 0`
  }
}));

const SortQuestion = (props) => {
  const classes = useStyles();
  const { store, widgetCode } = props;
  const { t } = useTranslation('widget');
  const { question, widget, allowedTries, PlayWidget, answerDisabled } = useWidget(props);

  const widgetAttr = widget.attributes;
  // new array with answers sorted by default (NOT CORRECT SORTED)
  const sortItems = cloneDeep(getSnapshot(widgetAttr.options));
  // --------
  const correctAnswerByValueUnsorted = [...sortItems];
  // Value is a string, so we need to convert it to a number to sort it correctly
  // (when # answers >= 10 and if value is a string it will sort 1, 10, 2, 3, 4, 5, 6, 7, 8, 9)
  // After sorting we convert it back to a string
  const correctAnswerByValue = correctAnswerByValueUnsorted
    .map((a) => ({ ...a, value: Number(a.value) })) // convert value to number
    .sort((a, b) => a.value - b.value) // sort by value
    .map((a) => ({ ...a, value: a.value.toString() })); // convert value back to string

  // 2. sort items in correct order by value
  const correctAnswerArray = correctAnswerByValue.map((a) => a.value);
  const touched = useRef(!!question.last_response);
  const lastResponse = question.last_response ? convertResponseToArray() : '';
  const isDisabled = answerDisabled || question.tries >= allowedTries || question.last_correct || false;

  function convertResponseToArray() {
    return question.last_response
      .split(',')
      .map((n) => correctAnswerByValue.find((s) => s.value === n))
      .map((obj, i) => ({
        ...obj,
        order: correctAnswerByValue[i].value === obj.value ? 'correct' : 'wrong'
      }));
  }

  const answer = useRef(lastResponse);

  const handleInput = (value) => {
    answer.current = value;
  };
  const handleTouched = (value) => {
    touched.current = value;
  };

  const validateAnswer = () => {
    if (!touched.current) return false;
    let isCorrect = false;
    isCorrect = isEqual(answer.current, correctAnswerArray);

    return { value: answer.current.toString(), correct: isCorrect };
  };

  const showFeedback = () => {
    if (!question.tries) return null;
    // CREATE NEW ARRAY WITH CORRECT SORTED ITEMS
    const sortItemsCorrect = correctAnswerByValue.map((item) => ({ ...item, order: 'correct' }));
    // SORT ELEMENT WITH THE CORRECT ANSWERS
    const correctSort = (
      <Sort handleInput={handleInput} touched={touched.current} handleTouched={handleTouched} disabled items={sortItemsCorrect} />
    );
    // Is Question answered correctly
    const isCorrect = question.last_correct;
    // Translations isCorrect
    const respAnswer = isCorrect ? t('Correct', 'correct') : t('Incorrect', 'incorrect');
    // can user try again to answer the question.
    const tryAgain = !isCorrect && question.tries < allowedTries ? t('Try again', 'try again') : '';
    // Show translatable textafter the user has failed or has tried to answer the question twice.
    const showCorrectAnswer = !isCorrect && question.tries === allowedTries ? t('The correct answer is', 'the correct anwer is') : '';
    // show the correct feedback if the user has succesfully or failed the question
    const feedback = isCorrect ? widgetAttr.feedbackCorrect : widgetAttr.feedbackFalse;
    //

    return (
      <div className="feedback_block">
        <b dangerouslySetInnerHTML={{ __html: respAnswer }} className={isCorrect ? 'correct-answer' : 'wrong-answer'} />
        <div dangerouslySetInnerHTML={{ __html: feedback }} /> {/* show correct or incorrect feedback (always show) */}
        {/* show correct answer in case of 2nd answer wrong */}
        <div className="feedback" dangerouslySetInnerHTML={{ __html: showCorrectAnswer }} />
        {!isCorrect && question.tries === allowedTries ? correctSort : ''}
        {showCorrectFeedback()}
        <div dangerouslySetInnerHTML={{ __html: tryAgain }} className="retry" />
      </div>
    );
  };

  function showCorrectFeedback() {
    if (question.tries < allowedTries && !question.last_correct) return null;
    const correctFeedback = widgetAttr.options.feedback;
    return correctFeedback;
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget touched={touched.current} validateAnswer={validateAnswer} showFeedback={showFeedback} {...props}>
        <FormGroup className={classes.group}>
          <Sort
            handleInput={handleInput}
            touched={touched.current}
            handleTouched={handleTouched}
            disabled={isDisabled}
            items={touched.current ? lastResponse : shuffle(correctAnswerByValue)}
            {...props}
          />
        </FormGroup>
      </PlayWidget>
    </>
  );
};

SortQuestion.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  question: PropTypes.object,
  store: PropTypes.object
};

export default observer(SortQuestion);
