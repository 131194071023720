// /* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
/* CORE */
import { Store } from 'state/ContextStore';
import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
/* MUI */
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Typography, IconButton, Stack } from '@mui/material';
/* ICONS */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
/* CUSTOM COMP */
import Logo from './components/layout/Logo';
import MenuList from './components/layout/MenuList';
import ToolbarAccount from './components/toolbar/ToolbarAccount';
import ToastContainer from './components/Toasts/ToastContainer';
import PackageListDialog from './components/layout/PackageListDialog';

const drawerWidth = 290;
/*
  In summary, the AdminCourseLayout component defines the layout for an admin section of a web application, 
  including a sidebar, a top app bar, and the main content area. 
  The sidebar's open/closed state can be toggled, and the layout provides navigation, page titles, language selection, and user account management. 
  The children prop allows different content to be displayed within this layout, making it versatile for various admin-related pages or sections of the application.
*/
const AdminCourseLayout = ({ menu, title, children }) => {
  const store = useContext(Store);
  const { i18n } = useTranslation();
  const { routerState, params } = store;
  const [open, setOpen] = useState(store?.courseEdit?.drawerOpen || false);
  const theme = useTheme();
  //
  const packageTabTitle = params.package;
  const profession = store?.courseEdit?.currentPackage?.profession_name || '';
  const isEdit = routerState.routeName.includes('edit');
  const action = isEdit ? 'edit' : '';
  const pageTitle = (isEdit && store?.courseEdit?.currentCourse?.attributes?.title) || title;
  const breadCrumbTitle = isEdit ? `${action}/course/${pageTitle}` : pageTitle;
  const headerTitle = `${pageTitle}`;

  useEffect(() => {
    if (open !== store.courseEdit.drawerOpen) setOpen(store.courseEdit.drawerOpen);
  }, [open, store.courseEdit.drawerOpen]);

  const toggleDrawer = () => {
    setOpen(!open);
    store.courseEdit.setStoreValue('drawerOpen', !open);
  };

  /* Return to homepage */
  const goHome = (event) => {
    event.preventDefault();
    window.location.href = '/';
  };
  const adminLeftmenu = {
    ' .MuiDrawer-paper': { background: theme.palette.primary.main }
  };

  return (
    <Box className={`admin-course-layout ${open && `draweropen`}`} sx={{ display: 'flex' }}>
      <Drawer className="test" variant="permanent" open={open} sx={adminLeftmenu}>
        <Box className="main__logo" onClick={goHome} sx={sxLogoContainer}>
          <Logo />
        </Box>
        <MenuList menu={menu} />
      </Drawer>

      <Box component="main" sx={cssMain} className="admin__main">
        <DrawerHeader />
        <AppBar open={open} className="header-bar" sx={cssHeaderBar} position="fixed">
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Box className="toolbar-left" sx={cssToolBarLeft}>
              <Box>
                <IconButton
                  size="small"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer}
                  sx={{
                    '&:hover': {
                      background: theme.palette.primary.main,
                      transform: 'scale(1.2)',
                      transition: 'all .2s'
                    },
                    background: theme.palette.secondary.main,
                    color: 'white',
                    marginRight: 5,
                    ...(open && { display: 'inline-flex' })
                  }}
                >
                  {!open ? <ChevronRightIcon fontSize="inherit" /> : <ChevronLeftIcon fontSize="inherit" />}
                </IconButton>
              </Box>
              <Box>
                <Typography sx={cssBreadCrumbs} variant="h2" component="div">
                  Admin/
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {profession ? profession.toLowerCase() : ''}
                  </Box>
                  /{packageTabTitle}/{breadCrumbTitle}
                </Typography>
                <Box sx={cssHeaderTitle} variant="h2" component="div">
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {`${headerTitle} ${(!isEdit && packageTabTitle) || ''} `}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="toolbar-tools" sx={cssToolBarTools}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Box onClick={() => i18n.changeLanguage('en')} sx={sxLanguageBox}>
                  <img style={sxFlags} alt="EN" src={`${flagsPath}/ic_flag_en.svg`} />
                </Box>
                <Box onClick={() => i18n.changeLanguage('nl')} sx={sxLanguageBox}>
                  <img style={sxFlags} alt="NL" src={`${flagsPath}/ic_flag_nl.svg`} />
                </Box>
                <Box>
                  <ToolbarAccount />
                </Box>
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        {children}
      </Box>
      <PackageListDialog />
      <ToastContainer />
    </Box>
  );
};

export default observer(AdminCourseLayout);
AdminCourseLayout.propTypes = {
  children: PropTypes.element,
  title: PropTypes.any,
  menu: PropTypes.any
};

const flagsPath = `/assets/icons`;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

/* INLINE CSS */
const cssMain = {
  maxWidth: 'calc(100% - 290px)',
  padding: '15px',
  minHeight: '100vh',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden'
};
const cssHeaderBar = { background: '#EEF2FA', boxShadow: 'none', padding: '10px' };
const cssToolBarLeft = { display: 'flex', alignItems: 'center', marginLeft: '-21px' };
const cssToolBarTools = {
  display: 'flex',
  border: '10px solid',
  background: 'white',
  borderRadius: '40px',
  justifyContent: 'end'
};
const cssBreadCrumbs = { color: '#232427cf', fontSize: '11px', fontWeight: '400' };
const cssHeaderTitle = { color: '#232427cf', fontSize: 'calc(0.75em + 1vw)', fontWeight: '600' };
const sxLanguageBox = { cursor: 'pointer', width: '40px', height: '40px', fontSize: '30px' };
const sxFlags = { width: '28px', height: '20px', borderRadius: '0px' };
//
const sxImageHover = { '&:hover img': { transform: 'scale(1.4)', position: 'absolute', transition: 'all .2s' } };
const sxLogoContainer = {
  cursor: 'pointer',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '15px',
  width: '100%',
  height: '100%',
  minHeight: '90px',
  maxHeight: '90px',
  textAlign: 'center',
  ...{ img: { height: '100%!important', maxHeight: '60px!important', maxWidth: '75px' } },
  ...sxImageHover
};
