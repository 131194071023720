import { createRouterState } from 'mobx-state-router';
import { ThemeBrandTitle, convertUrlEncodedString } from 'helpers/Helpers';
import { getEwapiSearch } from 'state/RoutesActions';

/**
 * CourseOverviewRoute
 * @type {{name: string, pattern: string, title: string, beforeEnter: (function(*, *, *): Promise<void>)}}
 */
export const searchOverviewRoute = {
    name: 'searchOverview',
    pattern: '/search/:baseProductId/:searchParam/:searchAttempt',
    meta: { title: `%%searchoverview.title%% | ${ThemeBrandTitle()}` },
    // eslint-disable-next-line consistent-return
    beforeEnter: async (fromState, toState, routerStore) => {
        const searchParam = toState.params.searchParam.toString();
        const baseProductId = toState.params.baseProductId.toString();
        const { store } = routerStore.options;
        if (searchParam !== '' && searchParam !== undefined && searchParam !== null
            && baseProductId !== '' && baseProductId !== undefined && baseProductId !== null) {
            store.setSearchParam(convertUrlEncodedString(searchParam));
            store.setSearchBaseProductParam(baseProductId);
            try {
                const response = getEwapiSearch(fromState, toState, routerStore);
                if (response?.data?.error) {
                    return createRouterState('home');
                }
            } catch (error) {
                // Handle error if necessary
                console.error('Error fetching search data:', error);
            }
        }
    }
};
