import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

/* MUI */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

const CoursePointsAlert = (props) => {
  const { open, handleClose, handleConfirm, msg, showConfirm, btnBackCaption } = props;
  const { t } = useTranslation();
  const getMessage = {
    'msg-1': 'introduction.popup.title',
    'msg-2': 'introduction.popup.title2',
    'msg-3': 'introduction.popup.notEnoughPoints',
    'msg-4': 'introduction.popup.courseExpired',
    'msg-5': 'introduction.popup.courseCompletLessThan30Minutes'
  };
  const showMessage = getMessage[msg];

  return (
    <Dialog open={open} onClose={handleClose} id="points-dialog-title">
      <DialogContent>
        <DialogContentText>{t(showMessage, 'Do you want to continue?')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {btnBackCaption || t('introduction.popup.btn1', 'Back')}
        </Button>
        {!showConfirm && (
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {t('introduction.popup.btn2', 'Continue')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

CoursePointsAlert.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  msg: PropTypes.string,
  showConfirm: PropTypes.bool,
  btnBackCaption: PropTypes.string
};

export default observer(CoursePointsAlert);
