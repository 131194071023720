import PropTypes from 'prop-types';
import { useCustomTheme, useStore } from '../../hooks';

/* Our logo */
const Logo = ({ style, showLabelLogo = false }) => {
  const { labelLogo, customLogo } = useCustomTheme();
  const store = useStore();

  /* Return to homepage */
  const GoHome = (event) => {
    event.preventDefault();
    const NoRedirectToHome = store.getLtiSession() === 1 || store.getLtiSession() === '1';
    window.location.href = NoRedirectToHome ? '#' : '/';
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="logo-wrapper" onClick={(event) => GoHome(event)}>
      <img src={showLabelLogo ? labelLogo : customLogo ?? labelLogo} alt="logo" className="logo" id="logo" style={style} />
    </div>
  );
};

/* Our logo props */
Logo.propTypes = {
  style: PropTypes.object,
  showLabelLogo: PropTypes.bool
};

export default Logo;
