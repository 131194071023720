/* Core */
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
// MUI
import { Grid, DialogContent, DialogActions, Box, Button, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
/* ICONS */
import UndoIcon from '@mui/icons-material/Undo';
import ArchiveIcon from '@mui/icons-material/Archive';
import ClearIcon from '@mui/icons-material/Clear';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
/* Custom RHF components */
import { Form } from 'components/form';
import { MySelect, MyCheckbox, MyTextField, FileUpload } from 'components/form/RHF';
import {
  LessonToast,
  LessonFormLabels,
  getSelectedLesson,
  getSelectedLessonContent,
  getSelectedLessonInitialFormValues
} from 'pages/adminCourses/addCourse/EditCourse.helper';
import { newCourseValidation } from './beans/NewCourse.ValidationSchema';
import { defaultValues, lessonTypes, freeFlow } from './beans/lesson.helper';
import OneAnswerGroup from './OneAnswerGroup';
/* CUSTOM components */
import DialogWidgetsTable from '../../lesson/DialogWidgetsTable';
/* LOADERS */
import LoaderWithBackdrop from '../../loading/LoaderWithBackdrop';
import LoadingLinearProgress from '../../loading/LoadingLinearProgress';

const LessonEditForm = () => {
  const { t } = useTranslation('editCourseForm');
  const store = useStore();
  //
  const [loading, setLoading] = useState(false);
  const [displayFormFieldContainer, setDisplay] = useState('flex');
  const [odtFileName, setOdtFileName] = useState({});
  //
  // Destructering
  const { courseEdit } = store;
  const { currentCourse } = courseEdit;

  const fileNameOdt = store?.courseEdit?.currentFilename?.fileName || '';

  useEffect(() => {
    if (fileNameOdt) {
      setOdtFileName({ file: fileNameOdt, url: store?.courseEdit?.currentFilename?.fileUri });
    }
  }, [fileNameOdt, store?.courseEdit?.currentFilename?.fileUri, store?.courseEdit?.currentFilename.url]);

  // TOAST MESSAGES __________________________________________________
  const toast = LessonToast();

  // FORM LABELS TRANSLATIONS ______________________________________________
  const labels = LessonFormLabels();

  // SELECTED LESSON VALUES__________________________________________
  const selectedLesson = getSelectedLesson(store);
  const selectedLessonContent = getSelectedLessonContent(currentCourse, selectedLesson);
  const initialValues = getSelectedLessonInitialFormValues(currentCourse, selectedLesson, defaultValues, selectedLessonContent);

  // EVENT: SUBMIT FORM ________________________________________
  const handleSubmitLesson = async (values) => {
    if (values?.attributes?.field_valid_period) delete values.attributes.field_valid_period;
    setLoading(true);
    try {
      await store.courseEdit.patchpostLesson(values);
      setLoading(false);
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
      courseEdit.setDialogLesson();
    } catch (error) {
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmitLesson} initialValues={initialValues} validationSchema={newCourseValidation}>
      {({ control, errors, dirty, reset, watch, setValue, getValues }) => (
        <Box className="form__content" sx={sxFlexColumn}>
          <LoaderWithBackdrop open={loading} />
          <DialogContent sx={sxDialogContent}>
            {/* 1. LESSON PART OF THE DIALOG */}
            <Box className="form_fields_container" sx={{ ...sxFormFieldContainer, display: displayFormFieldContainer }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MyTextField
                    autoFocus
                    size="small"
                    name="attributes.title"
                    label={labels.title}
                    type="text"
                    fullWidth
                    control={control}
                    error={errors?.attributes?.title}
                    helperText={errors?.attributes?.title && errors.attributes.title?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} container>
                  <Grid item xs={12}>
                    <MySelect control={control} label={labels.lessonType} name="attributes.lesson_type" items={lessonTypes} />
                  </Grid>
                  <Grid item xs={12}>
                    <MySelect control={control} label={labels.freeFlow} name="attributes.free_flow" items={freeFlow} />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ '& .MuiFormControlLabel-label': { fontSize: '13px' } }}>
                    <MyCheckbox
                      control={control}
                      name="attributes.rotating_answers"
                      label={labels.rotatingAnswers}
                      labelplacement="bottom"
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ '& .MuiFormControlLabel-label': { fontSize: '13px' } }}>
                    <OneAnswerGroup
                      control={control}
                      labels={labels}
                      setValue={setValue}
                      getValues={getValues}
                      initialValues={initialValues}
                      reset={reset}
                      watch={watch}
                      defaultValues={getValues()}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} container>
                  <Grid item xs={12}>
                    <Stack sx={{ rowGap: '20px' }} spacing={2} direction="column">
                      <MyTextField
                        autoFocus
                        margin="normal"
                        name="attributes.minimum_score"
                        label={labels.minimumScore}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        type="number"
                        fullWidth
                        control={control}
                        error={errors?.attributes?.minimum_score}
                      />
                      <MyTextField
                        autoFocus
                        margin="normal"
                        name="attributes.minimum_score_2nd_try"
                        label={labels.minimumScore2ndTry}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        type="number"
                        fullWidth
                        control={control}
                        error={errors?.attributes?.minimum_score_2nd_try}
                      />
                      <MyTextField
                        autoFocus
                        margin="normal"
                        name="attributes.max_attempts"
                        label={labels.maxAttempts}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        type="number"
                        fullWidth
                        control={control}
                        error={errors?.attributes?.max_attempts}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} container>
                  <Grid item xs={6}>
                    <Box className="file_upload_container" sx={sxBoxFileUpload}>
                      <FileUpload
                        control={control}
                        label="ODT file"
                        buttonName="Upload ODT file"
                        accept=".odt"
                        name="selectedLessonContent.relationships.tagdoc.data"
                        fileName=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {odtFileName?.file ? (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItens: 'center' }}>
                        <Box>{odtFileName?.file || ''}</Box>
                        <Box>
                          <Button
                            href={odtFileName?.url || ''}
                            target="_blank"
                            rel="noopener"
                            disableElevation
                            sx={{ borderRadius: '20px' }}
                            size="small"
                            color="primary"
                            variant="contained"
                            endIcon={<CloudDownloadIcon />}
                          >
                            download odt
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {/* 2. QUESTION WIDGETS PART OF THE DIALOG */}
            <Box sx={sxFlexColumn}>
              {selectedLessonContent?.id && <DialogWidgetsTable showLessonItems={setDisplay} lessonContentId={selectedLessonContent.id} />}
            </Box>
          </DialogContent>
          {/* ______ DIALOG FORM ACTION BAR ____________ */}
          <DialogActions sx={{ justifyContent: 'space-between', padding: '25px', position: 'relative' }}>
            <Box sx={sxLoader}>
              <LoadingLinearProgress loading={loading} />
            </Box>
            <Box>
              <Button onClick={() => reset()} variant="text" startIcon={<UndoIcon />} disabled={!dirty}>
                {t('lesEditForm.btnReset')}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', columnGap: '14px' }}>
              <Button
                onClick={() => {
                  reset();
                  courseEdit.setDialogLesson();
                }}
                disableElevation
                variant="contained"
                endIcon={<ClearIcon />}
                sx={{ background: '#607d8b' }}
              >
                {t('lesEditForm.btnCancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                endIcon={<ArchiveIcon />}
                loading={loading}
                loadingPosition="end"
                disabled={!dirty}
              >
                {t('lesEditForm.btnSave')}
              </LoadingButton>
            </Box>
          </DialogActions>
        </Box>
      )}
    </Form>
  );
};

export default observer(LessonEditForm);

// CUSTOM SX ________________________________________________________________
const sxLoader = { position: 'absolute', left: '0px', top: '0px', width: '100%', height: '4px' };
const sxFlexColumn = { display: 'flex', flex: '1', flexDirection: 'column' };
const sxDialogContent = {
  display: 'flex',
  flexDirection: 'column',
  '.MuiFormControl-root': { padding: '0px', margin: '0px' },
  '.MuiInputBase-root': { height: '40px', fontSize: '14px' },
  '.MuiGrid-item': { margin: '5px 0px', paddingTop: '0px!important' }
};
const sxFormFieldContainer = {
  marginBottom: '0px',
  padding: '10px 20px',
  background: '#607d8b12',
  borderRadius: '10px',
  '.MuiInputBase-root': { background: 'white' }
};
const sxBoxFileUpload = {
  display: 'flex',
  padding: '10px',
  borderRadius: '5px',
  'label label': {
    boxShadow: 'none',
    background: 'white',
    border: '5px solid #d8dee1',
    borderRadius: '20px',
    fontSize: '14px',
    color: '#698594'
  },
  '>.MuiBox-root': { display: 'flex', columnGap: '10px' }
};
