import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const FeedbackTableDetail = ({ rowData }) => {
  const { rowData: details } = rowData;
  const { t: r } = useTranslation('editCourseForm');
  const { t: e } = useTranslation('evaluation');

  const propsDetail = {
    header: { component: 'h3', mb: 0, mt: 1 },
    feedback: { component: 'p', mb: 0 }
  };

  const detailPanelItems = [
    { title: e('form.evaluation_question_1b'), field: 'evaluation_question_1b', preTitle: r('tabFeedback.quality') },
    { title: e('form.evaluation_question_2b'), field: 'evaluation_question_2b', preTitle: r('tabFeedback.applicable') },
    { title: e('form.evaluation_remarks_2'), field: 'evaluation_remarks_2' },
    { title: e('form.evaluation_remarks_1'), field: 'evaluation_remarks_1' }
  ];
  return (
    <Box sx={{ p: 1, paddingLeft: '50px' }} key={details.id}>
      {detailPanelItems.map((item) => {
        const { title, field } = item;
        const question = item?.preTitle ? `${item.preTitle}: ${title}` : title;
        return (
          <Box key={field}>
            <Box {...propsDetail.header}>{question}</Box>
            <Box>{details[field]}</Box>
          </Box>
        );
      })}
    </Box>
  );
};

FeedbackTableDetail.propTypes = {
  rowData: PropTypes.object.isRequired
};

export default observer(FeedbackTableDetail);
