/* eslint-disable no-unused-vars */
import { convertToCorrectMatchAnswer } from 'pages/play/questions/MatchSortUtils';

const types = {
  check: 'Check Question',
  sort: 'Sort Question',
  radio: 'Radio Question',
  open: 'Open Question',
  match: 'Match Question',
  numerical: 'Numerical Question'
};

/*
 NOTE: sonarclound is showing security hotspost issues on this file, but it is a false positive.
 the errors are related to using regular expressions.
 /(<([^>]+)>)/gi is used to remove html tags.
 there is no risk of using regular expressions in this file.
*/

export const createCmi5JourneyHooks = (cmi5Client) => ({
  onStartCourse: (courseId) => {
    // cmi5Client.startCourse(courseId);
  },
  onStartLesson: (courseId, lessonId) => {
    // cmi5Client.startLesson(courseId, lessonId);
  },
  onStartPage: (courseId, lessonId, pageId) => {
    // cmi5Client.startPage(courseId, lessonId, pageId);
  },
  onAnswerQuestion: (courseId, questionId, answerIds, question, correct, options, type) => {
    const saveFunction = createSaveFunctionsMap(cmi5Client)[type];
    if (!saveFunction) return;
    const variablesGenerator = createCMI5VariablesGenerator(type);
    const variables = variablesGenerator({ courseId, questionId, answerIds, question, correct, options });
    saveFunction(...Object.values(variables));
  },
  onNextPage: (courseId, lessonId, pageId) => {
    // cmi5Client.nextPage(courseId, lessonId, pageId);
  },
  onFinishLesson: (courseId, lessonId) => {
    // cmi5Client.finishLesson(courseId, lessonId);
  },
  onNextLesson: (courseId, lessonId) => {
    // cmi5Client.nextLesson(courseId, lessonId);
  },
  onFinishCourse: (courseId) => {
    // cmi5Client.finishCourse(courseId);
  },
  onLeaveLesson: (courseId, lessonId) => {
    // cmi5Client.leaveLesson(courseId, lessonId);
  },
  onLeaveCourse: (courseId) => {
    // cmi5Client.leaveCourse(courseId);
  }
});

// wrapped in a function to avoid loss of `this` context in the cmi5 api, maybe fixable with bind(cmi5Client)
const createSaveFunctionsMap = (cmi5Client) => ({
  [types.check]: (...args) => cmi5Client.interactionChoice(...args),
  [types.sort]: (...args) => cmi5Client.interactionSequencing(...args),
  [types.radio]: (...args) => cmi5Client.interactionChoice(...args),
  [types.open]: (...args) => withoutChoices((...nargs) => cmi5Client.interactionLongFillIn(...nargs))(...args),
  [types.numerical]: (...args) => withoutChoices((...nargs) => cmi5Client.interactionNumeric(...nargs))(...args),
  [types.match]: (...args) => withoutChoices((...nargs) => cmi5Client.interactionOther(...nargs))(...args)
});

const withoutChoices = (interaction) => (testId, questionId, answers, correctAnswers, _choices, name, description, success) =>
  interaction(testId, questionId, answers, correctAnswers, name, description, success);

const createCMI5VariablesGenerator =
  (widgetType) =>
  ({ courseId, questionId, answerIds, question, correct, options }) => ({
    testId: courseId,
    questionId,
    answerIds: widgetTransforms[widgetType]?.answerIds ? widgetTransforms[widgetType].answerIds(answerIds) : answerIds,
    correctAnswerIds: widgetTransforms[widgetType]?.correctAnswerIds ? widgetTransforms[widgetType].correctAnswerIds(options) : options,
    choices: widgetTransforms[widgetType]?.choices ? widgetTransforms[widgetType].choices(options) : options,
    name: widgetTransforms[widgetType]?.name ? widgetTransforms[widgetType].name(question) : question,
    description: widgetTransforms[widgetType]?.description ? widgetTransforms[widgetType].description(question) : question,
    success: correct
  });

const widgetTransforms = {
  [types.check]: {
    answerIds: (values) =>
      values
        .split(',')
        .map((value, i) => (+value ? `option-${i}` : false))
        .filter(Boolean),
    correctAnswerIds: (options) => options.map((option, i) => (option.correct ? `option-${i}` : false)).filter(Boolean),
    choices: (options) =>
      options.map((option, i) => ({
        id: `option-${i}`,
        description: { 'en-US': `${option.label} ${option.feedback.replace(/(<([^>]+)>)/gi, '')}` }
      })),
    name: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') }),
    description: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') })
  },
  [types.sort]: {
    correctAnswerIds: (options) => options.map((a) => a.value),
    choices: (options) => options.map((item) => ({ id: item.value, description: { 'en-US': item.label } })),
    name: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') }),
    description: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') })
  },
  [types.radio]: {
    answerIds: (value) => [`option-${value}`],
    correctAnswerIds: (options) => options.map((option, i) => (option.correct ? `option-${i}` : false)).filter(Boolean),
    choices: (options) =>
      options.map((option, i) => ({
        id: `option-${i}`,
        description: { 'en-US': `${option.label} ${option.feedback.replace(/(<([^>]+)>)/gi, '')}` }
      })),
    name: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') }),
    description: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') })
  },
  [types.open]: {
    answerIds: (value) => [value],
    correctAnswerIds: () => ['*'],
    name: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') }),
    description: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') })
  },
  [types.numerical]: {
    correctAnswerIds: (options) =>
      options.valueEnd > options.value ? { min: options.value, max: options.valueEnd } : { exact: options.value },
    name: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') }),
    description: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') })
  },
  [types.match]: {
    correctAnswerIds: (options) => JSON.stringify({ ...convertToCorrectMatchAnswer(options), bench: undefined }),
    name: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') }),
    description: (question) => ({ 'en-US': question.replace(/(<([^>]+)>)/gi, '') })
  }
};
