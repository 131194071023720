import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import {
  Accordion,
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  Switch,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Grid
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useTranslation } from 'react-i18next';
import { useBreakpoint, useStore } from 'hooks';

const mapSortByLabels = {
  New: 'course.badges.badge1',
  Expected: 'course.badges.badge2',
  Ending: 'course.badges.badge3',
  Update: 'course.badges.badge4'
};

/*
In summary, the CourseFilters component is responsible for rendering a set of filters for courses.
It allows users to filter courses by author, categories, statuses, competencies, and the presence of podcasts.
Users can also clear all filters and view all available courses.
The appearance and behavior of these filters are determined by data from the application's store and user interactions.
*/
const CourseFilters = ({ onClose }) => {
  const store = useStore();
  const { t } = useTranslation();
  const currentBreakpoint = useBreakpoint();
  const desktopMode = currentBreakpoint === 'xl';
  const expanded = currentBreakpoint === 'sm' || currentBreakpoint === 'xs';
  // if (store.courses.isSpecialAccessView) return null;

  const [searchAuthorInput, setSearchAuthorInput] = React.useState(() => {
    const authorId = store.courses?.filter.authorId;
    if (authorId !== undefined) {
      const author = store.authors.getAuthorById(authorId);

      return author || null;
    }
    return null;
  });

  const isFiltering = store.courses?.isFiltering;
  const authors = store.authors.sortedByFullName;

  const categories = (store.courses?.categories || []).sort((a, b) => a.name.localeCompare(b.name, 'nl', { ignorePunctuation: true }));
  const statuses = store.courses?.statuses;
  const competencies = store.courses?.competencies;
  const countPodcastes = store.courses?.countPodcastes;
  const countExpiringCourses = store.courses?.countExpiringCourses;

  const filterByCategories = store.courses?.filter.categories;
  const filterByStatuses = store.courses?.filter.statuses;
  const filterByCompetencies = store.courses?.filter.competencies;
  const filterByPodcasts = store.courses?.filter.hasPodcast;
  const filterByExpiringCourses = store.courses?.filter.isExpiring;

  const handleClearFilters = () => {
    store.courses.resetFilter();
    setSearchAuthorInput(null);
  };
  const handleSearchAuthor = (event, value) => {
    if (value) {
      store.courses.filterByAuthor(value.id);
    } else {
      store.courses.filterByAuthor(null);
    }
    setSearchAuthorInput(value);
  };

  return (
    <Box data-testid="courses-filter" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '15px' }}>
      <Box className="filters_headline">
        <Typography fontSize="24px" color="#2B3674" fontWeight="700">
          {t('course.filters.title')}
        </Typography>
        {isFiltering && <Button onClick={handleClearFilters}>{t('course.filters.clear')}</Button>}
      </Box>

      {authors.length > 0 && (
        <Box className="authorSection">
          <Box className="filter_search_author">
            <SearchIcon />
            <Autocomplete
              options={authors}
              className="instructor_styling"
              size="small"
              getOptionLabel={(option) => option.fullName}
              value={searchAuthorInput}
              onChange={handleSearchAuthor}
              renderInput={(params) => <TextField {...params} label={t('course.filters.search')} />}
            />
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              const { product, productName, packageId } = store.params;
              store.routerStore.goTo('teachersOverview', { params: { product, productName, packageId } });
              if (!desktopMode && store.isfilterVisible) {
                onClose();
              }
            }}
            className="teacherButton"
          >
            {t('course.filters.viewAll')}
          </Button>
        </Box>
      )}

      <Box className="course_overview_filters_stack1" direction="column">
        {categories?.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>{t('course.filters.category.title')}</AccordionSummary>
            <AccordionDetails>
              {categories.map((category) => (
                <Grid key={category.tid} container m={0} px={1} justifyContent="space-between" alignItems="center">
                  <Grid item m={0}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={filterByCategories?.includes(category.tid)}
                          onChange={() => store.courses.filterByCategories(category.tid)}
                          color="primary"
                        />
                      }
                      label={
                        <Typography color="#2B3674" fontSize="12px" fontWeight="500">
                          {category.name}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Typography color="#2B3674" fontSize="9.6px" fontWeight="400">
                      {category.count}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>
        )}

        {(statuses?.length > 0 || countExpiringCourses > 0) && (
          <Box mt={1}>
            <Accordion defaultExpanded={expanded}>
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>{t('course.filters.sort.title')}</AccordionSummary>
              <AccordionDetails>
                {statuses.map((status) => (
                  <Grid key={status.name} container m={0} px={1} justifyContent="space-between" alignItems="center">
                    <Grid item m={0}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={filterByStatuses?.includes(status.name)}
                            onChange={() => store.courses.filterByStatuses(status.name)}
                            color="primary"
                          />
                        }
                        label={
                          <Typography color="#2B3674" fontSize="12px" fontWeight="500">
                            {t(mapSortByLabels[status.name])}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography color="#2B3674" fontSize="9.6px" fontWeight="400">
                        {status.count}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
                {countExpiringCourses > 0 && (
                  <Grid container m={0} px={1} justifyContent="space-between" alignItems="center">
                    <Grid item m={0}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={filterByExpiringCourses}
                            onChange={() => store.courses.filterByExpiringCourses()}
                            color="primary"
                          />
                        }
                        label={
                          <Typography color="#2B3674" fontSize="12px" fontWeight="500">
                            {t('course.filters.expiring.label')}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography color="#2B3674" fontSize="9.6px" fontWeight="400">
                        {countExpiringCourses}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

        {competencies?.length > 0 && (
          <Box mt={1}>
            <Accordion defaultExpanded={expanded}>
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>{t('course.filters.compentence.title')}</AccordionSummary>
              <AccordionDetails>
                {competencies.map((competence) => (
                  <Grid key={competence.tid} container m={0} px={1} justifyContent="space-between" alignItems="center">
                    <Grid item m={0}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={filterByCompetencies?.includes(competence.tid)}
                            onChange={() => store.courses.filterByCompetencies(competence.tid)}
                            color="primary"
                          />
                        }
                        label={
                          <Typography color="#2B3674" fontSize="12px" fontWeight="500">
                            {competence.name}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography color="#2B3674" fontSize="9.6px" fontWeight="400">
                        {competence.count}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

        {countPodcastes > 0 && (
          <Box mt={1}>
            <Accordion defaultExpanded={expanded}>
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>{t('course.filters.podcast.title')}</AccordionSummary>
              <AccordionDetails>
                <Grid container m={0} px={1} justifyContent="space-between" alignItems="center">
                  <Grid item m={0}>
                    <FormControlLabel
                      control={<Switch checked={filterByPodcasts} onChange={() => store.courses.filterByPodcasts()} color="primary" />}
                      label={
                        <Typography color="#2B3674" fontSize="12px" fontWeight="500">
                          {t('course.filters.podcast.label')}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Typography color="#2B3674" fontSize="9.6px" fontWeight="400">
                      {countPodcastes}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </Box>
  );
};

CourseFilters.propTypes = {
  onClose: PropTypes.func
};

export default observer(CourseFilters);
