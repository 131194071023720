import { Box, Chip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import { useTheme } from '@mui/styles';
import { urlify } from 'state/Utils';
import { useTranslation } from 'react-i18next';
import { IsLtiDeeplinker, GetSearchTimeLabel, translatePoints } from 'helpers/Helpers';
import CourseStatus from 'pages/course/components/CourseOverview/Card/elements/CourseStatus';
import Progress from 'pages/course/components/CourseOverview/Grid/CourseTable/Progress';
import { FavoriteButton } from 'pages/course/components/CourseOverview/Grid/CourseTable/FavoriteButton';
import HideCourse from 'pages/course/components/CourseOverview/Card/elements/HideCourse';
import CourseCategories from 'pages/course/components/CourseOverview/Card/elements/CourseCategories';
import CourseLtiButton from 'pages/course/components/CourseOverview/Card/elements/CourseLtiButton';
import CourseAuthors from 'pages/course/components/CourseOverview/Card/elements/CourseAuthors';
import CourseSymbols from 'pages/course/components/CourseOverview/Card/elements/CourseSymbols';
import PodcastIcon from 'pages/course/components/CourseOverview/Card/elements/PodcastIcon';
import CoursePackage from './elements/CoursePackage';

const CourseTableRow = (props) => {
  const store = useContext(Store);
  const { propsBasicCard, hideCourse } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const gotoCourse = (courseId, packageId, title) =>
    store.routerStore.goTo('courseIntroduction', {
      params: {
        productName: store.baseProduct.unique_name,
        product: store.selectedProductId.toString(),
        packageId: packageId.toString(),
        courseId: courseId.toString(),
        title: urlify(title)
      }
    });
  const isCourseExpiring = store.courses.isCourseExpiring(propsBasicCard.nid);
  const isCourseFavorite = store.favorites && store.favorites.isCourseFavorite(propsBasicCard.nid);
  const getCoursePackage = store.courses.getCoursePackage(propsBasicCard.nid) || '';

  const sxAuthorChip = {
    top: 0,
    left: 0,
    padding: 0,
    margin: 0,
    marginTop: '5px',
    height: 'auto',
    position: 'relative',
    border: 'none',
    borderRadius: '5px',
    background: '#f8f8f8',
    color: '#2c3331',
    marginRight: '5px'
  };

  const points = propsBasicCard.duration;
  const splitPoints = points.split(' ');
  const getHours = parseFloat(splitPoints[0]);
  const isMinutes = getHours < 1 && splitPoints[1]?.includes('ur');

  const courseImage = propsBasicCard?.courseImage?.replace(/%20/g, '%2520');

  return (
    <Box
      key={propsBasicCard.id}
      sx={{
        backgroundColor: 'white',
        borderRadius: 3,
        mt: 1,
        ml: 2,
        boxShadow: '0 5px 12px rgba(0,0,0,0.0)',
        transition: 'all ease-in-out, 0.2s',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0 0 30px rgba(0,0,0,0.15)',
          display: hideCourse && 'grid',
          gridTemplateColumns: hideCourse && '1fr 35px',
          [`.hideCourse`]: {
            display: 'flex',
            transition: 'width 0.2s',
            transform: 'translateX(0px)',
            width: '40px',
            [`& .MuiButtonBase-root`]: {
              borderRadius: '0px 8px 8px 0px'
            }
          }
        },
        [`.hideCourse`]: {
          display: 'none'
        }
      }}
      className="card__container"
      onClick={() => gotoCourse(propsBasicCard.nid, propsBasicCard.packageId, propsBasicCard.courseTitle)}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'grid',
          gridTemplateColumns: {
            md: `46px auto 200px 126px 48px`,
            xs: `46px 1fr 48px`
          },
          gap: 2,
          width: '100%',
          p: 2,
          alignItems: 'center'
        }}
      >
        <Box
          backgroundColor="palette.grey"
          sx={{
            height: 46,
            backgroundImage: `url("${courseImage}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            alignSelf: 'flex-start',
            marginTop: '4px',
            borderRadius: 1,
            backgroundColor: theme.palette.grey[200],
            position: 'relative'
          }}
        >
          <Progress id={propsBasicCard.nid} lessons={propsBasicCard.lessons} />
        </Box>
        <Box>
          <Box
            sx={{
              marginBottom: '8px'
            }}
          >
            {getCoursePackage !== '' && <CoursePackage packageLabel={getCoursePackage} />}
            <CourseStatus status={propsBasicCard.chip} isCourseExpiring={isCourseExpiring} size="small" nid={propsBasicCard.nid} />
            {IsLtiDeeplinker() && <CourseLtiButton courseId={propsBasicCard.nid} hideBg={1} />}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ fontWeight: 800, mr: 1 }}>
              {propsBasicCard.courseTitle}
            </Typography>
            {propsBasicCard.podcast && <PodcastIcon sx={{ height: 20, mr: '8px' }} style={{ marginTop: '2px' }} />}
          </Box>
          {propsBasicCard.extraLabel && (
            <Typography variant="body2" color="primary" sx={{ mb: '4px', fontStyle: 'italic' }}>
              {propsBasicCard.extraLabel}
            </Typography>
          )}
          <Box sx={{ '.authors-wrapper .chip_author': sxAuthorChip }}>
            <CourseAuthors authors={[...(propsBasicCard?.authors || []), ...(propsBasicCard?.reviewers || [])]} />
          </Box>

          {store.courses?.isFiltering && (
            <Box
              sx={{
                display: 'flex',
                mt: 2,
                [`.card__categories`]: {
                  display: 'flex',
                  gap: 1,
                  flex: '1 1 auto',
                  [`.chip_category`]: {
                    height: 'auto',
                    border: '1px solid #2a4a92'
                  }
                }
              }}
            >
              <CourseCategories categories={propsBasicCard.categoryLabelArray} />
            </Box>
          )}
          <Box sx={{ gap: 1, mt: 2, display: { xs: 'flex', md: 'none' } }}>
            <Box sx={{ display: 'flex', gap: '2px', flexWrap: 'wrap' }}>
              <CourseSymbols badges={propsBasicCard.badges} small />
            </Box>
            <Chip color="primary" label={propsBasicCard.duration} size="small" />
          </Box>
        </Box>

        <Box sx={{ justifySelf: 'end', display: { xs: 'none', md: 'initial' } }}>
          <Box sx={{ display: 'flex', gap: '2px', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            <CourseSymbols badges={propsBasicCard.badges} small />
          </Box>
        </Box>
        <Box sx={{ justifySelf: 'end', display: { xs: 'none', md: 'initial' } }}>
          <Chip
            color="primary"
            label={isMinutes ? GetSearchTimeLabel(getHours, propsBasicCard.packageId, true) : translatePoints(points, t)}
            size="small"
          />
        </Box>
        <Box sx={{ position: { xs: 'absolute', md: 'relative' }, top: { xs: 8, md: 'initial' }, right: { xs: 8, md: 'initial' } }}>
          <FavoriteButton propsBasicCard={propsBasicCard} isCourseFavorite={isCourseFavorite} />
        </Box>
      </Box>
      {hideCourse && <HideCourse id={propsBasicCard.id} />}
    </Box>
  );
};

CourseTableRow.propTypes = {
  propsBasicCard: PropTypes.any,
  hideCourse: PropTypes.bool
};

export default observer(CourseTableRow);
