/* Core */
import { observer } from 'mobx-react';
import { useStore } from 'hooks';

/* MUI */
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { convertUrlEncodedString } from 'helpers/Helpers';

/* Custom searching component */
const Searching = () => {
  const store = useStore();
  const { t } = useTranslation();
  const searchResults = store.searchCoursesFound;

  const goBackToSearchResults = () => {
    const searchTerm = store.searchParam;
    store.routerStore.goTo('searchOverview', {
      params: {
        baseProductId: store.baseProduct?.nid.toString(),
        searchParam: convertUrlEncodedString(searchTerm.trim()),
        searchAttempt: store.searchIsRandom === true ? '2' : '0'
      }
    });
  };

  // If no search param is set and the current route is not searchOverview, do not render the component
  // eslint-disable-next-line no-useless-return
  if (store.searchParam === '') {
    return null;
  }

  return (
    <Tooltip title={t('searchoverview.back', 'Go back to search results')} arrow>
      <Button
        sx={{
          height: '41px',
          width: '41px',
          fontSize: '1rem',
          padding: '8px',
          marginRight: '8px',
          borderRadius: '50%',
          minWidth: 'auto'
        }}
        variant="contained"
        onClick={goBackToSearchResults}
      >
        {searchResults}
      </Button>
    </Tooltip>
  );
};

export default observer(Searching);
