import PropTypes from 'prop-types';
import parse, { domToReact } from 'html-react-parser/dist/html-react-parser.min';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMemo } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

export const EventTypeDescriptionDialog = ({ selectedData, onClose }) => {
  const { t } = useTranslation('translation');

  const parsedHtml = useMemo(() => {
    if (!selectedData) {
      return null;
    }

    return parse(selectedData.body.processed, {
      // eslint-disable-next-line consistent-return
      replace: ({ attribs, children, options, name }) => {
        if (name === 'div' && attribs.class?.split(' ').includes('field-label')) {
          const textChildren = children.find((child) => child.name === 'strong')?.children;

          return <Typography variant="h5">{domToReact(textChildren, options)}</Typography>;
        }

        if (name === 'p') {
          return (
            <Typography sx={{ my: 1 }} variant="body1">
              {domToReact(children, options)}
            </Typography>
          );
        }

        if (name === 'ul') {
          const listItemChildren = children.filter((child) => child.name === 'li');

          return (
            <ul>
              {listItemChildren.map((listItemChild) => (
                <li key={listItemChild.data}>
                  <Typography variant="body2">{domToReact(listItemChild.children, listItemChild.options)}</Typography>
                </li>
              ))}
            </ul>
          );
        }
      }
    });
  }, [selectedData]);

  if (!parsedHtml) {
    return null;
  }

  return (
    <Dialog open={!!selectedData} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{selectedData.title}</DialogTitle>
      <DialogContent>
        <Box className="event-type-description">{parsedHtml}</Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
            <Button onClick={onClose}>{t('lesson.close')}</Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EventTypeDescriptionDialog.propTypes = {
  selectedData: PropTypes.any,
  onClose: PropTypes.func.isRequired
};
