import { prepareCourseView } from 'state/RoutesActions';
import { ThemeBrandTitle } from 'helpers/Helpers';

// route for the settings page
// This code gets the settings for a user.

export const settingsRoute = {
  name: 'settings',
  pattern: '/account/settings',
  meta: { title: `%%account.settings.title%% | ${ThemeBrandTitle()}` },
  beforeEnter: async (fromState, toState, routerStore) => prepareCourseView(fromState, toState, routerStore)
};
