import { IconButton } from '@mui/material';
import { Fingerprint } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { useStore, useCustomTheme } from 'hooks';

/*
In summary, the ToggleColorMode component provides a simple user interface for switching between different color themes for the application. 
When a user clicks one of the themed buttons, it not only changes the color theme but may also initiate the retrieval of theme-specific content. 
This feature allows for a dynamic and customized user experience based on the selected theme.
*/
const ToggleColorMode = () => {
  const { setThemeName } = useCustomTheme();
  const store = useStore();

  return (
    <div id="toggle_color_mode" style={{ background: 'white', display: 'flex', gap: '11px' }}>
      <IconButton
        aria-label="fingerprint"
        style={{ color: '#9e2e67', width: '20px' }}
        onClick={() => {
          setThemeName('ewiseTheme');
          store.fetchFaqByLabel('ewise');
        }}
      >
        <Fingerprint />
      </IconButton>
      <IconButton
        aria-label="fingerprint"
        style={{ color: '#2a4a92', width: '20px' }}
        onClick={() => {
          setThemeName('cmeTheme');
          store.fetchFaqByLabel('cme');
        }}
      >
        <Fingerprint />
      </IconButton>
      <IconButton
        aria-label="fingerprint"
        style={{ color: '#006846', width: '20px' }}
        onClick={() => {
          setThemeName('poTheme');
          store.fetchFaqByLabel('po');
        }}
      >
        <Fingerprint />
      </IconButton>
    </div>
  );
};
export default observer(ToggleColorMode);
