import AdminCourseLayout from 'pages/adminCourses/AdminCourseLayout';
import EditCourse from './EditCourse';

const EditCourseViewMap = {
  editCourse: (
    <AdminCourseLayout menu="new" title="Add new course">
      <EditCourse noPaper />
    </AdminCourseLayout>
  )
};

export default EditCourseViewMap;
