import { Controller } from 'react-hook-form';
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useCallback, useState, useEffect, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import useDebounce from '../../../hooks/useDebounce';

const MyAutocompleteTaxonomy = forwardRef(({ name, control, label, node, multiSelect = true, includeNid = false, ...otherProps }, fRef) => {
  const store = useContext(Store);
  const { t } = useTranslation('editCourseForm');

  const [objOptions, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const errorSearchTxt = 'type 3 characters or more';
  const errorNoData = 'Geen data gevonden';

  const toast = {
    search: { title: 'search', content: 'minimal 3 characters are needed for the search', type: 'info' },
    empty: { title: t('serverEmptyResponse.title'), content: t('serverEmptyResponse.message'), type: 'info' },
    fail: { title: t('serverTimeOut.title'), content: t('serverTimeOut.message'), type: 'error' },
    default: { timeOut: 4000, variant: 'outlined' }
  };
  const searchMsg = useMemo(
    () => ({
      title: toast.search.title,
      content: toast.search.content,
      type: 'info',
      timeOut: 3000,
      variant: 'outlined'
    }),
    [toast.search.content, toast.search.title]
  );

  const fetchData = useCallback(
    async (search) => {
      try {
        const newQuery = `filter[name][condition][path]=title&filter[name][condition][value]=${search}&filter[name][condition][operator]=CONTAINS`;
        const { data: result } = await store.lmsApi.fetchIt(node, newQuery);
        const items = result.data;
        if (!items.length) {
          store.courseEdit.setToasts({
            title: t('serverEmptyResponse.title', 'Geen data gevonden'),
            content: t('serverEmptyResponse.message', 'Er zijn geen resultaten gevonden'),
            type: 'info',
            timeOut: 4000,
            variant: 'outlined'
          });
        }
        const newOptionsData = items.map((item) => ({
          title: item.attributes.title,
          id: item.id,
          type: node,
          ...(includeNid ? { nid: item.attributes.drupal_internal__nid } : {})
        }));
        setOptions(newOptionsData);
        setIsSearching(false);
      } catch (error) {
        setIsSearching(false);
        store.courseEdit.setToasts({
          title: t('serverTimeOut.title', 'Geen data gevonden'),
          content: t('serverTimeOut.message', 'Er zijn geen resultaten gevonden'),
          type: 'info',
          timeOut: 6000,
          variant: 'filled'
        });
      }
    },
    [store.lmsApi, store.courseEdit, node, t, includeNid]
  );

  useEffect(() => {
    if (debouncedSearchTerm.length > 0 && debouncedSearchTerm.length < 3) return setOptions([]);
    if (debouncedSearchTerm) {
      setIsSearching(true);
      setOptions([]);
      fetchData(debouncedSearchTerm);
    }
    return () => setOptions([]);
  }, [debouncedSearchTerm, fetchData, searchMsg, store.courseEdit]);

  return (
    <Controller
      control={control}
      name={name}
      ref={fRef}
      defaultValue={[]}
      render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
        <Autocomplete
          disabled={otherProps.disabled}
          {...field}
          freeSolo
          multiple={multiSelect}
          id="tags-outlined"
          options={objOptions.sort((a, b) => -b.title.localeCompare(a.title))}
          onChange={(_, data) => onChange(data)}
          // eslint-disable-next-line no-shadow
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => (option?.title ? `${option.title}${includeNid ? ` (${option.nid})` : ''}` : '')}
          getOptionKey={(option) => option?.id || ''}
          defaultValue={[]}
          loading={isSearching && objOptions.length === 0}
          loadingText={t('retrieveData', 'Retrieving data')}
          autoComplete
          filterSelectedOptions
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip key={indexedDB} variant="filled" color="primary" label={option.title} size="small" {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              error={
                (debouncedSearchTerm.length > 0 && debouncedSearchTerm.length < 3) ||
                (debouncedSearchTerm.length > 2 && !objOptions.length) ||
                false
              }
              {...params}
              {...field}
              label={label}
              onChange={(e) => setSearchTerm(e.target.value)}
              inputRef={ref}
              helperText={
                // eslint-disable-next-line no-nested-ternary
                debouncedSearchTerm.length > 0 && debouncedSearchTerm.length < 3
                  ? errorSearchTxt
                  : debouncedSearchTerm.length > 2 && !objOptions.length
                  ? errorNoData
                  : error?.message
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      )}
    />
  );
});

MyAutocompleteTaxonomy.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  node: PropTypes.string,
  otherProps: PropTypes.object,
  multiSelect: PropTypes.bool,
  includeNid: PropTypes.bool
};

export default observer(MyAutocompleteTaxonomy);
