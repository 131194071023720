import { ThemeBrandTitle } from 'helpers/Helpers';
import { createEditStore } from 'state/RoutesActions';

export const courseSearchBarViewRoute = {
  name: 'courseSearchBar',
  pattern: '/admin/courses/search',
  title: `View search results | ${ThemeBrandTitle}`,
  meta: { title: `View search results | ${ThemeBrandTitle()}` },
  beforeEnter: createEditStore
};
