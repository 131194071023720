import { SvgIcon } from '@mui/material';

const AuthorIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 19">
    <path d="M9.24229 3.01585C9.24229 4.68156 7.89201 6.03184 6.2263 6.03184C4.56073 6.03184 3.21045 4.68156 3.21045 3.01585C3.21045 1.35028 4.56073 0 6.2263 0C7.89201 0 9.24229 1.35028 9.24229 3.01585Z" />
    <path d="M15.6209 7.36889C15.3495 7.07944 14.9742 6.90989 14.5776 6.89725C14.181 6.8846 13.7957 7.02992 13.5062 7.30144L6.93339 13.4129L6.27979 15.9998L8.97799 15.595L15.5508 9.48358C15.8405 9.21244 16.0108 8.83736 16.0239 8.44079C16.037 8.04409 15.892 7.65861 15.6209 7.36889H15.6209Z" />
    <path d="M6.32602 13.055L11.4411 8.29672C10.9338 7.66946 10.171 7.30398 9.36413 7.30176H2.69821C1.98254 7.30176 1.29629 7.58607 0.790237 8.092C0.284189 8.59805 0 9.28433 0 9.99997V13.059H6.32595L6.32602 13.055Z" />
  </SvgIcon>
);

export default AuthorIcon;
