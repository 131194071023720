import { Box, Button, Grid } from '@mui/material';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import useAPI from '../../../hooks/useAPI';

export const ResetOnboarding = () => {
  const { t } = useTranslation('onboarding');
  const store = useContext(Store);
  const userUUID = store?.user?.uuid || undefined;
  const { response } = useAPI(`/jsonapi/user/${userUUID}`, undefined);
  const setOnboarding = response?.data.attributes.field_skip_tour || false;
  const [showResetOnboarding, setShowResetOnboarding] = useState(false);

  useEffect(() => {
    setShowResetOnboarding(setOnboarding);
  }, [setOnboarding]);

  const resetOnboarding = async () => {
    await window.authedClient.patch(`/jsonapi/user/${userUUID}`, {
      data: {
        type: 'user',
        id: userUUID,
        attributes: {
          field_skip_tour: false
        }
      }
    });

    store.showToast({ message: t('resetSuccess'), variant: 'success' });
    setShowResetOnboarding(false);
  };

  if (!showResetOnboarding) {
    return null;
  }

  return (
    <Box padding="10px  30px" sx={{ backgroundColor: 'white', borderRadius: '12.8px' }}>
      <Grid container height="54px" marginTop="0px" marginBottom="0px" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" gap="5px">
            <Button className="reset_pass" onClick={resetOnboarding}>
              {t('reset')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
