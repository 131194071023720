import { Lazy } from 'components';
import LastStep from '../evaluation/LastStep';

const TermsViewMap = {
  terms: (
    <Lazy noLayout>
      <LastStep />
    </Lazy>
  )
};

export default TermsViewMap;
