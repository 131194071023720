import Radio from '@mui/material/Radio';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// renders a Radio button with the option to use react-hook-form
// eslint-disable-next-line react/prop-types
const MyRadio = ({ name, control, index, onChange: onChangeProp, type = 'radio', value, label, options, checked }) => {
  const indexCorrectOption = options?.findIndex((x) => x.correct === true);

  return control ? (
    <Controller
      name={name}
      control={control}
      shouldUnregister
      render={({ onChange, field }) => (
        <Radio
          {...field}
          {...{ name, type, value, label }}
          {...(index !== undefined ? { checked: indexCorrectOption === index } : { checked })}
          onChange={onChangeProp || onChange}
          inputProps={{ 'aria-label': 'A' }}
        />
      )}
    />
  ) : (
    <Radio {...{ name, type, value, label, checked }} inputProps={{ 'aria-label': 'A' }} />
  );
};

MyRadio.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  options: PropTypes.array
};

export default MyRadio;
