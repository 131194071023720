// set color
export const setColor = (item, copyValues) => {
  const isSelected = item.package_id === copyValues.subMenuId;
  if (!isSelected) return ['#eef2fa', 'inherit'];
  return isSelected && ['#006847', '#fff'];
};
// menu item selected
export const isSelected = (item, open) => {
  const isOpen = item.package_id === open;
  return isOpen ? 'selected' : '';
};
// submenu active
export const isActive = (mainMenu, _subMenu, copyValues) => {
  const ccId = mainMenu.package_id;
  const subMenuName = _subMenu.name;
  const isSubmenu = ccId === copyValues.subMenuId && subMenuName === copyValues.subMenuItem;
  return isSubmenu;
};

export const defaultCopyValues = (store) => ({
  containerNid: store.params.containerNid,
  course_container_uuid: '',
  fromContainerNid: '',
  packageNid: 0,
  package: 'onbekend',
  course: 'onbekend',
  courseUuid: '',
  courseNid: '',
  action: '',
  actionId: 0,
  subMenuId: 0,
  subMenuItem: '',
  listType: 1,
  profession_id: '',
  profession_uuid: '',
  categoryUuid: ''
});
