/* eslint-disable camelcase */
import { applySnapshot, flow } from 'mobx-state-tree';
import { buildLessonPages } from 'pages/play/PageParsing';
import * as logger from 'loglevel';
import { PollWidgetItem, PollWidgetList } from './PollWidget';

const log = logger.getLogger('PageParsing');
log.setDefaultLevel('info');

export const pollWidgetLessonPlayViews = (lessonplay) => ({
  getPollWidget(widgetCode) {
    return lessonplay.pollWidgetList?.getPollWidget(widgetCode);
  }
});

export const pollWidgetLessonPlayActions = (lessonPlay) => ({
  createPollWidgetList(pollWidget) {
    const newWidget = PollWidgetItem.create(pollWidget);
    lessonPlay.pollWidgetList = PollWidgetList.create({ widgets: [newWidget] });
  },
  //
  fetchPollWidget: flow(function* fetchPollWidget(widgetCode) {
    const questionWidget = lessonPlay.question_widgets.find((w) => w.attributes.widget_code.toLowerCase() === widgetCode.toLowerCase());
    if (!questionWidget) {
      return;
    }
    const widget = yield lessonPlay.lmsApi.fetchPollWidget(questionWidget.attributes.widget_link);
    const pollWidget = {
      ...widget,
      widgetCode
    };
    if (lessonPlay.pollWidgetList) {
      lessonPlay.pollWidgetList.add(pollWidget);
    } else {
      lessonPlay.createPollWidgetList(pollWidget);
    }
  }),
  /**
   * UPDATEPOLL WIDGET
   * Create new poll widget
   * Create || Update question widget
   * Update lesson content
   */
  updatePollWidget: flow(function* updatePollWidget(poll) {
    const status = { success: 'error', message: window.i18next.t('widgetError') };
    // ==> CREATE NEW QUESTION WIDGET
    if (!poll.id) {
      try {
        let widgetLinkId = poll.relationships.widget_link.data?.id;
        // --> if !relationship widget_link data ->  CREATE NEW POLL WIDGET
        if (!poll.relationships.widget_link.data) {
          const newPollWidget = lessonPlay.createPollWidget(poll);
          const resPollWidget = yield lessonPlay.lmsApi.patchpost(newPollWidget);
          widgetLinkId = resPollWidget.id;
        }
        // --> set correct format -> QUESTION WIDGET
        const newQuestionWidget = lessonPlay.createQuestionWidget(poll, widgetLinkId);
        // --> patchpost -> QUESTION WIDGET
        const resQuestionWidget = yield lessonPlay.lmsApi.patchpost(newQuestionWidget);
        const resQuestionWidgetRelPollNid = resQuestionWidget.relationships.widget_link.data.meta.drupal_internal__target_id;
        // --> set correct format -> LESSON_CONTENT
        const newQuestionWidgetId = resQuestionWidget?.id;
        const newLessonContent = lessonPlay.createLessonContent(newQuestionWidgetId);
        // --> patchpost LESSON_CONTENT
        yield lessonPlay.lmsApi.patchpost(newLessonContent);

        // --> fetch ewapi -> RELATED POLL WIDGET
        const fetchedPollWidget = yield lessonPlay.lmsApi.fetchPollWidget(resQuestionWidgetRelPollNid);
        fetchedPollWidget.widgetCode = resQuestionWidget.attributes.widget_code;
        fetchedPollWidget.answer = null;
        //
        if (lessonPlay.pollWidgetList) {
          lessonPlay.pollWidgetList.add(fetchedPollWidget);
        } else {
          lessonPlay.createPollWidgetList(fetchedPollWidget);
        }
        lessonPlay.question_widgets.push({ ...resQuestionWidget });
        lessonPlay.pageList = buildLessonPages(lessonPlay);
        lessonPlay.reload++;
        // --> set -> STATUS
        status.success = 'success';
        status.message = window.i18next.t('widgetSaved');
        //
      } catch (e) {
        console.error(e);
        window.store.showToast({ message: window.i18next.t('widgetError'), variant: 'error' });
        throw e;
      }
    }
    // ==> UPDATE QUESTION WIDGET
    if (poll.id) {
      const existingWidgetIndex = lessonPlay?.question_widgets?.findIndex((qw) => qw.id === poll.id);

      try {
        status.message = window.i18next.t('widgetError1');
        if (!poll.relationships?.widget_link?.data) return status;
        if (poll.relationships?.widget_link?.data === poll.widget_link_uuid) return status;
        //
        const patchQuestionWidget = lessonPlay.patchQuestionWidget(poll, poll.relationships?.widget_link?.data?.id);
        //
        const resQuestionWidget = yield lessonPlay.lmsApi.patchpost(patchQuestionWidget);
        const resQuestionWidgetNid = resQuestionWidget.relationships?.widget_link?.data?.meta.drupal_internal__target_id;
        //
        // FETCH THE NEW RELATED POLL WIDGET
        const fetchedPollWidget = yield lessonPlay.lmsApi.fetchPollWidget(resQuestionWidgetNid);
        fetchedPollWidget.widgetCode = resQuestionWidget.attributes.widget_code;
        //
        const existingPollIndex = lessonPlay.pollWidgetList.widgets.findIndex((pw) => pw.widgetCode === poll.attributes.widget_code);
        //
        applySnapshot(lessonPlay.pollWidgetList.widgets[existingPollIndex], fetchedPollWidget);
        applySnapshot(lessonPlay.question_widgets[existingWidgetIndex], resQuestionWidget);
        //
        //
        if (lessonPlay.pollWidgetList) {
          lessonPlay.pollWidgetList.add(fetchedPollWidget);
        } else {
          lessonPlay.createPollWidgetList(fetchedPollWidget);
        }
        status.success = 'success';
        status.message = window.i18next.t('widgetSaved');
      } catch (e) {
        window.store.showToast({ message: window.i18next.t('widgetError'), variant: 'error' });
        console.error(e);
        throw e;
      }
    }
    // ==> RETURN STATUS
    return status;
    //
  }),

  editPollWidget: flow(function* addPollWidget(widget) {
    yield JSON.stringify(widget, null, 2);
  }),
  //
  // returns correct format new -> poll_widget
  createPollWidget(poll) {
    return {
      type: 'poll_widget',
      attributes: {
        title: poll.attributes.title,
        body: {
          value: poll.attributes.question
        },
        poll_options: poll.attributes.poll_options
      }
    };
  },
  //
  // returns correct format new -> question_widget
  patchQuestionWidget(qw, widgetLinkId) {
    return {
      id: qw.id,
      type: 'question_widget',
      attributes: {
        title: qw.attributes.title,
        weight: qw.attributes.weight,
        widget_code: qw.attributes.widget_code,
        widget_type: 'Poll widget'
      },
      relationships: {
        widget_link: {
          data: {
            type: 'poll_widget',
            id: widgetLinkId,
            meta: {
              target_type: 'node'
            }
          }
        }
      }
    };
  },
  // returns correct format update -> question_widget
  createQuestionWidget(poll, widgetLinkId) {
    return {
      type: 'question_widget',
      attributes: {
        title: poll.attributes.title,
        weight: poll.attributes.weight,
        widget_code: poll.attributes.widget_code,
        widget_data: null,
        widget_type: 'Poll widget'
      },
      relationships: {
        widget_link: {
          data: {
            type: 'poll_widget',
            id: widgetLinkId,
            meta: {
              target_type: 'node'
            }
          }
        }
      }
    };
  },

  //
  // returns correct format update -> lesson_content
  createLessonContent(questionWidgetId) {
    const {
      id,
      type,
      attributes: { title }
    } = lessonPlay.lesson_content;
    //
    const lesson_content_widgets = {
      data: [...lessonPlay.question_widgets.map((qw) => ({ id: qw.id, type: qw.type })), { type: 'question_widget', id: questionWidgetId }]
    };
    return { id, type, attributes: { title }, relationships: { lesson_content_widgets } };
  },
  //
  hasRelatedPoll(control) {
    let relPoll = { id: '', question: '', options: [] };
    const pollLink = control._formValues.attributes?.widget_link;
    if (pollLink) {
      const relatedPoll = lessonPlay.pollWidgetList.widgets.find((w) => w.nid === pollLink);
      const question = relatedPoll.poll.body;
      const options = relatedPoll.poll.options.map((p) => p.value);
      relPoll = { id: '1', question, options };
    }
    return relPoll;
  },

  getDefaultValueLinkedPoll(control) {
    const pollLink = control._formValues.attributes?.widget_link;
    let iniValue = [];
    if (pollLink) {
      const relatedPoll = lessonPlay.pollWidgetList.widgets.find((w) => w.nid === pollLink);
      const internalTitle = relatedPoll?.poll.internal_title;
      iniValue = internalTitle ? [{ attributes: { title: internalTitle } }] : [];
    }
    return iniValue;
  },

  isLinkedPoll(poll) {
    return !!poll.relationships.data;
  }
});
