/* eslint-disable react/no-danger */
import { useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Video from 'components/widgets/Video';
import { observer } from 'mobx-react';
import { LinearProgress } from '@mui/material';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import useWidget from './useWidget';

const useStyles = makeStyles(() => ({
  reactPlayerWrapper: {
    background: '#c0c0c029',
    position: 'relative',
    paddingTop: '56.25%',
    textAlign: 'center'
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  noPlayWrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)'
  }
}));

const VideoQuestion = (props) => {
  const { store, widgetCode } = props;
  const classes = useStyles();
  const { question, widget, allowedRetries, PlayWidget } = useWidget(props);
  const widgetAttr = widget.attributes;
  const playerRef = useRef(null);

  const touched = useRef(!!question.last_response);
  const lastResponse = question.last_response ? question.last_response : '';
  const [values] = useState(lastResponse);
  const videoUrl = widgetAttr?.options?.url || '';

  const validateAnswer = () => ({ value: values, correct: true });

  const showFeedback = () => {
    if (!question.tries) return null;
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: widgetAttr.feedback }} /> {/* show general feedback (allways show) */}
        {showCorrectFeedback()}
      </>
    );
  };

  // * show main feedback */
  function showCorrectFeedback() {
    if (question.tries < allowedRetries && !question.last_correct) return null;
    const correctFeedback = widgetAttr.options.feedback;
    return correctFeedback;
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget {...props} touched={touched.current} validateAnswer={validateAnswer} showFeedback={showFeedback}>
        <div className={classes.reactPlayerWrapper}>
          <div className="innerVideo" style={{ width: '100%', height: '100%' }}>
            {videoUrl && <Video className={classes.reactPlayer} ref={playerRef} url={videoUrl} style={{ animation: 'fadeIn 2s' }} />}
          </div>
        </div>
      </PlayWidget>
    </>
  );
};

VideoQuestion.propTypes = {
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  question: PropTypes.object,
  store: PropTypes.object
};

export default memo(observer(VideoQuestion));
