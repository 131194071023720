import axios from 'axios';
import qs from 'qs';

const AUTH_CANARY_ENDPOINT = '/oauth/userinfo';

let store;
export const injectStore = (_store) => {
  store = _store;
};

const createAuthenticatedClient = (OAuthHandler) => {
  const AuthenticatedClient = axios.create({
    baseURL: process.env.REACT_APP_JSONAPI,
    // Making this timeout more aggro for now.
    // so slowness is noticed and not ignored
    timeout: 50000,
    headers: {}
  });

  AuthenticatedClient.subRequests = async (requests) => {
    const AuthToken = await OAuthHandler.getToken();

    requests.map((req) => {
      req.headers = {
        ...req.headers,
        Authorization: `Bearer ${AuthToken.access_token}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Cache-Control': 'no-cache'
      };
      return req.headers;
    });

    return new Promise((resolve, reject) => {
      AuthenticatedClient.post('subrequests?_format=json', requests, {
        headers: { 'Content-Type': 'application/json' }
      })
        .then((data) => {
          const response = {};
          Object.keys(data.data).forEach((key) => {
            response[key.replace(/#uri.*/, '')] = data.data[key].body && JSON.parse(data.data[key].body);
          });

          resolve(response);
        })
        .catch((e) => {
          let error;

          if (e?.response) {
            error = {
              name: e.response.data.error,
              message: e.response.data.message,
              status: e.response.status
            };
          } else if (e?.title) {
            error = { message: e.message, name: e.title, status: e.status };
          } else {
            error = { message: e?.message, name: e?.name, status: e?.status };
          }
          reject(error);
        });
    });
  };

  /**
   * Make sure all params are properly
   * serialized for every request
   *
   * @see https://axios-http.com/docs/urlencoded
   */
  AuthenticatedClient.interceptors.request.use((config) => {
    config.paramsSerializer = (params) =>
      // Qs is already included in the Axios package
      qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false
      });

    return config;
  });

  /**
   * Make sure the correct headers are set.
   * And that the request has active auth
   */
  AuthenticatedClient.interceptors.request.use(async (config) => {
    const AuthToken = await OAuthHandler.getToken();

    config.headers = {
      'Content-Type': 'application/vnd.api+json',
      Authorization: `Bearer ${AuthToken.access_token}`,
      ...config.headers
    };

    return config;
  });

  /**
   * Handle 503 - Unavailable
   */
  AuthenticatedClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response?.status === 503) {
        store.setStoreValue('maintenance', true);
      }
      return Promise.reject(error);
    }
  );

  /**
   * Make sure we properly handle 401 responses. and reset the auth
   * if for some reason the token has become invalid
   */
  AuthenticatedClient.interceptors.response.use(
    (response) => response,

    async (error) => {
      const originalRequest = error.config;

      if (!error?.response) {
        return Promise.reject(error);
      }

      if (error.response?.status !== 401) {
        return Promise.reject(error);
      }

      if (originalRequest.url === AUTH_CANARY_ENDPOINT) {
        return Promise.reject(error);
      }

      try {
        const userInfoResponse = await AuthenticatedClient.get(AUTH_CANARY_ENDPOINT);
        if (userInfoResponse.status === 401) {
          const oauthInvalidEvent = new Event('oauth_invalid');
          window.dispatchEvent(oauthInvalidEvent);
          return new Promise((resolve, reject) => setTimeout(reject, 3000));
        }
      } catch (e) {
        const oauthInvalidEvent = new Event('oauth_invalid');
        window.dispatchEvent(oauthInvalidEvent);
        return new Promise((resolve, reject) => setTimeout(reject, 3000));
      }

      // If the error is not related to auth, just forward it trough the stack
      return Promise.reject(error);
    }
  );

  /**
   * Add error formatting.
   *
   * This is kinda a mess, but we need to keep compatibility with a lot
   * of code that makes use of this format
   */
  AuthenticatedClient.interceptors.response.use(
    (response) => response,

    async (error) => {
      if (error.message && error.message.indexOf('timeout') !== -1) {
        error.message = 'Timeout';
        error.status = 408;
      } else if (error?.response?.data?.errors && error.response.data.errors.length) {
        const e = error.response.data.errors[0];
        error.message = e.detail;
        error.status = e.status;
        error.title = e.title;
      } else {
        error.message = error.response ? error.response.data.message : 'Unknown error.';
        error.status = error.response ? error.response.status : 500;
      }

      return Promise.reject(error);
    }
  );

  /**
   * Add timer for this request
   */
  AuthenticatedClient.interceptors.request.use(async (config) => config);

  /**
   * finish timer for this response
   */
  AuthenticatedClient.interceptors.response.use(
    async (response) => response,

    async (error) => Promise.reject(error)
  );

  return AuthenticatedClient;
};

export default createAuthenticatedClient;
