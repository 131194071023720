import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';

// renders a DatePicker
const MyDatePicker = forwardRef(
  ({ name, control, textFieldProps = {}, ...otherProps }, ref) =>
    control && (
      <Controller
        name={name}
        control={control}
        shouldUnregister
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={dayjs(field.value)}
              // onChange={onDatePicked}
              {...field}
              ref={ref}
              inputFormat="dd/MM/yyyy"
              {...otherProps}
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  fullWidth
                  {...params}
                  error={otherProps?.error}
                  helperText={otherProps?.helperText}
                  {...textFieldProps}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    )
);

MyDatePicker.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  textFieldProps: PropTypes.object
};

export default MyDatePicker;
