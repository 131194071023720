/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
/* Helpers */
import { rawHTML, isValidPodcast } from 'helpers/Helpers';
/* MUI */
import { Box } from '@mui/material';
import CourseSubHeaders from './CourseSubHeaders';

// renders the course podcast to be displayed in the preview dialog
const CoursePodcast = ({ previewValues }) => {
  if (!previewValues?.lessonContainer.attributes.field_podcast_html) return '';
  if (!isValidPodcast(previewValues?.lessonContainer.attributes.field_podcast_html)) return '';
  return (
    <Box sx={{ border: '1px solid #eee', padding: '15px', borderRadius: '20px' }}>
      <Box className="video-wrapper">{rawHTML(previewValues?.lessonContainer.attributes.podcast_title)}</Box>
      <CourseSubHeaders title="Podcast" />

      <Box>
        <Box className="video-wrapper">{rawHTML(previewValues?.lessonContainer.attributes.field_podcast_html)}</Box>
      </Box>
    </Box>
  );
};

CoursePodcast.propTypes = {
  previewValues: PropTypes.object
};

export default observer(CoursePodcast);
