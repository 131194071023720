/* eslint-disable camelcase */
/* Core */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Store } from 'state/ContextStore';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

/* MUI */
import Box from '@mui/material/Box';
import { Typography, Grid, CardMedia } from '@mui/material';

/* Custom components */
import CalculateProgress from 'pages/course/components/Common/CalculateProgress';
import { LinearProgressWithLabel } from 'components';
import { isCourseFailed, getLabelNameFromDomain, IsLtiDeeplinker } from 'helpers/Helpers';
import { useTheme } from '@mui/styles';
import CourseSymbols from '../CourseOverview/Card/elements/CourseSymbols';
import CourseReactions from '../CourseOverview/Card/elements/CourseReactions';
import CourseIntroductionAuthors from './CourseIntroductionAuthors';
import CourseStatus from '../CourseOverview/Card/elements/CourseStatus';
import CourseLtiButton from '../CourseOverview/Card/elements/CourseLtiButton';

const CourseIntroductionHeader = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  // eslint-disable-next-line camelcase
  const { title, subTitle, image, badges, id, uuid, lesson, chip, authors, isCourseExpiring } = props;
  const store = useContext(Store);
  const { isLessonFailed } = store.lesson_results;
  const { isCourseStarted } = store.courseResults;

  const isStarted = isCourseStarted(id);
  const isFailed = isCourseFailed(store.lesson_results, store.courseResults, id);
  const currentTheme = getLabelNameFromDomain();
  const showAuthors = currentTheme === 'po';
  const isNonTrialCourse = (store.isTrialProduct && store.trialCourses && !store.trialCourseIds.includes(id)) || false;

  return (
    <>
      <Box className="header" id="header" style={{ background: 'linear-gradient(90deg, #6F0039 11.97%, #920850 72.31%)' }}>
        {image && <CardMedia className="banner" component="img" loading="lazy" image={image} alt={title} />}
        <Box className="course_information">
          <Box className="content">
            <Typography
              variant="h1"
              component="h1"
              color="white"
              fontSize="30px"
              sx={{
                paddingRight: {
                  xs: '60px',
                  sm: '30px'
                }
              }}
            >
              {title}
            </Typography>
            {subTitle !== '' && (
              <Typography fontStyle="italic" fontSize="24px" color="white">
                {subTitle}
              </Typography>
            )}
            {IsLtiDeeplinker() && <CourseLtiButton courseId={id} hideBg={1} />}
            {showAuthors && authors && authors.length > 0 && <CourseIntroductionAuthors authors={authors} />}
            <Box className="course_accreditations" component="div">
              <CourseSymbols badges={badges} />
            </Box>
            {lesson.length === 1 && isStarted && store.courseResults.courseResults.length && !isLessonFailed(id) && !isFailed && (
              <Box className="status">
                <Grid container gap="4px" flexDirection="column" justifyContent="center">
                  <Grid item xs={2}>
                    <Typography fontSize="20px" color="primaryBlue.main" fontWeight="500">
                      {t('introduction.progress', 'Progress')}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Box display="flex" justifyContent="flex-end" marginBottom="10px" marginRight="8px">
                      <Typography component="div" fontSize="11px" color="secondaryOrange.main">
                        {store.courseResults.courseResults.length ? (
                          <>
                            <CalculateProgress id={id} uuid={uuid} lessons={lesson} isInHeader showProgress /> %
                          </>
                        ) : (
                          '0%'
                        )}
                      </Typography>
                    </Box>

                    <Box sx={{ width: '100%', mr: 1 }}>
                      {store.courseResults.courseResults.length ? (
                        <CalculateProgress id={id} uuid={uuid} lessons={lesson} isInHeader />
                      ) : (
                        <LinearProgressWithLabel value={0} color="secondaryOrange" />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="chips_box">
          <CourseStatus status={chip} isCourseExpiring={isCourseExpiring} />
        </Box>
        <Box className="favorites_box">
          {isNonTrialCourse ? '' : <CourseReactions id={uuid} lessons={lesson} nid={id} courseIntroduction />}
        </Box>
        <Box className="gradient-overlay" style={{ background: theme.custom?.courseIntroductionHeader?.imageGradient }} />
      </Box>
    </>
  );
};

CourseIntroductionHeader.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  badges: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  id: PropTypes.number,
  uuid: PropTypes.string,
  lesson: PropTypes.any,
  chip: PropTypes.string,
  authors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isCourseExpiring: PropTypes.any
};

export default observer(CourseIntroductionHeader);
