import AdminCourseLayout from 'pages/adminCourses/AdminCourseLayout';
import OfflineCourses from './OfflineCourses';

const OfflineCoursesViewMap = {
  adminOfflineCourses: (
    <AdminCourseLayout menu="offline" title="Offline courses">
      <OfflineCourses noPaper />
    </AdminCourseLayout>
  )
};

export default OfflineCoursesViewMap;
